import SizeBox from "@components/SizeBox"
import Conversation from "@components/category/Conversation"
import Exercise from "@components/category/Exercise"
import Grammar from "@components/category/Grammar"
import Kanji from "@components/category/Kanji"
import Vocab from "@components/category/Vocab"
import SubNavComponent from "@components/common/SubNavComponent"
import Helper from "@helper"
import { memo } from "react"

const CategoryMobile = ({ nabs, lesson, cagetories, vocab, furigana, fontSize, lang, grammarExplain, id, kaiwaPerson }: any) => {
    return <div className=" md:hidden container mx-auto mb-5 ">
        <SubNavComponent nabs={nabs} lesson={lesson} />
        <SizeBox vertical="h-10" />
        <div className="flex flex-row ">
            {/* vocab Card */}
            <Vocab className=""
                cagetories={cagetories}
                vocab={vocab}
                furigana={furigana}
                furiTranslate={Helper.furiTranslate}
                fontSize={fontSize}
                lang={lang} />
            <SizeBox horizontal="w-4" />

            {/* Kanji Card */}
            {(id + 1) % 5 != 0 ?
                <Kanji cagetories={cagetories} id={id} />
                : <Exercise cagetories={cagetories} id={id} />
            }

        </div>
        <SizeBox vertical="h-10" />

        {/* Grammar */}
        <Grammar cagetories={cagetories} grammarExplain={grammarExplain} furigana={furigana}
            furiTranslate={Helper.furiTranslate}
            fontSize={fontSize} />  
        <SizeBox vertical="h-10" />
        {/* Converstation */}
        <Conversation cagetories={cagetories} kaiwaPerson={kaiwaPerson} furigana={furigana}
            furiTranslate={Helper.furiTranslate}
            fontSize={fontSize} />
            

    </div>
}
export default memo(CategoryMobile);