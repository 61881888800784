export const lesson3 = {
    '特': 20,
    '別': 21,
    '有': 22,
    '便': 23,
    '利': 24,
    '不': 25,
    '切': 26,
    '元': 27,
    '好': 28,
    '急': 29
};