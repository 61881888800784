import KanjiListMobile from "@components/kanji/KanjiListMobileComponent";
import KanjiListWeb from "@components/kanji/KanjiListWebComponent";
import { Minna } from "@consts";
import { BottomSpace, TopSpace } from "@consts/layout";
import KanjiScreenLayout from "@layouts/home/KanjiScreenLayout";
import { memo, useEffect } from "react";
import { ScreenPropType } from "../../../App";

// data convert only
const KanjiScreen = ({ setScores,showModel,setShowModel,setUnlockPremium,getScore, ScoreType, Logger, useLocation, Routes, useNavigate, routeHistory ,setRouteData}: ScreenPropType) => {
    // const log = Logger(8);
    const navigate = useNavigate();
    const location = useLocation();
    const { id, lesson } = location.state;
    const kanjiId = id - Math.floor(id / 5);
    const kanjiScore = getScore({ id: kanjiId, key: ScoreType.KANJI_SCORE });
    const kanjiLessons = Minna.kanji.getLesson(`${kanjiId + 1}`);
    const nabs = [
        {
            title: "home",
            route: Routes.HOME,
        },
        {
            title: "Category",
            route: Routes.CATEGORY,
            state: { id, lesson }
        },
        {
            title: "Kanji ",
            route: null
        }
    ];
 
    const kanjiDetail = (kanjiId:any) => {
        const state = { lesson, id, path: location.pathname, kanjiId };
        navigate(Routes.KANJI_DETAIL, { state });
        scoreIncrementAction();
    }
    const scoreIncrementAction = ()=>{
        const {kanji,hira,count} = getScore({id,key:ScoreType.KANJI_SCORE});
        setScores({id,key:ScoreType.KANJI_SCORE,value: {kanji,hira, count: count + 1}});
        // console.log({kanji,hira, count: count + 1});
    }

    const startGame = ({ kanji2hira }: any) => {
        const state = { lesson, id:kanjiId, kanji2hira };
        navigate(Routes.KANJI_GAME, { state });
    }
    const getKanji = () => {
        // [[{kanji:"1",id:0},"2","3","4","5"],["6","7","8","9","10"]]
        let temp: Array<Array<Object>> = [[], []];
        kanjiLessons.map(({ id, kanji }: { id: number, kanji: string }, index: number) => {
            if (index < 5) {
                temp[0] = [...temp[0], { kanji, id }]
            } else {
                temp[1] = [...temp[1], { kanji, id }]
            }
        });
        return temp;
    }
    useEffect(() => {
        if (routeHistory.home.route != Routes.KANJI) {
            setRouteData({
                home: {
                    state: { id, lesson },
                    route: Routes.KANJI
                }
            })
        }
    }, [])
    return (
        <KanjiScreenLayout
            body={
                <div className="container mx-auto">
                    <TopSpace/>
                    {/* Web View */}
                    <KanjiListWeb
                        lesson={lesson}
                        kanjiLessons={kanjiLessons}
                        kanjiDetail={kanjiDetail}
                        startGame={startGame}
                        score={kanjiScore}
                        getKanji={getKanji}
                        nabs={nabs}
                    />

                    {/* Mobile View */}
                    <KanjiListMobile
                        lesson={lesson}
                        kanjiLessons={kanjiLessons}
                        kanjiDetail={kanjiDetail}
                        startGame={startGame}
                        score={kanjiScore}
                        getKanji={getKanji}
                        nabs={nabs}
                    />
                    <BottomSpace/>
                </div>
            }
            Routes={Routes}
            Logger={Logger}
            useNavigate={useNavigate}
            showModel={showModel}
            setShowModel={setShowModel}
            setUnlockPremium={setUnlockPremium}
        />

    )
}
export default memo(KanjiScreen);
