export const bunkei= [
    {
        sentence: [
            {
                furi: "＾速（はや）｜く　｜＾泳（およ）｜げるように、｜＾毎（まい）日（にち）｜　｜＾練（れん）習（しゅう）｜して　います。",
                normal: "速く　泳げるように、毎日　練習して　います。",
                en: "I practice every day so that I can swim fast.",
                mm: "မြန်မြန်ရေကူးနိုင်အောင် နေ့တိုင်းလေ့ကျင့်နေတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson36/bunpou/4N36BK1.mp3")
    },
    {
        sentence: [
            {
                furi: "やっと　｜＾自（じ）転（てん）車（しゃ）｜に　｜＾乗（の）｜れるように　なりました。",
                normal: "やっと　自転車に　乗れるように　なりました。",
                en: "I've finally learned to ride a bicycle.",
                mm: "နောက်ဆုံးတော့ စက်ဘီးစီးတတ်လာတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson36/bunpou/4N36BK2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾毎（まい）日（にち）｜　｜＾日（にっ）記（こう）｜を　｜＾書（か）｜くように　して　います。",
                normal: "毎日　日記を　書くように　して　います。",
                en: "I try to write a diary every day.",
                mm: "နေ့တိုင်း ဒိုင်ယာရီရေးဖြစ်ဖို့ ကြိုးစားတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson36/bunpou/4N36BK3.mp3")
    },
]