export const lesson26 = [
    {
        furi: "＾見（み）｜ます。",
        normal: "見ます",
        en: "see",
        remark_en: "",
        mm: "ကြည့်သည်",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO1.mp3")
    },
    {
        furi: "＾診（み）｜ます",
        normal: "診ます",
        en: "examine",
        remark_en: "",
        mm: "ကြည့်ရှုစစ်ဆေးသည်၊ စမ်းသပ်ကြည့်ရှုသည်",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO1.mp3")
    },
    {
        furi: "＾探（さが）｜します。",
        normal: "探します",
        en: "look for, search",
        remark_en: "",
        mm: "ရှာသည်၊ ရှာ‌ဖွေသည်",
        remark_mm: "",
        group: 1,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO2.mp3")
    },
    {
        furi: "＾送（おく）｜れます。［｜＾時（じ）｜＾間（かん）｜に　～　］", 
        normal: "送れます　［時間に　～　］",
        en: "be late",
        remark_en: "",
        mm: "နောက်ကျသည် [အချိန်(ကို) ～　]",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO3.mp3")
    },
    {
        furi: "＾間（ま）｜に｜＾合（あ）｜います。［｜＾時（じ）｜＾間（かん）｜に　～　］",
        normal: "間に　合ます　［時間に　～　］",
        en: "be on time",
        remark_en: "",
        mm: "အချိန်မီသည် [အချိန်(ကို) ～　]",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO4.mp3")
    },
    {
        furi: "やります",
        normal: "やります",
        en: "do",
        remark_en: "",
        mm: "လုပ်သည်၊ လုပ်ကိုင်သည်",
        remark_mm: "(　「します」နှင့်အဓိပ္ပာယ် တူညီ၍ အ‌ပြောစကားတွင် သုံးလေ့ရှိသည်။)",
        group: 1,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO5.mp3")
    },
    {
        furi: "＾拾（ひろ）｜います。",
        normal: "拾います",
        en: "pick up",
        remark_en: "(title of respect added to a name)",
        mm: "ကောက်ယူသည်",
        remark_mm: "",
        group: 1,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO6.mp3")
    },
    {
        furi: "＾連絡（れんらく）｜します。",
        normal: "連絡します",
        en: "contact",
        remark_en: "",
        mm: "ဆက်သွယ်သည်",
        remark_mm: "",
        group: 3,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO7.mp3")
    },
    {
        furi: "＾気分（きぶん）｜が　いい。",
        normal: "きぶんが　いい",
        en: "feel well",
        remark_en: "",
        mm: "စိတ်ကြည်လင်သည်၊ စိတ်ကောင်းဝင်သည်",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO8.mp3")
    },
    {
        furi: "＾気分（きぶん）｜が　わるい。",
        normal: "気分が　わるい",
        en: "feel ill",
        remark_en: "",
        mm: "စိတ်မကြည်မလင်ဖြစ်သည်၊ စိတ်ဆိုးသည်",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO9.mp3")
    },
    {
        furi: "＾運（うん）｜＾動（どう）｜＾会（かい）｜",
        normal: "運動会",
        en: "athletic meeting",
        remark_en: "",
        mm: "အားကစားပွဲ",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO10.mp3")
    },
    {
        furi: "＾盆（ぼん）｜＾踊（うん）｜り",
        normal: "盆踊り",
        en: "teacher, instructor",
        remark_en: "Bon dance",
        mm: "ဘွန်းအက",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO11.mp3")
    },
    {
        furi: "フリーマーケット",
        normal: "フリーマーケット",
        en: "Free Market",
        remark_en: "",
        mm: "အဟောင်းဈေး၊ ဖရီးမားကတ်",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO12.mp3")
    },
    {
        furi: "＾場（ば）｜＾所（しょ）",
        normal: "ばしょ",
        en: "place",
        remark_en: "",
        mm: "နေရာ",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO13.mp3")
    },
    {
        furi: "ボランティア",
        normal: "ボランティア",
        en: "volunteer",
        remark_en: "",
        mm: "လုပ်အားပေး၊ ပရဟိတ",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO14.mp3")
    },
    {
        furi: "＾財（さい）｜＾布（ふ）",
        normal: "さいふ",
        en: "Wallet",
        remark_en: "",
        mm: "ပိုက်ဆံအိတ်",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO15.mp3")
    },
    {
        furi: "ごみ",
        normal: "ごみ",
        en: "garbage, trash, dust",
        remark_en: "",
        mm: "အမှိုက်",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO16.mp3")
    },
    {
        furi: "＾国（こっ）｜＾会（かい）｜＾議（ぎ）｜＾事（じ）｜＾堂（どう）",
        normal: "国会議事堂",
        en: "Houses of Parliament",
        remark_en: "",
        mm: "လွှတ်တော်အဆောက်အဦး",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO17.mp3")
    },
    {
        furi: "＾平（へい）｜＾日（じつ）｜",
        normal: "へいじつ",
        en: "week day",
        remark_en: "",
        mm: "ကြားရက်",
        remark_mm: "(စနေ၊ တနင်္ဂနွေမှအပ တနင်္လာနေ့မှ သောကြာနေ့အထိ)",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO18.mp3")
    },
    {
        furi: "＾弁（べん）",
        normal: "～べん",
        en: "dialect",
        remark_en: "",
        mm: "～လေသံ၊　～ဒေသစကား",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO19.mp3")
    },
    {
        furi: "＾今（こん）｜＾度（ど）",
        normal: "こんど",
        en: "next time, another time",
        remark_en: "",
        mm: "နောက်တစ်ကြိမ်၊ နောက်တစ်ခါ",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO20.mp3")
    },
    {
        furi: "すいぶん",
        normal: "すいぶん",
        en: "pretty, very",
        remark_en: "",
        mm: "အပြတ်အသတ်",
        remark_mm: "(မှန်းထားသည့် အနေအထားထက် သာလွန်ခြင်း)",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO21.mp3")
    },
    {
        furi: "＾直（ちょく）｜＾接（せつ）",
        normal: "ちょくせつ",
        en: "directly",
        remark_en: "",
        mm: "တိုက်ရိုက်",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO22.mp3")
    },
    {
        furi: "いつでも",
        normal: "いつでも",
        en: "any time",
        remark_en: "",
        mm: "အချိန်မရွေး၊ ဘယ်အချိန်မဆို၊ ဘယ်အချိန်ပဲဖြစ်ဖြစ်",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO23.mp3")
    },
    {
        furi: "どこでも",
        normal: "どこでも",
        en: "any place",
        remark_en: "",
        mm: "နေရာမရွေး၊ ဘယ်နေရာမဆို၊ ဘယ်နေရာပဲဖြစ်ဖြစ်",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO24.mp3")
    },
    {
        furi: "だれでも",
        normal: "だれでも",
        en: "anybody",
        remark_en: "",
        mm: "ဘယ်သူမဆို၊ ဘယ်သူပဲဖြစ်ဖြစ်",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO25.mp3")
    },
    {
        furi: "＾何（なん）｜でも",
        normal: "なんでも",
        en: "anything",
        remark_en: "",
        mm: "ဘာမဆို၊ ဘာပဲဖြစ်ဖြစ်",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO26.mp3")
    },
    {
        furi: "こんな～",
        normal: "こんな～",
        en: "~ like this",
        remark_en: "",
        mm: "ဒီလောက်～",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO27.mp3")
    },
    {
        furi: "そんな～",
        normal: "そんな～",
        en: "~ like that ",
        remark_en: " (near the listener)",
        mm: "အဲဒီလောက်～",
        remark_mm: "(နာသူ၏ အနီးကို ညွှန်းရာတွင် သုံးသည်)",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO28.mp3")
    },
    {
        furi: "あんな～",
        normal: "あんな～",
        en: "~ like that",
        remark_en: "(far from both the speaker and the listener)",
        mm: "အဲဒီလောက်～",
        remark_mm: "(ပြောသူနာသူ၂ဦးစလုံးမှ ဝေးကွာရာကို ညွှန်းရာတွင် သုံးသည်)",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO29.mp3")
    },
    {
        furi: "※　エドヤストア",
        normal: "※　エドヤストア",
        en: "Edo store",
        remark_en: "",
        mm: "အဲဒိုစတိုး",
        remark_mm: "(စိတ်ကူးသက်သက်ဖြင့် အမည်တပ်ထားသောဆိုင်)",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO30.mp3")
    },
    {
        furi: "＾片（かた）｜づきます　［　｜＾荷（に）｜＾物（もつ）｜が　～　］",
        normal: "片づきます　［荷物が　～　］",
        en: "put in order",
        remark_en: "",
        mm: "ရှင်းလင်းသည်၊ သိမ်းဆည်းရှင်းလင်းသည် [အထုပ်အပိုး(က)～]",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO31.mp3")
    },
    {
        furi: "＾出（だ）｜します　［ごみを　］",
        normal: "出します　［ごみを　］",
        en: "Pleased to meet you",
        remark_en: "",
        mm: "ထုတ်သည်၊ စွန့်ပစ်သည် [အမှိုက်ကို～]",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO32.mp3")
    },
    {
        furi: "＾燃（も）｜えるごみ",
        normal: "燃えるごみ",
        en: "burnable trash/",
        remark_en: "",
        mm: "မီးလောင်ကျွမ်းနိုင်သောအမှိုက်",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO33.mp3")
    },
    {
        furi: "＾置（お）｜き｜＾場（ば）",
        normal: "置き場",
        en: "place where something is put",
        remark_en: "",
        mm: "ထားရာနေရာ",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO34.mp3")
    },
    {
        furi: "＾横（よこ）",
        normal: "横",
        en: "side",
        remark_en: "",
        mm: "ဘေး၊ နံဘေး",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO35.mp3")
    },
    {
        furi: "＾瓶（びん）",
        normal: "瓶",
        en: "side",
        remark_en: "",
        mm: "ပုလင်း",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO36.mp3")
    },
    {
        furi: "＾缶（かん）",
        normal: "缶",
        en: "can",
        remark_en: "",
        mm: "သံဘူး၊ သတ္တုဘူး၊ အလူမီနီယမ်ဘူး",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO37.mp3")
    },
    {
        furi: "ガス",
        normal: "ガス",
        en: "gas",
        remark_en: "",
        mm: "ဂက်စ်ဓာတ်ငွေ့",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO38.mp3")
    },
    {
        furi: "～｜＾会（かい）社（しゃ）",
        normal: "～会社",
        en: "~ company",
        remark_en: "",
        mm: "～ကုမ္ပဏီ",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson26/vocab/4N26VO39.mp3")
    },
    {
        furi: "＾宇（う）｜＾宙（ちゅう）",
        normal: "宇宙",
        en: "space",
        remark_en: "",
        mm: "အာကသ၊ စင်္ကြာဝဠာ",
        remark_mm: "",
        group: 0
    },
    {
        furi: "＾～様（さま）",
        normal: "～様",
        en: "Mr～, Mrs～",
        remark_en: "",
        mm: "ဦး～၊ ဒေါ်～၊ မောင်～၊ မ～ (～さん၏ ရိုသေလေးစားသောအသုံး)",
        remark_mm: "",
        group: 0
    },
    {
        furi: "＾宇（う）｜＾宙（ちゅう）｜＾船（せん）",
        normal: "宇宙船",
        en: "spaceship",
        remark_en: "",
        mm: "အာကာသယာဉ်",
        remark_mm: "",
        group: 0
    },
    {
        furi: "＾怖（こわ）｜い",
        normal: "怖い",
        en: " be afraid of",
        remark_en: "",
        mm: "ကြောက်သော၊ ကြောက်စရာကောင်းသော",
        remark_mm: "",
        group: 0
    },
    {
        furi: "＾宇（う）｜＾宙（ちゅう）｜ステーション",
        normal: "宇宙ステーション",
        en: "Brazil",
        remark_en: "",
        mm: "အာကာသစခန်း",
        remark_mm: "",
        group: 0
    },
    {
        furi: "＾違（ちが）｜います",
        normal: "違います",
        en: "wrong",
        remark_en: "",
        mm: "လွဲသည်၊ မှားသည်",
        remark_mm: "",
        group: 0
    },
    {
        furi: "＾宇（う）｜＾宙（ちゅう）｜＾飛（ひ）｜＾行（こう）｜＾士（し）｜",
        normal: "宇宙飛行士",
        en: "astronaut",
        remark_en: "",
        mm: "အာကာသယာဉ်မှူး ",
        remark_mm: "",
        group: 0
    },
    {
        furi: "｜＾星（ほし）｜＾出（で）｜＾彰（あき）｜＾彦（ひこ）",
        normal: "星出彰彦",
        en: "Mr. Akihiko Hoshide",
        remark_en: "",
        mm: "ဟိုရှိဒဲအခိဟိကို ",
        remark_mm: "(ဂျပန်မှ အာကာသယာဉ်မှူး (၁၉၆၈-)",
        group: 0
    }
]