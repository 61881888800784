
export const lesson9 = [
    {"lesson":9,"level":4,"question":"顔色","represent":["顔","色"],"answer":["かもく","うちがわ","かおいろ","しゅしょう"],"true":"かおいろ","times":10},
    {"lesson":9,"level":4,"question":"薬局","represent":["薬"],"answer":["あたまきん","やっきょく","びょうにん","かがく"],"true":"やっきょく","times":10},
    {"lesson":9,"level":4,"question":"病気","represent":["病","気"],"answer":["しゅしょう","びょうき","ないか","うちがわ"],"true":"びょうき","times":10},
    {"lesson":9,"level":4,"question":"科目","represent":["科","目"],"answer":["ないりょく","かもく","たいいく","ずつう"],"true":"かもく","times":10},
    {"lesson":9,"level":4,"question":"顔","represent":["顔"],"answer":["こえ","あたま","こころ","かお"],"true":"かお","times":10},
    {"lesson":9,"level":4,"question":"内科","represent":["内","科"],"answer":["ないか","げか","こくない","うちがわ"],"true":"ないか","times":10},
    {"lesson":9,"level":4,"question":"頭金","represent":["頭","金"],"answer":["かもく","びょうき","ないか","あたまきん"],"true":"あたまきん","times":10},
    {"lesson":9,"level":4,"question":"頭","represent":["頭"],"answer":["あたま","かお","こえ","こころ"],"true":"あたま","times":10},
    {"lesson":9,"level":4,"question":"声","represent":["声"],"answer":["こえ","こころ","からだ","かお"],"true":"こえ","times":10},
    {"lesson":9,"level":4,"question":"科学","represent":["科","学"],"answer":["あたまきん","めぐすり","たいいく","かがく"],"true":"かがく","times":10},
    {"lesson":9,"level":4,"question":"家内","represent":["家","内"],"answer":["こくない","かもく","びょうにん","かない"],"true":"かない","times":10},
    {"lesson":9,"level":4,"question":"関心","represent":["心"],"answer":["かんしん","がんめん","とうぶ","こくない"],"true":"かんしん","times":10},
    {"lesson":9,"level":4,"question":"国内","represent":["国","内"],"answer":["こくない","おおごえ","とうぶ","びょうにん"],"true":"こくない","times":10},
    {"lesson":9,"level":4,"question":"病院","represent":["病","院"],"answer":["びょうき","ずつう","めぐすり","びょういん"],"true":"びょういん","times":10},
    {"lesson":9,"level":4,"question":"体","represent":["体"],"answer":["こえ","こころ","からだ","くすり"],"true":"からだ","times":10},
    {"lesson":9,"level":4,"question":"内側","represent":["内"],"answer":["うちがわ","めぐすり","こくない","かもく"],"true":"うちがわ","times":10},
    {"lesson":9,"level":4,"question":"体育","represent":["体"],"answer":["うちがわ","ないか","ずつう","たいいく"],"true":"たいいく","times":10},
    {"lesson":9,"level":4,"question":"大声","represent":["大","声"],"answer":["おおごえ","びょうき","とうぶ","やっきょく"],"true":"おおごえ","times":10},
    {"lesson":9,"level":4,"question":"頭部","represent":["頭"],"answer":["こくない","しゅしょう","とうぶ","かもく"],"true":"とうぶ","times":10},
    {"lesson":9,"level":4,"question":"顔面","represent":["顔"],"answer":["うちがわ","かがく","がんめん","しゅしょう"],"true":"がんめん","times":10},
    {"lesson":9,"level":4,"question":"心","represent":["心"],"answer":["くすり","あたま","かお","こころ"],"true":"こころ","times":10},
    {"lesson":9,"level":4,"question":"目薬","represent":["目","薬"],"answer":["かんしん","びょういん","めぐすり","かもく"],"true":"めぐすり","times":10},
    {"lesson":9,"level":4,"question":"病人","represent":["病","人"],"answer":["びょうにん","うちがわ","たいいく","かんしん"],"true":"びょうにん","times":10},
    {"lesson":9,"level":4,"question":"外科","represent":["外","科"],"answer":["かんしん","びょうき","びょうにん","げか"],"true":"げか","times":10},
    {"lesson":9,"level":4,"question":"薬","represent":["薬"],"answer":["あたま","くすり","からだ","こころ"],"true":"くすり","times":10},
    {"lesson":9,"level":4,"question":"首相","represent":["首"],"answer":["こくない","びょうにん","しゅしょう","かんしん"],"true":"しゅしょう","times":10},
    {"lesson":9,"level":4,"question":"体力","represent":["体","力"],"answer":["こくない","たいりょく","ずつう","うちがわ"],"true":"たいりょく","times":10},
]