import { MojiType } from "..";

export const moji: MojiType = {
  mondai1:{
    title: 'もんだい１＿＿＿＿の　ことばは　ひらがなで　どう　かきますか。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 1,
        question: 'ちょっと　｜！味｜が　へんですよ。',
        answers: ['こえ','あじ','おと','におい'],
        pick: '',
        correct: 'あじ'
      },
      {
        number: 2,
        question: 'この　ビルは　｜！世界｜で　いちばん　高いです。',
        answers: ['せいがい','せいかい','せがい','せかい'],
        pick: '',
        correct: 'せかい'
      },
      {
        number: 3,
        question: 'それは　たなかさんが　｜！考えて｜　くれました。',
        answers: ['かぞえて','こたえて','かんがえて','つたえて'],
        pick: '',
        correct: 'かんがえて'
      },
      {
        number: 4,
        question: 'じしょが　買いたかったのに、お金が　｜！足りません｜でした。',
        answers: ['たりません','あしりません','たしりません','ありません'],
        pick: '',
        correct: 'たりません'
      },
      {
        number: 5,
        question: 'わたしの　かぞくは　みんな　｜！体｜が　じょうぶです。',
        answers: ['かたら','からた','かだら','からだ'],
        pick: '',
        correct: 'からだ'
      },
      {
        number: 6,
        question: '｜！今度｜の　日よう日に　きこくします。',
        answers: ['こんどう','こんど','こんと','こんとう'],
        pick: '',
        correct: 'こんど'
      },
      {
        number: 7,
        question: 'あしたの　｜！営業｜は　あさ　９時からです。',
        answers: ['じゅぎょう','えいきょう','えいぎょう','じゅきょう'],
        pick: '',
        correct: 'えいぎょう'
      },
      {
        number: 8,
        question: 'ずっと　まどから　｜！雲｜を　見ていました。',
        answers: ['くも','ほし','つき','そら'],
        pick: '',
        correct: 'くも'
      },
      {
        number: 9,
        question: 'まいあさ　｜！近所｜の　こうえんで　ジョギングを　しています。',
        answers: ['きんじょ','ちかじょ','きんじょう','ちかじょう'],
        pick: '',
        correct: 'きんじょ'
      },
    ]
  },
  mondai2:{
    title: 'もんだい２＿＿＿＿の　ことばは　どう　かきますか。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 10,
        question: 'この　｜！くすり｜は　いくらですか。',
        answers: ['果','楽','菓','薬'],
        pick: '',
        correct: '薬'
      },
      {
        number: 11,
        question: 'きのうは　９時に　｜！おきました｜。',
        answers: ['赴きました','題きした','起きました','匙きました'],
        pick: '',
        correct: '起きました'
      },
      {
        number: 12,
        question: '｜！だんせい｜の　おてあらいは　あちらです。',
        answers: ['男姓','女性','男性','女姓'],
        pick: '',
        correct: '男性'
      },
      {
        number: 13,
        question: 'この　スイッチを　｜！おせば｜、電池が　つきます。',
        answers: ['伸せば','挿せば','押せば','坤せば'],
        pick: '',
        correct: '押せば'
      },
      {
        number: 14,
        question: '国の　りょうしんに　にもつを　｜！おくりました｜。',
        answers: ['込りました','返りました','速りました','送りました'],
        pick: '',
        correct: '送りました'
      },
      {
        number: 15,
        question: 'あには　車の｜！うんてん｜が　できます。',
        answers: ['運転','運軽','連転','連軽'],
        pick: '',
        correct: '運転'
      },
    ]
  },
  mondai3:{
    title: 'もんだい３（　　）に　なにを　いれますか。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 16,
        question: 'とても　行きたかったのに、りょこうが　ちゅうしに　なって、（　　）です。',
        answers: ['ざんねん','ふべん','だめ','しんぱい'],
        pick: '',
        correct: 'ざんねん'
      },
      {
        number: 17,
        question: 'なくした　かぎを（　　）けど、どこにも　ないんです。',
        answers: ['しらべた','さがした','みつけた','あつめた'],
        pick: '',
        correct: 'さがした'
      },
      {
        number: 18,
        question: 'スポーツの　あとで　シャワーを　あびるのは（　　）がいいです。',
        answers: ['ぐあい','こころ','きもち','げんき'],
        pick: '',
        correct: 'きもち'
      },
      {
        number: 19,
        question: '日本へ　りょこうに行った　とき、いろいろな（　　）を　しました。',
        answers: ['けいけん','おもいで','おみやげ','きょうみ'],
        pick: '',
        correct: 'けいけん'
      },
      {
        number: 20,
        question: '雨が　ふっているので、さんぽに　行くのは（　　）。',
        answers: ['とじました','すてました','しめました','やめました'],
        pick: '',
        correct: 'やめました'
      },
      {
        number: 21,
        question: 'にもつが　多いので、やまださんが　ひっこしを（　　）くれました。',
        answers: ['はこんで','てつだって','かたづけて','つつんで'],
        pick: '',
        correct: 'てつだって'
      },
      {
        number: 22,
        question: 'もりさんに、先週　学校に　来なかった（　　）を　聞きました。',
        answers: ['れんらく','りゆう','いけん','そうだん'],
        pick: '',
        correct: 'りゆう'
      },
      {
        number: 23,
        question: 'どうぞ（　　）しないで、たくさん　食べて　ください。',
        answers: ['しつれい','えんりょ','ちゅうい','はんたい'],
        pick: '',
        correct: 'えんりょ'
      },
      {
        number: 24,
        question: 'えさを　やったり、さんぽに　つれて　いったり、毎日　犬の（　　）を　して　います。',
        answers: ['せわ','うんどう','ようい','しゅうかん'],
        pick: '',
        correct: 'せわ'
      },
      {
        number: 25,
        question: 'ねつが　あるので、びょういんに（　　）から　会社に　行きます。',
        answers: ['もどって','よって','まがって','かよって'],
        pick: '',
        correct: 'よって'
      },
    ]
  },
  mondai4:{
    title: 'もんだい4＿＿＿＿の ぶんと　だいたい　おなじ　いみの　ぶんが　あります。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 26,
        question: 'おとうとは　あそこで　アルバイトを　しています。',
        answers: ['おとうとは　あそこで　まっています。','おとうとは　あそこで　やすんでいます。','おとうとは　あそこで　あそんでいます。','おとうとは　あそこで　はたらいています。'],
        pick: '',
        correct: 'おとうとは　あそこで　はたらいています。'
      },
      {
        number: 27,
        question: 'あの　人は　ほんとうに　うつくしいですね。',
        answers: ['あの　人は　ほんとうに　きれいですね。','あの　人は　ほんとうに　おもしろいですね。','あの　人は　ほんとうに　わかいですね。','あの　人は　ほんとうに　かわいいですね。'],
        pick: '',
        correct: 'あの　人は　ほんとうに　きれいですね。'
      },
      {
        number: 28,
        question: 'あの　店は　すいて　います。',
        answers: ['あの　店は　しなものが　少ないです。','あの　店は　おきゃくさんが　多いです。','あの　店は　おきゃくさんが　少ないです。','あの　店は　しなものが　多いです。'],
        pick: '',
        correct: 'あの　店は　おきゃくさんが　少ないです。'
      },
      {
        number: 29,
        question: 'この　シャワーは　ぬるいです。',
        answers: ['この　シャワーは　あつくないです。','この　シャワーは　あついです。','この　シャワーは　つよいです。','この　シャワーは　つよくないです。'],
        pick: '',
        correct: 'この　シャワーは　あつくないです。'
      },
      {
        number: 30,
        question: '友だちに　日本の　りょうりを　おそわりました。',
        answers: ['友だちに　日本の　りょうりを　もらいました。','友だちに　日本の　りょうりを　おしえました。','友だちに　日本の　りょうりを　あげました。','友だちに　日本の　りょうりを　ならいました。'],
        pick: '',
        correct: '友だちに　日本の　りょうりを　ならいました。'
      },
    ]
  },
  mondai5:{
    title: 'もんだい５　つぎの ことばの　つかいかたで　いちばん　いい　ものを　１・２・３・４から　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 31,
        question: '　けしき',
        answers: [
          '先週　えきまえに　できた　店は、中の　｜！けしき｜が　とても　あかるいです。',
          'わたしは　かぞくの　たのしそうな　｜！けしき｜を　いつも　ビデオで　とっています。',
          'この　まちは　やまも　川も　あって、｜！けしき｜がとても　いいです。',
          '子どもの｜！けしき｜が　へんだったので、びょういんに　つれて　いきました。',
        ],
        pick: '',
        correct: 'この　まちは　やまも　川も　あって、｜！けしき｜がとても　いいです。'
      },
      {
        number: 32,
        question: '　おどろく',
        answers: [
          'みんなの　前で　話す　とき、いつも　｜！おどろいて｜　じょうずに　話せませえん。',
          'やまださんの　おにいさんが　ゆうめいな　かしゅだと　きいて、｜！おどろき｜ました。',
          'ねる　まえに　コーヒーを　飲むと、｜！おどろいて｜　ねもらません。',
          'あしたは　はじめての　デートなので、いまから｜！おどろいて｜います。',
        ],
        pick: '',
        correct: 'やまださんの　おにいさんが　ゆうめいな　かしゅだと　きいて、｜！おどろき｜ました。'
      },
      {
        number: 33,
        question: '　ひろう',
        answers: [
          'みちが　わからなく　なったので、かばんから　ちずを　｜！ひろいました｜。',
          'すみません、たなの　上の　はこを　｜！ひろって｜　くれませんか。',
          '木から　りんごを　｜！ひろって｜、ふくろに　入れました。',
          '小学生が　ごみを｜！ひろって｜、こうえんを　そうじして　います。',
        ],
        pick: '',
        correct: '小学生が　ごみを｜！ひろって｜、こうえんを　そうじして　います。'
      },
      {
        number: 34,
        question: '　しょうたい',
        answers: [
          '119ばんに　電話して、きゅうきゅうしゃを　いえに｜！しょうたい｜してください。',
          'ぶちょうに　｜！しょうたい｜されて、休みの　日に　会社に　行きました。',
          '大学の　きょうはいを　けっこんしきに　｜！しょうたい｜する　つもりです。',
          'いしゃに　｜！しょうたい｜されて、あしたから　にゅういんする　ことに　なりました。',
        ],
        pick: '',
        correct: '大学の　きょうはいを　けっこんしきに　｜！しょうたい｜する　つもりです。'
      },
      {
        number: 35,
        question: '　わたす',
        answers: [
          '毎日　にわの　花に　水を　｜！わたして｜います。',
          'この　きっぷは、かいさつぐちを　出る　とき、えきいんに　｜！わたして｜ください。',
          'この　てがみを　ポストに　｜！わたして｜　きて　ください。',
          'わたしは　いつも　こうちゃに　さとうと　ぎゅうにゅうを　｜！わたします｜。',
        ],
        pick: '',
        correct: 'この　きっぷは、かいさつぐちを　出る　とき、えきいんに　｜！わたして｜ください。'
      },
    ]
  }
}