export const lesson12 = {
    "家": 110,
    "族": 111,
    "私": 112,
    "自": 113,
    "親": 114,
    "両": 115,
    "兄": 116,
    "弟": 117,
    "姉": 118,
    "妹": 119
};