import CategoryViewBtn from "@components/common/CategoryViewBtn"
import { Minna } from "@consts"
import { Card } from "@material-tailwind/react"
import { memo } from "react"
interface Kanji {
    kanji : String | null
}
const Kanji = ({ cagetories, id }: any) => {
    id = id<4? id : id>18? id-4: id>13? id-3: id>8? id-2 : id-1;
    const getKanjiList = ():Array<Kanji>|any => {
        let list:Array<Kanji> = [];
        Minna.kanji.getLesson(id + 1).map(({ kanji }, index) => {
            if(index === 8) list = [...list,{ kanji:null}]
            list = [...list, { kanji }]
        });
        return list;
    }
    return <Card onClick={cagetories[3].onclick}
        //  h-[250px] md:h-[300px] 
        className="hover:scale-105 transition duration-500 flex-1 flex flex-col md:h-[250px] justify-between rounded-[20px] text-[16px] md:text-[22px] btn px-4 py-6 md:p-6 dark:bg-darkFooter dark-shadow ">
        <div className="flex flex-row items-center md:justify-center">
            <div className=""><img src={cagetories[3].Icon} /></div>
            <div className=" text-black dark:text-white font-semibold ml-[10px] md:ml-[20px]">{cagetories[3].label}</div>
        </div>
        <div className="grid grid-cols-4 gap-2  md:mx-3 lg:mx-8 text-[12px] md:text-[16px] lg:text-[20px]">
            {
                getKanjiList().map(({kanji}:Kanji,index:number) => {
                    return kanji === null ? <div key={index}></div>: <div key={index} className="flex flex-row items-center justify-center">
                            <div className="flex flex-row items-center justify-center w-[20px] h-[20px] md:w-[26px] md:h-[26px] lg:w-[33px] lg:h-[33px] bg-yellow rounded-full text-white ">
                                {kanji}
                            </div>
                    </div>
                })
            }
        </div>
        <div>
            <CategoryViewBtn cagetories={cagetories} />
        </div>
        {/* <SizeBox vertical="h-10" /> */}
    </Card>
}
export default memo(Kanji);