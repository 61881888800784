export const renshuuA = [
    {
        isTable: true,
        tables: [
            {
                head: {
                    a: {
                        val:"",
                        span: 1,
                        style: ""
                    },
                    b: {
                        val:"",
                        span: 3,
                        style: ""
                    },
                    c: {
                        val: "＾可（か）能（のう）",
                        span: 3,
                        style: ""
                    }
                },
                body: [
                    {
                        no: "I",
                        l1: "うた",
                        l2: "い",
                        l3: "ます",
                        r1: "うた",
                        r2: "え",
                        r3:"ます"

                    },
                    {
                        no: "",
                        l1: "ひ",
                        l2: "き",
                        l3: "ます",
                        r1: "ひ",
                        r2: "け",
                        r3:"ます"
                    },                
                    {
                        no: "",
                        l1: "およ",
                        l2: "ぎ",
                        l3: "ます",
                        r1: "およ",
                        r2: "げ",
                        r3:"ます"
                    },                
                    {
                        no: "",
                        l1: "も",
                        l2: "ち",
                        l3: "ます",
                        r1: "も",
                        r2: "て",
                        r3:"ます"
                    },                
                    {
                        no: "",
                        l1: "あそ",
                        l2: "び",
                        l3: "ます",
                        r1: "あそ",
                        r2: "べ",
                        r3:"ます"
                    },                
                    {
                        no: "",
                        l1: "よ",
                        l2: "み",
                        l3: "ます",
                        r1: "よ",
                        r2: "め",
                        r3:"ます"
                    },                
                    {
                        no: "",
                        l1: "はし",
                        l2: "り",
                        l3: "ます",
                        r1: "はし",
                        r2: "れ",
                        r3:"ます"
                    }                
                ],
                style: {
                    no: "p-2 w-[50px] text-right bg-white dark:bg-darkFooter",
                    l1: "p-2 text-right bg-white dark:bg-darkFooter",
                    l2: "p-2 w-[30px] text-right bg-[#729eff] dark:bg-[#003257]",
                    l3: "p-2 text-left bg-white dark:bg-darkFooter",
                    r1: "p-2 text-right bg-white dark:bg-darkFooter",
                    r2: "p-2 w-[30px] text-right bg-[#729eff] dark:bg-[#003257]",
                    r3: "p-2 text-left bg-white dark:bg-darkFooter"
                }
            },
            {
                head: {
                    a: {
                        val:"",
                        span: 1,
                        style: ""
                    },
                    b: {
                        val:"",
                        span: 2,
                        style: ""
                    },
                    c: {
                        val: "＾可（か）能（のう）",
                        span: 3,
                        style: ""
                    }
                },
                body: [
                    {
                        no: "II",
                        l1: "しらべ",
                        l3: "ます",
                        r1: "しらべ",
                        r2: "られ",
                        r3:"ます"
                    },
                    {
                        no: "",
                        l1: "おぼえ",
                        l3: "ます",
                        r1: "おぼえ",
                        r2: "られ",
                        r3:"ます"
                    },
                    {
                        no: "",
                        l1: "おり",
                        l3: "ます",
                        r1: "おり",
                        r2: "られ",
                        r3:"ます"
                    },
                               
                ],
                style: {
                    no: "p-2 w-[50px] text-right bg-white dark:bg-darkFooter",
                    l1: "p-2 text-right bg-white dark:bg-darkFooter",
                    l3: "p-2 text-left bg-white dark:bg-darkFooter",
                    r1: "p-2 text-right bg-white dark:bg-darkFooter",
                    r2: "p-2 w-[60px] text-center bg-[#729eff] dark:bg-[#003257]",
                    r3: "p-2 text-left bg-white dark:bg-darkFooter"
                }
            },
            {
                head: {
                    a: {
                        val:"",
                        span: 1,
                        style: ""
                    },
                    b: {
                        val:"",
                        span: 2,
                        style: ""
                    },
                    c: {
                        val: "＾可（か）能（のう）",
                        span: 2,
                        style: ""
                    }
                },
                body: [
                    {
                        no: "III",
                        l1: "き",
                        l3: "ます",
                        r2: "こられ",
                        r3:"ます"
                    },
                    {
                        no: "",
                        l1: "し",
                        l3: "ます",
                        r2: "*でき",
                        r3:"ます"
                    },                              
                ],
                style: {
                    no: "p-2 w-[50px] text-right bg-white dark:bg-darkFooter",
                    l1: "p-2 text-right bg-[#729eff] dark:bg-[#003257] ",
                    l3: "p-2 text-left bg-white dark:bg-darkFooter",
                    r2: "p-2 text-right bg-[#729eff] dark:bg-[#003257]",
                    r3: "p-2 text-left bg-white dark:bg-darkFooter"
                }
            }
        ],
        audioUrl: require("@assets/audio/lesson27/renshua/4N27RSA1.mp3")
    },
    {
        sentence: [
            { 
                //｜！旅（りょ）行（こう）なん｜
                furi: "わたしは　はし｜！が｜　｜つかえます。",
                normal: "わたしは　はしが　つかえます。",
                en: "I can use chopsticks",
                mm: "ငါ တူသုံးတတ်တယ်။ ",
            },
            {
                furi: "わたしは　さしみ｜！が｜たべられます。",
                normal: "わたしは　さしみが　たべられます。",
                en: "I can eat sashimi.",
                mm: "ငါ ဆာရှီမီ စားတတ်တယ်။ ",
            },
        ],
        audioUrl: require("@assets/audio/lesson27/renshua/4N27RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "２｜＾皆（かい）｜から　はなび｜！が｜みえます｜。",
                normal: "２階から　はなびが　みえます。",
                en: "You can see the fireworks from the second floor.",
                mm: "မီးရှူးမီးပန်းတွေကို ဒုတိယထပ်ကနေ မြင်နိုင်ပါတယ်။",
            },
            {
                furi: "＾隣（とな）｜りの　｜＾部（へ）｜＾屋（や）｜から　こえ｜！が｜ きこえます。",
                normal: "隣りの　部屋から　こえ｜きこえます。",
                en: "I can hear voices from the next room.",
                mm: "ဘေးခန်းကနေ အသံတွေကြားရတယ်။",
            }
        ],
        audioUrl: require("@assets/audio/lesson27/renshua/4N27RSA3.mp3")
    },
    {
        sentence: [
            {
                furi: "＾新（あたら）｜しい　いえ｜！が｜できました。",
                normal: "新しい　いえ　ができました。",
                en: "A new house has been built.",
                mm: "အိမ်အသစ်ဆောက်ပြီးသွားပြီ။",
            },
            {
                furi: "ばんごはん｜！が｜できました。",
                normal: "ばんごはん　ができました。",
                en: "Dinner has been finished.",
                mm: "ညစာစီစဉ်ပြီးသွားပါပြီ။",
            }
        ],
        audioUrl: require("@assets/audio/lesson27/renshua/4N27RSA4.mp3")
    },
    {
        sentence: [
            {
                furi: "わたしは　ひらがな　｜！しか｜わかりません。",
                normal: "わたしは　ひらがな　しかわかりません。",
                en: "I only understand hiragana.",
                mm: "ကျွန်တော် ဟီရာဂန လောက်ပဲ နားလည်တယ်။",
            },
            {
                furi: "わたしは｜＾日（に）本（ほん）語（ご）｜が　すこし｜！しか｜はなせません。",
                normal: "わたしは 日本語が　すこししかはなせません。",
                en: "I can speak only a little Japanese.",
                mm: "ကျွန်တော် ဂျပန်စကား အနည်းငယ်သာ ပြောတတ်ပါသည်။",
            },
            {
                furi: "わたしは きのう｜＾日（に）｜＾本（ほん）｜＾語（ご）｜を　１じかん｜！しか｜べんきょうしませんでした。",
                normal: "わたしは きのう　日本語を　１じかんしか　べんきょうしませんでした。",
                en: "I studied Japanese for only one hour yesterday.",
                mm: "ကျွန်တော် မနေ့က ဂျပန်စာကို တစ်နာရီပဲ လေ့လာခဲ့တယ်။",
            }
        ],
        audioUrl: require("@assets/audio/lesson27/renshua/4N27RSA5.mp3")
    },
     {
        sentence: [
            {
                furi: "サッカー｜！は｜します　｜！が｜やきゅう｜！は｜しません。",
                normal: "サッカーはします　がやきゅうはしません。",
                en: "I play soccer, but not baseball.",
                mm: "ကျွန်တော် ဘောလုံးကစားပေမယ့် ဘေ့စ်ဘောတော့ မကစားပါဘူး။",
             },
             {
                 furi: "ひらがな｜！は｜かけます｜！が｜かんじ｜！は｜かけはせん。",
                 normal: "ひらがなは　かけますが　かんじは　かけはせん。",
                 en: "I can write Hiragana, but I can't write Kanji.",
                 mm: "ဟီရာဂနကို ရေးနိုင်ပေမယ့် Kanji မရေးနိုင်ဘူး။",
             },
             {
                 furi: "やま｜！は｜　みえます｜！が｜　うみ｜！は｜　みえません。",
                 normal: "やまは｜　みえますが　うみは　みえません。",
                 en: "I can see the mountains, but I can't see the sea.",
                 mm: "တောင်တွေကို မြင်နိုင်ပေမယ့် ပင်လယ်ကို မမြင်ရဘူး။",
             }
        ],
         audioUrl: require("@assets/audio/lesson27/renshua/4N27RSA6.mp3")
    },
];

/*
    {
        sentence: [
            {
                furi: "",
                normal: "",
                en: "",
                mm: "",
            },
        ],
        audioUrl: require("@assets/audio/testing.mp3")
    },

 // furi: 'あしたから　｜！旅（りょ）行（こう）なん｜です。',
        // normal: 'あしたから　旅行　なんです。',
        // en: 'It\'s a trip from tomorrow.',
        // mm: 'မနက်ဖြန်ကနေစပြီး ခရီးထွက်ပါမယ်။',
        //


*/