
export const lesson6 = [
    {"lesson":6,"level":4,"question":"のうぎょう","represent":["業"],"answer":["農業","産業","事故","室内"],"true":"農業","times":10},
    {"lesson":6,"level":4,"question":"いいん","represent":["医","院"],"answer":["医者","用事","銀行","医院"],"true":"医院","times":10},
    {"lesson":6,"level":4,"question":"てんいん","represent":["店","員"],"answer":["水銀","定員","仕事","店員"],"true":"店員","times":10},
    {"lesson":6,"level":4,"question":"しょうぎょう","represent":["業"],"answer":["仕事","食事","教室","商業"],"true":"商業","times":10},
    {"lesson":6,"level":4,"question":"ていいん","represent":["員"],"answer":["医者","会員","銀行","定員"],"true":"定員","times":10},
    {"lesson":6,"level":4,"question":"かいいん","represent":["会","員"],"answer":["会員","室内","水銀","医者"],"true":"会員","times":10},
    {"lesson":6,"level":4,"question":"ぎんざ","represent":["銀"],"answer":["銀座","事故","仕事","食事"],"true":"銀座","times":10},
    {"lesson":6,"level":4,"question":"しつない","represent":["室","内"],"answer":["室内","食事","医者","農業"],"true":"室内","times":10},
    {"lesson":6,"level":4,"question":"きょうしつ","represent":["教","室"],"answer":["仕方","教室","室内","農業"],"true":"教室","times":10},
    {"lesson":6,"level":4,"question":"さんち","represent":["産","地"],"answer":["農業","工業","産地","医院"],"true":"産地","times":10},
    {"lesson":6,"level":4,"question":"ようじ","represent":["用","事"],"answer":["教室","用事","仕事","農業"],"true":"用事","times":10},
    {"lesson":6,"level":4,"question":"こうぎょう","represent":["工","業"],"answer":["定員","用事","医院","工業"],"true":"工業","times":10},
    {"lesson":6,"level":4,"question":"しごと","represent":["仕","事"],"answer":["仕事","医学","銀座","仕方"],"true":"仕事","times":10},
    {"lesson":6,"level":4,"question":"しょくじ","represent":["食","事"],"answer":["定員","用事","室内","食事"],"true":"食事","times":10},
    {"lesson":6,"level":4,"question":"しかた","represent":["仕","方"],"answer":["工業","事故","仕方","農業"],"true":"仕方","times":10},
    {"lesson":6,"level":4,"question":"いしゃ","represent":["医","者"],"answer":["教室","店員","工業","医者"],"true":"医者","times":10},
    {"lesson":6,"level":4,"question":"さんぎょう","represent":["産","業"],"answer":["店員","仕方","産業","室内"],"true":"産業","times":10},
    {"lesson":6,"level":4,"question":"ぎんこう","represent":["銀","行"],"answer":["農業","銀行","仕方","商業"],"true":"銀行","times":10},
    {"lesson":6,"level":4,"question":"いがく","represent":["医","学"],"answer":["室内","銀行","医学","産地"],"true":"医学","times":10},
    {"lesson":6,"level":4,"question":"すいぎん","represent":["水","銀"],"answer":["農業","水銀","銀行","仕事"],"true":"水銀","times":10},
    {"lesson":6,"level":4,"question":"がくしゃ","represent":["学","者"],"answer":["仕事","仕方","医院","学者"],"true":"学者","times":10},
    {"lesson":6,"level":4,"question":"じこ","represent":["事"],"answer":["定員","工業","事故","産業"],"true":"事故","times":10},
]