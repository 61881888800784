export const lesson7 = {
    "林": 60,
    "森": 61,
    "地": 62,
    "池": 63,
    "海": 64,
    "洋": 65,
    "雪": 66,
    "光": 67,
    "台": 68,
    "風": 69
};