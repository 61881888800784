
export const lesson10 = [
    {"lesson":10,"level":4,"question":"以前","represent":["以","前"],"answer":["ちょうしょく","ひるね","こんばん","いぜん"],"true":"いぜん","times":10},
    {"lesson":10,"level":4,"question":"晩","represent":["晩"],"answer":["ばん","かた","あさ","よる"],"true":"ばん","times":10},
    {"lesson":10,"level":4,"question":"月曜日","represent":["月","曜","日"],"answer":["むくようび","げつようび","ばんごはん","たいおんけい"],"true":"げつようび","times":10},
    {"lesson":10,"level":4,"question":"木曜日","represent":["木","曜","日"],"answer":["もくようび","かようび","すいようび","ばんごはん"],"true":"もくようび","times":10},
    {"lesson":10,"level":4,"question":"時計","represent":["時","計"],"answer":["とけい","いちど","やしょく","さまかた"],"true":"とけい","times":10},
    {"lesson":10,"level":4,"question":"体温計","represent":["体","計"],"answer":["ばんごはん","げつようび","かようび","たいおんけい"],"true":"たいおんけい","times":10},
    {"lesson":10,"level":4,"question":"夜食","represent":["夜","食"],"answer":["やしょく","ほうほう","ちゅうしょく","こんや"],"true":"やしょく","times":10},
    {"lesson":10,"level":4,"question":"今夜","represent":["今","夜"],"answer":["ひるね","ゆうがた","やしょく","こんや"],"true":"こんや","times":10},
    {"lesson":10,"level":4,"question":"今晩","represent":["今","晩"],"answer":["たなばた","そくど","いじょう","こんばん"],"true":"こんばん","times":10},
    {"lesson":10,"level":4,"question":"朝食","represent":["朝","食"],"answer":["そくど","ちょうしょく","やしょく","さまかた"],"true":"ちょうしょく","times":10},
    {"lesson":10,"level":4,"question":"夕食","represent":["夕","食"],"answer":["ほうほう","ゆうしょく","いか","いちど"],"true":"ゆうしょく","times":10},
    {"lesson":10,"level":4,"question":"以上","represent":["以","上"],"answer":["いちど","いじょう","いか","ゆうしょく"],"true":"いじょう","times":10},
    {"lesson":10,"level":4,"question":"昼","represent":["昼"],"answer":["ひる","かた","ばん","あさ"],"true":"ひる","times":10},
    {"lesson":10,"level":4,"question":"速度","represent":["度"],"answer":["そくど","いじょう","こんばん","ちゅうしょく"],"true":"そくど","times":10},
    {"lesson":10,"level":4,"question":"朝","represent":["朝"],"answer":["よる","ひる","あさ","かた"],"true":"あさ","times":10},
    {"lesson":10,"level":4,"question":"昼食","represent":["昼","食"],"answer":["こんや","ちゅうしょく","とけい","たなばた"],"true":"ちゅうしょく","times":10},
    {"lesson":10,"level":4,"question":"温度","represent":["度"],"answer":["いちど","おんど","いぜん","さまかた"],"true":"おんど","times":10},
    {"lesson":10,"level":4,"question":"朝日","represent":["朝","日"],"answer":["ちょうしょく","そくど","ゆうがた","あさひ"],"true":"あさひ","times":10},
    {"lesson":10,"level":4,"question":"以後","represent":["以","後"],"answer":["いご","とけい","ゆうしょく","ひるね"],"true":"いご","times":10},
    {"lesson":10,"level":4,"question":"昼寝","represent":["昼"],"answer":["ちゅうしょく","そくど","たなばた","ひるね"],"true":"ひるね","times":10},
    {"lesson":10,"level":4,"question":"様方","represent":["方"],"answer":["さまかた","あさひ","たなばた","こんや"],"true":"さまかた","times":10},
    {"lesson":10,"level":4,"question":"夜","represent":["夜"],"answer":["よる","ひる","あさ","ばん"],"true":"よる","times":10},
    {"lesson":10,"level":4,"question":"今度","represent":["今","度"],"answer":["とけい","こんや","こんど","おんど"],"true":"こんど","times":10},
    {"lesson":10,"level":4,"question":"一度","represent":["一","度"],"answer":["いちど","ゆうしょく","とけい","さまかた"],"true":"いちど","times":10},
    {"lesson":10,"level":4,"question":"水曜日","represent":["水","曜","日"],"answer":["げつようび","すいようび","たいおんけい","かようび"],"true":"すいようび","times":10},
    {"lesson":10,"level":4,"question":"夕方","represent":["夕","方"],"answer":["ちょうしょく","ゆうしょく","ゆうがた","とけい"],"true":"ゆうがた","times":10},
    {"lesson":10,"level":4,"question":"晩御飯","represent":["晩","飯"],"answer":["ばんごはん","かようび","すいようび","もくようび"],"true":"ばんごはん","times":10},
    {"lesson":10,"level":4,"question":"七夕","represent":["七","夕"],"answer":["たなばた","ひるね","ゆうがた","ゆうしょく"],"true":"たなばた","times":10},
    {"lesson":10,"level":4,"question":"方法","represent":["方"],"answer":["ひるね","おんど","ほうほう","ちゅうしょく"],"true":"ほうほう","times":10},
    {"lesson":10,"level":4,"question":"火曜日","represent":["火","曜","日"],"answer":["ばんごはん","たいおんけい","かようび","げつようび"],"true":"かようび","times":10},
    {"lesson":10,"level":4,"question":"以下","represent":["以","下"],"answer":["ちょうしょく","ゆうがた","いか","おんど"],"true":"いか","times":10},
    {"lesson":10,"level":4,"question":"方","represent":["方"],"answer":["かた","よる","ひる","ばん"],"true":"かた","times":10},
]