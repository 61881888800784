export const bunkei= [
    {
        sentence: [
            {
                furi: '＾急（いそ）｜げ',
                normal: '急げ',
                en: 'hurry up',
                mm: 'မြန်မြန်လုပ်ပါ',
            }
        ],
        audioUrl: require("@assets/audio/lesson33/bunpou/4N33BK1.mp3")
    },
    {
        sentence: [
            {
                furi: '＾触（さわ）｜るな。',
                normal: '触るな。',
                en: "don't touch",
                mm: 'မထိနဲ့',
            }
        ],
        audioUrl: require("@assets/audio/lesson33/bunpou/4N33BK2.mp3")
    },
    {
        sentence: [
            {
                furi: '＾立（た）｜ち｜＾入（い）｜り｜＾禁（きん）止（し）｜は　｜＾入（はい）｜るなと　いう　｜＾意（い）味（み）｜です。',
                normal: '立ち入り禁止は　入るなと　いう　意味です。',
                en: 'No entry means do not enter.',
                mm: '立ち入り禁止 ဆိုသည်မှာ မဝင်ရပါ။',
            }
        ],
        audioUrl: require("@assets/audio/lesson33/bunpou/4N33BK3.mp3")
    },
    {
        sentence: [
            {
                furi: 'ミラーさんは　｜＾来（らい）週（しゅう）｜　｜＾大（おお）阪（さか）｜へ　｜＾出（しゅっ）張（ちょう）｜すると　｜＾言（い）｜って　いました。',
                normal: 'ミラーさんは　来週　大阪へ　出張すると　言って　いました。',
                en: 'Mr. Miller said he will go on a business trip to Osaka next week.',
                mm: 'မစ္စတာ Miller က သူသည် လာမည့်အပတ်တွင် အိုဆာကာသို့ အလုပ်ကိစ္စဖြင့် ခရီးထွက်မည်ဟု ပြောကြားခဲ့သည်။',
            }
        ],
        audioUrl: require("@assets/audio/lesson33/bunpou/4N33BK4.mp3")
    },
]