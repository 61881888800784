import Helper from "@helper";

export const fukushuu3 = {
    mondai1: Helper.shuffle(
        [
            {
                question: "やっと　｜＾日（に）本（ほん）｜の　｜＾生（せい）活（かつ）｜＄０｜＾慣（な）｜れました。",
                picked: [""],
                answers:Helper.shuffle(["が","に","を","は"]),
                correct: ["に"],
            },
            {
                question: "＾夜（よる）｜　１１｜＾時（じ）｜＄０｜＾過（す）｜ぎたら、｜＾電（でん）話（わ）｜をかけないようにしてください。",
                picked: [""],
                answers:Helper.shuffle(["に","が","を","へ"]),
                correct: ["を"],
            },
            {
                question: "いつも　あの｜＾公（こう）園（えん）｜＄０｜＾通（とお）｜って　｜＾帰（かえ）｜ります。",
                picked: [""],
                answers:Helper.shuffle(["を","へ","に","で"]),
                correct: ["を"],
            },
            {
                question: "＾食（た）｜べ｜＾物（もの）｜＄０｜＾腐（くさ）｜らないように、｜＾冷（れい）蔵（ぞう）庫（こ）｜に　｜＾入（い）｜れて　おきます。",
                picked: [""],
                answers:Helper.shuffle(["が","を","に","は"]),
                correct: ["が"],
            },
            {
                question: "わたしは　｜＾林（はやし）｜さん｜＄０｜＾映（えい）画（が）｜＄１｜＾誘（さそ）｜いました。",
                picked: ["",""],
                answers:Helper.shuffle(["を","に","が","へ"]),
                correct: ["を","に"],
            },
            {
                question: "わたしは　｜＾母（はは）｜＄０｜マンガの　｜＾本（ほん）｜＄１｜＾捨（す）｜てられました。",
                picked: ["",""],
                answers:Helper.shuffle(["に","を","が","は"]),
                correct: ["に","を"],
            },
            {
                question: "＾大（おお）阪（さか）｜＄０｜＾国（こく）際（さい）会（かい）議（ぎ）｜＄１｜＾開（ひら）｜かれます。",
                picked: ["",""],
                answers:Helper.shuffle(["で","が","に","は"]),
                correct: ["で","が"],
            },
            {
                question: "＾日（に）本（ほん）｜の　お｜＾酒（さけ）｜は　｜＾米（こめ）｜＄０｜＾造（つく）｜られます。",
                picked: [""],
                answers:Helper.shuffle(["を","に","から","で"]),
                correct: ["から"],
            },
            {
                question: "あの　｜＾教（きょう）会（かい）｜＄０｜＾木（き）｜＄１｜＾造（つく）｜られています。",
                picked: ["",""],
                answers:Helper.shuffle(["は","に","で","が"]),
                correct: ["は","で"],
            },
            {
                question: "この　｜＾小（しょう）説（せつ）｜は　いろいろな　｜＾国（くに）｜の　ことば｜＄０｜＾翻（ほん）訳（やく）｜されています。",
                picked: [""],
                answers:Helper.shuffle(["で","に","へ","を"]),
                correct: ["に"],
            },
            {
                question: "＾石（せき）油（ゆ）｜は　サウジアラビアなど｜＄０｜＾輸（ゆ）入（にゅう）｜されて　います。",
                picked: [""],
                answers:Helper.shuffle(["から","に","へ","を"]),
                correct: ["から"],
            },
            {
                question: "＾東（とう）京（きょう）｜の　｜＾人（ひと）｜は　｜＾歩（ある）｜くの｜＄０｜＾速（はや）｜いです。",
                picked: [""],
                answers:Helper.shuffle(["が","を","は","に"]),
                correct: ["が"],
            },
            {
                question: "＾先（せん）生（せい）｜の　｜＾質（しつ）問（もん）｜＄０｜すぐ　｜＾答（こた）｜えるの｜＄１｜＾難（むずか）｜しいです。",
                picked: ["",""],
                answers:Helper.shuffle(["に","は","が","を"]),
                correct: ["に","は"],
            },
            {
                question: "＾宮（まや）崎（ざき）｜さん　｜＄０｜＾赤（あか）｜ちゃんが　｜＾生（う）｜まれたの｜＄１｜＾知（し）｜っていますか。",
                picked: ["",""],
                answers:Helper.shuffle(["の","を","が","に"]),
                correct: ["に","を"],
            },
            {
                question: "＾ロンドン（London）｜＄０｜＾オリンピック（Olympic）｜が　｜＾行（おこ）｜われたの｜＄１｜いつですか。",
                picked: ["",""],
                answers:Helper.shuffle(["で","は","を","が"]),
                correct: ["で","は"],
            },
            {
                question: "ダイエットは｜＾3（み）日（っか）｜＄０｜やめて　しまいました。",
                picked: [""],
                answers:Helper.shuffle(["で","に","が","を"]),
                correct: ["で"],
            },
            {
                question: "＾太（た）郎（ろう）｜が　｜＾友（とも）達（だち）｜＄０｜けんかしたので、しかりました。",
                picked: [""],
                answers:Helper.shuffle(["と","に","で","が"]),
                correct: ["と"],
            },

            {
                question: "＾火（か）事（じ）｜＄０｜＾家（いえ）｜が　｜＾焼（や）｜けました。",
                picked: [""],
                answers:Helper.shuffle(["で","を","に","が"]),
                correct: ["で"],
            },
            {
                question: "どんな　｜＾問（もん）題（だい）｜＄０｜＾試（し）験（けん）｜＄１｜＾出（で）｜るか、わかりません。",
                picked: ["",""],
                answers:Helper.shuffle(["が","に","を","で"]),
                correct: ["が","に"],
            },
            {
                question: "パーティー｜＄０｜　｜＾参（さん）加（か）｜するか　どうか、｜＾教（おし）｜えてください。",
                picked: [""],
                answers:Helper.shuffle(["に","で","を","へ"]),
                correct: ["に"],
            },
    ])
    ,
    mondai2: 
    // Helper.shuffle(
        [
        {
            question: "彼にはもう｜！体力｜がない。",
            picked: "",
            answers: Helper.shuffle(["たいりょく", "だいりょく", "たいりく", "だいりく"]),
            correct: "たいりょく",
        },
        {
            question: "彼女は彼より｜！頭｜がいい。",
            picked: "",
            answers: Helper.shuffle(["あたま", "からだ", "かお", "たなか"]),
            correct: "あたま",
        },
        {
            question: "彼女はとてもかわいい｜！顔｜だ。",
            picked: "",
            answers: Helper.shuffle(["あたま", "からだ", "かお", "たなか"]),
            correct: "かお",
        },
        {
            question: "バンコクはタイの｜！首都｜です。",
            picked: "",
            answers: Helper.shuffle(["しゅと", "しと", "しゅど", "じゅと"]),
            correct: "しゅと",
        },
        {
            question: "！心配｜する必要はない。",
            picked: "",
            answers: Helper.shuffle(["しんぱい", "ちゅうもん", "ざんねん", "あんしん"]),
            correct: "しんぱい",
        },
        {
            question: "小さい｜！声｜で話してください。",
            picked: "",
            answers: Helper.shuffle(["こえ", "はな", "おと", "いろ"]),
            correct: "こえ",
        },
        {
            question: "彼は｜！病気｜で休んでいる。",
            picked: "",
            answers: Helper.shuffle(["びょうき", "てんき", "げんき", "でんき"]),
            correct: "びょうき",
        },
        {
            question: "その｜！薬｜はすぐに効きますか。",
            picked: "",
            answers: Helper.shuffle(["くすり", "ぐすり", "くずり", "ぐずり"]),
            correct: "くすり",
        },
        {
            question: "どの｜！教科｜が一番好きですか。",
            picked: "",
            answers: Helper.shuffle(["きょうか", "きょか", "きょうが", "きょが"]),
            correct: "きょうか",
        },
        {
            question: "彼は私の｜！案内｜をしてくれた。",
            picked: "",
            answers: Helper.shuffle(["あんない", "おんない", "えんない", "おんうち"]),
            correct: "あんない",
        },
        {
            question: "！今朝｜は何時に家を出た？",
            picked: "",
            answers: Helper.shuffle(["けさ", "げさ", "こんあさ", "いまあさ"]),
            correct: "けさ",
        },
        {
            question: "今日は｜！昼食｜をとる時間がない。",
            picked: "",
            answers: Helper.shuffle(["ちゅうしょく", "ちょうしょく", "ちょしょく", "しょうしょく"]),
            correct: "ちゅうしょく",
        },
        {
            question: "今日の｜！夜｜何か予定ある？",
            picked: "",
            answers: Helper.shuffle(["よる", "ひる", "あさ", "ゆう"]),
            correct: "よる",
        },
        {
            question: "母は｜！夕食｜を作っています。",
            picked: "",
            answers: Helper.shuffle(["ゆうしょく", "ゆしょく", "ゆうしょうく", "ゆしょうく"]),
            correct: "ゆうしょく",
        },
        {
            question: "何か他の｜！方法｜があるの？",
            picked: "",
            answers: Helper.shuffle(["ほうほう", "かたほう", "がたほう", "ぽうほう"]),
            correct: "ほうほう",
        },
        {
            question: "！晩｜ご飯には何を作るつもりなの？",
            picked: "",
            answers: Helper.shuffle(["ばん", "はん", "べん", "ばい"]),
            correct: "ばん",
        },
        {
            question: "彼はその数字を｜！合計｜した。",
            picked: "",
            answers: Helper.shuffle(["ごうけい", "けいかく", "ごうかく", "ごけい"]),
            correct: "ごうけい",
        },
        {
            question: "！日曜日｜は営業してますか？",
            picked: "",
            answers: Helper.shuffle(["にちようび", "にちようひ", "にちよび", "にちよっび"]),
            correct: "にちようび",
        },
        {
            question: "１８歳｜！以上｜の人は車を運転できる。",
            picked: "",
            answers: Helper.shuffle(["いじょう", "いか", "いうえ", "いない"]),
            correct: "いじょう",
        },
        {
            question: "私は何｜！度｜も心配しています。",
            picked: "",
            answers: Helper.shuffle(["ど", "と", "せき", "なみ"]),
            correct: "ど",
        },
        {
            question: "タバコを｜！止｜めた方が良いよ。",
            picked: "",
            answers: Helper.shuffle(["と", "し", "き", "た"]),
            correct: "と",
        },
        {
            question: "天気がいいから｜！散歩｜しましょう。",
            picked: "",
            answers: Helper.shuffle(["さんぽ", "しんぽ", "ちょきん", "りょこう"]),
            correct: "さんぽ",
        },
        {
            question: "馬は人間よりも速く｜！走｜れる。",
            picked: "",
            answers: Helper.shuffle(["はし", "なが", "おそ", "おく"]),
            correct: "はし",
        },
        {
            question: "私はたいてい朝早く｜！起｜きる。",
            picked: "",
            answers: Helper.shuffle(["お", "あ", "ある", "い"]),
            correct: "お",
        },
        {
            question: "車を何台お｜！持ち｜ですか。",
            picked: "",
            answers: Helper.shuffle(["もち", "まち", "おち", "かち"]),
            correct: "もち",
        },
        {
            question: "駅で友達を｜！待ち｜ます。",
            picked: "",
            answers: Helper.shuffle(["もち", "まち", "おち", "かち"]),
            correct: "まち",
        },
        {
            question: "私は彼にこの本を｜！借り｜た。",
            picked: "",
            answers: Helper.shuffle(["かり", "かえり", "うり", "おり"]),
            correct: "かり",
        },
        {
            question: "車を｜！貸し｜ていただけませんか。",
            picked: "",
            answers: Helper.shuffle(["かし", "おし", "だし", "さし"]),
            correct: "かし",
        },
        {
            question: "授業はいつ｜！開始｜予定ですか。",
            picked: "",
            answers: Helper.shuffle(["かいし", "かいじ", "がいし", "がいじ"]),
            correct: "かいし",
        },
        {
            question: "いつ仕事が｜！終わり｜ますか。",
            picked: "",
            answers: Helper.shuffle(["おわり", "まわり", "かわり", "さわり"]),
            correct: "おわり",
        },
        {
            question: "ご｜！家族｜はお元気ですか？",
            picked: "",
            answers: Helper.shuffle(["かぞく", "かそく", "かぞうく", "かそうく"]),
            correct: "かぞく",
        },
        {
            question: "｜！私｜は野球が好きだ。",
            picked: "",
            answers: Helper.shuffle(["わたし", "あなた", "かれ", "かのじょ"]),
            correct: "わたし",
        },
        {
            question: "彼は｜！自転車｜で通学する。",
            picked: "",
            answers: Helper.shuffle(["じてんしゃ", "してんしゃ", "じたんしゃ", "したんしゃ"]),
            correct: "じてんしゃ",
        },
        {
            question: "彼は｜！両親｜に手紙を書いた。",
            picked: "",
            answers: Helper.shuffle(["りょうしん", "りょしん", "りょうじん", "りょじん"]),
            correct: "りょうしん",
        },
        {
            question: "ご｜！兄弟｜をお持ちですか？",
            picked: "",
            answers: Helper.shuffle(["きょうだい","きょだい","きょうたい","きょたい"]),
            correct: "きょうだい",
        },
        {
            question: "！姉｜ちゃん、電気つけてくれない？",
            picked: "",
            answers: Helper.shuffle(["ねえ", "いもうと", "おとうと", "あに"]),
            correct: "ねえ",
        },
        {
            question: "彼女はあなたの｜！妹｜ですか。",
            picked: "",
            answers: Helper.shuffle(["ねえ", "いもうと", "おとうと", "あに"]),
            correct: "いもうと",
        }
    ]
    // )
}