import Icon from "@consts/icon";

export const lesson34 = {
    title: {
        furi: 'わたしが　した　とおりに、　して　ください',
        normal: 'わたしが　した　とおりに、　して　ください',
        en: 'Please do as I did',
        mm: 'ကျွန်ုပ်လုပ်သလို လိုက်လုပ်ပါ',
    },
    conversation: [
        {
            person: {
                furi: 'クララ',
                normal: 'クララ',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: '＾一（いち）度（ど）｜　｜＾茶（さ）道（どう）｜が　｜＾見（み）｜たいんですが。。。',
                    normal: '一度　茶道が　見たいんですが。。。',
                    en: "I'd like to see a tea ceremony once. . .",
                    mm: 'တစ်ကြိမ်လောက် ဂျပန်လက်ဘက်ရည် မြင်ဖူးချင်လို့ပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾渡（わた）辺（なべ）',
                normal: '渡辺',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: 'じゃ、｜＾来（らい）週（しゅう）｜の　｜＾土（ど）曜（よう）日（び）｜　いっしょに　｜＾行（い）｜きませんか。',
                    normal: 'じゃ、来週の　土曜日　いっしょに　行きませんか。',
                    en: 'Well then, would you like to go with me next Saturday?',
                    mm: 'ဒါဖြင့်ရင်၊ လာမယ့်အပတ် စနေနေ့ အတူတူသွားကြမလား',
                },
            ],
        },
        {
            person: {
                furi: 'お｜＾茶（ちゃ）｜の｜＾先（せん）生（せい）',
                normal: 'お茶の先生',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: '＾渡（わた）辺（なべ）｜さん、　お｜＾茶（ちゃ）｜を　たてて　ください。クララさん　お｜＾菓（か）子（し）｜を　どうぞ。',
                    normal: '渡辺さん、　お茶を　たてて　ください。クララさん　お菓子を　どうぞ。',
                    en: 'Mr. Watanabe, please make some tea. Clara: Have some sweets.',
                    mm: 'ဝတနဘဲစံ လက်ဘက်ရည်တည်ပေးပါ။ ခုရရစံ မုန့်အချိုလေးသုံးဆောင်ပါ။',
                },
            ],
        },
        {
            person: {
                furi: 'クララ',
                normal: 'クララ',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: 'えっ、｜＾先（さき）｜に　お｜＾菓（か）子（し）｜を　｜＾食（た）｜べるんですか。',
                    normal: 'えっ、先に　お菓子を　食べるんですか。',
                    en: 'Eh, eating sweets first?',
                    mm: 'အဲ မုန့်အချိုကို အရင်ဆုံးစားရမှာလား',
                },
            ],
        },
        {
            person: {
                furi: 'お｜＾茶（ちゃ）｜の｜＾先（せん）生（せい）',
                normal: 'お茶の先生',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'ええ。｜＾甘（あま）｜い　お｜＾菓（か）子（し）｜を　｜＾食（た）｜べたあとで、　お｜＾茶（ちゃ）｜を｜＾飲（の）｜むと　おいしいん　ですよ。',
                    normal: 'ええ。甘い　お菓子を　食べたあとで、　お茶を飲むと　おいしいん　ですよ。',
                    en: "yes. It's delicious to drink tea after eating sweets.",
                    mm: 'အင်း၊ ချိုတဲ့ မုန့်ကို စားပြီးမှ လက်ဘက်ရည်ကိုသောက်မယ်ဆိုရင် အရသာကောင်းတယ်နော်',
                },
            ],
        },
        {
            person: {
                furi: 'クララ',
                normal: 'クララ',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: 'そうですか。',
                    normal: 'そうですか。',
                    en: 'Is that so.',
                    mm: 'ဟုတ်လား',
                },
            ],
        },
        {
            person: {
                furi: 'お｜＾茶（ちゃ）｜の｜＾先（せん）生（せい）',
                normal: 'お茶の先生',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'では、お｜＾茶（ちゃ）｜を　｜＾飲（の）｜みましょう。',
                    normal: 'では、お茶を　飲みましょう。',
                    en: "Now, let's have some tea.",
                    mm: 'ဒါဖြင့်ရင် လက်ဘက်ရည်သောက်ကြစို့။',
                },
                {
                    furi: 'まず　｜＾右（みぎ）手（て）｜で　おちゃわんを　｜＾取（と）｜って、　｜＾左（ひだり）手（て）｜に　｜＾載（の）｜せます。',
                    normal: 'まず　右手で　おちゃわんを　取って、　左手に　載せます。',
                    en: 'First, take the bowl with your right hand and put it on your left hand.',
                    mm: 'အရင်ဆုံး ညာဘက်လက်နဲ့ လက်ဘက်ရည်ခွက်ကို ယူပြီးတော့ ဘယ်လက်ပေါ်ကို တင်လိုက်ပါ။',
                },
                {
                    furi: '＾次（つぎ）｜に　おちゃわんを　２｜＾回（かい）｜　｜＾回（まわ）｜して、　それから　｜＾飲（の）｜みます。',
                    normal: '次に　おちゃわんを　２回　回して、　それから　飲みます。',
                    en: 'Next, turn the bowl twice, and then drink.',
                    mm: 'နောက်ပြီးတော့ လက်ဘက်ရည်ပန်းကန်လုံးကို နှစ်ကြိမ်လှည့်ပြီးမှ သောက်ပါ။',
                },
            ],
        },
        {
            person: {
                furi: 'クララ',
                normal: 'クララ',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: 'はい。',
                    normal: 'はい。',
                    en: 'yes',
                    mm: 'ဟုတ်ကဲ့',
                },
            ],
        },
        {
            person: {
                furi: 'お｜＾茶（ちゃ）｜の｜＾先（せん）生（せい）',
                normal: 'お茶の先生',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'じゃ、わたしが　した　とおりに、　して　ください。',
                    normal: 'じゃ、わたしが　した　とおりに、　して　ください。',
                    en: 'Then please do as I did.',
                    mm: 'ကဲ ကျွန်ုပ်လုပ်တဲ့အတိုင်း လုပ်ပေးပါ',
                },
            ],
        },
        {
            person: {
                furi: 'クララ',
                normal: 'クララ',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: 'これで　いいですか。',
                    normal: 'これで　いいですか。',
                    en: 'Is this alright',
                    mm: 'ဒီလိုလား',
                },
            ],
        },
        {
            person: {
                furi: 'お｜＾茶（ちゃ）｜の｜＾先（せん）生（せい）',
                normal: 'お茶の先生',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'はい、いかがですか。',
                    normal: 'はい、いかがですか。',
                    en: 'yes, How do you like it?',
                    mm: 'ဟုတ်ကဲ့ ဘယ်လိုလဲ',
                },
            ],
        },
        {
            person: {
                furi: 'クララ',
                normal: 'クララ',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: '＾少（すこ）｜し　｜＾苦（にが）｜いですが、おいしいです。',
                    normal: '少し　苦いですが、おいしいです。',
                    en: 'A little bitter, but delicious.',
                    mm: 'နဲနဲ ခါးပေမယ့် အရသာရှိတယ်',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson34/kaiwa/4N34KW.mp3")
}