import Number from "@components/common/Number";
import { MondaiType, QuestionType } from "@consts/test";
import Helper from "@helper";
import { Card } from "@material-tailwind/react";
import { Dispatch, SetStateAction, memo } from "react";
import { GiCheckMark } from "react-icons/gi";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
interface Mondai45Type {
    finish: Boolean;
    fontSize: number;
    mondai: MondaiType;
    setMondai: Dispatch<SetStateAction<MondaiType>>;
    furigana?: String | undefined;
}
const Mondai45Component = ({ mondai: { title, questions }, setMondai, fontSize, furigana, finish }: Mondai45Type ) => {
    const onSelect = (ans: String, index: number) => {
        questions[index].pick = ans;
        setMondai({ title, questions });
    }
    const renderAns = (ans: String) => {
        if (ans.includes("！")) {
            // run with furi
            return Helper.furiTranslate({ context: ans, fontSize, style: "flex-wrap" })
        } else {
            return ans;
        }        
    }
    return <div>
        <div className="font-semibold">{title}</div>
        {
            questions.map(({ number, question, answers, pick, correct }: QuestionType, i: number) => {
                return <div key={i} className="mt-[20px] mb-[50px]">
                    {/* question */}
                    <div className="flex flex-row mb-[15px]">
                        <Number furigana={furigana || "true"} fontSize={fontSize}>
                            {number}
                            <span>. </span>
                        </Number>
                        <div className="">
                            {
                                furigana == "true" ?
                                    Helper.furiTranslate({ context: question, fontSize, style: "flex-wrap" })
                                    : question
                            }
                        </div>
                    </div>
                    {finish ?
                        // Show REsult
                        <div className="mt-[10px] grid grid-cols-1 gap-[18px] md:gap-[30px]">
                            {answers.map((ans) => {
                                return ans === pick && pick === correct ?
                                    // ans correct
                                    <Card key={Math.random()} className="shadow-md dark:shadow-[#344a5a] relative flex flex-row md:w-2/3 items-center bg-[#00E392]  font-semibold  dark:text-green-900 p-2 rounded-[10px] btn">
                                        <div className="flex flex-row items-center justify-center w-[20px] h-[20px] mr-[5px]">
                                            <MdRadioButtonChecked />
                                        </div>
                                        {renderAns(ans)}
                                        <GiCheckMark size={25} className="absolute text-green-700 dark:text-green-900 dark:right-[4px]  -right-[10px]" />
                                    </Card>
                                    // ans wrong
                                    : ans === pick && pick !== correct ? <Card key={Math.random()} className="shadow-md dark:shadow-[#344a5a] relative flex flex-row items-center md:w-2/3 bg-[#FF516E] p-2 rounded-[10px] btn">
                                        <div className="flex flex-row items-center justify-center w-[20px] h-[20px] mr-[5px]">
                                            <MdRadioButtonChecked />
                                        </div>
                                        {renderAns(ans)}
                                    </Card>
                                        // user no select
                                        : <Card key={Math.random()} className={`shadow-md dark:shadow-[#344a5a] ${ans == correct ? ' bg-[#00E392]  font-semibold  dark:text-green-900' : 'bg-white dark:bg-[#001E34]'} flex flex-row items-center md:w-2/3 p-2 rounded-[10px] btn`}>
                                            <div className="flex flex-row items-center justify-center w-[20px] h-[20px] mr-[5px]">
                                                <MdRadioButtonUnchecked />
                                            </div>
                                            {renderAns(ans)}
                                        </Card>
                            })}
                        </div> 
                        // Multiple Choice
                        : <div className="mt-[10px] grid grid-cols-1 gap-[18px] md:gap-[24px]">
                            {answers.map((ans) => {
                                return ans === pick ?
                                    <Card key={Math.random()} className="shadow-md dark:shadow-[#344a5a] flex flex-row md:w-2/3 items-center bg-[#00E392]  font-semibold  dark:text-green-900 p-2 rounded-[10px] btn">
                                        <div className="flex flex-row items-center justify-center w-[20px] h-[20px] mr-[5px]">
                                            <MdRadioButtonChecked />
                                        </div>
                                        {renderAns(ans)}
                                    </Card>
                                    : <Card key={Math.random()} onClick={() => onSelect(ans, i)} className="shadow-md dark:shadow-[#344a5a] flex flex-row items-center md:w-2/3 bg-white dark:bg-[#001E34] p-2 rounded-[10px] btn">
                                        <div className="flex flex-row items-center justify-center w-[20px] h-[20px] mr-[5px]">
                                            <MdRadioButtonUnchecked/>
                                        </div>
                                        {renderAns(ans)}
                                    </Card>
                            })}
                        </div>
                    }
                </div>
            })
        }
    </div>
}
export default memo(Mondai45Component);