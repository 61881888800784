
export const lesson16 = [
    {"lesson":16,"level":4,"question":"図書","represent":["図","書"],"answer":["としょ","せいぶつ","ばあい","びょういん"],"true":"としょ","times":10},
    {"lesson":16,"level":4,"question":"講堂","represent":["堂"],"answer":["こうどう","こうじょう","しょくどう","ばあい"],"true":"こうどう","times":10},
    {"lesson":16,"level":4,"question":"工事","represent":["工","事"],"answer":["こうじ","こうがく","としょ","きょうしつ"],"true":"こうじ","times":10},
    {"lesson":16,"level":4,"question":"図書館","represent":["図","書","館"],"answer":["としょかん","たいしかん","うんどうじょう","だいがくいん"],"true":"としょかん","times":10},
    {"lesson":16,"level":4,"question":"広場","represent":["広","場"],"answer":["ひろば","せいぶつ","こうどう","こうぎょう"],"true":"ひろば","times":10},
    {"lesson":16,"level":4,"question":"地図","represent":["地","図"],"answer":["ちず","しんごう","ばあい","きごう"],"true":"ちず","times":10},
    {"lesson":16,"level":4,"question":"信号","represent":["信","号"],"answer":["しんごう","ばしょ","しょくどう","こうじ"],"true":"しんごう","times":10},
    {"lesson":16,"level":4,"question":"荷物","represent":["物"],"answer":["にもつ","こうがく","たてもの","ばしょ"],"true":"にもつ","times":10},
    {"lesson":16,"level":4,"question":"工学","represent":["工","学"],"answer":["こうがく","びょういん","しょくどう","ちず"],"true":"こうがく","times":10},
    {"lesson":16,"level":4,"question":"天気図","represent":["天","気","図"],"answer":["てんきず","えいがかん","たいしかん","けんきゅうしつ"],"true":"てんきず","times":10},
    {"lesson":16,"level":4,"question":"建物","represent":["建","物"],"answer":["たてもの","こうじょう","ばしょ","きごう"],"true":"たてもの","times":10},
    {"lesson":16,"level":4,"question":"食堂","represent":["食","堂"],"answer":["しょくどう","こうがく","こうじ","せいぶつ"],"true":"しょくどう","times":10},
    {"lesson":16,"level":4,"question":"場所","represent":["場","所"],"answer":["ばしょ","にもつ","こうじ","こうぎょう"],"true":"ばしょ","times":10},
    {"lesson":16,"level":4,"question":"大使館","represent":["大","使","館"],"answer":["たいしかん","けんきゅうしつ","てんきず","としょかん"],"true":"たいしかん","times":10},
    {"lesson":16,"level":4,"question":"生物","represent":["生","物"],"answer":["せいぶつ","こうじょう","きごう","ばあい"],"true":"せいぶつ","times":10},
    {"lesson":16,"level":4,"question":"大学院","represent":["大","学","院"],"answer":["だいがくいん","えいがかん","てんきず","たいしかん"],"true":"だいがくいん","times":10},
    {"lesson":16,"level":4,"question":"映画館","represent":["映","画","館"],"answer":["えいがかん","けんきゅうしつ","てんきず","だいがくいん"],"true":"えいがかん","times":10},
    {"lesson":16,"level":4,"question":"病院","represent":["病","院"],"answer":["びょういん","せいぶつ","しょくどう","ちず"],"true":"びょういん","times":10},
    {"lesson":16,"level":4,"question":"工場","represent":["工","場"],"answer":["こうじょう","こうぎょう","しんごう","ひろば"],"true":"こうじょう","times":10},
    {"lesson":16,"level":4,"question":"室内","represent":["室","内"],"answer":["きょうしつ","しつない","しんごう","せいぶつ"],"true":"しつない","times":10},
    {"lesson":16,"level":4,"question":"教室","represent":["教","室"],"answer":["きょうしつ","しょくどう","たてもの","ばあい"],"true":"きょうしつ","times":10},
    {"lesson":16,"level":4,"question":"運動場","represent":["運","動","場"],"answer":["うんどうじょう","えいがかん","てんきず","だいがくいん"],"true":"うんどうじょう","times":10},
    {"lesson":16,"level":4,"question":"記号","represent":["号"],"answer":["きごう","きょうしつ","こうじょう","たてもの"],"true":"きごう","times":10},
    {"lesson":16,"level":4,"question":"研究室","represent":["研","究","室"],"answer":["けんきゅうしつ","としょかん","たいしかん","えいがかん"],"true":"けんきゅうしつ","times":10},
    {"lesson":16,"level":4,"question":"場合","represent":["場","合"],"answer":["ばあい","こうじ","ばしょ","きごう"],"true":"ばあい","times":10},
    {"lesson":16,"level":4,"question":"工業","represent":["工","業"],"answer":["こうぎょう","ばあい","ばしょ","しんごう"],"true":"こうぎょう","times":10},
]