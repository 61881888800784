export const bunkei= [
    {
        sentence: [
            {
                furi: '＾絵（え）｜を　かくのは　｜＾楽（たの）｜しいです。',
                normal: '絵を　かくのは　楽しいです。',
                en: 'Drawing pictures is fun.',
                mm: 'ပုံတွေဆွဲရတာ ပျော်စရာကောင်းတယ်။    ',
            },
        ],
        audioUrl: require("@assets/audio/lesson38/bunpou/4N38BK1.mp3")
    },
    {
        sentence: [
            {
                furi: 'わたしは　｜＾星（ほし）｜を　｜＾見（み）｜るのが　｜＾好（す）｜きです。',
                normal: 'わたしは　星を　見るのが　好きです。',
                en: 'I like looking at the stars.',
                mm: 'ကြယ်တွေကိုကြည့်ရတာကြိုက်တယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson38/bunpou/4N38BK2.mp3")
    },
    {
        sentence: [
            {
                furi: '＾財（さい）布（ふ）｜を　｜＾持（も）｜って　｜＾来（く）｜るのを　｜＾忘（わす）｜れました。',
                normal: '財布を　持って　来るのを　忘れました。',
                en: 'I forgot to bring my wallet.',
                mm: 'ပိုက်ဆံအိတ် ယူလာဖို့ မေ့သွားတယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson38/bunpou/4N38BK3.mp3")
    },
    {
        sentence: [
            {
                furi: 'わたしが　｜＾日（に）本（ほん）｜へ　｜＾来（き）｜たのは　｜＾去（きょ）年（ねん）｜の　３｜＾月（がつ）｜です',
                normal: 'わたしが　日本へ　来たのは　去年の　３月です',
                en: 'I came to Japan last March',
                mm: 'ပြီးခဲ့တဲ့ မတ်လက ဂျပန်ကို ရောက်ခဲ့တယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson38/bunpou/4N38BK4.mp3")
    },
]