import KanjiPopOverComponent from "@components/kanji/KanjiPopOverComponent";
import { Minna } from "@consts";

interface FuriType {
    context: String;
    fontSize: number;
    style: String;
    notUseLibrary?: Boolean;
    kanjiDetail?: (id: number) => void;
}
export const furiTranslate = ({ context, fontSize, style, notUseLibrary,kanjiDetail }: FuriType) => {
    if (!context.includes("！") && !context.includes("＾")) {
        return (
            <div className={`flex flex-row  ${style}`}>
                <div className="flex flex-col items-center leading-tight">
                    {<div className={`text-${fontSize - 7}px`}>　</div>}
                    <div className="">{context}</div>
                </div>
            </div>
        )
    }
    const spliter = context.split("｜");
    const furi = (text: string) => {
        // ＾天（てん）気（き）
        text = text.substring(1);
        // 天（てん）気（き）
        const textArray = text.split("）")
        // ['天（てん', '気（き', '']
        return (<>
            <div className="flex flex-row mx-1">
                {
                    textArray.map((withfuri, i) => {
                        if (withfuri.length > 0) {
                            // ['天','てん']
                            const [kanji, furigana] = withfuri.split("（");
                            // search kanji from library
                            const index = !notUseLibrary ? Minna.kanji.search[kanji] : false;
                            // != undefined
                            // console.log(index);
                            return index || index===0 ?
                                (
                                    <KanjiPopOverComponent id={index} kanjiDetail={kanjiDetail}>
                                        <div key={i} onClick={() => console.log(index)} className="flex flex-col items-center leading-tight text-blue-500 cursor-pointer ">
                                            <div className={`text-${fontSize - 7}px`}>{furigana}</div>
                                            <div className="">{kanji}</div>
                                        </div>
                                    </KanjiPopOverComponent>
                                )
                                : (
                                    <div key={i} className="flex flex-col items-center leading-tight">
                                        <div className={`text-${fontSize - 7}px`}>{furigana}</div>
                                        <div className="">{kanji}</div>
                                    </div>
                                )
                        }

                    })
                }
            </div>
        </>)
    } 
    const furiUnderline = (text: string) => {
        // ！買（か）ったん
        text = text.substring(1);
        // 買（か）ったん
        const textArray = text.split("）")
        // [' 買（か', 'ったん']
        return (<>
            <div className="px-[1px] flex flex-row mx-1">
                {
                    textArray.map((withfuri, i) => {
                        if (withfuri.length > 0) {
                            if (withfuri.includes("（")) {
                                // ['買','か']
                                const [kanji, furigana] = withfuri.split("（");
                                // search kanji from library
                                const index = !notUseLibrary ? Minna.kanji.search[kanji] : false;
                                return index ?
                                    (
                                        <KanjiPopOverComponent id={index} kanjiDetail={kanjiDetail}>
                                            <div key={i} onClick={() => console.log(index)} className="flex flex-col items-center leading-tight text-navbar cursor-pointer  ">
                                                <div className={`text-${fontSize - 7}px`}>{furigana}</div>
                                                <div className="">{kanji}</div>
                                            </div>
                                        </KanjiPopOverComponent>
                                    )
                                    : (
                                        <div key={i} className="flex flex-col items-center leading-tight">
                                            <div className={`text-${fontSize - 7}px`}>{furigana}</div>
                                            <div className="">{kanji}</div>
                                        </div>
                                    )
                            } else {
                                // 'ったん'
                                return (
                                    // px-2 flex flex-row border-b-2 border-blue-800
                                    <div key={i} className="flex flex-col items-center leading-tight">
                                        <div className={`text-${fontSize - 7}px`}>　</div>
                                        <div className="">{withfuri}</div>
                                    </div>
                                )
                            }
                        }

                    })
                }
            </div>
        </>)
    }
    return (<>
        <div className={`flex flex-row ${style}`}>
            {/* flex-wrap  className={`flex flex-row ${style}`} */}
            {
                spliter.map((text, i) => {
                    return text.includes("＾") ? <div key={i} className={`flex flex-row`}>{furi(text)}</div> :
                        text.includes("！") ? <div key={i} className={`flex flex-row border-b-2 border-blue-500`}>{furiUnderline(text)}</div> :
                            text.split("").map((char: string) => {
                                return <div key={Math.random()} className="flex flex-col items-center leading-tight">
                                    {<div className={`text-${fontSize - 7}px`}>　</div>}
                                    <div className="">{char}</div>
                                </div>
                            })
                })
            }
        </div>
    </>);
}