import { explain } from "./explain";
import { bunkei } from "./bunkei";
import { reibun } from "./reibun";
import { renshuuA } from "./renshuuA";
export const lesson36 = {
    explain,
    bunkei,
    reibun,
    renshuuA
   
}
/*

{
    furi: '',
    normal: '',
    en: '',
    mm: '',
},

*/