export const lesson3 = [
    {"lesson":3,"level":4,"question":"りし","represent":["利","子"],"answer":["切符","急行","元日","利子"],"true":"利子","times":10},
    {"lesson":3,"level":4,"question":"きっぷ","represent":["切"],"answer":["区別","切符","急行","特急"],"true":"切符","times":10},
    {"lesson":3,"level":4,"question":"がんじつ","represent":["元","日"],"answer":["便所","元日","切手","急行"],"true":"元日","times":10},
    {"lesson":3,"level":4,"question":"きゅうこう","represent":["急","行"],"answer":["急行","切手","区別","急用"],"true":"急行","times":10},
    {"lesson":3,"level":4,"question":"とくべつな","represent":["特","別"],"answer":["特別な","有名な","元気な","親切な"],"true":"特別な","times":10},
    {"lesson":3,"level":4,"question":"ゆうりな","represent":["有","利"],"answer":["特別な","不便な","有利な","便利な"],"true":"有利な","times":10},
    {"lesson":3,"level":4,"question":"ふべんな","represent":["不","便"],"answer":["有力な","不便な","有名な","有利な"],"true":"不便な","times":10},
    {"lesson":3,"level":4,"question":"とっきゅう","represent":["特","急"],"answer":["切符","特急","切手","区別"],"true":"特急","times":10},
    {"lesson":3,"level":4,"question":"ゆうりょくな","represent":["有","力"],"answer":["特別な","有力な","便利な","不便な"],"true":"有力な","times":10},
    {"lesson":3,"level":4,"question":"げんきな","represent":["元","気"],"answer":["有力な","不運な","親切な","元気な"],"true":"元気な","times":10},
    {"lesson":3,"level":4,"question":"ふうんな","represent":["不","運"],"answer":["不運な","便利な","有名な","元気な"],"true":"不運な","times":10},
    {"lesson":3,"level":4,"question":"きゅうよう","represent":["急","用"],"answer":["利子","便所","急用","特色"],"true":"急用","times":10},
    {"lesson":3,"level":4,"question":"きって","represent":["切","手"],"answer":["切符","便所","元日","切手"],"true":"切手","times":10},
    {"lesson":3,"level":4,"question":"くべつ","represent":["区","別"],"answer":["特色","区別","便所","利子"],"true":"区別","times":10},
    {"lesson":3,"level":4,"question":"しんせつな","represent":["親","切"],"answer":["親切な","有力な","特別な","不運な"],"true":"親切な","times":10},
    {"lesson":3,"level":4,"question":"べんりな","represent":["便","利"],"answer":["元気な","有名な","不運な","便利な"],"true":"便利な","times":10},
    {"lesson":3,"level":4,"question":"ゆうめいな","represent":["有","名"],"answer":["元気な","便利な","有力な","有名な"],"true":"有名な","times":10},
    {"lesson":3,"level":4,"question":"べんじょ","represent":["便","所"],"answer":["利子","便所","切手","特色"],"true":"便所","times":10},
    {"lesson":3,"level":4,"question":"とくしょく","represent":["特","色"],"answer":["特急","便所","急行","特色"],"true":"特色","times":10},
]