import Icon from "@consts/icon";
import { Card } from "@material-tailwind/react";
import { ChangeEvent, memo, useEffect, useRef, useState } from "react";
import {
    IoPauseSharp,
    IoVolumeMute
} from 'react-icons/io5';
import ProgressSliderComponent from "./ProgressSliderComponent";
interface AmidioProps {
    audioUrl: string;
    scoreIncrementAction?:()=>void;
}
const AmidioComponent = ({ audioUrl,scoreIncrementAction }: AmidioProps) => {
    const parent = useRef(null); // demount detect
    const audio = useRef(new Audio(audioUrl));
    const duration = audio.current.duration || 0;
    const [range, setRange] = useState<number | string>(0);
    const [play, setPlay] = useState<Boolean>(false);

    const [muteVolume, setMuteVolume] = useState(false);
    const [volume, setVolume] = useState<number>(1);

    const [time, setTime] = useState<number>(0);
    const timeConvert = (time: number): string => {
        const min = Math.floor(time / 60);
        const sec = Math.floor(time % 60);
        return `${min.toString().padStart(min > 9 ? 2 : 1, '0')}:${sec.toString().padStart(2, '0')}`;
    }
    const onTimeUpdate = () => {
        setTime(audio.current.currentTime);
        setRange(audio.current.currentTime * 100 / duration);
    }
    const skipForward = () => {
        setCurrentTime(time + 5);
    };

    const skipBackward = () => {
        setCurrentTime(time - 5);
    };
    const setCurrentTime = (time: number) => {
        audio.current.currentTime = time;
    }
    const onVolSliderChange = (event: ChangeEvent<HTMLInputElement>) => {
        setVolume(parseInt(event.target.value) / 100);
        audio.current.volume = parseInt(event.currentTarget.value) / 100;
    }
    // volume
    const playBtnAction = () => {
        setPlay(!play);
        if (play) {
            audio.current.pause();

        } else {
            audio.current.play();
        }
    }
    const onTimeSliderChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentTime((parseInt(event.currentTarget.value) * duration) / 100);
    }
    const stopAudio = () => {
            console.log('User clicked back button');
    }
    const onEnd = () => {
        setCurrentTime(0);
        setPlay(false);
        scoreIncrementAction && scoreIncrementAction();
        console.log("On Audio End",scoreIncrementAction);
    }
    useEffect(() => {
        // addListen
        audio.current.addEventListener("timeupdate", onTimeUpdate);
        audio.current.addEventListener("ended",onEnd);
     // Remove Listen
        return () => {
            if (!parent.current) audio.current.pause();
            audio.current.removeEventListener("timeupdate", onTimeUpdate);
            audio.current.removeEventListener("ended", onEnd);

    
        }
    })
    return <Card ref={parent} className="relative flex flex-col px-3 justify-evenly items-center w-[300px] h-[50px] rounded-[10px] border-[1px] bg-white dark:bg-darkFooter border-[#B1B1B1] dark:border-[#001E34] select-none">
        <div className="flex flex-row items-center justify-between w-full text-[11px] text-[#424242] dark:text-[#BCBCBC] ">
            {/* Time */}
            <div>{timeConvert(time)}</div>
            {/* Progress Bar */}
            <ProgressSliderComponent
                width={209}
                value={range}
                color="#F59815"
                bgColor="#B3B3B3"
                thumbSize={13}
                scrollHeight={5}
                onSliderChange={onTimeSliderChange} 
                name="time-md-slider"
            />
            {/* Duration */}
            <div>{timeConvert(duration)}</div>
        </div>
        <div className="flex flex-row items-center justify-center w-full">
            <div className="flex-1"></div>
            <div className="flex-1 flex flex-row items-center justify-evenly">
                <div onClick={skipBackward}>
                    <img src={Icon.Prev} className="text-[#8D8D8D] dark:text-[#BCBCBC] btn" />
                </div>
                <div onClick={playBtnAction}>
                    {
                        play === true ? <IoPauseSharp className="btn text-[#8D8D8D] dark:text-[#BCBCBC] w-[18px] h-[18px]" /> : <img src={Icon.Play} className="text-[#8D8D8D] dark:text-[#BCBCBC] btn" />
                    }
                </div>
                <div onClick={skipForward}>
                    <img src={Icon.Next} className="text-[#8D8D8D] btn" />
                </div>
            </div>
            <div className="flex-1 flex flex-row  items-center w-full justify-evenly">
                <div onClick={(e) => setMuteVolume(!e)}>
                    {muteVolume || volume == 0 ? <IoVolumeMute className="text-[#8D8D8D] dark:text-[#BCBCBC]" /> : <img src={Icon.Amiespeaker} className="btn" />}
                </div>
                <ProgressSliderComponent
                    width={57}
                    value={volume * 100}
                    color="#F59815"
                    bgColor="#B3B3B3"
                    thumbSize={7}
                    scrollHeight={4}
                    onSliderChange={onVolSliderChange}
                    name="volume-md-slider"
                />
            </div>
        </div>
    </Card>
}
export default memo(AmidioComponent);