import LessonCardComponent from "@components/home/LessonCardComponent";
import { BottomSpace, TopSpace } from "@consts/layout";
import HomeScreenLayout from "@layouts/home/HomeScreenLayout";
import { memo, useEffect } from "react";
import { ScreenPropType } from "../../App";
// import { useAppSelector as selector } from '@store/hooks';

const HomeScreen = ({selector,lang,showModel,setShowModel,setUnlockPremium, Logger,routeHistory,setRouteData,premium, name, dispatch, setName,scores, Routes, useNavigate, toggleTheme, changeFontSize, dark }:ScreenPropType) => {
    const log = Logger(2);
    const navigate = useNavigate();
    const {pro} =selector(({service})=>service);
    const lessonCards = [
        {
            percent: 40,
            isLock: false
        },
        {
            percent: 60,
            isLock: false

        },
        {
            percent: 14,
            isLock: false

        },
        {
            percent: 56,
            isLock: false
        },
        {
            percent: 100,
            isLock: false

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true

        },
        {
            percent: 40,
            isLock: true
        },
    ]
    const cardAction = ({ id }: { id: number }) => {
        navigate(Routes.CATEGORY, {
            state: ({ lesson: id , id: id  })
        })
    }

    useEffect(() => {
        if(routeHistory.home.route != Routes.HOME){
            setRouteData({home:{
                state:{},
                route:Routes.HOME
            }})
        }
    },[])
    return (
        <HomeScreenLayout
            body={
                <>
                    <TopSpace />
                    <div className="container mx-auto grid grid-cols-2 gap-[25px] md:gap-[30px] lg:gap-[40px] md:grid-cols-4 lg:grid-cols-5 text-center cursor-pointer">
                        {
                            (pro?lessonCards.map(({percent})=>({isLock:false,percent})):lessonCards).map(({ isLock }, id) => {
                                const { vocab, grammar, kanjiScore: { count }, kaiwa } = scores[id];
                                const percent = (vocab > 25 ? 25 : vocab) + (grammar > 40 ? 40 : grammar) + (count > 25 ? 25 : count) + (kaiwa > 10 ? 10 : kaiwa);
                                return <LessonCardComponent dark={dark} key={id} id={id} percent={percent} premium={premium} isLock={isLock} cardAction={cardAction} setShowModel={setShowModel} />
                            }
                            )
                        }
                    </div>
                    <BottomSpace/>
                </>
            }
            Routes={Routes}
            Logger={Logger}
            useNavigate={useNavigate}
            lang={lang}
            showModel={showModel}
            setShowModel={setShowModel}
            setUnlockPremium={setUnlockPremium}
        />
    )}
export default memo(HomeScreen);
