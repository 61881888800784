import { lesson26 } from "./lesson26";
import { lesson27 } from "./lesson27";
import { lesson28 } from "./lesson28";
import { lesson29 } from "./lesson29";
import { lesson30 } from "./lesson30";
import { lesson31 } from "./lesson31";
import { lesson32 } from "./lesson32";
import { lesson33 } from "./lesson33";
import { lesson34 } from "./lesson34";
import { lesson35 } from "./lesson35";
import { lesson36 } from "./lesson36";
import { lesson37 } from "./lesson37";
import { lesson38 } from "./lesson38";
import { lesson39 } from "./lesson39";
import { lesson40 } from "./lesson40";
import { lesson41 } from "./lesson41";
import { lesson42 } from "./lesson42";
import { lesson43 } from "./lesson43";
import { lesson44 } from "./lesson44";
import { lesson45 } from "./lesson45";
import { lesson46 } from "./lesson46";
import { lesson47 } from "./lesson47";
import { lesson48 } from "./lesson48";
import { lesson49 } from "./lesson49";
import { lesson50 } from "./lesson50";

export const kaiwa = [
    // testing 
    
    // testing
    lesson26, lesson27, lesson28, lesson29, lesson30,
    lesson31, lesson32, lesson33, lesson34, lesson35,
    lesson36, lesson37, lesson38, lesson39, lesson40,
    lesson41, lesson42, lesson43, lesson44, lesson45,
    lesson46, lesson47, lesson48, lesson49, lesson50,
];