export const renshuuA = [
    {
        sentence: [
            {
                furi: "あしたは　｜＾雪（ゆき）｜が　｜！ふる｜　そうです。",
                normal: "あしたは　雪が　ふる　そうです。",
                en: "It is said that it will snow tomorrow.",
                mm: "မနက်ဖြန် နှင်းကျမယ်လို့ ပြောတယ်။",
            },
            {
                furi: "＾台（たい）風（ふう）｜は　｜！こない｜　そうです。",
                normal: "台風は　こない　そうです。",
                en: "It seems that there will be no typhoon.",
                mm: "တိုင်ဖွန်းမုန်တိုင်း ရှိတော့မှာ မဟုတ်ဘူး။",
            },
            {
                furi: "きのう　ひどい　｜＾事（じ）故（こ）｜が　｜！あった｜　そうです。",
                normal: "きのう　ひどい　事故が　あった　そうです。",
                en: "It seems that there was a terrible accident yesterday.",
                mm: "မနေ့က ဆိုးရွားတဲ့ မတော်တဆမှုတစ်ခု ဖြစ်ခဲ့ပုံရတယ်။",
            },
            {
                furi: "けがを　した　｜！人（ひと）｜は　｜！いなかった｜　そうです。",
                normal: "けがを　した　人は　いなかった　そうです。",
                en: "It seems that no one was injured.",
                mm: "ထိခိုက်ဒဏ်ရာရသူ မရှိဟု ထင်ရသည်။",
            },
            {
                furi: "ことしは　｜＾夏（なつ）｜が　｜！みじかい｜　そうです。",
                normal: "ことしは　夏が　みじかい　そうです。",
                en: "It seems that summer will be short this year.",
                mm: "ဒီနှစ်နွေရာသီက တိုတောင်းမယ်ထင်တယ်။",
            },
            {
                furi: "＾札（さっ）幌（ぽろ）｜の　｜＾雪（ゆき）祭（まつ）｜りは　｜！きれいだ｜　そうです。",
                normal: "札幌の　雪祭りは きれいだ　そうです。",
                en: "The Sapporo Snow Festival is said to be beautiful.",
                mm: "Sapporo နှင်းပွဲတော်ဟာ လှပတယ်လို့ ဆိုကြပါတယ်။",
            },
            {
                furi: "あしたの　｜＾天（てん）気（き）｜は　｜！くもりだ｜　そうです。",
                normal: "あしたの　天気は　くもりだ　そうです。",
                en: "It seems that the weather tomorrow will be cloudy.",
                mm: "မနက်ဖြန် ရာသီဥတုက တိမ်ထူမဲ့ပုံပဲ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson47/renshua/4N47RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "コンサートが　｜！はじまる｜　ようです。",
                normal: "コンサートが　はじまる　ようです。",
                en: "It seems that the concert is about to begin.",
                mm: "ဖျော်ဖြေပွဲစတော့မည်ဟု ထင်ရသည်။",
            },
            {
                furi: "＾課（か）長（ちょう）｜は　｜＾事（じ）務（む）所（しょ）｜に　｜！いない｜　ようです。",
                normal: "課長は　事務所に　いない　ようです。",
                en: "The manager doesn't seem to be in the office.",
                mm: "မန်နေဂျာက ရုံးမှာရှိပုံမပေါ်ဘူး။",
            },
            {
                furi: "きのうの　｜＾晩（ばん）｜　｜＾雨（あめ）｜が　｜！ふった｜　ようです。",
                normal: "きのうの　晩　雨が　ふった　ようです。",
                en: "It seems that it rained last night.",
                mm: "မနေ့ညက မိုးရွာနေပုံရတယ်။",
            },
            {
                furi: "タワポンさんは　｜＾試（し）験（けん）｜に　｜！ごうかくしなかった｜　ようです。",
                normal: "タワポンさんは　試験に　ごうかくしなかった　ようです。",
                en: "Mr. Thawaphon seems to have failed the exam.",
                mm: "မစ္စတာ သောဝါဖွန် စာမေးပွဲ ကျရှုံးပုံရသည်။",
            },
            {
                furi: "＾外（そと）｜は　｜！さむい｜　ようです。",
                normal: "外は　さむい　ようです。",
                en: "It looks cold outside.",
                mm: "အပြင်မှာ အေးနေပုံရတယ်။",
            },
            {
                furi: "＾部（ぶ）長（ちょう）｜は　｜＾甘（あま）｜い　｜＾物（もの）｜が　｜！すきな｜　ようです。",
                normal: "部長は　甘い　物が　すきな　ようです。",
                en: "The manager seems to like sweets.",
                mm: "မန်နေဂျာက အချို ကြိုက်ပုံရတယ်။",
            },
            {
                furi: "＾小（お）川（がわ）｜さんの　｜＾話（はなし）｜は　｜！ほんとうの｜　ようです。",
                normal: "小川さんの　話は　ほんとうの　ようです。",
                en: "Mr. Ogawa's story seems to be true.",
                mm: "မစ္စတာ Ogawa ၏ ဇာတ်လမ်းသည် အမှန်ဖြစ်ပုံရသည်။",
            }
        ],
        audioUrl: require("@assets/audio/lesson47/renshua/4N47RSA2.mp3")
    },
];