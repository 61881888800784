export const reibun= [
    {
        sentence: [
            {
                furi: "お｜＾見（み）合（あ）｜いは　どうでしたか。",
                normal: "お見合いは　どうでしたか。",
                en: "How was your date?",
                mm: "မင်းရဲ့dateက ဘယ်လိုလဲ။",
            },
            {
                furi: "＾写（しゃ）真（しん）｜を　｜＾見（み）｜た　ときは、すてきな　｜＾人（ひと）｜だと　｜＾思（おも）｜いましたが、｜＾会（あ）｜って、がっかりしました。",
                normal: "写真を　見た　ときは、すてきな　人だと　思いましたが、会って、がっかりしました。",
                en: "When I saw the picture, I thought he was a nice person, but when I met him, I was disappointed.",
                mm: "ပုံကိုမြင်တော့ သူက ချစ်စရာကောင်းတယ်လို့ ထင်ခဲ့ပေမယ့် သူနဲ့တွေ့တဲ့အခါမှာတော့ စိတ်ပျက်မိပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson39/bunpou/4N39RB1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾今（こん）度（ど）｜の　｜＾土（ど）曜（よう）日（び）｜に　みんなで　ハイキングに　｜＾行（い）｜くんですが、いっしょに　｜＾行（い）｜きませんか。",
                normal: "今度の　土曜日に　みんなで　ハイキングに　行くんですが、いっしょに　行きませんか。",
                en: "We're going hiking together next Saturday, would you like to go with us?",
                mm: "ငါတို့အားလုံး လာမယ့်စနေနေ့မှာ တောင်တက်ကြမယ်၊ မင်း ငါတို့နဲ့ လိုက်ချင်လား။",
            },
            {
                furi: "すみません。｜＾土（ど）曜（よう）日（び）｜は　ちょっと　｜＾都（つ）合（ごう）｜が　｜＾悪（わる）｜くて、｜＾行（い）｜けないんです。",
                normal: "すみません。土曜日は　ちょっと　都合が　悪くて、行けないんです。",
                en: "sorry. Saturday is a bit inconvenient, so I can't go.",
                mm: "တောင်းပန်ပါတယ်။ စနေနေ့က နည်းနည်း အဆင်မပြေလို့ မသွားနိုင်ဘူး။",
            },
        ],
        audioUrl: require("@assets/audio/lesson39/bunpou/4N39RB2.mp3")
    },
    {
        sentence: [
            {
                furi: "きのうの　｜＾映（えい）画（が）｜は　どうでしたか。",
                normal: "きのうの　映画は　どうでしたか。",
                en: "How was the movie yesterday?",
                mm: "မနေ့က ရုပ်ရှင်က ဘယ်လိုလဲ။",
            },
            {
                furi: "＾話（はなし）｜が　｜＾複（ふく）雑（ざつ）｜で、よく　わかりませんでした。",
                normal: "話が　複雑で、よく　わかりませんでした。",
                en: "The story was complicated and I didn't understand it well.",
                mm: "ဇာတ်လမ်းက ရှုပ်ထွေးပြီး ကောင်းကောင်းနားမလည်ဘူး။",
            },
        ],
        audioUrl: require("@assets/audio/lesson39/bunpou/4N39RB3.mp3")
    },
    {
        sentence: [
            {
                furi: "＾遅（おそ）｜く　なって、すみません。",
                normal: "遅く　なって、すみません。",
                en: "I'm sorry for being late.",
                mm: "နောက်ကျတဲ့အတွက် တောင်းပန်ပါတယ်။",
            },
            {
                furi: "どう　したんですか。",
                normal: "どう　したんですか。",
                en: "What happened.",
                mm: "ဘာဖြစ်တာလဲ။",
            },
            {
                furi: "＾事（じ）故（こ）｜で　バスが　｜＾遅（おく）｜れたんです。",
                normal: "事故で　バスが　遅れたんです。",
                en: "The bus was late due to an accident.",
                mm: "မတော်တဆမှုတစ်ခုကြောင့် ဘတ်စ်ကားနောက်ကျသွားတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson39/bunpou/4N39RB4.mp3")
    },
    {
        sentence: [
            {
                furi: "ちょっと　｜＾飲（の）｜みに　｜＾行（い）｜きませんか。",
                normal: "ちょっと　飲みに　行きませんか。",
                en: "Would you like to go have a drink?",
                mm: "သွားသောက်မလား",
            },
            {
                furi: "すみません。｜＾用（よう）事（じ）｜が　あるので、お｜＾先（さき）｜に　｜＾失（しつ）礼（れい）｜します。",
                normal: "すみません。用事が　あるので、お先に　失礼します。",
                en: "sorry. I have something to do, so please excuse me in advance.",
                mm: "တောင်းပန်ပါတယ်။ ငါလုပ်စရာရှိလို့ ကြိုသွားခွင့်ပြုပါ။",
            },
            {
                furi: "そうですか。お｜＾疲（つか）｜れさまでした。",
                normal: "そうですか。お疲れさまでした。",
                en: "Is that so. Thank you for your hard work.",
                mm: "ဟုတ်လား။ ပင်ပန်းသွားပါပြီ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson39/bunpou/4N39RB5.mp3")
    },
    {
        sentence: [
            {
                furi: "＾最（さい）近（きん）｜　｜＾布（ふ）団（とん）｜で　｜＾寝（ね）｜て　いるんですが、｜＾便（べん）利（り）｜ですね。",
                normal: "最近　布団で　寝て　いるんですが、便利ですね。",
                en: "I've been sleeping on a futon recently, and it's convenient.",
                mm: "မကြာသေးမီက ကျွန်တော် ဖူတွန်ပေါ်မှာ အိပ်ပြီး အဆင်ပြေ‌‌နေတယ်။",
            },
            {
                furi: "ベッドは　どう　したんですか。",
                normal: "ベッドは　どう　したんですか。",
                en: "What happened to your bed?",
                mm: "မင်းရဲ့အိပ်ယာက ဘာဖြစ်သွားတာလဲ။",
            },
            {
                furi: "＾部（へ）屋（や）｜が　｜＾狭（せま）｜くて、｜＾邪（じゃ）魔（ま）｜なので、｜＾友（とも）達（だち）｜に　あげました。",
                normal: "部屋が　狭くて、邪魔なので、友達に　あげました。",
                en: "",
                mm: "အခန်းကကျဉ်း‌‌တော့ အ‌နှောင့်အယှက်ဖြစ်လို့ သူငယ်ချင်းကို ‌ပေးလိုက်တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson39/bunpou/4N39RB6.mp3")
    },
]