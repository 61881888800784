export const explain= [
    { 
        pattern_furi: '＾意（い）向（こう）形（けい）｜-Verb',
        // pattern_normal: '＾友（とも）達（だち）｜とコーヒー',
        pattern_normal: '意向形-Verb',
        en: [
            '　It is used to talk about what is going to do.',
            "　–let's（same as let's）",
            '　「意向形（いこうけい）」 is a form used between close people, friend, family.'
        ],
        mm: [
            '　မိမိပြုလုပ်မည့် အကြောင်းအရာကို ပြောရာတွင်သုံးသည်။',
            '　–ရအောင် （ましょうနှင့်ဆင်တူသည်）',
            '「意向形（いこうけい）」သည် သူငယ်ချင်း၊ မိသားစု၊ ရင်းနှီးသောသူများအကြား သုံးသောပုံစံဖြစ်ပါသည်။'
        ]
    },
    {
        pattern_furi: '＾意（い）向（こう）形（けい）｜Vと｜＾思（おも）｜っています',
        pattern_normal: '意向形（いこうけい）Vと思（おも）っています',
        en: [
            '　Saying an idea, a plan, a desire',
            '　意向形（いこうけい）Vと思（おも）ですます is used in the context of intending to do, and Vですますです is used in cases where one has definitely decided to do.',
        ],
        mm: [
            '　စိတ်ကူးထားသော စီစဉ်ထားသော ကိစ္စရပ်တစ်ခု စိတ်ဆန္ဒတစ်ခုကို ပြောခြင်း',
            '　意向形（いこうけい）Vと思（おも）っていますကို လုပ်ရန်ရည်ရွယ်ထားသော အကြောင်းအရာတွင်သုံးပြီး Vつもりです ကို ကျိန်းသေလုပ်ရန် ဆုံးဖြတ်ထားသော ကိစ္စများတွင်သုံးသည်။',
        ]
    },
    {
        pattern_furi: 'Vつもりです',
        pattern_normal: 'Vつもりです',
        en: [
            '　Saying an idea, a plan, a desire',
        ],
        mm: [
            '　ရည်ရွယ်တယ် （ကျိန်းသေပြုလုပ်မည့် ကိစ္စတစ်ခုကိုပြောခြင်း）',
        ]
    },
    {
        pattern_furi: '＾予（よ）定（よてい）｜です',
        pattern_normal: '予定です',
        en: [
            '　Planned （used to talk about schedules）',
        ],
        mm: [
            '　စီစဉ်ထားပါတယ်။ အစီအစဉ်ဆွဲထားပါတယ် （နေ့လရက်များ schedule များကိုပြောရာတွင် အသုံးများ）',
        ]
    }

]