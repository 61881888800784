import { _success, _info, _warning, _error } from 'react-color-log';
import logReg from './logReg';

const Logger = (regNo)=>{
  // set true when deploy
  const config = { deploy: false }
  const {screen,output} = logReg[regNo] || {screen:"unknown",output:false};
  return config.deploy || !output?
  {
    sys: (...log) => {
      console.log(screen);
      log.map( l=> typeof l === 'object'? _success(JSON.stringify(l)) : _success(l) );
    },i: ()=>{},s: ()=>{}, w: ()=>{}, e: ()=>{}
  }:
  { //log.sys
    sys: (...log) => {
      console.log(screen);
      log.map( l=> typeof l === 'object'? _success(JSON.stringify(l)) : _success(l) );
    },
    i: (...log)=>{
      console.log(screen);
      log.map( l=> typeof l === 'object'? _info(JSON.stringify(l)) : _info(l) );
    },
    s: (...log)=>{
      console.log(screen);
      log.map( l=> typeof l === 'object'? _success(JSON.stringify(l)) : _success(l) );
    },
    w: (...log)=>{
      console.log(screen);
      log.map( l=> typeof l === 'object'? _warning(JSON.stringify(l)) : _warning(l) );
    },
    e: (...log)=>{
      console.log(screen);
      log.map( l=> typeof l === 'object'? _error(JSON.stringify(l)) : _error(l) );
    }
  }
}
export default Logger;