export const explain= [
    {
        // ＾友（とも）達（だち）｜
        pattern_furi: 'Nのために/Vために',
        pattern_normal: 'Nのために/Vために',
        mm: [
            "　-ရန်/အတွက်/ဖို့ ",
            "　(တစ်ခုခုအတွက် ရည်ရွယ်၍ တစ်ခုခုကိုပြုလုပ်ခြင်း)",
        ],
        en: [ 
            '　to/for',
            '　(doing something for the purpose of something)',
        ]
    },
    {
        pattern_furi: 'Nに/Vのに',
        pattern_normal: 'Nに/Vのに',
        mm: [
            "　-ရန်/အတွက်/ဖို့ ",
            "　ためにဖြင့် သုံးပုံအလွန်တူပြီး အဓိပ္ပါယ်လည်းတူပါသည်။ ",
            "　Nに/Vのに‌နောက်တွင် べんります、つかいます、いいですတို့ အလိုက်များသည်။",
        ],
        en: [ 
            '　to/for',
            '　very similar and has the same meaning with ために.',
            '　After Nに/Vのに,べんります、つかいます、いいです are common.',
        ]
    },
    {
        pattern_furi: '～は/～も',
        pattern_normal: '～は/～も',
        mm: [
            "　～はကို ပြောသူ၏ ခံစားချက်နှင့်လိုက်၍ အရေအတွက်၊ ကိန်းဂဏန်း အနဲကို ပိုမိထင်ရှားအောင်ပြောသော အခါတွင်သုံးသည်။ ",
            "　もကို ပြောသူ၏ ခံစားချက်နှင့်လိုက်၍ အရေအတွက်၊ ကိန်းဂဏန်းအများ၊ ထင်ထားတာထက် များနေသောအခါများတွင် သုံးသည်။",
        ],
        en: [ 
            '　～は is used when the numbers are more obvious according to the feeling of the speaker.',
            '　も is used when  the numbers are more obvious according to the feeling of the speaker.',
        ]
    },
 
]