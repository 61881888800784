import { Card, Typography } from "@material-tailwind/react";
import { memo } from "react";

const TableComponent = ({table,furiTranslate, fontSize}:any)=>{
    const align = (style: string)=>{
      return style.includes("text-left")? "justify-start"
      : style.includes("text-right")? "justify-end" : "justify-center";
    }
    return (
        // overflow-scroll
      <Card className="overflow-hidden h-fit rounded-[20px]">
        <table className="table-auto text-left ">
          {/* w-full min-w-max  */}
            <thead>
              <tr>
                {
                  Object.entries(table.head).map(([key,{val,span,style}]:any)=>{
                    return (
                      <th colSpan={span} key={Math.random()} className={`border-b py-4 text-center bg-[#c7e7ff] dark:bg-navbar border-[#c7e7ff] dark:border-navbar ${style}`}>
                        <Typography variant="small" color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {
                            val.includes("＾") || val.includes("！")? 
                                furiTranslate({ context: val, fontSize, style: "flex-wrap " + align(style) ,notUseLibrary: true }) 
                                : val
                          }
                        </Typography>
                    </th>
                  )
                  })
                }
              </tr>
            </thead>
            <tbody>
              {table.body.map((body: any, index: any) => {
                return (
                  <tr key={Math.random()} className=" border-[#c7e7ff] dark:border-navbar">
                    {
                        Object.entries(body).map(([key,value]:any)=>{
                            const furiStyle = table.style[key].includes("text-center")? "justify-center":
                              table.style[key].includes("text-right")? "justify-end" : "";
                            return (
                            <td key={Math.random()} className={table.style[key]}>
                                <Typography variant="small" color="blue-gray" className="font-normal">
                                    {
                                        value.includes("＾") || value.includes("！")? 
                                            furiTranslate({ 
                                              context: value, 
                                              fontSize, 
                                              style: `flex-wrap ${furiStyle}`
                                            })
                                        : value
                                    }
                                </Typography>
                            </td>
                            );
                        })
                    }
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
      );
}
export default memo(TableComponent);