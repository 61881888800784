import { memo } from "react"
import Contact from "@assets/icon/contact/contact-vector 1.svg";
import Phone from "@assets/icon/contact/phone 1.svg";
import Gmail from "@assets/icon/contact/gmail 1.svg";
import Telegram from "@assets/icon/contact/telegram.svg";
import Line from "@assets/icon/contact/line.svg";
import Facebook from "@assets/icon/contact/facebook 1.svg";
import Viber from "@assets/icon/contact/viber.svg"
import { useAppSelector as selector } from '@store/hooks';
import { Card } from "@material-tailwind/react";
const ContactMobileComponent = ()=>{
    const {lang} = selector(({ service }) => service);
    const content = lang === 'en'?"Contact Integrated Solution for any inquiries regarding Minna Master N4, your ultimate Japanese language learning tool. We're here to assist you with expertise in Japanese language applications. Feel free to reach out for support, questions, or collaboration opportunities. Your language journey starts here!":"Minna Master N4 နှင့်ပတ်သက်သည့် စုံစမ်းမေးမြန်းမှုများအတွက် Integrated Solution သို့ဆက်သွယ်နိုင်ပါသည်။ သင်အတွက် ‌အကောင်းဆုံးဖြစ်သော ဂျပန်စာ သင်ကြားရေး အက်ပ်လီကေးရှင်းများဖြင့် ကူညီပေးရန် ကျွန်ုပ်တို့ရှိနေပါသည်။ ပံ့ပိုးကူညီမှု၊ မေးခွန်းများ သို့မဟုတ် ပူးပေါင်းဆောင်ရွက်မည့် အခွင့်အလမ်းများအတွက် ဒီနေရာကနေ စတင်လိုက်ပါ!";
    return <div className="md:hidden text-[#424242] dark:text-[#D4D4D4]">
        <div className="text-[20px] flex flex-col items-center justify-center">
            Contact Us
            <img src={Contact} className="w-[250px] h-[250px]" />
            <div className="text-[16px]">
            {content}
            </div>
        </div>
        <div className="flex-1 font-semibold my-[20px]">
              <a href="tel:+959421208939" className="flex flex-row items-center">
                <div className="mr-3"><img src={Phone} className="w-[30px] h-[30px]" /></div>
                <div>+959421208939</div>
            </a>
            <a href="mailto:mm.integratedsolution@gmail.com" target="_blank" className="flex flex-row items-center mt-3">
                <div className="mr-3"><img src={Gmail} className="w-[30px] h-[30px]" /></div>
                <div> mm.integratedsolution@gmail.com</div>
            </a>
        </div>
        <div className=" flex flex-row justify-center">
            <Card className="w-fit px-5 py-1 rounded-full bg-white dark:bg-darkFooter shadow-inner drop-shadow-[#b8b9b9] flex flex-row justify-center items-center">
                <a href="https://www.facebook.com/Integrated-solution-106595108344646" target="_blank" className="mx-3"><img src={Facebook} /></a>
                <a href="https://t.me/@arkarmannaung" target="_blank" className="mx-3"><img src={Telegram} /></a>
                <a href="https://line.me/ti/p/tBqsuGnB-Q" target="_blank" className="mx-3"><img src={Line} /></a>
                <a href="viber://chat?number=959780132792" target="_blank" className="mx-3"><img src={Viber} /></a>
            </Card>
        </div>
    </div>
}
export default memo(ContactMobileComponent);