import Icon from "@consts/icon";

export const lesson49 = {
    title: {
        furi: 'よろしく　お｜＾伝（つた）｜え　ください',
        normal: 'よろしく　お伝え　ください',
        en: 'Please let me know',
        mm: 'ကျေးဇူးပြုပြီးတစ်ဆင့်ပြောပေးပါ',
    },
    conversation: [
        {
            person: {
                furi: '＾先（せん）生（せい）',
                normal: '先生',
                avatar:　Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'はい、ひまわり｜＾小（しょう）学（がっ）校（こう）｜です。',
                    normal: 'はい、ひまわり小学校です。',
                    en: "Yes, it's Himawari Elementary School.",
                    mm: 'ဟုတ်ကဲ့၊ ဟိမဝါရိ မူလတန်းကျောင်းကဖြစ်ပါတယ်',
                },
            ],
        },
        {
            person: {
                furi: 'クララ',
                normal: 'クララ',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'おはよう　ございます。',
                    normal: 'おはよう　ございます。',
                    en: 'Good Morning',
                    mm: 'မင်္ဂလာနံနက်ခင်းပါ',
                },
                {
                    furi: '５｜＾年（ねん）｜２｜＾組（くみ）｜の　ハンス・シュミットの　｜＾母（はは）｜ですが、｜＾伊（い）藤（とう）先（せん）生（せい）｜は　いらっしゃいますか。',
                    normal: '５年２組の　ハンス・シュミットの　母ですが、伊藤先生は　いらっしゃいますか。',
                    en: "I'm Hans Smith mother in class 5, but is Ito-sensei here?",
                    mm: '၅နှစ်အတန်း အခန်း၂ရဲ့ ဟန်စုစမစ်ရဲ့ အမေပါ၊ ဆရာအိတို ရှိပါသလား?',
                },
            ],
        },
        {
            person: {
                furi: '＾先（せん）生（せい）',
                normal: '先生',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'まだなんですが。。。',
                    normal: 'まだなんですが。。。',
                    en: 'Not yet. . .',
                    mm: 'မရှိသေးပါဘူး',
                },
            ],
        },
        {
            person: {
                furi: 'クララ',
                normal: 'クララ',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'では、｜＾伊（い）藤（とう）先（せん）生（せい）｜に　｜＾伝（つた）｜えて　いただきたいんですが。。。',
                    normal: 'では、伊藤先生に　伝えて　いただきたいんですが。。。',
                    en: 'Then, I would like you to tell Mr. Ito. . .',
                    mm: 'ဒါဖြင့်ရင် ဆရာအိတိုကို တစ်ဆင့်ပြောပေးလို့ရမလား?',
                },
            ],
        },
        {
            person: {
                furi: '＾先（せん）生（せい）',
                normal: '先生',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'はい、｜＾何（なん）｜でしょうか。',
                    normal: 'はい、何でしょうか。',
                    en: 'yes what is it',
                    mm: 'ဟုတ်ကဲ့ ဘာများပြောပေးရမလဲ?',
                },
            ],
        },
        {
            person: {
                furi: 'クララ',
                normal: 'クララ',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: '＾実（じつ）｜は　ハンスが　ゆうべ　｜＾熱（ねつ）｜を｜＾出（だ）｜しまして、けさも　まだ　｜＾下（さ）｜がらないんです。',
                    normal: '実は　ハンスが　ゆうべ　熱を　出しまして、けさも　まだ　下がらないんです。',
                    en: "Actually, Hans had a fever last night, and this morning he still hasn't recovered.",
                    mm: 'တစ်ကယ်တော့ ဟန်စုက မနေ့ညက အဖျားတက်ခဲ့ပြီးတော့ ခုမနက်ထိအဖျားမကျသေးလို့ပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾先（せん）生（せい）',
                normal: '先生',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'それは　いけませんね。',
                    normal: 'それは　いけませんね。',
                    en: "That won't happen",
                    mm: 'အဲ့ဒါမဖြစ်ဘူးနော်',
                },
            ],
        },
        {
            person: {
                furi: 'クララ',
                normal: 'クララ',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'それで　きょうは　｜＾学（がっ）校（こう）｜を　｜＾休（やす）｜ませますので、｜＾先（せん）生（せい）｜に　よろしくお｜＾伝（つた）｜え　ください。',
                    normal: 'それで　きょうは　学校を　休ませますので、先生に　よろしくお伝え　ください。',
                    en: 'So I will ask you to take a leave of absence from school today, so tell the teacher through it',
                    mm: 'အဲ့ဒါနဲ့ပဲ ဒီနေ့ ကျောင်းကိုခွင့်ယူခိုင်းမှာမို့လို့ ဆရာ့ကိုတစ်ဆင့်ပြောပေးပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾先（せん）生（せい）',
                normal: '先生',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'わかりました。どうぞ　お｜＾大（だい）事（じ）｜に。',
                    normal: 'わかりました。どうぞ　お大事に。',
                    en: 'got it. Please take care of her.',
                    mm: 'နားလည်ပါပြီ၊ ဂရုစိုက်ပါနော်',
                },
            ],
        },
        {
            person: {
                furi: 'クララ',
                normal: 'クララ',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'ありがとう　ございます。｜＾失（しつ）礼（れい）｜いたします。',
                    normal: 'ありがとう　ございます。失礼いたします。',
                    en: 'thank you. excuse me.',
                    mm: '‌ကျေးဇူးအများကြီးတင်ပါတယ်၊ ခွင့်ပြုပါအုန်း',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson49/kaiwa/4N49KW.mp3")
}
/*
{
    title: {
        furi: '',
        normal: '',
        en: '',
        mm: '',
    },
    conversation: [
        {
            person: {
                furi: '',
                normal: '',
                avatar: Icon.Kaiwa.OFFICER
            },
            sentence: [
                {
                    furi: '',
                    normal: '',
                    en: '',
                    mm: '',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson36/kaiwa/4N36KW.mp3")
}
*/