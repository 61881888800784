import SizeBox from "@components/SizeBox";
import Number from "@components/common/Number";
import Helper from "@helper";
import { CompoundTile } from "@screens/home/category/KanjiDetailScreen";
import { memo } from "react"

const KanjiCompoundWebComponent = ({lesson, compound ,furigana,furiTranslate,fontSize,kanjiDetail,lang}:any) => {
    const style = {
        container: 'flex-1 flex flex-row items-center px-[50px]',
        design: ' border-b-[1px] border-white'
    }
    return <div className="relative hidden md:flex flex-row ">
        <div className={`absolute left-0 px-[18px] py-[8px] rounded-r-[100px] text-[${fontSize}px] bg-[#0084E8] bunkei-title-light text-white w-fit`}>
            Compound
        </div>
        <div className="mt-[60px] w-full ">
            {
                compound.map(({ furi, normal, en, mm }: any,i:number) => {
                    return (<div key={Math.random()}>
                        <div className={`flex flex-row items-center relative dark:text-white`}>
                            {
                                <div className={`${Helper.pushStyle(style)} bg-[#D0EBFF] border-white dark:border-[#424242] dark:bg-[#001829] `}>
                                    <div className=" py-2">
                                        <Number fontSize={fontSize} furigana={furigana}>
                                            {i+1}
                                            <span className="ml-2">.</span>
                                        </Number>
                                    </div>
                                    <div className="flex-1">{furigana === 'true' ? furiTranslate({ context: furi, fontSize, style: "flex-wrap items-center", kanjiDetail }) : normal}</div>
                                </div>
                            }
                            {
                                <div className={`${Helper.pushStyle(style)} border-[#C9C9C9] dark:border-[#424242] bg-white dark:bg-[#162027] `}>
                                    <div className={`${furigana == 'true' ? "py-3" :"py-2"}  flex-1 flex flex-row`}>
                                        {(lang === "en") ?
                                            <div>{en}</div>
                                            :
                                            <div>{mm}</div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>)
                })
            }
        </div>
        
       
    </div>
}
export default memo(KanjiCompoundWebComponent);
 