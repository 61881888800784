export const explain= [
    { 
        // pattern_furi: '＾命（めい）令（れい）形（けい）｜/｜＾禁（きん）止（し）形（けい）｜',
        pattern_furi: '~｜＾条（ じょう）件（けん）形（けい）',
        pattern_normal: '~条件形 ',
        en: [
            '　It ends with ~ば.',
            '　It is often used as an conjection.',
        ],
        mm: [
            '　~ばနှင့်အဆုံးသတ်သည်။',
            '　စကားဆက်အဖြစ်အသုံးများသည်။',
        ]
    },
    {
        pattern_furi: '~ば ',
        pattern_normal: '~ば ',
        en: [
            '　If~',
            '　For example, 言(い)えて、聞(き)さな are characters.',
            '　It follows the past tense of the verb and the noun.',
        ],
        mm: [
            '　–ရင်',
            '　ဥပမာ 言(い)えば、聞(き)けば စသည့်စာလုံးများဖြစ်သည်။',
            '　အတိတ်ကြိယာပုံစံနှင့် နာမ်၏နောက်တွင် အလိုက်များသည်',
        ]
    },
    {
        pattern_furi: 'なれば ',
        pattern_normal: 'なれば ',
        en: [
            "　If/when",
            '　It is often used as an conjection.',
        ],
        mm: [
            '　–ဖြစ်ရင်/ရောက်ရင် ',
            '　စကားဆက်အဖြစ်အသုံးများသည်။',
        ]
    },
    {
        pattern_furi: '~ば~ほど',
        pattern_normal: '~ば~ほど',
        en: [
            "　As much as it happens/As much as you do",
            "　Used to describe concepts that change.",
        ],
        mm: [
            '　–ဖြစ်/လုပ်ရင် ဖြစ်/လုပ်သလောက်',
            '　ပုံစံပြောင်းလဲတတ်သော သဘောတရားများကို ဖေါ်ပြရာတွင်သုံးသည်။',
        ]
    }
]