import { BunpouType } from "..";

export const bunpou: BunpouType = {
  mondai1:{
    title: 'もんだい１（　　）に　何を　入れますか。１・２・３・４から　いちばん　いい　ものを　ーつ　えらんで　ください。',
    questions: [
      {
        number: 1,
        question: [
          '(1)わたしの　父は　中国語も　英語（　　）話せます。',
        ],
        answers: ['を','で','も','が'],
        pick: '',
        correct: 'も'
      },
      {
        number: 2,
        question: ['(2)こらは　わたしが　米（　　）　作った　パンです。'],
        answers: ['に','の','や','で'],
        pick: '',
        correct: 'で'
      },
      {
        number: 3,
        question: [
          '(3)男の子の　顔は　父親より　母親（　　）にると　いう　話を　聞いた。',
        ],
        answers: ['に','へ','も','を'],
        pick: '',
        correct: 'に'
      },
      {
        number: 4,
        question: [
          '(4)妹は　おしゃべりだ。静かなのは、食事の　とき（　　）だ。',
        ],
        answers: ['しか','だけ','は','の'],
        pick: '',
        correct: 'だけ'
      },
      {
        number: 5,
        question: [
          '(5)A「誕生日に　田中さんから　何を　もらったんですか。」',
          'B「田中さん（　　）　腕時計を　もらいました。」',
        ],
        answers: ['からが','からに','からで','からは'],
        pick: '',
        correct: 'からは'
      },
      {
        number: 6,
        question: [
          '(6)（会社で）',
          'A「すみません、山田さんは　どこですか。」',
          'B「山田さんは　会議中ですが、会議は　11時半（　　）終わると　おもいますよ。',
        ],
        answers: ['までは','までには','までも','までにも'],
        pick: '',
        correct: 'までには'
      },
      {
        number: 7,
        question: [
          '(7)先生「先生、この　言葉は（　　）意味ですか。」',
          '先生「「やさしい」と　いう　いみです。」'
        ],
        answers: ['どうやって','どのぐらい','どういう','どう'],
        pick: '',
        correct: 'どういう'
      },
      {
        number: 8,
        question: [
          '(8)チャン「古川さん、この　じゅぎょうの　レポート、おわりましたか。」',
          '古川「はい、一週間　かかりましたが、きのうの　夜、（　　）終わりました。」'
        ],
        answers: ['やっと','ずっと','もっと','きっと'],
        pick: '',
        correct: 'やっと'
      },
      {
        number: 9,
        question: [
          '(9) A「あした　いっしょに　ドライブに　行きませんか。」',
          'B「（　　）、ごめんなさい、あしたは　アルバイトが　あるんです。」',
        ],
        answers: ['行きたいから','行きたいけれど','行きたいし','行きたくて'],
        pick: '',
        correct: '行きたいけれど'
      },
      {
        number: 10,
        question: [
          '(10)子ども「ねえ、ダームを　しても　いい？」',
          '母親「しゅくだいを（　　）あとでね。」',
        ],
        answers: ['していた','している','する','した'],
        pick: '',
        correct: 'した'
      },
      {
        number: 11,
        question: ['(11)この　すいかは（　　）すぎて、れいぞうこに　入らない。'],
        answers: ['大き','大きい','大きく','大きくて'],
        pick: '',
        correct: '大き'
      },
      {
        number: 12,
        question: [
          '(12)A「誕生日　おめでとうございます。これ、プレゼントです。どうぞ。」',
          'B「ありがとうございます。大切に（　　）。」',
        ],
        answers: ['します','います','なります','あります'],
        pick: '',
        correct: 'します'
      },
      {
        number: 13,
        question: [
          '(13)A「きのうは　ひっこしを（　　）、どうも　ありがとうございました。」',
          'B「いいえ。どういたしまして。」',
        ],
        answers: ['手伝って','手伝ったので','手伝って　くれて','手伝ったから'],
        pick: '',
        correct: '手伝って　くれて'
      },
      {
        number: 14,
        question: [
          '(14)今日、わたしは　えんぴつと　消しゴムを　忘れたので、となりの　人に　貸して（　　）。',
        ],
        answers: ['やりました','あげました','くれました','もらいました'],
        pick: '',
        correct: 'もらいました'
      },
      {
        number: 15,
        question: [
          '(15)わたしの　本だなには（　　）読んで　いない　まんがが　たくさん　あります。',
        ],
        answers: ['買うあいだ','買ったまま','買いそうで','買っている'],
        pick: '',
        correct: '買ったまま'
      },
    ]
  },
  mondai2:{
    title: 'もんだい（2）｜！ ★ ｜ に　何を　入れますか。１・２・３・４から　いちばん　いい　ものを　ーつ　えらんで　ください。',
    questions: [
      {
        number: 16,
        question: [
          '(16)A「コンサートには　もう　間に合わないですね。」',
          'B「今すぐ｜！＿＿★＿｜行こう。',
        ],
        answers: ['タクシーに','タクシーで','間に合うかもしれないから','乗れば'],
        pick: '',
        correct: '間に合うかもしれないから'
      },
      {
        number: 17,
        question: [
          '(17)山田「田中さん、これから　食事でも　どうですか。」',
          '田中「すみません。ちょうど｜！＿＿★＿｜です。」',
        ],
        answers: ['なん','ところ','食べた','今'],
        pick: '',
        correct: 'ところ'
      },
      {
        number: 18,
        question: [
          '(18)A「あしたは　大事な　おきゃくさまに　会うから、｜！＿＿★＿｜いけませんよ。」',
          'B「わかりました。あしたは　新しい　くつを　はいて　来ます。」',
        ],
        answers: ['くつ','は','そんな','で'],
        pick: '',
        correct: 'で'
      },
      {
        number: 19,
        question: [
          '(19)今日は、風が｜！＿＿★＿｜出かけたくない。',
        ],
        answers: ['し','だから','寒そう','強い'],
        pick: '',
        correct: '寒そう'
      },
      {
        number: 20,
        question: [
          '(20)田中「山田さん。東京の　大学に　行く　ことを、もう　ご両親に　話しましたか。」',
          '田中「いいえ。でも、もし　両親に｜！＿＿★＿｜つもりです。」',
        ],
        answers: ['反対','する','東京で　勉強','されても'],
        pick: '',
        correct: '東京で　勉強'
      },
    ]
  },
}