
export const lesson12 = [
    {"lesson":12,"level":4,"question":"みんぞく","represent":["民","族"],"answer":["民族","両替","両方","自分"],"true":"民族","times":10},
    {"lesson":12,"level":4,"question":"じしん","represent":["自","信"],"answer":["姉妹","車両","自信","大家"],"true":"自信","times":10},
    {"lesson":12,"level":4,"question":"りょうがえ","represent":["両"],"answer":["両替","姉妹","父兄","両方"],"true":"両替","times":10},
    {"lesson":12,"level":4,"question":"きょうだい","represent":["兄","弟"],"answer":["自宅","父親","両親","兄弟"],"true":"兄弟","times":10},
    {"lesson":12,"level":4,"question":"あね","represent":["姉"],"answer":["家","私","姉","弟"],"true":"姉","times":10},
    {"lesson":12,"level":4,"question":"わたし","represent":["私"],"answer":["私","家","妹","弟"],"true":"私","times":10},
    {"lesson":12,"level":4,"question":"あに","represent":["兄"],"answer":["妹","親","兄","姉"],"true":"兄","times":10},
    {"lesson":12,"level":4,"question":"おや","represent":["親"],"answer":["姉","弟","親","家"],"true":"親","times":10},
    {"lesson":12,"level":4,"question":"いえ","represent":["家"],"answer":["姉","家","兄","弟"],"true":"家","times":10},
    {"lesson":12,"level":4,"question":"ふけい","represent":["父","兄"],"answer":["父兄","両親","両替","子弟"],"true":"父兄","times":10},
    {"lesson":12,"level":4,"question":"いもうと","represent":["妹"],"answer":["妹","家","私","兄"],"true":"妹","times":10},
    {"lesson":12,"level":4,"question":"ちちおや","represent":["父","親"],"answer":["民族","姉妹","父親","自信"],"true":"父親","times":10},
    {"lesson":12,"level":4,"question":"しゃりょう","represent":["車","両"],"answer":["自宅","父兄","車両","姉妹"],"true":"車両","times":10},
    {"lesson":12,"level":4,"question":"じぶん","represent":["自","分"],"answer":["自信","兄弟","自分","父兄"],"true":"自分","times":10},
    {"lesson":12,"level":4,"question":"りょうほう","represent":["両","方"],"answer":["父親","両方","父兄","家族"],"true":"両方","times":10},
    {"lesson":12,"level":4,"question":"してい","represent":["子","弟"],"answer":["自由","大家","自信","子弟"],"true":"子弟","times":10},
    {"lesson":12,"level":4,"question":"でし","represent":["弟","子"],"answer":["自由","弟子","家族","両方"],"true":"弟子","times":10},
    {"lesson":12,"level":4,"question":"りょうしん","represent":["両","親"],"answer":["大家","両親","自宅","民族"],"true":"両親","times":10},
    {"lesson":12,"level":4,"question":"じゆう","represent":["自"],"answer":["自信","自由","両替","家族"],"true":"自由","times":10},
    {"lesson":12,"level":4,"question":"じたく","represent":["自"],"answer":["家族","両方","車両","自宅"],"true":"自宅","times":10},
    {"lesson":12,"level":4,"question":"しまい","represent":["姉","妹"],"answer":["自宅","車両","姉妹","民族"],"true":"姉妹","times":10},
    {"lesson":12,"level":4,"question":"おとうと","represent":["弟"],"answer":["家","妹","弟","姉"],"true":"弟","times":10},
    {"lesson":12,"level":4,"question":"おおや","represent":["大","家"],"answer":["弟子","大家","父兄","自信"],"true":"大家","times":10},
    {"lesson":12,"level":4,"question":"かぞく","represent":["家","族"],"answer":["自宅","父兄","家族","子弟"],"true":"家族","times":10},
]