export const explain= [
    {
        // ＾友（とも）達（だち）｜
        pattern_furi: '～Nをいただきました',
        pattern_normal: '～Nをいただきました',
        en: [
            "　receive",
            "　Used when receiving something from a superior at work. (eg.parents who are older than him, teacher)",
        ],
        mm: [ 
            '　လက်ခံရရှိသည်',
            '　မိမိထက်အသက်ကြီးသူ မိဘ၊ ဆရာ၊ အလုပ်မှ အထက်လူကြီးထံမှ တစ်ခုခုကို လက်ခံရရှိသည့်အခါမျိုးတွင် သုံးသည်။',
        ]
    },
    {
        pattern_furi: 'Vて＋くださいました',
        pattern_normal: 'Vて＋くださいました',
        en: [
            "　It is accepted that something is done",
            "　Used when receiving something from a superior at work. (eg.parents who are older than him, teacher)",
        ],
        mm: [ 
            '　တစ်ခုခုကိုလုပ်ပေးတာကို လက်ခံသည်',
            '　မိမိထက်အသက်ကြီးသူ မိဘ၊ ဆရာ၊ အလုပ်မှ အထက်လူကြီးထံမှ တစ်ခုခုကို လက်ခံရရှိသည့်အခါမျိုးတွင် သုံးသည်။',
        ]
    },
    {
        pattern_furi: 'Vて＋やりました',
        pattern_normal: 'Vて＋やりました',
        en: [
            "　do something",
            "　It is used when you do something to subordinate.",
        ],
        mm: [ 
            '　တစ်ခုခုကိုလုပ်ပေးသည်',
            '　မိမိထက် အသက်ငယ်သူ လက်အောက်ငယ်သား၊ မောင်နှမ၊ တိရစ္ဆာန်၊ အပင်တွေကို ကိုယ်က တစ်ခုခုလုပ်ပေး သည့်အခါမျိုးတွင်သုံးသည်။',
        ]
    },
 
]