export const reibun= [
    {
        sentence: [
            {
                furi: "＾二（に）次（じ）会（かい）｜は　どこへ　｜＾行（い）｜きましたか。",
                normal: "二次会は　どこへ　行きましたか。",
                en: "Where did you go for second party?",
                mm: "ဒုတိယပွဲ ဘယ်ကိုသွားတာလဲ။",
            },
            {
                furi: "＾酔（よ）｜って　いたので、どこへ　｜＾行（い）｜ったか、｜＾全（ぜん）然（ぜん）｜　｜＾覚（おぼ）｜えて　いないです。",
                normal: "酔って　いたので、どこへ　行ったか、全然　覚えて　いないです。",
                en: "I was drunk, so I don't remember where I went.",
                mm: "မူးနေတော့ ဘယ်သွားလဲ မမှတ်မိတော့ဘူး။",
            },
        ],
        audioUrl: require("@assets/audio/lesson40/bunpou/4N40RB1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾山（やま）｜の　｜＾高（たか）｜さは　どうやって　｜＾測（はか）｜るか、｜＾知（し）｜って　いますか。",
                normal: "山の　高さは　どうやって　測るか、知って　いますか。",
                en: "Do you know how to measure the height of mountains?",
                mm: "တောင်တွေရဲ့ အမြင့်ကို ဘယ်လိုတိုင်းတာရမလဲ သိလား။",
            },
            {
                furi: "さあ。。インターネットで　｜＾調（しら）｜べましょう。",
                normal: "さあ。。インターネットで　調べましょう。",
                en: "here . . Look it up on the internet.",
                mm: "ကဲ။ . အင်တာနက်မှာ ရှာကြည့်ရ‌အောင်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson40/bunpou/4N40RB2.mp3")
    },
    {
        sentence: [
            {
                furi: "わたしたちが　｜＾初（はじ）｜めて　｜＾会（あ）｜ったのは いつか、｜＾覚（おぼ）｜えて　いますか。",
                normal: "わたしたちが　初めて　会ったのは いつか、覚えて　いますか。",
                en: "Do you remember when we first met?",
                mm: "ငါတို့ပထမဆုံးစတွေ့တုန်းက မှတ်မိသေးလား။",
            },
            {
                furi: "＾昔（むかし）｜の　ことなので、もう　｜＾忘（わす）｜れて　しまいました。",
                normal: "昔の　ことなので、もう　忘れて　しまいました。",
                en: "Since it was a long time ago, I have already forgotten.",
                mm: "တော်တော်ကြာနေပြီဆိုတော့ မေ့နေပြီ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson40/bunpou/4N40RB3.mp3")
    },
    {
        sentence: [
            {
                furi: "＾忘（ぼう）年（ねん）会（かい）｜に　｜＾出（しゅっ）席（せき）｜できるか　どうか、メールで　｜＾返（へん）事（じ）｜を　ください。",
                normal: "忘年会に　出席できるか　どうか、メールで　返事を　ください。",
                en: "Please reply by email if you can attend the year-end party.",
                mm: "နှစ်ကုန်ပါတီကို တက်ရောက်နိုင်လျှင် ကျေးဇူးပြု၍ အီးမေးလ်ဖြင့် အကြောင်းပြန်ပါ။",
            },
            {
                furi: "はい、わかりました。",
                normal: "はい、わかりました。",
                en: "Yes, I understand.",
                mm: "ဟုတ်ကဲ့, နားလည်ပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson40/bunpou/4N40RB4.mp3")
    },
    {
        sentence: [
            {
                furi: "＾大（だい）学（がく）｜に　｜＾出（だ）｜す　｜＾書（しょ）類（るい）｜なんですが、まちがいが　ないか　どうか、｜＾見（み）｜て　いただけませんか。",
                normal: "大学に　出す　書類なんですが、まちがいが　ないか　どうか、見て　いただけませんか。",
                en: "These are the documents I will send to the university. Could you please take a look at  if there are any mistakes?",
                mm: "ဒါတွေက ကျွန်တော် တက္ကသိုလ်ကို ပို့ပေးမယ့် စာရွက်စာတမ်းတွေမို့ အမှားအယွင်းတွေ ရှိမရှိ စစ်ဆေးပေးလို့ရမလား။",
            },
            {
                furi: "いいですよ。",
                normal: "いいですよ。",
                en: "OK.",
                mm: "အိုကေတယ်နော်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson40/bunpou/4N40RB5.mp3")
    },
    {
        sentence: [
            {
                furi: "＾長（なが）崎（さき）｜へ　｜＾行（い）｜った　ことが　ありますか。",
                normal: "長崎へ　行った　ことが　ありますか。",
                en: "Have you ever been to Nagasaki?",
                mm: "Nagasaki ကို ရောက်ဖူးပါသလား။",
            },
            {
                furi: "まだ　ありません。ぜひ　｜＾一（いち）度（ど）｜　｜＾行（い）｜って　みたいです。",
                normal: "まだ　ありません。ぜひ　一度　行って　みたいです。",
                en: "Not yet. I definitely want to go there once.",
                mm: "မသွားရသေးဘူး ။ သေချာပေါက် အဲဒီကို တစ်ခေါက်သွားချင်တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson40/bunpou/4N40RB6.mp3")
    },
]