export const explain= [
    {
        pattern_furi: 'Vて　+　あります',
        pattern_normal: 'Vて　+　あります',
        en: [
            '　have Someone or someone has done something',
            '　Can be used after Vて'
            ],
        mm: [
            '　ထားတယ်။ တစ်စုံတစ်ယောက် (သို့)မိမိက တစ်ခုခုကို လုပ်ထားခြင်း',
            '　Vて၏နောက်တွင် ထား၍သုံးသည်။'
        ]
    },
    {
        pattern_furi: 'Vて　+　おきます',
        pattern_normal: 'Vて　+　おきます',
        en: [
            '　Commonly used in preparatory cases.',
            '　Can be used after Vて'
        ],
        mm: [
            '　ထားတယ်။ ကြိုတင်ပြင်ဆင်ခြင်း ကိစ္စများတွင် အသုံးများသည်။',
            '　Vて၏နောက်တွင် ထား၍သုံးသည်။',
        ]
    },
    {
        pattern_furi: 'Vて　+　おいてください',
        pattern_normal: 'Vて　+　おいてください',
        en: [
            '　Asking to be prepared in advance; giving orders.',
            '　Can be used after Vて'
        ],
        mm: [
            '　ထားပါ။ ကြိုတင်ပြင်ဆင်ထားရန် တောင်းဆိုခြင်း၊ အမိန့်ပေးခြင်း။',
            '　Vて၏နောက်တွင် ထား၍သုံးသည်။',
        ]
    },
    {
        pattern_furi: 'まだ～ています',
        pattern_normal: 'まだ～ています',
        en: [
            '　Still doing. It is used when you want to say that it is not finished yet.',
            '　Can be used after Vて'
        ],
        mm: [
            '　နေတုန်းဘဲ။ကိစ္စတစ်ခုကို လုပ်နေတုန်း၊ မပြီးပြတ်သေးဘူးဟု ပြောချင်သောအခါတွင် သုံးသည်။',
            '　Vて၏နောက်တွင် ထား၍သုံးသည်။',
        ]
    },
]