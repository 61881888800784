import Icon from "@consts/icon";

export const lesson31 = {
    title: {
        furi: '＾料（りょう）理（り）｜を　｜＾習（なら）｜おうと　｜＾思（おも）｜っています',
        normal: '料理を　習おうと　思っています',
        en: "I'm thinking of learning to cook",
        mm: 'ဟင်းချက်တာ သင်ယူမယ်လို့တွေးထားတယ်။',
    },
    conversation: [
        {
            person: {
                furi: '＾小（お）川（がわ）',
                normal: '小川',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '＾来（らい）月（げつ）｜から　｜＾独（どく）身（しん）｜です。',
                    normal: '来月から　独身です。',
                    en: 'I will be single from next month.',
                    mm: 'နောက်လကနေစပြီး လူပျိုဖြစ်ပြီ',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'えっ？',
                    normal: 'えっ？',
                    en: 'eh?',
                    mm: 'အမ်',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）',
                normal: '小川',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '＾実（じつ）｜は　｜＾大（おお）阪（さか）｜の　｜＾本（ほん）社（しゃ）｜に　｜＾転（てん）勤（きん）｜なんです。',
                    normal: '実は　大阪の　本社に　転勤なんです。',
                    en: "Actually, I'm transferred to the head office in Osaka.",
                    mm: 'တစ်ကယ်တော့ အိုဆာကာရုံးချုပ်ကို အလုပ်ပြောင်းရမှာပါ။',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: '＾本（ほん）社（しゃ）｜ですか。それは　おめでとう　ございます。',
                    normal: '本社ですか。それは　おめでとう　ございます。',
                    en: 'Headquarters? Congratulations on that.',
                    mm: 'ရုံးချုပ်လား၊ အဲ့ဒီအတွက် ဂုဏ်ယူပါတယ်။',
                },
                {
                    furi: 'でも、どうして　｜＾独（どく）身（しん）｜に　なるんですか。',
                    normal: 'でも、どうして　独身に　なるんですか。',
                    en: 'But why are you single?',
                    mm: 'ဒါပေမယ့် ဘာလို့ လူပျိုဖြစ်သွားရတာတုန်း။',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）',
                normal: '小川',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '＾妻（つま）｜と　｜＾子（こ）｜どもは　｜＾東（とう）京（きょう）｜に　｜＾残（のこ）｜るんです',
                    normal: '妻と　子どもは　東京に　残るんです。',
                    en: 'My wife and children will remain in Tokyo.',
                    mm: 'အမျိုးသမီးနဲ့သားက တိုကျိုမှာပဲ နေခဲ့မှာပါ။',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'えっ、いっしょに　｜＾行（い）｜かないんですか。',
                    normal: 'えっ、いっしょに　行かないんですか。',
                    en: "Eh, why don't you go together?",
                    mm: 'အဲ တူတူမလိုက်ဘူးလား။',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）',
                normal: '小川',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'ええ。｜＾息（むす）子（こ）｜は　｜＾来（らい）年（ねん）｜　｜＾大（だい）学（がく）｜の　｜＾入（にゅう）学（がく）試（し）験（けん）｜が　あるから、',
                    normal: 'ええ。息子は　来年　大学の　入学試験が　あるから、',
                    en: 'yes. My son has his university entrance exam next year,',
                    mm: 'အင်း၊ သားက လာမယ့်နှစ်မှာ တက္ကသိုလ်ဝင်ခွင့်စာမွေးပွဲရှိတဲ့အတွက်',
                },
                {
                    furi: '＾東（とう）京（きょう）｜に　｜＾残（のこ）｜ると　｜＾言（い）｜うし、｜＾妻（つま）｜も　｜＾今（いま）｜の　｜＾会（かい）社（しゃ）｜を やめたくないと　｜＾言（い）｜うんです。',
                    normal: '東京に　残ると　言うし、妻も　今の　会社を やめたくないと　言うんです',
                    en: "He says he will stay in Tokyo, and his wife says she doesn't want to quit her current company.",
                    mm: 'တိုကျိုမှာ နေခဲ့မယ်လို့ပြောတယ်၊ အမျိုးသမီးကလည်းပဲ အခုလုပ်နေတဲ့ ကုမ္ပဏီကို မထွက်ချင်ဘူးလို့ ပြောတယ်။',
                }
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'それで、｜＾別（べつ）々（べつ）｜に　｜＾住（す）｜むんですね。',
                    normal: 'それで、別々に　住むんですね。',
                    en: 'So you live separately.',
                    mm: 'အဲ့ဒါနဲ့ပဲ သတ်သတ်စီခွဲနေကြရမယ်ဆိုပါတော့',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）',
                normal: '小川',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'ええ。でも、｜＾月（つき）｜に　２，３｜＾回（かい）｜　｜＾週（しゅう）末（まつ）｜に　｜＾帰（かえ）｜る　つもりです。',
                    normal: 'ええ。でも、月に　２，３回　週末に　帰る　つもりです。',
                    en: "yes. But a month he'll be her going home for the weekend a couple of times.",
                    mm: 'အင်း ဒါပေမယ် တစ်လမှာ နှစ်ကြိမ်၊ သုံးကြိမ် ပိတ်ရက်တွေမှာ ပြန်ဖို့အစီအစဉ်ရှိပါတယ်။',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: '＾大（たい）変（へん）｜ですね。',
                    normal: '大変ですね。',
                    en: "It's hard, isn't it?",
                    mm: 'ပင်ပန်းလိုက်တာနော်',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）',
                normal: '小川',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'でも、いい　チャンスですから、　｜＾料（りょう）理（り）｜を　｜＾習（なら）｜おうと　｜＾思（おも）｜っています。',
                    normal: 'でも、いい　チャンスですから、　料理を　習おうと　思っています。',
                    en: "But it's a good chance, so I'm thinking of learning to cook.",
                    mm: 'ဒါပေမယ့် အခွင့်အရေးရတုန်းမှာ ဟင်းချက်တာ သင်ယူမယ်လို့ တွေးထားပါတယ်',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'それは　いいですね。',
                    normal: 'それは　いいですね。',
                    en: 'I like it.',
                    mm: 'အဲ့ဒါ ကောင်းတယ်နော်။',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson31/kaiwa/4N31KW.mp3")
}