import SettingScreenLayout from "@layouts/setting/SettingScreenLayout";
import { ScreenPropType } from "App";
import { memo } from "react"
import { BsToggleOn } from "react-icons/bs";
import Moon from "@assets/icon/setting/Moon.svg";
import Cloud from "@assets/icon/setting/cloud.svg";
import Star from "@assets/icon/setting/star.svg";
import Sun from "@assets/icon/setting/sun-icon 1.svg";
import Appeareance from "@assets/icon/setting/paint 1.svg";
import About from "@assets/icon/setting/about 1.svg";
import Contact from "@assets/icon/setting/contact 1.svg";
import Privacy from "@assets/icon/setting/privacy 1.svg";
import { Card } from "@material-tailwind/react";
import { CgRadioCheck, CgRadioChecked } from "react-icons/cg";
import SizeBox from "@components/SizeBox";
const AppeareanceScreen = ({ Routes, Logger, dark, toggleTheme, fontSize, useNavigate, showModel, lang,changeLanguage,changeFontSize,setShowModel,setUnlockPremium}:ScreenPropType) => {
    const isDark = dark === 'dark';
    const themeDataLight = [
        {
            component: <img src={Cloud} width={70} height={70} />,
            style: "flex flex-row items-center pl-[25px]"
        },
        {
            component: <img src={Cloud} width={70} height={70} />,
            style: "flex flex-row items-center"
        },
        {
            component: <img src={Cloud} width={70} height={70} />,
            style: "flex flex-row items-start justify-end"
        },
        {
            component: <img src={Cloud} width={70} height={70} />,
            style: "flex flex-row items-start justify-end pr-[25px]"
        },
    ]
    const themeDataDark = [
        {
            component: <img src={Cloud} width={70} height={70} />,
            style: "flex flex-row justify-center items-center"
        },
        {
            component: <img src={Star} width={70} height={70} />,
            style: "flex flex-row justify-center items-center"
        },
        {
            component: <img src={Cloud} width={70} height={70} />,
            style: "flex flex-row justify-center items-center"
        },
        {
            component: <img src={Star} width={70} height={70} />,
            style: "flex flex-row justify-center items-center"
        },
        {
            component: <img src={Cloud} width={70} height={70} />,
            style: "flex flex-row justify-center items-start"
        },
        {
            component: <img src={Star} width={70} height={70} />,
            style: "flex flex-row items-center"
        },
    ]
    return <div>
        <SettingScreenLayout
            body={
                <div className=" md:hidden grid gap-5 container mx-auto mt-[40px] ">
                        {/*  */}
                        <div className="h-[250px] flex flex-col dark:border-none drop-shadow-md">
                            {/* Header */}
                            <div
                                className="bg-white rounded-t-[15px] border-b-2 border-[#e3e3e3] dark:border-slate-900 h-[50px] flex flex-row p-[25px] justify-between items-center dark:bg-[#001e34] shadow-lg" >
                                <div className="text-[#424242] dark:text-[#D4d4d4]">{isDark ? "Dark Mode" : "Light Mode"}</div>
                                {
                                    isDark ?
                                        <div onClick={!isDark ? () => { } : toggleTheme} className="btn p-3">
                                            <BsToggleOn size={30} className="text-navbar" />
                                        </div>
                                        :
                                        <div onClick={isDark ? () => { } : toggleTheme} className="btn p-3">
                                            <BsToggleOn size={30} className={`text-navbar rotate-180`} />
                                        </div>
                                }
                            </div>
                            {/* Body */}
                            <div className={`grid grid-cols-2 dark:grid-cols-3 flex-1 relative bg-[#e0f1ff] dark:bg-[#1b2b38]`}>
                                {
                                    isDark ? themeDataDark.map(({ component: Icon, style }, i) => {
                                        return <div key={i} className={style}>
                                            {Icon}
                                        </div>
                                    }) :
                                        themeDataLight.map(({ component: Icon, style }, i) => {
                                            return <div key={i} className={style}>
                                                {Icon}
                                            </div>
                                        })
                                }
                                {
                                    isDark ?
                                        <img src={Moon} width={70} height={70} className="absolute -bottom-[35px] right-[25px]" />
                                        :
                                        <img src={Sun} width={70} height={70} className="absolute -bottom-[35px] right-[25px]" />
                                }
                            </div>
                            <div className="h-[50px]  rounded-b-[15px] border-t-[1.5px] border-[#e3e3e3] dark:border-[#424242] bg-white dark:bg-[#001e34]"></div>
                        </div>
                        {/* Font Size */}
                        <div className="h-[250px] flex flex-col dark:border-none drop-shadow-md">
                            {/* Header */}
                            <div
                                className="bg-white rounded-t-[15px] border-b-2 border-[#e3e3e3] dark:border-slate-900 h-[50px] flex flex-row p-[25px] justify-between items-center dark:bg-[#001e34] shadow-lg" >
                                <div className="text-[#424242] dark:text-[#D4d4d4]">Font Size</div>
                            </div>
                            {/* Body */}
                            <div className={` flex-1 grid grid-cols-3 gap-[30px] gap-y-0 px-[20px] relative bg-white dark:bg-[#1b2b38]`}>
                                {
                                    [12, 14, 16, 18, 20].map((font, i) => {
                                        if (font === fontSize) {
                                            return (<div key={i} className="btn select-none  font-bold flex flex-row justify-center items-center">
                                                <div className="rounded-full bg-navbar text-white flex-1 py-[2px] text-center">{font}</div>
                                            </div>)
                                        } else {
                                            return (<div key={i} className="btn select-none flex flex-row justify-center items-center" onClick={() => changeFontSize(font)}>
                                                <div className="rounded-full outline outline-navbar flex-1 py-[2px] text-center">{font}</div>
                                            </div>)
                                        }
                                    })
                                }
                            </div>
                            <div className="h-[50px]  rounded-b-[15px] dark:border-t-[1.5px] dark:border-[#424242] bg-white dark:bg-[#001e34]"></div>
                        </div>
                        {/* Language */}
                        <div className="h-[250px] flex flex-col dark:border-none drop-shadow-md">
                            {/* Header */}
                            <div
                                className="bg-white rounded-t-[15px] border-b-2 border-[#e3e3e3] dark:border-slate-900 h-[50px] flex flex-row p-[25px] justify-between items-center dark:bg-[#001e34] shadow-lg" >
                                <div className="text-[#424242] dark:text-[#D4d4d4]">Language</div>
                            </div>
                            {/* Body */}
                            <div className={` flex-1 dark:bg-[#1b2b38] bg-white dark:text-[#D4D4D4]`}>
                                {
                                    ['en', 'mm'].map((language, i) => {
                                        if (language === lang) {
                                            return (
                                                <Card key={i} className="drop-shadow-md font-bold flex flex-row items-center justify-between rounded-md mx-[20px] my-[14px] px-[20px] py-[10px] border border-[#CACACA] dark:border-[#424242] bg-[#e0f1ff] dark:bg-[#004578]">
                                                    <div className="">
                                                        {language === 'en' ? "English" : "Myanmar"}
                                                    </div>
                                                    <CgRadioChecked size={20} className="text-navbar" />
                                                </Card>)
                                        } else {
                                            return (
                                                <Card key={i} className="drop-shadow-md cursor-pointer flex flex-row items-center justify-between select-none mx-[20px] my-[14px] rounded-md px-[20px] py-[10px] border border-[#CACACA]  dark:border-[#424242] dark:bg-darkFooter"
                                                    onClick={() => changeLanguage(language)}>
                                                    <div className="">
                                                        {language === 'en' ? "English" : "Myanmar"}
                                                    </div>
                                                    <CgRadioCheck size={20} className="text-navbar" />
                                                </Card>
                                            )
                                        }
                                    })
                                }
                            </div>
                            <div className="h-[50px]  rounded-b-[15px] dark:border-t-[1.5px] dark:border-[#424242] bg-white dark:bg-[#001e34]"></div>
                    </div>
                    <SizeBox vertical="h-[80px]"/>
                    </div>
            }
            Routes={Routes}
            Logger={Logger}
            useNavigate={useNavigate}
            showModel={showModel}
            setShowModel={setShowModel}
            setUnlockPremium={setUnlockPremium}
        />
    </div>
}
export default memo(AppeareanceScreen);