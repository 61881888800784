export const bunkei= [
    {
        sentence: [
            {
                furi: "＾会（かい）議（ぎ）｜は　｜＾今（いま）｜から　｜＾始（はじ）｜まる　ところです。",
                normal: "会議は　今から　始まる　ところです。",
                en: "The meeting is about to start now.",
                mm: "အစည်းအဝေးက အခုစတော့မယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson46/bunpou/4N46BK1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾彼（かれ）｜は　３｜＾月（げつ）｜に　｜＾大（だい）学（がく）｜を　｜＾卒（そつ）業（ぎょう）｜した　ばかりです。",
                normal: "彼は　３月に　大学を　卒業した　ばかりです。",
                en: "He just graduated from college in March.",
                mm: "သူက မတ်လတုန်းက တက္ကသိုလ်က‌နေ ဘွဲ့ရခါစ ဖြစ်ပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson46/bunpou/4N46BK2.mp3")
    },
    {
        sentence: [
            {
                furi: "ミラーさんは　｜＾会（かい）議（ぎ）室（しつ）｜に　いる　はずです。",
                normal: "ミラーさんは　会議室に　いる　はずです。",
                en: "Miller must be in the conference room.",
                mm: "Millerက အစည်းအဝေးခန်းထဲမှာ ရှိနေမှာ ‌သေချာတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson46/bunpou/4N46BK3.mp3")
    },
]