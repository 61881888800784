import CategoryViewBtn from "@components/common/CategoryViewBtn"
import Helper from "@helper"
import { Card } from "@material-tailwind/react"
import { memo } from "react"

const Vocab = ({ cagetories, vocab, furigana, furiTranslate, fontSize, lang }: any) => {
   //h-[250px] md:h-[300px] 
    return <Card onClick={cagetories[0].onclick}
        className="hover:scale-105 transition duration-500 flex-1 flex flex-col md:h-[250px] justify-between rounded-[20px] text-[16px] md:text-[22px] px-4 py-6 md:p-6 btn dark:bg-darkFooter dark-shadow ">
        <div className="flex flex-row items-center mb-5 md:justify-center">
            <div className=""><img src={cagetories[0].Icon} /></div>
            <div className=" text-black dark:text-white font-semibold ml-[10px] md:ml-[20px]">{cagetories[0].label}</div>
        </div>
        <div className="h-[120px] md:h-[180px] overflow-hidden">
            {
                [vocab[0],vocab[1]].map(({ furi, normal, mm, en }: any,index:number) => {
                    return <div key={index} className="flex flex-col justify-between mb-2 md:mb-3 text-[13px] md:text-[16px]">
                            <div className="md:leading-loose">
                                <div>
                                {furiTranslate({ context: furi, fontSize, style: "flex-wrap items-center", notUseLibrary:true, kanjiDetail: (id:any)=>{console.log(id)} })}
                                </div>
                                <div>{Helper.spliter((lang === "en") ? en : mm, lang)}</div>
                            </div>
                        </div>
                })
            }
        </div>
        <CategoryViewBtn cagetories={cagetories} />
    </Card>
}
export default memo(Vocab);