import Icon from "@consts/icon";

export const lesson35 = {
    title: {
        furi: 'どこか　いい　｜＾所（ところ）｜、　ありませんか',
        normal: 'どこか　いい　所、　ありませんか',
        en: 'Is there any good place?',
        mm: 'တစ်နေရာရာ ကောင်းတဲ့နေရာများ ရှိလား',
    },
    conversation: [
        {
            person: {
                furi: 'タワプン',
                normal: 'タワプン',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '＾鈴（すず）木（き）｜さん、｜＾冬（ふゆ）休（やす）｜みに　｜＾友（とも）達（だち）｜と　スキーに　｜＾行（い）｜きたいんですが、',
                    normal: '鈴木さん、冬休みに　友達と　スキーに　行きたいんですが、',
                    en: "Mr. Suzuki, I'd like to go skiing with my friends during winter vacation.",
                    mm: 'စုစုခိစံ ဆောင်းရာသီပိတ်ရက်မှာ သူငယ်ချင်းနဲ့ စကိတ်စီးဖို့ သွားချင်လို့ပါ',
                },
                {
                    furi: 'どこか　いい　｜＾所（ところ）｜、ありませんか。',
                    normal: 'どこか　いい　所、ありませんか。',
                    en: "Is there a nice place somewhere to visit?",
                    mm: 'သွားလို့ကောင်းမယ့် နေရာရှိလား',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: '＾何（なん）日（にち）｜ぐらいの　｜＾予（よ）定（てい）｜ですか。',
                    normal: '何日ぐらいの　予定ですか。',
                    en: 'How many days do you plan to stay?',
                    mm: 'ဘယ်နှစ်ရက်လောက် သွားဖို့စီစဉ်ထားလည်း',
                },
            ],
        },
        {
            person: {
                furi: 'タワプン',
                normal: 'タワプン',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '＾3（み）日（っか）｜ぐらいです。',
                    normal: '3日ぐらいです。',
                    en: 'about 3 days',
                    mm: '၃ရက်ဝန်းကျင်လောက်ပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'それなら、　｜＾草（くさ）津（つ）｜か　｜＾志（し）賀（が）高（こう）原（げん）｜が　いいと　｜＾思（おも）｜います。',
                    normal: 'それなら、　草津か　志賀高原が　いいと　思います。',
                    en: 'So, I think Kusatsu or Shiga Kogen would be good.',
                    mm: 'အဲ့ဒါဆိုရင် kusatsu ဒါမှမဟုတ် shigakogen ကကောင်းမယ်ထင်တယ်',
                },
                {
                    furi: '＾温（おん）泉（せん）｜も　あるし。。。',
                    normal: '温泉も　あるし。。。',
                    en: 'There is also a hot spring. . .',
                    mm: '‌ရေပူစမ်းလည်းရှိတယ်...',
                },
            ],
        },
        {
            person: {
                furi: 'タワプン',
                normal: 'タワプン',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'どうやって　｜＾行（い）｜くんですか。',
                    normal: 'どうやって　行くんですか。',
                    en: 'How are you going?',
                    mm: 'ဘယ်လိုသွားရတာလည်း',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'JRでも　｜＾行（い）｜けますが、｜＾夜（や）行（こう）｜バスなら、｜＾朝（あさ）｜　｜＾着（つ）｜きますから、｜＾便（べん）利（り）｜ですよ。',
                    normal: 'JRでも　行けますが、夜行バスなら、朝　着きますから、便利ですよ。',
                    en: "You can also go by JR, but the night bus will arrive in the morning, so it's convenient.",
                    mm: 'JR ရထားနဲ့လည်းသွားလို့ရပေမယ့်၊ ညဘက်ဘစ်ကားနဲ့ဆိုရင် မနက်ရောက်တာဆိုတော့ အဆင်ပြေတယ်',
                },
            ],
        },
        {
            person: {
                furi: 'タワプン',
                normal: 'タワプン',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'そうですか。どちらが　｜＾安（やす）｜いんですか。',
                    normal: 'そうですか。どちらが　安いんですか。',
                    en: 'Is that so. Which one is cheaper?',
                    mm: 'ဟုတ်လား၊ ဘယ်ဟာက ပိုသက်သာလည်း',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'さあ。。。｜＾旅（りょ）行（こう）社（しゃ）｜へ　｜＾行（い）｜けば　もっと　｜＾詳（くわ）｜しい　ことが　わかりますよ。',
                    normal: 'さあ。。。旅行社へ　行けば　もっと　詳しい　ことが　わかりますよ。',
                    en: 'here. . You can find out more by going to a travel agency.',
                    mm: 'ကဲ ခရီးသွားကုမ္ပဏီကို သွားလိုက်ရင်ပိုပြီး အသေးစိပ်သိရမှာ',
                },
            ],
        },
        {
            person: {
                furi: 'タワプン',
                normal: 'タワプン',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'それから　スキーの　｜＾道（どう）具（ぐ）｜や　｜＾服（ふく）｜は　｜＾何（なに）｜も　｜＾持（も）｜って　いないんですが。',
                    normal: 'それから　スキーの　道具や　服は　何も　持って　いないんですが。',
                    en: "Also, I don't have any ski equipment or clothes.",
                    mm: 'ပြီးတော့ စကိတ်ပစ္စည်းတွေ အဝတ်တွေ ဘာမှမရှိဘူး',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: '＾全（ぜん）部（ぶ）｜　スキー｜＾場（じょう）｜で　｜＾借（か）｜りられますよ。',
                    normal: '全部　スキー場で　借りられますよ。',
                    en: 'You can rent everything at the ski resort.',
                    mm: 'အကုန်လုံး စကိတ်ကွင်းမှာ ငှားလို့ရတယ်',
                },
                {
                    furi: '＾心（しん）配（ぱい）｜なら、　｜＾旅（りょ）行（こう）社（しゃ）｜で　｜＾予（よ）約（やく）｜も　できるし。。。',
                    normal: '心配なら、　旅行社で　予約も　できるし。。。',
                    en: "If you're worried, you can make a reservation at a travel agency. . .",
                    mm: 'စိတ်ပူတယ်ဆိုရင်တော့ ခရီးသွားကုမ္ပဏီမှာ ကြိုမှာထားလို့လည်းရတယ် . . .',
                },
            ],
        },
        {
            person: {
                furi: 'タワプン',
                normal: 'タワプン',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'そうですか。どうも　ありがとう　ございました。',
                    normal: 'そうですか。どうも　ありがとう　ございました。',
                    en: 'Is that so. thank you very much.',
                    mm: 'ဟုတ်လား ကျေးဇူးအများကြီးတင်ပါတယ်။',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson35/kaiwa/4N35KW.mp3")
}

/* 
{
    title: {
        furi: '',
        normal: '',
        en: '',
        mm: '',
    },
    conversation: [
        {
            person: {
                furi: '',
                normal: '',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '',
                    normal: '',
                    en: '',
                    mm: '',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson26/kaiwa/4N26KW.mp3")
}
*/