import AboutSubNavComponent from "@components/common/AboutSubNavComponent ";
import Pravacy from "@components/privacy";
import { BottomSpace, TopSpace } from "@consts/layout";
import SignUpScreenLayout from "@layouts/SignUpScreenLayout";
import { memo } from "react";
const PrivacyPolicyScreen = ({ showModel, setShowModel, setUnlockPremium, selector, Logger, name, dispatch, setName, Routes, useNavigate }: any) => {
    const nabs = [
        {
            title: "About",
            route: Routes.ABOUT,
        },
        {
            title: "Privacy & Policy",
            route: null
        }
    ];
    return (<>
        <SignUpScreenLayout
            body={
                <div className="container mx-auto">
                    <TopSpace/>
                    <AboutSubNavComponent
                        nabs={nabs} lesson={1} style="py-2 pl-2 pr-4" />
                    <Pravacy />
                    <BottomSpace/>
                </div>
            }
            Routes={Routes}
            Logger={Logger}
            useNavigate={useNavigate}
            showModel={showModel}
            setShowModel={setShowModel}
            setUnlockPremium={setUnlockPremium}
        />
    </>) 
}
export default memo(PrivacyPolicyScreen);