import { memo } from "react";
import KanjiStroke from "@components/common/KanjiStrokeComponent";
import { CompoundTile, ExampleTile, ListTile, toString } from "@screens/home/category/KanjiDetailScreen";
 
const KanjiCardComponent = ({ id,url, strokes, lesson, kun, on, lang,mean}:any) => {
    return <div className="hidden md:flex flex-row h-[300px] dark:bg-[#0E2D45] dark:text-white ">
        {/* Roll */}
        <div style={{ boxShadow:" 3px 3px 10px rgba(0, 0, 0, 0.25)"}} className="flex flex-col relative justify-center w-[85px] bg-[#F9ECC9] border-t-[20px] border-b-[20px] border-[#2Ea5ff]">
            <div className="flex flex-col justify-center mx-[18px] bg-[#fff] border-[3px] border-[#FFD175] text-[30px] font-[600] text-[#6C6C6C] text-center py-[15px]">
                <div>漢</div>
                <div>字</div>                
            </div> 
            {/* Top */}
            <div className="absolute bg-[#6C6C6C] w-[45px] h-[20px] left-[22.5px] -top-[40px] rounded-t-[5px] "></div>
            {/* Bottom */}
            <div className="absolute bg-[#6C6C6C] w-[45px] h-[20px] left-[22.5px] -bottom-[40px] rounded-b-[5px]"></div>
        </div>
        {/* Card */}
        <div style={{ boxShadow: " 0 3px 10px rgba(0, 0, 0, 0.25)" }} className="flex-1 flex flex-row bg-white dark:text-white  dark:bg-[#0E2D45] border-[20px] border-l-0 border-[#2Ea5ff]">
            {/* Stroke */}
            <div className="flex-[2] flex flex-row justify-center items-center">
                <KanjiStroke src={url} style="w-[200px] h-[200px]" />
            </div>
            {/* Spliter */}
            <div className="w-[2px] h-full py-[8px]">
                <div className="w-full h-full bg-[#c8C8c8] dark:bg-[#4F4F4F] "></div>
            </div>
            {/* Info */}
            <div className="flex-[5] flex flex-row">
                <div className="ml-[110px] h-full py-4 flex-1 flex flex-col justify-evenly">
                    <div className="text-[22px] font-[600] w-fit  mb-2">
                        Information
                    </div>
                    <ListTile title="Stroke" body={strokes} />
                    <ListTile title="Lesson" body={lesson + 1} />
                    <ListTile title="Kun Yomi" body={toString(kun)} />
                    <ListTile title="On Youmi" body={toString(on)} />
                    <ListTile title="Meaning" body={lang == "en" ? mean.en : mean.mm} />
                </div>
                <div className="mt-1 mr-2 text-[22px] font-[600]">
                    {id}
                </div>
            </div>
       </div>
    </div>
}
export default memo(KanjiCardComponent);