
export const renshuuA = [
    {
        //  {
        // furi: 'あしたから　｜！旅（りょ）行（こう）なん｜です。',
        // normal: 'あしたから　旅行　なんです。',
        // en: 'It\'s a trip from tomorrow.',
        // mm: 'မနက်ဖြန်ကနေစပြီး ခရီးထွက်ပါမယ်။',
        //｜＾電（でん）話（わ）番（べん）号（ごう）｜
        // }
        sentence: [
            {
                furi: "カレンダーに　こんげつの　よていが　かいて　あります。",
                normal: "カレンダーに　こんげつの　よていが　かいて　あります。",
                en: "The schedule for this month is written on the calendar.",
                mm: "ဒီလအတွက် အချိန်ဇယားကို ပြက္ခဒိန်မှာ ရေးထားတယ်။",
            },
            {
                furi: "かべに　えが　かけて　あります。",
                normal: "かべに　えが　かけて　あります。",
                en: "The picture is hanged on the wall.",
                mm: "နံရံမှာ ပုံကို ချိတ်ဆွဲထားတယ်။",
            },
            {
                furi: "テーブルに　おさらが　ならべて　あります。",
                normal: "テーブルに　おさらが　ならべて　あります。",
                en: "Plates are arranged on the table.",
                mm: "စားပွဲပေါ်မှာ ပန်းကန်ပြားတွေကို စီထားတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson30/renshua/4N30RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "しゃしんは　ひきだしに　しまって　あります。",
                normal: "しゃしんは　ひきだしに　しまって　あります。",
                en: "The photo is kept in the drawer.",
                mm: "ဓာတ်ပုံက အံဆွဲထဲမှာ သိမ်းထားတယ်။",
            },
            {
                furi: "ごみばこは　へやの　すみに　おいて　あります。",
                normal: "ごみばこは　へやの　すみに　おいて　あります。",
                en: "There is a garbage can in the corner of the room.",
                mm: "အခန်းထောင့်မှာ အမှိုက်ပုံးရှိတယ်။",
            },
            {
                furi: "よていひょうは　ドアの　みぎに　はって　あります。",
                normal: "よていひょうは　ドアの　みぎに　はって　あります。",
                en: "The schedule is posted on the right side of the door.",
                mm: "အချိန်ဇယားကို တံခါး၏ ညာဘက်တွင် ရေးထားသည်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson30/renshua/4N30RSA2.mp3")
    },
    {
        sentence: [
            {
                //｜＾全（ぜん）部（ぶ）
                furi: "＾子（こ）｜どもが　｜＾生（う）｜まれる　まえに、｜＾服（ふく）｜や　ベッドを　かって　おきます。",
                normal: "子どもが　生まれる　まえに、服や　ベッドを　かって　おきます。",
                en: "I bought clothes and a bed before the baby was born.",
                mm: "ကလေးမမွေးခင် အဝတ်အစားနဲ့ အိပ်ရာဝယ်ထားတယ်။",
            },
            {
                furi: "＾子（こ）｜どもが　｜＾生（う）｜まれる　まえに、｜＾名（な）前（まえ）｜を　きめて　おきます。",
                normal: "子どもが　生まれる　まえに、名前を　きめて　おきます。",
                en: "The baby is named before birth",
                mm: "ကလေးမမွေးခင် နာမည်မှည့်ထားတယ်",
            },
            
        ],
        audioUrl: require("@assets/audio/lesson30/renshua/4N30RSA3.mp3")
    },
    {
        sentence: [
            {
                furi: "＾食（しょく）事（じ）｜が　｜＾終（お）｜わったら、ちゃわんや　お｜＾皿（さら）｜を　あらって　おきます。",
                normal: "食事が　終わったら、ちゃわんや　お皿を　あらって　おきます。",
                en: "After you finish eating, wash the bowl and dishes.",
                mm: "ထမင်းစားပြီးရင် ပန်းကန်နဲ့ ပန်းကန်တွေကို ဆေးကြောထားပါ။",
            },
            {
                furi: "＾食（しょく）事（じ）｜が　｜＾終（お）｜わったら、テーブルの｜＾上（うえ｜を　かたづけて　おきます。",
                normal: "食事が　終わったら、テーブルの上を　かたづけて　おきます。",
                en: "After you finish eating, clear the table.",
                mm: "စားပြီးရင် စားပွဲကို ရှင်းထားပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson30/renshua/4N30RSA4.mp3")
    },
    {
        sentence: [
            {
                furi: "あした　｜＾会（かい）議（ぎ）｜が　ありますから、いすは　この　｜＾部（へ）屋（や）｜に　おいて　おきます。",
                normal: "あした　会議が　ありますから、いすは　この　部屋に　おいて　おきます。",
                en: "There is a meeting tomorrow, so I put the chairs in this room.",
                mm: "မနက်​ဖြန်​အစည်းအ​ဝေးရှိ​တာမို့ ဒီအခန်းထဲမှာ ကုလားထိုင်​​တွေကို ထားထားတယ်​။",
            },
            {
                furi: "あした　｜＾会（かい）議（ぎ）｜が　ありますから、いすは　そこに　ならべて　おきます。",
                normal: "あした　会議が　ありますから、いすは　そこに　ならべて　おきます。",
                en: "There is a meeting tomorrow, so I will arrange the chairs there.",
                mm: "မနက်ဖြန် အစည်းအဝေးရှိလို့ ကုလားထိုင်တွေ ဒီမှာ စီထားမယ်။",
            },
            {
                furi: "あした　｜＾会（かい）議（ぎ）｜が　ありますから、いすは　そのままに　しておきます。",
                normal: "あした　会議が　ありますから、いすは　そのままに　しておきます。",
                en: "I have a meeting tomorrow, so I will leave the chairs as they are.",
                mm: "မနက်ဖြန် အစည်းအဝေးရှိလို့ ကုလားထိုင်တွေကို ဒီအတိုင်းဘဲ ထားထားမယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson30/renshua/4N30RSA5.mp3")

    }
];

/*
    {
        sentence: [
            {
                furi: "",
                normal: "",
                en: "",
                mm: "",
            },
        ],
        audioUrl: require("@assets/audio/testing.mp3")
    },




*/