export const bunkei= [
    {
        sentence: [
            {
                furi: 'わたしは　ワット｜＾先（せん）生（せい）｜に　｜＾本（ほん）｜を　いただきました。',
                normal: 'わたしは　ワット先生に　本を　いただきました。',
                en: "I received a book from Mr. Watt.",
                mm: "မစ္စတာဝပ်ထံမှ စာအုပ်တစ်အုပ်ကို ကျွန်တော်လက်ခံရရှိခဲ့သည်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson41/bunpou/4N41BK1.mp3")
    },
    {
        sentence: [
            {
                furi: 'わたしは　｜＾先（せん）生（せい）｜に　｜＾漢（かん）字（じ）｜の　まちがいを　｜＾直（なお）｜して　いただきました。',
                normal: 'わたしは　先生に　漢字の　まちがいを　直して　いただきました。',
                en: "My teacher corrected my kanji mistakes.",
                mm: "ကျွန်တော့်ရဲ့ kanji အမှားတွေကို ဆရာက ပြင်ပေးခဲ့တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson41/bunpou/4N41BK2.mp3")
    },
    {
        sentence: [
            {
                furi: '＾部（ぶ）長（ちょう）｜の　｜＾奥（おく）｜さんが　お｜＾茶（ちゃ）｜を　｜＾教（おし）｜えて　くださいました。',
                normal: '部長の　奥さんが　お茶を　教えて　くださいました。',
                en: "The director's wife taught me how to make tea.",
                mm: "ဒါရိုက်တာရဲ့ဇနီးက လက်ဖက်ရည်လုပ်နည်းကို သင်ပေးတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson41/bunpou/4N41BK3.mp3")
    },
    {
        sentence: [
            {
                furi: 'わたしは　｜＾息（むす）子（こ）｜に　｜＾紙（かみ）飛（ひ）行（こう）機（き）｜を　｜＾作（つく）｜って　やりました。',
                normal: 'わたしは　息子に　紙飛行機を　作って　やりました。',
                en: "I made a paper airplane for my son.",
                mm: "ငါ့သားအတွက် စက္ကူလေယာဉ်တစ်စင်း ငါလုပ်ခဲ့တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson41/bunpou/4N41BK4.mp3")
    },
]