export const explain= [
    {
        // ＾友（とも）達（だち）｜
        pattern_furi: '＾受（う）身（け）動（どう）詞（し）',
        pattern_normal: '受身動詞',
        en: [
            '　It means to be done by someone.',
            '　別(わか)れる、話(はな)す、ケンカする are not used in verbs formed by two people.'
        ],
        mm: [ 
            '　တစ်စုံတစ်ယောက် ပြုလုပ်တာကိုခံရခြင်းလို့ အဓိပ္ပါယ်ရပါတယ်။',
            '　別(わか)れる、話(はな)す、ケンカするဆိုတဲ့ လူနှစ်ယောက်လုပ်မှ ဖြစ်မြောက်တဲ့ ကြိယာများတွင် သုံးလေ့မရှိပါ။'
        ]
    },
    {
        pattern_furi: ' ～によって',
        pattern_normal: ' ～によって',
        en: [
            "　A made B",
            '　It means that B is caused by A',
            '　～ is usually preceded by people.',
        ],
        mm: [
            '　AによってBが作(つく)られました　(AကBကို ပြုလုပ်ခဲ့တာပါ)',
            '　A‌‌ေကြာင့် Bကဖြစ်ပေါ်လာတာကို ဆိုလိုပါတယ်',
            '　～によって၏ရှေ့တွင် လူပုဂ္ဂိုလ်များ လိုက်လေ့ရှိသည်။',
        ]
    }
]