enum Routes {
    // Home Tab
    HOME = '/',
        CATEGORY = '/category',
            VOCAB = '/category/vocab',
            KANJI = '/category/kanji',
                KANJI_DETAIL = '/category/kanji/kanjiDetail',
                KANJI_GAME = '/category/kanji/kanjiDetail/game',
            GRAMMAR = '/category/grammar',
            KAIWA = '/category/kaiwa',
            RENSHUU_B = '/category/renshuuB',
            RENSHUU_C = '/category/renshuuC',
            MONDAI = '/category/mondai',
            FUKUSHUU = '/category/fukushuu',
    // Test Tab
    TEST = '/test',
        TEST_DETAIL = '/test/testDetail',
    // Setting Tab
    SETTING = '/setting',
    // Appearance Tab
    APPEARANCE = '/appearance',
    // Privacy
    PRIVACY = '/privacy',
    // About
    ABOUT = '/about',
    // Contact
    CONTACT = '/contact',
    // Testing
    // TEST = '/testing',
    
}
export default Routes;
export const TestRoutes = [
    Routes.TEST,Routes.TEST_DETAIL
];
export const HomeRoutes = [
    Routes.HOME, Routes.CATEGORY, Routes.GRAMMAR,
    Routes.VOCAB, Routes.KANJI, Routes.KAIWA,Routes.FUKUSHUU,
    Routes.RENSHUU_B, Routes.CATEGORY, Routes.MONDAI,Routes.KANJI_DETAIL
]
export const SettingMobileRoutes = [
    Routes.SETTING,Routes.CONTACT,Routes.ABOUT,Routes.PRIVACY,Routes.APPEARANCE
];
export const SettingWebRoutes = [
    Routes.SETTING,
];
export const ContactRoutes = [Routes.CONTACT]
export const AboutRoutes = [Routes.ABOUT, Routes.PRIVACY]
export const Testing = [Routes.TEST];
// export const PrivacyRoute = []