import Icon from "@consts/icon";

export const lesson41 = {
    title: {
        furi: 'ご｜＾結（けっ）婚（こん）｜　おめでとう　ございます',
        normal: 'ご結婚　おめでとう　ございます',
        en: 'Congratulations on your wedding',
        mm: 'မင်္ဂလာပွဲအတွက် ဂုဏ်ယူပါတယ်',
    },
    conversation: [
        {
            person: {
                furi: '＾学（がく）長（ちょう）',
                normal: '学長',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'ワットさん、いずみさん、ご｜＾結（けっ）婚（こん）｜　おめでとう　ございます。',
                    normal: 'ワットさん、いずみさん、ご結婚　おめでとう　ございます。',
                    en: 'Mr. Watt, Mr. Izumi, congratulations on your marriage',
                    mm: 'ဝတိုစံ အိဇုမိစံ၊ မင်္ဂလာပွဲအတွက် ဂုဏ်ယူပါတယ်',
                },
                {
                    furi: '＾乾（かん）杯（ぱい）',
                    normal: '乾杯',
                    en: 'cheers',
                    mm: 'ချီးယား',
                },
            ],
        },
        {
            person: {
                furi: '＾皆（みんな）',
                normal: '皆',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: '＾乾（かん）杯（ぱい）',
                    normal: '乾杯',
                    en: 'cheers',
                    mm: 'ချီးယား',
                },
            ],
        },
        {
            person: {
                furi: '＾司（し）会（かい）者（しゃ）',
                normal: '司会者',
                avatar: Icon.Kaiwa.STAFF
            },
            sentence: [
                {
                    furi: '＾次（つぎ）｜に　こちらの　｜＾皆（みな）｜さんに　ひとことずつ　お｜＾願（ねが）｜いします。',
                    normal: '次に　こちらの　皆さんに　ひとことずつ　お願いします。',
                    en: 'Next, I would like to ask each of you here for one thing.',
                    mm: 'ဆက်လက်ပြီးတော့ ဒီဘက်က လူကြီးမင်းတွေအတွက် စကားတစ်ခွန်းစီလောက် ပြောပေးကြပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾松（まつ）本（もと）良（よし）子（こ）',
                normal: '松本良子',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'わたしは　｜＾去（きょ）年（ねん）｜の　｜＾夏（なつ）｜の　クラスで　ワット｜＾先（せん）生（せい）｜に　｜＾英（えい）語（ご）｜を　｜＾教（おし）｜えて　いただきました。',
                    normal: 'わたしは　去年の　夏の　クラスで　ワット先生に　英語を　教えて　いただきました。',
                    en: "I had Mr. Watt teach me English in last summer's class.",
                    mm: 'ကျွန်မက မနှစ်ကနွေရာသီသင်တန်းမှာ ဝတိုဆရာဆီက အင်္ဂလိပ်စာသင်ယူခဲ့ပါတယ်',
                },
                {
                    furi: '＾先（せん）生（せい）｜の　｜＾授（じゅ）業（ぎょう）｜は　ユーモアが　あって、　｜＾楽（たの）｜しかったです。',
                    normal: '先生の　授業は　ユーモアが　あって、　楽しかったです。',
                    en: "The teacher's class was humorous and fun",
                    mm: 'ဆရာ့ရဲ့အတန်းက ဟာသလေးတွေပါပြီးတော့ ပျော်စရာကောင်းခဲ့ပါတယ်။',
                },
                {
                    furi: '＾実（じつ）｜は　その　クラスに　いずみさんも　いらっしゃたんです。',
                    normal: '実は　その　クラスに　いずみさんも　いらっしゃたんです。',
                    en: 'Actually, Izumi was also in that class.',
                    mm: 'တစ်ကယ်တော့ အဲ့ဒီ အတန်းမှာ အိဇုမိစံလည်း တက်ရောက်ခဲ့ပါတယ်။',
                },
            ],
        },
        {
            person: {
                furi: '＾大（だい）学（がく）職（しょく）員（いん）',
                normal: '大学職員',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: 'わたしは　｜＾先（せん）生（せい）｜に　「｜＾上（じょう）手（ず）｜な　｜＾整（せい）理（り）｜の　｜＾方（ほう）法（ほう）｜」と　いう　｜＾本（ほん）｜を　いただきました。',
                    normal: 'わたしは　先生に　「上手な　整理の　方法」と　いう　本を　いただきました。',
                    en: 'My teacher gave me a book called "How to organize well".',
                    mm: 'ကျွန်မက ဆရာဆီက "ကျွမ်းကျင်စွာစီရီနည်း" ဆိုတဲ့ စာအုပ်ကို လက်ခံရရှိခဲ့ပါတယ်',
                },
                {
                    furi: '＾先（せん）生（せい）｜は　｜＾整（せい）理（り）｜するのが　お｜＾上（じょう）手（ず）｜で、｜＾研（けん）究（きゅう）室（しつ）｜は　いつも　きれいです。',
                    normal: '先生は　整理するのが　お上手で、研究室は　いつも　きれいです。',
                    en: 'The teacher is good at organizing things, and the laboratory is always clean.',
                    mm: 'ဆရာက စီစီရီရီလုပ်တဲ့နေရာမှာ ထူးချွန်ပြီးတော့ သုတေသနအခန်းက အမြဲတမ်း လှပါနေပါတယ်',
                },
                {
                    furi: 'きっと　お｜＾二（ふた）人（り）｜の　お｜＾宅（たく）｜も　すてきだと　｜＾思（おも）｜います。',
                    normal: 'きっと　お二人の　お宅も　すてきだと　思います。',
                    en: "I'm sure your house is also nice.",
                    mm: 'ဆရာတို့နှစ်ယောက်ရဲ့ အိမ်လေးက လှပသေသပ်နေမှာ အသေအချာပဲလို့ ထင်မိပါတယ်။',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'ワットさん、｜＾今（こん）度（ど）｜は　「すてきな　｜＾人（ひと）｜と　｜＾結（けっ）婚（こん）｜する　｜＾方（ほう）法（ほう）｜」と　いう　｜＾本（ほん）｜を　｜＾書（か）｜いて　くださいませんか。',
                    normal: 'ワットさん、今度は　「すてきな　人と　結婚する　方法」と　いう　本を　書いて　くださいませんか。',
                    en: 'Mr. Watt, could you please write a book this time called "How to Marry a Nice Person"?',
                    mm: 'ဝတိုစံ ဒီတစ်ကြိမ်မှာတော့ "ချောမောလှပတဲ့သူနဲ့ လက်ထပ်နည်း"ဆိုတဲ့ စာအုပ်ကို ရေးပေးလို့ရမလား?',
                },
                {
                    furi: 'ぜひ　｜＾読（よ）｜んで、｜＾勉（べん）強（きょう）｜したいです。どうぞ　お｜＾幸（しあわ）｜せに。',
                    normal: 'ぜひ　読んで、勉強したいです。どうぞ　お幸せに。',
                    en: 'I definitely want to read and study. I hope she is happy.',
                    mm: 'သေချာပေါက်ဖတ်ပြီး လေ့လာချင်လို့ပါ၊ ပျော်ရွှင်ချမ်းမြေ့ပါစေ။',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson41/kaiwa/4N41KW.mp3")
}
/*
{
    title: {
        furi: '',
        normal: '',
        en: '',
        mm: '',
    },
    conversation: [
        {
            person: {
                furi: '',
                normal: '',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '',
                    normal: '',
                    en: '',
                    mm: '',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson36/kaiwa/4N36KW.mp3")
}
*/