import SizeBox from "@components/SizeBox";
import CategoryViewBtn from "@components/common/CategoryViewBtn";
import Icon from "@consts/icon";
import { Card } from "@material-tailwind/react";
import { memo } from "react"

const Grammar = ({ cagetories, grammarExplain,furigana,furiTranslate,fontSize }:any) => {
    return <Card onClick={cagetories[1].onclick}
        className="hover:scale-105 transition duration-500 flex-[3] h-[200px] md:h-[250px] rounded-[20px] p-6 text-[16px] md:text-[20px] btn dark:bg-darkFooter dark-shadow ">
        <div className="flex flex-row  ml-5 mb-8 ">
            <div className=""><img src={cagetories[1].Icon} /></div>
            <div className="text-black dark:text-white font-semibold ml-[10px] md:ml-[20px]">{cagetories[1].label}</div>
        </div>
        {
            grammarExplain.length>3 ? [grammarExplain[0],grammarExplain[1],grammarExplain[2]].map(({ pattern_furi, pattern_normal }: any) => {
                return <div key={Math.random()} className="flex flex-row items-center leading-loose">
                    <div className="mx-5"><img src={Icon.Bullet} /></div>
                    <div>{furigana === 'true' && pattern_furi.includes("＾") ? furiTranslate({ context: pattern_furi, fontSize, style: "flex-wrap items-center" }) : pattern_normal}</div>
                </div>
            })
                :
                grammarExplain.map(({ pattern_furi, pattern_normal }: any) => {
                    return <div key={Math.random()} className="flex flex-row items-center leading-loose">
                        <div className="mx-5"><img src={Icon.Bullet} /></div>
                        <div>{furigana === 'true' && pattern_furi.includes("＾") ? furiTranslate({ context: pattern_furi, fontSize, style: "flex-wrap items-center" }) : pattern_normal}</div>
                    </div>
            })
        }
        <SizeBox vertical="h-24" />
            <CategoryViewBtn cagetories={cagetories}/>

    </Card>
}
export default memo(Grammar);