export const explain= [
    {
        // ＾友（とも）達（だち）｜
        pattern_furi: '＾使（し）役（えき）動（どう）詞（し）',
        pattern_normal: '使役動詞',
        mm: [
            "　စေခိုင်းခြင်းခံ ကြိယာများ",
            "　အမိန့်ပေး။ စေခိုင်းခြင်း ကိစ္စများတွင် သုံးသည်။",
        ],
        en: [ 
            '　Subjunctive verbs',
            '　Used in cases of order and servitude.',
        ]
    },  
    {
        pattern_furi: 'させていただけませんか',
        pattern_normal: 'させていただけませんか',
        mm: [
            "　ခွင့်တောင်းခြင်း",
            "　「してもいいですか」နှင့် အဓိပ္ပါယ်တူပြီး ပို၍ယဉ်ကျေးသော အသုံးအနှုန်းဖြစ်သည်။",
            "　အထက်လူကြီးထံတွင် ယဉ်ကျေးစွာခွင့်တောင်းသော အခါများတွင်သုံးသည်။",
        ],
        en: [ 
            '　Asking for permission',
            '　It has the same meaning as 「してもいいですか?」and is a more polite expression..',
            '　Used when politely asking for permission from a superior.',
        ]
    },  
]