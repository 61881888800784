
export const lesson4 = [
    {"lesson":4,"level":4,"question":"短い","represent":["短"],"answer":["みじかい","くろい","よい","ひくい"],"true":"みじかい","times":10},
    {"lesson":4,"level":4,"question":"短大","represent":["短","大"],"answer":["せいとう","あくせい","たんだい","わるぐち"],"true":"たんだい","times":10},
    {"lesson":4,"level":4,"question":"広島","represent":["広"],"answer":["あかじ","ひろしま","せいねん","こくばん"],"true":"ひろしま","times":10},
    {"lesson":4,"level":4,"question":"黒板","represent":["黒"],"answer":["くろじ","こくばん","ひろしま","わるぐち"],"true":"こくばん","times":10},
    {"lesson":4,"level":4,"question":"良い","represent":["良"],"answer":["よい","みじかい","くろい","ひくい"],"true":"よい","times":10},
    {"lesson":4,"level":4,"question":"黒字","represent":["黒","字"],"answer":["ていおん","おかじ","ひろしま","くろじ"],"true":"くろじ","times":10},
    {"lesson":4,"level":4,"question":"悪い","represent":["悪"],"answer":["ひくい","よい","わるい","あかい"],"true":"わるい","times":10},
    {"lesson":4,"level":4,"question":"青年","represent":["青","年"],"answer":["せいねん","ていおん","ひろしま","せいとう"],"true":"せいねん","times":10},
    {"lesson":4,"level":4,"question":"悪口","represent":["悪","口"],"answer":["わるぐち","あかじ","せいねん","ひろしま"],"true":"わるぐち","times":10},
    {"lesson":4,"level":4,"question":"赤道","represent":["赤","道"],"answer":["さいりょう","こうこく","あかじ","せきどう"],"true":"せきどう","times":10},
    {"lesson":4,"level":4,"question":"低音","represent":["低","音"],"answer":["ひろしま","たんだい","ていおん","こくばん"],"true":"ていおん","times":10},
    {"lesson":4,"level":4,"question":"悪性","represent":["悪"],"answer":["あくせい","ていおん","さいりょう","たんだい"],"true":"あくせい","times":10},
    {"lesson":4,"level":4,"question":"赤字","represent":["赤","字"],"answer":["あかじ","わるぐち","せいとう","たんだい"],"true":"あかじ","times":10},
    {"lesson":4,"level":4,"question":"青い","represent":["青"],"answer":["あおい","あかい","わるい","ひろい"],"true":"あおい","times":10},
    {"lesson":4,"level":4,"question":"黒い","represent":["黒"],"answer":["みじかい","くろい","あかい","わるい"],"true":"くろい","times":10},
    {"lesson":4,"level":4,"question":"低い","represent":["低"],"answer":["くろい","ひくい","よい","あかい"],"true":"ひくい","times":10},
    {"lesson":4,"level":4,"question":"広い","represent":["広"],"answer":["よい","ひろい","くろい","みじかい"],"true":"ひろい","times":10},
    {"lesson":4,"level":4,"question":"広告","represent":["広"],"answer":["こうこく","わるぐち","せきどう","あかじ"],"true":"こうこく","times":10},
    {"lesson":4,"level":4,"question":"正答","represent":["正","答"],"answer":["せいとう","こうこく","だんだい","ていおん"],"true":"せいとう","times":10},
    {"lesson":4,"level":4,"question":"正月","represent":["正","月"],"answer":["せいねん","くろじ","しょうがつ","さいりょう"],"true":"しょうがつ","times":10},
    {"lesson":4,"level":4,"question":"最良","represent":["良"],"answer":["くろじ","たんだい","さいりょう","こくばん"],"true":"さいりょう","times":10},
]