import { data } from "./data";
const Definition = ()=>{
    return <div className="mt-[30px]">
        <p>2. <b>Definitions</b></p>
        <div className="pt-1 pb-1"></div>
        <p><b>SERVICE</b> means the <b>{data.website}</b> website operated by <b>{data.name}</b>.</p>
        <div className="pt-1 pb-1"></div>
        <p><b>PERSONAL DATA</b> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
        <div className="pt-1 pb-1"></div>
        <p><b>USAGE DATA</b> is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).</p>
        <div className="pt-1 pb-1"></div>
        <p><b>COOKIES</b> are small files stored on your device (computer or mobile device).</p>
        <div className="pt-1 pb-1"></div>
        <p><b>DATA CONTROLLER</b> means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.</p>
        <div className="pt-1 pb-1"></div>
        <p><b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.</p>
        <div className="pt-1 pb-1"></div>
        <p><b>DATA SUBJECT</b> is any living individual who is the subject of Personal Data.</p>
        <div className="pt-1 pb-1"></div>
        <p><b>THE USER</b> is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p>
        <div className="pt-1 pb-1"></div>
    </div>
}

export default Definition;