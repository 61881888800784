import SizeBox from "@components/SizeBox";
import AmidioComponent from "@components/common/Amidio/AmidioComponent";
import SentenceComponent from "@components/common/SentenceComponent";
import TitleComponent from "@components/common/TitleComponent";
import Helper from "@helper";
import { memo } from "react";
const BunKeiComponent = ({ scoreIncrementAction,bunkei, furigana, lang, fontSize, lesson, kanjiDetail }: any) => {
    const style = {
        container: 'flex-1 flex flex-row',
    }
    return(
        <div className="relative flex flex-col">
            <TitleComponent 
                furi="＾文（ぶん）｜ ｜＾型（けい）"
                normal="文 型"
                furigana={furigana} 
                furiTranslate={Helper.furiTranslate} 
                fontSize={fontSize} 
                lesson={lesson+26} 
            />
            <SizeBox vertical="h-16"/>
            {
                bunkei.map(({ sentence, audioUrl }:any, no: number) => {
                    return (<div key={Math.random()}>
                        <div className="flex flex-row px-3 md:pl-8">
                            <div>
                                {
                                    sentence.map(({ furi, normal, en, mm }: any, index: number) => {
                                        return (<div key={Math.random()}>
                                            <SentenceComponent
                                                no={no + 1}
                                                index={index}
                                                furigana={furigana}
                                                furi={furi}
                                                normal={normal}
                                                en={en}
                                                mm={mm}
                                                lang={lang}
                                                fontSize={fontSize}
                                                kanjiDetail={kanjiDetail}
                                                scoreIncrementAction={scoreIncrementAction}
                                            />
                                        </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="pl-8"><AmidioComponent audioUrl={audioUrl} scoreIncrementAction={scoreIncrementAction}/></div>
                        <SizeBox vertical="h-5" />
                    </div>
                    )
                })
            }
        </div>
        
    )
}
export default memo(BunKeiComponent);