
export const lesson16 = [
    {"lesson":16,"level":4,"question":"えいがかん","represent":["映","画","館"],"answer":["映画館","大使館","天気図","研究室"],"true":"映画館","times":10},
    {"lesson":16,"level":4,"question":"うんどうじょう","represent":["運","動","場"],"answer":["運動場","大使館","大学院","研究室"],"true":"運動場","times":10},
    {"lesson":16,"level":4,"question":"こうどう","represent":["堂"],"answer":["講堂","室内","建物","広場"],"true":"講堂","times":10},
    {"lesson":16,"level":4,"question":"たてもの","represent":["建","物"],"answer":["建物","工学","荷物","工場"],"true":"建物","times":10},
    {"lesson":16,"level":4,"question":"きょうしつ","represent":["教","室"],"answer":["教室","建物","室内","図書"],"true":"教室","times":10},
    {"lesson":16,"level":4,"question":"びょういん","represent":["病","院"],"answer":["病院","信号","工場","地図"],"true":"病院","times":10},
    {"lesson":16,"level":4,"question":"けんきゅうしつ","represent":["研","究","室"],"answer":["研究室","大使館","図書館","映画館"],"true":"研究室","times":10},
    {"lesson":16,"level":4,"question":"ばあい","represent":["場","合"],"answer":["場合","室内","工場","荷物"],"true":"場合","times":10},
    {"lesson":16,"level":4,"question":"てんきず","represent":["天","気","図"],"answer":["天気図","大使館","図書館","研究室"],"true":"天気図","times":10},
    {"lesson":16,"level":4,"question":"きごう","represent":["号"],"answer":["記号","工業","室内","教室"],"true":"記号","times":10},
    {"lesson":16,"level":4,"question":"としょかん","represent":["図","書","館"],"answer":["図書館","天気図","映画館","運動場"],"true":"図書館","times":10},
    {"lesson":16,"level":4,"question":"にもつ","represent":["物"],"answer":["荷物","建物","講堂","工場"],"true":"荷物","times":10},
    {"lesson":16,"level":4,"question":"だいがくいん","represent":["大","学","院"],"answer":["大学院","大使館","研究室","天気図"],"true":"大学院","times":10},
    {"lesson":16,"level":4,"question":"しつない","represent":["室","内"],"answer":["室内","講堂","記号","病院"],"true":"室内","times":10},
    {"lesson":16,"level":4,"question":"せいぶつ","represent":["生","物"],"answer":["生物","工学","広場","場合"],"true":"生物","times":10},
    {"lesson":16,"level":4,"question":"こうがく","represent":["工","学"],"answer":["工学","工場","工業","場所"],"true":"工学","times":10},
    {"lesson":16,"level":4,"question":"ばしょ","represent":["場","所"],"answer":["場所","生物","講堂","記号"],"true":"場所","times":10},
    {"lesson":16,"level":4,"question":"こうじ","represent":["工","事"],"answer":["工事","場所","病院","生物"],"true":"工事","times":10},
    {"lesson":16,"level":4,"question":"ひろば","represent":["広","場"],"answer":["広場","工事","工学","病院"],"true":"広場","times":10},
    {"lesson":16,"level":4,"question":"としょ","represent":["図","書"],"answer":["図書","広場","生物","場所"],"true":"図書","times":10},
    {"lesson":16,"level":4,"question":"しんごう","represent":["信","号"],"answer":["信号","工事","室内","場所"],"true":"信号","times":10},
    {"lesson":16,"level":4,"question":"ちず","represent":["地","図"],"answer":["地図","工業","教室","食堂"],"true":"地図","times":10},
    {"lesson":16,"level":4,"question":"たいしかん","represent":["大","使","館"],"answer":["大使館","研究室","大学院","映画館"],"true":"大使館","times":10},
    {"lesson":16,"level":4,"question":"しょくどう","represent":["食","堂"],"answer":["食堂","場所","工事","場合"],"true":"食堂","times":10},
    {"lesson":16,"level":4,"question":"こうぎょう","represent":["工","業"],"answer":["工業","生物","工学","地図"],"true":"工業","times":10},
]