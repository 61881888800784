
export const renshuuA = [
    {
        sentence: [
            {
                furi: "わたしが｜！いった｜とおりに、　｜＾言（い）｜って　ください。",
                normal: "わたしが　いった　とおりに、　言って　ください。",
                en: "Say what I said.",
                mm: "ငါပြောထားတဲ့အတိုင်း ပြောပါ။",
            },
            {
                furi: "＾先（せん）生（せい）｜が　｜！やった｜　とおりに、やりました。",
                normal: "先生が　やった　とおりに、やりました。",
                en: "I did what the teacher did.",
                mm: "ဆရာလုပ်တဲ့အတိုင်း လုပ်ခဲ့တယ်။",
            },
            {
                furi: "ビデオで｜！みた｜とおりに、｜＾踊（おど）｜って　ください。",
                normal: "ビデオで　みた　とおりに、踊って　ください。",
                en: "Dance as you saw in the video.",
                mm: "ဗီဒီယိုထဲမှာ ‌တွေ့တဲ့အတိုင်း ကပါ။",
            },
            {
                furi: "＾母（はは）｜に｜！ならった｜とおりに、　｜＾作（つく）｜りました。",
                normal: "母に　ならった　とおりに、　作りました。",
                en: "I made it as my mother taught me.",
                mm: "အမေသင်ပေးတဲ့အတိုင်းလုပ်ခဲ့တယ်။",
            },
            {
                furi: "｜！せつめいしょの｜とおりに、｜＾組（く）｜み｜＾立（た）｜てました。",
                normal: "せつめいしょの　とおりに、組み立てました。",
                en: "I assembled it according to the instructions.",
                mm: "ညွှန်ကြားချက်အတိုင်း စုစည်းလိုက်တာပါ။",
            },
            {
                furi: "この｜！とおりに｜、｜＾書（か）｜いて　ください。",
                normal: "この　とおりに、　書いて　ください。",
                en: "Please write it like this.",
                mm: "ကျေးဇူးပြု၍ ဤကဲ့သို့ ရေးပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson34/renshua/4N34RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾仕（し）事（ごと）｜が　｜！おわった｜　あとで、｜＾飲（の）｜みに　｜＾行（い）｜きます。",
                normal: "仕事が　おわった　あとで、飲みに　行きます。",
                en: "After work, I will go out for a drink.",
                mm: "အလုပ်ပြီးရင် အပြင်ထွက်သောက်မယ်။",
            },
            {
                furi: "＾説（せつ）明（めい）｜を　｜！きいた｜　あとで、｜＾質（しつ）問（もん）｜しました。",
                normal: "説明を　きいた　あとで、質問しました。",
                mm: "ရှင်းပြတာကို နားထောင်ပြီးတာနဲ့ မေးခွန်းတစ်ခုမေးတယ်။",
                en: "After listening to the explanation, I asked a question.",
            },
            {
                furi: "スポーツの｜！あとで｜、シャワーを｜＾浴（お）｜びます。",
                normal: "スポーツの　あとで、シャワーを浴びます。",
                en: "After playing sports, I take a shower.",
                mm: "အားကစားလုပ်ပြီးရင် ရေချိုးတယ်။",
            },
            {
                furi: "しょくじの　｜！あとで｜、コーヒーを　｜＾飲（の）｜みます。",
                normal: "しょくじの　あとで、コーヒーを　飲みます。",
                en: "After eating, I drink coffee.",
                mm: "ထမင်းစားပြီးရင် ကော်ဖီသောက်တယ်။",
            }
        ],
        audioUrl: require("@assets/audio/lesson34/renshua/4N34RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾傘（かさ）｜を　｜！もって｜　｜＾出（で）｜かけます。",
                normal: "傘を　もって　出かけます。",
                en: "I will go out with an umbrella.",
                mm: "ထီးယူပြီး အပြင်ထွက်မယ်။",
            },
            {
                furi: "＾朝（あさ）｜ごはんを　｜！たべて｜＾出（で）｜かけます。",
                normal: "朝ごはんを　｜！たべて｜　出かけます。",
                en: "I will have breakfast and go out.",
                mm: "မနက်စာစားပြီး အပြင်ထွက်မယ်။",
            },
            {
                furi: "＾傘（かさ）｜を　もたないで　｜！出（で）｜かけます。",
                normal: "傘を　もたないで　出かけます。",
                en: "I will go out without an umbrella.",
                mm: "ထီးမပါဘဲ အပြင်ထွက်မယ်။",
            },
            {
                furi: "＾朝（あさ）｜ごはんを　｜！たべないで｜　｜＾出（で）｜かけます。",
                normal: "朝ごはんを　たべないで　出かけます。",
                en: "I will go out without eating breakfast",
                mm: "မနက်စာမစားဘဲ အပြင်ထွက်မယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson34/renshua/4N34RSA3.mp3")
    },
    {
        sentence: [
            {
                furi: "＾日（にち）曜（よう）日（び）｜　どこも｜！いか｜ないで、　うちに　います。",
                normal: "日曜日　どこも　いか　ないで、　うちに　います。",
                en: "I don't go anywhere on Sunday,and stay at home.",
                mm: "တနင်္ဂနွေမှာ ဘယ်မှမသွားနဲ့ အိမ်ထဲမှာပဲနေမယ်။",
            },
            {
                furi: "エレベーターに　｜！のら｜　ないで、　｜＾階（かい）段（だん）｜を　｜＾使（つか）｜います。",
                normal: "エレベーターに　のら　ないで、　階段を　使います。",
                en: "Without using the elevator, I am using the stairs.",
                mm: "ဓာတ်လှေကား မစီးဘဲနဲ့ လှေကားကို အသုံးပြု‌နေတယ်။",
            },
            {
                furi: "＾仕（し）事（ごと）｜を　｜！し｜　ないで、　｜＾遊（あそ）｜んで　います。",
                normal: "仕事を　｜！し｜　ないで、　遊んで　います。",
                en: "Without working, I am playing.",
                mm: "အလုပ်မလုပ်ဘဲ ကစား‌နေတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson34/renshua/4N34RSA4.mp3")
    },
];

/*
    {
        sentence: [
            {
                furi: "",
                normal: "",
                en: "",
                mm: "",
            },
        ],
        audioUrl: require("@assets/audio/testing.mp3")
    },




*/