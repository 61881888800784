export const reibun= [
    {
        sentence: [
            {
                furi: '＾学（がく）生（せい）｜の　アルバイトに　ついて　どう　｜＾思（おも）｜いますか。',
                normal: '学生の　アルバイトに　ついて　どう　思いますか。',
                en: 'What do you think about student part-time jobs?',
                mm: 'ကျောင်းသား အချိန်ပိုင်းအလုပ်တွေကိုရော ဘယ်လိုထင်လဲ။',
            },
            {
                furi: 'いいと　｜＾思（おも）｜いますよ。｜＾若（わか）｜い　ときは、いろいろな　｜＾経（けい）験（けん）｜をした｜！ほうが｜いいですから。',
                normal: 'いいと　思いますよ。若い　ときは、いろいろな　経験をした　ほうが　いいですから。',
                en: "I think it is good! When you're young, it's better to have different experiences.",
                mm: 'ကောင်းမယ်ထင်တယ်။ ဘာဖြစ်လို့လဲဆိုတော့ ငယ်ငယ်တုန်းက အတွေ့အကြုံမျိုးစုံရရင် ပိုကောင်းပါတယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson32/bunpou/4N32RB1.mp3")
    },
    {
        sentence: [
            {
                furi: '１か｜＾月（げつ）｜ぐらい　ヨーロッパへ　｜＾遊（あそ）｜びに　｜＾行（い）｜きたいんですが、４０｜＾万（まん）円（えん）｜で　｜＾足（た）｜りますか。',
                normal: '１か月ぐらい　ヨーロッパへ　遊びに　行きたいんですが、４０万円で　足りますか。',
                en: "As I'd like to visit Europe for about a month, is 400,000 yen enough?",
                mm: 'ဥရောပကို တစ်လလောက်သွားလည်ချင်လို့ ယန်း 400,000 နဲ့ လုံလောက်ပါသလား။',
            },
            {
                furi: '＾十（じゅう）分（ぶん）｜だと　｜＾思（おも）｜います。でも、｜＾現（げん）金（きん）｜で　｜＾持（も）｜って　｜＾行（い）｜かない｜！ほうが｜いいですよ。',
                normal: '十分だと　思います。でも、現金で　持って　行かない　ほうが　いいですよ。',
                en: "I think it's enough. However, it is better not to bring cash.",
                mm: 'လုံလောက်တယ်လို့ထင်ပါတယ်။ ဒါပေမယ့် ငွေသားနဲ့ မယူတာ ပိုကောင်းပါတယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson32/bunpou/4N32RB2.mp3")
    },
    {
        sentence: [
            {
                furi: '＾先（せん）生（せい）｜、｜＾日（に）本（ほん）｜の　｜＾経（けい）済（ざい）｜は　どう　なるでしょうか。',
                normal: '先生、日本の　経済は　どう　なるでしょうか。',
                en: 'Teacher, what will happen to the Japanese economy?',
                mm: 'ဆရာ၊ ဂျပန်ရဲ့ စီးပွားရေးက ဘယ်လိုဖြစ်လာနိုင်လဲ။',
            },
            {
                furi: 'そうですね。まだ　しばらく　よく｜！ならないでしょう｜。',
                normal: 'そうですね。まだ　しばらく　よく　ならないでしょう。',
                en: "I agree. It won't get better for a while yet.",
                mm: 'ဟုတ်တယ်‌‌နော်၊ အချိန်တစ်ခုထိ‌တော့ ပြန်မ‌ကောင်းလာနိုင်‌သေးဘူး။',
            },
        ],
        audioUrl: require("@assets/audio/lesson32/bunpou/4N32RB3.mp3")
    },
    {
        sentence: [
            {
                furi: '＾先（せん）生（せい）｜、ハンスは　インフルエンザですか。',
                normal: '先生、ハンスは　インフルエンザですか。',
                en: 'Teacher, does Hans have the flu?',
                mm: 'ဆရာ၊ Hans တုပ်ကွေးရှိပါသလား။',
            },
            {
                furi: 'はい、インフルエンザです。２，３｜＾日（にち）｜　｜＾高（たか）｜い　｜＾熱（ねつ）｜が｜！続（つづ）くかもしれませんが｜、｜＾心（しん）配（ぱい）｜しなくても　いいですよ。',
                normal: 'はい、インフルエンザです。２，３日　高い　熱が　続くかもしれませんが、心配しなくても　いいですよ。',
                en: "Yes, She has the flu. She may have a high fever for a few days, but don't worry.",
                mm: 'ဟုတ်တယ်၊ တုပ်ကွေးပါ။ ရက်အနည်းငယ်ကြာ အဖျားကြီးနေနိုင်သော်လည်း စိတ်မပူပါနှင့်။',
            }
        ],
        audioUrl: require("@assets/audio/lesson32/bunpou/4N32RB4.mp3")
    },
    {
        sentence: [
            {
                furi: 'エンジンの　｜＾音（おと）｜が　おかしいですね。',
                normal: 'エンジンの　音が　おかしいですね。',
                en: 'The engine sounds strange.',
                mm: 'အင်ဂျင်သံက ထူးဆန်းနေတယ်။',
            },
            {
                furi: 'そうですね。｜＾故（こ）障（しょう）｜かも　しれません。ちょっと　｜＾調（しら）｜べましょう。',
                normal: 'そうですね。故障かも　しれません。ちょっと　調べましょう。',
                en: "I agree. It may be out of order. Let's find out.",
                mm: 'ဟုတ်တယ်‌။ ချွတ်ယွင်းချက်တစ်ခု ဖြစ်နိုင်တယ်။ ရှာကြစို့။',
            },
        ],
        audioUrl: require("@assets/audio/lesson32/bunpou/4N32RB5.mp3")
    }
]
/*
{
    furi: '',
    normal: '',
    en: '',
    mm: '',
},
*/