export const bunkei= [
    {
        sentence: [
            {
                furi: "＾課（か）長（ちょう）｜は　｜＾帰（かえ）｜られました。",
                normal: "課長は　帰られました。",
                en: "The manager has returned.",
                mm: "မန်နေဂျာ ပြန်သွားပါပြီ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson49/bunpou/4N49BK1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾社（しゃ）長（ちょう）｜は　お｜＾帰（かえ）｜りに　なりました。",
                normal: "社長は　お帰りに　なりました。",
                en: "The boss has returned.",
                mm: "သူ‌ဌေး ပြန်သွားပါပြီ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson49/bunpou/4N49BK2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾部（ぶ）長（ちょう）｜は　アメリカへ　｜＾出（しゅっ）張（ちょう）｜なさいます。",
                normal: "部長は　アメリカへ　出張なさいます。",
                en: "The manager will go on a business trip to America.",
                mm: "မန်နေဂျာက အမေရိကကို အလုပ်ကိစ္စနဲ့ သွားပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson49/bunpou/4N49BK3.mp3")
    },
    {
        sentence: [
            {
                furi: "しばらく　お｜＾待（ま）｜ち　ください。",
                normal: "しばらく　お待ち　ください。",
                en: "Please wait.",
                mm: "ကျေးဇူးပြုပြီးခဏစောင့်ပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson49/bunpou/4N49BK4.mp3")
    },
];