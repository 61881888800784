export const reibun= [
    {
        sentence: [
            {
                furi: "＾重（おも）｜そうですね。お｜＾持（も）｜ちしましょうか。",
                normal: "重そうですね。お持ちしましょうか。",
                en: "It looks heavy. Would you like me to bring it?",
                mm: "လေး‌နေတဲ့ပုံပဲ။ သယ်ကူ‌ပေးရမလား",
            },
            {
                furi: "すみません。お｜＾願（ねが）｜いします。",
                normal: "すみません。お願いします。",
                en: "sorry. please.",
                mm: " ကျေးဇူးပြု၍ သယ်ပေးပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson50/bunpou/4N50RB1.mp3")
    },
    {
        sentence: [
            {
                furi: "ガイドさん、ここを　｜＾見（み）｜た　あとで、どこへ　｜＾行（い）｜くんですか。",
                normal: "ガイドさん、ここを　見た　あとで、どこへ　行くんですか。",
                en: "Guide, where are you going after seeing this place?",
                mm: "လမ်းညွန်၊ ဒီနေရာကိုကြည့်ပြီးရင် ဘယ်သွားမလဲ။",
            },
            {
                furi: "＾江（え）戸（ど）東（とう）京（きょう）博（はく）物（ぶつ）館（かん）｜へ　ご｜＾案（あん）内（ない）｜します。",
                normal: "江戸東京博物館へ　ご案内します。",
                en: "I will guide you to the Edo-Tokyo Museum.",
                mm: "ကျွန်တော် Edo-Tokyo ပြတိုက်ဆီကို လိုက်လံပြသပေးပါမယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson50/bunpou/4N50RB2.mp3")
    },
    {
        sentence: [
            {
                furi: "グプタさんの　｜＾到（とう）着（ちゃく）｜は　２｜＾時（じ）｜ですね。だれか　｜＾迎（むか）｜えに　｜＾行（い）｜くんですか。",
                normal: "グプタさんの　到着は　２時ですね。だれか　迎えに　行くんですか。",
                en: "Mr. Gupta will arrive at 2:00. Is someone going to pick you up?",
                mm: "မစ္စတာ Gupta သည် 2:00 တွင် ရောက်ရှိမည်ဖြစ်သည်။ တစ်ယောက်ယောက် ကြိုဖို့ သွားပေးမလား။",
            },
            {
                furi: "はい、｜＾私（わたくし）｜が　｜＾参（まい）｜ります。",
                normal: "はい、私が　参ります。",
                en: "Yes, I will come.",
                mm: "ဟုတ်ကဲ့ ကျွန်တော် သွားလိုက်ပါမယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson50/bunpou/4N50RB3.mp3")
    },
    {
        sentence: [
            {
                furi: "ちょっと　｜＾切（きっ）符（ぷ）｜を　｜＾拝（はい）見（けん）｜します。",
                normal: "ちょっと　切符を　拝見します。",
                en: "Let me take a look at your ticket.",
                mm: "တဆိတ်လောက် လက်မှတ်‌ကို ကြည့်ပါရစေ။",
            },
            {
                furi: "はい。",
                normal: "はい。",
                en: "yes",
                mm: "ဟုတ်ကဲ့",
            },
            {
                furi: "どうも　ありがとう　ございました。",
                normal: "どうも　ありがとう　ございました。",
                en: "Thank you",
                mm: "ကျေးဇူးအများကြီးတင်ပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson50/bunpou/4N50RB4.mp3")
    },
    {
        sentence: [
            {
                furi: "こちらは　ミラーさんです。",
                normal: "こちらは　ミラーさんです。",
                en: "This is Mr. Miller.",
                mm: "ဒါက မစ္စတာမီလာပါ။",
            },
            {
                furi: "＾初（はじ）｜めまして。ミラーと　｜＾申（もう）｜します。",
                normal: "初めまして。ミラーと　申します。",
                en: "nice to meet you. My name is Miller.",
                mm: "တွေ့ရတာဝမ်းသာပါတယ်။ ကျွန်တော့်နာမည်က Miller ပါ။",
            },
            {
                furi: "どうぞ　よろしく　お｜＾願（ねが）｜いします。",
                normal: "どうぞ　よろしく　お願いします。",
                en: "Nice to meet you.",
                mm: "တွေ့ရတာဝမ်းသာပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson50/bunpou/4N50RB5.mp3")
    },
    {
        sentence: [
            {
                furi: "ご｜＾家（か）族（ぞく）｜は　どちらに　いらっしゃいますか。",
                normal: "ご家族は　どちらに　いらっしゃいますか。",
                en: "Where are your family?",
                mm: "မိသားစုက ဘယ်မှာ နေပါသလဲခင်ဗျာ။",
            },
            {
                furi: "ニューヨークに　おります。",
                normal: "ニューヨークに　おります。",
                en: "In new york.",
                mm: "နယူးယောက်မှာနေပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson50/bunpou/4N50RB6.mp3")
    },
];