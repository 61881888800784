export const explain= [
    {
        pattern_furi: '～(て)います',
        pattern_normal: '～(て)います',
        en: [
            '　Used to describe the state of doing something.',
            '　Can be used after Vて'
            ],
        mm: [
            '　လုပ်လေ့ရှိတယ်။ တစ်ခုခုကို လုပ်နေစဉ် အခြေအနေကို ဖော်ပြရာတွင် သုံးသည်။',
            '　Vて၏နောက်တွင် ထား၍သုံးသည်။'
        ]
    },
    {
        pattern_furi: '～(て)しまいました',
        pattern_normal: '～(て)しまいました',
        en: [
            '　Did it accidentally. (Difficult to withdraw).',
            '　Can be used after Vて'
        ],
        mm: [
            '　အမှတ်မထင် လုပ်မိသွားသည်။ (ပြန်လည်ရုတ်သိမ်းရန် ခက်ခဲသော အခြေအနေမျိုးဖြစ်)',
            '　Vて၏နောက်တွင် ထား၍သုံးသည်။',
        ]
    }
]