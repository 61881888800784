import SizeBox from "@components/SizeBox";
import SpeedDialComponent, { SPEDIAL } from "@components/common/SpeedDialComponent";
import SubNavComponent from "@components/common/SubNavComponent";
import BunKeiComponent from "@components/grammar/BunKeiComponent";
import CustomizedAccordions from "@components/grammar/CustomizedAccordions";
import RebunComponent from "@components/grammar/RebunComponent";
import RenshuuAComponent from "@components/grammar/RenshuuAComponent";
import { BottomSpace, TopSpace } from "@consts/layout";
import Helper from "@helper";
import GrammarScreenLayout from "@layouts/home/GrammarScreenLayout";
import { ScreenPropType } from "App";
import { memo, useEffect, useState } from "react";

const GrammarScreen = ({ScoreType,getScore,setScores,showModel,setShowModel,setUnlockPremium,Routes, useNavigate, Logger,fontSize, useLocation,routeHistory, lang,furigana, dark, toggleFuri,Minna,setRouteData}: ScreenPropType) => {
    const log = Logger(6);
    const location = useLocation();
    const navigate = useNavigate();

    const { id, lesson } = location.state;
    const { explain, bunkei, reibun, renshuuA } = Minna.grammar[id];
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    // Speed Dial
    const [showTranslate, setShowTranslate] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const nabs = [ 
        {
            title: "home",
            route: Routes.HOME,
        },
        {
            title: "Category",
            route: Routes.CATEGORY,
            state: {id, lesson}
        },
        {
            title: "Grammer",
            route: null
        }
    ];
    // kanji PopOver Detail
    const kanjiDetail = (kanjiId: any) => {
        const state = { lesson, id, name: "Grammar", path: location.pathname, kanjiId };
        navigate(Routes.KANJI_DETAIL, { state });
        scoreIncrementAction();
    }
    const resizeHandler = () => {
        setScreenHeight(window.innerHeight);
    }
    //  spedial action
    const scoreIncrementAction = ()=>{
        const currentScore = getScore({id,key:ScoreType.GRAMMAR});
        console.log("Score",currentScore);
        // setScores({id,key:ScoreType.GRAMMAR,value:currentScore+1});
        asyncSetScore(currentScore);
    }
    const asyncSetScore=async(currentScore:number)=>{
        await Helper.sleep(1000);
        setScores({id,key:ScoreType.GRAMMAR,value:currentScore+1});
        //console.log("Set Score")
    }
    const toggleFuriAction = ()=>{
        toggleFuri();
        scoreIncrementAction();
    }
    useEffect(() => {
        if(routeHistory.home.route != Routes.GRAMMAR){
            setRouteData({home:{
                state:{ lesson, id },
                route:Routes.GRAMMAR
            }})
        }
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        }
    })
    return (
        <GrammarScreenLayout
            body={
                <>
                    <div className="hidden md:block" onClick={isHovering ? () => setIsHovering(false) : () => { }}>
                        <div style={{ height: screenHeight - (85 + 60) }} className="flex flex-row bg-[#C7E7FF] dark:bg-darkBackground">
                            {/* SideBar */}
                            <div className="flex-[3] border-r-[2px] border-r-[#C8C8C8] dark:border-r-[#424242]">
                                <CustomizedAccordions patterns={explain}
                                    furigana={furigana}
                                    fontSize={fontSize}
                                    lang={lang} />
                            </div>
                            {/* Body */}
                            <div className="flex-[10] flex flex-col bg-white dark:bg-darkBackground dark:text-white">
                                {/* <TopSpace/> */}
                                <div className="flex flex-col justify-center h-[50px]">                                    
                                    <SubNavComponent nabs={nabs} lesson={lesson} style="py-2 pl-2 pr-4" />
                                </div>
                                <div className="flex-1 overflow-y-scroll scrollbar-hide">
                                    <SizeBox vertical="h-3" />
                                    {/* bunkei */}
                                    <BunKeiComponent
                                        bunkei={bunkei}
                                        furigana={furigana}
                                        lang={lang}
                                        fontSize={fontSize}
                                        lesson={lesson}
                                        kanjiDetail={kanjiDetail}
                                        scoreIncrementAction={scoreIncrementAction}
                                    />
                                    <SizeBox vertical="h-3" />
                                    {/* reibun */}
                                    <RebunComponent
                                        reibun={reibun}
                                        furigana={furigana}
                                        lang={lang}
                                        fontSize={fontSize}
                                        furiTranslate={Helper.furiTranslate}
                                        lesson={lesson}
                                        kanjiDetail={kanjiDetail}
                                        scoreIncrementAction={scoreIncrementAction}
                                    />
                                    <SizeBox vertical="h-3" />
                                    {/*renshuu A */}
                                    <RenshuuAComponent
                                        renshuuA={renshuuA}
                                        furigana={furigana}
                                        lang={lang}
                                        fontSize={fontSize}
                                        furiTranslate={Helper.furiTranslate}
                                        lesson={lesson}
                                        kanjiDetail={kanjiDetail}
                                        scoreIncrementAction={scoreIncrementAction}
                                    />
                                    <BottomSpace/>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* Mobile */}
                    <div className="flex flex-col md:hidden">
                        <SizeBox vertical="h-3" />
                        <SubNavComponent nabs={nabs} lesson={lesson} />
                        <SizeBox vertical="h-3" />
                        {/* SideBar */}
                        <CustomizedAccordions patterns={explain}
                            furigana={furigana}
                            fontSize={fontSize}
                            lang={lang} />
                        <SizeBox vertical="h-3" />
                        {/* bunkei */}
                        <BunKeiComponent
                            bunkei={bunkei}
                            furigana={furigana}
                            lang={lang}
                            fontSize={fontSize}
                            lesson={lesson}
                            kanjiDetail={kanjiDetail}
                            scoreIncrementAction={scoreIncrementAction}
                        />
                        <SizeBox vertical="h-3" />
                        {/* reibun */}
                        <RebunComponent
                            reibun={reibun}
                            furigana={furigana}
                            lang={lang}
                            fontSize={fontSize}
                            furiTranslate={Helper.furiTranslate}
                            lesson={lesson}
                            kanjiDetail={kanjiDetail}
                            scoreIncrementAction={scoreIncrementAction}
                        />
                        <SizeBox vertical="h-3" />
                        {/*renshuu A */}
                        <RenshuuAComponent
                            renshuuA={renshuuA}
                            furigana={furigana}
                            lang={lang}
                            fontSize={fontSize}
                            furiTranslate={Helper.furiTranslate}
                            lesson={lesson}
                            kanjiDetail={kanjiDetail}
                            scoreIncrementAction={scoreIncrementAction}
                        />
                        <SizeBox vertical="h-10" />
                    </div>
                    <SpeedDialComponent
                        type={SPEDIAL.FURI_ONLY}
                        showTranslate={showTranslate}
                        setShowTranslate={setShowTranslate}
                        dark={dark}
                        furigana={furigana}
                        toggleFuri={toggleFuriAction}
                        isHovering={isHovering}
                        setIsHovering={setIsHovering}
                    />
                </>}
            Routes={Routes}
            Logger={Logger}
            useNavigate={useNavigate}
            lesson={lesson}
            showModel={showModel}
            setShowModel={setShowModel}
            setUnlockPremium={setUnlockPremium}
        />
    )
}
export default memo(GrammarScreen);