import Icon from "@consts/icon";

export const lesson45 = {
    title: {
        furi: 'コースを　まちがえた　｜＾場（ば）合（あい）｜は、どう　したら　いいですか',
        normal: 'コースを　まちがえた　場合は、どう　したら　いいですか',
        en: 'What should I do if I take the wrong course?',
        mm: 'လမ်းကြောင်းကို မှားသွားခဲ့ရင် ဘယ်လိုလုပ်ရမလဲ?',
    },
    conversation: [
        {
            person: {
                furi: '＾係（かかり）員（いん）',
                normal: '係員',
                avatar: Icon.Kaiwa.OFFICER
            },
            sentence: [
                {
                    furi: '＾皆（みな）｜さん、この　マラソンは　｜＾健（けん）康（こう）｜マラソンですから、｜＾無（む）理（り）｜をしないで　ください。',
                    normal: '皆さん、この　マラソンは　健康マラソンですから、無理をしないで　ください。',
                    en: "Everyone, this marathon is a health marathon, so please don't push yourself too hard.",
                    mm: 'လူကြီးမင်းများခင်ဗျ အခု မာရသွန်က ကျန်းမာရေးမာရသွန်ဖြစ်တာမို့လို့ အရမ်းကြီးမဖြစ်နိုင်တာကို မလုပ်ပါနဲ့',
                },
                {
                    furi: 'もし｜＾気（き）分（ぶん）｜が　｜＾悪（わる）｜く　なったら、｜＾係（かかり）員（いん）｜に　｜＾言（い）｜って　ください。',
                    normal: 'もし　気分が　悪く　なったら、係員に　言って　ください。',
                    en: 'If you feel sick, please tell the staff.',
                    mm: 'တစ်ကယ်လို့ နေမကောင်းဖြစ်ခဲ့ရင် တာဝန်ခံကို ပြောပေးပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾参（さん）加（か）者（しゃ）',
                normal: '参加者',
                avatar: Icon.Kaiwa.PEOPLE1
            },
            sentence: [
                {
                    furi: 'はい',
                    normal: 'はい',
                    en: 'yes',
                    mm: 'ဟုတ်ကဲ့',
                },
            ],
        },
        {
            person: {
                furi: '＾参（さん）加（か）者（しゃ）｜1',
                normal: '参加者1',
                avatar: Icon.Kaiwa.BOY
            },
            sentence: [
                {
                    furi: 'すみません。コースを　まちがえた｜＾場（ば）合（あい）｜は、どう　したら　いいですか。',
                    normal: 'すみません。コースを　まちがえた　場合は、どう　したら　いいですか。',
                    en: 'Excuse me, What should I do if I take the wrong course?',
                    mm: 'တစ်ဆိတ်လောက်ပါ၊ လမ်းကြောင်းမှားသွားရင် ဘယ်လိုလုပ်ရမလဲ?',
                },
            ],
        },
        {
            person: {
                furi: '＾係（かかり）員（いん）',
                normal: '係員',
                avatar: Icon.Kaiwa.OFFICER
            },
            sentence: [
                {
                    furi: '＾元（もと）｜の｜＾所（ところ）｜に｜＾戻（もど）｜って、｜＾続（つづ）｜けて　ください。',
                    normal: '元の所に戻って、続けて　ください。',
                    en: 'Please go back and continue.',
                    mm: 'မူလနေရာကိုပြန်သွားပြီး ဆက်လုပ်ပါ',
                }
            ],
        },
        {
            person: {
                furi: '＾参（さん）加（か）者（しゃ）｜2',
                normal: '参加者2',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'あのう、｜＾途（と）中（ちゅう）｜で　やめたい｜＾場（ば）合（あい）｜は？',
                    normal: 'あのう、途中で　やめたい　場合は？',
                    en: 'Um, what if I want to stop in the middle?',
                    mm: 'ဟိုလေ...လမ်းတစ်ဝက်မှာ နားချင်တယ်ဆိုရင်ကော?',
                },
            ],
        },
        {
            person: {
                furi: '＾係（かかり）員（いん）',
                normal: '係員',
                avatar: Icon.Kaiwa.OFFICER
            },
            sentence: [
                {
                    furi: 'その｜＾場（ば）合（あい）｜は、｜＾近（ちか）｜くの　｜＾係（かかり）員（いん）｜に　｜＾名（な）前（まえ）｜を　｜＾言（い）｜って　｜＾帰（かえ）｜って　ください。',
                    normal: 'その場合は、近くの　係員に　名前を　言って　帰って　ください。',
                    en: 'In that case, please tell the staff nearby your name and go home.',
                    mm: 'အဲ့ဒီအခြေအနေမှာဆိုရင် အနီးနားမှာရှိတဲ့ တာဝန်ခံဆီမှာ နာမည်ပြောပြီးတော့ ပြန်လို့ရပါတယ်',
                },
                {
                    furi: 'では、スタートの　｜＾時（じ）間（かん）｜です。',
                    normal: 'では、スタートの　時間です。',
                    en: "Now then, it's time to start.",
                    mm: 'ဒါဖြင့်ရင် ပြိုင်ပွဲစမယ့်အချိန်ရောက်ပါပြီ',
                },
                {
                    furi: '。。。。。。。。。。。。',
                    normal: '。。。。。。。。。。。。',
                    en: '',
                    mm: '',
                }
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'ミラーさん、マラソンは　どうでしたか。',
                    normal: 'ミラーさん、マラソンは　どうでしたか。',
                    en: '',
                    mm: 'မိရစံ မာရသွန်က ဘယ်လိုဖြစ်သွားလည်း',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: '２｜＾位（い）｜でした',
                    normal: '２位でした',
                    en: '',
                    mm: 'ဒုတိယရခဲ့ပါတယ်',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '２｜＾位（い）｜だったんですか。すごいですね。',
                    normal: '２位だったんですか。すごいですね。',
                    en: '',
                    mm: 'ဒုတိယလား တော်လိုက်တာ',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'いいえ、｜＾一（いっ）生（しょう）懸（けん）命（めい）｜　｜＾練（れん）習（しゅう）｜したのに、　｜＾優（ゆう）勝（しょう）｜できなくて、｜＾残（ざん）念（ねん）｜です。',
                    normal: 'いいえ、一生懸命　練習したのに、　優秀できなくて、残念です。',
                    en: '',
                    mm: 'ဟင့်အင်း၊ အားသွန်ခွန်စိုက် လေ့ကျင့်ထားပေမယ့်လည်း အနိုင်မရခဲ့လို့ စိတ်မကောင်းဘူး',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'また　｜＾来（らい）年（ねん）｜が　ありますよ。',
                    normal: 'また　来年が　ありますよ。',
                    en: '',
                    mm: 'နောက်နှစ်ရှိသေးတယ်လေ',
                },
            ],
        }
    ],
    audioUrl: require("@assets/audio/lesson45/kaiwa/4N45KW.mp3")
}
/*
{
    title: {
        furi: '',
        normal: '',
        en: '',
        mm: '',
    },
    conversation: [
        {
            person: {
                furi: '',
                normal: '',
                avatar: Icon.Kaiwa.OFFICER
            },
            sentence: [
                {
                    furi: '',
                    normal: '',
                    en: '',
                    mm: '',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson36/kaiwa/4N36KW.mp3")
}
*/