// import { interview } from "@consts/interview";
import Icon from "@consts/icon";
import { TopSpace } from "@consts/layout";
import TestScreenLayout from "@layouts/test/TestScreenLayout";
import { Card } from "@material-tailwind/react";
import { ScreenPropType } from "App";
import { memo, useEffect } from "react";
const ScoreScreen = ({ showModel,setShowModel,setUnlockPremium,premium,selector, name, Logger, Helper, dispatch, setName, routeHistory, setRouteData, Routes, useNavigate, testPoint, useLocation, lang, fontSize, dark, furigana, toggleFuri }: ScreenPropType) => {
    const {pro} = selector(({service})=>service);
    const navigate = useNavigate();
    const testDetail = (index:number) => {
        // console.log("from TestScreeen",index);
        navigate(Routes.TEST_DETAIL, { state: { id: index } });
    } 
    // Temporary of data before store config
            //jlpt2018,jlpt201407,jlpt201312,jlpt201307,jlpt2012,jlpt2010
    const tests: Array<any> = [
        { title: "Test 1", point: testPoint['jlpt2018'], isLock: false },
        { title: "Test 2", point: testPoint['jlpt201407'], isLock: true },
        { title: "Test 3", point: testPoint['jlpt201312'], isLock: true },
        { title: "Test 4", point: testPoint['jlpt201307'],  isLock: true },
        { title: "Test 5", point: testPoint['jlpt2012'], isLock: true},
        { title: "Test 6", point: testPoint['jlpt2010'], isLock: true }
    ];
    interface TestType {
        title: String;
        point: number;
        isLock: boolean;
        index: number;
    }

    const RenderCard = ({title,point,isLock,index}: TestType)=>{
        const {status,style} = getPointStatus(point);
        return !premium && isLock ?
            // Lock
            <Card onClick={()=>setShowModel(true)}
                className="shadow-md dark:shadow-[#344a5a] btn h-[55px] md:h-[75px] mb-[24px] md:mb-[30px] pl-[20px] md:pl-[70px] pr-[15px] md:pr-[30px] flex flex-row items-center justify-between bg-[#CCCCCC] dark:bg-[#344a5a] rounded-lg">
                <div className="text-[#424242] dark:text-white text-[15px] md:text-[25px]">{title}</div>
                <div><img src={Icon.Lock} className="w-[22px] md:w-[40px] h-[22px] md:h-[40px]"/></div>
            </Card >
            // no lock free version
            : <Card onClick={() => testDetail(index)} className="shadow-md dark:shadow-[#344a5a]  btn h-[55px] md:h-[75px] mb-[24px] md:mb-[30px] pl-[20px] md:pl-[70px] pr-[15px] md:pr-[30px] flex flex-row items-center justify-between bg-white dark:bg-[#001E34] rounded-lg">
                <div className="text-[#424242] dark:text-white text-[15px] md:text-[25px]">{title}</div>
                <div className={`h-[27px] md:h-[41px] px-[18px] md:px-[22px] text-[15px] md:text-[18px]  rounded-full flex flex-row items-center btn ${style}`}>{status}</div>
            </Card>
    }

    const getPointStatus = (point: number)=>{
        return point == -1?{status: `Not Complete`,style: "bg-gray-400 text-white"}:
        point<20?{status: `Fail | ${point} Points`,style: "bg-[#FF516E] text-white"}:
        point<=40?{status: `Fair | ${point} Points`,style: "bg-amber-500 text-white"}:
        point>40?{status: `Success | ${point} Points`,style: "bg-[#00E392] text-white"}:{}
    }
    useEffect(() => {
        if (routeHistory.test.route != Routes.TEST) {
            setRouteData({
                test: {
                    state: {},
                    route: Routes.TEST
                }
            })
        }
    })
    return (
        <TestScreenLayout
            body={
                <div className="container mx-auto">
                    <TopSpace/>
                    {
                        (pro?tests.map(({title,point,isLock})=>({title,point,isLock:false})):tests).map(({title,point,isLock},index)=>{
                            return <RenderCard
                                key={index}
                                index={index}
                                title={title}
                                point={point}
                                isLock={isLock}
                            />
                        })
                    }
                </div>
            }
            Routes={Routes}
            Logger={Logger}
            useNavigate={useNavigate}
            showModel={showModel}
            setShowModel={setShowModel}
            setUnlockPremium={setUnlockPremium}
        />

    )
}
export default memo(ScoreScreen);
