export const explain= [
    { 
        pattern_furi: '~（Vた/Vない）ほうがいいです',
        // pattern_normal: '＾友（とも）達（だち）｜とコーヒー',
        pattern_normal: '~（Vた/Vない）ほうがいいです',
        en: [
            '　It is good to do/not to do',
        ],
        mm: [
            '　လုပ်တာ/ မလုပ်တာ ကောင်းတယ်',
        ]
    },
    {
        pattern_furi: '~（Vる/Vない/Adj/N）でしょう',
        pattern_normal: '~（Vる/Vない/Adj/N）でしょう',
        en: [
            '　It will be / not be',
        ],
        mm: [
            '　ဖြစ်လိမ့်မယ်/ မဟုတ်လောက်ဘူး',
        ]
    },
    {
        pattern_furi: '~（Vる/Vない/Adj/N）かもしれません',
        pattern_normal: '~（Vる/Vない/Adj/N）かもしれません',
        en: [
            "　I think/ Perhaps (used to talk about things that don't know much about or are not sure about)",
            '　It is a polite term.'
        ],
        mm: [
            '　ဖြစ်‌လေမလားမသိဘူး၊ ဖြစ်ကောင်းဖြစ်နိုင်တယ် (သိပ်မသိ၊ သိပ်မသေချာသော ကိစ္စများကို ပြောရာတွင်သုံးသည်)',
            '　ယဉ်ကျေးသော အသုံးအနှုန်းဖြစ်သည်',
        ]
    }

]