import SizeBox from "@components/SizeBox";
import AudioPlayer from "@components/common/AudioPlayComponent";
import Number from "@components/common/Number";
import SubNavComponent from "@components/common/SubNavComponent";
import Helper from "@helper";
import { memo } from "react";

const VocabWebComponent = ({ nabs, data, furigana, showVocab, furiTranslate, fontSize, showTranslate, lang, dark, onShuffle, lesson, kanjiDetail }:any)=>{
    const style = {
        container: 'flex-1 flex flex-row items-center px-[50px]',
        design:' border-b-[1px]'
    }
    const hasRemark = ({ remark_en, remark_mm }: { remark_en: string, remark_mm: string }):boolean => {
        if (lang === "en") {
            return remark_en !== '' || remark_en.length > 0;
        } else {
            return remark_mm != '' || remark_mm.length > 0;
        }
    }
    return (<>
         {/* Desktop View */}
         <div className="container mx-auto hidden md:flex flex-col ">
            <SubNavComponent nabs={nabs} lesson={lesson} />
            <SizeBox vertical="h-10"/>
            {   
                data.map(({furi,normal,mm,remark_mm,en,remark_en,audioUrl}:any,i:number) => {
                    return (
                    <div key={i}>
                        <div className={`flex flex-row items-center relative`}>
                            {
                                    showVocab && <div className={`${Helper.pushStyle(style)} bg-[#D0EBFF] border-white dark:border-[#424242] dark:bg-[#001829] ${hasRemark({remark_en,remark_mm})?'  h-[70px]':'h-[60px]' } `}>         
                                    <div className=" py-0">
                                        <Number fontSize={fontSize} furigana={furigana}>
                                            {i + 1}
                                            <span className="ml-2">.</span>
                                        </Number>
                                    </div>
                                        <div className="flex-1">{furigana === 'true' ? furiTranslate({ context: furi, fontSize, style: "flex-wrap items-center", kanjiDetail }) : normal}</div>
                                </div>
                            }
                            {
                                showTranslate&& 
                                    <div className={`${Helper.pushStyle(style)} border-[#C9C9C9] dark:border-[#424242] bg-white dark:bg-[#162027]  ${hasRemark({ remark_en, remark_mm }) ? '  h-[70px]' : 'h-[60px]' } `}>
                                    <div className="flex-1 flex flex-row">
                                        {(lang === "en")?
                                        <div className="">
                                            <div>{en}</div>
                                        {remark_en && <div>
                                                    <div className="">
                                                        <span className="text-sm ml-2"> ※{remark_en}
                                                    </span>
                                                </div>                       
                                                </div>} 
                                            </div>
                                            :
                                    <div className="">
                                        <div>{mm}</div>
                                        {remark_mm && <div>
                                            <div className="">
                                                <span className="text-sm ml-2"> ※{remark_mm}
                                                </span>
                                            </div>
                                        </div>}
                                    </div>
                                        }
                                    </div>
                                    <div className="" >
                                        {audioUrl && !onShuffle && <AudioPlayer audioUrl={audioUrl} />}
                                    </div>
                                    </div>
                                }
                            </div>
                    </div>
                    )
                })
            }                      
        </div>         
    </>)
}

export default memo(VocabWebComponent);