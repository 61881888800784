export const lesson41 = [
  {
      "furi": "いただきます",
      "normal": "いただきます",
      "en": "receive",
      "remark_en": "",
      "mm": "လက်ခံသည်၊ ရယူသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO1.mp3")
  },
  {
      "furi": "くださいます",
      "normal": "くださいます",
      "en": "give",
      "remark_en": "",
      "mm": "ပေးသည်(မိမိအား)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO2.mp3")
  },
  {
      "furi": "やります",
      "normal": "やります",
      "en": "give",
      "remark_en": "",
      "mm": "လုပ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO3.mp3")
  },
  {
      "furi": "＾上（あ）｜げます",
      "normal": "上げます",
      "en": "to raise",
      "remark_en": "",
      "mm": "မြှင်တင်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO4.mp3")
  },
  {
      "furi": "＾下（さ）｜げます",
      "normal": "下げます",
      "en": "reduce, low",
      "remark_en": "",
      "mm": "လျော့ချသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO5.mp3")
  },
  {
      "furi": "＾親（しん）切（せつ）｜にします",
      "normal": "親切にします",
      "en": "be kind to",
      "remark_en": "",
      "mm": "ကြင်ကြင်နာနာဆက်ဆံသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO6.mp3")
  },
  {
      "furi": "かわいい",
      "normal": "かわいい",
      "en": "lovely, cute",
      "remark_en": "",
      "mm": "ချစ်စရာကောင်းသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO7.mp3")
  },
  {
      "furi": "＾珍（めずら）｜しい",
      "normal": "珍しい",
      "en": "rare",
      "remark_en": "",
      "mm": "ရှားပါးသော၊ထူးဆန်းသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO8.mp3")
  },
  {
      "furi": "お｜＾祝（いわ）｜い",
      "normal": "お祝い",
      "en": "celebration, gift",
      "remark_en": "",
      "mm": "ဂုဏ်ပြုခြင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO9.mp3")
  },
  {
      "furi": "お｜＾年（とし）｜＾玉（だま）",
      "normal": "お年玉",
      "en": "money given as New Year’s gift",
      "remark_en": "",
      "mm": "နှစ်သစ်ကူးမုန့်ဖိုး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO10.mp3")
  },
  {
      "furi": "[お]｜＾見（み）｜＾舞（ま）｜い",
      "normal": "[お]見舞い",
      "en": "expression of sympathy",
      "remark_en": "",
      "mm": "လူနာသတင်းမေးခြင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO11.mp3")
  },
  {
      "furi": "＾興（きょう）味（み）",
      "normal": "興味",
      "en": "interest",
      "remark_en": "",
      "mm": "စိတ်ဝင်စားမှု",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO12.mp3")
  },
  {
      "furi": "＾情（じょう）報（ほう）",
      "normal": "情報",
      "en": "information",
      "remark_en": "",
      "mm": "သတင်းအချက်အလက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO13.mp3")
  },
  {
      "furi": "＾文（ぶん）法（ぽう）",
      "normal": "文法",
      "en": "Grammar",
      "remark_en": "",
      "mm": "သဒ္ဒါ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO14.mp3")
  },
  {
      "furi": "＾発（はつ）音（おん）",
      "normal": "発音",
      "en": "pronunciation",
      "remark_en": "",
      "mm": "အသံထွက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO15.mp3")
  },
  {
      "furi": "＾猿（さる）",
      "normal": "猿",
      "en": "monkey",
      "remark_en": "",
      "mm": "မျောက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO16.mp3")
  },
  {
      "furi": "えさ",
      "normal": "えさ",
      "en": "feed, bait",
      "remark_en": "",
      "mm": "အစာ(တိရိစ္ဆာန်)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO17.mp3")
  },
  {
      "furi": "おもちゃ",
      "normal": "おもちゃ",
      "en": "toy",
      "remark_en": "",
      "mm": "ကစားစရာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO18.mp3")
  },
  {
      "furi": "＾絵（え）本（ほん）",
      "normal": "絵本",
      "en": "picture book",
      "remark_en": "",
      "mm": "ရုပ်ပြစာအုပ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO19.mp3")
  },
  {
      "furi": "＾絵（え）｜はがき",
      "normal": "絵はがき",
      "en": "picture postcard",
      "remark_en": "",
      "mm": "ရုံပုံပို့စကတ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO20.mp3")
  },
  {
      "furi": "ドライバー",
      "normal": "ドライバー",
      "en": "driver",
      "remark_en": "",
      "mm": "ယာဉ်မောင်းသူ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO21.mp3")
  },
  {
      "furi": "パンカチ",
      "normal": "パンカチ",
      "en": "handkerchief",
      "remark_en": "",
      "mm": "လက်ကိုင်ပဝါ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO22.mp3")
  },
  {
      "furi": "＾靴（くつ）下（した）",
      "normal": "靴下",
      "en": "socks, stockings",
      "remark_en": "",
      "mm": "ခြေအိတ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO23.mp3")
  },
  {
      "furi": "＾手（て）袋（ぶくろ）",
      "normal": "手袋",
      "en": "gloves",
      "remark_en": "",
      "mm": "လက်အိတ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO24.mp3")
  },
  {
      "furi": "＾幼（よう）稚（ち）園（えん）",
      "normal": "幼稚園",
      "en": "Pre-school",
      "remark_en": "",
      "mm": "မူကြို",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO25.mp3")
  },
  {
      "furi": "＾暖（だん）房（ぼう）",
      "normal": "暖房",
      "en": "heater",
      "remark_en": "",
      "mm": "လေနွေးစက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO26.mp3")
  },
  {
      "furi": "＾冷（れい）房（ぼう）",
      "normal": "冷房",
      "en": "air cooler",
      "remark_en": "",
      "mm": "လေအေးစက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO27.mp3")
  },
  {
      "furi": "＾温（おん）度（ど）",
      "normal": "温度",
      "en": "temperature",
      "remark_en": "",
      "mm": "အပူအချိန်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO28.mp3")
  },
  {
      "furi": "＾祖（そ）父（ふ）",
      "normal": "祖父",
      "en": "grandfather(my)",
      "remark_en": "",
      "mm": "အဖိုး(မိမိ၏)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO29.mp3")
  },
  {
      "furi": "＾祖（そ）母（ぼ）",
      "normal": "祖母",
      "en": "grandmother(my)",
      "remark_en": "",
      "mm": "အဖွား(မိမိ၏)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO30.mp3")
  },
  {
      "furi": "＾孫（まご）",
      "normal": "孫",
      "en": "grandchild(my)",
      "remark_en": "",
      "mm": "မြေး(မိမိ၏)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO31.mp3")
  },
  {
      "furi": "お｜＾孫（まご）｜さん",
      "normal": "お孫さん",
      "en": "grandchild(someone's)",
      "remark_en": "",
      "mm": "မြေး(သူတစ်ပါး)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO32.mp3")
  },
  {
      "furi": "おじ",
      "normal": "おじ",
      "en": "uncle(my)",
      "remark_en": "",
      "mm": "ဦးလေး(မိမိ၏)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO33.mp3")
  },
  {
      "furi": "おじさん",
      "normal": "おじさん",
      "en": "uncle(someone's)",
      "remark_en": "",
      "mm": "ဦးလေး(သူတစ်ပါး)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO34.mp3")
  },
  {
      "furi": "おば",
      "normal": "おば",
      "en": "aunt(my)",
      "remark_en": "",
      "mm": "အဒေါ်(မိမိ၏)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO35.mp3")
  },
  {
      "furi": "おばさん",
      "normal": "おばさん",
      "en": "aunt(someone's)",
      "remark_en": "",
      "mm": "အဒေါ်(သူတစ်ပါး)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO36.mp3")
  },
  {
      "furi": "＾管（かん）理（り）人（にん）",
      "normal": "管理人",
      "en": "manager",
      "remark_en": "",
      "mm": "တာဝန်ရှိသူ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO37.mp3")
  },
  {
      "furi": "～さん",
      "normal": "～さん",
      "en": "~Mr,~Ms",
      "remark_en": "",
      "mm": "လူကြီးမင်း～",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO38.mp3")
  },
  {
      "furi": "この｜＾間（あいだ）",
      "normal": "この間",
      "en": "During this time",
      "remark_en": "",
      "mm": "ဟိုတလော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO39.mp3")
  },
  {
      "furi": "＾一（ひと）言（こと）",
      "normal": "一言",
      "en": "A word",
      "remark_en": "",
      "mm": "စကားလေးတစ်ခွန်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO40.mp3")
  },
  {
      "furi": "～ずつ",
      "normal": "～ずつ",
      "en": "~Each",
      "remark_en": "",
      "mm": "～စီ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO41.mp3")
  },
  {
      "furi": "＾二（ふた）人（り）",
      "normal": "二人",
      "en": "Two persons, couple",
      "remark_en": "",
      "mm": "စုံတွဲ၊ အတွဲ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO42.mp3")
  },
  {
      "furi": "お｜＾宅（たく）",
      "normal": "お宅",
      "en": "house",
      "remark_en": "",
      "mm": "အိမ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO43.mp3")
  },
  {
      "furi": "どうぞお｜＾幸（しあわ）｜せに",
      "normal": "どうぞお幸せに",
      "en": "To please your happiness",
      "remark_en": "",
      "mm": "ရွှင်လန်းချမ်းမြေ့ပါစေ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson41/vocab/4N41VO44.mp3")
  },
  {
      "furi": "＾昔（むかし）話（はなし）",
      "normal": "昔話",
      "en": "old tale, folklore",
      "remark_en": "",
      "mm": "ရှေးဟောင်းပုံပြင်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "ある～",
      "normal": "ある～",
      "en": "a certain ~, one ~",
      "remark_en": "",
      "mm": "တစ်ချို့～",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾男（おとこ）",
      "normal": "男",
      "en": "man",
      "remark_en": "",
      "mm": "အမျိုးသား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾子（こ）｜どもたち",
      "normal": "子どもたち",
      "en": "children",
      "remark_en": "",
      "mm": "ကလေးများ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "いじめます",
      "normal": "いじめます",
      "en": "bully, abuse",
      "remark_en": "",
      "mm": "အနိုင်ကျင့်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "かめ",
      "normal": "かめ",
      "en": "turtle, tortoise",
      "remark_en": "",
      "mm": "လိပ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾助（たす）｜けます",
      "normal": "助けます",
      "en": "save, help",
      "remark_en": "",
      "mm": "ကူညီသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾優（やさ）｜しい",
      "normal": "優しい",
      "en": "kind",
      "remark_en": "",
      "mm": "သဘောကောင်းသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "お｜＾姫（ひめ）｜＾様（さま）",
      "normal": "お姫様",
      "en": "princess",
      "remark_en": "",
      "mm": "သမီးတော်၊မင်းသမီး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾暮（く）｜らします",
      "normal": "暮らします",
      "en": "live, lead a life",
      "remark_en": "",
      "mm": "နေထိုင်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾陸（りく）",
      "normal": "陸",
      "en": "land, shore",
      "remark_en": "",
      "mm": "ကုန်းမြေ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "すると",
      "normal": "すると",
      "en": "and, then",
      "remark_en": "",
      "mm": "ဆိုရင်ပဲ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾煙（けむり）",
      "normal": "煙",
      "en": "smoke",
      "remark_en": "",
      "mm": "မီးခိုး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾真（ま）｜っ｜＾白（しろ）｜[な]",
      "normal": "真っ白[な]",
      "en": "pure white",
      "remark_en": "",
      "mm": "ဖြူစွတ်သော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾中（なか）身（み）",
      "normal": "中身",
      "en": "content",
      "remark_en": "",
      "mm": "အတွင်းသား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾浦（うら）島（しま）太（た）郎（ろう）",
      "normal": "浦島太郎",
      "en": "Urashima Taro(name)",
      "remark_en": "",
      "mm": "အူရရှိမတာရော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  }
]

/*
    {
        furi: "",
        normal: "",
        en: "",
        remark_en: "",
        mm: "",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson29/vocab/4N29VO1.mp3")
    },


*/
const test = [
  {
    'goi': 'いただきます',
    'unicode': 'လက်ခံသည်၊ ရယူသည်',
    'zawgyi': 'လက္ခံသည္၊ ရယူသည္',
    'english': 'receive',
    'long': false
  },
  {
    'goi': 'くださいます',
    'unicode': 'ပေးသည်(မိမိအား)',
    'zawgyi': 'ေပးသည္(မိမိအား)',
    'english': 'give',
    'long': false
  },
  {
    'goi': 'やります',
    'unicode': 'လုပ်သည်',
    'zawgyi': 'လုပ္သည္',
    'english': 'give',
    'long': false
  },
  {
    'goi': '上（あ）」げます',
    'unicode': 'မြှင်တင်သည်',
    'zawgyi': 'ျမႇင္တင္သည္',
    'english': 'to raise',
    'long': false
  },
  {
    'goi': '下（さ）」げます',
    'unicode': 'လျော့ချသည်',
    'zawgyi': 'ေလ်ာ့ခ်သည္',
    'english': 'reduce, low',
    'long': false
  },
  {
    'goi': '親（しん）」切（せつ）」にします',
    'unicode': 'ကြင်ကြင်နာနာဆက်ဆံသည်',
    'zawgyi': 'ၾကင္ၾကင္နာနာဆက္ဆံသည္',
    'english': 'be kind to',
    'long': true
  },
  {
    'goi': 'かわいい',
    'unicode': 'ချစ်စရာကောင်းသော',
    'zawgyi': 'ခ်စ္စရာေကာင္းေသာ',
    'english': 'lovely, cute',
    'long': false
  },
  {
    'goi': '珍（めずら）」しい',
    'unicode': 'ရှားပါးသော၊ထူးဆန်းသော',
    'zawgyi': 'ရွားပါးေသာ၊ထူးဆန္းေသာ',
    'english': 'rare',
    'long': false
  },
  {
    'goi': 'お」祝（いわ）」い',
    'unicode': 'ဂုဏ်ပြုခြင်း',
    'zawgyi': 'ဂုဏ္ျပဳျခင္း',
    'english': 'celebration, gift',
    'long': false
  },
  {
    'goi': 'お」年（とし）」玉（だま）',
    'unicode': 'နှစ်သစ်ကူးမုန့်ဖိုး',
    'zawgyi': 'ႏွစ္သစ္ကူးမုန႔္ဖိုး',
    'english': 'money given as New Year’s gift',
    'long': false
  },
  {
    'goi': '[お]」見（み）」舞（ま）」い',
    'unicode': 'လူနာသတင်းမေးခြင်း',
    'zawgyi': 'လူနာသတင္းေမးျခင္း',
    'english': 'expression of sympathy',
    'long': false
  },
  {
    'goi': '興（きょう）」味（み）',
    'unicode': 'စိတ်ဝင်စားမှု',
    'zawgyi': 'စိတ္ဝင္စားမႈ',
    'english': 'interest',
    'long': false
  },
  {
    'goi': '情（じょう）」報（ほう）',
    'unicode': 'သတင်းအချက်အလက်',
    'zawgyi': 'သတင္းအခ်က္အလက္',
    'english': 'information',
    'long': false
  },
  {
    'goi': '文（ぶん）」法（ぽう）',
    'unicode': 'သဒ္ဒါ',
    'zawgyi': 'သဒၵါ',
    'english': 'Grammar',
    'long': false
  },
  {
    'goi': '発（はつ）」音（おん）',
    'unicode': 'အသံထွက်',
    'zawgyi': 'အသံထြက္',
    'english': 'pronunciation',
    'long': false
  },
  {
    'goi': '猿（さる）',
    'unicode': 'မျောက်',
    'zawgyi': 'ေမ်ာက္',
    'english': 'monkey',
    'long': false
  },
  {
    'goi': 'えさ',
    'unicode': 'အစာ(တိရိစ္ဆာန်)',
    'zawgyi': 'အစာ(တိရိစၧာန္)',
    'english': 'feed, bait',
    'long': false
  },
  {
    'goi': 'おもちゃ',
    'unicode': 'ကစားစရာ',
    'zawgyi': 'ကစားစရာ',
    'english': 'toy',
    'long': false
  },
  {
    'goi': '絵（え）」本（ほん）',
    'unicode': 'ရုပ်ပြစာအုပ်',
    'zawgyi': '႐ုပ္ျပစာအုပ္',
    'english': 'picture book',
    'long': false
  },
  {
    'goi': '絵（え）」はがき',
    'unicode': 'ရုံပုံပို့စကတ်',
    'zawgyi': '႐ုံပုံပို႔စကတ္',
    'english': 'picture postcard',
    'long': false
  },
  {
    'goi': 'ドライバー',
    'unicode': 'ယာဉ်မောင်းသူ',
    'zawgyi': 'ယာဥ္ေမာင္းသူ',
    'english': 'driver',
    'long': false
  },
  {
    'goi': 'パンカチ',
    'unicode': 'လက်ကိုင်ပဝါ',
    'zawgyi': 'လက္ကိုင္ပဝါ',
    'english': 'handkerchief',
    'long': false
  },
  {
    'goi': '靴（くつ）」下（した）',
    'unicode': 'ခြေအိတ်',
    'zawgyi': 'ေျခအိတ္',
    'english': 'socks, stockings',
    'long': false
  },
  {
    'goi': '手（て）」袋（ぶくろ）',
    'unicode': 'လက်အိတ်',
    'zawgyi': 'လက္အိတ္',
    'english': 'gloves',
    'long': false
  },
  {
    'goi': '幼（よう）」稚（ち）」園（えん）',
    'unicode': 'မူကြို',
    'zawgyi': 'မူႀကိဳ',
    'english': 'Pre-school',
    'long': false
  },
  {
    'goi': '暖（だん）」房（ぼう）',
    'unicode': 'လေနွေးစက်',
    'zawgyi': 'ေလေႏြးစက္',
    'english': 'heater',
    'long': false
  },
  {
    'goi': '冷（れい）」房（ぼう）',
    'unicode': 'လေအေးစက်',
    'zawgyi': 'ေလေအးစက္',
    'english': 'air cooler',
    'long': false
  },
  {
    'goi': '温（おん）」度（ど）',
    'unicode': 'အပူအချိန်',
    'zawgyi': 'အပူအခ်ိန္',
    'english': 'temperature',
    'long': false
  },
  {
    'goi': '祖（そ）」父（ふ）',
    'unicode': 'အဖိုး(မိမိ၏)',
    'zawgyi': 'အဖိုး(မိမိ၏)',
    'english': 'grandfather(my)',
    'long': false
  },
  {
    'goi': '祖（そ）」母（ぼ）',
    'unicode': 'အဖွား(မိမိ၏)',
    'zawgyi': 'အဖြား(မိမိ၏)',
    'english': 'grandmother(my)',
    'long': false
  },
  {
    'goi': '孫（まご）',
    'unicode': 'မြေး(မိမိ၏)',
    'zawgyi': 'ေျမး(မိမိ၏)',
    'english': 'grandchild(my)',
    'long': false
  },
  {
    'goi': 'お」孫（まご）」さん',
    'unicode': 'မြေး(သူတစ်ပါး)',
    'zawgyi': 'ေျမး(သူတစ္ပါး)',
    'english': 'grandchild(someone\'s)',
    'long': false
  },
  {
    'goi': 'おじ',
    'unicode': 'ဦးလေး(မိမိ၏)',
    'zawgyi': 'ဦးေလး(မိမိ၏)',
    'english': 'uncle(my)',
    'long': false
  },
  {
    'goi': 'おじさん',
    'unicode': 'ဦးလေး(သူတစ်ပါး)',
    'zawgyi': 'ဦးေလး(သူတစ္ပါး)',
    'english': 'uncle(someone\'s)',
    'long': false
  },
  {
    'goi': 'おば',
    'unicode': 'အဒေါ်(မိမိ၏)',
    'zawgyi': 'အေဒၚ(မိမိ၏)',
    'english': 'aunt(my)',
    'long': false
  },
  {
    'goi': 'おばさん',
    'unicode': 'အဒေါ်(သူတစ်ပါး)',
    'zawgyi': 'အေဒၚ(သူတစ္ပါး)',
    'english': 'aunt(someone\'s)',
    'long': false
  },
  {
    'goi': '管（かん）」理（り）」人（にん）',
    'unicode': 'တာဝန်ရှိသူ',
    'zawgyi': 'တာဝန္ရွိသူ',
    'english': 'manager',
    'long': false
  },
  {
    'goi': '～さん',
    'unicode': 'လူကြီးမင်း～',
    'zawgyi': 'လူႀကီးမင္း～',
    'english': '~Mr,~Ms',
    'long': false
  },
  {
    'goi': 'この」間（あいだ）',
    'unicode': 'ဟိုတလော',
    'zawgyi': 'ဟိုတေလာ',
    'english': 'During this time',
    'long': false
  },
  {
    'goi': '一（ひと）」言（こと）',
    'unicode': 'စကားလေးတစ်ခွန်း',
    'zawgyi': 'စကားေလးတစ္ခြန္း',
    'english': 'A word',
    'long': false
  },
  {
    'goi': '～ずつ',
    'unicode': '～စီ',
    'zawgyi': '～စီ',
    'english': '~Each',
    'long': false
  },
  {
    'goi': '二（ふた）」人（り）',
    'unicode': 'စုံတွဲ၊ အတွဲ',
    'zawgyi': 'စုံတြဲ၊ အတြဲ',
    'english': 'Two persons, couple',
    'long': false
  },
  {
    'goi': 'お」宅（たく）',
    'unicode': 'အိမ်',
    'zawgyi': 'အိမ္',
    'english': 'house',
    'long': false
  },
  {
    'goi': 'どうぞお」幸（しあわ）」せに',
    'unicode': 'ရွှင်လန်းချမ်းမြေ့ပါစေ',
    'zawgyi': 'ရႊင္လန္းခ်မ္းေျမ့ပါေစ',
    'english': 'To please your happiness',
    'long': false
  },
  {
    'goi': '昔（むかし）」話（はなし）',
    'unicode': 'ရှေးဟောင်းပုံပြင်',
    'zawgyi': 'ေရွးေဟာင္းပုံျပင္',
    'english': 'old tale, folklore',
    'long': false
  },
  {
    'goi': 'ある～',
    'unicode': 'တစ်ချို့～',
    'zawgyi': 'တစ္ခ်ိဳ႕～',
    'english': 'a certain ~, one ~',
    'long': false
  },
  {
    'goi': '男（おとこ）',
    'unicode': 'အမျိုးသား',
    'zawgyi': 'အမ်ိဳးသား',
    'english': 'man',
    'long': false
  },
  {
    'goi': '子（こ）」どもたち',
    'unicode': 'ကလေးများ',
    'zawgyi': 'ကေလးမ်ား',
    'english': 'children',
    'long': false
  },
  {
    'goi': 'いじめます',
    'unicode': 'အနိုင်ကျင့်သည်',
    'zawgyi': 'အနိုင္က်င့္သည္',
    'english': 'bully, abuse',
    'long': false
  },
  {
    'goi': 'かめ',
    'unicode': 'လိပ်',
    'zawgyi': 'လိပ္',
    'english': 'turtle, tortoise',
    'long': false
  },
  {
    'goi': '助（たす）」けます',
    'unicode': 'ကူညီသည်',
    'zawgyi': 'ကူညီသည္',
    'english': 'save, help',
    'long': false
  },
  {
    'goi': '優（やさ）」しい',
    'unicode': 'သဘောကောင်းသော',
    'zawgyi': 'သေဘာေကာင္းေသာ',
    'english': 'kind',
    'long': false
  },
  {
    'goi': 'お」姫（ひめ）」様（さま）',
    'unicode': 'သမီးတော်၊မင်းသမီး',
    'zawgyi': 'သမီးေတာ္၊မင္းသမီး',
    'english': 'princess',
    'long': false
  },
  {
    'goi': '暮（く）」らします',
    'unicode': 'နေထိုင်သည်',
    'zawgyi': 'ေနထိုင္သည္',
    'english': 'live, lead a life',
    'long': false
  },
  {
    'goi': '陸（りく）',
    'unicode': 'ကုန်းမြေ',
    'zawgyi': 'ကုန္းေျမ',
    'english': 'land, shore',
    'long': false
  },
  {
    'goi': 'すると',
    'unicode': 'ဆိုရင်ပဲ',
    'zawgyi': 'ဆိုရင္ပဲ',
    'english': 'and, then',
    'long': false
  },
  {
    'goi': '煙（けむり）',
    'unicode': 'မီးခိုး',
    'zawgyi': 'မီးခိုး',
    'english': 'smoke',
    'long': false
  },
  {
    'goi': '真（ま）」っ」白（しろ）」[な]',
    'unicode': 'ဖြူစွတ်သော',
    'zawgyi': 'ျဖဴစြတ္ေသာ',
    'english': 'pure white',
    'long': false
  },
  {
    'goi': '中（なか）」身（み）',
    'unicode': 'အတွင်းသား',
    'zawgyi': 'အတြင္းသား',
    'english': 'content',
    'long': false
  },
  {
    'goi': '浦（うら）」島（しま）」太（た）」郎（ろう）',
    'unicode': 'အူရရှိမတာရော',
    'zawgyi': 'အူရရွိမတာေရာ',
    'english': 'Urashima Taro(name)',
    'long': false
  }
]