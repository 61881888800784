import SubNavComponent from "@components/common/SubNavComponent";
import MondaiOneComponent from "@components/exercise/MondaiOneComponent";
import MondaiTwoComponent from "@components/exercise/MondaiTwoComponent";
import FukushuuScreenLayout from "@layouts/home/FukushuuScreenLayout";
import { memo, useEffect, useState } from "react";
import { ScreenPropType } from "../../../App";
import { BottomSpace, TopSpace } from "@consts/layout";

const FukushuuScreen = ({ showModel,setShowModel,setUnlockPremium,Minna, Helper, getScore, ScoreType, scores, setScores, Logger, furigana, name, dispatch, setName, useLocation, fontSize, createSearchParams, Routes, useNavigate, toggleTheme, changeFontSize, dark,routeHistory,setRouteData }: ScreenPropType) => {
    const location = useLocation();
    const { lesson, id } = location.state;
    const nabs = [
        {
            title: "home",
            route: Routes.HOME,
        },
        {
            title: "Category",
            route: Routes.CATEGORY,
            state: { id, lesson }
        },
        {
            title: "Exercise "+ `${id+22}~${id+26}`,
            route: null
        }
    ];
    const  exerciseId = id == 24 ? 4 : id - (4 * Math.floor(id / 4));
    const [mondai1, setMondai1] = useState<any>(Helper.deepClone(Minna.exercises[exerciseId].mondai1));
    const [mondai2, setMondai2] = useState<any>(Helper.deepClone(Minna.exercises[exerciseId].mondai2));
    // check ans
    const [checkMondai1,setCheckMondai1] = useState<boolean>(false);
    const [checkMondai2,setCheckMondai2] = useState<boolean>(false);

    const pickAnswer = ({ questionId, boxId, ans }: { questionId: number, boxId: number, ans: string }) => {
        setMondai1(
            mondai1.map(({ question, picked, answers, correct }: any, index: number) => {
                if (index === questionId) {
                    // picked = ["が",""],
                    picked[boxId] = ans;
                    return { question, picked, answers, correct };
                } else {
                    return { question, picked, answers, correct };
                }
            }),
        );
    }
    const pickAnswer2 = ({ questionId, answer }: { questionId: number, answer: string }) => {
        setMondai2(
            mondai2.map(({ question, picked, answers, correct }: any, index: number) => {
                if (index === questionId) {
                    return { question, picked: answer, answers, correct };
                } else {
                    return { question, picked, answers, correct };
                }
            })
        );
    }
    useEffect(() => {
        if (routeHistory.home.route != Routes.FUKUSHUU) {
            setRouteData({
                home: {
                    state: { id, lesson },
                    route: Routes.FUKUSHUU
                }
            }) 
        }
    }, [])
    return <FukushuuScreenLayout
        body={<div className="container mx-auto">
            <TopSpace/> 
            <SubNavComponent nabs={nabs} lesson={lesson} style="py-2 pl-2 pr-4" />
            <MondaiOneComponent
                mondai1={mondai1}
                fontSize={fontSize}
                pickAnswer={pickAnswer}
                Helper={Helper}
                underline={Helper.underline}
                furigana={furigana}
                furiTranslate={Helper.furiTranslate}
                checkMondai1={checkMondai1}
                setCheckMondai1={setCheckMondai1}
            />
            <MondaiTwoComponent
                mondai2={mondai2}
                pickAnswer2={pickAnswer2}
                underline={Helper.underline}
                furigana={furigana}
                furiTranslate={Helper.furiTranslate}
                fontSize={fontSize}
                checkMondai2={checkMondai2}
                setCheckMondai2={setCheckMondai2}
            />
        </div>}
        Routes={Routes} Logger={Logger} useNavigate={useNavigate}
        showModel={showModel}
        setShowModel={setShowModel}
        setUnlockPremium={setUnlockPremium}
    />

}
export default memo(FukushuuScreen);