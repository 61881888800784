export const lesson11 = {
    "止": 100,
    "歩": 101,
    "走": 102,
    "起": 103,
    "持": 104,
    "待": 105,
    "借": 106,
    "貸": 107,
    "始": 108,
    "終": 109
};