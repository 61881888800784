export const lesson33 = [
  {
    furi: "にげます",
    normal: "にげます",
    en: "run away",
    remark_en: "",
    mm: "ထွက်ပြေးသည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO1.mp3")
  },
  {
    furi: "さわぎます",
    normal: "さわぎます",
    en: "make a noise",
    remark_en: "",
    mm: "ဆူပူသည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO2.mp3")
  },
  {
    furi: "あきらめます",
    normal: "あきらめます",
    en: "give up",
    remark_en: "",
    mm: "လက်လျှော့သည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO3.mp3")
  },
  {
    furi: "＾投（なげ）｜げます",
    normal: "投げます",
    en: "throw",
    remark_en: "",
    mm: "ပစ်ပေါက်သည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO4.mp3")
  },
  {
    furi: "＾守（まも）｜ります",
    normal: "守ります",
    en: "keep, follow",
    remark_en: "",
    mm: "စောင့်ထိမ်းသည်၊ လိုက်နာသည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO5.mp3")
  },
  {
    furi: "＾始（はじ）｜まります［｜＾式（しき）｜が～］",
    normal: "始まります［式が～］",
    en: "begin, start",
    remark_en: "a ceremony",
    mm: "စသည်၊ စတင်သည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO6.mp3")
  },
  {
    furi: "＾出（しゅっ）席（せき）｜します［｜＾会（かい）議（ぎ）｜に～］",
    normal: "出席します［会議に～］",
    en: "attend",
    remark_en: "a meeting",
    mm: "တက်ရောက်သည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO7.mp3")
  },
  {
    furi: "＾伝（つた）｜えます",
    normal: "伝えます",
    en: "convey",
    remark_en: "",
    mm: "တစ်ဆင့်ပြောသည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO8.mp3")
  },
  {
    furi: "＾注（ちゅう）意（い）｜します［｜＾車（くるま）｜に～］",
    normal: "注意します［車に～］",
    en: "be careful",
    remark_en: "of the cars",
    mm: "သတိထားသည်၊ သတိပေးသည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO9.mp3")
  },
  {
    furi: "＾外（はず）｜しい［｜＾席（せき）｜を～］",
    normal: "外しい［席を～］",
    en: "be away",
    remark_en: "from someone's desk",
    mm: "ခွါသည်၊ ဖယ်သည်၊ ဖြုတ်သည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO10.mp3")
  },
  {
    furi: "＾戻（もど）｜ります",
    normal: "戻ります",
    en: "come back, return",
    remark_en: "",
    mm: "ပြန်လာသည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO11.mp3")
  },
  {
    furi: "あります［｜＾電（でん）話（わ）｜が～］",
    normal: "あります［電話が～］",
    en: "have",
    remark_en: "a telephone call",
    mm: "လာသည်(ဖုန်း)",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO12.mp3")
  },
  {
    furi: "リサイクルします",
    normal: "リサイクルします",
    en: "recycle",
    remark_en: "",
    mm: "ပြန်လည်အသုံးပြသည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO13.mp3")
  },
  {
    furi: "だめ［な］",
    normal: "だめ［な］",
    en: "not good, not permitted",
    remark_en: "",
    mm: "မရသော",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO14.mp3")
  },
  {
    furi: "＾同（おな）｜じ",
    normal: "同じ",
    en: "same",
    remark_en: "",
    mm: "တူညီသည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO15.mp3")
  },
  {
    furi: "＾警（けい）察（さつ）",
    normal: "警察",
    en: "police",
    remark_en: "station",
    mm: "ရဲ၊ ရဲအဖွဲ့",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO16.mp3")
  },
  {
    furi: "＾席（せき）",
    normal: "席",
    en: "seat",
    remark_en: "",
    mm: "ထိုင်ခုံ၊ နေရာ",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO17.mp3")
  },
  {
    furi: "マーク",
    normal: "マーク",
    en: "mark, symbol",
    remark_en: "",
    mm: "အမှတ်အသား",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO18.mp3")
  },
  {
    furi: "ボール",
    normal: "ボール",
    en: "ball",
    remark_en: "",
    mm: "ဘောလုံး",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO19.mp3")
  },
  {
    furi: "しめきり",
    normal: "しめきり",
    en: "deadline",
    remark_en: "",
    mm: "စာရင်းပိတ်ချိန်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO20.mp3")
  },
  {
    furi: "＾規（き）則（そく）",
    normal: "規則",
    en: "regulation, rule",
    remark_en: "",
    mm: "စည်းမျဉ်းစည်းကမ်း",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO21.mp3")
  },
  {
    furi: "＾危（き）険（けん）",
    normal: "危険",
    en: "Danger",
    remark_en: "",
    mm: "အန္တရာယ်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO22.mp3")
  },
  {
    furi: "＾使（し）用（よう）禁（きん）止（し）",
    normal: "使用禁止",
    en: "Do Not Use",
    remark_en: "",
    mm: "အသုံးမပြုရ",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO23.mp3")
  },
  {
    furi: "＾立（た）｜ち｜＾入（い）｜り｜＾禁（きん）止（し）",
    normal: "立ち入り禁止",
    en: "Keep Out",
    remark_en: "",
    mm: "မဝင်ရ၊ ဝင်ခွင့်မပြု",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO24.mp3")
  },
  {
    furi: "＾徐（じょ）行（こう）",
    normal: "徐行",
    en: "Go Slow",
    remark_en: "",
    mm: "ဖြေးဖြေးမောင်း",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO25.mp3")
  },
  {
    furi: "＾入（いり）口（ぐち）",
    normal: "入口",
    en: "Entrance",
    remark_en: "",
    mm: "ဝင်ပေါက်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO26.mp3")
  },
  {
    furi: "＾出（で）口（ぐち）",
    normal: "出口",
    en: "Exit",
    remark_en: "",
    mm: "ထွက်ပေါက်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO27.mp3")
  },
  {
    furi: "＾非（ひ）常（じょう）口（ぐち）",
    normal: "非常口",
    en: "emergency exit",
    remark_en: "",
    mm: "အရေးပေါ်ထွက်ပေါက်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO28.mp3")
  },
  {
    furi: "＾無（む）料（りょう）",
    normal: "無料",
    en: "Free of charge",
    remark_en: "",
    mm: "အခမဲ့၊ အလကား",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO29.mp3")
  },
  {
    furi: "＾割（わり）引（びき）",
    normal: "割引",
    en: "discount",
    remark_en: "",
    mm: "လျှော့ဈေး",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO30.mp3")
  },
  {
    furi: "＾飲（の）｜み｜＾放（ほう）題（だい）",
    normal: "飲み放題",
    en: "All You Can Drink",
    remark_en: "",
    mm: "အဝသောက်၊ စိတ်ကြိုက်သောက်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO31.mp3")
  },
  {
    furi: "＾使（し）用（よう）中（ちゅう）",
    normal: "使用中",
    en: "In Use",
    remark_en: "",
    mm: "အသုံးပြုဆဲ",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO32.mp3")
  },
  {
    furi: "＾募（ぼ）集（しゅう）中（ちゅう）",
    normal: "募集中",
    en: "Applications Wanted",
    remark_en: "",
    mm: "လက်ခံဆဲ",
    remark_mm: "အလုပ်လျှောက်လွှာ",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO33.mp3")
  },
  {
    furi: "～｜＾中（ちゅう）",
    normal: "～中",
    en: "～ing",
    remark_en: "",
    mm: "～ဆဲ၊～စဉ်၊～တုန်း၊～ခိုက်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO34.mp3")
  },
  {
    furi: "どういう～",
    normal: "どういう～",
    en: "what",
    remark_en: "kind of",
    mm: "ဘယ်လို～၊ဘယ်လိုမျိုး～",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO35.mp3")
  },
  {
    furi: "いくら",
    normal: "いくら",
    en: "however～",
    remark_en: "",
    mm: "ဘယ်လောက်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO36.mp3")
  },
  {
    furi: "～ても",
    normal: "～ても",
    en: "even if～",
    remark_en: "",
    mm: "～ဘဲဖြစ်ဖြစ်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO37.mp3")
  },
  {
    furi: "もう",
    normal: "もう",
    en: "(not) any longer",
    remark_en: "used with negative",
    mm: "ထက်ပြီး၊ နောက်ထပ်",
    remark_mm: "အငြင်းနဲ့တွဲပြီးသုံးသည်",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO38.mp3")
  },
  {
    furi: "あと～",
    normal: "あと～",
    en: "～left",
    remark_en: "",
    mm: "နောက်ထပ်～",
    remark_mm: "ကျန်သေးသည့်သဘောကိုဆိုလိုသည်",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO39.mp3")
  },
  {
    furi: "～ほど",
    normal: "～ほど",
    en: "about～",
    remark_en: "",
    mm: "～လောက်၊～မျှ",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO40.mp3")
  },
  {
    furi: "＾駐（ちゅう）車（しゃ）違（い）反（はん）",
    normal: "駐車違反",
    en: "parking violation",
    remark_en: "",
    mm: "ယာဉ်ရပ်နားခွင့်ချိုးဖောက်ခြင်း",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO41.mp3")
  },
  {
    furi: "＾罰（ばっ）｜金",
    normal: "罰金",
    en: "fine",
    remark_en: "",
    mm: "ဒဏ်ငွေ",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson33/vocab/4N33VO42.mp3")
  },
  {
    furi: "＾地（じ）震（しん）",
    normal: "地震",
    en: "earthquake",
    remark_en: "",
    mm: "မြေငလျင်",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "＾起（お）｜きます",
    normal: "起きます",
    en: "happen",
    remark_en: "",
    mm: "ဖြစ်ပွားသည်",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "＾助（たす）｜け｜＾合（あ）｜います",
    normal: "助け合います",
    en: "help each other",
    remark_en: "",
    mm: "အပြန်အလှန်ကူညီသည်",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "もともと",
    normal: "もともと",
    en: "originally",
    remark_en: "",
    mm: "နဂိုမူလ",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "＾悲（かな）｜しい",
    normal: "悲しい",
    en: "sad",
    remark_en: "",
    mm: "ဝမ်းနည်းသော",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "もっと",
    normal: "もっと",
    en: "more",
    remark_en: "",
    mm: "ပို၍",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "あいさつ",
    normal: "あいさつ",
    en: "greeting",
    remark_en: "",
    mm: "နှုတ်ဆက်စကား၊ အမှာစကား",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "＾相（あい）手（て）",
    normal: "相手",
    en: "the other person",
    remark_en: "",
    mm: "တစ်ဖက်လူ၊ တစ်ဖက်သား",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "＾気（き）持（も）｜ち",
    normal: "気持ち",
    en: "feeling",
    remark_en: "",
    mm: "စိတ်၊ စိတ်ဓါတ်၊ စိတ်နေစိတ်ထား",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
]