export const lesson43 = [
  {
      "furi": "＾増（ふ）｜えます",
      "normal": "増えます",
      "en": "increase",
      "remark_en": "",
      "mm": "တိုးပွားသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO1.mp3")
  },
  {
      "furi": "＾減（へ）｜ります",
      "normal": "減ります",
      "en": "decrease",
      "remark_en": "",
      "mm": "လျော့ပါးသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO2.mp3")
  },
  {
      "furi": "＾上（あ）｜がります",
      "normal": "上がります",
      "en": "rise",
      "remark_en": "",
      "mm": "မြင့်တက်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO3.mp3")
  },
  {
      "furi": "＾下（さ）｜がります",
      "normal": "下がります",
      "en": "fall",
      "remark_en": "",
      "mm": "ကျဆင်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO4.mp3")
  },
  {
      "furi": "＾切（き）｜れます",
      "normal": "切れます",
      "en": "break, snap",
      "remark_en": "",
      "mm": "ပြတ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO5.mp3")
  },
  {
      "furi": "とれます",
      "normal": "とれます",
      "en": "come off",
      "remark_en": "",
      "mm": "ပြုတ်ထွက်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO6.mp3")
  },
  {
      "furi": "＾落（お）｜ちます",
      "normal": "落ちます",
      "en": "fall down",
      "remark_en": "",
      "mm": "ကျသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO7.mp3")
  },
  {
      "furi": "なくなります",
      "normal": "なくなります",
      "en": "run out, be lost",
      "remark_en": "",
      "mm": "ကုန်သည်၊ပျောက်ဆုံးသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO8.mp3")
  },
  {
      "furi": "＾変（へん）｜な",
      "normal": "変な",
      "en": "strange, peculiar",
      "remark_en": "",
      "mm": "ပုံမှန်မဟုတ်သော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO9.mp3")
  },
  {
      "furi": "＾幸（しあわ）｜せな",
      "normal": "幸せな",
      "en": "happy",
      "remark_en": "",
      "mm": "ပျော်ရွှင်ချမ်းမြေ့သော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO10.mp3")
  },
  {
      "furi": "＾楽（らく）｜な",
      "normal": "楽な",
      "en": "easy",
      "remark_en": "",
      "mm": "သက်သာသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO11.mp3")
  },
  {
      "furi": "うまい",
      "normal": "うまい",
      "en": "tasty, good at",
      "remark_en": "",
      "mm": "အရသာကောင်းသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO12.mp3")
  },
  {
      "furi": "まずい",
      "normal": "まずい",
      "en": "not tasty",
      "remark_en": "",
      "mm": "အရသာမကောင်းသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO13.mp3")
  },
  {
      "furi": "つまらない",
      "normal": "つまらない",
      "en": "boring, uninteresting",
      "remark_en": "",
      "mm": "ပျင်းရိငြီးငွေ့စရာကောင်းသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO14.mp3")
  },
  {
      "furi": "＾優（やさ）｜しい",
      "normal": "優しい",
      "en": "kind",
      "remark_en": "",
      "mm": "သဘောကောင်းသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO15.mp3")
  },
  {
      "furi": "ガソリン",
      "normal": "ガソリン",
      "en": "petrol, gasoline",
      "remark_en": "",
      "mm": "လောင်စာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO16.mp3")
  },
  {
      "furi": "＾火（ひ）",
      "normal": "火",
      "en": "fire",
      "remark_en": "",
      "mm": "မီး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO17.mp3")
  },
  {
      "furi": "パンフレット",
      "normal": "パンフレット",
      "en": "Pamphlet",
      "remark_en": "",
      "mm": "လက်ကမ်းစာစောင်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO18.mp3")
  },
  {
      "furi": "＾今（いま）｜にも",
      "normal": "今にも",
      "en": "at any moment",
      "remark_en": "",
      "mm": "မကြာခင်၊တော်ကြာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO19.mp3")
  },
  {
      "furi": "わあ",
      "normal": "わあ",
      "en": "Oh! / Wow!",
      "remark_en": "",
      "mm": "ဝါး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson43/vocab/4N43VO20.mp3")
  },
  {
      "furi": "ばら",
      "normal": "ばら",
      "en": "rose",
      "remark_en": "",
      "mm": "နှင်းဆီပန်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "ドライブ",
      "normal": "ドライブ",
      "en": "driving",
      "remark_en": "",
      "mm": "ကားမောင်းခြင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾理（り）由（ゆう）",
      "normal": "理由",
      "en": "reason",
      "remark_en": "",
      "mm": "အကြောင်းပြချက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾謝（あやま）｜ります",
      "normal": "謝ります",
      "en": "Apologize",
      "remark_en": "",
      "mm": "တောင်းပန်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾知（し）合（あ）｜います",
      "normal": "知合います",
      "en": "Get acquainted",
      "remark_en": "",
      "mm": "သိကျွမ်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  }
]

/*
    {
        furi: "",
        normal: "",
        en: "",
        remark_en: "",
        mm: "",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson29/vocab/4N29VO1.mp3")
    },


*/
const test = [
  {
    'goi': '増（ふ）」えます',
    'unicode': 'တိုးပွားသည်',
    'zawgyi': 'တိုးပြားသည္',
    'english': 'increase',
    'long': false
  },
  {
    'goi': '減（へ）」ります',
    'unicode': 'လျော့ပါးသည်',
    'zawgyi': 'ေလ်ာ့ပါးသည္',
    'english': 'decrease',
    'long': false
  },
  {
    'goi': '上（あ）」がります',
    'unicode': 'မြင့်တက်သည်',
    'zawgyi': 'ျမင့္တက္သည္',
    'english': 'rise',
    'long': false
  },
  {
    'goi': '下（さ）」がります',
    'unicode': 'ကျဆင်းသည်',
    'zawgyi': 'က်ဆင္းသည္',
    'english': 'fall',
    'long': false
  },
  {
    'goi': '切（き）」れます',
    'unicode': 'ပြတ်သည်',
    'zawgyi': 'ျပတ္သည္',
    'english': 'break, snap',
    'long': false
  },
  {
    'goi': 'とれます',
    'unicode': 'ပြုတ်ထွက်သည်',
    'zawgyi': 'ျပဳတ္ထြက္သည္',
    'english': 'come off',
    'long': false
  },
  {
    'goi': '落（お）」ちます',
    'unicode': 'ကျသည်',
    'zawgyi': 'က်သည္',
    'english': 'fall down',
    'long': false
  },
  {
    'goi': 'なくなります',
    'unicode': 'ကုန်သည်၊ပျောက်ဆုံးသည်',
    'zawgyi': 'ကုန္သည္၊ေပ်ာက္ဆုံးသည္',
    'english': 'run out, be lost',
    'long': false
  },
  {
    'goi': '変（へん）」な',
    'unicode': 'ပုံမှန်မဟုတ်သော',
    'zawgyi': 'ပုံမွန္မဟုတ္ေသာ',
    'english': 'strange, peculiar',
    'long': false
  },
  {
    'goi': '幸（しあわ）」せな',
    'unicode': 'ပျော်ရွှင်ချမ်းမြေ့သော',
    'zawgyi': 'ေပ်ာ္ရႊင္ခ်မ္းေျမ့ေသာ',
    'english': 'happy',
    'long': false
  },
  {
    'goi': '楽（らく）」な',
    'unicode': 'သက်သာသော',
    'zawgyi': 'သက္သာေသာ',
    'english': 'easy',
    'long': false
  },
  {
    'goi': 'うまい',
    'unicode': 'အရသာကောင်းသော',
    'zawgyi': 'အရသာေကာင္းေသာ',
    'english': 'tasty, good at',
    'long': false
  },
  {
    'goi': 'まずい',
    'unicode': 'အရသာမကောင်းသော',
    'zawgyi': 'အရသာမေကာင္းေသာ',
    'english': 'not tasty',
    'long': false
  },
  {
    'goi': 'つまらない',
    'unicode': 'ပျင်းရိငြီးငွေ့စရာကောင်းသော',
    'zawgyi': 'ပ်င္းရိၿငီးေငြ႕စရာေကာင္းေသာ',
    'english': 'boring, uninteresting',
    'long': false
  },
  {
    'goi': '優（やさ）」しい',
    'unicode': 'သဘောကောင်းသော',
    'zawgyi': 'သေဘာေကာင္းေသာ',
    'english': 'kind',
    'long': false
  },
  {
    'goi': 'ガソリン',
    'unicode': 'လောင်စာ',
    'zawgyi': 'ေလာင္စာ',
    'english': 'petrol, gasoline',
    'long': false
  },
  {
    'goi': '火（ひ）',
    'unicode': 'မီး',
    'zawgyi': 'မီး',
    'english': 'fire',
    'long': false
  },
  {
    'goi': 'パンフレット',
    'unicode': 'လက်ကမ်းစာစောင်',
    'zawgyi': 'လက္ကမ္းစာေစာင္',
    'english': 'Pamphlet',
    'long': false
  },
  {
    'goi': '今（いま）」にも',
    'unicode': 'မကြာခင်၊တော်ကြာ',
    'zawgyi': 'မၾကာခင္၊ေတာ္ၾကာ',
    'english': 'at any moment',
    'long': false
  },
  {
    'goi': 'わあ',
    'unicode': 'ဝါး',
    'zawgyi': 'ဝါး',
    'english': 'Oh! / Wow!',
    'long': false
  },
  {
    'goi': 'ばら',
    'unicode': 'နှင်းဆီပန်း',
    'zawgyi': 'ႏွင္းဆီပန္း',
    'english': 'rose',
    'long': false
  },
  {
    'goi': 'ドライブ',
    'unicode': 'ကားမောင်းခြင်း',
    'zawgyi': 'ကားေမာင္းျခင္း',
    'english': 'driving',
    'long': false
  },
  {
    'goi': '理（り）」由（ゆう）',
    'unicode': 'အကြောင်းပြချက်',
    'zawgyi': 'အေၾကာင္းျပခ်က္',
    'english': 'reason',
    'long': false
  },
  {
    'goi': '謝（あやま）」ります',
    'unicode': 'တောင်းပန်သည်',
    'zawgyi': 'ေတာင္းပန္သည္',
    'english': 'Apologize',
    'long': false
  },
  {
    'goi': '知（し）」合（あ）」います',
    'unicode': 'သိကျွမ်းသည်',
    'zawgyi': 'သိကၽြမ္းသည္',
    'english': 'Get acquainted',
    'long': false
  }
]