export const bunkei= [
    {
        sentence: [
            {
                furi: '＾先（せん）生（せい）｜が　｜＾言（い）｜った｜！とおりに｜、｜＾書（か）｜きます。',
                normal: '先生が　言った　とおりに、書きます。',
                en: 'I will write as the teacher said.',
                mm: 'ဆရာပြောတဲ့အတိုင်း ရေးမယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson34/bunpou/4N34BK1.mp3")
    },
    {
        sentence: [
            {
                furi: 'ごはんを　｜＾食（た）｜べた｜！あとで｜、｜＾歯（は）｜を　｜＾磨（みが）｜きます。',
                normal: 'ごはんを　食べた　あとで、歯を　磨きます。',
                en: 'After eating rice, I brush my teeth.',
                mm: 'ထမင်းစားပြီးတာနဲ့ သွားတိုက်တယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson34/bunpou/4N34BK2.mp3")
    },
    {
        sentence: [
            {
                furi: 'コーヒーは　｜＾砂（さ）糖（とう）｜を　｜！入（い）れないで｜　｜＾飲（の）｜みます。',
                normal: 'コーヒーは　砂糖を　入れないで　飲みます。',
                en: 'I drink coffee without sugar.',
                mm: 'သကြားမပါတဲ့ ကော်ဖီကို သောက်တယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson34/bunpou/4N34BK3.mp3")
    },
]