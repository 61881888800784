import SizeBox from "@components/SizeBox";
import Number from "@components/common/Number";
import SpeedDialComponent, { SPEDIAL } from "@components/common/SpeedDialComponent";
import SubNavComponent from "@components/common/SubNavComponent";
import KanjiDetailMobileComponent from "@components/kanji/KanjiDetailMobileComponent";
import KanjiDetailWebComponent from "@components/kanji/KanjiDetailWebComponent";
import { Minna } from "@consts";
import { TopSpace } from "@consts/layout";
import Helper from "@helper";
import KanjiDetailScreenLayout from "@layouts/home/KanjiDetailScreenLayout";
import { Card } from "@material-tailwind/react";
import { memo, useEffect, useState } from "react";

export const ListTile = ({ title, body }: any) => {
    return (
        <div className="flex flex-row dark:text-white text-[15px]">
            <div className="flex flex-row items-center w-[80px]">
                <div className="flex-1">{title}</div>
                <div>:</div>
            </div>
            <div className="flex-1 ml-5">{body}</div>
        </div>
    )
}
export const CompoundTile = ({ compound, meaning }: any) => {
    return (
        <Card className="flex flex-row p-3 dark:text-white ">
            <div className="flex-[1]">{compound}</div>
            <div className="">{meaning}</div>
        </Card>
    )
}
export const ExampleTile = ({ sentence, meaning, fontSize,furigana,i }: any) => {
    return (
        <div className="flex flex-row items-start mt-2 md:mt-6">
            <Number fontSize={fontSize} furigana={furigana}>
                {i + 1}
                <span className="ml-2">.</span>
            </Number>
            <div className="flex flex-col">
                <div className="">{sentence}</div>
                <div className="text-[#0084e8]">{meaning}</div>
            </div>
       </div>
    )
}

export const toString = (list: Array<String>) => {
    let result = "";
    list.map((str: any, index: number) => {
        result += index + 1 !== list.length ? (str + ", ") : str;
    });
    return result;
}
const KanjiDetailScreen = ({ showModel,setShowModel,setUnlockPremium,useLocation, useNavigate, Routes, Logger, dark, toggleFuri, fontSize, furigana, lang, routeHistory,setRouteData }: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    // const log = Logger(16);
    const { id, lesson, path, kanjiId,name } = location.state;
    const { kanji, lesson: kanjiLesson } = Minna.kanji.information[kanjiId];

    // Speed Dial 
    const [showTranslate, setShowTranslate] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const nabs = [
        {
            title: "home",
            route: Routes.HOME,
        },
        {
            title: "Category",
            route: Routes.CATEGORY,
            state: { id, lesson }
        },
        {
            title: name? name : "Kanji",
            route: path,
            state: { id, lesson }
        },
        {
            title: kanji,
            route: null
        }
    ];
    // kanji PopOver Detail
    const kanjiDetail = (kanjiId: any) => {
        const state = { lesson, id, name: name ? name : "Kanji", path:navigate(0), kanjiId };
        // console.log(state);
        navigate(Routes.KANJI_DETAIL, { state });
    }
    useEffect(() => {
        if (routeHistory.home.route != Routes.KANJI_DETAIL) {
            setRouteData({
                home: {
                    state: { id, lesson, path, kanjiId, name },
                    route: Routes.KANJI_DETAIL
                }
            })
        }
    }, [])
    return (
        <KanjiDetailScreenLayout
            body={
                <div className="container mx-auto dark:text-dark">
                    <TopSpace/>
                    {/* Sub Nav */}
                    <SubNavComponent nabs={nabs} lesson={parseInt(kanjiLesson) - 26} />
                    <SizeBox vertical="h-[50px] md:h-[75px]" />
                    {/* Web View */}
                    <KanjiDetailWebComponent
                        lesson={lesson}
                        kanjiId={kanjiId}
                        lang={lang}
                        furigana={furigana}
                        fontSize={fontSize}
                        furiTranslate={Helper.furiTranslate}
                        Routes={Routes}
                        kanjiDetail={kanjiDetail}
                    />
                    {/* Mobile View */}
                    <KanjiDetailMobileComponent
                        lesson={lesson}
                        kanjiId={kanjiId}
                        lang={lang}
                        furigana={furigana}
                        fontSize={fontSize}
                        dark={dark}
                        toggleFuri={toggleFuri}
                        furiTranslate={Helper.furiTranslate}
                        kanjiDetail={kanjiDetail}
                    />
                    <SpeedDialComponent
                        type={SPEDIAL.FURI_ONLY}
                        showTranslate={showTranslate}
                        setShowTranslate={setShowTranslate}
                        dark={dark}
                        furigana={furigana}
                        toggleFuri={toggleFuri}
                        isHovering={isHovering}
                        setIsHovering={setIsHovering}
                    />
               
                </div>
            }
            Routes={Routes}
            Logger={Logger}
            useNavigate={useNavigate}
            showModel={showModel}
            setShowModel={setShowModel}
            setUnlockPremium={setUnlockPremium}
        />

        )
}
export default memo(KanjiDetailScreen);