export const renshuuA = [
    {
        sentence: [
            {
                furi: "＾会（かい）社（しゃ）｜に　｜！おくれる｜　｜＾場（ば）合（あい）｜は、｜＾連（れん）絡（らく）｜して　ください。",
                normal: "会社に　おくれる　場合は、連絡して　ください。",
                en: "If you can be late the company, please contact me.",
                mm: "ကုမ္ပဏီ‌နောက်ကျနိုင်လျှင် ကျွန်ုပ်ကို ဆက်သွယ်ပါ။",
            },
            {
                furi: "＾荷（に）物（もつ）｜が　｜！つかない｜　｜＾場（ば）合（あい）｜は、｜＾連（れん）絡（らく）｜して　ください。",
                normal: "荷物が　つかない　場合は、連絡して　ください。",
                en: "Please contact me if you cannot find your package.",
                mm: "ပါဆယ်ကို ရှာမတွေ့ပါက ကျွန်ုပ်ထံ ဆက်သွယ်ပါ။",
            },
            {
                furi: "＾交（こう）通（つう）事（じ）故（こ）｜に　｜！あった｜　｜＾場（ば）合（あい）｜は、｜＾連（れん）絡（らく）｜して　ください。",
                normal: "交通事故に　あった　場合は、連絡して　ください。",
                en: "If you have been in a traffic accident, please contact me.",
                mm: "ယာဉ်မတော်တဆဖြစ်ရင် ကျေးဇူးပြုပြီး ဆက်သွယ်ပေးပါ။",
            },
            {
                furi: "コピー｜＾機（き）｜の　｜＾調（ちょう）子（し）｜が　｜！わるい｜　｜＾場（ば）合（あい）｜は、｜＾連（れん）絡（らく）｜して　ください。",
                normal: "コピー機の　調子が　わるい　場合は、連絡して　ください。",
                en: "If the copier is not working well, please contact me.",
                mm: "မိတ္တူစက် အဆင်မပြေပါက ကျွန်ုပ်ကို ဆက်သွယ်ပါ။",
            },
            {
                furi: "＾資（し）料（りょう）｜が　｜！ひつような｜　｜＾場（ば）合（あい）｜は、｜＾連（れん）絡（らく）｜して　ください。",
                normal: "資料が　ひつような　場合は、連絡して　ください。",
                en: "Please contact me if you need materials.",
                mm: "သင်လိုအပ်သောပစ္စည်းများရှိပါကငါ့ကိုဆက်သွယ်ပါ။",
            },
            {
                furi: "エレベーターが　｜！こしょうの｜　｜＾場（ば）合（あい）｜は、｜＾連（れん）絡（らく）｜して　ください。",
                normal: "エレベーターが　こしょうの　場合は、連絡して　ください。",
                en: "Please let me know if the elevator is broken.",
                mm: "ဓာတ်လှေကား ပျက်နေပါက ကျွန်ုပ်အား အသိပေးပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson45/renshua/4N45RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾一（いっ）生（しょう）懸（けん）命（めい）｜　｜！べんきょうした｜　のに、　｜＾試（し）験（けん）｜の　｜＾点（てん）｜が　｜＾悪（わる）｜かったです。",
                normal: "一生懸命　べんきょうした　のに、　試験の　点が　悪かったです。",
                en: "Even though I studied hard, I got a bad score on the exam.",
                mm: "ကျွန်တော် ခက်ခက်ခဲခဲ စာလုပ်ခဲ့ပေမယ့် စာမေးပွဲမှာ အမှတ်မကောင်းခဲ့ပါဘူး။",
            },
            {
                furi: "３０｜＾分（ぷん）｜も　｜！まっている｜　のに、タクシーが　｜＾来（き）｜ません。",
                normal: "３０分も　まっている　のに、タクシーが　来ません。",
                en: "I've been waiting for 30 minutes, but the taxi hasn't come.",
                mm: "မိနစ် 30 စောင့်နေပေမယ့် တက္ကစီမလာဘူး။",
            },
            {
                furi: "この　レストランは　｜！おいしくない｜　のに、｜＾値（ね）段（だん）｜が｜＾高（たか）｜いです。",
                normal: "この　レストランは　おいしくない　のに、値段が高いです。",
                en: "This restaurant doesn't taste good, but it's expensive.",
                mm: "ဒီစားသောက်ဆိုင်က အရသာမကောင်းဘဲနဲ့ ဈေးကြီးတယ်။",
            },
            {
                furi: "＾夫（おっと）｜は　｜＾料（りょう）理（り）｜が　｜！じょうすな｜　のに、あまり　｜＾作（つく）｜って　くれません。",
                normal: "夫は　料理が　じょうすな　のに、あまり　作って　くれません。",
                en: "My husband is a good cook, but he doesn't cook much.",
                mm: "ကျွန်မခင်ပွန်းက ထမင်းချက်ကောင်းပေမယ့် အများကြီး မချက်တတ်သူပါ။",
            },
            {
                furi: "きょうは　｜！にちようひな｜　のに、｜＾働（はたら）｜かなければ　なりません。",
                normal: "きょうは　にちようひな　のに、働かなければ　なりません。",
                en: "It's Sunday today, but I have to work.",
                mm: "ဒီ​နေ့ တနင်္ဂ​နွေ​နေ့‌ပေမဲ့ အလုပ်​လုပ်​ရမယ်​။",
            },
        ],
        audioUrl: require("@assets/audio/lesson45/renshua/4N45RSA2.mp3")
    },
];