
export const lesson8 = [
    {"lesson":8,"level":4,"question":"せつぶん","represent":["節","分"],"answer":["涼風","節分","四季","冷夏"],"true":"節分","times":10},
    {"lesson":8,"level":4,"question":"ふゆ","represent":["冬"],"answer":["暑","秋","冬","春"],"true":"冬","times":10},
    {"lesson":8,"level":4,"question":"しき","represent":["四","季"],"answer":["暖房","季節","涼風","四季"],"true":"四季","times":10},
    {"lesson":8,"level":4,"question":"だんぼう","represent":["暖"],"answer":["暖冬","暖房","季節","涼風"],"true":"暖房","times":10},
    {"lesson":8,"level":4,"question":"はる","represent":["春"],"answer":["夏","冬","春","秋"],"true":"春","times":10},
    {"lesson":8,"level":4,"question":"あつ","represent":["暑"],"answer":["夏","冬","秋","暑"],"true":"暑","times":10},
    {"lesson":8,"level":4,"question":"だんとう","represent":["暖","冬"],"answer":["暖冬","四季","涼風","冷夏"],"true":"暖冬","times":10},
    {"lesson":8,"level":4,"question":"せいしゅん","represent":["青","春"],"answer":["晩秋","青春","暖冬","季節"],"true":"青春","times":10},
    {"lesson":8,"level":4,"question":"すずかぜ","represent":["涼","風"],"answer":["晩秋","節分","涼風","青春"],"true":"涼風","times":10},
    {"lesson":8,"level":4,"question":"あき","represent":["秋"],"answer":["秋","夏","暑","春"],"true":"秋","times":10},
    {"lesson":8,"level":4,"question":"ばんしゅう","represent":["晩","秋"],"answer":["晩秋","青春","節分","暖冬"],"true":"晩秋","times":10},
    {"lesson":8,"level":4,"question":"れいか","represent":["夏"],"answer":["冷夏","晩秋","季節","暖冬"],"true":"冷夏","times":10},
    {"lesson":8,"level":4,"question":"なつ","represent":["夏"],"answer":["秋","夏","冬","暑"],"true":"夏","times":10},
    {"lesson":8,"level":4,"question":"きせつ","represent":["季","節"],"answer":["青春","暖房","季節","冷夏"],"true":"季節","times":10},
]