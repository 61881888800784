export const bunkei= [
    {
        sentence: [
            {
                furi: '＾春（はる）｜に　なれば、　｜＾桜（さくら）｜が　｜＾咲（さ）｜きます。',
                normal: '春に　なれば、　桜が　咲きます。',
                en: 'When spring comes, the cherry blossoms will bloom.',
                mm: 'နွေဦးရာသီတွင် ချယ်ရီပန်းများ ပွင့်လိမ့်မည်။',
            }
        ],
        audioUrl: require("@assets/audio/lesson35/bunpou/4N35BK1.mp3")
    },
    {
        sentence: [
            {
                furi: '＾天（てん）気（き）｜が　よければ、｜＾向（む）｜こうに　｜＾島（しま）｜が　｜＾見（み）｜えます。',
                normal: '天気が　よければ、向こうに　島が　見えます。',
                en: 'If the weather is fine, you can see the island over there.',
                mm: 'ရာသီဥတုသာယာရင် ဟိုဘက်ကျွန်းကိုမြင်ရတယ်။',
            }
        ],
        audioUrl: require("@assets/audio/lesson35/bunpou/4N35BK2.mp3")
    },
    {
        sentence: [
            {
                furi: '＾北（ほっ）海（かい）道（どう）旅（りょ）行（こう）｜なら、６｜＾月（がつ）｜が　いいです。',
                normal: '北海道旅行なら、６月が　いいです。',
                en: 'June is a good time to travel to Hokkaido.',
                mm: 'ဇွန်လသည် ဟော့ကိုင်းဒိုးသို့ ခရီးသွားရန် အချိန်ကောင်းဖြစ်သည်။',
            }
        ],
        audioUrl: require("@assets/audio/lesson35/bunpou/4N35BK3.mp3")
    }
]