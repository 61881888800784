export const renshuuA = [
    {
        sentence: [
            {
                furi: "＾家（いえ）｜を　｜！かう｜　ために、｜＾一（いっ）生（しょう）懸（けん）命（めい）｜　｜＾働（はたら）｜きます。",
                normal: "家を　かう　ために、一生懸命　働きます。",
                en: "I work hard to buy a house.",
                mm: "အိမ်ဝယ်ဖို့ အလုပ်ကြိုးစားတယ်။",
            },
            {
                furi: "＾会（かい）社（しゃ）｜を　｜！つくる｜　ために、｜＾一（いっ）生（しょう）懸（けん）命（めい）｜　｜＾働（はたら）｜きます。",
                normal: "会社を　つくる　ために、一生懸命　働きます。",
                en: "I will work hard to create a company.",
                mm: "ကုမ္မဏီတစ်ခုဖန်တီးဖို့ ကျွန်တော်ကြိုးစားပါ့မယ်။",
            },
            {
                furi: "！しょうらいの｜　ために、｜＾一（いっ）生（しょう）懸（けん）命（めい）｜　｜＾働（はたら）｜きます。",
                normal: "しょうらいの　ために、一生懸命　働きます。",
                en: "I will work hard for the future.",
                mm: "အနာဂတ်အတွက် ငါကြိုးစားမယ်။",
            },
            {
                furi: "！かぞくの｜　ために、｜＾一（いっ）生（しょう）懸（けん）命（めい）｜　｜＾働（はたら）｜きます。",
                normal: "かぞくの　ために、一生懸命　働きます。",
                en: "I will work hard for my family.",
                mm: "ငါ့မိသားစုအတွက် ငါကြိုးစားမယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson42/renshua/4N42RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "この　かばんは　｜＾絵（え）｜を　｜！はこぶの｜に　使います。",
                normal: "この　かばんは　絵を　はこぶのに　使います。",
                en: "This bag is used to carry picture.",
                mm: "ဒီအိတ်ကို ပန်းချီကားသယ်ဖို့ သုံးတယ်။",
            },
            {
                furi: "この　かばんは　｜！しゅっちょう｜に　｜＾使（つか）｜います。",
                normal: "この　かばんは　しゅっちょうに　使います。",
                en: "I use this bag for business trips.",
                mm: "အလုပ်ကိစ္စအတွက် ဒီအိတ်ကို သုံးပါတယ်။",
            },
            {
                furi: "この　かばんは　パソコンを　｜！いれるのに｜　いいです。",
                normal: "この　かばんは　パソコンを　いれるのに　いいです。",
                en: "This bag is good for putting a computer in.",
                mm: "ဒီအိတ်လေးက ကွန်ပြူတာထည့်လို့ကောင်းတယ်။",
            },
            {
                furi: "この　かばんは｜！りょこう｜　に　いいです。",
                normal: "この　かばんは　りょこう　に　いいです。",
                en: "This bag is good for travel.",
                mm: "ဒီအိတ်က ခရီးသွားလို့ကောင်းတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson42/renshua/4N42RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾家（いえ）｜を　｜！たてるの｜　に　２０００｜＾万（まん）円（えん）｜　かかります。",
                normal: "家を　たてるの　に　２０００万円　かかります。",
                en: "It costs 20 million yen to build a house.",
                mm: "အိမ်တစ်လုံးဆောက်ဖို့ ယန်းသန်း ၂၀ ကုန်ကျတယ်။",
            },
            {
                furi: "！ひっこし｜　に　｜＾2（ふつ）日（か）｜　かかります。",
                normal: "ひっこし　に　2日　かかります。",
                en: "It will take two days to move.",
                mm: "ပြောင်းရွှေ့ရန် နှစ်ရက်ကြာမည်ဖြစ်သည်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson42/renshua/4N42RSA3.mp3")
    },
];