
export const lesson1 = [
    {"lesson":1,"level":4,"question":"村","represent":["村"],"answer":["し","く","ところ","むら"],"true":"むら","times":10},
    {"lesson":1,"level":4,"question":"住宅","represent":["住"],"answer":["けんちょう","じゅうたく","だいどころ","じゅうしょ"],"true":"じゅうたく","times":10},
    {"lesson":1,"level":4,"question":"県庁","represent":["県"],"answer":["きょうと","ちく","じゅうしょ","けんちょう"],"true":"けんちょう","times":10},
    {"lesson":1,"level":4,"question":"町長","represent":["町","長"],"answer":["とかい","のうそん","せいふ","ちょうちょう"],"true":"ちょうちょう","times":10},
    {"lesson":1,"level":4,"question":"市場","represent":["市","場"],"answer":["しじょう","だいどころ","きょうと","ちょうちょう"],"true":"しじょう","times":10},
    {"lesson":1,"level":4,"question":"農村","represent":["村"],"answer":["じゅうたく","のうそん","ちく","しみん"],"true":"のうそん","times":10},
    {"lesson":1,"level":4,"question":"下町","represent":["下","町"],"answer":["だいどころ","したまち","けんちょう","のうそん"],"true":"したまち","times":10},
    {"lesson":1,"level":4,"question":"市","represent":["市"],"answer":["く","まち","むら","し"],"true":"し","times":10},
    {"lesson":1,"level":4,"question":"区役所","represent":["区","所"],"answer":["けんきゅうじょ","くやくしょ","むらやくば","きょうとふ"],"true":"くやくしょ","times":10},
    {"lesson":1,"level":4,"question":"京都府","represent":["京","都","府"],"answer":["けんきゅうじょ","むらやくば","とうきょうと","きょうとふ"],"true":"きょうとふ","times":10},
    {"lesson":1,"level":4,"question":"研究所","represent":["研","究","所"],"answer":["もらやくば","おおさｋふ","けんきゅうじょ","とうきょうと"],"true":"けんきゅうじょ","times":10},
    {"lesson":1,"level":4,"question":"所","represent":["所"],"answer":["し","く","ところ","むら"],"true":"ところ","times":10},
    {"lesson":1,"level":4,"question":"地区","represent":["地","区"],"answer":["きょうと","ちく","せいふ","とかい"],"true":"ちく","times":10},
    {"lesson":1,"level":4,"question":"大阪府","represent":["大","府"],"answer":["とうきょうと","おおさかふ","きょうとふ","むらやくば"],"true":"おおさかふ","times":10},
    {"lesson":1,"level":4,"question":"東京都","represent":["東","京","都"],"answer":["きょうとふ","くやくしょ","けんきゅうじょ","とうきょうと"],"true":"とうきょうと","times":10},
    {"lesson":1,"level":4,"question":"区","represent":["区"],"answer":["く","むら","とこる","し"],"true":"く","times":10},
    {"lesson":1,"level":4,"question":"村役場","represent":["村","場"],"answer":["きょうとふ","けんきゅうじょ","むらやくば","おおさかふ"],"true":"むらやくば","times":10},
    {"lesson":1,"level":4,"question":"政府","represent":["府"],"answer":["しちょう","じゅうみん","ちく","せいふ"],"true":"せいふ","times":10},
    {"lesson":1,"level":4,"question":"町","represent":["町"],"answer":["く","ところ","し","まち"],"true":"まち","times":10},
    {"lesson":1,"level":4,"question":"都会","represent":["都","会"],"answer":["ちょうちょう","きょうと","とかい","けんちょう"],"true":"とかい","times":10},
    {"lesson":1,"level":4,"question":"京都","represent":["都","京"],"answer":["きょうと","じゅうしょ","ちょうちょう","とかい"],"true":"きょうと","times":10},
    {"lesson":1,"level":4,"question":"住民","represent":["住","民"],"answer":["しみん","じゅうみん","のうそん","せいふ"],"true":"じゅうみん","times":10},
    {"lesson":1,"level":4,"question":"台所","represent":["台","所"],"answer":["ちく","だいどころ","しじょう","せいふ"],"true":"だいどころ","times":10},
    {"lesson":1,"level":4,"question":"市長","represent":["市","長"],"answer":["ちく","しちょう","しみん","とうきょう"],"true":"しちょう","times":10},
    {"lesson":1,"level":4,"question":"東京","represent":["東","京"],"answer":["とうきょう","しじょう","しちょう","ちょうちょう"],"true":"とうきょう","times":10},
    {"lesson":1,"level":4,"question":"市民","represent":["市","民"],"answer":["しじょう","とかい","じゅうしょ","しみん"],"true":"しみん","times":10},
    {"lesson":1,"level":4,"question":"住所","represent":["住","所"],"answer":["ちょうちょう","じゅうしょ","けんちょう","しじょう"],"true":"じゅうしょ","times":10},
]