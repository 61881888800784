import Score from "@assets/icon/Champion.svg";
import Home from "@assets/icon/home-icon 1.svg";
import Setting from "@assets/icon/setting 1.svg";
import Routes, { AboutRoutes, ContactRoutes, HomeRoutes, SettingWebRoutes, TestRoutes } from "@routes/Routes";
import { memo } from "react";
import { FaHome } from 'react-icons/fa';
import DesktopViewComponent from "./DesktopViewComponent";
import MobileHeaderComponent from "./MobileHeaderComponent";
export const navItems = [
    {
        icon: Home,
        label: 'Home',
        path: Routes.HOME,
        children: HomeRoutes
    },
    {
        icon: Score,
        label: 'Test',
        path: Routes.TEST,
        children: TestRoutes
    },
    {
        icon: FaHome,
        label: 'About',
        path: Routes.ABOUT,
        children: AboutRoutes
    }, {
        icon: FaHome,
        label: 'Contact',
        path: Routes.CONTACT,
        children: ContactRoutes
    },
    {
        icon: Setting,
        label: 'Setting',
        path: Routes.SETTING,
        children: SettingWebRoutes
    },
]
const NavbarComponent = ({
    root = true,
    appBar = { title: '', centerTitle: true, leadingIcon: false, leadingRoute: -1 },
    useNavigate, Logger, currentRoute,setShowModel
}: any) => {

    return (
        <>
            {/* Desktop View */}
            <DesktopViewComponent
                navItems={navItems}
                Routes={Routes}
                useNavigate={useNavigate}
                Logger={Logger}
                currentRoute={currentRoute}
                setShowModel={setShowModel}
            />

            {/* Mobile View  */}
            {
                <MobileHeaderComponent root={root} {...appBar} setShowModel={setShowModel}/>
            }
        </>
    )
}
export default memo(NavbarComponent);