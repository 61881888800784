export const renshuuA = [
     {
        sentence: [
            {
                furi: "メールを　｜！よんで｜、｜＾安（あん）心（しん）｜しました。",
                normal: "メールを　よんで、安心しました。",
                en: "After reading the email, I felt relieved.",
                mm: "အီးမေးလ်ကိုဖတ်ပြီး စိတ်သက်သာရာရသွားတယ်။",
             },
            {
                furi: "＾電（でん）話（わ）｜を　｜！もらって｜、｜＾安（あん）心（しん）｜しました。",
                normal: "電話を　もらって、安心しました。",
                en: "I was relieved when I received the phone call.",
                mm: "ဖုန်းဆက်လိုက်တာနဲ့ စိတ်သက်သာရာ ရသွားတယ်။",
             },
            {
                furi: "＾家（か）族（ぞく）｜に　｜！あえなくて｜、｜＾寂（さび）｜しです。",
                normal: "家族に　あえなくて、寂しです。",
                en: "I miss my family because I can't see them.",
                mm: "မိသားစုကို မတွေ့ရလို့ လွမ်းတယ်။",
             },
            {
                furi: "＾友（とも）達（だち）｜が　いなくて、　｜＾寂（さび）｜しです。",
                normal: "友達が　いなくて、　寂しです。",
                en: "I don't have any friends and I'm lonely.",
                mm: "ငါ့မှာ သူငယ်ချင်းမရှိလို့ အထီးကျန်တယ်။",
             },
            {
                furi: "＾問（もん）題（だい）｜が　｜！むずかしくて｜、　わかりません。",
                normal: "問題が　むずかしくて、　わかりません。",
                en: "The problem is so difficult that I don't understand.",
                mm: "ပြဿနာက ခက်တော့ နားမလည်ဘူး။",
             },
            {
                furi: "＾使（つか）｜い｜＾方（かた）｜が　｜！ふくざつで｜、　わかりません。",
                normal: "使い方が　ふくざつで、　わかりません。",
                en: "I don't understand how to use it.",
                mm: "ဘယ်လိုသုံးရမလဲဆိုတာတော့ မသိဘူး။",
             },
        ],
         audioUrl: require("@assets/audio/lesson39/renshua/4N39RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "！じしん｜で　｜＾人（ひと）｜が　｜＾大（おお）勢（ぜい）｜　｜＾死（し）｜にました。",
                normal: "じしんで　人が　大勢　死にました。",
                en: "Many people died in the earthquake.",
                mm: "ငလျင်ကြောင့် လူအများအပြား သေဆုံးခဲ့ပါတယ်။",
            },
            {
                furi: "！つなみ｜で　｜＾人（ひと）｜が　｜＾大（おお）勢（ぜい）｜　｜＾死（し）｜にました。",
                normal: "つなみで　人が　大勢　死にました。",
                en: "Many people died in the tsunami.",
                mm: "ဆူနာမီကြောင့် လူအများအပြား သေဆုံးခဲ့ရသည်။",
             },
        ],
         audioUrl: require("@assets/audio/lesson39/renshua/4N39RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾病（びょう）院（いん）｜へ　｜！いく｜　ので、　５｜＾時（じ）｜に　｜＾帰（かえ）｜っても　いいですか。",
                normal: "病院へ　いく　ので、　５時に　帰っても　いいですか。",
                en: "I'm going to the hospital, so can I go home at 5 o'clock?",
                mm: "ငါဆေးရုံသွားရမှာဆိုတော့ ညနေ ၅ နာရီမှာ အိမ်ပြန်လို့ရမလား",
            },
            {
                furi: "＾日（に）本（ほん）語（ご）｜が　｜！わからない｜　ので、　｜＾英（えい）語（ご）｜で　｜＾話（はな）｜して　いただけませんか。",
                normal: "日本語が　わからない　ので、　英語で　話して　いただけませんか。",
                en: "I don't understand Japanese, so could you speak in English?",
                mm: "ဂျပန်စာကို နားမလည်လို့ အင်္ဂလိပ်လို ပြောလို့ရလား။",
            },
            {
                furi: "バズが　｜！おくれた｜　ので、　｜＾約（やく）束（そく）｜に　｜＾間（ま）｜に　｜＾合（あ）｜いませんでした。",
                normal: "バズが　おくれた　ので、　約束に　間に　合いませんでした。",
                en: "The bus was late, so I missed my appointment.",
                mm: "ဘတ်စ်ကားက နောက်ကျတဲ့အတွက် ချိန်းထားတာ လွတ်သွားတယ်။",
            },
            {
                furi: "＾新（しん）聞（ぶん）｜を　｜！よまなかった｜　ので、　｜＾事（じ）故（こ）｜の　ことを　｜＾知（し）｜りませんでした。",
                normal: "新聞を　よまなかった　ので、　事故の　ことを　知りませんでした。",
                en: "I didn't read the newspaper, so I didn't know about the accident.",
                mm: "ကျွန်တော် သတင်းစာ မဖတ်ခဲ့ဘူးဆိုတော့ မတော်တဆမှုအကြောင်းကို မသိပါဘူး။",
            },
            {
                furi: "＾毎（まい）日（にち）｜　｜！いそがしい｜　ので、　どこも　｜＾遊（あそ）｜びに　｜＾行（い）｜けません。",
                normal: "毎日　いそがしい　ので、　どこも　遊びに　行けません。",
                en: "I'm busy every day, so I can't go anywhere.",
                mm: "နေ့တိုင်း အလုပ်ရှုပ်နေတော့ ဘယ်မှ မသွားရဘူး။",
            },
            {
                furi: "きょうは　｜！ひまな｜　ので、　｜＾買（か）｜い｜＾物（もの）｜に　｜＾行（い）｜きます。",
                normal: "きょうは　ひまな　ので、　買い物に　行きます。",
                en: "I'm free today, so I'm going shopping",
                mm: "ဒီ​နေ့ အားလို့ ​ဈေးဝယ်​ထွက်​မယ်​",
            },
            {
                furi: "あしたは　｜！やすみな｜ので　うちで　ゆっくり　｜＾休（やす）｜みます。",
                normal: "あしたは　やすみなので　うちで　ゆっくり　休みます。",
                en: "Tomorrow is a holiday, so I will rest at home.",
                mm: "မနက်ဖြန် အားလပ်ရက်မို့ အိမ်မှာ အနားယူမယ်။",
            },
        ],
         audioUrl: require("@assets/audio/lesson39/renshua/4N39RSA3.mp3")
    },
];