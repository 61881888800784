import SizeBox from "@components/SizeBox";
import SubNavComponent from "@components/common/SubNavComponent";
import Icon from "@consts/icon";
import { Card } from "@material-tailwind/react";
import { memo } from "react";
import CountUp from 'react-countup';

const KanjiListWebComponent = ({ score, lesson,nabs, kanjiLessons, kanjiDetail, startGame ,getKanji}: any) => {
    
    return (
        <div className="hidden md:block">
            {/* Sub Nav */}
            <SubNavComponent nabs={nabs} lesson={lesson} />
            <SizeBox vertical="h-[40px]" />
            {/* <ResponsiveView /> */}
            { getKanji().map((list:Array<Object>) => {
                return <div key={Math.random()} className="flex flex-row justify-between items-center">
                    {
                        list.map(({id,kanji}:any) => {
                            return <div key={Math.random()} className="relative btn mb-[30px] lg:mb-[50px]">
                                <Card onClick={() => kanjiDetail(id)} className="flex flex-row justify-center items-center w-[100px] h-[100px] lg:w-[120px] lg:h-[120px] text-navbar dark:text-[#D4D4D4] dark:bg-[#001E34] text-[45px] lg:text-[60px] font-[500px] kanji-card-shadow dark-kanji-card-shadow rounded-[15px]" >{kanji}</Card>
                                <div className="absolute flex flex-row justify-center items-center top-[13px] right-0 h-[27px] w-[39px] rounded-l-[10px] bg-[#379AE5] text-white text-[22px]">{id+1}</div>
                            </div>
                        }) 
                    }
                </div>})
            }
            <div className="border-2 border-b-[#C8C8C8] dark:border-b-[#4F4F4F]"></div>
            <SizeBox vertical="h-[20px]"/>
            <div className="flex flex-row justify-center font-[500px] lg:text-[22px] text-[#424242] dark:text-[#D4D4D4]" >QUIZ GAME</div>
            <SizeBox vertical="h-[40px]" />
            <div className="flex flex-row justify-evenly">
                <div onClick={() => startGame({ kanji2hira: true })} className="btn">
                    <img src={Icon.Quizz1} width={380} className="rounded-t-[20px] card-light-shadow dark:bg-[#001E34] " />
                    <div className=" bg-[#AA58F5] h-[55px] border-t-[1px] border-[#C5C5C5] rounded-b-[20px] card-light-shadow">
                        <div className="flex flex-row items-center p-5 justify-between">
                            {/* Left */}
                            <div className="flex-1 flex flex-row items-center text-[14px] font-semibold text-white">
                                <div className="">漢字</div>
                                <div className="mx-3"><img src={Icon.Arrow} /></div>
                                <div>ひらがな</div>
                            </div>
                            {/* Right */}
                            <div className="flex-1 text-[14px] text-white">
                                <div className="flex flex-row justify-end ">
                                    <CountUp
                                        start={0}
                                        end={score.kanji}
                                        duration={3}
                                    />  
                                    {/* <div>{score}</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={() => startGame({ kanji2hira: false })} className="btn">
                    <img src={Icon.Quizz2} width={380} className="rounded-t-[20px] card-light-shadow dark:bg-[#001E34]" />
                    <div className=" bg-[#AA58F5] h-[55px] border-t-[1px] border-[#C5C5C5] rounded-b-[20px] card-light-shadow">
                        <div className="flex flex-row p-5 items-center justify-between">
                            {/* Left */}
                            <div className="flex-1 flex flex-row items-center text-[14px] font-semibold text-white">
                                <div className="">ひらがな</div>
                                <div className="mx-3"><img src={Icon.Arrow} /></div>
                                <div>漢字</div>
                            </div>
                            {/* Right */}
                            <div className="flex-1 text-[14px] text-white">
                                <div className="flex flex-row justify-end ">
                                    <div className="mr-3">Your Scored</div>
                                    <CountUp
                                        start={0}
                                        end={score.hira}
                                        duration={3}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SizeBox vertical="h-[20px]" />


        </div>
    );
}

export default memo(KanjiListWebComponent);