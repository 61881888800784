
export const lesson6 =[
    {"lesson":6,"level":4,"question":"産地","represent":["産","地"],"answer":["しつない","さんち","さんぎょう","すいぎん"],"true":"さんち","times":10},
    {"lesson":6,"level":4,"question":"水銀","represent":["水","銀"],"answer":["しょくじ","すいぎん","しごと","がくしゃ"],"true":"すいぎん","times":10},
    {"lesson":6,"level":4,"question":"食事","represent":["食","事"],"answer":["しょくじ","かいいん","こうぎょう","じこ"],"true":"しょくじ","times":10},
    {"lesson":6,"level":4,"question":"医院","represent":["医","院"],"answer":["じこ","いしゃ","いいん","しごと"],"true":"いいん","times":10},
    {"lesson":6,"level":4,"question":"医者","represent":["医","者"],"answer":["ぎんこう","さんぎょう","きょうしつ","いしゃ"],"true":"いしゃ","times":10},
    {"lesson":6,"level":4,"question":"教室","represent":["教","室"],"answer":["かいいん","ぎんこう","きょうしつ","がくしゃ"],"true":"きょうしつ","times":10},
    {"lesson":6,"level":4,"question":"医学","represent":["医","学"],"answer":["しょくじ","こうぎょう","ようじ","いがく"],"true":"いがく","times":10},
    {"lesson":6,"level":4,"question":"仕方","represent":["仕","方"],"answer":["きょうしつ","いがく","さんぎょう","しかた"],"true":"しかた","times":10},
    {"lesson":6,"level":4,"question":"銀座","represent":["銀"],"answer":["ぎんざ","がくしゃ","のうぎょう","しょうぎょう"],"true":"ぎんざ","times":10},
    {"lesson":6,"level":4,"question":"銀行","represent":["銀","行"],"answer":["ぎんこう","ていいん","がくしゃ","かいいん"],"true":"ぎんこう","times":10},
    {"lesson":6,"level":4,"question":"用事","represent":["用","事"],"answer":["しょうぎょう","さんぎょう","いいん","ようじ"],"true":"ようじ","times":10},
    {"lesson":6,"level":4,"question":"工業","represent":["工","業"],"answer":["てんいん","さんぎょう","すいぎん","こうぎょう"],"true":"こうぎょう","times":10},
    {"lesson":6,"level":4,"question":"店員","represent":["店","員"],"answer":["のうぎょう","かいいん","てんいん","いしゃ"],"true":"てんいん","times":10},
    {"lesson":6,"level":4,"question":"仕事","represent":["仕","事"],"answer":["てんいん","じこ","ぎんざ","しごと"],"true":"しごと","times":10},
    {"lesson":6,"level":4,"question":"商業","represent":["業"],"answer":["さんぎょう","いがく","しょうぎょう","ていいん"],"true":"しょうぎょう","times":10},
    {"lesson":6,"level":4,"question":"会員","represent":["会","員"],"answer":["かいいん","しょうぎょう","きょうしつ","じこ"],"true":"かいいん","times":10},
    {"lesson":6,"level":4,"question":"産業","represent":["産","業"],"answer":["さんぎょう","のうぎょう","ようじ","ぎんざ"],"true":"さんぎょう","times":10},
    {"lesson":6,"level":4,"question":"定員","represent":["員"],"answer":["のうぎょう","ていいん","しょくじ","しかた"],"true":"ていいん","times":10},
    {"lesson":6,"level":4,"question":"学者","represent":["学","者"],"answer":["がくしゃ","いいん","さんち","ぎんこう"],"true":"がくしゃ","times":10},
    {"lesson":6,"level":4,"question":"事故","represent":["事"],"answer":["かいいん","さんち","さんぎょう","じこ"],"true":"じこ","times":10},
    {"lesson":6,"level":4,"question":"農業","represent":["業"],"answer":["のうぎょう","じこ","こうぎょう","かいいん"],"true":"のうぎょう","times":10},
    {"lesson":6,"level":4,"question":"室内","represent":["室","内"],"answer":["てんいん","いしゃ","しつない","いがく"],"true":"しつない","times":10},
]