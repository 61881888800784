export const bunkei= [
    {
        sentence: [
            {
                furi: '＾交（こう）｜＾番（ばん）｜に　｜＾町（まち）｜の｜＾地（ち）｜＾図（ず）｜が　はって　おります。',
                normal: '交番に　町の　地図が　はって　おります。',
                en: 'There is a map of the town on the police box.',
                mm: 'ရဲစခန်းမှာ မြို့ရဲ့မြေပုံ ကပ်ထားတယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson30/bunpou/4N30BK1.mp3")
    },
    {
        sentence: [
            {
                furi: '＾旅（りょ）｜＾行（こう）｜の　まえに、インターネットで　いろいろ　｜＾調（しら）｜べて　おきます。',
                normal: '旅行の　まえに、インターネットで　いろいろ　調べて　おきます。',
                en: 'Before the trip, I will search various things on the Internet.',
                mm: 'ခရီးမထွက်ခင် အမျိုးမျိုးသော အကြောင်းအရာတွေကို အင်တာနက်မှာ ရှာ‌ဖွေလေ့လာထားမယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson30/bunpou/4N30BK2.mp3")
    },
];

/*

{
    furi: "",
    normal: "",
    en: "",
    mm: "",
},

*/