import { lesson1 as hk_lesson1 } from './hira2kanji/lesson1';
import { lesson10 as hk_lesson10 } from './hira2kanji/lesson10';
import { lesson11 as hk_lesson11 } from './hira2kanji/lesson11';
import { lesson12 as hk_lesson12 } from './hira2kanji/lesson12';
import { lesson13 as hk_lesson13 } from './hira2kanji/lesson13';
import { lesson14 as hk_lesson14 } from './hira2kanji/lesson14';
import { lesson15 as hk_lesson15 } from './hira2kanji/lesson15';
import { lesson16 as hk_lesson16 } from './hira2kanji/lesson16';
import { lesson17 as hk_lesson17 } from './hira2kanji/lesson17';
import { lesson18 as hk_lesson18 } from './hira2kanji/lesson18';
import { lesson19 as hk_lesson19 } from './hira2kanji/lesson19';
import { lesson2 as hk_lesson2 } from './hira2kanji/lesson2';
import { lesson20 as hk_lesson20 } from './hira2kanji/lesson20';
import { lesson3 as hk_lesson3 } from './hira2kanji/lesson3';
import { lesson4 as hk_lesson4 } from './hira2kanji/lesson4';
import { lesson5 as hk_lesson5 } from './hira2kanji/lesson5';
import { lesson6 as hk_lesson6 } from './hira2kanji/lesson6';
import { lesson7 as hk_lesson7 } from './hira2kanji/lesson7';
import { lesson8 as hk_lesson8 } from './hira2kanji/lesson8';
import { lesson9 as hk_lesson9 } from './hira2kanji/lesson9';

import { lesson1 as kh_lesson1 } from './kanji2hira/lesson1';
import { lesson10 as kh_lesson10 } from './kanji2hira/lesson10';
import { lesson11 as kh_lesson11 } from './kanji2hira/lesson11';
import { lesson12 as kh_lesson12 } from './kanji2hira/lesson12';
import { lesson13 as kh_lesson13 } from './kanji2hira/lesson13';
import { lesson14 as kh_lesson14 } from './kanji2hira/lesson14';
import { lesson15 as kh_lesson15 } from './kanji2hira/lesson15';
import { lesson16 as kh_lesson16 } from './kanji2hira/lesson16';
import { lesson17 as kh_lesson17 } from './kanji2hira/lesson17';
import { lesson18 as kh_lesson18 } from './kanji2hira/lesson18';
import { lesson19 as kh_lesson19 } from './kanji2hira/lesson19';
import { lesson2 as kh_lesson2 } from './kanji2hira/lesson2';
import { lesson20 as kh_lesson20 } from './kanji2hira/lesson20';
import { lesson3 as kh_lesson3 } from './kanji2hira/lesson3';
import { lesson4 as kh_lesson4 } from './kanji2hira/lesson4';
import { lesson5 as kh_lesson5 } from './kanji2hira/lesson5';
import { lesson6 as kh_lesson6 } from './kanji2hira/lesson6';
import { lesson7 as kh_lesson7 } from './kanji2hira/lesson7';
import { lesson8 as kh_lesson8 } from './kanji2hira/lesson8';
import { lesson9 as kh_lesson9 } from './kanji2hira/lesson9';


export const games = {
    hira2kanji: [
        hk_lesson1,hk_lesson2,hk_lesson3,hk_lesson4,hk_lesson5,
        hk_lesson6,hk_lesson7,hk_lesson8,hk_lesson9,hk_lesson10,
        hk_lesson11,hk_lesson12,hk_lesson13,hk_lesson14,hk_lesson15,
        hk_lesson16,hk_lesson17,hk_lesson18,hk_lesson19,hk_lesson20
    ],
    kanji2hira: [
        kh_lesson1,kh_lesson2,kh_lesson3,kh_lesson4,kh_lesson5,
        kh_lesson6,kh_lesson7,kh_lesson8,kh_lesson9,kh_lesson10,
        kh_lesson11,kh_lesson12,kh_lesson13,kh_lesson14,kh_lesson15,
        kh_lesson16,kh_lesson17,kh_lesson18,kh_lesson19,kh_lesson20
    ]
}