export const bunkei= [
    {
        sentence: [
            {
                furi: "＾今（いま）｜にも　｜＾雨（あめ）｜が　｜＾降（ふ）｜りそうです。",
                normal: "今にも　雨が　降りそうです。",
                en: "It looks like it's going to rain now.",
                mm: "အခု မိုးရွာတော့မယ်ပုံရတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson43/bunpou/4N43BK1.mp3")
    },
    {
        sentence: [
            {
                furi: "ちょっと　｜＾切（きっ）符（ぷ）｜を　｜＾買（か）｜って　｜＾来（き）｜ます。",
                normal: "ちょっと　切符を　買って　来ます。",
                en: "I'll go to buy a ticket.",
                mm: "လက်မှတ်သွားဝယ်လိုက်မယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson43/bunpou/4N43BK2.mp3")
    },
]