export const lesson38 = [
  {
      "furi": "＾参（さん）加（か）｜します",
      "normal": "参加します",
      "en": "participate",
      "remark_en": "",
      "mm": "ပါဝင်ဆင်နွှဲသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO1.mp3")
  },
  {
      "furi": "＾育（そだ）｜てます",
      "normal": "育てます",
      "en": "breed, bring up",
      "remark_en": "",
      "mm": "ပြုစုပျိုးထောင်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO2.mp3")
  },
  {
      "furi": "＾運（はこ）｜びます",
      "normal": "運びます",
      "en": "carry, transport",
      "remark_en": "",
      "mm": "သယ်ဆောင်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO3.mp3")
  },
  {
      "furi": "＾入（にゅう）院（いん）｜します",
      "normal": "入院します",
      "en": "enter hospital",
      "remark_en": "",
      "mm": "ဆေးရုံတက်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO4.mp3")
  },
  {
      "furi": "＾退（たい）院（いん）｜します",
      "normal": "退院します",
      "en": "leave hospital",
      "remark_en": "",
      "mm": "ဆေးရုံဆင်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO5.mp3")
  },
  {
      "furi": "＾入（い）｜れます",
      "normal": "入れます",
      "en": "turn on",
      "remark_en": "",
      "mm": "ဖွင့်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO6.mp3")
  },
  {
      "furi": "＾切（き）｜ります",
      "normal": "切ります",
      "en": "turn off",
      "remark_en": "",
      "mm": "ဖြတ်တောက်သည်၊ ပိတ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO7.mp3")
  },
  {
      "furi": "＾掛（か）｜けます",
      "normal": "掛けます",
      "en": "lock",
      "remark_en": "",
      "mm": "ချိတ်သည်၊ ခတ်သည်(သော့)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO8.mp3")
  },
  {
      "furi": "つきます",
      "normal": "つきます",
      "en": "Attached",
      "remark_en": "",
      "mm": "တွဲပါသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO9.mp3")
  },
  {
      "furi": "＾気（き）持（も）｜ちがいい",
      "normal": "気持ちがいい",
      "en": "pleasant, agreeable",
      "remark_en": "",
      "mm": "စိတ်ကြည်လင်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO10.mp3")
  },
  {
      "furi": "＾気（き）持（も）｜ちが｜＾悪（わる）｜い",
      "normal": "気持ちが悪い",
      "en": "unpleasant, disgusting",
      "remark_en": "",
      "mm": "စိတ်မကြည်မလင်ဖြစ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO11.mp3")
  },
  {
      "furi": "＾大（おお）｜きな～",
      "normal": "大きな～",
      "en": "large ~",
      "remark_en": "",
      "mm": "ကြီးမားသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO12.mp3")
  },
  {
      "furi": "＾小（ちい）｜さな～",
      "normal": "小さな～",
      "en": "small ~",
      "remark_en": "",
      "mm": "သေးငယ်သော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO13.mp3")
  },
  {
      "furi": "＾赤（あか）｜ちゃん",
      "normal": "赤ちゃん",
      "en": "baby",
      "remark_en": "",
      "mm": "မွေးကင်စကလေး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO14.mp3")
  },
  {
      "furi": "＾小（しょう）学（がっ）校（こう）",
      "normal": "小学校",
      "en": "elementary school",
      "remark_en": "",
      "mm": "မူလတန်းကျောင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO15.mp3")
  },
  {
      "furi": "＾中（ちゅう）学（がっ）校（こう）",
      "normal": "中学校",
      "en": "junior high school",
      "remark_en": "",
      "mm": "အလယ်တန်းကျောင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO16.mp3")
  },
  {
      "furi": "＾駅（えき）前（まえ）",
      "normal": "駅前",
      "en": "front of the station",
      "remark_en": "",
      "mm": "ဘူတာရှေ့",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO17.mp3")
  },
  {
      "furi": "＾海（かい）岸（がん）",
      "normal": "海岸",
      "en": "seaside, seashore",
      "remark_en": "",
      "mm": "ပင်လယ်ကမ်းစပ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO18.mp3")
  },
  {
      "furi": "＾工（こう）場（じょう）",
      "normal": "工場",
      "en": "factory",
      "remark_en": "",
      "mm": "စက်ရုံ၊အလုပ်ရုံ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO19.mp3")
  },
  {
      "furi": "＾村（むら）",
      "normal": "村",
      "en": "village",
      "remark_en": "",
      "mm": "ရွာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO20.mp3")
  },
  {
      "furi": "かな",
      "normal": "かな",
      "en": "hiragana and karakana",
      "remark_en": "",
      "mm": "ဟီရဂန နှင့် ခတခန",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO21.mp3")
  },
  {
      "furi": "＾指（ゆ）輪（びわ）",
      "normal": "指輪",
      "en": "ring",
      "remark_en": "",
      "mm": "လက်စွပ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO22.mp3")
  },
  {
      "furi": "＾電（でん）源（げん）",
      "normal": "電源",
      "en": "Power supply",
      "remark_en": "",
      "mm": "မီးခလုတ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO23.mp3")
  },
  {
      "furi": "＾習（しゅう）慣（かん）",
      "normal": "習慣",
      "en": "Habits",
      "remark_en": "",
      "mm": "အလေ့အကျင့်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO24.mp3")
  },
  {
      "furi": "＾健（けん）康（こう）",
      "normal": "健康",
      "en": "health",
      "remark_en": "",
      "mm": "ကျန်းမာရေး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO25.mp3")
  },
  {
      "furi": "～｜＾製（せい）",
      "normal": "～製",
      "en": "made in ~",
      "remark_en": "",
      "mm": "～ထုတ်၊～ထွက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO26.mp3")
  },
  {
      "furi": "おととし",
      "normal": "おととし",
      "en": "Year before last year",
      "remark_en": "",
      "mm": "တနှစ်(က)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO27.mp3")
  },
  {
      "furi": "[あ、]いけない",
      "normal": "[あ、]いけない",
      "en": "Oops!/ Oh, no!",
      "remark_en": "",
      "mm": "(အမလေး) မဖြစ်(သင့်)ဘူး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO28.mp3")
  },
  {
      "furi": "お｜＾先（さき）｜に[｜＾失（しつ）｜＾礼（れい）｜します]。",
      "normal": "お先に[失礼します]。",
      "en": "Excuse me (for leaving before you)",
      "remark_en": "",
      "mm": "အရင်(ပြန်နှင့်မယ်/လုပ်နှင့်မယ်)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO29.mp3")
  },
  {
      "furi": "＾原（げん）爆（ばく）｜ドーム",
      "normal": "原爆ドーム",
      "en": "dome commemorating the atomic bombing of Hiroshima",
      "remark_en": "",
      "mm": "အဏုမြူအဆောက်အဦ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO30.mp3")
  },
  {
      "furi": "＾出（い）雲（ずも）大（たい）社（しゃ）",
      "normal": "出雲大社",
      "en": "Izumo Taisha",
      "remark_en": "",
      "mm": "အိဇုမိုတိုင်းရှ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO31.mp3")
  },
  {
      "furi": "チェインマイ",
      "normal": "チェインマイ",
      "en": "Chiang Mai",
      "remark_en": "",
      "mm": "ချင်းမိုင်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO32.mp3")
  },
  {
      "furi": "＾回（かい）覧（らん）",
      "normal": "回覧",
      "en": "circular, sending around",
      "remark_en": "",
      "mm": "လက်ဆင့်ကမ်းနှိုးဆော်စာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO33.mp3")
  },
  {
      "furi": "＾研（けん）究（きゅう）者（しゃ）",
      "normal": "研究者",
      "en": "study room, laboratory",
      "remark_en": "",
      "mm": "သုတေသနခန်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO34.mp3")
  },
  {
      "furi": "きちんと",
      "normal": "きちんと",
      "en": "neatly, tidily",
      "remark_en": "",
      "mm": "သေသေချာချာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO35.mp3")
  },
  {
      "furi": "＾整（せい）理（り）｜します",
      "normal": "整理します",
      "en": "put (things) in order, tidy up",
      "remark_en": "",
      "mm": "စီစီရီရီဖြစ်အောင်လုပ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO36.mp3")
  },
  {
      "furi": "＾方（ほう）法（ほう）",
      "normal": "方法",
      "en": "way",
      "remark_en": "",
      "mm": "နည်းလမ်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO37.mp3")
  },
  {
      "furi": "～と　いう",
      "normal": "～と　いう",
      "en": "~call",
      "remark_en": "",
      "mm": "～လို့ခေါ်တဲ့",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO38.mp3")
  },
  {
      "furi": "ー｜＾冊（さつ）",
      "normal": "ー冊",
      "en": "counter for books",
      "remark_en": "",
      "mm": "-အုပ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO39.mp3")
  },
  {
      "furi": "はんこ",
      "normal": "はんこ",
      "en": "seal stamp",
      "remark_en": "",
      "mm": "တံဆိပ်တုံး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO40.mp3")
  },
  {
      "furi": "＾押（お）｜します",
      "normal": "押します",
      "en": "affix (a seal)",
      "remark_en": "",
      "mm": "နှိပ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson38/vocab/4N38VO41.mp3")
  },
  {
      "furi": "＾双（ふた）子（ご）",
      "normal": "双子",
      "en": "twins",
      "remark_en": "",
      "mm": "အမွှာပူး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾姉（し）妹（まい）",
      "normal": "姉妹",
      "en": "sisters",
      "remark_en": "",
      "mm": "ညီအစ်မ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾５（ご）年（ねん）生（せい）",
      "normal": "５年生",
      "en": "fifth grade, fifth year",
      "remark_en": "",
      "mm": "၅တန်းကျောင်းသား/သူ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾似（に）｜ています",
      "normal": "似ています",
      "en": "resemble, be like",
      "remark_en": "",
      "mm": "ဆင်တူသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾性（せい）格（かく）",
      "normal": "性格",
      "en": "character",
      "remark_en": "",
      "mm": "အကျင့်စရိုက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "おとなしい",
      "normal": "おとなしい",
      "en": "quiet",
      "remark_en": "",
      "mm": "လူကြီးဆန်သော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾優（やさ）｜しい",
      "normal": "優しい",
      "en": "kind",
      "remark_en": "",
      "mm": "သဘောကောင်းသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾世（せ）話（わ）｜をします",
      "normal": "世話をします",
      "en": "take care",
      "remark_en": "",
      "mm": "ဝေယာဝစ္စကိုပြုလုပ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾時（じ）間（かん）｜がたちます",
      "normal": "時間がたちます",
      "en": "time pass by",
      "remark_en": "",
      "mm": "အချိန် ကြာသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾大（だい）好（す）｜き[な]",
      "normal": "大好き[な]",
      "en": "like very much",
      "remark_en": "",
      "mm": "အလွန်နှစ်သက်သော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "ー｜＾点（てん）｜",
      "normal": "ー点",
      "en": "~ points",
      "remark_en": "",
      "mm": "-အချက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾気（き）｜が｜＾強（つよ）｜い",
      "normal": "気が強い",
      "en": "aggressive",
      "remark_en": "",
      "mm": "စိတ်ဓါတ်ကြံ့ခိုင်သော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "けんがします",
      "normal": "けんがします",
      "en": "quarrel, fight",
      "remark_en": "",
      "mm": "ရန်ဖြစ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾不（ふ）思（し）議（ぎ）｜[な]",
      "normal": "不思議[な]",
      "en": "mysterious, strange",
      "remark_en": "",
      "mm": "ဆန်းကြယ်သော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾年（ねん）齢（れい）",
      "normal": "年齢",
      "en": "age",
      "remark_en": "",
      "mm": "အသက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾仕（し）方（かた）",
      "normal": "仕方",
      "en": "How to do",
      "remark_en": "",
      "mm": "လုပ်နည်းကိုင်နည်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  }
]

/*
    {
        furi: "",
        normal: "",
        en: "",
        remark_en: "",
        mm: "",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson29/vocab/4N29VO1.mp3")
    },


*/
const test = [
  {
    'goi': '参（さん）」加（か）」します',
    'unicode': 'ပါဝင်ဆင်နွှဲသည်',
    'zawgyi': 'ပါဝင္ဆင္ႏႊဲသည္',
    'english': 'participate',
    'long': false
  },
  {
    'goi': '育（そだ）」てます',
    'unicode': 'ပြုစုပျိုးထောင်သည်',
    'zawgyi': 'ျပဳစုပ်ိဳးေထာင္သည္',
    'english': 'breed, bring up',
    'long': false
  },
  {
    'goi': '運（はこ）」びます',
    'unicode': 'သယ်ဆောင်သည်',
    'zawgyi': 'သယ္ေဆာင္သည္',
    'english': 'carry, transport',
    'long': false
  },
  {
    'goi': '入（にゅう）」院（いん）」します',
    'unicode': 'ဆေးရုံတက်သည်',
    'zawgyi': 'ေဆး႐ုံတက္သည္',
    'english': 'enter hospital',
    'long': false
  },
  {
    'goi': '退（たい）」院（いん）」します',
    'unicode': 'ဆေးရုံဆင်းသည်',
    'zawgyi': 'ေဆး႐ုံဆင္းသည္',
    'english': 'leave hospital',
    'long': false
  },
  {
    'goi': '入（い）」れます',
    'unicode': 'ဖွင့်သည်',
    'zawgyi': 'ဖြင့္သည္',
    'english': 'turn on',
    'long': false
  },
  {
    'goi': '切（き）」ります',
    'unicode': 'ဖြတ်တောက်သည်၊ ပိတ်သည်',
    'zawgyi': 'ျဖတ္ေတာက္သည္၊ ပိတ္သည္',
    'english': 'turn off',
    'long': false
  },
  {
    'goi': '掛（か）」けます',
    'unicode': 'ချိတ်သည်၊ ခတ်သည်(သော့)',
    'zawgyi': 'ခ်ိတ္သည္၊ ခတ္သည္(ေသာ့)',
    'english': 'lock',
    'long': false
  },
  {
    'goi': 'つきます',
    'unicode': 'တွဲပါသည်',
    'zawgyi': 'တြဲပါသည္',
    'english': 'Attached',
    'long': false
  },
  {
    'goi': '気（き）」持（も）」ちがいい',
    'unicode': 'စိတ်ကြည်လင်သည်',
    'zawgyi': 'စိတ္ၾကည္လင္သည္',
    'english': 'pleasant, agreeable',
    'long': false
  },
  {
    'goi': '気（き）」持（も）」ちが」悪（わる）」い',
    'unicode': 'စိတ်မကြည်မလင်ဖြစ်သည်',
    'zawgyi': 'စိတ္မၾကည္မလင္ျဖစ္သည္',
    'english': 'unpleasant, disgusting',
    'long': false
  },
  {
    'goi': '大（おお）」きな～',
    'unicode': 'ကြီးမားသော',
    'zawgyi': 'ႀကီးမားေသာ',
    'english': 'large ~',
    'long': false
  },
  {
    'goi': '小（ちい）」さな～',
    'unicode': 'သေးငယ်သော',
    'zawgyi': 'ေသးငယ္ေသာ',
    'english': 'small ~',
    'long': false
  },
  {
    'goi': '赤（あか）」ちゃん',
    'unicode': 'မွေးကင်စကလေး',
    'zawgyi': 'ေမြးကင္စကေလး',
    'english': 'baby',
    'long': false
  },
  {
    'goi': '小（しょう）」学（がっ）」校（こう）',
    'unicode': 'မူလတန်းကျောင်း',
    'zawgyi': 'မူလတန္းေက်ာင္း',
    'english': 'elementary school',
    'long': false
  },
  {
    'goi': '中（ちゅう）」学（がっ）」校（こう）',
    'unicode': 'အလယ်တန်းကျောင်း',
    'zawgyi': 'အလယ္တန္းေက်ာင္း',
    'english': 'junior high school',
    'long': false
  },
  {
    'goi': '駅（えき）」前（まえ）',
    'unicode': 'ဘူတာရှေ့',
    'zawgyi': 'ဘူတာေရွ႕',
    'english': 'front of the station',
    'long': false
  },
  {
    'goi': '海（かい）」岸（がん）',
    'unicode': 'ပင်လယ်ကမ်းစပ်',
    'zawgyi': 'ပင္လယ္ကမ္းစပ္',
    'english': 'seaside, seashore',
    'long': false
  },
  {
    'goi': '工（こう）」場（じょう）',
    'unicode': 'စက်ရုံ၊အလုပ်ရုံ',
    'zawgyi': 'စက္႐ုံ၊အလုပ္႐ုံ',
    'english': 'factory',
    'long': false
  },
  {
    'goi': '村（むら）',
    'unicode': 'ရွာ',
    'zawgyi': 'ရြာ',
    'english': 'village',
    'long': false
  },
  {
    'goi': 'かな',
    'unicode': 'ဟီရဂန နှင့် ခတခန',
    'zawgyi': 'ဟီရဂန ႏွင့္ ခတခန',
    'english': 'hiragana and karakana',
    'long': false
  },
  {
    'goi': '指（ゆ）」輪（びわ）',
    'unicode': 'လက်စွပ်',
    'zawgyi': 'လက္စြပ္',
    'english': 'ring',
    'long': false
  },
  {
    'goi': '電（でん）」源（げん）',
    'unicode': 'မီးခလုတ်',
    'zawgyi': 'မီးခလုတ္',
    'english': 'Power supply',
    'long': false
  },
  {
    'goi': '習（しゅう）」慣（かん）',
    'unicode': 'အလေ့အကျင့်',
    'zawgyi': 'အေလ့အက်င့္',
    'english': 'Habits',
    'long': false
  },
  {
    'goi': '健（けん）」康（こう）',
    'unicode': 'ကျန်းမာရေး',
    'zawgyi': 'က်န္းမာေရး',
    'english': 'health',
    'long': false
  },
  {
    'goi': '～」製（せい）',
    'unicode': '～ထုတ်၊～ထွက်',
    'zawgyi': '～ထုတ္၊～ထြက္',
    'english': 'made in ~',
    'long': false
  },
  {
    'goi': 'おととし',
    'unicode': 'တနှစ်(က)',
    'zawgyi': 'တႏွစ္(က)',
    'english': 'Year before last year',
    'long': false
  },
  {
    'goi': '[あ、]いけない',
    'unicode': '(အမလေး) မဖြစ်(သင့်)ဘူး',
    'zawgyi': '(အမေလး) မျဖစ္(သင့္)ဘူး',
    'english': 'Oops!/ Oh, no!',
    'long': false
  },
  {
    'goi': 'お」先（さき）」に[」失（しつ）」礼（れい）」します]。',
    'unicode': 'အရင်(ပြန်နှင့်မယ်/လုပ်နှင့်မယ်)',
    'zawgyi': 'အရင္(ျပန္ႏွင့္မယ္/လုပ္ႏွင့္မယ္)',
    'english': 'Excuse me (for leaving before you)',
    'long': true
  },
  {
    'goi': '原（げん）」爆（ばく）」ドーム',
    'unicode': 'အဏုမြူအဆောက်အဦ',
    'zawgyi': 'အဏုျမဴအေဆာက္အဦ',
    'english': 'dome commemorating the atomic bombing of Hiroshima',
    'long': true
  },
  {
    'goi': '出（い）」雲（ずも）」大（たい）」社（しゃ）',
    'unicode': 'အိဇုမိုတိုင်းရှ',
    'zawgyi': 'အိဖုမိုတိုင္းရွ',
    'english': 'Izumo Taisha',
    'long': false
  },
  {
    'goi': 'チェインマイ',
    'unicode': 'ချင်းမိုင်',
    'zawgyi': 'ခ်င္းမိုင္',
    'english': 'Chiang Mai',
    'long': false
  },
  {
    'goi': '回（かい）」覧（らん）',
    'unicode': 'လက်ဆင့်ကမ်းနှိုးဆော်စာ',
    'zawgyi': 'လက္ဆင့္ကမ္းႏွိုးေဆာ္စာ',
    'english': 'circular, sending around',
    'long': false
  },
  {
    'goi': '研（けん）」究（きゅう）」者（しゃ）',
    'unicode': 'သုတေသနခန်း',
    'zawgyi': 'သုေတသနခန္း',
    'english': 'study room, laboratory',
    'long': false
  },
  {
    'goi': 'きちんと',
    'unicode': 'သေသေချာချာ',
    'zawgyi': 'ေသေသခ်ာခ်ာ',
    'english': 'neatly, tidily',
    'long': false
  },
  {
    'goi': '整（せい）」理（り）」します',
    'unicode': 'စီစီရီရီဖြစ်အောင်လုပ်သည်',
    'zawgyi': 'စီစီရီရီျဖစ္ေအာင္လုပ္သည္',
    'english': 'put (things) in order, tidy up',
    'long': false
  },
  {
    'goi': '方（ほう）」法（ほう）',
    'unicode': 'နည်းလမ်း',
    'zawgyi': 'နည္းလမ္း',
    'english': 'way',
    'long': false
  },
  {
    'goi': '～と　いう',
    'unicode': '～လို့ခေါ်တဲ့',
    'zawgyi': '～လို႔ေခၚတဲ့',
    'english': '~call',
    'long': false
  },
  {
    'goi': 'ー」冊（さつ）',
    'unicode': '-အုပ်',
    'zawgyi': '-အုပ္',
    'english': 'counter for books',
    'long': false
  },
  {
    'goi': 'はんこ',
    'unicode': 'တံဆိပ်တုံး',
    'zawgyi': 'တံဆိပ္တုံး',
    'english': 'seal stamp',
    'long': false
  },
  {
    'goi': '押（お）」します',
    'unicode': 'နှိပ်သည်',
    'zawgyi': 'ႏွိပ္သည္',
    'english': 'affix (a seal)',
    'long': false
  },
  {
    'goi': '双（ふた）」子（ご）',
    'unicode': 'အမွှာပူး',
    'zawgyi': 'အမႊာပူး',
    'english': 'twins',
    'long': false
  },
  {
    'goi': '姉（し）」妹（まい）',
    'unicode': 'ညီအစ်မ',
    'zawgyi': 'ညီအစ္မ',
    'english': 'sisters',
    'long': false
  },
  {
    'goi': '５（ご）」年（ねん）」生（せい）',
    'unicode': '၅တန်းကျောင်းသား/သူ',
    'zawgyi': '၅တန္းေက်ာင္းသား/သူ',
    'english': 'fifth grade, fifth year',
    'long': false
  },
  {
    'goi': '似（に）」ています',
    'unicode': 'ဆင်တူသည်',
    'zawgyi': 'ဆင္တူသည္',
    'english': 'resemble, be like',
    'long': false
  },
  {
    'goi': '性（せい）」格（かく）',
    'unicode': 'အကျင့်စရိုက်',
    'zawgyi': 'အက်င့္စရိုက္',
    'english': 'character',
    'long': false
  },
  {
    'goi': 'おとなしい',
    'unicode': 'လူကြီးဆန်သော',
    'zawgyi': 'လူႀကီးဆန္ေသာ',
    'english': 'quiet',
    'long': false
  },
  {
    'goi': '優（やさ）」しい',
    'unicode': 'သဘောကောင်းသော',
    'zawgyi': 'သေဘာေကာင္းေသာ',
    'english': 'kind',
    'long': false
  },
  {
    'goi': '世（せ）」話（わ）」をします',
    'unicode': 'ဝေယာဝစ္စကိုပြုလုပ်သည်',
    'zawgyi': 'ေဝယာဝစၥကိုျပဳလုပ္သည္',
    'english': 'take care',
    'long': false
  },
  {
    'goi': '時（じ）」間（かん）」がたちます',
    'unicode': 'အချိန် ကြာသည်',
    'zawgyi': 'အခ်ိန္ ၾကာသည္',
    'english': 'time pass by',
    'long': false
  },
  {
    'goi': '大（だい）」好（す）」き[な]',
    'unicode': 'အလွန်နှစ်သက်သော',
    'zawgyi': 'အလြန္ႏွစ္သက္ေသာ',
    'english': 'like very much',
    'long': false
  },
  {
    'goi': 'ー」点（てん）」',
    'unicode': '-အချက်',
    'zawgyi': '-အခ်က္',
    'english': '~ points',
    'long': false
  },
  {
    'goi': '気（き）」が」強（つよ）」い',
    'unicode': 'စိတ်ဓါတ်ကြံ့ခိုင်သော',
    'zawgyi': 'စိတ္ဓါတ္ႀကံ့ခိုင္ေသာ',
    'english': 'aggressive',
    'long': false
  },
  {
    'goi': 'けんがします',
    'unicode': 'ရန်ဖြစ်သည်',
    'zawgyi': 'ရန္ျဖစ္သည္',
    'english': 'quarrel, fight',
    'long': false
  },
  {
    'goi': '不（ふ）」思（し）」議（ぎ）」[な]',
    'unicode': 'ဆန်းကြယ်သော',
    'zawgyi': 'ဆန္းၾကယ္ေသာ',
    'english': 'mysterious, strange',
    'long': false
  },
  {
    'goi': '年（ねん）」齢（れい）',
    'unicode': 'အသက်',
    'zawgyi': 'အသက္',
    'english': 'age',
    'long': false
  },
  {
    'goi': '仕（し）」方（かた）',
    'unicode': 'လုပ်နည်းကိုင်နည်း',
    'zawgyi': 'လုပ္နည္းကိုင္နည္း',
    'english': 'How to do',
    'long': false
  }
]