export const bunkei= [
    {
        sentence: [
            {
                furi: '＾毎（まい）日（にち）｜　｜＾運（うん）動（どう）｜した　｜！ほうが｜　いいです。',
                normal: '毎日　運動した　ほうが　いいです。',
                en: 'You should exercise every day.',
                mm: 'နေ့တိုင်း လေ့ကျင့်ခန်းလုပ်သင့်တယ်။',
            }
        ],
        audioUrl: require("@assets/audio/lesson32/bunpou/4N32BK1.mp3")
    },
    {
        sentence: [
            {
                furi: 'あしたは　｜＾雪（ゆき）｜が　｜＾降（ふ）｜る｜！でしょう｜。',
                normal: 'あしたは　雪が　降るでしょう。',
                en: 'It will snow tomorrow.',
                mm: 'မနက်ဖြန် နှင်းကျလိမ့်မယ်။',
            }
        ],
        audioUrl: require("@assets/audio/lesson32/bunpou/4N32BK2.mp3")
    },
    {
        sentence: [
            {
                furi: '＾約（やく）束（そく）｜の　｜＾時（じ）間（かん）｜に　｜＾間（ま）｜に　｜＾合（あ）｜わない｜！かもしれません｜。',
                normal: '約束の　時間に　間に　合わない　かもしれません。',
                en: 'I may not be on time for the promised time.',
                mm: 'ချိန်းဆိုထားတဲ့အချိန်အတိုင်း မမီနိုင်ပါဘူး။',
            }
        ],
        audioUrl: require("@assets/audio/lesson32/bunpou/4N32BK3.mp3")
    },
]