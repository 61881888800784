export const lesson4 = {
    '低': 30,
    '広': 31,
    '短': 32,
    '良': 33,
    '悪': 34,
    '正': 35,
    '変': 36,
    '赤': 37,
    '青': 38,
    '黒': 39
};