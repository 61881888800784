export const reibun= [
    {
        sentence: [
            {
                furi: "＾会（かい）議（ぎ）室（しつ）｜の　かぎが　｜＾掛（か）｜かって　｜！いますね｜。",
                normal: "会議室の　かぎが　掛かって　いますね。",
                en: "The conference room is locked.",
                mm: "အစည်းအဝေးအခန်းရဲ့က သော့ခတ်ထားတယ်။",
            },
            {
                furi: "。。。じゃ、｜＾渡（わた）辺（なべ）｜さんに　｜＾言（い）｜って、｜＾開（あ）｜けて　もらいましょう。",
                normal: "。。。じゃ、渡辺さんに　言って、開けて　もらいましょう。",
                en: ". . . Then tell Watanabe-san to open it and let him get it.",
                mm: ". . . ဒါဆို Watanabe-san ကိုဖွင့်ခိုင်းရအောင်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson29/bunpou/4N29RB1.mp3")
    },
    {
        sentence: [
            {
                furi: "この　パソコン、　｜＾使（つか）｜っても　いいですか。",
                normal: "この　パソコン、　使っても　いいですか。",
                en: "Can I use this computer?",
                mm: "ဒီကွန်ပြူတာကို သုံးလို့ရမလား။",
            },
            {
                furi: "。。。それは　｜！故（こ）障（しょう）して｜　いますから、あちらのを　｜＾使（つか）｜って　ください。",
                normal: "。。。それは　故障して　いますから、あちらのを　使って　ください。",
                en: ". . . It's broken and he's there, so use that one for him.",
                mm: "...အဲ့ဒါက ပျက်နေလို့ ဟိုးကဟာကို သုံးပေးပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson29/bunpou/4N29RB2.mp3")
    },
    {
        sentence: [
            {
                furi: "シュミットさんが　｜＾持（も）｜って　｜＾来（き）｜た　ワインは　どこですか。",
                normal: "シュミットさんが　持って　来た　ワインは　どこですか。",
                en: "Where is the wine Mr.Schmidt brought?",
                mm: "မစ္စတာရှမစ် ယူဆောင်လာတဲ့ဝိုင်က ဘယ်မှာလဲ။",
            },
            {
                furi: "。。。みんなで　｜＾飲（の）｜んで　｜！しまいました｜。",
                normal: "。。。みんなで　飲んで　しまいました。",
                en: ". . . Everyone drank it and she put it away.",
                mm: ". . . အားလုံးစုပြီး သောက်လိုက်ပါပြီ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson29/bunpou/4N29RB3.mp3")
    },
    {
        sentence: [
            {
                furi: "いっしょに　｜＾帰（かえ）｜りませんか。",
                normal: "いっしょに　帰りませんか。",
                en: "Would you like to go home with me?",
                mm: "အတူတူ အိမ်ပြန်ကြစို့။",
            },
            {
                furi: "。。。すみません。この　メールを　｜！書（か）いて　しまいます｜から、　お｜＾先（さき）｜に　どうぞ。",
                normal: "。。。すみません。この　メールを　書いて　しまいますから、　お先に　どうぞ。",
                en: ". . . excuse me. I will write this email for her and put it away, so please send him first.",
                mm: "... စိတ်မရှိပါနဲ့၊ ဒီမေးလ်လေးကို ပြီးအောင်ရေးရမှာမို့လို့ အရင်ပြန်နှင့်ပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson29/bunpou/4N29RB4.mp3")
    },
    {
        sentence: [
            {
                furi: "＾約（やく）束（そく）｜の　｜＾時（じ）間（かん）｜に　｜＾間（ま）｜に　｜＾会（あ）｜いましたか。",
                normal: "約束の　時間に　間に　会いましたか。",
                en: "Did you meet in time for the appointment?",
                mm: "ချိန်းဆိုမှုအတွက် အချိန်မီတွေ့ဆုံခဲ့ပါသလား။",
            },
            {
                furi: "。。。いいえ、｜！遅（おく）れて　しまいました｜。｜＾道（みち）｜を　まちがえたんです。",
                normal: "。。。いいえ、遅れて　しまいました。道を　まちがえたんです。",
                en: ". . . No, she was late. She made a mistake on the way.",
                mm: "... ဟင့်အင်း၊ နောက်ကျသွားခဲ့ပါတယ်။ လမ်းမှားသွားခဲ့လို့ပါ။",
            },
            
        ],
        audioUrl: require("@assets/audio/lesson29/bunpou/4N29RB5.mp3")
    },
    {
        sentence: [
            {
                furi: "どう　したんですか。",
                normal: "どう　したんですか。",
                en: "What happened?",
                mm: "ဘာဖြစ်တာလဲ။",
            },
            {
                furi: "。。。タクシーに　｜＾荷（に）物（もつ）｜を　｜！忘れて　しまったんです｜。",
                normal: "。。。タクシーに　荷物を　忘れて　しまったんです。",
                en: ". . . He forgot his luggage in the taxi and put it away.",
                mm: ". . . တက္ကစီမှာ ခရီးဆောင်အိတ်မေ့သွားတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson29/bunpou/4N29RB6.mp3")
    },
];

/*

{
    furi: "",
    normal: "",
    en: "",
    mm: "",
},

*/