import { memo } from "react";

const TitleComponent = ({ furigana, furiTranslate, furi, fontSize, normal, tran, showTranslate,kanjiDetail }:any) => {
    return <div key={Math.random()}> 
        {/* Web */}
        <div className={`hidden md:flex flex-col justify-center font-bold text-${fontSize + 6}px `}>
            {
                showTranslate ? tran
                    :
                    (furigana === 'true' ? furiTranslate({ context: furi, fontSize: fontSize + 6, style: "flex-wrap items-center", kanjiDetail }) : normal)
            }
        </div>
        {/* mobile */}
        <div className={`md:hidden flex flex-col justify-center items-center font-bold `}>
            {
                showTranslate ? tran
                    :
                    (furigana === 'true' ? furiTranslate({ context: furi, fontSize, style: "flex-wrap items-center", kanjiDetail }) : normal)
            }
        </div>

    </div>
}
export default memo(TitleComponent); 