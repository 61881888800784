import Icon from "@consts/icon";
import { memo, useEffect, useRef, useState } from "react";

const AudioPlayer = ({ audioUrl }: { audioUrl: string }) => {
    const [play, setPlay] = useState(false);
    const audio = useRef(new Audio(audioUrl))
    // let audio = new Audio(audioUrl);
    const sleep = (ms: number) => new Promise((r, _) => setInterval(r, ms));
    const playAction: any = async () => {
        setPlay(true);
        audio.current.play();
    }
    const stopAction = () => {
        audio.current.currentTime = 0;
        audio.current.pause();
        setPlay(false);
    }
    useEffect(() => {
        audio.current.addEventListener("ended", () => {
            setPlay(false);
        });
        return () => {
            audio.current.removeEventListener("ended", () => {
            });
        }
    })
    return (<div className="btn">
        {/* {
            play?
            <HiStop onClick={stopAction} size={25} className="text-red-500 btn"/> :
            <HiPlay onClick={playAction} size={25} className="text-yellow-500 btn"/>
        } */}
        {
            play ?
                <img src={Icon.Speaker} onClick={stopAction} />
                // <Play onClick={stopAction} size={25} className="text-blue-800 dark:text-green-300 btn" /> :
                // <Stop onClick={playAction} size={25} className="btn" />
                : <img src={Icon.Speaker} onClick={playAction} />
            
        }
    </div>);
}

export default memo(AudioPlayer)