export const reibun= [
    {
        sentence: [
            {
                furi: '＾疲（つか）｜れたね。ちょっと　｜＾休（やす）｜まない？',
                normal: '疲れたね。ちょっと　休まない？',
                en: "Tired, aren't you. Why don't you take a break?",
                mm: 'ပင်ပန်း‌ပြီ မဟုတ်လား။ အနားမယူဘူးလား။',
            },
            {
                furi: '。。。うん、そう　｜！しよう｜。',
                normal: '。。。うん、そう　しよう。',
                en: ". . . Yeah, let's do it.",
                mm: '. . . ဟုတ်တယ်၊ နားရအောင်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson31/bunpou/4N31RB1.mp3")
    },
    {
        sentence: [
            {
                furi: 'お｜＾正（しょう）月（がつ）｜は　｜＾何（なに）｜を　しますか。',
                normal: 'お正月は　何を　しますか。',
                en: "What are you doing for New Year's?",
                mm: 'နှစ်သစ်ကူး‌‌‌တွေမှာ ဘာလုပ်နေလဲ။',
            },
            {
                furi: '。。。｜＾家（か）族（ぞく）｜と　｜＾温（おん）泉（せん）｜に｜！行（い）こう｜と　｜＾思（おも）｜って　います。',
                normal: '。。。家族と　温泉に　行こうと　思って　います。',
                en: '. . . I am thinking of going to a hot spring with my family.',
                mm: '. . . မိသားစုနဲ့ ရေပူစမ်းသွားဖို့ စိတ်ကူးထားတယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson31/bunpou/4N31RB2.mp3")
    },
    {
        sentence: [
            {
                furi: 'レポートは　もう　できましたか。',
                normal: 'レポートは　もう　できましたか。',
                en: 'Have you finished the report yet?',
                mm: 'အစီရင်ခံစာပြီးပြီလား။',
            },
            {
                furi: '。。。いいえ、まだ　｜！書（か）いて　いません｜。',
                normal: '。。。いいえ、まだ　書いて　いません。',
                en: '. . . No, I am not writing yet.',
                mm: '. . . ဟင့်အင်း၊ ငါမရေးရသေးဘူး။',
            },
            {
                furi: '＾金（きん）曜（よう）日（び）｜までに　まとめようと｜！思（おも）っています｜。',
                normal: '金曜日までに　まとめようと　思って　います。',
                en: "I'm thinking of finishing it on Friday.",
                mm: 'သောကြာနေ့မှာ ပြီးအောင်လုပ်ဖို့ စဉ်းစားနေတယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson31/bunpou/4N31RB3.mp3")
    },
    {
        sentence: [
            {
                furi: '＾国（くに）｜へ　｜＾帰（かえ）｜っても、｜＾日（に）本（ほん）語（ご）｜の　｜＾勉（べん）強（きょう）｜を　｜＾続（つづ）｜けますか。',
                normal: '国へ　帰っても、日本語の　勉強を　続けますか。',
                en: 'Will you continue studying Japanese even after you return to your country?',
                mm: 'မင်းနိုင်ငံကိုပြန်ရောက်ပြီးတောင် ဂျပန်စာကို ဆက်လေ့လာမှာလား။',
            },
            {
                furi: 'はい、｜＾続（つづ）｜ける｜！つもりです｜。',
                normal: 'はい、続ける　つもりです。',
                en: 'Yes, I plan to continue.',
                mm: 'ဟုတ်ကဲ့ ဆက်လုပ်ဖို့ စီစဉ်ထားပါတယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson31/bunpou/4N31RB4.mp3")
    },
    {
        sentence: [
            {
                furi: '＾夏（なつ）休（やす）｜みは　｜＾国（くに）｜へ　｜＾帰（かえ）｜らないです。',
                normal: '夏休みは　国へ　帰らないです。',
                en: 'I will not go back to my country for summer vacation.',
                mm: 'နွေရာသီ အားလပ်ရက်မှာ ကိုယ့်နိုင်ငံကို မပြန်သွားဘူး။',
            },
            {
                furi: 'ええ。｜＾大（だい）学（がく）院（いん）｜の｜＾試（し）験（けん）｜を｜＾受（う）｜けますから、ことしは｜＾帰（かえ）｜らない　つもりです。',
                normal: 'ええ。大学院の試験を受けますから、ことしは帰らない　つもりです。',
                en: "yes. I'm going to take a graduate school exam, so I'm not going to go home this year.",
                mm: 'ဟုတ်တယ် ဘွဲ့လွန်စာမေးပွဲ ဖြေရတော့မှာမို့ ဒီနှစ် အိမ်မပြန်ဘူး။',
            },
        ],
        audioUrl: require("@assets/audio/lesson31/bunpou/4N31RB5.mp3")
    },
    {
        sentence: [
            {
                furi: 'あしたから　ニューヨークへ　｜＾出（しゅっ）張（ちょう）｜します。',
                normal: 'あしたから　ニューヨークへ　出張します。',
                en: 'I will be on a business trip to New York tomorrow.',
                mm: 'မနက်ဖြန် နယူးယောက်ကို အလုပ်ကိစ္စနဲ့ ခရီးထွက်မယ်။',
            },
            {
                furi: 'そうですか。いつ　｜＾帰（かえ）｜りますか。',
                normal: 'そうですか。いつ　帰りますか。',
                en: 'Is that so. When are you coming back.',
                mm: 'ဟုတ်လား။ မင်းဘယ်တော့ပြန်လာမှာလဲ။',
            },
            {
                furi: '＾来（らい）週（しゅう）｜の｜＾金（きん）曜（よう）日（び）｜に｜！帰（かえ）る（　）予（よ）定（てい）です｜。',
                normal: '来週の金曜日に　帰る　予定です。',
                en: 'I plan to return next Friday.',
                mm: 'လာမယ့်သောကြာနေ့ ပြန်လာဖို့ စီစဉ်ထားတယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson31/bunpou/4N31RB6.mp3")
    },
]

/*
{
    furi: '',
    normal: '',
    en: '',
    mm: '',
},
*/