export const reibun= [
    {
        sentence: [
            {
                furi: "この　サッカー｜＾教（きょう）室（しつ）｜は　｜＾練（れん）習（しゅう）｜が｜＾寂（きび）｜しいそうですね。",
                normal: "この　サッカー教室は　練習が　寂しいそうですね。",
                en: "It seems that this soccer school is lonely.",
                mm: "ဒီဘောလုံးသင်တန်းကျောင်းရဲ့ ဘောလုံးလေ့ကျင့်မှုတွေက တင်းကြပ်မဲ့ပုံပဲ။",
            },
            {
                furi: "ええ、毎日　子どもたちを　１キロ　走らせて　います。",
                normal: "ええ、毎日　子どもたちを　１キロ　走らせて　います。",
                en: "Yes, I make children run 1km every day.",
                mm: "ဟုတ်တယ်၊ ကလေးတွေကို နေ့တိုင်း ၁ ကီလိုမီတာ ပြေးခိုင်းတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson48/bunpou/4N48RB1.mp3")
    },
    {
        sentence: [
            {
                furi: "そろそろ　｜＾失（しつ）礼（れい）｜します。",
                normal: "そろそろ　失礼します。",
                en: "I'll be leaving soon.",
                mm: "သွားခွင့်ပြုပါဦး။",
            },
            {
                furi: "あ、ちょっと　｜＾待（ま）｜って　ください。",
                normal: "あ、ちょっと　待って　ください。",
                en: "Oh, please wait a minute.",
                mm: "အိုး၊ ခဏစောင့်ပါ။",
            },
            {
                furi: "＾息（むす）子（こ）｜に　｜＾駅（えき）｜まで　｜＾送（おく）｜らせますから。",
                normal: "息子に　駅まで　送らせますから。",
                en: "I will have my son take you to the station.",
                mm: "သားကို ဘူတာရုံထိ လိုက်ပို့ခိုင်းမယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson48/bunpou/4N48RB2.mp3")
    },
    {
        sentence: [
            {
                furi: "パンス｜＾君（くん）｜は　｜＾学（がっ）校（こう）｜の　｜＾勉（べん）強（きょう）｜の　ほかに、｜＾何（なに）｜か　｜＾習（なら）｜って　いますか。",
                normal: "パンス君は　学校の　勉強の　ほかに、何か　習って　いますか。",
                en: "Pansu, are you learning anything besides schoolwork?",
                mm: "Pansuက ကျောင်းစာ သင်ယူတာအပြင် တခြားဘာတွေလုပ်သေးလဲ။",
            },
            {
                furi: "ええ、｜＾柔（じゅう）道（どう）｜を　したいと　｜＾言（い）｜ったので、｜＾柔（じゅう）道（どう）教（きょう）室（しつ）｜に　｜＾行（い）｜かせて　います。",
                normal: "ええ、柔道を　したいと　言ったので、柔道教室に　行かせて　います。",
                en: "Yes, He said he wanted to do judo, so I sent him to a judo class.",
                mm: "ဟုတ်တယ်၊ ဂျူဒို လုပ်ချင်တယ်လို့ ပြောလို့ သူ့ကို ဂျူဒိုသင်တန်းကို ပို့လိုက်တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson48/bunpou/4N48RB3.mp3")
    },
    {
        sentence: [
            {
                furi: "＾伊（い）藤（どう）先（せん）生（せい）｜は　どんな　｜＾先（せん）生（せい）｜ですか。",
                normal: "伊藤先生は　どんな　先生ですか。",
                en: "What kind of teacher is Mr. Ito?",
                mm: "Mr. Ito က ဘယ်လိုဆရာလဲ။",
            },
            {
                furi: "いい　｜＾先（せん）生（せい）｜ですよ。｜＾生（せい）徒（と）｜に　｜＾好（す）｜きな　｜＾本（ほん）｜を　｜＾読（よ）｜ませて、｜＾自（じ）由（ゆう）｜に　｜＾意（い）見（けん）｜を　｜＾言（い）｜わせるんです。",
                normal: "いい　先生ですよ。生徒に　好きな　本を　読ませて、自由に　意見を　言わせるんです。",
                en: "Good teacher. He lets the students read their favorite books and express their opinions freely.",
                mm: "ဆရာကောင်းတစ်ယောက်ပါ။ ကျောင်းသား‌တွေကို မိမိတို့နှစ်သက်ရာ စာအုပ်ကို ဖတ်ရှုပြီး သူတို့ရဲ့ ထင်မြင်ချက်ကို လွတ်လပ်စွာ ထုတ်ဖော်ခွင့်ပေးပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson48/bunpou/4N48RB4.mp3")
    },
    {
        sentence: [
            {
                furi: "すみません。しばらく　ここに　｜＾車（くるま）｜を　｜＾止（と）｜めさせて　いただけませんか。",
                normal: "すみません。しばらく　ここに　車を　止めさせて　いただけませんか。",
                en: "sorry. Would you mind letting me park the car here for a while?",
                mm: "တဆိတ်လောက်ပါ ခဏ ဒီနေရာမှာ ကားရပ်ခွင့်လေး ပေးနိုင်မလား။",
            },
            {
                furi: "いいですよ。",
                normal: "いいですよ。",
                en: "Yes",
                mm: "ရပါတယ်",
            },
        ],
        audioUrl: require("@assets/audio/lesson48/bunpou/4N48RB5.mp3")
    },
]