import { MojiType } from "..";

export const moji: MojiType = {
  mondai1:{
    title: 'もんだい１＿＿＿＿の　ことばは　ひらがなで　どう　かきますか。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 1,
        question: 'きょうは　とても　｜！楽しかった｜　ですね。',
        answers: ['うれしかった','いそがしかった','はずかしかった','たのしかった'],
        pick: '',
        correct: 'たのしかった'
      },
      {
        number: 2,
        question: 'たなかさんは　いつから　｜！習って｜いるんですか。',
        answers: ['かよって','まって','ならって','のこって'],
        pick: '',
        correct: 'ならって'
      },
      {
        number: 3,
        question: 'この　パソコンは　｜！軽い｜ですね。',
        answers: ['かるい','おもい','はやい','おそい'],
        pick: '',
        correct: 'かるい'
      },
      {
        number: 4,
        question: 'この　スーパーは　｜！食料品｜が　安いです。',
        answers: ['しょくりゅうひん','しょくりょうひん','しゅくりょうひん','しゅくりゅうひん'],
        pick: '',
        correct: 'しょくりょうひん'
      },
      {
        number: 5,
        question: '｜！顔｜に　何か　ついていますよ。',
        answers: ['くつ','あたま','ふく','かお'],
        pick: '',
        correct: 'かお'
      },
      {
        number: 6,
        question: 'けさは　天気｜！予報｜を　見ませんでした。',
        answers: ['よほう','ようほう','ようぼう','よぼう'],
        pick: '',
        correct: 'よほう'
      },
      {
        number: 7,
        question: 'やまもとさん、｜！動かないで｜　ください。',
        answers: ['あるかないで','うごかないで','なかないで','はたらかないで'],
        pick: '',
        correct: 'うごかないで'
      },
      {
        number: 8,
        question: 'うちでは　子どもが　犬の｜！世話｜をします。',
        answers: ['せいわ','せわ','せはなし','せいはなし'],
        pick: '',
        correct: 'せわ'
      },
      {
        number: 9,
        question: 'もうすぐ　｜！特急｜電車が　来ます。',
        answers: ['とっきゅ','ときゅう','とっきゅう','ときゅ'],
        pick: '',
        correct: 'とっきゅう'
      },
    ]
  },
  mondai2:{
    title: 'もんだい２＿＿＿＿の　ことばは　どう　かきますか。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 10,
        question: 'その　｜！えいが｜は　おもしろかったですよ。',
        answers: ['映面','央画','映画','央面'],
        pick: '',
        correct: '映画'
      },
      {
        number: 11,
        question: '魚が　たくさん｜！およいで｜います。',
        answers: ['海いで','泳いで','港いで','注いで'],
        pick: '',
        correct: '泳いで'
      },
      {
        number: 12,
        question: 'おもちゃ｜！うりば｜は　どこですか。',
        answers: ['売り場','売り所','完り場','完り所'],
        pick: '',
        correct: '売り場'
      },
      {
        number: 13,
        question: 'コンサートは　4時に｜！おわります｜。',
        answers: ['関わります','始わります','閉わります','終わります'],
        pick: '',
        correct: '終わります'
      },
      {
        number: 14,
        question: 'この　あたりに｜！ほんや｜は　ありますか。',
        answers: ['本室','本店','本屋','本家'],
        pick: '',
        correct: '本屋'
      },
      {
        number: 15,
        question: '｜！かかり｜の　人に　聞いてみましょう。',
        answers: ['糸','係り','員り','損り'],
        pick: '',
        correct: '係り'
      },
    ]
  },
  mondai3:{
    title: 'もんだい３（　　）に　なにを　いれますか。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 16,
        question: '二つめの　えきで　電車から　バスに　（　　）ください。',
        answers: ['おりて','のりかえて','ひっこして','でて'],
        pick: '',
        correct: 'のりかえて'
      },
      {
        number: 17,
        question: 'あの　レストランの　（　　）には、英語の　せつめいも　書いてあります。',
        answers: ['サービス','チップ','アルバイト','メニュー'],
        pick: '',
        correct: 'メニュー'
      },
      {
        number: 18,
        question: '先月　大学を　（　　）して、今は　日本で　しごとをしています。',
        answers: ['ふくしゅう','きょういく','けんきゅう','そつぎょう'],
        pick: '',
        correct: 'そつぎょう'
      },
      {
        number: 19,
        question: 'この　みちは　（　　）をしている　ので、とおれません。',
        answers: ['こしょう','こうじ','はんたい','しっぱい'],
        pick: '',
        correct: 'こうじ'
      },
      {
        number: 20,
        question: 'きょう、（　　）を　１まい　わって　しまいました。',
        answers: ['タオル','ふくろ','さら','ポスター'],
        pick: '',
        correct: 'さら'
      },
      {
        number: 21,
        question: '日本語が　ただしい　かどうか、もういちど　（　　）して　ください。',
        answers: ['チェック','コピー','スタート','サイン'],
        pick: '',
        correct: 'チェック'
      },
      {
        number: 22,
        question: 'どこに　りょこうに　行くか、　クラスの　みんなに（　　）を　聞いて、きめました。',
        answers: ['そうだん','さんせい','しつもん','いけん'],
        pick: '',
        correct: 'いけん'
      },
      {
        number: 23,
        question: '国から　りょうしんが　来るので、　くうこうへ　（　　）に行きます。',
        answers: ['むかえ','さそい','あんない','じゅんび'],
        pick: '',
        correct: 'むかえ'
      },
      {
        number: 24,
        question: 'この　にくは　かたいので、よく　（　　）たべて　ください。',
        answers: ['かんで','こわして','ふんで','おして'],
        pick: '',
        correct: 'かんで'
      },
      {
        number: 25,
        question: 'きょうは　パーテイーが　あるので、テーブルに　花を（　　）。',
        answers: ['かけました','おくりました','かざりました','たてました'],
        pick: '',
        correct: 'かざりました'
      },
    ]
  },
  mondai4:{
    title: 'もんだい4＿＿＿＿の ぶんと　だいたい　おなじ　いみの　ぶんが　あります。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 26,
        question: 'これは　とても　だいじです。',
        answers: ['これは　とても　ゆうめいです。','これは　とても　たいせつです。','これは　とても　べんりです。','これは　とても　じょうぶです。'],
        pick: '',
        correct: 'これは　とても　たいせつです。'
      },
      {
        number: 27,
        question: 'ここは　きんえんです。',
        answers: ['ここで　右に　まがってはいけません。','ここで　しゃしんを　とってはいけません。','ここで　のみものを　飲んではいけません。','ここで　たばこを　すってはいけません。'],
        pick: '',
        correct: 'ここで　たばこを　すってはいけません。'
      },
      {
        number: 28,
        question: 'きのう　父に　しかられました。',
        answers: ['きのう　父に　わらわれました。','きのう　父に　ほめられました。','きのう　父に　たのまれました。','きのう　父に　おこられました。'],
        pick: '',
        correct: 'きのう　父に　おこられました。'
      },
      {
        number: 29,
        question: 'あしたの　9時に　そちらに　とどけます。',
        answers: ['あしたの　9時に　そちらに　もっていきます。','あしたの　9時に　そちらに　かえします。','あしたの　9時に　そちらに　つきます。','あしたの　9時に　そちらに　かえります。'],
        pick: '',
        correct: 'あしたの　9時に　そちらに　もっていきます。'
      },
      {
        number: 30,
        question: 'ここは　車を　せいさんする　ところです。',
        answers: ['ここは　車をかりる　とこるです。','ここは　車をとめる　ところです。','ここは　車をつくる　ところです。','ここは　車をあらう　ところです。'],
        pick: '',
        correct: 'ここは　車をつくる　ところです。'
      },
    ]
  },
  mondai5:{
    title: 'もんだい５　つぎの ことばの　つかいかたで　いちばん　いい　ものを　１・２・３・４から　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 31,
        question: '　やくそく',
        answers: [
          'この　ひこうきは　10時に　日本に　つく　｜！やくそく｜',
          'あしたは、　母と　かいものに　いく　｜！やくそく｜が　あります。',
          '電車の　きっぷの　｜！やくそく｜は　インターネットで　できますか。',
          '今から、きょうの　目学の　｜！やくそく｜を　せつめいします。'
        ],
        pick: '',
        correct: 'あしたは、　母と　かいものに　いく　｜！やくそく｜が　あります。'
      },
      {
        number: 32,
        question: '　おれい',
        answers: [
          'おみやげを　もらったので、｜！おれい｜を　言いました。',
          'にゅういんした　とき、友だちが　｜！おれい｜に　来て　くれました。',
          'きょうは　父の　たんじょうびの　｜！おれい｜を　買いに　行きます。',
          '「しつれいします」と　言って、｜！おれい｜を　してから　へやに　入ります。',
        ],
        pick: '',
        correct: 'おみやげを　もらったので、｜！おれい｜を　言いました。'
      },
      {
        number: 33,
        question: '　ていねい',
        answers: [
          'デパートや　ホテルの　人は｜！ていねい｜な　ことばを　つかいます。',
          '父の　たんじょうびに｜！ていねい｜な　レストランに　行きました。',
          '女の人に　年を　聞くのは｜！ていねい｜ではありません。',
          'この　おかしは　あじが　とても　｜！ていねい｜ですね。',
        ],
        pick: '',
        correct: 'デパートや　ホテルの　人は｜！ていねい｜な　ことばを　つかいます。'
      },
      {
        number: 34,
        question: '　ぬれる',
        answers: [
          'チョコレートで　てが　｜！ぬれて｜　しまいました。',
          'もりの　中の　空気は　｜！ぬれて｜　います。',
          '雨で　せんたくものが　｜！ぬれて｜　しまいました。',
          'この　メロンは　｜！ぬれて｜　いて、おいしいですね。',
        ],
        pick: '',
        correct: '雨で　せんたくものが　｜！ぬれて｜　しまいました。'
      },
      {
        number: 35,
        question: '　わかす',
        answers: [
          'はしる前に、少し　うんどうを　して、からだを　｜！わかしました｜。',
          '！わかした｜　たまごを　半分にして、サラダに　入れました。',
          'さむいので、ストーブで　へやを　｜！わかしましょう｜。',
          'おちゃを　入れるから、おゆを　｜！わかして｜　ください。',
        ],
        pick: '',
        correct: 'おちゃを　入れるから、おゆを　｜！わかして｜　ください。'
      },
    ]
  }
}