import Helper from "@helper";

export const fukushuu2 = {
    mondai1: Helper.shuffle([
            {
                question: "タワポンさんは　｜＾試（し）験（けん）｜　｜＄０｜＾合（ごう）格（かく）｜しました。",
                picked: [""],
                answers:Helper.shuffle(["を","が","で","に"]),
                correct: ["に"],
            },
            {
                question: "＾引（ひ）｜っ｜＾越（こ）｜しは　｜＾来（らい）月（げつ）｜＄０｜＾予（よ）定（てい）｜です。",
                picked: [""],
                answers:Helper.shuffle(["の","に","へ","で"]),
                correct: ["の"],
            },
            {
                question: "かぜ｜＄０｜ひいて、せき｜＄１｜＾出（で）｜ます。",
                picked: ["",""],
                answers:Helper.shuffle(["を","が","に","で"]),
                correct: ["を","が"],
            },
            {
                question: "＾運（うん）動（どう）会（かい）｜で　｜＾足（あし）｜＄０｜けが｜＄１｜して　しまいました。",
                picked: ["",""],
                answers:Helper.shuffle(["を","が","で","に"]),
                correct: ["に","を"],
            },
            {
                question: "＾本（ほん）｜に　｜＾名（な）前（まえ）｜＄０｜　｜＾書（か）｜いて　あります。",
                picked: [""],
                answers:Helper.shuffle(["が","を","は","に"]),
                correct: ["が"],
            },
            {
                question: "あそこに　「｜＾立（たち）入（いり）禁（きん）止（し）｜」｜＄０｜＾書（か）｜いて　あります。",
                picked: [""],
                answers:Helper.shuffle(["と","を","が","に"]),
                correct: ["と"],
            },
            {
                question: "あの　｜＾漢（かん）字（じ）｜は　「こしょう」｜＄０｜＾読（よ）｜みます。",
                picked: [""],
                answers:Helper.shuffle(["と","を","が","に"]),
                correct: ["と"],
            },
            {
                question: "このマークは　｜＾水（みず）｜で｜＾洗（あら）｜える｜＄０｜いう　｜＾意（い）味（み）｜です。",
                picked: [""],
                answers:Helper.shuffle(["と","を","が","に"]),
                correct: ["と"],
            },
            {
                question: "＾学（がっ）校（こう）｜の　｜＾近（ちか）｜くですから、｜＾子（こ）｜どもたち｜＄０｜＾注（ちゅう）意（い）｜して　｜＾運（うん）転（てん）｜します。",
                picked: [""],
                answers:Helper.shuffle(["と","を","が","に"]),
                correct: ["に"],
            },
            {
                question: "＾下（へ）手（た）｜ですから、｜＾試（し）合（あい）｜＄０｜＾出（で）｜られません。",
                picked: [""],
                answers:Helper.shuffle(["を","に","で","が"]),
                correct: ["に"],
            },
            {
                question: "＾鈴（すず）木（き）｜さんは　｜＾今（いま）席（せき）｜＄０｜＾外（はず）｜しています。",
                picked: [""],
                answers:Helper.shuffle(["を","に","へ","が"]),
                correct: ["を"],
            },
            {
                question: "＾説（せつ）明（めい）書（しょ）｜＄０｜とおりに、｜＾棚（たな）｜を｜＾組（く）｜み｜＾立（た）｜てます。",
                picked: [""],
                answers:Helper.shuffle(["の","が","を","は"]),
                correct: ["の"],
            },
            {
                question: "＾私（わたし）｜＄０｜＾折（お）｜ったとおりに、｜＾紙（かみ）｜＄１｜＾折（お）｜ってください。",
                picked: ["",""],
                answers:Helper.shuffle(["が","を","に","は"]),
                correct: ["が","を"],
            },
            {
                question: "＾昼（ひる）｜ごはん｜＄０｜あとで、｜＾出（で）｜かけます。",
                picked: [""],
                answers:Helper.shuffle(["の","を","と","が"]),
                correct: ["の"],
            },
            {
                question: "うちを｜＾出（で）｜たあとで、｜＾忘（わす）｜れ｜＾物（もの）｜＄０｜＾気（き）｜＄１｜つきました。",
                picked: ["",""],
                answers:Helper.shuffle(["に","が","を","で"]),
                correct: ["に","が"],
            },
            {
                question: "あの｜＾店（みせ）｜で　いろいろな｜＾物（もの）｜が　１００｜＾円（えん）｜＄０｜＾買（か）｜えます。",
                picked: [""],
                answers:Helper.shuffle(["で","に","と","は"]),
                correct: ["で"],
            },
    ]),
    mondai2: 
    // Helper.shuffle(
    [
        {
            question: "この　｜！映画｜は　気に入った。",
            picked: "",
            answers: Helper.shuffle(["えいが", "えが", "えいか", "えか"]),
            correct: "えいが",
        },
        {
            question: "この　｜！計画｜は　どうでしょうか？",
            picked: "",
            answers: Helper.shuffle(["けいかく", "けいがく", "けいか", "けいが"]),
            correct: "けいかく",
        },
        {
            question: "私は　｜！音｜楽家に　なりたいです。",
            picked: "",
            answers: Helper.shuffle(["おん", "おと", "うん", "あん"]),
            correct: "おん",
        },
        {
            question: "どんな　｜！音楽｜が好きですか？",
            picked: "",
            answers: Helper.shuffle(["おんがく", "あんがく", "おんかく", "あんかく"]),
            correct: "おんがく",
        },
        {
            question: "その　｜！歌｜を　知っていますか？",
            picked: "",
            answers: Helper.shuffle(["うた", "おと", "こえ", "かしゅ"]),
            correct: "うた",
        },
        {
            question: "君の　｜！写真｜を撮ってもいいですか？",
            picked: "",
            answers: Helper.shuffle(["しゃしん", "かばん", "ぼうし", "めがね"]),
            correct: "しゃしん",
        },
        {
            question: "彼女は　世界中に　｜！旅行｜しました。",
            picked: "",
            answers: Helper.shuffle(["りょこう", "りょうこう", "りょこ", "りょうこ"]),
            correct: "りょこう",
        },
        {
            question: "彼女は　｜！世界｜中に　旅行しました。",
            picked: "",
            answers: Helper.shuffle(["せかい", "せいかい", "ぜかい", "ぜいかい"]),
            correct: "せかい",
        },
        {
            question: "あなたの　｜！仕事｜はなんですか。",
            picked: "",
            answers: Helper.shuffle(["しごと", "しこと", "じごと", "じこと"]),
            correct: "しごと",
        },
        {
            question: "私の　仕事は　｜！銀行｜員です。",
            picked: "",
            answers: Helper.shuffle(["ぎんこう", "きんこう", "きんごう", "ぎんごう"]),
            correct: "ぎんこう",
        },
        {
            question: "彼の　父親は　｜！医者｜ですか？",
            picked: "",
            answers: Helper.shuffle(["いしゃ", "きしゃ", "きょうし", "べんごし"]),
            correct: "いしゃ",
        },
        {
            question: "彼は　本当に　よく　｜！働｜く　人だ。",
            picked: "",
            answers: Helper.shuffle(["はたら", "うごう", "はだら", "うこう"]),
            correct: "はたら",
        },
        {
            question: "彼が　｜！部屋｜に　入ってきた。",
            picked: "",
            answers: Helper.shuffle(["へや", "ほんや", "しんしつ", "きょうしつ"]),
            correct: "へや",
        },
        {
            question: "この　商品は　日本に　｜！生産｜しましたか？",
            picked: "",
            answers: Helper.shuffle(["せいさん", "ぜいさん", "せいざん", "さんせい"]),
            correct: "せいさん",
        },
        {
            question: "！授業｜中は　食事しないこと。",
            picked: "",
            answers: Helper.shuffle(["じゅぎょう", "さぎょう", "えいぎょう", "こうぎょう"]),
            correct: "じゅぎょう",
        },
        {
            question: "！林｜の中でいっぱい虫にさされた。",
            picked: "",
            answers: Helper.shuffle(["はやし", "むし", "しんりん", "きむら"]),
            correct: "はやし",
        },
        {
            question: "！森林｜の空気はおいしい。",
            picked: "",
            answers: Helper.shuffle(["しんりん", "もりはやし", "じんりん", "もりばやし"]),
            correct: "しんりん",
        },
        {
            question: "日本は｜！地震｜が多い国です",
            picked: "",
            answers: Helper.shuffle(["じしん", "しま", "しぜん", "かわ"]),
            correct: "じしん",
        },
        {
            question: "北｜！海｜道は本州の北にある。",
            picked: "",
            answers: Helper.shuffle(["かい", "がい", "うみ", "まい"]),
            correct: "かい",
        },
        {
            question: "どんな｜！洋服｜が好ですか。",
            picked: "",
            answers: Helper.shuffle(["ようふく", "よふく", "ようぶく", "よっふく"]),
            correct: "ようふく",
        },
        {
            question: "先週、東京で大｜！雪｜が降りました。",
            picked: "",
            answers: Helper.shuffle(["ゆき", "あめ", "ふき", "うき"]),
            correct: "ゆき",
        },
        {
            question: "彼は｜！観光｜バスの運転手です。",
            picked: "",
            answers: Helper.shuffle(["かんこう", "かんごう", "けんこう", "けんごう"]),
            correct: "かんこう",
        },
        {
            question: "大きな｜！台風｜が接（せっ）近（きん）している。",
            picked: "",
            answers: Helper.shuffle(["たいふう", "かぜ", "つなみ", "じしん"]),
            correct: "たいふう",
        },
        {
            question: "どの｜！季節｜が一番好きですか。",
            picked: "",
            answers: Helper.shuffle(["きせつ", "ふく", "せいかつ", "かんきょう"]),
            correct: "きせつ",
        },
        {
            question: "！春｜は私が一番好きな季節だ。",
            picked: "",
            answers: Helper.shuffle(["はる", "あき", "なつ", "ふゆ"]),
            correct: "はる",
        },
        {
            question: "！夏｜休みは７月から始まる。",
            picked: "",
            answers: Helper.shuffle(["はる", "あき", "なつ", "ふゆ"]),
            correct: "なつ",
        },
        {
            question: "！秋｜になると木の葉の色が変わる。",
            picked: "",
            answers: Helper.shuffle(["はる", "あき", "なつ", "ふゆ"]),
            correct: "あき",
        },
        {
            question: "！冬｜が終わって、春が来ました。",
            picked: "",
            answers: Helper.shuffle(["はる", "あき", "なつ", "ふゆ"]),
            correct: "ふゆ",
        },
        {
            question: "今日は外が本当に｜！暑い｜。",
            picked: "",
            answers: Helper.shuffle(["あつい", "さむい", "おもい", "あたたかい"]),
            correct: "あつい",
        },
        {
            question: "家の中はとても｜！あたたかい｜。",
            picked: "",
            answers: Helper.shuffle(["重かい", "暑かい", "寒かい", "暖かい"]),
            correct: "暖かい",
        },
        {
            question: "夜は｜！涼しい｜が、昼間は暑い。",
            picked: "",
            answers: Helper.shuffle(["すずしい", "なつかしい", "やさしい", "おいしい"]),
            correct: "すずしい",
        }
    ]
    // )
}