import Number from "@components/common/Number"
import { memo } from "react"

const KanjiCompoundMobileComponent = ({ compound,fontSize,furigana,furiTranslate,kanjiDetail,lang }: any) => {
    const ListTile = ({ title, body, i }: any) => {
        return (
            <div className="flex flex-row items-start mt-2 border-b-gray-300 dark:border-b-[#424242] border-b-[0.5px]">
                <Number fontSize={fontSize} furigana={furigana}>
                    {i + 1}
                    <span className="ml-2">.</span>
                </Number>
                <div className="flex flex-col ">
                    <div className="">{title}</div>
                    <div className="mb-2">{body}</div>
                </div>
            </div>
        )
    }
    return <>
        <div className={`mt-10 absolute left-0 px-[10px] py-[5px] rounded-r-[100px] text-[14px] bg-[#0084E8] bunkei-title-light text-white w-fit`}>
            Compound
        </div>
        <div className="mt-[70px] flex flex-col p-4 pt-2">
            {
                compound.map(({ furi, normal, en, mm }: any, i: number) => {
                    return (<div key={Math.random()}>
                        {
                            <ListTile i={i} title={furigana === 'true' ?
                                furiTranslate({ context: furi, fontSize, style: "flex-wrap items-center", kanjiDetail }) : normal}
                                body={lang == 'en' ? en : mm}
                            />
                        }
                    </div>)
                })
            }
        </div>
    </>
}
export default memo(KanjiCompoundMobileComponent);