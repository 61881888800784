import Play from "@assets/icon/Amidio/play.svg";
import SizeBox from "@components/SizeBox";
import Icon from "@consts/icon";
import { ChangeEvent, memo, useEffect, useRef, useState } from "react";
import {
    IoPauseSharp,
    IoVolumeMute
} from 'react-icons/io5';
import ProgressSliderComponent from "./ProgressSliderComponent";
interface AmidioProps {
    audioUrl: string;
    scoreIncrementAction?:()=>void;
}
const AmidioComponent = ({ audioUrl,scoreIncrementAction }: AmidioProps) => {
    // on demounted
    const parent = useRef(null); 
    const [range, setRange] = useState<number | string>(0);
    const [time, setTime] = useState<number>(0);
    const [play, setPlay] = useState<Boolean>(false);
    const [showVolSlider, setShowVolSlider] = useState<Boolean>(false);
    const audio = useRef(new Audio(audioUrl));
    const duration = audio.current.duration || 0;
    // volume
    const [volume, setVolume] = useState<number>(50);
    const [muteVolume, setMuteVolume] = useState(false);

    // volume
    const playBtnAction = () => {
        setPlay(!play);
        if (play) {
            audio.current.pause();
        } else {
            audio.current.play();
        }
    }
    const onTimeUpdate = () => {
        setTime(audio.current.currentTime);
        setRange(audio.current.currentTime * 100 / duration);
    }
     const timeConvert = (time: number): string => {
        const min = Math.floor(time / 60);
        const sec = Math.floor(time % 60);
        return `${min.toString().padStart(min > 9 ? 2 : 1, '0')}:${sec.toString().padStart(2, '0')}`;
    }
    const onVolSliderChange = (event: ChangeEvent<HTMLInputElement>) => {
        setVolume(parseInt(event.target.value)/100);
        audio.current.volume = parseInt(event.currentTarget.value) / 100;

    }
    const onEnd = () => {
        audio.current.currentTime = 0;
        setPlay(false);
        scoreIncrementAction && scoreIncrementAction();
    }
 
    // pure js
    useEffect(() => {
        // addListen
        audio.current.addEventListener("timeupdate", onTimeUpdate);
        audio.current.addEventListener("ended", onEnd);
        // Remove Listen
        return () => {
            // close Audio
            if (!parent.current) audio.current.pause();
            
            audio.current.removeEventListener("timeupdate", onTimeUpdate);
            audio.current.removeEventListener("ended", onEnd);

        }
    })
    // pure js
    const setCurrentTime = (time: number) => {
        audio.current.currentTime = time;
    }
    const onTimeSliderChange = (event: ChangeEvent<HTMLInputElement>) => {
        // setRange(event.currentTarget.value); not Require
        setCurrentTime((parseInt(event.currentTarget.value) * duration) / 100);
    }
    return <>
        <div ref={parent} className="relative select-none flex flex-row px-2 items-center w-[232px] h-[32px] rounded-[5px] border-[1px] bg-white dark:bg-darkFooter border-[#B1B1B1] dark:border-[#001E34]">
            {/* Play Btn */}
            <div onClick={playBtnAction} className="flex flex-row justify-center items-center w-[12px] h-[12px]">
                {
                    play === true ? <IoPauseSharp className="btn  text-[#8D8D8D]" /> : <img src={Play} className="btn " />

                }
            </div>

            {/* Time */}
            <div className="flex flex-row justify-center text-[11px] text-[#424242] dark:text-[#BCBCBC] mx-2 ">
                <div>{timeConvert(time)}</div><span className="mx-0.5">/</span><div>{timeConvert(duration)}</div>
            </div>
            {/* Slider */}
            <div className="flex-1 flex flex-row justify-center items-center w-full h-full">
                <ProgressSliderComponent
                    width={110}
                    value={range}
                    color="#F59815"
                    bgColor="#B3B3B3"
                    thumbSize={10}
                    scrollHeight={3}
                    onSliderChange={onTimeSliderChange}
                    name="time-slider"
                />
            </div>
            <div className=" " onClick={() => setShowVolSlider(!showVolSlider)}>
                {
                    showVolSlider && <div className="absolute bottom-11 -rotate-90 -right-9 z-popover">
                        <ProgressSliderComponent
                            width={80}
                            value={volume * 100}
                            color="#F59815"
                            bgColor="#B3B3B3"
                            thumbSize={6}
                            scrollHeight={5}
                            onSliderChange={onVolSliderChange}
                            name="volume-slider"
                        />
                    </div>
                }
                <div onClick={(e) => setMuteVolume(!e)}>
                    {muteVolume || volume == 0 ? <IoVolumeMute className="text-[#8D8D8D]" /> : <img src={Icon.Amiespeaker} className="btn" />}
                </div>
            </div>
        </div>
        <SizeBox vertical="h-5" />
    </>
}

export default memo(AmidioComponent);