export const lesson28 = [
    //＾飼（か）｜います
    {
        furi: '＾売（う）｜れます',
        normal: '売れます',
        en: 'sell, be sold',
        remark_en: "",
        mm: 'ရောင်းကောင်းသည်',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO1.mp3")
    },
    {
        furi: '＾踊（おど）｜ります',
        normal: '踊ります',
        en: '',
        remark_en: "dance",
        mm: 'ကသည်၊ ကခုန်သည်',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO2.mp3")
    },
    {
        furi: '＾噛（か）｜みます',
        normal: '噛みます',
        en: 'chew, bite',
        remark_en: "",
        mm: 'ကိုက်သည်။ ဝါးသည်',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO3.mp3")
    },
    {
        furi: '＾選（えら）｜びます',
        normal: '選びます',
        en: 'choose',
        remark_en: "",
        mm: 'ရွေးချယ်သည်',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO4.mp3")
    },
    {
        furi: '＾通（かよ）｜います',
        normal: '通います',
        en: 'go to and from',
        remark_en: "",
        mm: 'တက္ကသိုလ်၊ ကျူရှင် တက်သည်',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO5.mp3")
    },
    {
        furi: '＾メモ（めも）｜します',
        normal: 'メモします',
        en: 'take a memo',
        remark_en: "",
        mm: 'noteမှတ်သည်',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO6.mp3")
    },
    {
        furi: '＾メモ（まじめ）',
        normal: '真面目',
        en: 'serious',
        remark_en: "",
        mm: 'တည်ကြည်သော။ ရိုးသားဖြောင့်မတ်သော။ လေးနက်သော',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO7.mp3")
    },
    {
        furi: '＾熱心（ねっしん）',
        normal: '熱心',
        en: 'earnest',
        remark_en: "",
        mm: 'စိတ်အားထက်သန်သော',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO8.mp3")
    },
    {
        furi: '＾偉（えら）｜い',
        normal: '偉い',
        en: 'great',
        remark_en: "",
        mm: 'မြင့်မြတ်သော',
        remark_mm: "ကြီးမြတ်သော၊ရာထူးကြီးသော",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO9.mp3")
    },
    {
        furi: 'ちょうどいい',
        normal: 'ちょうどいい',
        en: 'Just right',
        remark_en: "",
        mm: 'ကွက်တိဖြစ်သော',
        remark_mm: "အနေတော်ဖြစ်သော",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO10.mp3")
    },
    {
        furi: '＾景（け）色（しき）',
        normal: '景色',
        en: 'scenery',
        remark_en: "",
        mm: 'ရှုခင်း',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO11.mp3")
    },
    {
        furi: '＾美（び）容（よう）院（いん）',
        normal: '美容院',
        en: 'beauty salon',
        remark_en: "",
        mm: 'အလှပြင်ဆိုင်',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO12.mp3")
    },
    {
        furi: '＾台（だい）所（どころ）',
        normal: '台所',
        en: 'kitchen',
        remark_en: "",
        mm: 'မီးဖိုခြောင်',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO13.mp3")
    },
    {
        furi: '＾経（けい）験（けん）',
        normal: '経験',
        en: 'experience',
        remark_en: "",
        mm: 'အတွေ့အကြုံ',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO14.mp3")
    },
    {
        furi: '＾力（ちから）',
        normal: '力',
        en: 'Power',
        remark_en: "",
        mm: 'အင်အား',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO15.mp3")
    },
    {
        furi: '＾人（にん）気（き）',
        normal: '人気',
        en: 'popular',
        remark_en: "",
        mm: 'လူကြိုက်များသော',
        remark_mm: "ရေပန်းစားသော",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO16.mp3")
    },
    {
        furi: '＾形（かたち）',
        normal: '形',
        en: 'shape',
        remark_en: "",
        mm: 'ပုံသဏ္ဍာန်',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO17.mp3")
    },
    {
        furi: '＾色（いろ）',
        normal: '色',
        en: 'colour',
        remark_en: "",
        mm: 'အရောင်',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO18.mp3")
    },
    {
        furi: '＾味（あじ）',
        normal: '味',
        en: 'taste',
        remark_en: "",
        mm: 'အရသာ',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO19.mp3")
    },
    {
        furi: 'ガム',
        normal: 'ガム',
        en: 'gum',
        remark_en: "",
        mm: 'ပီကေ',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO20.mp3")
    },
    {
        furi: '＾品（しな）物（もの）',
        normal: '品物',
        en: 'goods',
        remark_en: "",
        mm: 'ကုန်ပစ္စည်း',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO21.mp3")
    },
    {
        furi: '＾値（ね）段（だん）',
        normal: '値段',
        en: 'price',
        remark_en: "",
        mm: 'ကုန်ဈေးနှုန်း',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO22.mp3")
    },
    {
        furi: '＾給（きょう）料（りょう）',
        normal: '給料',
        en: 'salary',
        remark_en: "",
        mm: 'လစာ',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO23.mp3")
    },
    {
        furi: 'ボーナス',
        normal: 'ボーナス',
        en: 'Bonus',
        remark_en: "",
        mm: 'ဘောနပ်စ်',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO24.mp3")
    },
    {
        furi: 'ゲーム',
        normal: 'ゲーム',
        en: 'game',
        remark_en: "",
        mm: 'ဂိမ်း',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO25.mp3")
    },
    {
        furi: '＾歌（か）手（しゅ）',
        normal: '歌手',
        en: 'singer',
        remark_en: "",
        mm: 'အဆိုတော်',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO28.mp3")
    },
    {
        furi: '＾小（しょう）説（せつ）',
        normal: '小説',
        en: 'novel',
        remark_en: "",
        mm: 'ဝတ္ထု',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO29.mp3")
    },
    {
        furi: '＾小（しょう）説（せつ）家（か）',
        normal: '小説家',
        en: 'Novelist',
        remark_en: "",
        mm: 'ဝတ္ထုဆရာ',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO30.mp3")
    },
    {
        furi: '～｜＾家（か）',
        normal: '～家',
        en: '~specialist',
        remark_en: "",
        mm: '~ပညာရှင်',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO31.mp3")
    },
    {
        furi: '～｜＾機（き）',
        normal: '～機',
        en: '～nos',
        remark_en: "",
        mm: '～လုံး',
        remark_mm: "စက်ကိုရေတွက်ရာတွင်သုံး",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO32.mp3")
    },
    {
        furi: '＾息（むす）子（こ）',
        normal: '息子',
        en: 'son',
        remark_en: "my son",
        mm: 'သား',
        remark_mm: "မိမိ၏သား",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO33.mp3")
    },
    {
        furi: '＾息（むす）子（こ）｜さん',
        normal: '息子さん',
        en: 'son',
        remark_en: "other son",
        mm: 'သား',
        remark_mm: "အခြားသူ၏ သား",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO34.mp3")
    },
    {
        furi: '＾娘（むすめ）',
        normal: '娘',
        en: 'daughter',
        remark_en: "my daughter",
        mm: 'သမီး',
        remark_mm: "မိမိ၏သမီး",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO35.mp3")
    },
    {
        furi: '＾娘（むすめ）｜さん',
        normal: '娘さん',
        en: 'daughter',
        remark_en: "other daughter",
        mm: 'သမီး',
        remark_mm: "အခြားသူ၏",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO36.mp3")
    },
    {
        furi: '＾自（じ）分（ぶん）',
        normal: '自分',
        en: 'myself',
        remark_en: "",
        mm: 'မိမိကိုယ်ကို',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO37.mp3")
    },
    {
        furi: '＾将（しょう）来（らい）',
        normal: '将来',
        en: 'future',
        remark_en: "",
        mm: 'အနာဂါတ်',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO38.mp3")
    },
    {
        furi: 'しばらく',
        normal: 'しばらく',
        en: 'for a while',
        remark_en: "",
        mm: 'ခဏတစ်ဖြုတ်',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO39.mp3")
    },
    {
        furi: 'たいてい',
        normal: 'たいてい',
        en: 'generally',
        remark_en: "",
        mm: 'များသောအားဖြင့်',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO40.mp3")
    },
    {
        furi: 'それに',
        normal: 'それに',
        en: 'in addition',
        remark_en: "",
        mm: 'ထို့အပြင်',
        remark_mm: "နောက်ပြီးတော့",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO41.mp3")
    },
    {
        furi: 'それで',
        normal: 'それで',
        en: 'so',
        remark_en: "",
        mm: 'အဲ့ဒါကြောင့်၊ အဲ့ဒါနဲ့ပဲ',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO42.mp3")
    },
    {
        furi: '「ちょっと」お｜＾願（おねが）｜いがあるんですが。',
        normal: '「ちょっと」お願いがあるんですが。',
        en: 'I have a "little" request.',
        remark_en: "",
        mm: 'တစ်ဆိတ်လောက် တောင်းဆိုစရာရှိလို့ပါ။',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO43.mp3")
    },
    {
        furi: '＾実（じつ）｜は',
        normal: '実は',
        en: 'actually',
        remark_en: "",
        mm: 'တစ်ကယ်တော့',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO44.mp3")
    },
    {
        furi: '＾会（かい）話（わ）',
        normal: '会話',
        en: 'conversation',
        remark_en: "",
        mm: 'စကားပြော',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO45.mp3")
    },
    {
        furi: 'うーん',
        normal: 'うーん',
        en: 'hmm',
        remark_en: "",
        mm: 'အင်း၊ အေး',
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson28/vocab/4N28VO46.mp3")
    },
    {
        furi: 'お｜＾知（し）｜らせ',
        normal: 'お知らせ',
        en: 'notice',
        remark_en: "",
        mm: 'အကြောင်းကြားခြင်း၊ အသိပေးခြင်း။',
        remark_mm: "",
        group: 0
    },
    {
        furi: '＾参（さん）加（か）｜します',
        normal: '参加します',
        en: 'join',
        remark_en: "",
        mm: 'ပါဝင်ဆင်နွဲသည်။',
        remark_mm: "",
        group: 0
    },
    {
        furi: '＾日（ひ）｜にち',
        normal: '日にち',
        en: 'date',
        remark_en: "",
        mm: 'နေ့ရက်',
        remark_mm: "",
        group: 0
    },
    {
        furi: '＾土（ど）',
        normal: '土',
        en: 'soil',
        remark_en: "",
        mm: 'စနေ',
        remark_mm: "စနေနေ့",
        group: 0
    },
    {
        furi: '＾体（たい）育（いく）館（かん）',
        normal: '体育館',
        en: 'gym',
        remark_en: "",
        mm: 'အားကစားရုံ',
        remark_mm: "",
        group: 0
    },
    {
        furi: '＾無（む）料（りょう）',
        normal: '無料',
        en: 'free',
        remark_en: "",
        mm: 'အခမဲ့၊ အလကား',
        remark_mm: "",
        group: 0
    },
    {
        furi: '＾誘（さそ）｜います',
        normal: '誘います',
        en: 'invite',
        remark_en: "",
        mm: 'အဖော်စပ်သည်၊ ဖိတ်ကြားသည်။',
        remark_mm: "",
        group: 0
    },
    {
        furi: 'イベント',
        normal: 'イベント',
        en: 'event',
        remark_en: "",
        mm: 'ဖျော်ဖြေပွဲ၊ ပွဲစဉ်',
        remark_mm: "",
        group: 0
    }
]