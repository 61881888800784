import SizeBox from "@components/SizeBox";
import GameDashboardComponent from "@components/kanji/game/GameDashboardComponent";
import GameDisplayCardComponent from "@components/kanji/game/GameDisplayCardComponent";
import GameNavBarComponent from "@components/kanji/game/GameNavBarComponent";
import ResultComponent from "@components/kanji/game/ResultComponent";
import KanjiGameScreenLayout from "@layouts/home/KanjiGameScreenLayout";
import { memo, useEffect, useLayoutEffect, useRef, useState } from "react";
import { ScreenPropType } from "../../../App";


const KanjiGameScreen = ({ getScore,setScores,ScoreType,Minna, fontSize, Logger, useLocation, Routes, useNavigate, Helper }: ScreenPropType)=>{
    const log = Logger(8);
    const navigate = useNavigate();
    
    // for custom height
    const ref = useRef<any>(null);
    const [width, setWidth] = useState(0);

    const [oldScore, setOldScore] = useState(0);
    const [newScore, setNewScore] = useState(0);
    // Navbar data
    const [percent, setPercent] = useState(0);
    const [timeLeft, setTimeLeft] = useState(0);

    // for audio
    const intro = useRef(new Audio(require("@assets/audio/game/intro.mp3")));
    const award = useRef(new Audio(require("@assets/audio/game/award.mp3")));
    const correctAudio = useRef(new Audio(require("@assets/audio/game/correct.mp3")));
    const wrongAudio = useRef(new Audio(require("@assets/audio/game/wrong.mp3")));
    const loseAudio = useRef(new Audio(require("@assets/audio/game/lose.mp3")));
   
    // multiple choice
    const [multiple, setMultiple] = useState([
        { answer: "a",selected: false,correct: false},
        { answer: "b",selected: false,correct: false},
        { answer: "c",selected: false,correct: false},
        { answer: "d",selected: false,correct: false}
    ])

    const [game, setGame] = useState<Array<any>>([]);
    const [count, setCount] = useState(0);
    const [onPick, setOnPick] = useState(false);
    const [gameOver, setGameOver] = useState(false);
    const [hit, setHit] = useState(0);

    // dashboard
    const [life, setLife] = useState(0);
    const [correct,setCorrect] = useState(0);
    const [wrong,setWrong] = useState(0);
    const [lifeState,setLifeState] = useState("");

    const location = useLocation();
    const { id, kanji2hira } = location.state;
    const kanjiScore = getScore({ id, key: ScoreType.KANJI_SCORE });

    const handleWindowResize = ()=>{
        log.e("width update");
        setWidth(ref.current.offsetWidth);
    }

    useEffect(() => {
        if(game.length===0){
            // play audio intro
            initial();
        }
        // window.addEventListener('resize', handleWindowResize);
        // return () => {
        //     window.removeEventListener('resize', handleWindowResize);
        // };
    })

    const getPick = ()=>{
        return localStorage.getItem("onPick");
    }

    const setPick = (value:string)=>{
        localStorage.setItem("onPick",value);
    }

    const back = () => {
        navigate(-1);
        setPick("1");
    }

    const tryAgain = ()=>{
        setOnPick(false);
        setCount(0);
        setCorrect(0);
        setWrong(0);
        setLifeState("");
        setGameOver(false);
        setOldScore(0);
        setNewScore(0);
        setGame([]);
    }

    // Play Audio
    const initial = async() => {
        // intro.current.play();
        setPick("0");
        await Helper.sleep(0);
        const data = kanji2hira ?
            Helper.shuffle(Minna.kanji.games.kanji2hira[id]) :
            Helper.shuffle(Minna.kanji.games.hira2kanji[id]);
        setGame(data);
        setMultiple(
            Helper.shuffle(data[0].answer).map((ans: any) => {
                return { answer: ans, selected: false, correct: false };
            })
        );
        setLife(Math.floor(data.length / 3));
        start();
    }

    useLayoutEffect(() => {
        setWidth(ref.current.offsetWidth);
    }, []);

    const lifeStateHandler = async(state:string)=>{
        setLifeState(state);
        await Helper.sleep(2000);
        setLifeState("");
    }
    const start = async () => {
        let time = 10;
        for (let i = 10; i > -1; i--){
            // picked || demounted (!parent)
            //if (getPick() === "1" || !(window.location.hash).includes(Routes.KANJI_GAME)){
            // console.log(window.location);
            // Hash Route
            // if (getPick() === "1" || !(window.location.hash).includes(Routes.KANJI_GAME)){
            //     log.e("exit");
            //     break;
            // }
            // BrowserRouter
            if (getPick() === "1" || !(window.location.pathname).includes(Routes.KANJI_GAME)){
                log.e("exit");
                break;
            }
            setPercent( Math.floor( (((time-i)*100)/time) ) );
            setTimeLeft(i);
            await Helper.sleep(1000);
        }
        // Not picked && still mounted (parent)
        // Hash Route
        // if (getPick() === "0" && (window.location.hash).includes(Routes.KANJI_GAME)){
        //     ref.current.click();
        // }
        // BrowserRouter
        if (getPick() === "0" && (window.location.pathname).includes(Routes.KANJI_GAME)){
            ref.current.click();
        }
    }
    const awardPlay = ()=>{
        award.current.play();
    }
    const pickAns = async(pick:string)=>{
        setOnPick(true);
        setPick("1");

        const corretAns = game[count].true;
        // check answer
        let updatedLife = life + 0;
        let updatedScore = newScore + 0;
        if (pick === corretAns) {
            if(hit==2){
                // awardPlay();
                award.current.play();
                setLife(life+1);
                updatedLife = life + 1;
                setCorrect(correct + 1);
                updatedScore = newScore + 1000;
                setNewScore(updatedScore);
                setOldScore(newScore);
                setHit(0);
                lifeStateHandler("+1");
            }else{
                // play audio correct
                correctAudio.current.play();
                setCorrect(correct+1);
                setHit(hit + 1);
                setNewScore(newScore + 1000);
                setOldScore(newScore);
                
            }
        }else{
            // play audio wrong
            wrongAudio.current.play();
            setWrong(wrong+1);
            setLife(life - 1);
            setHit(0);
            updatedLife = life-1;
            lifeStateHandler("-1");
        }

        // show result
        setMultiple(
            multiple.map(({answer})=>{
                return { answer,selected: pick === answer,correct: corretAns===answer };
            })
        )
        await Helper.sleep(2000);

        if( game.length === (count + 1) || updatedLife===0 ){
            // finished
            if (updatedLife == 0) {
                loseAudio.current.play();
            }
            // set Score
            if (kanji2hira) {
                setScores({ id, key: ScoreType.KANJI_SCORE,
                    value: { ...kanjiScore, kanji: updatedScore + (updatedLife * 1000), count: kanjiScore.count + 1 }
                });
                
            } else {
                setScores({ id, key: ScoreType.KANJI_SCORE,
                    value: { ...kanjiScore, hira: updatedScore + (updatedLife * 1000), count: kanjiScore.count + 1 }
                })
            }
            setGameOver(true);
        }else{
            // prepare for next quiz
            setMultiple(
                Helper.shuffle(game[count+1].answer).map((ans:any)=>{
                    return { answer: ans,selected: false,correct: false};
                })
            );
            setCount(count+1);
            setOnPick(false);
            setPick("0");
            start();
        }
    }

    return <KanjiGameScreenLayout
        body={
            <div className="h-screen bg-gradient-to-r from-[#2EA5FF] from-1.22% via-[#ADDBFE] via-36.4% to-[#2EA5FF] to-99.86% dark:bg-gradient-to-r dark:from-[#001E34] dark:from-1.22% dark:via-[#004679] dark:via-32.32% dark:to-[#001E34] dark:to-99.86% ">
                {/* web view */}
                <div className="hidden md:block  ">
                    <GameNavBarComponent
                        timeLeft={timeLeft}
                        percent={percent}
                        setPick={setPick}
                        gameOver={gameOver}
                        back={back}
                        id={id}
                    />
                    <SizeBox vertical="h-[40px]" />
                    <div className="relative">
                        {/* Dashboard */}
                        {(game.length > 0 && !gameOver) &&
                        <GameDashboardComponent
                            timeLeft={timeLeft}
                            percent={percent}
                            life={life}
                            lifeState={lifeState}
                            id={id}
                            count={count}
                            game={game}
                            correct={correct}
                            wrong={wrong}
                            oldScore={oldScore}
                            score={newScore}
                            gameOver={gameOver}
                        />
                        }
                        {/* Game Display cart */}
                        {(game.length > 0 && !gameOver) &&
                            <GameDisplayCardComponent
                                game={game}
                                gameOver={gameOver}
                                count={count}
                                multiple={multiple}
                                onPick={onPick}
                                pickAns={pickAns}
                                width={width}
                                awardPlay={awardPlay}
                            />
                        }
                        {
                            gameOver && <ResultComponent
                                life={life}
                                correct={correct}
                                total={game.length}
                                score={newScore}
                                tryAgain={tryAgain}
                                back={back}
                                setNewScore={setNewScore}
                            />
                        }

                        {/* get width simple only */}
                        <div onClick={() => pickAns("~")} ref={ref} className="bg-gray-300 absolute rounded-full opacity-0 top-0 w-0 h-0 text-center cursor-point select-none">
                            a
                        </div>
                    </div>
                </div>
                {/* mobile view */}
                <div className=" md:hidden">
                    <GameNavBarComponent
                        timeLeft={timeLeft}
                        percent={percent}
                        setPick={setPick}
                        gameOver={gameOver}
                        back={back}
                        id={id}
                    />
                    <SizeBox vertical="h-20" />
                    {/* Dashboard */}
                    {(game.length > 0 && !gameOver) &&
                    <GameDashboardComponent
                        timeLeft={timeLeft}
                        percent={percent}
                        life={life}
                        lifeState={lifeState}
                        id={id}
                        count={count}
                        game={game}
                        correct={correct}
                        wrong={wrong}
                        oldScore={oldScore}
                        score={newScore}
                        gameOver={gameOver}
                    />
                    }
                    {/* Game Display cart */}
                    {(game.length > 0 && !gameOver) &&
                        <GameDisplayCardComponent
                            game={game}
                            gameOver={gameOver}
                            count={count}
                            multiple={multiple}
                            onPick={onPick}
                            pickAns={pickAns}
                            width={width}
                            awardPlay={awardPlay}
                        />
                    }
                    {(game.length == 0) && <div className="text-center font-bold text-lg font-rampart">Start</div>}

                    {
                        gameOver && <ResultComponent
                            life={life}
                            correct={correct}
                            total={game.length}
                            score={newScore}
                            tryAgain={tryAgain}
                            back={back}
                            setNewScore={setNewScore}
                        />
                    }

                    {/* get width simple only */}
                    <div className="container mx-auto px-6 grid grid-cols-2 gap-4">
                        <div onClick={() => pickAns("~")} ref={ref} className="bg-gray-300 rounded-full opacity-0 text-center cursor-point select-none">
                            a
                        </div>
                    </div>
                </div>
            </div>
        }
    />
}

export default memo(KanjiGameScreen);