import Lock from "@assets/icon/lock.svg";
import Icon from "@consts/icon";
import { RootState } from "@store";
import { useAppSelector } from "@store/hooks";
import { memo, ReactElement } from "react";
import { RiStarFill, RiStarHalfFill } from 'react-icons/ri';
import LessonCard from "./LessonCard";
const LessonCardComponent = ({ id, isLock,premium, cardAction, percent, dark, setShowModel }: any) => {
    
    const { fontSize } = useAppSelector(({ service }: RootState) => service);
    // const starFull = Icon.starFull;
    const starComponent2 = ({ half, full }: any):ReactElement => {
        return (percent >= full) ? <div className="text-yellow-300 dark:text-amber-300">
            {/* <IconCompoent path={starFull.path} size={starFull.size} color={ starFull.color} /> */}
            <RiStarFill size={15} className="text-[#FFBB35]"/>
        </div>
            : (percent >= half) ? <div className="text-yellow-300 dark:text-amber-300">
            <RiStarHalfFill size={15} />
        </div>
                : <div className="text-[#efebe9] dark:text-gray-300">
            <RiStarFill size={15} />
        </div>
    }
    const starComponent = ({half,full}:any)=>{
        return (percent >= full)?<img src={Icon.FullStar}/>:
        (percent >= half) ? <img src={Icon.HalfStar}/>:<img src={Icon.NoneStar}/>
            
    }
    return (
        <>
            {/*Web View */}
            <LessonCard dark={dark} starComponent={starComponent} percent={percent} premium={premium} isLock={isLock} cardAction={cardAction} Lock={Lock} fontSize={fontSize} id={id} setShowModel={setShowModel} />
            {/*Mobile View */}
            {/* <MobileLessonCard starComponent={starComponent} percent={percent} isLock={isLock} cardAction={cardAction} Lock={Lock} fontSize={fontSize} id={id} /> */}
        </>
    )
}
export default memo(LessonCardComponent);