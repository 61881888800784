export const renshuuA = [
    {
        isTable: true,
        tables: [
            {
                head: {
                    a: {
                        val:"",
                        span: 1,
                        style: ""
                    },
                    b: {
                        val:"",
                        span: 3,
                        style: ""
                    },
                    c: {
                        val: "＾使（し）役（えき）",
                        span: 3,
                        style: ""
                    }
                },
                body: [
                    {
                        no: "I",
                        l1: "てつだ",
                        l2: "い",
                        l3: "ます",
                        r1: "てつだ",
                        r2: "わ",
                        r3:"せます"
                    },
                    {
                        no: "",
                        l1: "か",
                        l2: "き",
                        l3: "ます",
                        r1: "か",
                        r2: "か",
                        r3:"せます"
                    },
                    {
                        no: "",
                        l1: "いそ",
                        l2: "ぎ",
                        l3: "ます",
                        r1: "いそ",
                        r2: "が",
                        r3:"せます"
                    },
                    {
                        no: "",
                        l1: "なお",
                        l2: "し",
                        l3: "ます",
                        r1: "なお",
                        r2: "さ",
                        r3:"せます"
                    },
                    {
                        no: "",
                        l1: "も",
                        l2: "ち",
                        l3: "ます",
                        r1: "も",
                        r2: "た",
                        r3:"せます"
                    },
                    {
                        no: "",
                        l1: "ほこ",
                        l2: "び",
                        l3: "ます",
                        r1: "ほこ",
                        r2: "ば",
                        r3:"せます"
                    },
                    {
                        no: "",
                        l1: "の",
                        l2: "み",
                        l3: "ます",
                        r1: "の",
                        r2: "ま",
                        r3:"せます"
                    },
                    {
                        no: "",
                        l1: "つく",
                        l2: "り",
                        l3: "ます",
                        r1: "つく",
                        r2: "ら",
                        r3:"せます"
                    },
                ],
                style: {
                    no: "p-2 w-[50px] text-right bg-white dark:bg-darkFooter",
                    l1: "p-2 text-right bg-white dark:bg-darkFooter",
                    l2: "p-2 w-[30px] text-right bg-[#729eff] dark:bg-[#003257]",
                    l3: "p-2 text-left bg-white dark:bg-darkFooter",
                    r1: "p-2 text-right bg-white dark:bg-darkFooter",
                    r2: "p-2 w-[30px] text-right bg-[#729eff] dark:bg-[#003257]",
                    r3: "p-2 text-left bg-white dark:bg-darkFooter"
                }
            },
            {
                head: {
                    a: {
                        val:"",
                        span: 1,
                        style: ""
                    },
                    b: {
                        val:"",
                        span: 2,
                        style: ""
                    },
                    c: {
                        val: "＾使（し）役（えき）",
                        span: 2,
                        style: ""
                    }
                },
                body: [
                    {
                        no: "II",
                        l1: "たべ",
                        l3: "ます",
                        r1: "たべ",
                        r3:"させます"
                    },
                    {
                        no: "",
                        l1: "しらべ",
                        l3: "ます",
                        r1: "しらべ",
                        r3:"させます"
                    },
                    {
                        no: "",
                        l1: "い",
                        l3: "ます",
                        r1: "い",
                        r3:"させます"
                    },
                    {
                        no: "",
                        l1: "",
                        l3: "",
                        r1: "",
                        r3:　""
                    },
                    {
                        no: "III",
                        l1: "き",
                        l3: "ます",
                        r1: "こ",
                        r3: "させます"
                    },
                    {
                        no: "",
                        l1: "し",
                        l3: "ます",
                        r1: "さ",
                        r3: "せます"
                    },
                ],
                style: {
                    no: "p-2 w-[50px] text-right bg-white dark:bg-darkFooter",
                    l1: "p-2 text-right bg-white dark:bg-darkFooter",
                    l3: "p-2 text-left bg-white dark:bg-darkFooter",
                    r1: "p-2 text-right bg-white dark:bg-darkFooter",
                    r3: "p-2 text-left bg-white dark:bg-darkFooter"
                }
            }
        ],
        audioUrl: require("@assets/audio/lesson48/renshua/4N48RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾部（ぶ）長（ちょう）｜は　ミラーさんを　アメリカへ　｜！しゅっちょうさせました。",
                normal: "部長は　ミラーさんを　アメリカへ　しゅっちょうさせました。",
                en: "The manager sent Mr. Miller on a business trip to America.",
                mm: "မန်နေဂျာက မစ္စတာ မီလာကို အမေရိကကို အလုပ်ကိစ္စနဲ့ ခရီးတစ်ခု စေလွှတ်ခဲ့တယ်။",
            },
            {
                furi: "＾部（ぶ）長（ちょう）｜は　さとうさんを　かいぎに　｜！しゅっせきさせました。",
                normal: "部長は　さとうさんを　かいぎに　しゅっせきさせました。",
                en: "The manager sent Mr. Sato to the meeting.",
                mm: "မန်နေဂျာက မစ္စတာဆာတိုကို အစည်းအဝေးသို့ စေလွှတ်ခဲ့သည်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson48/renshua/4N48RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "わたしは　こども　に　ほん　を　｜！よませます。",
                normal: "わたしは　こども　に　ほん　を　よませます。",
                en: "I make my child read a book.",
                mm: "ကျွန်တော် ကလေးတွေကို စာအုပ်ဖတ်စေတယ်။",
            },
            {
                furi: "わたしは　おとうと　に　じぶんの　へや　を　｜！そうじさせます。",
                normal: "わたしは　おとうと　に　じぶんの　へや　を　そうじさせます。",
                en: "I will have my brother clean his room.",
                mm: "ကျွန်တော်က ညီလေးကို သူ့အခန်းကို သန့်ရှင်းရေးလုပ်စေတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson48/renshua/4N48RSA3.mp3")
    },
    {
        sentence: [
            {
                furi: "わたしは　｜＾息（むす）子（こ）｜　を　｜＾好（す）｜きな　｜＾人（ひと）｜と　｜！けっこんさせました。",
                normal: "わたしは　息子を　好きな　人と　けっこんさせました。",
                en: "I let the son marry whoever he likes.",
                mm: "ကျွန်တော်က ကျွန်တော့်သားကို သူကြိုက်တဲ့သူနဲ့ လက်ထပ်စေတယ်။",
            },
            {
                furi: "わたしは　むすめ　を　｜＾行（い）｜きたい　｜＾大（だい）学（がく）｜に　｜！いかせました。",
                normal: "わたしは　むすめ　を　行きたい　大学に　いかせました。",
                en: "I sent my daughter to the university she wanted to go to.",
                mm: "ကျွန်တော်က သမီးတက်ချင်တဲ့ တက္ကသိုလ်ကို တက်စေတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson48/renshua/4N48RSA4.mp3")
    },
    {
        sentence: [
            {
                furi: "わたしは　こども　に　すきな　しごと　を　｜！させます。",
                normal: "わたしは　こども　に　すきな　しごと　を　させます。",
                en: "I let my child do what he likes.",
                mm: "ကျွန်တော်က ကလေးကို သူတို့နှစ်သက်တဲ့အလုပ်ကို လုပ်စေပါတယ်။",
            },
            {
                furi: "わたしは　むすこ　に　ほしい　もの　を　｜！かわせます。",
                normal: "わたしは　むすこ　に　ほしい　もの　を　かわせます。",
                en: "I will make my son do what he wants.",
                mm: "ကျွန်တ်ောက သားကို သူလုပ်ချင်တာကို ဝယ်စေပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson48/renshua/4N48RSA5.mp3")
    },
    {
        sentence: [
            {
                furi: "すみませんが、あした　｜！やすませて｜　いただけませんか。",
                normal: "すみませんが、あした　やすませて　いただけませんか。",
                en: "I'm sorry, but could I take a rest tomorrow?",
                mm: "တောင်းပန်ပါတယ် မနက်ဖြန် အနားယူလို့ရမလား",
            },
            {
                furi: "すみませんが、あした　この　資料を　｜！コピーさせて｜　いただけませんか。",
                normal: "すみませんが、あした　この　資料を　コピーさせて　いただけませんか。",
                en: "Excuse me, but could I have a copy of this document tomorrow?",
                mm: "ခွင့်ပြုပါ၊ ဒါပေမယ့် မနက်ဖြန် ဒီစာရွက်စာတမ်း မိတ္တူ ရနိုင်မလား။",
            },
        ],
        audioUrl: require("@assets/audio/lesson48/renshua/4N48RSA6.mp3")
    },
];