import MondaiPopOver1 from '@components/exercise/MondaiPopOver1';
import KanjiPopOverComponent from '@components/kanji/KanjiPopOverComponent';
import { Minna } from '@consts';
import { AiOutlineCheckCircle as Check, AiOutlineCloseCircle as Close } from "react-icons/ai";
import { TfiHandPointUp } from 'react-icons/tfi';
import { TiMinus as AiOutlineMinus } from 'react-icons/ti';
import { dependencyHelper } from './dependencyHelper';
import { furiTranslate } from './furiTranslate';
import vocabTran from './vocabTran';
// navigate(Routes.KANJI_DETAIL, { state: { lesson, id,path: location.pathName } })
interface PopOverType {
    questionId: number;
    callBack: Function;
    answers: Array<any>;
    picked: Array<string>;
}

interface MondaiType {
    context: String;
    fontSize: number;
    checkMondai1: Boolean;
    style: String;
    notUseLibrary?: Boolean;
    popOver: PopOverType;
    correct: Array<String>;
}

interface FuriType {
    context: String;
    fontSize: number;
    style: String;
    notUseLibrary?: Boolean;
}

const Helper = {
    furiTranslate,
    sleep: (ms: number) => new Promise((r, _) => setInterval(r, ms)),
    listGenerate: (length: number, callback: Function) => {
        let list: Array<any> = [];
        for (let i = 0; i < length; i++) {
            list = [...list, callback(i)];
        }
        return list;
    },
    deepClone: (data: any) => {
        return JSON.parse(JSON.stringify(data));
    },
    shuffle: (array: Array<any>) => {
        let shuffled = Helper.deepClone(array);
        let currentIndex = shuffled.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex != 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [shuffled[currentIndex], shuffled[randomIndex]] = [
                shuffled[randomIndex], shuffled[currentIndex]];
        }

        return shuffled;
    },
    mondaiFuri: ({ context,checkMondai1,correct, popOver: { questionId, callBack, answers, picked }, fontSize, style, notUseLibrary }: MondaiType) => {
        const spliter = context.split("｜");
        const furi = (text: string) => {
            // ＾天（てん）気（き）
            text = text.substring(1);
            // 天（てん）気（き）
            const textArray = text.split("）")
            // ['天（てん', '気（き', '']
            return (<>
                <div className="flex flex-row mx-1">
                    {
                        textArray.map((withfuri, i) => {
                            if (withfuri.length > 0) {
                                // ['天','てん']
                                const [kanji, furigana] = withfuri.split("（");
                                // search kanji from library
                                const index = !notUseLibrary ? Minna.kanji.search[kanji] : false;
                                return index ?
                                    (
                                        <KanjiPopOverComponent id={index}>
                                            <div key={Math.random()} className="flex flex-col items-center leading-tight text-blue-500 cursor-pointer">
                                                <div className={`text-${fontSize - 7}px`}>{furigana}</div>
                                                <div className="">{kanji}</div>
                                            </div>
                                        </KanjiPopOverComponent>
                                    )
                                    : (
                                        <div key={i} className="flex flex-col items-center leading-tight">
                                            <div className={`text-${fontSize - 7}px`}>{furigana}</div>
                                            <div className="">{kanji}</div>
                                        </div>
                                    )
                            }

                        })
                    }
                </div>
            </>)
        }
        const popOverBox = (text: string) => {
            const boxId = text[1] === '０' ? 0 : text[1] === '１' ? 1 : text[1] === '２' ? 2 : -1;
            return (<>
                <div className="px-2 flex flex-col mx-1 ">
                    {
                        !checkMondai1?<MondaiPopOver1
                            questionId={questionId}
                            boxId={boxId}
                            callBack={callBack}
                            answers={answers}
                        >
                            <div className='flex flex-col items-center leading-tight '>
                                {<div className={`text-${fontSize - 7}px`}>　</div>}
                                <div className={`flex flex-row text-${fontSize}px cursor-pointer select-none `}>
                                    (　
                                    {
                                        picked[boxId] !== "" ?
                                            picked[boxId]
                                            : (
                                                <>
                                                    <div className='text-[#b4b4b4] dark:text-[#848484]'>Click</div>
                                                    <TfiHandPointUp className='ml-1' />
                                                </>
                                            )
                                    }
                                    　)
                                </div>
                            </div>
                        </MondaiPopOver1>
                        : <div className='flex flex-col items-center leading-tight '>
                        {<div className={`text-${fontSize - 7}px`}>　</div>}
                        <div className={`flex flex-row text-${fontSize}px cursor-pointer select-none `}>
                            (　 
                                {
                                    picked[boxId] !== "" ?
                                        <div className={`flex flex-row items-center ${correct[boxId]===picked[boxId]? 'text-green-500' : 'text-red-500'} `}>
                                            {picked[boxId]}
                                            <div>
                                                {
                                                    correct[boxId] === picked[boxId] ? <Check className='ml-2 text-green-500'/> : <Close className='ml-2 text-red-500'/>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <AiOutlineMinus />
                                        </div>
                                }
                            　)
                        </div>
                    </div>
                    }
                </div>
            </>)
        }
        return (<>
            <div className={`flex flex-row ${style}`}>
                {/* flex-wrap  className={`flex flex-row ${style}`} */}
                {
                    spliter.map((text, i) => {
                        return text.includes("＾") ?
                            <div key={i} className={`flex flex-row`}>{furi(text)}</div> :
                            text.includes("＄") ? <div key={i} className={`flex flex-row`}>
                                {popOverBox(text)}
                            </div> :
                                text.split("").map((char: string) => {
                                    return <div key={Math.random()} className="flex flex-col items-center leading-tight mb-2">
                                        {<div className={`text-${fontSize - 7}px`}>　</div>}
                                        <div className="">{char}</div>
                                    </div>
                                })
                    })
                }
            </div>
        </>);
    },
    underline: ({ context, style }: FuriType) => {
        // context = context.substring(1);
        const spliter = context.split("｜");
        return (
            <div className={`flex flex-row ${style}`}>
                {/*[ ここで　名前と　,！住所,を　書いて　ください。] */}
                {
                    spliter.map((text, i) => {// i=0,
                        return text.includes("！") ?
                            <div key={Math.random()} className="border-b-2 border-blue-500  px-1">{text.substring(1)}</div>
                            : text.split("").map((char: string, index: number) => {
                                return <div key={Math.random()}>{char}</div>
                            })
                    })
                }
            </div>
        )

    },
    spliter: (meaning: string, lang: string) => {
        let json = { "temp": meaning };
        let { temp } = JSON.parse(JSON.stringify(json));
        return temp.split(lang === "en" ? "," : "၊")[0];

    },
    pushStyle: (style: any) => {
        let temp = '';
        Object.values(style).map((val) => {
            temp += val + ' ';
        })
        return temp;
    },
    vocabTran,
    ...dependencyHelper
}
export default Helper;