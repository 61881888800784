export const lesson16 = {
    "場": 150,
    "建": 151,
    "物": 152,
    "院": 153,
    "館": 154,
    "堂": 155,
    "室": 156,
    "工": 157,
    "図": 158,
    "号": 159
};