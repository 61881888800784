import SizeBox from "@components/SizeBox";
import AmidioComponent from "@components/common/Amidio/AmidioComponent";
import AmidioMdComponent from "@components/common/Amidio/AmidioMdComponent";
import SentenceComponent from "@components/common/SentenceComponent";
import TableComponent from "@components/common/TableComponent";
import TitleComponent from "@components/common/TitleComponent";
import { memo } from "react";
const RenshuuAComponent = ({scoreIncrementAction, renshuuA, furigana, lang, fontSize, furiTranslate, lesson, kanjiDetail }: any) => {
    return <div className="relative">
        <TitleComponent 
            furi="＾練（れん）習（しゅう）｜A"
            normal="練 習A"
            furigana={furigana} 
            furiTranslate={furiTranslate} 
            fontSize={fontSize} 
            lesson={lesson+26} 
        />
        <SizeBox vertical="h-16" />
        {
            renshuuA.map(({ isTable, tables, sentence, audioUrl }: any, no: number) => {
                return isTable?(
                    <div key={Math.random()} className="mb-12 px-3 md:pl-8">
                        <div className="mb-3 md:mb-5">{no + 1}</div>
                        <AmidioMdComponent audioUrl={audioUrl}/>
                        <SizeBox vertical="h-7"/>
                        <div className="grid md:grid-cols-2 gap-12 md:gap-10">
                            {
                                tables.map((table:any, index: number) => {
                                    return (
                                        <div key={Math.random()}>
                                            <TableComponent
                                                key={index}
                                                table={table}
                                                furiTranslate={furiTranslate}
                                                fontSize={fontSize}
                                                kanjiDetail={kanjiDetail}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                ) :
                    (<div key={no}>
                        <div className="flex flex-row px-3 md:pl-8">
                            <div>
                                {
                                    sentence.map(({ furi, normal, en, mm }: any, index: number) => {
                                        return (
                                            <div key={Math.random()}>
                                                <SentenceComponent
                                                    no={no + 1}
                                                    index={index}
                                                    furigana={furigana}
                                                    furi={furi}
                                                    normal={normal}
                                                    en={en}
                                                    mm={mm}
                                                    lang={lang}
                                                    fontSize={fontSize}
                                                    kanjiDetail={kanjiDetail}
                                                    scoreIncrementAction={scoreIncrementAction}
                                                />
                                          </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="pl-8"><AmidioComponent audioUrl={audioUrl} scoreIncrementAction={scoreIncrementAction}/></div>
                        <SizeBox vertical="h-5" />
                    </div>
                )
            })
        }   
    </div>
       
        
}
export default memo(RenshuuAComponent);