export const reibun= [
    {
        sentence: [
            {
                furi: "もしもし、｜＾田（た）中（なか）｜ですが、｜＾今（いま）｜　いいですか。",
                normal: "もしもし、田中ですが、今　いいですか。",
                en: "Hello it is Tanaka, is it okay now?",
                mm: "ဟဲလို Tanakaပါ, ခုအဆင်ပြေရဲ့လား",
            },
            {
                furi: "すみません。｜＾電（でん）車（しゃ）｜に｜＾乗（の）｜る、ところなんです。",
                normal: "すみません。電車に乗る、ところなんです。",
                en: "sorry, I am just on the train.",
                mm: "ခွင့်ပြုပါ။ ရထားစီးနေတုန်းမို့ပါ။",
            },
            {
                furi: "あとで　こちらから　｜＾電（でん）話（わ）｜します。",
                normal: "あとで　こちらから　電話します。",
                en: "I'll call you later.",
                mm: "ငါနောက်မှမင်းကိုဖုန်းခေါ်လိုက်မယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson46/bunpou/4N46RB1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾故（こ）障（しょう）｜の｜＾原（げん）因（いん）｜は　わかりましたか。",
                normal: "故障の原因は　わかりましたか。",
                en: "Did you find out what caused the failure?",
                mm: "ပျက်ရတဲ့ အကြောင်းရင်းကို မင်းရှာတွေ့သလား။",
            },
            {
                furi: "いいえ、今　｜＾調（しら）｜べて　いるところです。",
                normal: "いいえ、今　調べて　いるところです。",
                en: "No, I'm looking into it now.",
                mm: "ဟင့်အင်း၊ ငါ အဲဒါကို ရှာနေတုန်း။",
            },
        ],
        audioUrl: require("@assets/audio/lesson46/bunpou/4N46RB2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾渡（わた）辺（なべ）｜さんは　いますか。",
                normal: "渡辺さんは　いますか。",
                en: "Is Mr. Watanabe there?",
                mm: "မစ္စတာ Watanabe ရှိပါသလား။",
            },
            {
                furi: "あ、たった｜＾今（いま）｜　｜＾帰（かえ）｜った　ところです。",
                normal: "あ、たった今　帰った　ところです。",
                en: "Oh, I just got home.",
                mm: "ခုနလေးကမှ ပြန်သွားတာ",
            },
            {
                furi: "まだ　エレベーターの　｜＾所（ところ）｜に　いるかも　しれません。",
                normal: "まだ　エレベーターの　所に　いるかも　しれません。",
                en: "He may still be at the elevator.",
                mm: "ဓာတ်လှေခါးမှာဘဲ ရှိနေနိုင်သေးတယ်",
            },
        ],
        audioUrl: require("@assets/audio/lesson46/bunpou/4N46RB3.mp3")
    },
    {
        sentence: [
            {
                furi: "＾仕（し）事（ごと）｜は　どうですか。",
                normal: "仕事は　どうですか。",
                en: "How is your job?",
                mm: "မင်းအလုပ်က ဘယ်လိုလဲ။",
            },
            {
                furi: "＾先（せん）月（げつ）｜　｜＾会（かい）社（しゃ）｜に　｜＾入（はい）｜った　ばかりなので、まだ　よく　わかりません。",
                normal: "先月　会社に　入った　ばかりなので、まだ　よく　わかりません。",
                en: "I just joined the company last month, so I'm not sure yet.",
                mm: "ပြီးခဲ့တဲ့လကပဲ ကျွန်တော် ကုမ္ပဏီကို ဝင်ခဲ့တာဆိုတော့ သေချာမသိသေးပါဘူး။",
            },
        ],
        audioUrl: require("@assets/audio/lesson46/bunpou/4N46RB4.mp3")
    },
    {
        sentence: [
            {
                furi: "この　ビデオカメラ、｜＾先（せん）週（しゅう）｜　｜＾買（か）｜った　ばかりなのに、｜＾動（うご）｜かないんです。",
                normal: "この　ビデオカメラ、先週　買った　ばかりなのに、動かないんです。",
                en: "I just bought this video camera last week, but it doesn't work.",
                mm: "ဒီဗီဒီယိုကင်မရာကို ပြီးခဲ့တဲ့အပတ်ကမှ ဝယ်ခဲ့ပေမယ့် အဆင်မပြေပါဘူး။",
            },
            {
                furi: "じゃ、ちょっと　｜＾見（み）｜せて　ください。",
                normal: "じゃ、ちょっと　見せて　ください。",
                en: "Then, please show me a little.",
                mm: "ကျေးဇူးပြုပြီး နည်းနည်းလောက် ပြကြည့်ပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson46/bunpou/4N46RB5.mp3")
    },
    {
        sentence: [
            {
                furi: "ミラーさんは　まだですか。",
                normal: "ミラーさんは　まだですか。",
                en: "Mr. Miller, have you yet?",
                mm: "မစ္စတာမီလာ၊ မရောက်သေးဘူးလား။",
            },
            {
                furi: "さっき｜＾駅（えき）｜から　｜＾電（でん）話（が）｜　ありましたから、もうすぐ　｜＾来（く）｜るはずです。",
                normal: "さっき駅から　電話が　ありましたから、もうすぐ　来るはずです。",
                en: "He just got a call from the station, so he should be here soon.",
                mm: "ခုနကမှ ဘူတာကနေ ဖုန်းဆက်လာတဲ့အတွက် သိပ်မကြာခင် ရောက်လာမှာ သေချာပါတယ်၊",
            },
        ],
        audioUrl: require("@assets/audio/lesson46/bunpou/4N46RB6.mp3")
    },
]