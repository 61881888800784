
export const lesson15 = [
    {"lesson":15,"level":4,"question":"送別会","represent":["送","別","会"],"answer":["そうべつかい","きんだいか","せんしんこく","せんめんしょ"],"true":"そうべつかい","times":10},
    {"lesson":15,"level":4,"question":"作文","represent":["作","文"],"answer":["さくぶん","せんさい","さくひん","へんじ"],"true":"さくぶん","times":10},
    {"lesson":15,"level":4,"question":"注目する","represent":["注","目"],"answer":["ちゅうもくする","ちゅういする","しんぽする","しんようする"],"true":"ちゅうもくする","times":10},
    {"lesson":15,"level":4,"question":"進歩する","represent":["進","歩"],"answer":["しんぽする","へんさいする","ちゅうもんする","へんしんする"],"true":"しんぽする","times":10},
    {"lesson":15,"level":4,"question":"科学","represent":["科","学"],"answer":["かがく","どうさ","へんじ","ぶんか"],"true":"かがく","times":10},
    {"lesson":15,"level":4,"question":"通信","represent":["通","信"],"answer":["つうしん","そうりょう","しよう","せんざい"],"true":"つうしん","times":10},
    {"lesson":15,"level":4,"question":"注意する","represent":["注","意"],"answer":["ちゅういする","しんぽする","へんしんする","しんようする"],"true":"ちゅういする","times":10},
    {"lesson":15,"level":4,"question":"先進国","represent":["先","進","国"],"answer":["せんしんこく","きんだいか","せんめんしょ","そうべつかい"],"true":"せんしんこく","times":10},
    {"lesson":15,"level":4,"question":"動作","represent":["動","作"],"answer":["どうさ","さくぶん","かがく","たいし"],"true":"どうさ","times":10},
    {"lesson":15,"level":4,"question":"文化","represent":["文","化"],"answer":["ぶんか","しよう","さくぶん","さくひん"],"true":"ぶんか","times":10},
    {"lesson":15,"level":4,"question":"注文する","represent":["注","文"],"answer":["ちゅうもんする","ちゅういする","へんしんする","しんぽする"],"true":"ちゅうもんする","times":10},
    {"lesson":15,"level":4,"question":"洗面所","represent":["洗","所"],"answer":["せんめんしょ","きんだいか","せんしんこく","そうべつかい"],"true":"せんめんしょ","times":10},
    {"lesson":15,"level":4,"question":"洗剤","represent":["洗"],"answer":["せんざい","たいし","へんじ","どうさ"],"true":"せんざい","times":10},
    {"lesson":15,"level":4,"question":"使用","represent":["使","用"],"answer":["しよう","しんごう","そうりょう","さくぶん"],"true":"しよう","times":10},
    {"lesson":15,"level":4,"question":"送料","represent":["送","料"],"answer":["どうさ","せんざい","つうしん","そうりょう"],"true":"そうりょう","times":10},
    {"lesson":15,"level":4,"question":"返済する","represent":["返"],"answer":["へんさいする","しんぽする","へんしんする","しんようする"],"true":"へんさいする","times":10},
    {"lesson":15,"level":4,"question":"信号","represent":["信","号"],"answer":["しんごう","せんざい","そうりょう","どうさ"],"true":"しんごう","times":10},
    {"lesson":15,"level":4,"question":"作品","represent":["作","品"],"answer":["さくひん","へんじ","しよう","ぶんか"],"true":"さくひん","times":10},
    {"lesson":15,"level":4,"question":"近代化","represent":["近","代","化"],"answer":["きんだいか","そうべつかい","せんめんしょ","せんしんこく"],"true":"きんだいか","times":10},
    {"lesson":15,"level":4,"question":"大使","represent":["大","使"],"answer":["たいし","ぶんか","さくぶん","さくひん"],"true":"たいし","times":10},
    {"lesson":15,"level":4,"question":"信用する","represent":["信","用"],"answer":["しんようする","へんさいする","ちゅうもんする","ちゅうもくする"],"true":"しんようする","times":10},
    {"lesson":15,"level":4,"question":"返信する","represent":["返","信"],"answer":["へんしんする","しんようする","べんさいする","ちゅうもんする"],"true":"へんしんする","times":10},
    {"lesson":15,"level":4,"question":"返事","represent":["返","事"],"answer":["へんじ","せんざい","つうしん","たいし"],"true":"へんじ","times":10},
]