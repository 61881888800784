import SizeBox from "@components/SizeBox";
import SpeedDialComponent, { SPEDIAL } from "@components/common/SpeedDialComponent";
import VocabMobileComponent from "@components/vocab/VocabMobileComponent";
import VocabWebComponent from "@components/vocab/VocabWebComponent";
import { Minna } from "@consts";
import VocabScreenLayout from "@layouts/home/VocabScreenLayout";
import { memo, useEffect, useState } from "react";
import Test from "../../../tester";
import { BottomSpace, TopSpace } from "@consts/layout";
const VocabScreen = ({ ScoreType,getScore, setScores,showModel,setShowModel,setUnlockPremium, name, Logger, Helper,dispatch, setName, Routes,routeHistory, useNavigate, useLocation,setRouteData,lang,fontSize,dark, furigana, toggleFuri }: any) => {
    // Speed Dial
    const [showVocab, setShowVocab] = useState(true);
    const [showTranslate, setShowTranslate] = useState(true);
    const [onShuffle, setOnShuffle] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    // Speed Dial

    // const log = Logger(13);
    const location = useLocation();
    const navigate = useNavigate();
    const { lesson, id } = location.state;
    Test.vocab.keyCheck(Minna.vocab[id]);
    const data = onShuffle ? Helper.shuffle(Minna.vocab[id]) : Minna.vocab[id];

    const nabs = [
        {
            title: "home",
            route: Routes.HOME,
        },
        {
            title: "Category",
            route: Routes.CATEGORY,
            state: {id, lesson}
        },
        {
            title: "Vocabulary",
            route: null
        }
    ];
    // kanji PopOver Detail
    const kanjiDetail = (kanjiId: any) => {
        const state = { lesson, id,name: "Vocab", path: location.pathname, kanjiId };
        navigate(Routes.KANJI_DETAIL, { state });
    }
    //  spedial action
    const scoreIncrementAction = ()=>{
        const currentScore = getScore({id,key:ScoreType.VOCAB});
        setScores({id,key:ScoreType.VOCAB,value:currentScore+1});
    }
    const showVocabAction = (showVocab:boolean)=>{
        setShowVocab(showVocab);
        scoreIncrementAction();
    }

    const showTranAction = (showTran:boolean)=>{
        setShowTranslate(showTran);
        scoreIncrementAction();
    }
    const shuffleAction = (shuffle:boolean)=>{
        setOnShuffle(shuffle);
        scoreIncrementAction();
    }
    const toggleFuriAction = ()=>{
        toggleFuri();
        scoreIncrementAction();
    }
    //  spedial action
    useEffect(() => {
        if(routeHistory.home.route != Routes.VOCAB){
        setRouteData({home:{
            state: { lesson, id },
            route: Routes.VOCAB
        }})
    }
    },[])
    return ( 
        <>
            <VocabScreenLayout
                body={
                    <div onClick={isHovering ? () => setIsHovering(false) : () => { }}>
                        <TopSpace/>
                        {/* Desktop View */}
                        <VocabWebComponent
                            nabs={nabs}
                            data={data}
                            furigana={furigana}
                            showVocab={showVocab}
                            furiTranslate={Helper.furiTranslate}
                            fontSize={fontSize}
                            showTranslate={showTranslate}
                            lang={lang}
                            dark={dark}
                            onShuffle={onShuffle}
                            lesson={lesson}
                            kanjiDetail={kanjiDetail}
                        />
                        {/* Mobile View */}
                        <VocabMobileComponent
                            nabs={nabs}
                            data={data}
                            furigana={furigana}
                            showVocab={showVocab}
                            furiTranslate={Helper.furiTranslate}
                            fontSize={fontSize}
                            showTranslate={showTranslate}
                            lang={lang}
                            dark={dark}
                            onShuffle={onShuffle}
                            lesson={lesson}
                            kanjiDetail={kanjiDetail}
                        />
                        <BottomSpace/>

                        <SpeedDialComponent
                            type={SPEDIAL.SHOW_ALL}
                            showVocab={showVocab}
                            setShowVocab={showVocabAction}
                            showTranslate={showTranslate}
                            setShowTranslate={showTranAction}
                            dark={dark}
                            furigana={furigana}
                            toggleFuri={toggleFuriAction}
                            onShuffle={onShuffle}
                            setOnShuffle={shuffleAction}
                            isHovering={isHovering}
                            setIsHovering={setIsHovering}
                        />
                    </div>
                }
                Routes={Routes}
                Logger={Logger}
                useNavigate={useNavigate}
                showModel={showModel}
                setShowModel={setShowModel}
                setUnlockPremium={setUnlockPremium}
            />
        </>
 )
}
export default memo(VocabScreen);