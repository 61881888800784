import Icon from "@consts/icon";

export const lesson38 = {
    title: {
        furi: '＾片（かた）｜づけるのが　｜＾好（す）｜きなんです',
        normal: '片づけるのが　好きなんです',
        en: 'I like tidying up',
        mm: 'သပ်ရပ်အောင် လုပ်ရတာကို ကြိုက်တယ်',
    },
    conversation: [
        {
            person: {
                furi: '大（だい）学（がく）職（しょく）員（いん）',
                normal: '大学職員',
                avatar: Icon.Kaiwa.STAFF
            },
            sentence: [
                {
                    furi: 'ワット｜＾先（せん）生（せい）｜、｜＾回（かい）覧（らん）｜です。',
                    normal: 'ワット先生、回覧です。',
                    en: 'Mr. Watt, this is incoming message',
                    mm: 'ဆရာဝတို ဝင်စာပါ',
                },
            ],
        },
        {
            person: {
                furi: 'ワット',
                normal: 'ワット',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'あ、すみません。そこに　｜＾置（お）｜いといて　ください。',
                    normal: 'あ、すみません。そこに　置いといて　ください。',
                    en:"Oh, I'm sorry. Please leave it there.",
                    mm: 'တစ်ဆိတ်လောက်ပါ၊ အဲ့ဒီမှာ ထားထားပေးပါ',
                },
            ],
        },
        {
            person: {
                furi: '大（だい）学（がく）職（しょく）員（いん）',
                normal: '大学職員',
                avatar: Icon.Kaiwa.STAFF
            },
            sentence: [
                {
                    furi: '＾先（せん）生（せい）｜の　｜＾研（けん）究（きゅう）室（しつ）｜は　いつも　きれいですね。',
                    normal: '先生の　研究室は　いつも　きれいですね。',
                    en: 'Your laboratory is always clean.',
                    mm: 'ဆရာ့ဓါတ်ခွဲခန်းက အမြဲသန့်ရှင်းနေတယ်နော်',
                },
            ],
        },
        {
            person: {
                furi: 'ワット',
                normal: 'ワット',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'わたしは　｜＾片（かた）｜づけるのが　｜＾好（す）｜きなんです。',
                    normal: 'わたしは　片づけるのが　好きなんです。',
                    en: 'I like tidying up.',
                    mm: 'ကျနော်က သပ်သပ်ရပ်ရပ်လုပ်ရတာကို သဘောကျတယ်',
                },
            ],
        },
        {
            person: {
                furi: '大（だい）学（がく）職（しょく）員（いん）',
                normal: '大学職員',
                avatar: Icon.Kaiwa.STAFF
            },
            sentence: [
                {
                    furi: '＾本（ほん）｜も　きちんと　｜＾並（なら）｜べて　あるし。。。',
                    normal: '本も　きちんと　並べて　あるし。。。',
                    en: 'The books are also arranged neatly. . .',
                    mm: 'စာအုပ်တွေလည်း သေသေသပ်သပ် စီရီထားထားတယ် . .',
                },
                {
                    furi: '＾整（せい）理（り）｜するのが　｜＾上（じょう）手（ず）｜なんですね。',
                    normal: '整理するのが　上手なんですね。',
                    en: "You're good at organizing",
                    mm: 'စီစီရီရီလုပ်ထားတာ တော်လိုက်တာနော်',
                },
            ],
        },
        {
            person: {
                furi: 'ワット',
                normal: 'ワット',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '＾昔（むかし）｜「｜＾上（じょう）手（ず）｜な　｜＾整（せい）理（り）｜の　｜＾方（ほう）法（ほう）｜」と　いう　｜＾本（ほん）｜を　｜＾書（か）｜いた　ことが　あるんです。',
                    normal: '昔「上手な　整理の　方法」と　いう　本を　書いた　ことが　あるんです。',
                    en: 'Once upon a time, I wrote a book called "How to organize well".',
                    mm: 'အရင်က "ကျွမ်းကျင်စွာ စီရီနည်း" ဆိုတဲ့စာအုပ်တစ်အုပ် ရေးခဲ့တယ်။',
                },
            ],
        },
        {
            person: {
                furi: '大（だい）学（がく）職（しょく）員（いん）',
                normal: '大学職員',
                avatar: Icon.Kaiwa.STAFF
            },
            sentence: [
                {
                    furi: 'へえ、すごいですね。',
                    normal: 'へえ、すごいですね。',
                    en: "Huh, that's amazing.",
                    mm: 'ဟယ် တော်လိုက်တာ',
                },
            ],
        },
        {
            person: {
                furi: 'ワット',
                normal: 'ワット',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'あまり　｜＾売（う）｜れませんでしたけどね。',
                    normal: 'あまり　売れませんでしたけどね。',
                    en: "It didn't sell very well, though.",
                    mm: 'သိပ်ပြီး ရောင်းမကောင်းခဲ့ဘူး',
                },
                {
                    furi: 'よかったら、１｜＾冊（さつ）｜　｜＾持（も）｜って　｜＾来（き）｜ましょうか。',
                    normal: 'よかったら、１冊　持って　来ましょうか。',
                    en: "If you don't mind, can I bring one?",
                    mm: 'အဆင်ပြေရင် တစ်အုပ်ယူလာရမလား?',
                },
            ],
        },
        {
            person: {
                furi: '大（だい）学（がく）職（しょく）員（いん）',
                normal: '大学職員',
                avatar: Icon.Kaiwa.STAFF
            },
            sentence: [
                {
                    furi: 'おはよう　ございます。',
                    normal: 'おはよう　ございます。',
                    en: 'good morning.',
                    mm: 'မင်္ဂလာနံနက်ခင်းပါ',
                },
            ],
        },
        {
            person: {
                furi: 'ワット',
                normal: 'ワット',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'あ、｜＾本（ほん）｜を　｜＾持（も）｜って　｜＾来（く）｜るのを　｜＾忘（わす）｜れました。すみません。',
                    normal: 'あ、本を　持って　来るのを　忘れました。すみません。',
                    en: 'Oh, I forgot to bring the book. I am sorry.',
                    mm: 'အား၊ စာအုပ်ယူလာဖို့ကို မေ့သွားတယ်၊ တောင်းပန်ပါတယ်။',
                },
            ],
        },
        {
            person: {
                furi: '大（だい）学（がく）職（しょく）員（いん）',
                normal: '大学職員',
                avatar: Icon.Kaiwa.STAFF
            },
            sentence: [
                {
                    furi: 'いいですよ。',
                    normal: 'いいですよ。',
                    en: 'OK.',
                    mm: 'ရပါတယ်',
                },
                {
                    furi: 'でも、｜＾回（かい）覧（らん）｜に　はんこを　｜＾押（お）｜すのを　｜＾忘（わす）｜れないで　ください。',
                    normal: 'でも、回覧に　はんこを　押すのを　忘れないで　ください。',
                    en: "But don't forget to stamp the incoming message.",
                    mm: 'ဒါပေမယ့် ဝင်စာကို တံဆိပ်တုံးထုဖို့မမေ့ပါနဲ့',
                },
                {
                    furi: '＾先（せん）月（げつ）｜も　｜＾押（お）｜して　ありませんでしたよ。',
                    normal: '先月も　押して　ありませんでしたよ。',
                    en: "You didn't press it last month either.",
                    mm: 'အရင်လကလည်း တံဆိပ်မထုထားဘူး။',
                },
            ],
        }
    ],
    audioUrl: require("@assets/audio/lesson38/kaiwa/4N38KW.mp3")
}

/*
{
    title: {
        furi: '',
        normal: '',
        en: '',
        mm: '',
    },
    conversation: [
        {
            person: {
                furi: '',
                normal: '',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '',
                    normal: '',
                    en: '',
                    mm: '',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson36/kaiwa/4N36KW.mp3")
}
*/