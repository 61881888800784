export const bunkei= [
    {
        sentence: [
            {
                furi: "＾将（しょう）来（らい）｜　｜＾自（じ）分（ぶん）｜の　｜＾店（みせ）｜を　｜＾持（も）｜つ　ために、｜＾貯（ちょ）金（きん）｜して　います。",
                normal: "将来　自分の　店を　持つ　ために、貯金して　います。",
                en: "I'm saving up to have my own store in the future.",
                mm: "အနာဂတ်မှာ ကိုယ်ပိုင်ဆိုင်ရှိဖို့ ငွေစုဆောင်းနေပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson42/bunpou/4N42BK1.mp3")
    },
    {
        sentence: [
            {
                furi: "この　｜＾靴（くつ）｜は　｜＾山（やま）｜を　｜＾歩（ある）｜くのに　いいです。",
                normal: "この　靴は　山を　歩くのに　いいです。",
                en: "These shoes are good for walking in the mountains.",
                mm: "ဒီဖိနပ်တွေက တောင်ပေါ်မှာ လမ်းလျှောက်လို့ကောင်းတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson42/bunpou/4N42BK2.mp3")
    },
]