export const renshuuA = [
    {
        sentence: [
            {
                furi: "＾日（に）本（ほん）語（ご）｜が　｜！はなせる｜　ように、　｜＾毎（まい）日（にち）｜　｜＾練（れん）習（しゅう）｜します。",
                normal: "日本語が　はなせる　ように、　毎日　練習します。",
                en: "I practice every day so that I can speak Japanese.",
                mm: "ဂျပန်စကားပြောတတ်အောင် နေ့တိုင်းလေ့ကျင့်တယ်။"
            },
            {
                furi: "＾会（かい）議（ぎ）｜に　｜！まにあう｜　ように、タクシーで　｜＾行（い）｜きます。",
                normal: "会議に　まにあう　ように、タクシーで　行きます。",
                en: "I will take a taxi so that I can be on time for the meeting.",
                mm: "အစည်းအဝေး အချိန်မီနိုင်အောင် တက္ကစီ စီးမယ်။"
            },
            {
                furi: "ラッシュに　｜！あわない｜　ように、｜＾早（はや）｜く　うちを　｜＾出（で）｜ます。",
                normal: "ラッシュに　あわない　ように、早く　うちを　出ます。",
                en: "I will eave home early to avoid rush hour.",
                mm: "အလုအယက်အချိန်မဖြစ်အောင် အိမ်က‌ စောစောထွက်မယ်။"
            },
            {
                furi: "＾子（こ）｜どもが　｜！さわらない｜　ように、はさみを　しまって　おきます。",
                normal: "子どもが　さわらない　ように、はさみを　しまって　おきます。",
                en: "Keep the scissors away from children.",
                mm: "ကတ်ကြေးတွေကို ကလေးတွေနဲ့ ဝေးဝေးမှာထားပါ။"
            },
        ],
        audioUrl: require("@assets/audio/lesson36/renshua/4N36RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "テレビの　｜＾日（に）本（ほん）語（ご）｜が　かなり　｜！わかる｜　ように　なりました。",
                normal: "テレビの　日本語が　かなり　わかる　ように　なりました。",
                en: "I have become able to understand Japanese on TV quite well.",
                mm: "တီဗီထဲက ဂျပန်စာကို ကောင်းကောင်းနားလည်လာတယ်။"
            },
            {
                furi: "＾日（に）本（ほん）語（ご）｜で　｜＾自（じ）分（ぶん）｜の　｜＾意（い）見（けん）｜が　｜！いえる｜　ように　なりました。",
                normal: "日本語で　自分の　意見が　いえる　ように　なりました。",
                en: "I can now express my opinion in Japanese.",
                mm: "ယခု ကျွန်ုပ်၏ ထင်မြင်ချက်ကို ဂျပန်ဘာသာဖြင့် ဖော်ပြနိုင်ပါပြီ။"
            },
            {
                furi: "インターネットで　｜＾何（なん）｜でも　｜！しらべられる｜　ように　なりました。",
                normal: "インターネットで　何でも　しらべられる　ように　なりました。",
                en: "You can now look up anything on the Internet.",
                mm: "ယခုသင်အင်တာနက်ပေါ်တွင်မည်သည့်အရာကိုမဆိုရှာဖွေနိုင်ပါပြီ။"
            },
            {
                furi: "コンビニで　いろいろな　ことが　｜！できる｜　ように　なりました。",
                normal: "コンビニで　いろいろな　ことが　できる　ように　なりました。",
                en: "You can now do various things at convenience stores.",
                mm: "ယခု စတိုးဆိုင်များတွင် မျိုးစုံလုပ်နိုင်ပါတယ်။"
            },
        ],
        audioUrl: require("@assets/audio/lesson36/renshua/4N36RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "できるだけ　１０時までに　うちへ　｜！かえる｜　ように　して　います。",
                normal: "できるだけ　１０時までに　うちへ　かえる　ように　して　います。",
                en: "I try to be back home by 10 o'clock as much as possible.",
                mm: "တတ်နိုင်သမျှ ၁၀ နာရီမှာ အိမ်ပြန်ဖို့ ကြိုးစား‌နေတယ်။"
            },
            {
                furi: "できるだけ　｜＾子（こ）｜どもと　｜！あそぶ｜　ように　して　います。",
                normal: "できるだけ　子どもと　あそぶ　ように　して　います。",
                en: "I try to play with my children as much as possible.",
                mm: "တတ်နိုင်သမျှ ကလေးတွေနဲ့ ကစားဖို့ ကြိုးစားနေတယ်။"
            },
            {
                furi: "できるだけ　スポーツクラブは　｜！やすまない｜　ように　して　います。",
                normal: "できるだけ　スポーツクラブは　やすまない　ように　して　います。",
                en: "I try not to miss sports clubs as much as possible.",
                mm: "အားကစားကလပ်တွေကို အတတ်နိုင်ဆုံး လက်လွတ်မခံဖို့ ကြိုးစားနေပါတယ်။"
            },
            {
                furi: "できるだけ　｜！ざんぎょうしない｜　ように　して　います。",
                normal: "できるだけ　ざんぎょうしない　ように　して　います。",
                en: "I try not to work overtime as much as possible.",
                mm: "တတ်နိုင်သမျှ အချိန်ပိုအလုပ် မလုပ်မိဖို့ ကြိုးစားနေတယ်။"
            },
        ],
        audioUrl: require("@assets/audio/lesson36/renshua/4N36RSA3.mp3")
    },
    {
        sentence: [
            {
                furi: "もっと　｜＾野（や）菜（さい）｜を　｜！たべる｜　ように　して　ください。",
                normal: "もっと　野菜を　たべる　ように　して　ください。",
                en: "Please try to eat more vegetables.",
                mm: "အသီးအရွက်များများစားဖို့ ကြိုးစားပေးပါ။"
            },
            {
                furi: "＾休（やす）｜む　ときは、　｜＾必（かなら）｜ず　｜！れんらくする｜　ように　して　ください。",
                normal: "休む　ときは、　必ず　れんらくする　ように　して　ください。",
                en: "Please be sure to let me know when you will be resting.",
                mm: "အနားယူရမယ့်အချိန်ကို သေချာပြောပြပေးပါ။"
            },
            {
                furi: "＾絶（ぜっ）対（たい）｜に　パスポートを　｜！なくさない｜　ように　して　ください。",
                normal: "絶対に　パスポートを　なくさない　ように　して　ください。",
                en: "Please do not lose your passport.",
                mm: "ကျေးဇူးပြုပြီး သင့်ပတ်စပို့ကို မ‌ပျောက်ပါစေနှင့်။",
            },
            {
                furi: "＾時（じ）間（かん）｜を　｜！まちがえない｜　ように　して　ください。",
                normal: "時間を　まちがえない　ように　して　ください。",
                en: "Please be careful not to get the time wrong.",
                mm: "အချိန်မမှားစေရန် သတိထားပါ။"
            },
        ],
        audioUrl: require("@assets/audio/lesson36/renshua/4N36RSA4.mp3")
    },
];

/*
    {
        sentence: [
            {
                furi: "",
                normal: "",
                en: "",
                mm: "",
            },
        ],
        audioUrl: require("@assets/audio/testing.mp3")
    },

 // furi: 'あしたから　｜！旅（りょ）行（こう）なん｜です。',
        // normal: 'あしたから　旅行　なんです。',
        // en: 'It\'s a trip from tomorrow.',
        // mm: 'မနက်ဖြန်ကနေစပြီး ခရီးထွက်ပါမယ်။',
        //


*/