
export const lesson13 = [
    {"lesson":13,"level":4,"question":"民族","represent":["民","族"],"answer":["けいしき","どうい","かおいろ","みんぞく"],"true":"みんぞく","times":10},
    {"lesson":13,"level":4,"question":"形式","represent":["形"],"answer":["せいかつ","わふく","みんぞく","けいしき"],"true":"けいしき","times":10},
    {"lesson":13,"level":4,"question":"食料品","represent":["食","料","品"],"answer":["かつようがた","みんぞくがく","しょくりょうひん","にほんけん"],"true":"しょくりょうひん","times":10},
    {"lesson":13,"level":4,"question":"民族学","represent":["民","族","学"],"answer":["しょくりょうひん","みんぞくがく","にほんけん","かつようがた"],"true":"みんぞくがく","times":10},
    {"lesson":13,"level":4,"question":"活字","represent":["活","字"],"answer":["にんぎょう","しゅじん","かつじ","こいぬ"],"true":"かつじ","times":10},
    {"lesson":13,"level":4,"question":"同意","represent":["同","意"],"answer":["かつどう","どうい","こいぬ","かっぱつ"],"true":"どうい","times":10},
    {"lesson":13,"level":4,"question":"活発","represent":["活","発"],"answer":["かっぱつ","ようふく","こいぬ","せいかつ"],"true":"かっぱつ","times":10},
    {"lesson":13,"level":4,"question":"活用形","represent":["活","用","形"],"answer":["かつようがた","しょくりょうひん","にほんけん","みんぞくがく"],"true":"かつようがた","times":10},
    {"lesson":13,"level":4,"question":"品物","represent":["品","物"],"answer":["わふく","かっぱつ","けいしき","しなもの"],"true":"しなもの","times":10},
    {"lesson":13,"level":4,"question":"顔色","represent":["顔","色"],"answer":["かおいろ","みんぞく","しゅしん","どうい"],"true":"かおいろ","times":10},
    {"lesson":13,"level":4,"question":"商品","represent":["品"],"answer":["しょうひん","かつどう","かつじ","みんぞく"],"true":"しょうひん","times":10},
    {"lesson":13,"level":4,"question":"人形","represent":["人","形"],"answer":["しなもの","みんぞく","にんぎょう","かおいろ"],"true":"にんぎょう","times":10},
    {"lesson":13,"level":4,"question":"活動","represent":["活","動"],"answer":["かつどう","わふく","しょうひん","とくしょく"],"true":"かつどう","times":10},
    {"lesson":13,"level":4,"question":"和服","represent":["服"],"answer":["いっかい","わふく","みんぞく","とくしょく"],"true":"わふく","times":10},
    {"lesson":13,"level":4,"question":"特色","represent":["特","色"],"answer":["かつじ","しゅじん","わふく","とくしょく"],"true":"とくしょく","times":10},
    {"lesson":13,"level":4,"question":"日本犬","represent":["日","本","犬"],"answer":["かつようがた","にほんけん","しょくりょうひん","みんぞくがく"],"true":"にほんけん","times":10},
    {"lesson":13,"level":4,"question":"洋服","represent":["洋","服"],"answer":["せいかつ","とくしょく","けいしき","ようふく"],"true":"ようふく","times":10},
    {"lesson":13,"level":4,"question":"主人","represent":["主","人"],"answer":["しゅじん","しょうひん","けいしき","いっかい"],"true":"しゅじん","times":10},
    {"lesson":13,"level":4,"question":"一回","represent":["一","回"],"answer":["けいしき","しゅじん","いっかい","せいかつ"],"true":"いっかい","times":10},
    {"lesson":13,"level":4,"question":"生活","represent":["生","活"],"answer":["ようふく","かつどう","せいかつ","とくしょく"],"true":"せいかつ","times":10},
    {"lesson":13,"level":4,"question":"子犬","represent":["子","犬"],"answer":["こいぬ","せいかつ","かつどう","にんぎょう"],"true":"こいぬ","times":10},
]