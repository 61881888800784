export const lesson5 = {
    "映": 40,
    "画": 41,
    "音": 42,
    "楽": 43,
    "歌": 44,
    "写": 45,
    "真": 46,
    "旅": 47,
    "世": 48,
    "界": 49
};