import Helper from "@helper";

enum key {
    test = 'testPoint',
    dark = 'dark',
    fontSize = 'fontSize',
    lang = 'lang',
    furigana = 'furigana',
    score = 'minna_n4_scores',
    premium = 'premium'
}

const LocalStorage = {
    setPoint: ({ dispatch, setTestPoint, testPoint, key:pointKey, point }: any) => {
        let updatedTestPoint = Helper.deepClone(testPoint);
        updatedTestPoint[pointKey] = point;
        console.log(updatedTestPoint);
        dispatch(setTestPoint(updatedTestPoint));
        localStorage.setItem(key.test, JSON.stringify(updatedTestPoint));
    },
    getScore: () => {
        let score = localStorage.getItem(key.score);
        if (score) {
            return JSON.parse(score);
        } else {
            return false;
        }
    },
    setScore: ({ scores, dispatch, setScore }: any) => {
        localStorage.setItem(key.score, JSON.stringify(scores));
        dispatch(setScore(scores));
        
    },
    toggleTheme: ({ dark, dispatch, setDark }: any) => {
        const html = document.getElementById('html');
        if (dark === 'dark') {
            html!.setAttribute('class', 'light');
            localStorage.setItem(key.dark, 'light');
            dispatch(setDark('light'));
        } else {
            html!.setAttribute('class', 'dark');
            localStorage.setItem(key.dark, 'dark');
            dispatch(setDark('dark'));
        }

    },
    changeFontSize: ({ fontSize, dispatch, setFontSize }: any) => {
        localStorage.setItem(key.fontSize, `${fontSize}`);
        dispatch(setFontSize(fontSize));
        document.documentElement.style.setProperty(
            "--base-font-size",
            `${fontSize}px`,
        );
    },
    changeLanguage: ({ lang, dispatch, setLang }: any) => {
        if (lang === 'en') {
            localStorage.setItem(key.lang, lang); 
            dispatch(setLang('en'));
        } else{
            localStorage.setItem(key.lang, lang);
            dispatch(setLang('mm'));            
        } 
    },
    toggleFuri: ({ furigana, dispatch, setFurigana }: any) => {
        if (furigana === "true") {
            localStorage.setItem(key.furigana, "false");
            dispatch(setFurigana("false"));
        } else {
            localStorage.setItem(key.furigana, "true");
            dispatch(setFurigana("true"));
        }
    },
    setPremium:({unlockCode,dispatch,setPremium}:any)=>{
        localStorage.setItem(key.premium,unlockCode);
        dispatch(setPremium(true));
    }
}
export default LocalStorage;