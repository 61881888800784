export const reibun= [
    {
        sentence: [
            {
                furi: "それは　｜＾電（でん）子（し）辞（じ）書（しょ）｜ですか。",
                normal: "それは　電子辞書ですか。",
                en: "Is that an electronic dictionary?",
                mm: "အဲဒါ အီလက်ထရွန်းနစ်အဘိဓာန်လား။",
            },
            {
                furi: "ええ。｜＾知（し）｜らない　ことばが　あったら、すぐ　｜＾調（しら）｜べられるように、｜＾持（も）｜って　いるんです。",
                normal: "ええ。知らない　ことばが　あったら、すぐ　調べられるように、持って　いるんです。",
                en: "Yeah. If there is a word I don't know, I can look it up immediately.",
                mm: "အင်း။ မသိတဲ့ စကားလုံးရှိရင် ချက်ခြင်းရှာကြည့်လို့အောင် ဆောင်ထားတာပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson36/bunpou/4N36RB1.mp3")
    },
    {
        sentence: [
            {
                furi: "カレンダーの　｜＾赤（あか）｜い　｜＾丸（まる）｜は　どういう　｜＾意（い）味（み）｜ですか。",
                normal: "カレンダーの　赤い　丸は　どういう　意味ですか。",
                en: "What does the red circle on the calendar mean?",
                mm: "ပြက္ခဒိန်ပေါ်က အနီရောင် စက်ဝိုင်းက ဘာကို ဆိုလိုတာလဲ။",
            },
            {
                furi: "ごみの　｜＾日（ひ）｜です。｜＾忘（わす）｜れないように、｜＾付（つ）｜けて　あるんです。",
                normal: "ごみの　日です。忘れないように、付けて　あるんです。",
                en: "It's garbage day. I've attached it so that I don't forget.",
                mm: "အမှိုက်ပစ်ရမဲ့နေ့ပါ ။ မမေ့အောင် ဝိုင်းထားတာပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson36/bunpou/4N36RB2.mp3")
    },
    {
        sentence: [
            {
                furi: "もう　｜＾日（に）本（ほん）｜の　｜＾食（た）｜べ｜＾物（もの）｜に　｜＾慣（な）｜れましたか。",
                normal: "もう　日本の　食べ物に　慣れましたか。",
                en: "Have you gotten used to Japanese food yet?",
                mm: "ဂျပန်အစားအစာနဲ့ နေသားကျနေပြီလား။",
            },
            {
                furi: "はい。｜＾初（はじ）｜めは　｜＾食（た）｜べられませんでしたが、｜＾今（いま）｜は　｜＾何（なん）｜でも　｜＾食（た）｜べられるように　なりました。",
                normal: "はい。初めは　食べられませんでしたが、今は　何でも　食べられるように　なりました。",
                en: "yes. At first I couldn't eat, but now I can eat anything.",
                mm: "ဟုတ်တယ် အစတုန်းကတော့ မစားနိုင်ပေမယ့် အခုတော့ ဘာမဆို စားနိုင်တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson36/bunpou/4N36RB3.mp3")
    },
    {
        sentence: [
            {
                furi: "ショパンの　｜＾曲（きょく）｜が　｜＾弾（ひ）｜けるように　なりましたか。",
                normal: "ショパンの　曲が　弾けるように　なりましたか。",
                en: "Have you learned to play Chopin's music?",
                mm: "Chopin ရဲ့ တေးဂီတကို တီးခတ်တတ်ပြီလား။",
            },
            {
                furi: "いいえ、まだ　｜＾弾（ひ）｜けません。｜＾早（はや）｜く　｜＾弾（ひ）｜けるように　なりたいです。",
                normal: "いいえ、まだ　弾けません。早く　弾けるように　なりたいです。",
                en: "No, I can't play it yet. I want to be able to play fast.",
                mm: "မဟုတ်ဘူး၊ ငါမတီးနိုင်သေးဘူး။ မြန်မြန်တီးတတ်ချင်တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson36/bunpou/4N36RB4.mp3")
    },
    {
        sentence: [
            {
                furi: "＾新（あたら）｜しい　｜＾道（みち）｜が　できましたね。",
                normal: "新しい　道が　できましたね。",
                en: "A new path has been created.",
                mm: "လမ်းအသစ်တောင် ပြီးပြီဘဲ။",
            },
            {
                furi: "ええ。｜＾夫（おっと）｜の　｜＾田（い）舎（なか）｜まで　４｜＾時（じ）間（かん）｜で　｜＾帰（かえ）｜れるように　なりました。",
                normal: "ええ。夫の　田舎まで　４時間で　帰れるように　なりました。",
                en: "Yeah. I am now able to return to my husband's countryside in four hours.",
                mm: "အင်း။ အခု လေးနာရီအတွင်း ကျွန်မခင်ပွန်းရဲ့ ကျေးလက်တောရွာကို ပြန်နိုင်ပါပြီ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson36/bunpou/4N36RB5.mp3")
    },
    {
        sentence: [
            {
                furi: "＾甘（あま）｜い　｜＾物（もの）｜は　｜＾食（た）｜べないんですか。",
                normal: "甘い　物は　食べないんですか。",
                en: "Don't you eat sweets?",
                mm: "အချိုတွေမစားဘူးလား",
            },
            {
                furi: "ええ。できるだけ　｜＾食（た）｜べないように　して　いるんです。",
                normal: "ええ。できるだけ　食べないように　して　いるんです。",
                en: "Yeah. I am trying not to eat as much as possible.",
                mm: "အင်း။ တတ်နိုင်သမျှ မစားမိဖို့ ကြိုးစားနေတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson36/bunpou/4N36RB6.mp3")
    },
    {
        sentence: [
            {
                furi: "＾試（し）験（けん）｜は　９｜＾時（じ）｜からです。｜＾絶（せっ）対（たい）｜に　｜＾遅（おく）｜れないように　して　ください。",
                normal: "試験は　９時からです。絶対に　遅れないように　して　ください。",
                en: "The exam starts at 9:00. Please don't be late for him.",
                mm: "စာမေးပွဲက ၉း၀၀မှာ စမှာပါ ။ ကျေးဇူးပြုပြီး နောက်မကျပါနဲ့။",
            },
            {
                furi: "＾遅（おく）｜れたら、｜＾入（はい）｜れませんから。",
                normal: "遅れたら、入れませんから。",
                en: "If you're late, you won't be able to enter.",
                mm: "နောက်ကျရင် ဝင်လို့မရတော့ဘူး။",
            },
            {
                furi: "はい、わかりました。",
                normal: "はい、わかりました。",
                en: "Yes, I understand.",
                mm: "ဟုတ်ကဲ့, နားလည်ပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson36/bunpou/4N36RB7.mp3")
    },
]