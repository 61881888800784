import TestScreenLayout from "@layouts/test/TestScreenLayout";
import { memo, useState } from "react";
// import { ScoreType } from "../App";

// data convert only
import { ScreenPropType } from "../App";
import { Card } from "@material-tailwind/react";
import SizeBox from "@components/SizeBox";
import MondaiTwoComponent from "@components/exercise/MondaiTwoComponent";
import MondaiOneComponent from "@components/exercise/MondaiOneComponent";
import CategoryScreenLayout from "@layouts/home/CategoryScreenLayout";

const TestingScreen = ({showModel,setShowModel, Minna,Helper, getScore,ScoreType,scores,setScores, Logger,furigana, name, dispatch, setName, useLocation,fontSize, createSearchParams, Routes, useNavigate, toggleTheme, changeFontSize, dark }: ScreenPropType) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [mondai1,setMondai1] = useState<any>(Helper.deepClone(Minna.exercises[0].mondai1));
    const [mondai2, setMondai2] = useState<any>(Helper.deepClone(Minna.exercises[0].mondai2));
    
    const pickAnswer = ({ questionId, boxId, ans }: { questionId: number, boxId: number, ans: string }) => {        
        setMondai1(
            mondai1.map(({question,picked,answers,correct}:any,index: number)=>{
                if(index===questionId){
                    // picked = ["が",""],
                    picked[boxId] = ans;
                    return { question, picked, answers ,correct};
                }else{
                    return {question,picked,answers,correct};
                }

            }),
           
        );

    }
    const pickAnswer2 = ({ questionId, answer }: { questionId: number, answer: string }) => {
        setMondai2(
            mondai2.map(({ question, picked, answers, correct }: any, index: number) => {
                if (index === questionId) {
                    return { question, picked:answer, answers, correct };
                } else {
                    return { question, picked, answers, correct };
                }
            })
        );
    }
    return ( 
        <TestScreenLayout
            body={<div className="container mx-auto">
                <MondaiOneComponent
                    mondai1={mondai1}
                    fontSize={fontSize} 
                    pickAnswer={pickAnswer}
                    Helper={Helper}
                    underline={Helper.underline}
                    furigana={furigana}
                    furiTranslate={Helper.furiTranslate}
                />
                <MondaiTwoComponent
                    mondai2={mondai2}
                    pickAnswer2={pickAnswer2}
                    underline={Helper.underline}
                    furigana={furigana}
                    furiTranslate={Helper.furiTranslate}
                    fontSize={fontSize}
                />
                </div>}
            Routes={Routes}
            Logger={Logger}
            useNavigate={useNavigate}
            showModel={showModel}
            setShowModel={setShowModel}
        />

    )
}
export default memo(TestingScreen);


