import { BunpouType } from "..";

export const bunpou: BunpouType = {
    mondai1:{
        title: 'もんだい１（　　）に　何を　入れますか。１・２・３・４から　いちばん　いい　ものを　ーつ　えらんで　ください。',
        questions: [
          {
            number: 1,
            question: [
              'きのうの　しゅくだいは　少なかったので（　　）終わりました。',
            ],
            answers: ['２０分','２０分しか','２０分で','２０分を'],
            pick: '',
            correct: '２０分で'
          },
          {
            number: 2,
            question: [
              'この　ロボットは　人（　　）　会話することが　できます。'
            ],
            answers: ['や','を','へ','と'],
            pick: '',
            correct: 'と'
          },
          {
            number: 3,
            question: [
              '弟は　小さいき　よくけがを　して、両親（　　）　心配させました。'
            ],
            answers: ['で','を','の','や'],
            pick: '',
            correct: 'を'
          },
          {
            number: 4,
            question: [
              'ホテルの　朝ご飯の　パンが　とても　おいしかったので、　八つ（　　）食べました。'
            ],
            answers: ['も','に','が','で'],
            pick: '',
            correct: 'も'
          },
          {
            number: 5,
            question: [
              'この　日本語の　じしょは、１５０年前に　外国人（　　）　作られました。'
            ],
            answers: ['から','を','について','によって'],
            pick: '',
            correct: 'によって'
          },
          {
            number: 6,
            question: [
              'A市の　運動場は（　　）　使えますが、予約が　ひつようです。'
            ],
            answers: ['だれでも','だれを','だれに','だれが'],
            pick: '',
            correct: 'だれでも'
          },
          {
            number: 7,
            question: [
              '前田「リーさん、いつも（　　）国の　かぞくに　れんらくしますか。」',
              'リー「メールを　書くことが　多いです。」',
            ],
            answers: ['どのぐらい','どの','どうやって','どういう'],
            pick: '',
            correct: 'どうやって'
          },
          {
            number: 8,
            question: [
              '娘は　先月　高校を　卒業しました。（　　）　大学の　入学式です。'
            ],
            answers: ['だんだん','あまり','だいたい','もうすぐ'],
            pick: '',
            correct: 'もうすぐ'
          },
          {
            number: 9,
            question: [
              '今朝は　駅に　行く　バスが（　　）　来なかったので、タクシーで　行きました。'
            ],
            answers: ['やっと','なかなか','きっと','いつか'],
            pick: '',
            correct: 'なかなか'
          },
          {
            number: 10,
            question: [
              '山下「南さん、あしたか　あさって、カラオケに　行かない？」',
              '南「あ、いいね。あしたは　都合が　悪いけど、あさって　（　　）だいじょうぶだよ。」',
            ],
            answers: ['なのに','だから','でも','なら'],
            pick: '',
            correct: 'なら'
          },
          {
            number: 11,
            question: [
              '私は（　　）　間、スーパーで　アルバイトを　して　いました。'
            ],
            answers: ['夏休みに','夏休みで','夏休みの','夏休み'],
            pick: '',
            correct: '夏休みの'
          },
          {
            number: 12,
            question: [
              '先週　庭の　木の　えだを　切りました。ぜんぶ（　　）2時間　かかりました。'
            ],
            answers: ['切ったり','切るのに','切りに','切るか　どうか'],
            pick: '',
            correct: '切るのに'
          },
          {
            number: 13,
            question: [
              '木村「山田さん、あしたの　午後、サッカーの　練習に　行きますか。」',
              '山田「ええ、行きます。でも、午前中に　用事が　あるので、（　　）。」',
            ],
            answers: ['送れないでください','送れないほうがいいです','送れるかもしれません','送れてはいけません'],
            pick: '',
            correct: '送れるかもしれません'
          },
          {
            number: 14,
            question: [
              '（食堂で）',
              '森「空いて　いる　席が　ありませんね。」',
              '田中「ええ。あ、でも、あそこの　せきが（　　）よ。」',
              '森「本当ですね。空くまで、少し　待ちましょう。」',
            ],
            answers: ['空きそうです','空きました','空いて　います','空いたようです'],
            pick: '',
            correct: '空きそうです'
          },
          {
            number: 15,
            question: [
              '（会議室）',
              '林「上田さん、会議の　じゅんびは　終わりましたか。てつだいましょうか。」',
              '上田「ありがとうございます。じゃあ、いすが　一つ　足りないので、となりの　会議室から（　　）。」',
              '林「はい、わかりました。」',
            ],
            answers: ['持ってこなくてもいいですか','持って　きて　もらえ　ますか','持って　こないと　いけませんか','持って　きて　いまぜんか'],
            pick: '',
            correct: '持って　きて　もらえ　ますか'
          },
        ]
      },
    mondai2:{
      title: 'もんだい（2）｜！ ★ ｜ に　何を　入れますか。１・２・３・４から　いちばん　いい　ものを　ーつ　えらんで　ください。',
      questions: [
        {
          number: 16,
          question: [
            '先月まで　花屋が　あった｜！＿＿★＿｜、おいしいです。',
          ],
          answers: ['できた','りんごの　ケーキが','きっさてんは','場所に'],
          pick: '',
          correct: 'きっさてんは'
        },
        {
          number: 17,
          question: [
            'きのうの　夜　家に　帰ってから、かぎを｜！＿＿★＿｜覚えて　いません。',
          ],
          answers: ['どこ','置いた','に','か'],
          pick: '',
          correct: '置いた'
        },
        {
          number: 18,
          question: [
            '私は　ピアノを｜！＿＿★＿｜時間が　ありません。',
          ],
          answers: ['ひくのが','ひく','最近　いそがしくて','好きですが'],
          pick: '',
          correct: '最近　いそがしくて'
        },
        {
          number: 19,
          question: [
            '私は　２０さいの　たんじょうびに　そふが｜！＿＿★＿｜います。',
          ],
          answers: ['大切に','くれた','使って','カメラを'],
          pick: '',
          correct: '大切に'
        },
        {
          number: 20,
          question: [
            '林「来週、　野球の　試合を　見に　行こうと　思って　いるんですが、リーさんも　いっしょに　どうですか。」',
            'リー「えっ、野球の　試合ですか。いいですね。｜！＿＿★＿｜です。」',
          ],
          answers: ['ぜひ　行きたい','ことが　ない','見に行った','ので'],
          pick: '',
          correct: 'ので'
        },
      ]
    }
}