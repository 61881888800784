export const explain= [
    { 
        // pattern_furi: '＾命（めい）令（れい）形（けい）｜/｜＾禁（きん）止（し）形（けい）｜',
        pattern_furi: '＾命（めい）令（れい）形（けい）｜/｜＾禁（きん）止（し）形（けい）',
        pattern_normal: '命令形/禁止形',
        en: [
            '　Used in giving orders, prohibitions.',
            '　Teacher speaking orders to students. It is used when speaking and giving orders from parents to their children. It can also be used to cheer at sports events. factory It is also used in organizations as a form of command.',
        ],
        mm: [
            '　အမိန့်ပေးခြင်း၊ တားမြစ်ခြင်းများတွင် သုံးသည်။',
            '　ဆရာမှ ကျောင်းသားသို့ ပြောဆိုအမိန့်ပေးခြင်း။ မိဘမှ သားသမီးသို့ ပြောဆို အမိန့်ပေးရာတွင် သုံးသည်။ အားကစားပွဲများတွင် အားပေးရာတွင်လည်းသုံးနိုင်သည်။ စက်ရုံ၊ အဖွဲ့အစည်းများတွင် အမိန့်ပေးပုံစံမျိုး ဖြင့်လည်းသုံးသည်။',
        ]
    },
    {
        pattern_furi: 'Vるな',
        pattern_normal: 'Vるな',
        en: [
            '　A slightly harsh order; It is a warning form.',
            '　Not normally used.',
            '　Used only when angry.',
        ],
        mm: [
            '　အနည်းငယ် ကြမ်းတမ်းသော အမိန့်ပေး၊ သတိပေးပုံစံဖြစ်သည်။',
            '　ပုံမှန်တွင် သုံးလေ့မရှိပါ။',
            '　စိတ်ဆိုး ရန်ဖြစ်သော အခါမျိုးတွင်သာ သုံးလေ့ရှိသည်။',
        ]
    },
    {
        pattern_furi: 'Vる/いAdj/なAdj/Nounという｜＾意（い）味（み）｜です',
        pattern_normal: 'Vる/いAdj/なAdj/Nounという意味です',
        en: [
            "　It is used when saying that it means that.",
        ],
        mm: [
            '　အဲဒီလို အဓိပ္ပါယ်ရပါတယ်လို့ ပြောသောအခါတွင် သုံးသည်။',
        ]
    },
    {
        pattern_furi: 'Vると｜＾言（い）｜っていました',
        pattern_normal: 'Vると言っていました',
        en: [
            "　It is used to convey what someone has said",
        ],
        mm: [
            '　တစ်ယောက်ယောက်က ပြောသောစကားကို တဆင့်ပြန်လည်ပြောရာတွင်သုံးသည်',
        ]
    },
    {
        pattern_furi: 'もう~ない',
        pattern_normal: 'もう~ない  ',
        en: [
            "　It is used in situations where you can't afford it and have to give up",
        ],
        mm: [
            '　ဘယ်လိုမှ မတတ်နိုင် လက်လျှော့လိုက်ရသော အခြေအနေများတွင် သုံးသည်',
        ]
    },

]