export const renshuuA = [
    {
        isTable: true,
        tables: [
            {
                head: {
                    a: {
                        val:"",
                        span: 1,
                        style: ""
                    },
                    b: {
                        val:"",
                        span: 3,
                        style: ""
                    },
                    c: {
                        val: "＾受（うけ）身（み）",
                        span: 3,
                        style: ""
                    }
                },
                body: [
                    {
                        no: "I",
                        l1: "い",
                        l2: "い",
                        l3: "ます",
                        r1: "い",
                        r2: "わ",
                        r3:"れます"
                    },              
                    {
                        no: "",
                        l1: "か",
                        l2: "き",
                        l3: "ます",
                        r1: "か",
                        r2: "か",
                        r3:"れます"
                    },              
                    {
                        no: "",
                        l1: "お",
                        l2: "し",
                        l3: "ます",
                        r1: "お",
                        r2: "さ",
                        r3:"れます"
                    },              
                    {
                        no: "",
                        l1: "ま",
                        l2: "ち",
                        l3: "ます",
                        r1: "ま",
                        r2: "た",
                        r3:"れます"
                    },              
                    {
                        no: "",
                        l1: "よ",
                        l2: "び",
                        l3: "ます",
                        r1: "よ",
                        r2: "ば",
                        r3:"れます"
                    },              
                    {
                        no: "",
                        l1: "ふ",
                        l2: "み",
                        l3: "ます",
                        r1: "ふ",
                        r2: "ま",
                        r3:"れます"
                    },              
                    {
                        no: "",
                        l1: "と",
                        l2: "り",
                        l3: "ます",
                        r1: "と",
                        r2: "ら",
                        r3:"れます"
                    },              
                ],
                style: {
                    no: "p-2 w-[30px] text-right bg-white dark:bg-darkFooter",
                    l1: "p-2 text-right bg-white dark:bg-darkFooter",
                    l2: "p-2 w-[30px] text-right bg-[#729eff] dark:bg-[#003257]",
                    l3: "p-2 text-left bg-white dark:bg-darkFooter",
                    r1: "p-2 text-right bg-white dark:bg-darkFooter",
                    r2: "p-2 w-[30px] text-right bg-[#729eff] dark:bg-[#003257]",
                    r3: "p-2 text-left bg-white dark:bg-darkFooter"
                }
            },
            {
                head: {
                    a: {
                        val:"",
                        span: 1,
                        style: ""
                    },
                    b: {
                        val:"",
                        span: 2,
                        style: ""
                    },
                    c: {
                        val: "＾受（うけ）身（み）",
                        span: 2,
                        style: ""
                    }
                },
                body: [
                    {
                        no: "II",
                        l1: "ほめ",
                        l3: "ます",
                        r1: "ほめ",
                        r3:"られます"
                    },
                    {
                        no: "",
                        l1: "しらべ",
                        l3: "ます",
                        r1: "しらべ",
                        r3:"られます"
                    },                               
                    {
                        no: "",
                        l1: "み",
                        l3: "ます",
                        r1: "み",
                        r3:"られます"
                    },                               
                    {
                        no: "",
                        l1: "",
                        l3: "",
                        r1: "",
                        r3:""
                    },
                    {
                        no: "III",
                        l1: "き",
                        l3: "ます",
                        r1: "こ",
                        r3: "られます"
                    },   
                    {
                        no: "",
                        l1: "し",
                        l3: "ます",
                        r1: "さ",
                        r3: "れます"
                    },   
                ],
                style: {
                    no: "p-2 w-[50px] text-right bg-white dark:bg-darkFooter",
                    l1: "p-2 text-right bg-white dark:bg-darkFooter",
                    l3: "p-2 text-left bg-white dark:bg-darkFooter",
                    r1: "p-2 text-right bg-white dark:bg-darkFooter",
                    r3: "p-2 text-left bg-white dark:bg-darkFooter"
                }
            },
        ],
        audioUrl: require("@assets/audio/lesson37/renshua/4N37RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "わたしは　｜＾部（ぶ）長（ちょう）｜に　｜！ほめられました。",
                normal: "わたしは　部長に　ほめられました。",
                en: "I was praised by the manager.",
                mm: "ကျွန်တော် ဌာနမှူးချီးမွမ်းတာ ခံခဲ့ရတယ်။",
            },
            {
                furi: "わたしは　｜＾部（ぶ）長（ちょう）｜に　｜＾仕（し）事（ごと）｜を　｜！たのまれました。",
                normal: "わたしは　部長に　仕事を　たのまれました。",
                en: "I was asked to work by the manager.",
                mm: "မန်နေဂျာက အလုပ်ခိုင်းတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson37/renshua/4N37RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "わたしは　だれかに　じてんしゃを　｜！とられました。",
                normal: "わたしは　だれかに　じてんしゃを　とられました。",
                en: "Someone stole my bicycle.",
                mm: "တစ်စုံတစ်ယောက်က ကျွန်တော့်စက်ဘီးကို ခိုးသွားတယ် ။",
            },
            {
                furi: "わたしは　ははに　マンガのほん　を　｜！すてられました。",
                normal: "わたしは　ははに　マンガのほん　を　すてられました。",
                en: "My father throw away my comic book.",
                mm: "အဖေက ကျွန်မရဲ့ manga စာအုပ်ကို ပစ်လိုက်တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson37/renshua/4N37RSA3.mp3")
    },
    {
        sentence: [
            {
                furi: "＾大（おお）阪（さか）｜で　てんらんかいが　｜！ひらかれます。",
                normal: "大阪で　てんらんかいが　ひらかれます。",
                en: "Exhibition will be held in Osaka.",
                mm: "ပြပွဲကို အိုဆာကာတွင် ကျင်းပမည်ဖြစ်သည်။",
            },
            {
                furi: "＾大（おお）阪（さか）｜で　こくさいかいぎ　が　｜！おこなわれます。",
                normal: "大阪で　こくさいかいぎ　が　おこなわれます。",
                en: "International Conference will be held in Osaka.",
                mm: "အပြည်ပြည်ဆိုင်ရာညီလာခံကို အိုဆာကာတွင် ကျင်းပမည်ဖြစ်သည်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson37/renshua/4N37RSA4.mp3")
    },
    {
        sentence: [
            {
                furi: "この　｜＾美（び）術（じゅつ）館（かん）｜は　｜＾来（らい）月（がつ）｜　｜！こわされます。",
                normal: "この　美術館は　来月　こわされます。",
                en: "This museum will be demolished next month.",
                mm: "ဒီပြတိုက်ကို နောက်လမှာ ဖျက်ပစ်မယ်။",
            },
            {
                furi: "この　｜＾美（び）術（じゅつ）館（かん）｜は　100｜＾年（ねん）｜まえに、　｜！たてられました。",
                normal: "この　美術館は　100年まえに、　たてられました。",
                en: "This museum was built 100 years ago.",
                mm: "ဒီပြတိုက်ကို လွန်ခဲ့တဲ့ နှစ် 100 က တည်ဆောက်ခဲ့တာပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson37/renshua/4N37RSA5.mp3")
    },
    {
        sentence: [
            {
                furi: "にほんの　くるまは　いろいろな　｜＾国（くに）｜へ　｜！ゆしゅつされて｜　います。",
                normal: "にほんの　くるま　は　いろいろな　国へ　ゆしゅつされて　います。",
                en: "Japanese cars are exported to various countries.",
                mm: "ဂျပန်ကားများကို နိုင်ငံအသီးသီးသို့ တင်ပို့ရောင်းချကြသည်။",
            },
            {
                furi: "この　ほんは　｜＾世（せ）界（かい）中（じゅう）｜で　｜！よまれて｜　います。",
                normal: "この　ほんは　世界中で　よまれて　います。",
                en: "This book is read all over the world.",
                mm: "ဒီစာအုပ်ကို တစ်ကမ္ဘာလုံးမှာ ဖတ်ကြတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson37/renshua/4N37RSA6.mp3")
    },
];

/*
    {
        sentence: [
            {
                furi: "",
                normal: "",
                en: "",
                mm: "",
            },
        ],
        audioUrl: require("@assets/audio/testing.mp3")
    },

 // furi: 'あしたから　｜！旅（りょ）行（こう）なん｜です。',
        // normal: 'あしたから　旅行　なんです。',
        // en: 'It\'s a trip from tomorrow.',
        // mm: 'မနက်ဖြန်ကနေစပြီး ခရီးထွက်ပါမယ်။',
        //


*/