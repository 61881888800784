import { MojiType } from "..";

export const moji: MojiType = {
  mondai1:{
    title: 'もんだい１＿＿＿＿の　ことばは　ひらがなで　どう　かきますか。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 1,
        question: '！指｜に　けがを　しました。',
        answers: ['はな','あたま','ゆび','うで'],
        pick: '',
        correct: 'ゆび'
      },
      {
        number: 2,
        question: '先月　友だちと　｜！旅行｜しました。',
        answers: ['りょこ','りょこう','りょうこ','りょうこう'],
        pick: '',
        correct: 'りょこう'
      },
      {
        number: 3,
        question: '｜！急がなくても｜　いいですよ。',
        answers: ['さわがなくても','いそがなくても','およがなくても','ぬがなくても'],
        pick: '',
        correct: 'いそがなくても'
      },
      {
        number: 4,
        question: '｜！工場｜には　だれも　いません。',
        answers: ['おくじょ','おくじょう','こうじょ','こうじょう'],
        pick: '',
        correct: 'こうじょう'
      },
      {
        number: 5,
        question: 'わたしの　｜！妹｜は　日本にすんで　います。',
        answers: ['いもうと','あね','おとうと','あに'],
        pick: '',
        correct: 'いもうと'
      },
      {
        number: 6,
        question: 'ちょっと　｜！待って｜　ください。。',
        answers: ['まって','もって','とって','たって'],
        pick: '',
        correct: 'まって'
      },
      {
        number: 7,
        question: 'なぜが　少し　｜！強く｜　なりました。',
        answers: ['あたたかく','よわく','つよく','つめたく'],
        pick: '',
        correct: 'つよく'
      },
      {
        number: 8,
        question: '｜！駅員｜に　みちを　聞きっました。',
        answers: ['てんにん','えきにん','てんいん','えきいん'],
        pick: '',
        correct: 'えきいん'
      },
      {
        number: 9,
        question: 'わたしは　しょうらい　｜！医者｜に　なりたいです。',
        answers: ['いじゃ','いしゃ','いいじゃ','いいしゃ'],
        pick: '',
        correct: 'いしゃ'
      },
    ]
  },
  mondai2:{
    title: 'もんだい２＿＿＿＿の　ことばは　どう　かきますか。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 10,
        question: 'その　いけんには　｜！はんたい｜です。',
        answers: ['反対','半対','反体','半体'],
        pick: '',
        correct: '反対'
      },
      {
        number: 11,
        question: 'テキストの　１０ベージを｜！ひらいて｜　ください。',
        answers: ['関いて','閉いて','閑いて','開いて'],
        pick: '',
        correct: '開いて'
      },
      {
        number: 12,
        question: 'この　｜！あかい｜　じてんしゃは　だれのですか。',
        answers: ['黒い','青い','赤い','白い'],
        pick: '',
        correct: '赤い'
      },
      {
        number: 13,
        question: '毎日　３０ぷん　｜！はしって｜　います。',
        answers: ['足って','走って','是って','定って'],
        pick: '',
        correct: '走って'
      },
      {
        number: 14,
        question: 'この　へやは　｜！くらい｜ですね。',
        answers: ['暗い','明い','暑い','寒い'],
        pick: '',
        correct: '暗い'
      },
      {
        number: 15,
        question: 'いつも　あの　スーパーで｜！やさい｜を　買います。',
        answers: ['理薬','野薬','理菜','野菜'],
        pick: '',
        correct: '野菜'
      },
    ]
  },
  mondai3:{
    title: 'もんだい３（　　）に　なにを　いれますか。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 16,
        question: '新しい　しごとには　もう（　　）。',
        answers: ['なれましたか','がんばりましたか','つとめましたか','かよいましたか'],
        pick: '',
        correct: 'なれましたか'
      },
      {
        number: 17,
        question: '雨が（　　）、いい天気に　なりました。',
        answers: ['おわって','とまって','やんで','さえて'],
        pick: '',
        correct: 'やんで'
      },
      {
        number: 18,
        question: 'この　子は（　　）ので、あまり　ひとと話を　しません。',
        answers: ['わかいい','おとなしい','わかい','うつくしい'],
        pick: '',
        correct: 'おとなしい'
      },
      {
        number: 19,
        question: 'わたしが　にゅういんした　とき、友だちが（　　）に　来てくれました。',
        answers: ['おみまい','おねがい','けんがく','けんぶつ'],
        pick: '',
        correct: 'おみまい'
      },
      {
        number: 20,
        question: '来月　きこくするので、　ひこうきの　チケットを（　　）しました。',
        answers: ['けいかく','よやく','うけつけ','やくそく'],
        pick: '',
        correct: 'よやく'
      },
      {
        number: 21,
        question: '来週テストが　あるので、今週は（　　）べんきょうします。',
        answers: ['じょうぶに','しんせつに','まじめに','だいじに'],
        pick: '',
        correct: 'まじめに'
      },
      {
        number: 22,
        question: 'テーブルから　コップを（　　）、わって　しまいました。',
        answers: ['わたして','なくして','こわして','おとして'],
        pick: '',
        correct: 'おとして'
      },
      {
        number: 23,
        question: 'この　国は、たんじょうびに　とくべつな　ものを　食べる（　　）があります。',
        answers: ['よてい','しゅうかん','けいけん','きょうみ'],
        pick: '',
        correct: 'しゅうかん'
      },
      {
        number: 24,
        question: 'ドアの　よこの（　　）を　おすと、へやの　電気が　つきます。',
        answers: ['チェック','スタート','チャンス','スイッチ'],
        pick: '',
        correct: 'スイッチ'
      },
      {
        number: 25,
        question: 'こんばん　どこで　しょくじを　するか（　　）。',
        answers: ['きめましょう','はいりましょう','はらいましょう','さそいましょう'],
        pick: '',
        correct: 'きめましょう'
      },
    ]
  },
  mondai4:{
    title: 'もんだい4＿＿＿＿の ぶんと　だいたい　おなじ　いみの　ぶんが　あります。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 26,
        question: 'きのうの　話は　うそですよ。',
        answers: ['きのうの　話は　ほんとうですよ。','きのうの　話は　ほんとうじゃありませんよ。','きのうの　話は　ゆうめいですよ。','きのうの　話は　ゆうめいじゃありませんよ。'],
        pick: '',
        correct: 'きのうの　話は　ほんとうじゃありませんよ。'
      },
      {
        number: 27,
        question: 'やまださんは　何時ごろ　もどりますか。',
        answers: ['やまださんは　何時ごろ　かえって　きますか。','やまださんは　何時ごろ　でかけますか。','やまださんは　何時ごろ　ねますか。','やまださんは　何時ごろ　おきますか。'],
        pick: '',
        correct: 'やまださんは　何時ごろ　かえって　きますか。'
      },
      {
        number: 28,
        question: 'この　国は　こめを　ゆしゅつして　いますか。',
        answers: ['この　国は　こめを　ほかの　国から　もらって　いますか。','この　国は　こめを　ほかの　国から　かって　いますか。','この　国は　こめを　ほかの　国に　あげて　いますか。','この　国は　こめを　ほかの　国に　うって　いますか。'],
        pick: '',
        correct: 'この　国は　こめを　ほかの　国に　うって　いますか。'
      },
      {
        number: 29,
        question: 'たなかさんは　にこにこして　いました。',
        answers: ['たなかさんは　こまって　いました。','たなかさんは　やすんで　いました。','たなかさんは　わらって　いました。','たなかさんは　あそんで　いました。'],
        pick: '',
        correct: 'たなかさんは　わらって　いました。'
      },
      {
        number: 30,
        question: '新しい　かぐが　ほしいです。',
        answers: ['新しい　かばんや　さいふが　ほしです。','新しい　つくえや　ベッドが　ほしいです。','新しい　カメラや　ビデオが　ほしいです。','新しい　ノートや　ペンが　ほしいです。'],
        pick: '',
        correct: '新しい　つくえや　ベッドが　ほしいです。'
      },
    ]
  },
  mondai5:{
    title: 'もんだい５　つぎの ことばの　つかいかたで　いちばん　いい　ものを　１・２・３・４から　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 31,
        question: '　せんたく',
        answers: [
          'シャンプーで　かみを　｜！せんたく｜しました。',
          'にちようびに　へやを　｜！せんたく｜します。',
          'しょくじの　あとで、はを　｜！せんたく｜します。',
          'シャツと　パンツを　｜！せんたく｜　しました。'
        ],
        pick: '',
        correct: 'シャツと　パンツを　｜！せんたく｜　しました。'
      },
      {
        number: 32,
        question: '　こむ',
        answers: [
          'ごはんを　食べすぎて、おなかが　｜！こんで｜　います。',
          'この　じしょは　じが　｜！こんで｜　いて、読みにくいです。',
          'もりさんの　スーツケースは　ようふくで　｜！こんで｜　います。',
          'あさの　電車は　｜！こんで｜いて、すわれません。',
        ],
        pick: '',
        correct: 'あさの　電車は　｜！こんで｜いて、すわれません。'
      },
      {
        number: 33,
        question: '　にあう',
        answers: [
          'あの　きょうだいはかおが　よく　｜！にあって｜　いますね。',
          'その　みどりの　ぼうしは　よく　｜！にあって｜　いますね。',
          'わたしと　たなかさんは　しゅみが　｜！にあって｜　います。',
          'この　会社の　しごとは　わたしに　｜！にあって｜　います。',
        ],
        pick: '',
        correct: 'その　みどりの　ぼうしは　よく　｜！にあって｜　いますね。'
      },
      {
        number: 34,
        question: '　そだてる',
        answers: [
          'みんなで　さくらの　木を　たいせつに　｜！そだてて｜　います。',
          'いろいろな　しりょうを　しらべて、レポートを　｜！そだてました｜。',
          '先月から　ここで　新しい　ビルを　｜！そだてて｜　います。',
          'おいしく　なるまで、ゆっくりと　りょうりを　｜！そだてます｜。',
        ],
        pick: '',
        correct: 'みんなで　さくらの　木を　たいせつに　｜！そだてて｜　います。'
      },
      {
        number: 35,
        question: '　ぜひ',
        answers: [
          '！ぜひ｜　いちど　読んで　みて　ください。',
          'あしたも　｜！ぜひ｜　はれるでしょう。',
          'あぶないので、｜！ぜひ｜　さわらないで　ください。',
          'かりた　お金は　｜！ぜひ｜　かえします。',
        ],
        pick: '',
        correct: '！ぜひ｜　いちど　読んで　みて　ください。'
      },
    ]
  }
}