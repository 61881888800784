
export const lesson20 = [
    {"lesson":20,"level":4,"question":"去年","represent":["去","年"],"answer":["きょねん","しょうせつ","ししゃ","ばいてん"],"true":"きょねん","times":10},
    {"lesson":20,"level":4,"question":"過去","represent":["去"],"answer":["かこ","ししゃ","びょうし","きゅうし"],"true":"かこ","times":10},
    {"lesson":20,"level":4,"question":"小説","represent":["小","説"],"answer":["しょうせつ","ばいてん","かこ","ししゃ"],"true":"しょうせつ","times":10},
    {"lesson":20,"level":4,"question":"売店","represent":["売","店"],"answer":["ばいてん","きょねん","きゅうし","びょうし"],"true":"ばいてん","times":10},
    {"lesson":20,"level":4,"question":"知識","represent":["知"],"answer":["ちしき","せつめい","かこ","きょねん"],"true":"ちしき","times":10},
    {"lesson":20,"level":4,"question":"閉会する","represent":["閉","会"],"answer":["へいかいする","せっきょうする","つうちする","へいてんする"],"true":"へいかいする","times":10},
    {"lesson":20,"level":4,"question":"伝説","represent":["説"],"answer":["でんせつ","せつめい","びょうし","きょねん"],"true":"でんせつ","times":10},
    {"lesson":20,"level":4,"question":"通知する","represent":["通","知"],"answer":["つうちする","いんようする","せっきょうする","しゅうごうする"],"true":"つうちする","times":10},
    {"lesson":20,"level":4,"question":"説明","represent":["説","明"],"answer":["せつめい","ばいてん","かこ","しょうせつ"],"true":"せつめい","times":10},
    {"lesson":20,"level":4,"question":"閉店する","represent":["閉","店"],"answer":["へいてんする","かいかいする","つうちする","しゅうちゅうする"],"true":"へいてんする","times":10},
    {"lesson":20,"level":4,"question":"急死","represent":["急","死"],"answer":["きゅうし","びょうし","いんりょく","でんせつ"],"true":"きゅうし","times":10},
    {"lesson":20,"level":4,"question":"病死","represent":["病","死"],"answer":["びょうし","きょねん","ししゃ","しょうせつ"],"true":"びょうし","times":10},
    {"lesson":20,"level":4,"question":"開会する","represent":["開","会"],"answer":["かいかいする","へいてんする","しゅうちゅうする","へいかいする"],"true":"かいかいする","times":10},
    {"lesson":20,"level":4,"question":"集合する","represent":["集","合"],"answer":["しゅうごうする","かいかいする","いんようする","せっきょうするあ"],"true":"しゅうごうする","times":10},
    {"lesson":20,"level":4,"question":"開店する","represent":["開","店"],"answer":["かいてんする","へいかいする","かいかいする","いんようする"],"true":"かいてんする","times":10},
    {"lesson":20,"level":4,"question":"説教する","represent":["説","教"],"answer":["せっきょうする","かいてんする","しゅうちゅうする","しゅうごうする"],"true":"せっきょうする","times":10},
    {"lesson":20,"level":4,"question":"死者","represent":["死","者"],"answer":["ししゃ","しょうせつ","びょうし","きゅうし"],"true":"ししゃ","times":10},
    {"lesson":20,"level":4,"question":"引用する","represent":["引","用"],"answer":["いんようする","しゅうちゅうする","つうちする","せっきょうする"],"true":"いんようする","times":10},
    {"lesson":20,"level":4,"question":"集中する","represent":["集","中"],"answer":["しゅうちゅうする","へいてんする","しゅうごうする","せっきょうする"],"true":"しゅうちゅうする","times":10},
    {"lesson":20,"level":4,"question":"引力","represent":["引","力"],"answer":["いんりょく","かこ","きゅうし","きょねん"],"true":"いんりょく","times":10},
]