export const explain= [
    {
        // ＾友（とも）達（だち）｜
        pattern_furi: '～｜＾場（ば）合（あい）',
        pattern_normal: '～場合',
        mm: [
            "　～အခြေအနေမှာ",
        ],
        en: [ 
            '　when',
        ]
    },   
    {
        pattern_furi: '～のに',
        pattern_normal: '～のに',
        mm: [
            "　～ရဲ့သားနဲ့",
        ],
        en: [ 
            '　althought',
        ]
    },   
]