export const lesson1 = [
    {
        "lesson":1,
        "level":4,
        "question":"おおさかふ",
        "represent":["府","大"],
        "answer":["大阪府","東京都","研究所","京都府"],
        "true":"大阪府",
        "times":10
    },
    {"lesson":1,"level":4,"question":"きょうとふ","represent":["京","都","府"],"answer":["京都府","東京都","村役場","区役所"],"true":"京都府","times":10},
    {"lesson":1,"level":4,"question":"じゅうたく","represent":["住"],"answer":["台所","住民","住宅","住所"],"true":"住宅","times":10},
    {"lesson":1,"level":4,"question":"ちく","represent":["地","区"],"answer":["下町","市場","市長","地区"],"true":"地区","times":10},
    {"lesson":1,"level":4,"question":"じゅうみん","represent":["地","区"],"answer":["台所","東京","市民","住民"],"true":"住民","times":10},
    {"lesson":1,"level":4,"question":"し","represent":["市"],"answer":["区","町","村","市"],"true":"市","times":10},
    {"lesson":1,"level":4,"question":"く","represent":["区"],"answer":["区","市","村","町"],"true":"区","times":10},
    {"lesson":1,"level":4,"question":"じゅうしょ","represent":["住","所"],"answer":["住所","市民","台所","都会"],"true":"住所","times":10},
    {"lesson":1,"level":4,"question":"したまち","represent":["下","町"],"answer":["県庁","台所","下町","住民"],"true":"下町","times":10},
    {"lesson":1,"level":4,"question":"ところ","represent":["所"],"answer":["市","町","所","区"],"true":"所","times":10},
    {"lesson":1,"level":4,"question":"きょうと","represent":["京","都"],"answer":["京都","東京","住民","台所"],"true":"京都","times":10},
    {"lesson":1,"level":4,"question":"しみん","represent":["市","民"],"answer":["市場","市民","住所","住民"],"true":"市民","times":10},
    {"lesson":1,"level":4,"question":"むら","represent":["村"],"answer":["町","区","村","所"],"true":"村","times":10},
    {"lesson":1,"level":4,"question":"とうきょう","represent":["東","京"],"answer":["東京","政府","市場","町長"],"true":"東京","times":10},
    {"lesson":1,"level":4,"question":"けんきゅうじょ","represent":["研","究","所"],"answer":["京都府","研究所","村役場","東京都"],"true":"研究所","times":10},
    {"lesson":1,"level":4,"question":"とうきょうと","represent":["東","京","都"],"answer":["東京都","村役場","京都府","研究所"],"true":"東京都","times":10},
    {"lesson":1,"level":4,"question":"せいふ","represent":["府"],"answer":["住民","東京","地区","政府"],"true":"政府","times":10},
    {"lesson":1,"level":4,"question":"くやくしょ","represent":["区","所"],"answer":["京都府","東京都","区役所","大阪府"],"true":"区役所","times":10},
    {"lesson":1,"level":4,"question":"のうそん","represent":["村"],"answer":["市長","京都","住民","農村"],"true":"農村","times":10},
    {"lesson":1,"level":4,"question":"とかい","represent":["都","会"],"answer":["農村","都会","住宅","地区"],"true":"都会","times":10},
    {"lesson":1,"level":4,"question":"しじょう","represent":["市","場"],"answer":["台所","市場","地区","都会"],"true":"市場","times":10},
    {"lesson":1,"level":4,"question":"だいどころ","represent":["台","所"],"answer":["台所","町長","住民","県庁"],"true":"台所","times":10},
    {"lesson":1,"level":4,"question":"しちょう","represent":["市","長"],"answer":["市民","市長","政府","地区"],"true":"市長","times":10},
    {"lesson":1,"level":4,"question":"むらやくば","represent":["村","場"],"answer":["研究所","村役場","区役所","京都府"],"true":"村役場","times":10},
    {"lesson":1,"level":4,"question":"ちょうちょう","represent":["町","長"],"answer":["農村","町長","市長","政府"],"true":"町長","times":10},
    {"lesson":1,"level":4,"question":"けんちょう","represent":["県"],"answer":["町長","県庁","住所","農村"],"true":"県庁","times":10},
    {"lesson":1,"level":4,"question":"まち","represent":["町"],"answer":["村","町","区","所"],"true":"町","times":10}
]