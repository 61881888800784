export const lesson48 = [
  {
      "furi": "＾下（お）｜ろします",
      "normal": "下ろします",
      "en": "put down",
      "remark_en": "",
      "mm": "အောက်ချသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson48/vocab/4N48VO1.mp3")
  },
  {
      "furi": "＾届（とど）｜けます",
      "normal": "届けます",
      "en": "deliver, report",
      "remark_en": "",
      "mm": "ပို့သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson48/vocab/4N48VO2.mp3")
  },
  {
      "furi": "＾世（せ）話（わ）｜をします",
      "normal": "世話をします",
      "en": "take care of~",
      "remark_en": "",
      "mm": "ဝေယာဝစ္စဆောင်ရွက်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson48/vocab/4N48VO3.mp3")
  },
  {
      "furi": "＾録（ろく）音（おん）｜します",
      "normal": "録音します",
      "en": "Record",
      "remark_en": "",
      "mm": "အသံသွင်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson48/vocab/4N48VO4.mp3")
  },
  {
      "furi": "＾嫌（いや）｜[な]",
      "normal": "嫌[な]",
      "en": "unwilling, reluctant",
      "remark_en": "",
      "mm": "မနှစ်မြို့သော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson48/vocab/4N48VO5.mp3")
  },
  {
      "furi": "＾塾（じゅく）",
      "normal": "塾",
      "en": "cram school",
      "remark_en": "",
      "mm": "ကျူရှင်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson48/vocab/4N48VO6.mp3")
  },
  {
      "furi": "＾生（せい）徒（と）",
      "normal": "生徒",
      "en": "pupil",
      "remark_en": "",
      "mm": "တပည့်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson48/vocab/4N48VO7.mp3")
  },
  {
      "furi": "ファイル",
      "normal": "ファイル",
      "en": "file",
      "remark_en": "",
      "mm": "ဖိုင်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson48/vocab/4N48VO8.mp3")
  },
  {
      "furi": "＾自（じ）由（ゆう）｜に",
      "normal": "自由に",
      "en": "freely",
      "remark_en": "",
      "mm": "လွတ်လပ်စွာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson48/vocab/4N48VO9.mp3")
  },
  {
      "furi": "～｜＾間（かん）",
      "normal": "～間",
      "en": "for~(duration)",
      "remark_en": "",
      "mm": "～ကြာချိန်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson48/vocab/4N48VO10.mp3")
  },
  {
      "furi": "いいことですね。",
      "normal": "いいことですね。",
      "en": "That’s good",
      "remark_en": "",
      "mm": "ကောင်းတဲ့ကိစ္စပဲ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson48/vocab/4N48VO11.mp3")
  },
  {
      "furi": "お｜＾忙（いそが）｜しいですか。",
      "normal": "お忙しいですか。",
      "en": "Are you busy?",
      "remark_en": "",
      "mm": "အလုပ်များနေပါသလား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson48/vocab/4N48VO12.mp3")
  },
  {
      "furi": "＾営（えい）業（ぎょう）",
      "normal": "営業",
      "en": "business, sales",
      "remark_en": "",
      "mm": "စီးပွားရေးလုပ်ငန်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson48/vocab/4N48VO13.mp3")
  },
  {
      "furi": "それまでに",
      "normal": "それまでに",
      "en": "by that time",
      "remark_en": "",
      "mm": "အဲ့ဒီအချိန်နောက်ဆုံးထားပြီး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson48/vocab/4N48VO14.mp3")
  },
  {
      "furi": "かまいません。",
      "normal": "かまいません。",
      "en": "It doesn’t matter",
      "remark_en": "",
      "mm": "ကိစ္စမရှိပါဘူး(ရပါတယ်)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson48/vocab/4N48VO15.mp3")
  },
  {
      "furi": "＾楽（たの）｜しみます",
      "normal": "楽しみます",
      "en": "enjoy yourself",
      "remark_en": "",
      "mm": "ပျော်ရွှင်ကြည်နူးသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson48/vocab/4N48VO16.mp3")
  },
  {
      "furi": "＾親（おや）",
      "normal": "親",
      "en": "parent",
      "remark_en": "",
      "mm": "မိဘ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾小（しょう）学（がく）生（せい）",
      "normal": "小学生",
      "en": "Elementary school students",
      "remark_en": "",
      "mm": "မူလတန်းကျောင်းသား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "～パーセント",
      "normal": "～パーセント",
      "en": "~Percent",
      "remark_en": "",
      "mm": "-ရာခိုင်နှုန်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "その｜＾次（つぎ）",
      "normal": "その次",
      "en": "the next one",
      "remark_en": "",
      "mm": "အဲ့ဒီနောက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾習（しゅう）字（じ）",
      "normal": "習字",
      "en": "Penmanship",
      "remark_en": "",
      "mm": "လက်ရေးလေ့ကျင့်ခြင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾普（ふ）通（つう）｜の",
      "normal": "普通の",
      "en": "Usual",
      "remark_en": "",
      "mm": "ပုံမှန်ရိုးရိုး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  }
]

/*
    {
        furi: "",
        normal: "",
        en: "",
        remark_en: "",
        mm: "",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson29/vocab/4N29VO1.mp3")
    },


*/
const test = [
  {
    'goi': '下（お）」ろします',
    'unicode': 'အောက်ချသည်',
    'zawgyi': 'ေအာက္ခ်သည္',
    'english': 'put down',
    'long': false
  },
  {
    'goi': '届（とど）」けます',
    'unicode': 'ပို့သည်',
    'zawgyi': 'ပို႔သည္',
    'english': 'deliver, report',
    'long': false
  },
  {
    'goi': '世（せ）」話（わ）」をします',
    'unicode': 'ဝေယာဝစ္စဆောင်ရွက်သည်',
    'zawgyi': 'ကိစၥႀကီးငယ္ျပဳသည္',
    'english': 'take care of~',
    'long': false
  },
  {
    'goi': '録（ろく）」音（おん）」します',
    'unicode': 'အသံသွင်းသည်',
    'zawgyi': 'အသံသြင္းသည္',
    'english': 'Record',
    'long': false
  },
  {
    'goi': '嫌（いや）」[な]',
    'unicode': 'မနှစ်မြို့သော',
    'zawgyi': 'မႏွစ္ၿမိဳ႕ေသာ',
    'english': 'unwilling, reluctant',
    'long': false
  },
  {
    'goi': '塾（じゅく）',
    'unicode': 'ကျူရှင်',
    'zawgyi': 'က်ဴရွင္',
    'english': 'cram school',
    'long': false
  },
  {
    'goi': '生（せい）」徒（と）',
    'unicode': 'တပည့်',
    'zawgyi': 'တပည့္',
    'english': 'pupil',
    'long': false
  },
  {
    'goi': 'ファイル',
    'unicode': 'ဖိုင်',
    'zawgyi': 'ဖိုင္',
    'english': 'file',
    'long': false
  },
  {
    'goi': '自（じ）」由（ゆう）」に',
    'unicode': 'လွတ်လပ်စွာ',
    'zawgyi': 'လြတ္လပ္စြာ',
    'english': 'freely',
    'long': false
  },
  {
    'goi': '～」間（かん）',
    'unicode': '～ကြာချိန်',
    'zawgyi': '～ၾကာခ်ိန္',
    'english': 'for~(duration)',
    'long': false
  },
  {
    'goi': 'いいことですね。',
    'unicode': 'ကောင်းတဲ့ကိစ္စပဲ',
    'zawgyi': 'ေကာင္းတဲ့ကိစၥပဲ',
    'english': 'That’s good',
    'long': false
  },
  {
    'goi': 'お」忙（いそが）」しいですか。',
    'unicode': 'အလုပ်များနေပါသလား',
    'zawgyi': 'အလုပ္မ်ားေနပါသလား',
    'english': 'Are you busy?',
    'long': false
  },
  {
    'goi': '営（えい）」業（ぎょう）',
    'unicode': 'စီးပွားရေးလုပ်ငန်း',
    'zawgyi': 'စီးပြားေရးလုပ္ငန္း',
    'english': 'business, sales',
    'long': false
  },
  {
    'goi': 'それまでに',
    'unicode': 'အဲ့ဒီအချိန်နောက်ဆုံးထားပြီး',
    'zawgyi': 'အဲ့ဒီအခ်ိန္ေနာက္ဆုံးထားၿပီး',
    'english': 'by that time',
    'long': false
  },
  {
    'goi': 'かまいません。',
    'unicode': 'ကိစ္စမရှိပါဘူး(ရပါတယ်)',
    'zawgyi': 'ကိစၥမရွိပါဘူး(ရပါတယ္)',
    'english': 'It doesn’t matter',
    'long': false
  },
  {
    'goi': '楽（たの）」しみます',
    'unicode': 'ပျော်ရွှင်ကြည်နူးသည်',
    'zawgyi': 'ေပ်ာ္ရႊင္ၾကည္ႏူးသည္',
    'english': 'enjoy yourself',
    'long': false
  },
  {
    'goi': '親（おや）',
    'unicode': 'မိဘ',
    'zawgyi': 'မိဘ',
    'english': 'parent',
    'long': false
  },
  {
    'goi': '小（しょう）」学（がく）」生（せい）',
    'unicode': 'မူလတန်းကျောင်းသား',
    'zawgyi': 'မူလတန္းေက်ာင္းသား',
    'english': 'Elementary school students',
    'long': false
  },
  {
    'goi': '～パーセント',
    'unicode': '-ရာခိုင်းနှုန်း',
    'zawgyi': '-ရာခိုင္းႏႈန္း',
    'english': '~Percent',
    'long': false
  },
  {
    'goi': 'その」次（つぎ）',
    'unicode': 'အဲ့ဒီနောက်',
    'zawgyi': 'အဲ့ဒီေနာက္',
    'english': 'the next one',
    'long': false
  },
  {
    'goi': '習（しゅう）」字（じ）',
    'unicode': 'လက်ရေးလေ့ကျင့်ခြင်း',
    'zawgyi': 'လက္ေရးေလ့က်င့္ျခင္း',
    'english': 'Penmanship',
    'long': false
  },
  {
    'goi': '普（ふ）」通（つう）」の',
    'unicode': 'ပုံမှန်ရိုးရိုး',
    'zawgyi': 'ပုံမွန္ရိုးရိုး',
    'english': 'Usual',
    'long': false
  },
  {'goi': '', 'unicode': '', 'zawgyi': '', 'english': '', 'long': false}
]