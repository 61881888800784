import PrivacyPolicyScreen from '@screens/PrivacyPolicyScreen';
import TestingScreen from '@screens/TestingScreen';
import AboutScreen from '@screens/home/AboutScreen';
import CategoryScreen from '@screens/home/CategoryScreen';
import ContactScreen from '@screens/home/ContactScreen';
import HomeScreen from '@screens/home/HomeScreen';
import FukushuuScreen from '@screens/home/category/FukushuuScreen';
import GrammarScreen from '@screens/home/category/GrammarScreen';
import KaiwaScreen from '@screens/home/category/KaiwaScreen';
import KanjiDetailScreen from '@screens/home/category/KanjiDetailScreen';
import KanjiGameScreen from '@screens/home/category/KanjiGameScreen';
import KanjiScreen from '@screens/home/category/KanjiScreen';
import MondaiScreen from '@screens/home/category/MondaiScreen';
import Renshuu_C_Screen from '@screens/home/category/Renshuu_C_Screen';
import VocabScreen from '@screens/home/category/VocabScreen';
import TestScreen from '@screens/test/TestScreen';
import TestDetailScreen from '@screens/test/TestDetailScreen';
import SettingScreen from '@screens/setting/SettingScreen';
import AppearanceScreen from '@screens/setting/AppearanceScreen';
import { HashRouter, Route, Routes,BrowserRouter } from 'react-router-dom';
import { ScreenPropType } from '../App';
import RouteName from './Routes';

const RouterView = ({ screenProps }: { screenProps: ScreenPropType} ) => {
    const log = screenProps.Logger(1);
    // log.i("Routerview console")
    // console.log(screenProps);
    // basename={process.env.PUBLIC_URL}
    return (
        // <HashRouter >
        <BrowserRouter >
            <Routes>
                <Route path={RouteName.HOME} element={<HomeScreen {...screenProps} />} />
                <Route path={RouteName.CATEGORY} element={<CategoryScreen {...screenProps} />} />
                <Route path={RouteName.VOCAB} element={<VocabScreen {...screenProps} />} />
                <Route path={RouteName.KANJI} element={<KanjiScreen {...screenProps} />} />
                <Route path={RouteName.GRAMMAR} element={<GrammarScreen {...screenProps} />} />
                <Route path={RouteName.KAIWA} element={<KaiwaScreen {...screenProps} />} />
                <Route path={RouteName.CONTACT} element={<ContactScreen {...screenProps} />} />
                <Route path={RouteName.ABOUT} element={<AboutScreen {...screenProps} />} />
                <Route path={RouteName.RENSHUU_C} element={<Renshuu_C_Screen {...screenProps} />} />
                <Route path={RouteName.MONDAI} element={<MondaiScreen {...screenProps} />} />
                <Route path={RouteName.TEST} element={<TestScreen {...screenProps} />} />
                <Route path={RouteName.TEST_DETAIL} element={<TestDetailScreen {...screenProps} />} />
                <Route path={RouteName.SETTING} element={<SettingScreen {...screenProps} />} />
                <Route path={RouteName.KAIWA} element={<KaiwaScreen {...screenProps} />} />
                <Route path={RouteName.PRIVACY} element={<PrivacyPolicyScreen {...screenProps} />} />
                <Route path={RouteName.TEST} element={<TestingScreen {...screenProps} />} />
                <Route path={RouteName.KANJI_DETAIL} element={<KanjiDetailScreen {...screenProps} />} />
                <Route path={RouteName.KANJI_GAME} element={<KanjiGameScreen {...screenProps} />} />
                <Route path={RouteName.FUKUSHUU} element={<FukushuuScreen {...screenProps} />} />
                <Route path={RouteName.APPEARANCE} element={<AppearanceScreen {...screenProps} />} />
            </Routes>
        </BrowserRouter>
        // </HashRouter>
    ); 
}
export default RouterView;