import Icon from "@consts/icon";

export const lesson37 = {
    title: {
        furi: '＾金（きん）閣（かく）寺（じ）｜は　１４｜＾世（せい）紀（き）｜に　｜＾建（た）｜てられました',
        normal: '金閣寺は　１４世紀に　建てられました',
        en: 'Kinkakuji was built in the 14th century',
        mm: 'Kinkakuji ကို 14 ရာစုတွင်တည်ဆောက်ခဲ့သည်',
    },
    conversation: [
        {
            person: {
                furi: 'ガイド',
                normal: 'ガイド',
                avatar: Icon.Kaiwa.STAFF
            },
            sentence: [
                {
                    furi: '＾皆（みな）様（さま）｜、あちらが　｜＾有（ゆう）名（めい）｜な　｜＾金（きん）閣（かく）寺（じ）｜です。',
                    normal: '皆様、あちらが　有名な　金閣寺です。',
                    en: 'Ladies and gentlemen, over there is the famous Kinkakuji.',
                    mm: 'ဂုဏ်သရေရှိ လူကြီးမင်းတို့၊ ဟိုမှာ နာမည်ကြီး Kinkakuji ဖြစ်ပါတယ်။',
                },
                {
                    furi: '＾金（きん）閣（かく）寺（じ）｜は　１４｜＾世（せい）紀（き）｜に　｜＾建（た）｜てられました。',
                    normal: '金閣寺は　１４世紀に　建てられました。',
                    en: 'Kinkakuji was built in the 14th century',
                    mm: 'Kinkakuji ကို 14 ရာစုတွင်တည်ဆောက်ခဲ့တာဖြစ်ပါတယ်',
                },
                {
                    furi: '１９５０｜＾年（ねん）｜に　｜＾一（いち）度（ど）｜　｜＾焼（や）｜けて　しまいましたが、',
                    normal: '１９５０年に　一度　焼けて　しまいましたが、',
                    en: 'It burned down once in 1950,',
                    mm: '၁၉၅၀ မှာ တစ်ကြိမ် မီးလောင်ခဲ့ပေမယ့်',
                },
                {
                    furi: 'その｜＾後（ご）｜　｜＾新（あたら）｜しい　｜＾建（たて）物（もの）｜が　｜＾建（た）｜てられて、１９９４｜＾年（ねん）｜に　｜＾世（せ）界（かい）遺（い）産（さん）｜に　なりました。',
                    normal: 'その後　新しい　建物が　建てられて、１９９４年に　世界遺産に　なりました。',
                    en: 'After that, a new building was built and in 1994 it became a World Heritage Site.',
                    mm: 'အဲဒီနောက်မှာတော့ အဆောက်အအုံသစ်ကို တည်ဆောက်ခဲ့ပြီး ၁၉၉၄ ခုနှစ်မှာ ကမ္ဘာ့အမွေအနှစ်နေရာ ဖြစ်လာခဲ့ပါတယ်။',
                },
                {
                    furi: '＾京（きょう）都（と）｜で　｜＾人（にん）気（き）｜が　ある　お｜＾寺（てら）｜の　｜＾一（ひと）｜つです。',
                    normal: '京都で　人気が　ある　お寺の　一つです。',
                    en: 'It is one of the popular temples in Kyoto.',
                    mm: 'ကျိုတိုရှိတဲ့ နာမည်ကြီးဘုရားကျောင်းများထဲမှ တစ်ခုဖြစ်ပါသည်။',
                },
            ],
        },
        {
            person: {
                furi: 'カリナ',
                normal: 'カリナ',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: 'きれいですね。｜＾壁（かべ）｜が　｜＾金（きん）色（いろ）｜ですが、｜＾本（ほん）物（もの）｜の　｜＾金（きん）｜ですか。',
                    normal: 'きれいですね。壁が　金色ですが、本物の　金ですか。',
                    en: "It's beautiful. The wall is gold, but is it real gold?",
                    mm: 'လှလိုက်တာ၊ နံရံက ရွှေတွေက ရွှေအစစ်လား။',
                },
            ],
        },
        {
            person: {
                furi: 'ガイド',
                normal: 'ガイド',
                avatar: Icon.Kaiwa.STAFF
            },
            sentence: [
                {
                    furi: 'はい。｜＾金（きん）｜が　２０キロぐらい　｜＾使（つか）｜われました。',
                    normal: 'はい。金が　２０キロぐらい　使われました。',
                    en: 'yes. About 20 kg of gold was used.',
                    mm: 'ဟုတ်ပါတယ် ရွှေ ၂၀ ကီလိုဂရမ်ခန့် အသုံးပြုခဲ့ပါသည်။',
                },
            ],
        },
        {
            person: {
                furi: 'カリナ',
                normal: 'カリナ',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: 'そうですか。あの　｜＾中（なか）｜に　｜＾入（はい）｜れますか。',
                    normal: 'そうですか。あの　中に　入れますか。',
                    en: 'Is that so. Can we go inside?',
                    mm: 'ဟုတ်လား၊ အဲ့ဒီအထဲကို ဝင်လို့ရလား။',
                },
            ],
        },
        {
            person: {
                furi: 'ガイド',
                normal: 'ガイド',
                avatar: Icon.Kaiwa.STAFF
            },
            sentence: [
                {
                    furi: '＾中（なか）｜には　｜＾入（はい）｜れないんです。',
                    normal: '中には　入れないんです。',
                    en: "You can't go inside.",
                    mm: 'အထဲကိုတော့ ဝင်လို့မရပါဘူး',
                },
                {
                    furi: '＾池（いけ）｜の　｜＾周（まわ）｜りを　｜＾歩（ある）｜きながら　｜＾見（み）｜て　ください。',
                    normal: '池の　周りを　歩きながら　見て　ください。',
                    en: 'Take a look while walking around the pond.',
                    mm: 'ရေကန်ပတ်ပတ်လည်ကို လမ်းလျှောက်ကြည့်လိုက်ပါ။',
                },
            ],
        },
        {
            person: {
                furi: 'カリナ',
                normal: 'カリナ',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: '＾紅（もみ）葉（じ）｜が　きれいですね。',
                    normal: '紅葉が　きれいですね。',
                    en: "The autumn leaves are beautiful, aren't they?",
                    mm: 'မိုမိဂျိတွေက လှလိုက်တာနော်',
                },
            ],
        },
        {
            person: {
                furi: 'ガイド',
                normal: 'ガイド',
                avatar: Icon.Kaiwa.STAFF
            },
            sentence: [
                {
                    furi: 'ええ。｜＾金（きん）閣（かく）寺（じ）｜は　｜＾紅（もみ）葉（じ）｜と　｜＾雪（ゆき）｜の　｜＾季（き）節（せつ）｜が　｜＾特（とく）｜に　｜＾美（うつく）｜しいと　｜＾言（い）｜われて　います。',
                    normal: 'ええ。金閣寺は　紅葉と　雪の　季節が　特に　美しいと　言われて　います。',
                    en: 'yes. Kinkakuji is said to be especially beautiful during the fall foliage and snow seasons.',
                    mm: 'ဟုတ်ပါတယ်၊ Kinkakuji က မိုမိဂျိနဲ့ ဆောင်းရာသီဟာ အထူးသဖြင့်လှပတယ်လို့ ပြောကြပါတယ်',
                },
            ],
        }
    ],
    audioUrl: require("@assets/audio/lesson37/kaiwa/4N37KW.mp3")
}

/*

{
    title: {
        furi: '',
        normal: '',
        en: '',
        mm: '',
    },
    conversation: [
        {
            person: {
                furi: '',
                normal: '',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '',
                    normal: '',
                    en: '',
                    mm: '',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson36/kaiwa/4N36KW.mp3")
}

*/