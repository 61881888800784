export const bunkei= [
    {
        sentence: [
            {
                furi: 'あしたから　｜！習（なら）旅（りょ）行（こう）なん｜です。',
                normal: 'あしたから　旅行　なんです。',
                en: 'It\'s a trip from tomorrow.',
                mm: 'မနက်ဖြန်ကနေစပြီး ခရီးထွက်ပါမယ်။',
            }
        ],
        audioUrl: require("@assets/audio/lesson26/bunpou/4N26BK1.mp3")
    },
    {
        sentence: [
            {
                furi: '＾生（い）｜け｜＾花（ばな）｜を　｜＾習（なら）｜いたいですが、　いい　｜＾先（せん）生（せい）｜を　｜＾紹（しょう）介（かい）｜して　｜！いただけませんか｜。',
                normal: '生け花を　習いたいですが、　いい　先生を　紹介して　いただけませんか。',
                en: 'I would like to learn Ikebana, but could you introduce me to a good teacher?',
                mm: 'ဂျပန်ရိုးရာပန်းအလှဆင်ခြင်းကို သင်ယူချင်လို့ ဆရာကောင်းတစ်ယောက်နဲ့ မိတ်ဆက်ပေးနိုင်မလား။',
            }
        ],
        audioUrl: require("@assets/audio/lesson26/bunpou/4N26BK2.mp3")
    }
]