export const explain= [
    {
        // ＾友（とも）達（だち）｜
        pattern_furi: 'どうも',
        pattern_normal: 'どうも',
        mm: [
            "　မိမိ၏ထင်မြင်ချက်ဖြင့် မှန်းဆ၍ပြောသော အခါတွင်သုံးသည်။",
            "　စာကြောင်း၏ရှေ့တွင်ထားသုံးလေ့ရှိပြီး စာကြောင်း၏နောက်တွင် ようだ/そうだ များလိုက်လေ့ရှိသည်။",
            "　でしょう、かもしれない、～とおもうများဖြင့်တွဲမသုံးပါ။",
        ],
        en: [ 
            '　Used when speaking with own opinion.',
            '　It is often used in front of the sentence, followed by ようだ/そうだ.',
            '　It is usually not used with でしょう、かもしれない、～とおもう.',
        ]
    },    
    {
        pattern_furi: '～によると',
        pattern_normal: '～によると',
        mm: [
            "　～အရ",
            "　တခြားသူထံမှကြားထားသော အကြောင်းအရာကို ပြန်လည်ပြောသော အခါတွင်သုံးသည်။",
        ],
        en: [ 
            '　According to ～',
            '　It is used when repeating the content heard from someone else.',
        ]
    },   
    {
        pattern_furi: '～ようです',
        pattern_normal: '～ようです',
        mm: [
            "　 -ပုံဘဲ",
            "　မိမိ၏ထင်မြင်ချက်ဖြင့် မှန်းဆ၍ပြောသော အခါတွင်သုံးသည်။",
        ],
        en: [ 
            '　It sounds',
            '　Used when speaking with own opinion.',
        ]
    },   
    {
        pattern_furi: '～そうです',
        pattern_normal: '～そうです',
        mm: [
            "　 -ဆိုဘဲ",
            "　တစ်စုံတစ်ဦးထံမှ ကြားသိရသော တဆင့်စကားကို ပြန်ပြောသော အခါတွင်သုံးသည်။",
        ],
        en: [ 
            '　It sounds',
            '　It is used when speaking through what someone has heard.',
        ]
    },   
]