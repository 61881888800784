export const bunkei= [
    {
        sentence: [
            {
                furi: 'いっしょに　｜！行（い）こう｜。',
                normal: 'いっしょに　行こう。',
                en: "Let's go together.",
                mm: 'အတူတူသွားကြရအောင်။',
            }
        ],
        audioUrl: require("@assets/audio/lesson31/bunpou/4N31BK1.mp3")
    },
    {
        sentence: [
            {
                furi: '＾将（しょう）来（らい）｜　｜＾自（じ）分（ぶん）｜の　｜＾会（かい）社（しゃ）｜を　｜！作（つく）ろうと｜　思って　います。',
                normal: '将来　自分の　会社を　作ろうと　思って　います。',
                en: 'I am thinking of starting my own company in the future.',
                mm: 'အနာဂတ်မှာ ကိုယ်ပိုင်ကုမ္ပဏီတစ်ခုကို စတင်ဖို့ စဉ်းစားနေပါတယ်။',
            }
        ],
        audioUrl: require("@assets/audio/lesson31/bunpou/4N31BK2.mp3")
    },
    {
        sentence: [
            {
                furi: '＾来（らい）月（げつ）車（くるま）｜を　｜！買（か）うつもりです｜。',
                normal: '来月車を　買うつもりです。',
                en: 'I plan to buy a car next month.',
                mm: 'နောက်လမှာ ကားဝယ်ဖို့ စီစဉ်ထားတယ်။',
            }
        ],
        audioUrl: require("@assets/audio/lesson31/bunpou/4N31BK3.mp3")
    },
]