import SizeBox from "@components/SizeBox";
import DefaultLayout from "@layouts/default/DefaultLayout";
import { memo } from "react";
const SettingScreenLayout = ({body, showModel,setShowModel,setUnlockPremium,font,dark,lang,furigana, Routes, useNavigate, Logger }: any) => {
    return (
        <DefaultLayout
            navbarProps={{
                root: false,
                appBar: { title: "Minnano challenge", centerTitle: true, leadingIcon: false, leadingRoute: Routes.HOME }
            }}
            body={<> 
                <SizeBox vertical="h-[20px]" />
                <div>{body}</div>
                <div className=" container mx-auto grid grid-cols-1 gap-y-4  md:grid-cols-2 lg:grid-cols-3 md:gap-10  lg:gap-7 ">
                    {font}
                    {dark}
                    {lang}
                    {furigana}
                </div>
            </>
            }
            Routes={Routes}
            useNavigate={useNavigate}
            Logger={Logger}
            showModel={showModel}
            setShowModel={setShowModel}
            setUnlockPremium={setUnlockPremium}
        />
    )
}
export default memo(SettingScreenLayout);