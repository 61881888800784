import { Card } from "@material-tailwind/react"
import { memo } from "react"
import Appeareance from "@assets/icon/setting/paint 1.svg";
import About from "@assets/icon/setting/about 1.svg";
import Contact from "@assets/icon/setting/contact 1.svg";
import Privacy from "@assets/icon/setting/privacy 1.svg";
const SettingMobileComponent = ({navigate,Routes}:any) => {
    const mobileSettingItems = [
        {
            icon: Appeareance,
            label: "Appeareance",
            route: () => navigate(Routes.APPEARANCE)
        },
        {
            icon: About,
            label: "About",
            route: () => navigate(Routes.ABOUT)
        },
        {
            icon: Contact,
            label: "Contact",
            route: () => navigate(Routes.CONTACT)
        },
        {
            icon: Privacy,
            label: "Privacy",
            route: () => navigate(Routes.PRIVACY)
        },
    ]
    return <div className="md:hidden">
        {
            mobileSettingItems.map(({ icon, label, route }:any, i:number) => {
                return <Card key={i} onClick={route} className="flex flex-row items-center h-12  mb-3 px-3  bg-white dark:bg-[#001E34] ">
                    <img src={icon} />
                    <div className="ml-3">{label}</div>
                </Card>
            })
        }

    </div>
}
export default memo(SettingMobileComponent)