export const bunkei= [
    {
        sentence: [
            {
                furi: "＾天（てん）気（き）予（よ）報（ほう）｜に　よると、あしたは　｜＾寒（さむ）｜く　なるそうです。",
                normal: "天気予報に　よると、あしたは　寒く　なるそうです。",
                en: "According to the weather forecast, it will be cold tomorrow.",
                mm: "မိုးလေဝသခန့်မှန်းချက်အရ မနက်ဖြန်မှာ အအေးပိုမယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson47/bunpou/4N47BK1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾隣（となり）｜の　｜＾部（へ）屋（や）｜に　だれか　いるようです。",
                normal: "隣の　部屋に　だれか　いるようです。",
                en: "It seems that someone is in the next room.",
                mm: "နောက်ခန်းမှာ တစ်ယောက်ယောက် ရှိနေပုံရတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson47/bunpou/4N47BK2.mp3")
    },
]