import Icon from "@consts/icon";

export const lesson32 = {
    title: {
        furi: '＾無（む）理（り）｜を　しない　ほうが　いいですよ',
        normal: '無理を　しない　ほうが　いいですよ',
        en: "It's better not to overdo it",
        mm: 'မနိုင်ဝန်မထမ်းတာ ကောင်းမယ်',
    },
    conversation: [
        {
            person: {
                furi: '＾小（お）川（がわ）',
                normal: '小川',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'シュミットさん、｜＾元（げん）気（き）｜が　ありませんね。',
                    normal: 'シュミットさん、元気が　ありませんね。',
                    en: "Mr. Schmidt, you're not feeling well.",
                    mm: 'ရှုမစ်တိုစံ ကျန်းမာရေးသိပ်မကောင်းဘူးနော်',
                },
                {
                    furi: 'どう　したんですか。',
                    normal: 'どう　したんですか。',
                    en: 'What happened.',
                    mm: 'ဘယ်လိုဖြစ်တာလည်း',
                },
            ],
        },
        {
            person: {
                furi: 'シュミット',
                normal: 'シュミット',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: '＾最（さい）近（きん）｜　｜＾体（からだ）｜の　｜＾調（ちょう）子（し）｜が　よくないんです。',
                    normal: '最近　体の　調子が　よくないんです。',
                    en: "My body hasn't been feeling well nowadays.",
                    mm: 'အခုတစ်လော လူ(ခန္ဓာကိုယ်)ရဲ့အခြေအနေက သိပ်မကောင်းဘူး',
                },
                {
                    furi: '＾時（とき）々（どき）｜　｜＾頭（あたま）｜や　｜＾胃（い）｜が　｜＾痛（いた）｜く　なるんですよ。',
                    normal: '時々　頭や　胃が　痛く　なるんですよ。',
                    en: 'Sometimes my head and stomach hurt',
                    mm: 'တစ်ခါတစ်ရံ ခေါင်းကိုက်တယ်၊ ဗိုက်လည်းနာတယ်',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）',
                normal: '小川',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'それは　いけませんね。｜＾仕（し）事（ごと）｜が　｜＾忙（いそが）｜しいんですか。',
                    normal: 'それは　いけませんね。仕事が　忙しいんですか。',
                    en: "That's too bad. Are you busy with work?",
                    mm: 'အဲ့ဒါ သိပ်မကောင်းဘူးနော်၊ အလုပ်ရှုပ်ပြီးတော့များဖြစ်တာလား?',
                },
            ],
        },
        {
            person: {
                furi: 'シュミット',
                normal: 'シュミット',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'ええ。｜＾残（ざん）業（ぎょう）｜が　｜＾多（おお）｜いんです。',
                    normal: 'ええ。残業が　多いんです。',
                    en: 'yes. I work a lot of overtime.',
                    mm: 'အင်း အချိန်ပိုဆင်းရတာများတယ်',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）',
                normal: '小川',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'ストレスかも　しれませんね。',
                    normal: 'ストレスかも　しれませんね。',
                    en: '',
                    mm: 'စိတ်ဖိစီးမှုကြောင့်လည်းဖြစ်နိုင်တယ်',
                },
                {
                    furi: '＾一（いち）度（ど）｜　｜＾病（びょう）院（いん）｜で　｜＾診（み）｜て　もらった　ほうが　いいですよ。',
                    normal: '一度　病院で　診て　もらった　ほうが　いいですよ。',
                    en: 'It might be stress',
                    mm: 'တစ်ခါလောက် ဆေးရုံမှာ ကုသမှုခံယူတာကောင်းမယ်နော်',
                },
            ],
        },
        {
            person: {
                furi: 'シュミット',
                normal: 'シュミット',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'ええ、そうですね。',
                    normal: 'ええ、そうですね。',
                    en: 'Yeah, you are right.',
                    mm: 'အင်း ဟုတ်တယ်နော်',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）',
                normal: '小川',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '＾無（む）理（り）｜を　しない　ほうが　いいですよ。',
                    normal: '無理を　しない　ほうが　いいですよ。',
                    en: "It's better not to overdo it.",
                    mm: 'မနိုင်ဝန်မထမ်းတာ ကောင်းမယ်နော်',
                },
            ],
        },
        {
            person: {
                furi: 'シュミット',
                normal: 'シュミット',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'ええ、今の　｜＾仕（し）事（ごと）｜が　｜＾終（お）｜わったら、',
                    normal: 'ええ、今の　仕事が　終わったら、',
                    en: "Yeah, when I'm done with my current job,",
                    mm: 'အင်း အခုအလုပ်ပြီးရင်',
                },
                {
                    furi: '＾休（やす）｜みを　｜＾取（と）｜ろうと　｜＾思（おも）｜って　います。',
                    normal: '休みを　取ろうと　思って　います。',
                    en: "I'm thinking of taking a break.",
                    mm: 'အနားယူမယ်လို့ တွေးထားတယ်',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）',
                normal: '小川',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'それは　いいですね。',
                    normal: 'それは　いいですね。',
                    en: "That'd be nice",
                    mm: 'အဲ့ဒါ ကောင်းတယ်',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson32/kaiwa/4N32KW.mp3")
}

/*
{
        title: {
            furi: '',
            normal: '',
            en: '',
            mm: '',
        },
        conversation: [
            {
                person: {
                    furi: '',
                    normal: '',
                    avatar: Icon.Kaiwa.MEN1
                },
                sentence: [
                    {
                        furi: '',
                        normal: '',
                        en: '',
                        mm: '',
                    },
                ],
            },
        ],
        audioUrl: require("@assets/audio/lesson26/kaiwa/4N26KW.mp3")
    }
*/