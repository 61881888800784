import { memo } from "react";
import KanjiStroke from "@components/common/KanjiStrokeComponent";
import { CompoundTile, ExampleTile, ListTile, toString } from "@screens/home/category/KanjiDetailScreen";
import { Card } from "@material-tailwind/react";
const KanjiCardComponent = ({ id,url, strokes, lesson, kun, on, lang,mean}:any) => {
    return <div>
        <div className="flex flex-row h-[200px]">
            {/* Roll */}
            <div style={{ boxShadow:" 3px 3px 10px rgba(0, 0, 0, 0.25)"}} className="flex flex-col relative justify-center w-[55px] bg-[#F9ECC9] border-t-[14px] border-b-[14px] border-[#2Ea5ff]">
                <div className="flex flex-col justify-center mx-[10px] bg-[#fff] border-[3px] border-[#FFD175] text-[20px] font-[600] text-[#6C6C6C] text-center py-[15px]">
                    <div>漢</div>
                    <div>字</div>
                </div> 
                {/* Top */}
                <div className="absolute bg-[#6C6C6C] w-[30px] h-[14px] left-[12.5px] -top-[28px] rounded-t-[5px] "></div>
                {/* Bottom */}
                <div className="absolute bg-[#6C6C6C] w-[30px] h-[14px] left-[12.5px] -bottom-[27.75px] rounded-b-[5px]"></div>
            </div>
            {/* Card */}
            <div style={{ boxShadow: " 0 3px 10px rgba(0, 0, 0, 0.25)" }} className="flex-1 flex flex-row bg-white dark:bg-[#0E2D45] border-[14px] border-l-0 border-[#2Ea5ff]">
                {/* Stroke */}
                <div className="relative flex-1 flex flex-row justify-center items-center">
                    <KanjiStroke src={url} style="w-[150px] h-[150px]" />
                    <div className="absolute z-body top-0 right-2 text-[22px] font-[600]">
                        {id}
                    </div>
                </div>
            </div>
        </div>
        <Card className="mt-[40px] h-[200px] rounded-[20px] bg-white dark:bg-[#0E2D45] px-[28px] py-[15px] flex-1 flex flex-col justify-evenly">
            <div className="text-[18px] font-[600] w-fit  mb-2">
                Information
            </div>
            <ListTile title="Stroke" body={strokes} />
            <ListTile title="Lesson" body={lesson + 1} />
            <ListTile title="Kun Yomi" body={toString(kun)} />
            <ListTile title="On Youmi" body={toString(on)} />
            <ListTile title="Meaning" body={lang == "en" ? mean.en : mean.mm} />
        </Card>
    </div>
}
export default memo(KanjiCardComponent);