
export const lesson5 = [
    {"lesson":5,"level":4,"question":"まっしろ","represent":["真","白"],"answer":["歌手","画面","映画","真白"],"true":"真白","times":10},
    {"lesson":5,"level":4,"question":"しゃしん","represent":["写","真"],"answer":["写真","足音","旅費","映画"],"true":"写真","times":10},
    {"lesson":5,"level":4,"question":"えいが","represent":["映","画"],"answer":["写真","旅費","映画","音楽"],"true":"映画","times":10},
    {"lesson":5,"level":4,"question":"はつおん","represent":["発","音"],"answer":["足音","世界","映画","発音"],"true":"発音","times":10},
    {"lesson":5,"level":4,"question":"せいかい","represent":["界"],"answer":["世界","政界","画家","画面"],"true":"政界","times":10},
    {"lesson":5,"level":4,"question":"がか","represent":["画","家"],"answer":["世界","画面","真白","画家"],"true":"画家","times":10},
    {"lesson":5,"level":4,"question":"あしおと","represent":["足","音"],"answer":["画数","足音","歌手","画面"],"true":"足音","times":10},
    {"lesson":5,"level":4,"question":"せいき","represent":["世"],"answer":["世紀","画家","政界","真白"],"true":"世紀","times":10},
    {"lesson":5,"level":4,"question":"おんがく","represent":["音","楽"],"answer":["政界","世紀","旅館","音楽"],"true":"音楽","times":10},
    {"lesson":5,"level":4,"question":"かしゅ","represent":["歌","手"],"answer":["歌手","世紀","旅館","音楽"],"true":"歌手","times":10},
    {"lesson":5,"level":4,"question":"せかい","represent":["世","界"],"answer":["世界","政界","発音","世紀"],"true":"世界","times":10},
    {"lesson":5,"level":4,"question":"がめん","represent":["画"],"answer":["画家","写真","真白","画面"],"true":"画面","times":10},
    {"lesson":5,"level":4,"question":"りょひ","represent":["旅"],"answer":["世界","発音","旅費","写真"],"true":"旅費","times":10},
    {"lesson":5,"level":4,"question":"りょかん","represent":["旅","館"],"answer":["画家","旅館","歌手","世界"],"true":"旅館","times":10},
    {"lesson":5,"level":4,"question":"かくすう","represent":["画"],"answer":["発音","写真","画数","音楽"],"true":"画数","times":10},
]