import Helper from "@helper";
import { MojiType } from "..";

export const moji: MojiType = {
    mondai1:{
        title: 'もんだい１＿＿＿＿の　ことばは　ひらがなで　どう　かきますか。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
        questions: [
          {
            number: 1,
            question: '今日は　とても　｜！楽しかった｜　ですね。',
            answers: Helper.shuffle(['いそがしかった','すずしかった','たのしかった','かなしかった']),
            pick: '',
            correct: 'たのしかった'
          },
          {
            number: 2,
            question: 'わたしは　この　｜！味｜が　すきです。',
            answers: Helper.shuffle(['かたち','いろ','におい','あじ']),
            pick: '',
            correct: 'あじ'
          },
          {
            number: 3,
            question: 'この　あたりは　ちょっと　｜！不便｜　ですね。',
            answers: Helper.shuffle(['ふべん','ぶべん','ふへん','ぶへん']),
            pick: '',
            correct: 'ふべん'
          },
          {
            number: 4,
            question: 'やさいを　｜！切って｜　ください。',
            answers: Helper.shuffle(['とって','きって','あらって','もって']),
            pick: '',
            correct: 'きって'
          },
          {
            number: 5,
            question: 'はやしさん　｜！以外｜は　みんな　来ます。',
            answers: Helper.shuffle(['にそと','にがい','いそと','いがい']),
            pick: '',
            correct: 'いがい'
          },
          {
            number: 6,
            question: 'まどから　ずっと　｜！雲｜を　見て　いました。',
            answers: Helper.shuffle(['ほし','ゆき','くも','ぞら']),
            pick: '',
            correct: 'くも'
          },
          {
            number: 7,
            question: 'その　電車は　｜！急行｜　ですよ。',
            answers: Helper.shuffle(['きゅこ','きゅこう','きゅうこ','きゅうこう']),
            pick: '',
            correct: 'きゅうこう'
          },
          {
            number: 8,
            question: 'これは　｜！写さないで｜　ください。',
            answers: Helper.shuffle(['おさないで','うつさないで','けさないで','おとさないで']),
            pick: '',
            correct: 'うつさないで'
          },
          {
            number: 9,
            question: 'その　いけんには　｜！反対｜　です。',
            answers: Helper.shuffle(['はんたい','ほんたい','はんだい','ほんだい']),
            pick: '',
            correct: 'はんたい'
          },
        ]
      },
    mondai2:{
        title: 'もんだい２＿＿＿＿の　ことばは　どう　かきますか。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
        questions: [
            {
            number: 10,
            question: '　｜！くろい｜　くつしたが　ほしです。',
            answers: ['白い','黒い','赤い','青い'],
            pick: '',
            correct: '黒い'
            },
            {
            number: 11,
            question: 'なつやすみの　｜！けいかく｜は　まだ　きまって　いません。',
            answers: ['計書','訂画','計画','訂書'],
            pick: '',
            correct: '計画'
            },
            {
            number: 12,
            question: 'わたしは　｜！いしゃ｜に　なりたいです。',
            answers: ['匠員','医員','匠者','医者'],
            pick: '',
            correct: '医者'
            },
            {
            number: 13,
            question: 'あしたの　｜！よる｜　かぞくと　出かけます。',
            answers: ['夜','昼','夕','朝'],
            pick: '',
            correct: '夜'
            },
            {
            number: 14,
            question: 'かさを　｜！かして｜　ください。',
            answers: ['貨','資して','貸して','質して'],
            pick: '',
            correct: '貸して'
            },
            {
            number: 15,
            question: 'あしたは　サッカーの　｜！しあい｜が　あります。',
            answers: ['誡会','誡合','試会','試合'],
            pick: '',
            correct: '試合'
            },
        ]
    },
    mondai3:{
        title: 'もんだい３（　　）に　なにを　いれますか。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
        questions: [
            {
            number: 16,
            question: 'さとうさんが　けがを　したと　聞いて、みんな　（　　）しました。',
            answers: ['しんぱい','けいけん','しつれい','おじぎ'],
            pick: '',
            correct: 'しんぱい'
            },
            {
            number: 17,
            question: 'わたしには　しょうらい　かしゅに　なると　いう　（　　）が　あります。',
            answers: ['けしき','ゆめ','おもいで','せわ'],
            pick: '',
            correct: 'ゆめ'
            },
            {
            number: 18,
            question: 'リーさんも　こんどの　パーティーに　（　　）　来て　くださいね。',
            answers: ['ひじょうに','ぜひ','じゅうぶん','いつも'],
            pick: '',
            correct: 'ぜひ'
            },
            {
            number: 19,
            question: 'これから　きかいの　つかいかたを　（　　）しますから、よく　きいて　ください。',
            answers: ['じゅんび','りよう','せつめい','せいさん'],
            pick: '',
            correct: 'せつめい'
            },
            {
            number: 20,
            question: 'はが　わるいので、　（　　）　ものは　たべられません。',
            answers: ['きびしい','かたい','はやい','ふかい'],
            pick: '',
            correct: 'かたい'
            },
            {
            number: 21,
            question: 'もりさんをデートに　（　　）が、いけないと　言われました。',
            answers: ['さそいました','つたえました','あんないしました','しょうかいしました'],
            pick: '',
            correct: 'さそいました'
            },
            {
            number: 22,
            question: 'わたしの　もすこは　1年で　５（　　）くらい　せが　高く　なりました。',
            answers: ['グラム','ばん','けん','センチ'],
            pick: '',
            correct: 'センチ'
            },
            {
            number: 23,
            question: 'お店で　３だいの　パソコンを　（　　）、いちばん　かるい　パソコンを　えらびました。',
            answers: ['かたづけて','かぞえて','くらべて','はらって'],
            pick: '',
            correct: 'くらべて'
            },
            {
            number: 24,
            question: 'たなかさんの　いえの　電気が　ついていませんね。たなかさんは　（　　）の　ようです。',
            answers: ['うそ','じゆう','ちゅうし','るす'],
            pick: '',
            correct: 'るす'
            },
            {
            number: 25,
            question: 'へやの　かぎを　さがして　いますが、まだ　（　　）。',
            answers: ['みつかりません','つかまえません','しりません','さわりません'],
            pick: '',
            correct: 'みつかりません'
            },
        ]
    },
    mondai4:{
        title: 'もんだい4＿＿＿＿の ぶんと　だいたい　おなじ　いみの　ぶんが　あります。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
        questions: [
            {
            number: 26,
            question: 'おとうとは　あの　きっさてんで　アルバイトを　して　います。',
            answers: ['おとうとは　あの　きっさてんで　まって　います。','おとうとは　あの　きっさてんで　はたらいて　います。','おとうとは　あの　きっさてんで　コーヒを　飲んで　います。','おとうとは　あの　きっさてんで　友だちと　話して　います。'],
            pick: '',
            correct: 'おとうとは　あの　きっさてんで　はたらいて　います。'
            },
            {
            number: 27,
            question: 'わたしは　すいえいが　すきです。',
            answers: ['わたしは　はしるのが　すきです。','わたしは　およぐのが　すきです。','わたしは　ごはんを　食べるのが　すきです。','わたしは　本を　読むのが　すきです。'],
            pick: '',
            correct: 'わたしは　およぐのが　すきです。'
            },
            {
            number: 28,
            question: 'それを　聞いて　びっくりしました。',
            answers: ['それを　聞いて　わらいました。','それを　聞いて　こまりました。','それを　聞いて　おこりました。','それを　聞いて　おどろきました。'],
            pick: '',
            correct: 'それを　聞いて　おどろきました。'
            },
            {
            number: 29,
            question: 'あの　人は　うつくしですね。',
            answers: ['あの　人は　きれいですね。','あの　人は　げんきですね。','あの　人は　おもしろいですね。','あの　人は　わかいですね。'],
            pick: '',
            correct: 'あの　人は　きれいですね。'
            },
            {
            number: 30,
            question: 'この　国は　こめを　ゆにゅうして　います。',
            answers: ['この　国は　こめを　ほかの　国に　うって　います。','この　国は　こめを　ほかの　国から　もらって　います。','この　国は　こめを　ほかの　国から　買って　います。','この　国は　こめを　ほかの　国に　あげて　います。'],
            pick: '',
            correct: 'この　国は　こめを　ほかの　国から　買って　います。'
            },
        ]
    },
    mondai5:{
        title: 'もんだい５　つぎの ことばの　つかいかたで　いちばん　いい　ものを　１・２・３・４から　ひとつ　えらんで　ください。',
        questions: [
            {
            number: 31,
            question: '　さいきん',
            answers: [
                '！さいきん｜　りょうりが　できたので、いっしょに　食べましょう。',
                '！さいきん｜　しゅくだいを　出して　ください。',
                'きむらさんは　｜！さいきん｜　けっこんした　そうです。',
                '！さいきん｜　電車が　来ますから、いそいで　えきに　行きましょう。'
            ],
            pick: '',
            correct: 'きむらさんは　｜！さいきん｜　けっこんした　そうです。'
            },
            {
            number: 32,
            question: '　おと',
            answers: [
                'ラジオの　｜！おと｜が　大きいので、もう　少し　小さく　して　ください。',
                '日本語の　｜！おと｜が　じょうずに　なりたいので、毎日　たくさん　はなします。',
                '店の　人に　大きな　｜！おと｜で　名前を　よばれました。',
                'すずきさんが　ギターで　ゆうめいな　｜！おと｜を　ひいてくれました。',
            ],
            pick: '',
            correct: 'ラジオの　｜！おと｜が　大きいので、もう　少し　小さく　して　ください。'
            },
            {
            number: 33,
            question: '　けんがく',
            answers: [
                'かばんが　ほしいので、　でハートに　行って　｜！けんがく｜します。',
                'わからない　かんじは　じしょで　｜！けんがく｜して　ください。',
                '先生や　友だちと　こうじょうを　｜！けんがく｜しました。',
                'まいばん　テレビで　ニュースを　｜！けんがく｜して　います。',
            ],
            pick: '',
            correct: '先生や　友だちと　こうじょうを　｜！けんがく｜しました。'
            },
            {
            number: 34,
            question: '　かざる',
            answers: [
                'やまだ先生は　テストの　おしらせを　きょうしつに　｜！かざりました｜。',
                'おきゃくさんが　来ますから、へやに　花を　｜！かざりましょう｜。',
                '天気が　わるいので、せんたくものは　うちの　中に　｜！かざります｜。',
                'こっちの　エアコンは、となりの　へやの　かべに　｜！かざって｜　ください。',
            ],
            pick: '',
            correct: 'おきゃくさんが　来ますから、へやに　花を　｜！かざりましょう｜。'
            },
            {
            number: 35,
            question: '　こうじ',
            answers: [
                'はが　いたかったので、はいしゃで　｜！こうじ｜を　して　もらいました。',
                'この　セーターは　古いですが、｜！こうじ｜を　して、ずっと　きて　います。',
                'ほんだなが　こわれて　しまったので、｜！こうじ｜を　しました。',
                'この　みちは　｜！こうじ｜を　して　いるので、とおれません。',
            ],
            pick: '',
            correct: 'この　みちは　｜！こうじ｜を　して　いるので、とおれません。'
            },
        ]
    }
}