export const renshuuA = [
    {
        sentence: [
            {
                furi: "＾今（いま）｜にも　｜＾火（ひ）｜が　｜！きえ｜　そうです。",
                normal: "今にも　火が　きえ　そうです。",
                en: "It looks like the fire is about to go out.",
                mm: "မီးငြိမ်းသွားပုံရတယ်။",
            },
            {
                furi: "＾荷（に）物（もつ）｜が　｜！おち｜　そうです。",
                normal: "荷物が　おち　そうです。",
                en: "It seems that the luggage will fall.",
                mm: "ခရီးဆောင်အိတ် ပြုတ်ကျသွားပုံရတယ်။",
            },
            {
                furi: "あしたは　｜＾暑（あつ）｜く ｜！なり｜　そうです。",
                normal: "あしたは　暑く なり　そうです。",
                en: "It looks like it will be hot tomorrow.",
                mm: "မနက်ဖြန် ပူမဲ့ပုံဘဲ။",
            },
            {
                furi: "ことしは　｜＾輸（ゆ）出（しゅつ）｜が　｜！へり｜　そうです。",
                normal: "ことしは　輸出が　へり　そうです。",
                en: "Exports are likely to decline this year.",
                mm: "ယခုနှစ်တွင် တင်ပို့မှု ကျဆင်းဖွယ်ရှိသည်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson43/renshua/4N43RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "この　｜＾料（りょう）理（り）｜は　｜！まず｜　そうです。",
                normal: "この　料理は　まず　そうです。",
                en: "It seems that this dish is not delicious.",
                mm: "ဒီဟင်းက အရသာမရှိဘူးလို့ ထင်ရတယ်။",
            },
            {
                furi: "＾彼（かの）女（じょ）｜は　｜！やさし｜　そうです。",
                normal: "彼女は　やさし　そうです。",
                en: "She seems kind.",
                mm: "သူမသည် ကြင်နာပုံရတယ်။",
            },
            {
                furi: "この　｜＾机（つくえ）｜は　｜！じょうぶ｜　そうです。",
                normal: "この　机は　じょうぶ　そうです。",
                en: "This desk looks sturdy.",
                mm: "ဒီစားပွဲက ‌ခိုင်ခံ့မဲ့ပုံပဲ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson43/renshua/4N43RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "ちょっと　｜＾飲（の）｜み｜＾物（もの）｜を　｜！かって｜　｜＾来（き）｜ます。",
                normal: "ちょっと　飲み物を　かって　来ます。",
                en: "I'll buy you a drink.",
                mm: "ခဏလောက် သောက်စရာတစ်ခုခု သွားဝယ်လိုက်ဦးမယ်။",
            },
            {
                furi: "ちょっと　｜＾電（でん）話（わ）｜を　｜！かけて｜　｜＾来（き）｜ます。",
                normal: "ちょっと　電話を　かけて　来ます。",
                en: "I'll give you a call.",
                mm: "ခဏလောက် ဖုန်းခေါ်ပါမယ်။",
            },
            {
                furi: "ちょっと　｜！しょくじして｜　｜＾来（き）｜ます。",
                normal: "ちょっと　しょくじして　来ます。",
                en: "I'm going to have some food.",
                mm: "ခဏလောက် သွားစားလိုက်ဦးမယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson43/renshua/4N43RSA3.mp3")
    }
];