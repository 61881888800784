const logReg = [
    { // 0
        screen: 'App.tsx',
        output: false,
    },
    { // 1
        screen: 'RouterView.tsx',
        output: false,
    },
    { // 2
        screen: 'HomeScreen.tsx',
        output: true,
    },
    { // 3
        screen: 'CategoryScreen.tsx',
        output: false,
    },
    { // 4
        screen: 'ScoreScreen.tsx',
        output: false,
    },
    { // 5
        screen: 'SettingScreen.tsx',
        output: false,
    },
    { // 6
        screen: 'GrammarScreen.tsx',
        output: false,
    },
    { // 7
        screen: 'KaiwaScreen.tsx',
        output: false,
    },
    { // 8
        screen: 'KanjiScreen.tsx',
        output: false,
    },
    { // 9
        screen: 'MondaiScreen.tsx',
        output: false,
    },
    { // 10
        screen: 'Renshuu_A_Screen.tsx',
        output: false,
    },
    { // 11
        screen: 'Renshuu_B_Screen.tsx',
        output: false,
    },
    { // 12
        screen: 'Renshuu_C_Screen.tsx',
        output: false,
    },
    { // 13
        screen: 'VocabScreen.tsx',
        output: false,
    },
    {
        //Commmon use 14
        screen:'General',
        output:false
    },
    {
        // 15
        screen:'Kanji',
        output:false
    },
    {   
        //16
        screen:'KanjiDetail',
        output:false
    },
]
export default logReg;