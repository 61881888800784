interface Goi{
    goi: String;
    unicode: String;
    english: String;
}
const vocabTran = (test:Array<Goi>,lesson:Number)=>{
    let result:any = [];
    test.map(({goi,unicode,english}:Goi,index)=>{
        let targetDesign = {
            furi: furi(goi),
            normal: normal(goi),
            en: english,
            remark_en: "",
            mm: unicode,
            remark_mm: "",
            group: 0,
            audioUrl: `require("@assets/audio/lesson${lesson}/vocab/4N${lesson}VO${index+1}.mp3")`
        };
        result= [ ...result,targetDesign ]
    })
}

const normal = (furiString: String)=>{
    const path = furiString.split("」");
    let temp = '';
    path.map(s=>{
        if(s.includes("（")){
            s.split("）").map(p=>{
                temp += p.split("（")[0];
            });
        }else{
            temp+=s;
        }
    });
    return temp;
}
const furi = (furiString:String)=>{
    const path = furiString.split("」");
    let temp = '';
    let partial = '';
    path.map((s,i)=>{
        if(s.includes("（")){
            if(temp.length>0) partial+='｜＾';
            if(partial.length==0) partial+='＾';
            partial+=s;
            if(i===path.length-1 || !path[i+1].includes("（")){
                temp+= partial;
                if(i!==path.length-1) temp+='｜';
                partial= '';
            }
        }else{
            partial= '';
            temp+= s;
        }
    });
    return temp;
}
export default vocabTran;

/*

Ⅰ
Ⅱ
Ⅲ

const vocabTran = (test,lesson,max)=>{
    let result = [];
    test.map(({goi,unicode,english},index)=>{
        let targetDesign = {
            furi: furi(goi),
            normal: normal(goi),
            en: english,
            remark_en: "",
            mm: unicode,
            remark_mm: "",
            group: 0,
            audioUrl: index>max-1?null:`require("@assets/audio/lesson${lesson}/vocab/4N${lesson}VO${index+1}.mp3")`
        };
        result= [ ...result,targetDesign ]
    })
    console.log(result);
}

const normal = (furiString)=>{
    const path = furiString.split("」");
    let temp = '';
    path.map(s=>{
        if(s.includes("（")){
            s.split("）").map(p=>{
                temp += p.split("（")[0];
            });
        }else{
            temp+=s;
        }
    });
    return temp;
}
const furi = (furiString)=>{
    const path = furiString.split("」");
    let temp = '';
    let partial = '';
    path.map((s,i)=>{
        if(s.includes("（")){
            if(temp.length>0) partial+='｜＾';
            if(partial.length==0) partial+='＾';
            partial+=s;
            if(i===path.length-1 || !path[i+1].includes("（")){
                temp+= partial;
                if(i!==path.length-1) temp+='｜';
                partial= '';
            }
        }else{
            partial= '';
            temp+= s;
        }
    });
    return temp;
}
vocabTran(test,43,20);


*/