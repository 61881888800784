export const renshuuA = [
    {
        sentence: [
            {
                furi: "＾私（わたくし）｜が　コーヒーを　お｜！いれ｜します。",
                normal: "私がコーヒーを　おいれします。",
                en: "I'll make you some coffee.",
                mm: "ကျွန်‌တော် ကော်ဖီဖျော်ပေးပါမယ်။",
            },
            {
                furi: "＾私（わたくし）｜が　｜＾駅（えき）｜まで　お｜！おくり｜します。",
                normal: "私が　駅まで　おおくりします。",
                en: "I will drop you off at the station.",
                mm: "ကျွန်တော်က ဘူတာရုံထိ ပို့‌ဆောင်ပေးပါမယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson50/renshua/4N50RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾私（わたくし）｜が　きょうの　｜＾予（よ）定（てい）｜を　ご｜！せつめい｜します。",
                normal: "私が　きょうの　予定を　ごせつめい　します。",
                en: "I will explain today's schedule.",
                mm: "ကျွန်တော်က ဒီနေ့ အစီအစဉ်ကို ရှင်းပြပေးပါမယ်။",
            },
            {
                furi: "＾私（わたくし）｜が　できるだけ　｜＾早（はや）｜く　ご｜！れんらく｜　します。",
                normal: "私が　できるだけ　早く　ごれんらく　します。",
                en: "I will contact you as soon as possible.",
                mm: "ကျွန်တော်က တတ်နိုင်သလောက် အမြန်ဆုံး ဆက်သွယ်ပေးပါမယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson50/renshua/4N50RSA2.mp3")
    },
    {
        isTable: true,
        tables: [
            {
                head: {
                    a: {
                        val: "",
                        span: 1,
                        style: ""
                    },
                    b: {
                        val: "＾謙（けん）譲（じょう）語（ご）",
                        span: 1,
                        style: ""
                    },
                },
                body: [
                    {
                        l1: "いきます",
                        l2: "まいります",
                    },
                    {
                        l1: "きます",
                        l2: "",
                    },
                    {
                        l1: "います",
                        l2: "おります",
                    },
                    {
                        l1: "たべます",
                        l2: "いただきます",
                    },
                    {
                        l1: "のみます",
                        l2: "",
                    },
                    {
                        l1: "もらいます",
                        l2: "",
                    },
                    {
                        l1: "いいます",
                        l2: "もうします",
                    },
                    {
                        l1: "します",
                        l2: "いたします",
                    },
                    {
                        l1: "しって　います",
                        l2: "ぞんじて　おります",
                    },
                    {
                        l1: "しりません",
                        l2: "ぞんじません",
                    },
                    {
                        l1: "みます",
                        l2: "はいけんします",
                    },
                    {
                        l1: "ききます",
                        l2: "うかがいます",
                    },
                    {
                        l1: "（うちへ）いきます",
                        l2: "",
                    },
                    {
                        l1: "あいます",
                        l2: "おめに　かかります",
                    },

                ],
                style: {
                    l1: "p-2 text-center bg-white dark:bg-darkFooter",
                    l2: "p-2 text-center bg-white dark:bg-darkFooter",
                }
            },
        ],
        audioUrl: require("@assets/audio/lesson50/renshua/4N50RSA3.mp3")
    },
    {
        sentence: [
            {
                furi: "あした　３｜＾時（じ）｜に　お｜＾宅（たく）｜へ　｜！うかがいます。",
                normal: "あした　３時に　お宅へ　うかがいます。",
                en: "I will come to your house at 3 o'clock tomorrow.",
                mm: "မနက်​ဖြန်​ ၃ နာရီမှာ အိမ်​ကို လာလည်ပါမယ်​။",
            },
            {
                furi: "来週　｜！おめに　かかります。",
                normal: "来週 おめに　かかります。",
                en: "See you next week.",
                mm: "လာမည့်အပတ်မှာတွေ့ကြပါမယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson50/renshua/4N50RSA4.mp3")
    },
    {
        sentence: [
            {
                furi: "＾私（わたくし）｜は　ミラーと　もうします。",
                normal: "私は　ミラーと　もうします。",
                en: "My name is Miller.",
                mm: "ကျွန်တော့်နာမည်က Miller လို့‌‌ခေါ်ပါတယ်။",
            },
            {
                furi: "＾私（わたくし）｜は　ＩＭＣに　｜！つとめて　おります。",
                normal: "私は　ＩＭＣに　つとめて　おります。",
                en: "I am working at IMC.",
                mm: "ကျွန်‌တော် IMCမှာ အလုပ်လုပ်‌နေပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson50/renshua/4N50RSA5.mp3")
    },
];