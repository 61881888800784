import Icon from "@consts/icon";

export const lesson46 = {
    title: {
        furi: '＾先（せん）週（しゅう）｜　｜＾直（なお）｜して　もらった　ばかりなのに、　また。。。',
        normal: '先週　直して　もらった　ばかりなのに、　また。。。',
        en: 'I just got it fixed last week, but again. . .',
        mm: 'အရင်အပတ်ကမှ ပြင်ထားတာဖြစ်ပေမယ့်လည်း နောက်ထပ် . . .',
    },
    conversation: [
        {
            person: {
                furi: '＾係（かかり）員（いん）',
                normal: '係員',
                avatar: Icon.Kaiwa.OFFICER
            },
            sentence: [
                {
                    furi: 'はい、ガスサービスセンターで　ございます。',
                    normal: 'はい、ガスサービスセンターで　ございます。',
                    en: 'Yes, it is a gas service center.',
                    mm: 'ဟုတ်ကဲ့၊ ဂက်စ်ဝန်ဆောင်မှုစင်တာကဖြစ်ပါတယ်',
                },
            ],
        },
        {
            person: {
                furi: 'タワポン',
                normal: 'タワポン',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'あのう、ガスレンジの｜＾調（ちょう）子（し）｜が　おかしんですが。。。',
                    normal: 'あのう、ガスレンジの　調子が　おかしんですが。。。',
                    en: "Um, there's something wrong with the gas range. . .",
                    mm: 'ဟိုလေ၊ ဂက်စ်မီးဖိုရဲ့ အခြေအနေက ထူးဆန်းနေလို့ပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾係（かかり）員（いん）',
                normal: '係員',
                avatar: Icon.Kaiwa.OFFICER
            },
            sentence: [
                {
                    furi: 'どんな｜＾具（ぐ）合（あい）｜ですか。',
                    normal: 'どんな　具合ですか。',
                    en: 'How is it?',
                    mm: 'ဘယ်လိုအခြေအနေမျိုးပါလည်း',
                },
            ],
        },
        {
            person: {
                furi: 'タワポン',
                normal: 'タワポン',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '＾先（せん）週（しゅう）｜　｜＾直（なお）｜して　もらった　ばかりなのに、また　｜＾火（ひ）｜が　｜＾消（き）｜えてしまうんです。',
                    normal: '先週　直して　もらった　ばかりなのに、また　火が　消えてしまうんです。',
                    en: 'I just got it fixed last week, but the fire is going out again.',
                    mm: 'အရင်အပတ်ကမှ ပြင်ထားတာဖြစ်ပေမယ့်လည်း နောက်ထပ် ဂက်စ်မီးက မရတော့ဘူး',
                },
                {
                    furi: '＾危（あぶ）｜ないので、すぐ　｜＾見（み）｜に　｜＾来（き）｜て　くれませんか。',
                    normal: '危ないので、すぐ　見に　来て　くれませんか。',
                    en: "It's dangerous, so could you come and see it right away?",
                    mm: 'အန္တရာယ်များတဲ့အတွက် ချက်ချင်းလာကြည့်ပေးလို့ရမလား',
                },
            ],
        },
        {
            person: {
                furi: '＾係（かかり）員（いん）',
                normal: '係員',
                avatar: Icon.Kaiwa.OFFICER
            },
            sentence: [
                {
                    furi: 'わかりました。５｜＾時（じ）｜ごろには　｜＾行（い）｜けると　｜＾思（お）｜います。',
                    normal: 'わかりました。５時ごろには　行けると　思います。',
                    en: 'got it. I think I can go around 5:00.',
                    mm: 'နားလည်ပါပြီ ၅ နာရီလောက်မှာ လာနိုင်မယ်လို့ထင်ပါတယ်',
                },
                {
                    furi: 'ご｜＾住（じゅう）所（しょ）｜と　お｜＾名（な）前（まえ）｜を　お｜＾願（ねが）｜いします。',
                    normal: 'ご住所と　お名前を　お願いします。',
                    en: 'Please give me your name and address.',
                    mm: 'လိပ်စာနဲ့ နာမည်ကို ပြောပြပေးပါ',
                },
                {
                    furi: '。。。。。。。。。。。。。。。',
                    normal: '。。。。。。。。。。。。。。。',
                    en: '',
                    mm: '',
                },
            ],
        },
        {
            person: {
                furi: 'タワポン',
                normal: 'タワポン',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'もしもし、５｜＾時（じ）｜ごろに　ガスレンジを　｜＾見（み）｜に　｜＾来（き）｜て　くれるはずなんですが、まだですか。',
                    normal: 'もしもし、５時ごろに　ガスレンジを　見に　来て　くれるはずなんですが、まだですか。',
                    en: "Hello, he should have come to see the gas range around 5 o'clock, but he hasn't come yet?",
                    mm: 'ဟဲလို ၅ နာရီမှာ ဂက်စ်မီးဖို့ကို လာကြည့်မယ်လို့သေချာပြောထားပေမယ့် အခုထိမလာသေးလို့ပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾係（かかり）員（いん）',
                normal: '係員',
                avatar: Icon.Kaiwa.OFFICER
            },
            sentence: [
                {
                    furi: '＾申（もう）｜し｜＾訳（わけ）｜ありません。どちら｜＾様（さま）｜でしょうか。',
                    normal: '申し訳ありません。どちら様でしょうか。',
                    en: "very sorry. May I ask who's calling.",
                    mm: 'တောင်းပန်ပါတယ်၊ အခုပြောနေတာ ဘယ်သူလည်းခင်ဗျ',
                },
            ],
        },
        {
            person: {
                furi: 'タワポン',
                normal: 'タワポン',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'タワポンです。',
                    normal: 'タワポンです。',
                    en: "It's Tawapon.",
                    mm: 'တာဝပွန်းပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾係（かかり）員（いん）',
                normal: '係員',
                avatar: Icon.Kaiwa.OFFICER
            },
            sentence: [
                {
                    furi: '＾少（しょう）々（しょう）｜　お｜＾待（ま）｜ち　ください。｜＾係（かかり）員（いん）｜に　｜＾連（れん）絡（らく）｜しますから。',
                    normal: '少々　お待ち　ください。係員に　連絡しますから。',
                    en: 'please wait a moment. I will contact the staff.',
                    mm: 'ခဏစောင့်ပေးပါခင်ဗျ၊ တာဝန်ရှိတဲ့သူကို ဆက်သွယ်ပေးပါမယ်',
                },
                {
                    furi: '。。。。。。。。。。。。。。。',
                    normal: '。。。。。。。。。。。。。。。',
                    en: '',
                    mm: '',
                },
                {
                    furi: 'お｜＾待（ま）｜たせしました。',
                    normal: 'お待たせしました。',
                    en: 'Sorry I made you wait.',
                    mm: 'စောင့်ခိုင်းသလိုဖြစ်သွားလို့ တောင်းပန်ပါတယ်',
                },
                {
                    furi: '＾今（いま）｜　そちらに　｜＾向（む）｜かって　いる　ところです。',
                    normal: '今　そちらに　向かって　いる　ところです。',
                    en: 'I am on my way there now.',
                    mm: 'အခု အဲ့ဒီကို လာနေပြီဖြစ်ပါတယ်',
                },
                {
                    furi: 'あと　１０｜＾分（ぷん）｜ほど　お｜＾待（ま）｜ち　ください。',
                    normal: 'あと　１０分ほど　お待ち　ください。',
                    en: 'Please wait another 10 minutes.',
                    mm: 'နောက်ထပ် ၁၀ မိနစ်လောက်စောင့်ပေးပါခင်ဗျ',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson46/kaiwa/4N46KW.mp3")
}
/*
{
    title: {
        furi: '',
        normal: '',
        en: '',
        mm: '',
    },
    conversation: [
        {
            person: {
                furi: '',
                normal: '',
                avatar: Icon.Kaiwa.OFFICER
            },
            sentence: [
                {
                    furi: '',
                    normal: '',
                    en: '',
                    mm: '',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson36/kaiwa/4N36KW.mp3")
}
*/