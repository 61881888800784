export const lesson15 = {
    "代": 140,
    "使": 141,
    "作": 142,
    "化": 143,
    "信": 144,
    "進": 145,
    "送": 146,
    "返": 147,
    "洗": 148,
    "注": 149
};