export const bunkei= [
    {
        sentence: [
            {
                furi: "ニュースを　｜＾聞（き）｜いて、びっくりしました。",
                normal: "ニュースを　聞いて、びっくりしました。",
                en: "I was surprised when I heard the news.",
                mm: "သတင်းကြားတော့ အံ့သြသွားတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson39/bunpou/4N39BK1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾地（じ）震（しん）｜で　ビルが　｜＾倒（たお）｜れました。",
                normal: "地震で　ビルが　倒れました。",
                en: "The building collapsed in the earthquake.",
                mm: "ငလျင်ကြောင့် အဆောက်အအုံပြိုကျခဲ့ပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson39/bunpou/4N39BK2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾体（からだ）｜の　｜＾調（ちょう）子（し）｜が　｜＾悪（わる）｜いので、　｜＾病（びょう）院（いん）｜へ　｜＾行（い）｜きます。",
                normal: "体の　調子が　悪いので、　病院へ　行きます。",
                en: "I'm not feeling well, so I'm going to the hospital.",
                mm: "နေမကောင်းလို့ ဆေးရုံသွားလိုက်ရတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson39/bunpou/4N39BK3.mp3")
    },
]