import {
    Popover,
    PopoverContent,
    PopoverHandler
} from "@material-tailwind/react";
import { useState } from "react";
import { FiDelete } from "react-icons/fi";
import { memo } from "react";
import { Minna } from "@consts";
const MondaiPopOver1 = ({ children, questionId, boxId, callBack, answers, picked }: any) => {
    const { mean, url, kun, on, strokes, level } = Minna.kanji.information[boxId];
    

    const [openPopover, setOpenPopover] = useState(false);
    const callBackAction = ({ questionId, boxId, ans }: any) => {
        callBack({ questionId, boxId, ans });
        setOpenPopover(false);        
    }
    return (
        <Popover open={openPopover} handler={setOpenPopover} >
            <PopoverHandler onClick={() => setOpenPopover(true)}>
                {children}
            </PopoverHandler>
            <PopoverContent className="mx-auto w-[300px] z-[9999] flex flex-row dark:text-white dark:bg-darkFooter">
                <div className={`flex-1 grid grid-cols-4 gap-y-2 place-items-center btn`}>
                    {
                        answers.map( (ans:string,index: number)=>{
                            return (
                                <div onClick={()=>callBackAction({ questionId, boxId, ans })}
                                    key={index}
                                    className={`pb-1 ${index % 2 == 0 ? '' : ''}`}>
                                    {ans}
                                </div>
                            )
                        } )
                    }
                </div>
                <div className="text-red-500 select-none cursor-pointer dark:border-darkFooter" onClick={() => callBackAction({ questionId, boxId, ans: "" }) }>
                    <FiDelete size={20} />   
                </div>
            </PopoverContent>
        </Popover>
    );
}
export default memo(MondaiPopOver1);

/*
    import {
    Popover,
    PopoverContent,
    PopoverHandler
} from "@material-tailwind/react";
import { FiDelete } from "react-icons/fi";
import { memo } from "react";
const MondaiPopOver1 = ({ children, questionId,boxId,callBack,answers,picked,pickedId }: any) => {
    let maxItemLength = 1;
    answers.map( ({ ans, select }:any)=>{
        if(ans.length>maxItemLength){
            maxItemLength = ans.length;
        }
    } )
    // const gridStyle = maxItemLength<5? 'grid-cols-3' : maxItemLength<10? 'grid-cols-2': 'grid-cols-1';
    return (
        <Popover>
            <PopoverHandler>
                {children}
            </PopoverHandler>
            <PopoverContent className="mx-auto w-[300px] z-[9999]">
                <div className={`grid grid-cols-5 gap-y-2 place-items-center dark:text-black`}>
                    {
                        answers.map( ({ ans, select }:any,index: number)=>{
                            return (
                                <div onClick={() =>select?()=>{}: callBack({ questionId, boxId, ansId: index })}
                                    key={index}
                                    className={`pb-1 ${index % 2 == 0 ? '' : ''} ${select ? 'text-blue-500 font-bold' : ''} ${pickedId[boxId] === index?'border px-2  py-1 border-blue-400 rounded':''}`}>
                                    {ans}
                                </div>
                            )
                        } )
                    }
                    <div className="text-red-500 select-none cursor-pointer bg-slate-50" onClick={() => callBack({ questionId, boxId, ansId: -1 })}>
                        <FiDelete size={20} />   
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    );
}
export default memo(MondaiPopOver1);
*/