export const lesson20 = {
    "引": 190,
    "開": 191,
    "閉": 192,
    "去": 193,
    "死": 194,
    "集": 195,
    "知": 196,
    "売": 197,
    "説": 198,
    "思": 199
};