import DefaultLayout from "@layouts/default/DefaultLayout";
import { memo } from "react";
const HomeScreenLayout = ({ lang, showModel,setShowModel,setUnlockPremium, body, Routes, useNavigate, Logger }:any) => {

    return (
        <DefaultLayout 
            navbarProps={{
                root: false,
                appBar: { title: "", centerTitle: true, leadingIcon: false, leadingRoute: Routes.HOME }
            }}
            body={body}
            Routes={Routes}
            useNavigate={useNavigate}
            Logger={Logger}
            lang={lang}
            setShowModel={setShowModel}
            showModel={showModel}
            setUnlockPremium={setUnlockPremium}
        />
   )
}
export default memo(HomeScreenLayout);