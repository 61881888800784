
export const lesson20 = [
    {"lesson":20,"level":4,"question":"でんせつ","represent":["説"],"answer":["伝説","売店","死者","急死"],"true":"伝説","times":10},
    {"lesson":20,"level":4,"question":"かこ","represent":["去"],"answer":["過去","去年","小説","引力"],"true":"過去","times":10},
    {"lesson":20,"level":4,"question":"へいかいする","represent":["閉","会"],"answer":["閉会する","説教する","開店する","集中する"],"true":"閉会する","times":10},
    {"lesson":20,"level":4,"question":"ししゃ","represent":["死","者"],"answer":["死者","病死","去年","急死"],"true":"死者","times":10},
    {"lesson":20,"level":4,"question":"いんようする","represent":["引","用"],"answer":["引用する","開会する","説教する","開会する"],"true":"引用する","times":10},
    {"lesson":20,"level":4,"question":"ちしき","represent":["知"],"answer":["知識","病死","急死","引力"],"true":"知識","times":10},
    {"lesson":20,"level":4,"question":"びょうし","represent":["病","死"],"answer":["病死","去年","売店","引力"],"true":"病死","times":10},
    {"lesson":20,"level":4,"question":"かいてんする","represent":["開","店"],"answer":["開店する","集中する","引用する","通知する"],"true":"開店する","times":10},
    {"lesson":20,"level":4,"question":"かいかいする","represent":["開","会"],"answer":["開会する","通知する","開店する","集中する"],"true":"開会する","times":10},
    {"lesson":20,"level":4,"question":"せっきょうする","represent":["説","教"],"answer":["説教する","開店する","閉店する","引用する"],"true":"説教する","times":10},
    {"lesson":20,"level":4,"question":"しょうせつ","represent":["小","説"],"answer":["小説","説明","死者","引力"],"true":"小説","times":10},
    {"lesson":20,"level":4,"question":"きゅうし","represent":["急","死"],"answer":["急死","伝説","売店","引力"],"true":"急死","times":10},
    {"lesson":20,"level":4,"question":"しゅうごうする","represent":["集","合"],"answer":["集合する","通知する","説教する","集中する"],"true":"集合する","times":10},
    {"lesson":20,"level":4,"question":"いんりょく","represent":["引","力"],"answer":["引力","急死","過去","売店"],"true":"引力","times":10},
    {"lesson":20,"level":4,"question":"つうちする","represent":["通","知"],"answer":["通知する","集中する","引用する","開店する"],"true":"通知する","times":10},
    {"lesson":20,"level":4,"question":"きょねん","represent":["去","年"],"answer":["去年","引力","小説","知識"],"true":"去年","times":10},
    {"lesson":20,"level":4,"question":"ばいてん","represent":["売","店"],"answer":["売店","知識","小説","死者"],"true":"売店","times":10},
    {"lesson":20,"level":4,"question":"せつめい","represent":["説","明"],"answer":["説明","急死","死者","去年"],"true":"説明","times":10},
    {"lesson":20,"level":4,"question":"しゅうちゅうする","represent":["集","中"],"answer":["集中する","引用する","通知する","開店する"],"true":"集中する","times":10},
    {"lesson":20,"level":4,"question":"へいてんする","represent":["閉","店"],"answer":["閉店する","説教する","開店する","閉会する"],"true":"閉店する","times":10},
]