import { Minna } from "@consts";
import { memo } from "react";
import KanjiInfoWebComponent from "./KanjiInfoWebComponent";
import SizeBox from "@components/SizeBox";
import KanjiCompoundWebComponent from "./KanjiCompoundWebComponent";
import KanjiExampleWebComponent from "./KanjiExampleWebComponent";
 
const KanjiDetailWebComponent = ({ Routes,lesson,  kanjiId, lang, furigana, fontSize,furiTranslate,kanjiDetail }: any) => {
    const { id,level, kanji, mean, url, strokes, kun, on, compound, example } = Minna.kanji.information[kanjiId];
    // console.log({ id, level, kanji, url, strokes })
    return <div className="hidden md:block dark:text-black">
        {/* Testing */}
        <KanjiInfoWebComponent
            id={id + 1}
            url={url}
            strokes={strokes}
            lesson={lesson}
            kun={kun}
            on={on}
            lang={lang}
            mean={mean} />
        <SizeBox vertical="h-[70px]"/>
        <KanjiCompoundWebComponent
            compound={compound}
            furigana={furigana}
            furiTranslate={furiTranslate}
            fontSize={fontSize}
            kanjiDetail={kanjiDetail}
            lang={lang}
            lesson={lesson} />
        <SizeBox vertical="h-[70px]" />
        <KanjiExampleWebComponent
            example={example}
            furigana={furigana}
            furiTranslate={furiTranslate}
            fontSize={fontSize}
            kanjiDetail={kanjiDetail}
            lang={lang} />
        <SizeBox vertical="h-[70px]" />
    </div>
}
export default memo(KanjiDetailWebComponent);