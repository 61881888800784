import { memo } from "react";
const KanjiGameScreenLayout = ({ body, Routes,  Logger, navbarProps, fontSize }: any) => {
    return (
        <div>
            {
                body
            }
            {/* <SizeBox vertical="h-20 md:h-32" /> */}
            {/* <FooterComponent Routes={Routes} useNavigate={useNavigate} Logger={Logger} currentRoute={currentRoute} /> */}
        </div>

    )
}
export default memo(KanjiGameScreenLayout);