import Icon from "@consts/icon";

export const lesson29 = {
    title: {
        furi: '＾忘（わす）｜れ｜＾物（もの）｜を　して　しまったんです',
        normal: '忘れ物を　して　しまったんです',
        en: 'I forgot something',
        mm: 'မေ့ကျန်ပစ္စည်းလေး ရှိလို့ပါ။',
    },
    conversation: [
        {
            person: {
                furi: 'イー',
                normal: 'イー',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: 'すみません。　｜＾今（いま）｜の　｜＾電（でん）車（しゃ）｜に　｜＾忘（わす）｜れ｜＾物（もの）｜を　してしまったんですが　。。。',
                    normal: 'すみません。　今の　電車に　忘れ物を　してしまったんですが　。。。',
                    en: 'Excuse me, I forgot something on the train.',
                    mm: 'တစ်ဆိတ်လောက်ပါ၊ အခုထွက်သွားတဲ့ ရထားပေါ်မှာ မေ့ကျန်ပစ္စည်းလေး ရှိလို့ပါ။',
                },
            ],
        },
        {
            person: {
                furi: '＾駅（えき）員（いん）',
                normal: '駅員',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '＾何（なに）｜を　｜＾忘（わす）｜れたんでか。',
                    normal: '何を　忘れたんでか。',
                    en: 'What did you forget?',
                    mm: 'ဘာကို မေ့သွားတာလဲ။',
                },
            ],
        },
        {
            person: {
                furi: 'イー',
                normal: 'イー',
                avatar: Icon.Kaiwa.GIRL

            },
            sentence: [
                {
                    furi: '＾青（あお）｜い　かばんです。　このくらいの　。。。',
                    normal: '青い　かばんです。　このくらいの　。。。',
                    en: 'Blue bag.このくらいの',
                    mm: 'အပြာရောင်အိတ်‌ပါ။ ဒီလောက်‌လောက်ဘဲ',
                },
                {
                    furi: '＾外（そと）側（がわ）｜に　｜＾大（おお）｜きい　ポケットが　｜＾付（つ）｜いて　います。',
                    normal: '外側に　大きい　ポケットが　付いて　います。',
                    en: 'On the outside, there are large pockets.',
                    mm: 'အပြင်ဘက်မှာ အိတ်ကပ် အကြီးကြီးပါပါတယ်။',
                },
            ],
        },
        {
            person: {
                furi: '＾駅（えき）員（いん）',
                normal: '駅員',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'どの　｜＾辺（へん）｜に　｜＾置（お）｜きましたか。',
                    normal: 'どの　辺に　置きましたか。',
                    en: 'What side did you put it on?',
                    mm: 'မင်းဘယ်နားမှာ ထားခဲ့တာလဲ။',
                },
            ],
        },
        {
            person: {
                furi: 'イー',
                normal: 'イー',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: 'よく　｜＾覚（おぼ）｜えて　いません。　でも、　｜＾網（あみ）棚（だな）｜の　｜＾上（うえ）｜に　｜＾置（お）｜きました',
                    normal: 'よく　覚えて　いません。　でも、　網棚の　上に　置きました',
                    en: "I don't remember well. But I put it on the shelf.",
                    mm: 'ကောင်းကောင်းမမှတ်မိတော့ဘူး။ ဒါပေမဲ့ စင်ပေါ်မှာ ထားထားတယ်။',
                },
            ],
        },
        {
            person: {
                furi: '＾駅（えき）員（いん）',
                normal: '駅員',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '＾中（なか）｜に　｜＾何（なに）｜が　｜＾入（はい）｜って　いますか。',
                    normal: '中に　何が　入って　いますか。',
                    en: "What's in it?",
                    mm: 'အထဲမှာ ဘာပါလဲ?',
                },
            ],
        },
        {
            person: {
                furi: 'イー',
                normal: 'イー',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: 'えーと、｜＾確（たし）｜か、｜＾本（ほん）｜と　｜＾傘（かさ）｜が　｜＾入（はい）｜って　います。',
                    normal: 'えーと、確か、本と　傘が　入って　います。',
                    en: 'A book and an umbrella to be exact.',
                    mm: 'ဟိုလေ သေချာတာကတော့ စာအုပ်နဲ့ ထီးပါတယ်။',
                },
            ],
        },
        {
            person: {
                furi: '＾駅（えき）員（いん）',
                normal: '駅員',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'じゃ、｜＾調（しら）｜べますから、ちょっと　｜＾待（ま）｜って　いて　ください。',
                    normal: 'じゃ、調べますから、ちょっと　待って　いて　ください。',
                    en: "I'll look into it, so please wait a moment.",
                    mm: 'ကောင်းပါပြီ၊ ရှာပေးမှာမို့လို့ ခဏလေးစောင့်ပေးပါ။',
                },
                {
                    furi: '。。。。。。。。。。。。。。。',
                    normal: '。。。。。。。。。。。。。。。',
                    en: '。。。。。。。。。。。。。。。',
                    mm: '。。。。。。。。。。。。。。。',
                },
            ],
        },
        {
            person: {
                furi: '＾駅（えき）員（いん）',
                normal: '駅員',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'ありましたよ。',
                    normal: 'ありましたよ。',
                    en: 'there is',
                    mm: 'ရှိသေးတယ်',
                },
            ],
        },
        {
            person: {
                furi: 'イー',
                normal: 'イー',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: 'ああ、よかった。',
                    normal: 'ああ、よかった。',
                    en: 'Oh good.',
                    mm: 'အိုး ‌တော်သေးတာ‌ပေါ့။',
                },
            ],
        },
        {
            person: {
                furi: '＾駅（えき）員（いん）',
                normal: '駅員',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '＾今（いま）｜　｜＾新（しん）宿（じゅく）駅（えき）｜に　ありますが、どう　しますか。',
                    normal: '今　新宿駅に　ありますが、どう　しますか。',
                    en: "It's at Shinjuku station now, what should I do?",
                    mm: 'အခု Shinjuku ဘူတာမှာ ရောက်နေပါပြီ၊ ဘယ်လိုလုပ်မလဲ။',
                },
            ],
        },
        {
            person: {
                furi: 'イー',
                normal: 'イー',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: 'すぐ　｜＾取（と）｜りに　｜＾行（い）｜きます。',
                    normal: 'すぐ　取りに　行きます。',
                    en: "I'll go get it right away.",
                    mm: 'အခုချက်ချင်းသွားယူလိုက်ပါမယ်ရှင့်။',
                },
            ],
        },
        {
            person: {
                furi: '＾駅（えき）員（いん）',
                normal: '駅員',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'じゃ、｜＾新（しん）宿（じゅく）駅（えき）｜の　｜＾事（じ）務（む）所（しょ）｜に　｜＾行（い）｜って　ください。',
                    normal: 'じゃ、新宿駅の　事務所に　行って　ください。',
                    en: 'Then, please go to the office at Shinjuku station.',
                    mm: 'ကောင်းပါပြီ Shinjuku ဘူတာရုံကို သွားလိုက်ပါ။',
                },
            ],
        },
        {
            person: {
                furi: 'イー',
                normal: 'イー',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: 'はい。どうも　ありがとう　ございました。',
                    normal: 'はい。どうも　ありがとう　ございました。',
                    en: 'yes. Thank you very much',
                    mm: 'ဟုတ်ကဲ့ ကျေးဇူးအများကြီးတင်ပါတယ်ရှင်။',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson29/kaiwa/4N29KW.mp3")
}

/*

    {
        title: {
            furi: '',
            normal: '',
            en: '',
            mm: '',
        },
        conversation: [
            {
                person: {
                    furi: '',
                    normal: '',
                },
                sentence: [
                    {
                        furi: '',
                        normal: '',
                        en: '',
                        mm: '',
                    },
                ],
            },
        ],
        audioUrl: require("@assets/audio/lesson26/kaiwa/4N26KW.mp3")
    }
*/