import { BunpouType } from "..";

export const bunpou: BunpouType = {
  mondai1:{
    title: 'もんだい１（　　）に　何を　入れますか。１・２・３・４から　いちばん　いい　ものを　ーつ　えらんで　ください。',
    questions: [
      {
        number: 1,
        question: [
          '(1)ここから　駅まで、車なら　１０分（　　）行けます。',
        ],
        answers: ['が','に','で','を'],
        pick: '',
        correct: 'で'
      },
      {
        number: 2,
        question: [
          '(2)（電話で）',
          '「では、また　後で　電話する（　　）　伝えて　ください。」',
        ],
        answers: ['と','は','を','に'],
        pick: '',
        correct: 'と'
      },
      {
        number: 3,
        question: [
          '(3)私は、さとう（　　）　ミルク（　　）　入れないで　コーヒーを　飲みます。',
        ],
        answers: ['は/は','に/に','で/で','も/も'],
        pick: '',
        correct: 'も/も'
      },
      {
        number: 4,
        question: [
          '(4)A「今から　サッカーを　しませんか。」',
          'B「すみません、ともだち（　　）　待たせて　いるので、また　今度　さそって　ください。」',
        ],
        answers: ['を','の','へ','で'],
        pick: '',
        correct: 'を'
      },
      {
        number: 5,
        question: [
          '(5)A[アルバイトは、毎日　して　いますか。」',
          'B「いいえ、月曜日（　　）です。」'
        ],
        answers: ['も','しか','の','だけ'],
        pick: '',
        correct: 'だけ'
      },
      {
        number: 6,
        question: [
          '(6)大阪に　来た（　　）　今日が　初めてです。',
        ],
        answers: ['を','は','のを','のは'],
        pick: '',
        correct: 'のは'
      },
      {
        number: 7,
        question: [
          '(7)レポートは、今週（　　）　出して　ください。'
        ],
        answers: ['の間','中に','とき','の前に'],
        pick: '',
        correct: '中に'
      },
      {
        number: 8,
        question: [
          '(8)今、じゅぎょうで　町の　れきし（　　）　調べて　います。'
        ],
        answers: ['のことが','によって','のほうが','について'],
        pick: '',
        correct: 'について'
      },
      {
        number: 9,
        question: [
          '(9)A「きのうは　どうして　来なかったんです。（　　）　待っていたんですよ。」',
          'B「すみません」',
        ],
        answers: ['よく','ずっと','いつか','かならず'],
        pick: '',
        correct: 'ずっと'
      },
      {
        number: 10,
        question: [
          '(10)A「先週　いっしょに　行った　レストランに　今夜　行きませんか。」',
          'B「駅前の　（　　）　店ですか。いいですね。」',
        ],
        answers: ['こんな','あの','そんな','どの'],
        pick: '',
        correct: 'あの'
      },
      {
        number: 11,
        question: [
          '(11)私は、ピアノと　ギターを（　　）が　できる。'
        ],
        answers: ['ひく','ひける','ひくこと','ひけること'],
        pick: '',
        correct: 'ひくこと'
      },
      {
        number: 12,
        question: [
          '(12)A「地下鉄の　駅は　ここから　遠いですか。」',
          'B「いいえ、　近いですよ。だいたい　５分（　　）。」',
        ],
        answers: ['ごろです','ぐらいです','遠いです','着きます'],
        pick: '',
        correct: 'ぐらいです'
      },
      {
        number: 13,
        question: [
          '(13)妻「こどもの　名前は、女の子が（　　）「えり」で、男の子が（　　）「けん」は　どう？」',
          '夫「いいね。ぼくは　女の子が　いいなあ。」',
        ],
        answers: ['生まれたら/生まれたら','生まれても/生まれても','生まれるけど/生まれるけど','生まれたから/生まれたから'],
        pick: '',
        correct: '生まれたら/生まれたら'
      },
      {
        number: 14,
        question: [
          '(14)（タクシーで）',
          '「新幹線の　出発まで　もう　時間（　　）。運転手さん、急いで　ください。',
        ],
        answers: ['が　あります','に　なります','が　ありますせん','に　なりません'],
        pick: '',
        correct: 'が　ありますせん'
      },
      {
        number: 15,
        question: [
          '(15)この　ページには　何も　（　　）。',
        ],
        answers: ['書いては　いけません','書いた方あ　いいです','書いなければ　なりません','書いて　ください'],
        pick: '',
        correct: '書いては　いけません'
      },
    ]
  },
  mondai2:{
    title: 'もんだい（2）｜！ ★ ｜ に　何を　入れますか。１・２・３・４から　いちばん　いい　ものを　ーつ　えらんで　ください。',
    questions: [
      {
        number: 16,
        question: [
          '(16)ABC　大学の　図書館と｜！＿＿★＿｜できます。」',
        ],
        answers: ['食堂は','でも','利用','どなた'],
        pick: '',
        correct: 'でも'
      },
      {
        number: 17,
        question: [
          '(17)田中「ケンさん、日本に　来て　１か月ですね。」',
          'ケン「はい。やっと　日本の｜！＿＿★＿｜です。」',
        ],
        answers: ['なれた','ところ','に','生活'],
        pick: '',
        correct: 'なれた'
      },
      {
        number: 18,
        question: [
          '(18)（学校で）',
          'A「あ、しゅくだいがない。」',
          'B「え、忘れたんですか。」',
          'A「はい。がんばって　しゅくだいを｜！＿＿★＿｜きて　しまいました。」',
        ],
        answers: ['のに','忘れて','家に','やった'],
        pick: '',
        correct: '家に'
      },
      {
        number: 19,
        question: [
          '(19)この　道は、雨の　日は　すべり｜！＿＿★＿｜ください。',
        ],
        answers: ['通って','やすくて','気をつけて','あぶないから'],
        pick: '',
        correct: '気をつけて'
      },
      {
        number: 20,
        question: [
          '(20)今日は、一年で｜！＿＿★＿｜です。',
        ],
        answers: ['日','が','夜','最も長い'],
        pick: '',
        correct: '最も長い'
      },
    ]
  },
}