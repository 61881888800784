export const explain= [
    {
        // ＾友（とも）達（だち）｜
        pattern_furi: 'Vて＋Adj/V',
        pattern_normal: 'Vて＋Adj/V',
        en: [
            "　Depending on the verb, Adj is used when expressing emotions",
        ],
        mm: [ 
            '　အရှေ့က Verb နှင့်လိုက်၍ နောက်က Adj ကစိတ်ခံစားမှုကို ဖေါ်ပြသောအခါတွင် သုံးသည်။',
        ]
    },
    {
        pattern_furi: 'Adjで/くて＋Vない',
        pattern_normal: 'Adjで/くて＋Vない',
        en: [
            "　Because of Adj, the verb is the present state; Used when expressing feelings",
        ],
        mm: [
            '　 Adj ကြောင့် verb သည် လက်ရှိအခြေအနေ၊ ခံစားချက်တို့ကိုဖေါ်ပြသည့် အခါတွင်သုံးသည်။',
        ]
    },
    {
        pattern_furi: 'Nで＋｜＾結（けっ）果（か）',
        pattern_normal: 'Nで＋結果',
        en: [
            "　Used when describing benefit/sin according to Noun.",
        ],
        mm: [ 
            '　အရှေ့မှ Nounအရ နောက်ပိုင်းတွင် အကျိုး/အပြစ်ကိုဖေါ်ပြသည့်အခါတွင်သုံးသည်။',
        ]
    },
    {
        pattern_furi: 'Nな/Adj/Vので（｜＾理（り）由（ゆう）｜）＋V',
        pattern_normal: 'Nな/Adj/Vので＋V',
        en: [
            "　Used when describing benefit/sin according to Noun.",
        ],
        mm: [ 
            '　အရှေ့မှ Nounအရ နောက်ပိုင်းတွင် အကျိုး/အပြစ်ကိုဖေါ်ပြသည့်အခါတွင်သုံးသည်။',
        ]
    },

   
   
   
]