import Helper from "@helper";

export const fukushuu4 = {
    mondai1:
    Helper.shuffle(
        [
            {
                question: "＾日（に）本（ほん）｜の　マンガ｜＾文（ぶん）化（か）｜＄０｜　｜＾興（きょう）味（み）｜が　あります。",
                picked: [""],
                answers:Helper.shuffle(["に","で","の","へ"]),
                correct: ["に"],
            },
            {
                question: "＾結（けっ）婚（こん）｜の　お｜＾祝（いわ）｜い｜＄０｜＾部（ぶ）長（ちょう）｜に　｜＾時（と）計（けい）｜を　いただきました。",
                picked: [""],
                answers:Helper.shuffle(["に","で","の","が"]),
                correct: ["に"],
            },
            {
                question: "この　｜＾靴（くつ）｜は　｜＾山（やま）｜＄０｜＾歩（ある）｜くのに　いいです。",
                picked: [""],
                answers:Helper.shuffle(["を","に","へ","で"]),
                correct: ["を"],
            },
            {
                question: "この　かばんは　｜＾物（もの）｜が　｜＾入（い）｜れやすくて、　｜＾旅（りょ）行（こう）｜や　｜＾仕（し）事（ごと）｜＄０｜＾便（べん）利（り）｜です。",
                picked: [""],
                answers:Helper.shuffle(["に","が","は","の"]),
                correct: ["に"],
            },
            {
                question: "＾車（くるま）｜の　｜＾修（しゅう）理（り）｜＄０｜1｜＾週（しゅう）間（かん）｜　かかります。",
                picked: [""],
                answers:Helper.shuffle(["に","の","が","は"]),
                correct: ["に"],
            },
            {
                question: "＾家（か）族（ぞく）｜＄０｜ために、｜＾大（おお）｜きいうちを　｜＾買（か）｜いたいです。",
                picked: [""],
                answers:Helper.shuffle(["の","に","と","が"]),
                correct: ["の"],
            },
            {
                question: "＾棚（たな）｜から　｜＾荷（に）物（もつ）｜＄０｜＾落（お）｜ちそうです。",
                picked: [""],
                answers:Helper.shuffle(["が","は","を","に"]),
                correct: ["が"],
            },
            {
                question: "もう｜＾夜（よる）｜　｜＾遅（おそ）｜いですから、｜＾静（しず）｜か｜＄０｜していただきませんか。",
                picked: [""],
                answers:Helper.shuffle(["に","を","で","と"]),
                correct: ["に"],
            },
            {
                question: "＾今（こん）晩（ばん）｜の　おかずは　すき｜＾焼（や）｜き｜＄０｜しましょう。",
                picked: [""],
                answers:Helper.shuffle(["に","を","で","と"]),
                correct: ["に"],
            },
            {
                question: "＾雨（あめ）｜や｜＾雪（ゆき）｜の｜＾日（ひ）｜は　｜＾事（じ）故（こ）｜＄０｜＾起（お）｜きやすいです。",
                picked: [""],
                answers:Helper.shuffle(["が","は","で","に"]),
                correct: ["が"],
            },
            {
                question: "ズボンの　｜＾長（なが）｜さは　これ｜＄０｜よろしいでしょうか。",
                picked: [""],
                answers:Helper.shuffle(["で","を","は","に"]),
                correct: ["で"],
            },
            {
                question: "＾事（じ）故（こ）｜＄０｜あわないように、｜＾気（き）｜をつけてください。",
                picked: [""],
                answers:Helper.shuffle(["で","を","は","に"]),
                correct: ["に"],
            },
            {
                question: "マラソンを　｜＾途（と）中（ちゅう）｜＄０｜やめたい　｜＾場（ば）合（あい）｜は　｜＾掛（かかり）人（にん）｜＄１｜＾名（な）前（まえ）｜を　｜＾言（い）｜って、｜＾帰（かえ）｜って　ください。",
                picked: ["",""],
                answers:Helper.shuffle(["で","に","を","へ"]),
                correct: ["で","に"],
            },
            {
                question: "＾故（こ）障（しょう）｜＄０｜＾場（ば）合（あい）｜は、この｜＾番（ばん）号（ごう）｜に　｜＾電（でん）話（わ）｜してください。",
                picked: [""],
                answers:Helper.shuffle(["の","を","が","に"]),
                correct: ["の"],
            }
        ]
    )
    ,
    mondai2: // Helper.shuffle(
        [
        {
            question: "！生活｜するにはお金が必要です。",
            picked: "",
            answers: Helper.shuffle(["せいかつ", "せいかく", "せいと", "せいこう"]),
            correct: "せいかつ",
        },
        {
            question: "彼は週に｜！１回｜手紙をくれます。",
            picked: "",
            answers: Helper.shuffle(["いっかい", "いちかい", "いっがい", "いちがい"]),
            correct: "いっかい",
        },
        {
            question: "この町の｜！主｜な産業は何ですか。",
            picked: "",
            answers: Helper.shuffle(["おも", "じゅうよう", "しんせん", "ゆうめい"]),
            correct: "おも",
        },
        {
            question: "私の大好きな｜！色｜は赤です。",
            picked: "",
            answers: Helper.shuffle(["いろ", "かおり", "こえ", "あじ"]),
            correct: "いろ",
        },
        {
            question: "彼女に｜！人形｜を買ってあげた。",
            picked: "",
            answers: Helper.shuffle(["にんぎょう", "にんきょう", "ひとかたち", "ひとがたち"]),
            correct: "にんぎょう",
        },
        {
            question: "この品物は｜！品質｜がよい。",
            picked: "",
            answers: Helper.shuffle(["ひんしつ", "ほんしつ", "ひんしち", "ほんしち"]),
            correct: "ひんしつ",
        },
        {
            question: "彼は日本の｜！国民｜となった。",
            picked: "",
            answers: Helper.shuffle(["こくみん", "しみん", "けんみん", "くにみん"]),
            correct: "こくみん",
        },
        {
            question: "私は　｜！服｜が汚れるのは嫌だな",
            picked: "",
            answers: Helper.shuffle(["ふく", "ふとん", "くつ", "ぼうし"]),
            correct: "ふく",
        },
        {
            question: "彼はその｜！犬｜に石を投げた。",
            picked: "",
            answers: Helper.shuffle(["いぬ", "ねこ", "いね", "ぬこ"]),
            correct: "いぬ",
        },
        {
            question: "彼は馬と｜！同｜じぐらい強い。",
            picked: "",
            answers: Helper.shuffle(["おな", "どう", "とう", "あな"]),
            correct: "おな",
        },
        {
            question: "日本では｜！米｜が主食です。",
            picked: "",
            answers: Helper.shuffle(["こめ", "しお", "あぶら", "たまご"]),
            correct: "こめ",
        },
        {
            question: "今月は｜！給料｜が多かった",
            picked: "",
            answers: Helper.shuffle(["きゅうりょう", "じゅうりょう", "しりょう", "ざいりょう"]),
            correct: "きゅうりょう",
        },
        {
            question: "！料理｜は得意なんですか？",
            picked: "",
            answers: Helper.shuffle(["りょうり", "しゅうり", "かんり", "ちり"]),
            correct: "りょうり",
        },
        {
            question: "！肉｜と魚どちらが好きですか。",
            picked: "",
            answers: Helper.shuffle(["にく", "くに", "さかな", "やさい"]),
            correct: "にく",
        },
        {
            question: "！鳥｜の歌、聞こえますか。",
            picked: "",
            answers: Helper.shuffle(["とり", "さかな", "いぬ", "ねこ"]),
            correct: "とり",
        },
        {
            question: "好きな｜！野菜｜は何ですか？",
            picked: "",
            answers: Helper.shuffle(["にく", "くに", "さかな", "やさい"]),
            correct: "やさい",
        },
        {
            question: "彼女は｜！紅茶｜を注文した。",
            picked: "",
            answers: Helper.shuffle(["こうちゃ", "おちゃ", "こちゃ", "きっさ"]),
            correct: "こうちゃ",
        },
        {
            question: "夜｜！ご飯｜、食べに行かない？",
            picked: "",
            answers: Helper.shuffle(["ごはん", "ごへん", "ごばん", "ごべん"]),
            correct: "ごはん",
        },
        {
            question: "この薬はにがい｜！味｜がする。",
            picked: "",
            answers: Helper.shuffle(["あじ", "かおり", "におい", "こえ"]),
            correct: "あじ",
        },
        {
            question: "大学｜！時代｜の生活は楽しかったです。",
            picked: "",
            answers: Helper.shuffle(["じだい", "ときだい", "じたい", "ときたい"]),
            correct: "じだい",
        },
        {
            question: "そのコピー機は｜！使用｜できません。",
            picked: "",
            answers: Helper.shuffle(["しよう", "しりょう", "ちよう", "ちりょう"]),
            correct: "しよう",
        },
        {
            question: "！作業｜内容を教えてください。",
            picked: "",
            answers: Helper.shuffle(["さぎょう", "じゅぎょう", "ざんぎょう", "さくぎょう"]),
            correct: "さぎょう",
        },
        {
            question: "昨年日本の政治に大きな｜！変化｜があった。",
            picked: "",
            answers: Helper.shuffle(["へんか", "はんか", "こうか", "ぶんか"]),
            correct: "へんか",
        },
        {
            question: "電話は一つの｜！通信｜しゅだんです。",
            picked: "",
            answers: Helper.shuffle(["つうしん", "じしん", "ふしん", "すうしん"]),
            correct: "つうしん",
        },
        {
            question: "日本の化学はめざましく｜！進歩｜した。",
            picked: "",
            answers: Helper.shuffle(["しんぽ", "さんぽ", "しんぽう", "さんぽう"]),
            correct: "しんぽ",
        },
        {
            question: "資料を今日中に｜！送信｜していただけませんか。",
            picked: "",
            answers: Helper.shuffle(["そうしん", "つうしん", "すうしん", "ゆうしん"]),
            correct: "そうしん",
        },
        {
            question: "その質問に対して今日中に｜！返信｜いただきます。",
            picked: "",
            answers: Helper.shuffle(["へんしん", "そうしん", "すうしん", "ゆうしん"]),
            correct: "へんしん",
        },
        {
            question: "！洗濯｜機は故障しています。",
            picked: "",
            answers: Helper.shuffle(["せんたく", "ぜんたく", "せんだく", "ぜんだく"]),
            correct: "せんたく",
        },
        {
            question: "大切なことは｜！注意｜して聞くことだ。",
            picked: "",
            answers: Helper.shuffle(["ちゅうい", "しゅうい", "じゅうい", "しゅい"]),
            correct: "ちゅうい",
        },
        {
            question: "あの｜！建物｜は何ですか？",
            picked: "",
            answers: Helper.shuffle(["たてもの", "たてぶつ", "けんぶつ", "げんぶつ"]),
            correct: "たてもの",
        },
        {
            question: "私は｜！病院｜で働いています。",
            picked: "",
            answers: Helper.shuffle(["びょういん", "びよういん", "ひょういん", "ひよういん"]),
            correct: "びょういん",
        },
        {
            question: "私はこの｜！図書館｜でその本を借りた。",
            picked: "",
            answers: Helper.shuffle(["としょかん", "たいしかん", "えいがかん", "たいいくかん"]),
            correct: "としょかん",
        },
        {
            question: "学生たちは｜！教室｜に集まった。",
            picked: "",
            answers: Helper.shuffle(["きょうしつ", "しんしつ", "ぎょうしつ", "じんしつ"]),
            correct: "きょうしつ",
        },
        {
            question: "！工事｜は10月に始まった。",
            picked: "",
            answers: Helper.shuffle(["こうじ", "かじ", "ようじ", "しょくじ"]),
            correct: "こうじ",
        },
        {
            question: "この｜！番号｜に電話を下さい。",
            picked: "",
            answers: Helper.shuffle(["ばんごう", "しんごう", "あんごう", "つごう"]),
            correct: "ばんごう",
        },
    ]
    // )
}