import SizeBox from "@components/SizeBox";
import Number from "@components/common/Number";
import TitleComponent from "@components/common/TitleComponent";
import { memo } from "react";

const MondaiOneComponent = ({ checkMondai1, setCheckMondai1, mondai1, fontSize, Helper, pickAnswer, furigana, furiTranslate }: any) => {
    return <div className="">{!checkMondai1 ? <div className=" mt-[30px]">
    <TitleComponent
        furi="＾問（もん）｜＾題（だい）"
        normal="問　題"
        furigana={furigana} 
        furiTranslate={furiTranslate}
        fontSize={fontSize}
        lesson="１"
        />
    <SizeBox vertical="h-[70px] md:h-[80px]" />
    <div className="mb-[100px] relative">
        {
            mondai1.map(({ question, picked, answers,correct }: any, index: number) => {
                return (
                    <div key={Math.random()} className="flex flex-row items-center mb-3">
                        {/* Number */}

                        <Number fontSize={fontSize} furigana="true" style="" >
                            {index + 1})
                        </Number>
                        <div>
                            {
                                Helper.mondaiFuri({
                                    checkMondai1,
                                    context: question,
                                    fontSize,
                                    notUseLibrary: true,
                                    style: "flex-wrap",
                                    correct,
                                    popOver: {
                                        questionId: index,
                                        callBack: pickAnswer,
                                        answers: answers,
                                        picked
                                    }
                                })
                            }
                        </div>
                    </div>
                );
            })
        }
        <div className="absolute -bottom-[100px] md:-bottom-[80px] right-0 w-full flex flex-row justify-center">
            <div onClick={() => {
                setCheckMondai1(true)
            }}
                className="btn bg-navbar p-3 text-white rounded-md">
                Check Answer
            </div>
        </div>
    </div>
    <SizeBox vertical="h-[30px]"/>
</div>
    :
    <div className="mt-[40px] md:mt-[80px]">
        <TitleComponent
            furi="＾問（もん）｜＾題（だい）"
            normal="問　題"
            furigana={furigana}
            furiTranslate={furiTranslate}
            fontSize={fontSize}
            lesson="１"
        />
        <SizeBox vertical="h-[70px] md:h-[80px]" />
        <div className="mb-[100px]">

            {
                 mondai1.map(({ question, picked, answers,correct }: any, index: number) => {
                    return (
                        <div key={Math.random()} className="flex flex-row items-center mb-3">
                            {/* Number */}

                            <Number fontSize={fontSize} furigana="true" style="" >
                                {index + 1})
                            </Number>
                            <div>
                                {
                                    Helper.mondaiFuri({
                                        checkMondai1,
                                        context: question,
                                        fontSize,
                                        notUseLibrary: true,
                                        style: "flex-wrap",
                                        correct,
                                        popOver: {
                                            questionId: index,
                                            callBack: pickAnswer,
                                            answers: answers,
                                            picked
                                        }
                                    })
                                }
                            </div>
                        </div>
                    );
                })
            }
        </div>
    </div>
}
</div>
    return <>
        <div className="">
            <SizeBox vertical="h-[40px]" />
            <TitleComponent
            furi="＾問（もん）｜＾題（だい）"
            normal="問　題"
            furigana={furigana}
            furiTranslate={furiTranslate}
            fontSize={fontSize}
            lesson="１"
            />
            <SizeBox vertical="h-[80px]" />
            {
                mondai1.map(({ question, picked, answers,correct }: any, index: number) => {
                    return (
                        <div key={Math.random()} className="flex flex-row items-center mb-3">
                            {/* Number */}

                            <Number fontSize={fontSize} furigana="true" style="" >
                                {index + 1})
                            </Number>
                            <div>
                                {
                                    Helper.mondaiFuri({
                                        checkMondai1,
                                        context: question,
                                        fontSize,
                                        notUseLibrary: true,
                                        style: "flex-wrap",
                                        correct,
                                        popOver: {
                                            questionId: index,
                                            callBack: pickAnswer,
                                            answers: answers,
                                            picked
                                        }
                                    })
                                }
                            </div>
                        </div>
                    );
                })
            }
            <SizeBox vertical="h-[150px] md:h-0"/>
            {
            !checkMondai1 && 
            <div className="absolute bottom-[130px] md:-bottom-[80px] right-0 w-full flex flex-row justify-center">
                <div onClick={() => {
                    setCheckMondai1(true)
                }}
                    className="btn bg-navbar p-3 text-white rounded-md">
                    Check Answer
                </div>
            </div>
            }
        </div>
    </>
}
export default memo(MondaiOneComponent);