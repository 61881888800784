export const bunkei= [
    {
        sentence: [
            {
                furi: "＾子（こ）｜どもの　とき、よく　｜＾母（はは）｜に　しかられました。",
                normal: "子どもの　とき、よく　母に　しかられました。",
                en: "When I was a child, my mother often scolded me.",
                mm: "ငယ်ငယ်တုန်းက အမေ မကြာခဏ ဆူတာ ခဏခဏ ခံရတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson37/bunpou/4N37BK1.mp3")
    },
    {
        sentence: [
            {
                furi: "ラッシュの　｜＾電（でん）車（しゃ）｜で　｜＾足（あし）｜を　｜＾踏（ふ）｜まれました。",
                normal: "ラッシュの　電車で　足を　踏まれました。",
                en: "When I was on a crowded train, I was stepped on my foot by someone.",
                mm: "လူစည်ကားတဲ့ ရထားပေါ်မှာ တစ်စုံတစ်ယောက်ရဲ့ ခြေထောက်နင်းတာ ခံခဲ့ရတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson37/bunpou/4N37BK2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾法（ほう）隆（りゅう）寺（じ）｜は　６０７｜＾年（ねん）｜に　｜＾建（た）｜てられました。",
                normal: "法隆寺は　６０７年に　建てられました。",
                en: "Horyuji Temple was built in 607.",
                mm: "Horyuji ဘုရားကျောင်းကို 607 ခုနှစ်တွင်တည်ဆောက်ခဲ့သည်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson37/bunpou/4N37BK3.mp3")
    },
]