import SizeBox from "@components/SizeBox"
import Conversation from "@components/category/Conversation"
import Exercise from "@components/category/Exercise"
import Grammar from "@components/category/Grammar"
import Kanji from "@components/category/Kanji"
import Vocab from "@components/category/Vocab"
import SubNavComponent from "@components/common/SubNavComponent"
import Helper from "@helper"
import { memo } from "react"

const CategoryWeb = ({ nabs, lesson, cagetories, vocab, furigana, fontSize, lang, grammarExplain, id, kaiwaPerson }: any) => {
    const GapX = () => {
        return <SizeBox horizontal="md:w-6 lg:w-8 xl:w-16" />
    }
    const GapY = () => {
        return <SizeBox vertical="md:h-6 lg:h-8 xl:h-16" />
    }
    return <div className="hidden md:block container mx-auto mb-5">
        <SubNavComponent nabs={nabs} lesson={lesson} />
        <SizeBox vertical="h-10" />
        <div className="flex flex-row items-center">
            {/* vocab Card */}
            <Vocab
                cagetories={cagetories}
                vocab={vocab}
                furigana={furigana}
                furiTranslate={Helper.furiTranslate}
                fontSize={fontSize}
                lang={lang}
            />
            <GapX />

            {/* Grammar */}
            <Grammar cagetories={cagetories} grammarExplain={grammarExplain} furigana={furigana}
                furiTranslate={Helper.furiTranslate}
                fontSize={fontSize}
            />
        </div>
        <GapY />
        <div className="flex flex-row items-center">
            {/* Kanji Card */}
            {(id + 1) % 5 != 0 ?
                <Kanji cagetories={cagetories} id={id} />
                : <Exercise cagetories={cagetories} id={id} />
            }
            <GapX />
            {/* Converstation */}
            <Conversation key={Math.random()} cagetories={cagetories} kaiwaPerson={kaiwaPerson} furigana={furigana}
                furiTranslate={Helper.furiTranslate}
                fontSize={fontSize}
                lang={lang}
            />
        </div>
    </div>
}
export default memo(CategoryWeb)