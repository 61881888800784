
export const lesson5 = [
    {"lesson":5,"level":4,"question":"世界","represent":["世","界"],"answer":["せかい","おんがく","かくすう","はつおん"],"true":"せかい","times":10},
    {"lesson":5,"level":4,"question":"旅館","represent":["旅","館"],"answer":["かくすう","あしおと","りょかん","がめん"],"true":"りょかん","times":10},
    {"lesson":5,"level":4,"question":"映画","represent":["映","画"],"answer":["まっしろ","おんがく","えいが","せいき"],"true":"えいが","times":10},
    {"lesson":5,"level":4,"question":"画数","represent":["画"],"answer":["せいき","はつおん","あしおと","かくすう"],"true":"かくすう","times":10},
    {"lesson":5,"level":4,"question":"真白","represent":["真","白"],"answer":["まっしろ","しゃしん","りょかん","りょひ"],"true":"まっしろ","times":10},
    {"lesson":5,"level":4,"question":"発音","represent":["発","音"],"answer":["りょかん","がか","あしおと","はつおん"],"true":"はつおん","times":10},
    {"lesson":5,"level":4,"question":"政界","represent":["界"],"answer":["かくすう","せいかい","せかい","かしゅ"],"true":"せいかい","times":10},
    {"lesson":5,"level":4,"question":"画家","represent":["画","家"],"answer":["がか","まっしろ","がめん","せかい"],"true":"がか","times":10},
    {"lesson":5,"level":4,"question":"足音","represent":["足","音"],"answer":["せいかい","がめん","あしおと","はつおん"],"true":"あしおと","times":10},
    {"lesson":5,"level":4,"question":"写真","represent":["写","真"],"answer":["がか","りょかん","かくすう","しゃしん"],"true":"しゃしん","times":10},
    {"lesson":5,"level":4,"question":"旅費","represent":["旅"],"answer":["おんがく","はつおん","がか","りょひ"],"true":"りょひ","times":10},
    {"lesson":5,"level":4,"question":"音楽","represent":["音","楽"],"answer":["りょひ","えいが","せいき","おんがく"],"true":"おんがく","times":10},
    {"lesson":5,"level":4,"question":"世紀","represent":["世"],"answer":["しゃしん","りょかん","がめん","せいき"],"true":"せいき","times":10},
    {"lesson":5,"level":4,"question":"歌手","represent":["歌","手"],"answer":["かくすう","かしゅ","はつおん","おんがく"],"true":"かしゅ","times":10},
    {"lesson":5,"level":4,"question":"画面","represent":["画"],"answer":["あしおと","がめん","せかい","せいかい"],"true":"がめん","times":10},
]