export const lesson49 = [
  {
      "furi": "＾利（り）用（よう）｜します",
      "normal": "利用します",
      "en": "use",
      "remark_en": "",
      "mm": "အသုံးချသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO1.mp3")
  },
  {
      "furi": "＾勤（つと）｜めます",
      "normal": "勤めます",
      "en": "work",
      "remark_en": "",
      "mm": "တာဝန်ထမ်းဆောင်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO2.mp3")
  },
  {
      "furi": "＾掛（か）｜けます",
      "normal": "掛けます",
      "en": "sit on (a chair)",
      "remark_en": "",
      "mm": "ချိတ်ဆက်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO3.mp3")
  },
  {
      "furi": "＾過（す）｜ごします",
      "normal": "過ごします",
      "en": "spend, pass(time)",
      "remark_en": "",
      "mm": "ကုန်လွန်စေသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO4.mp3")
  },
  {
      "furi": "いらっしゃいます",
      "normal": "いらっしゃいます",
      "en": "be, go, come",
      "remark_en": "",
      "mm": "ရှိသည်၊သွားသည်၊လာသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO5.mp3")
  },
  {
      "furi": "＾召（め）｜し｜＾上（あ）｜がります",
      "normal": "召し上がります",
      "en": "eat, drink",
      "remark_en": "",
      "mm": "စားသောက်သည်၊ သုံးဆောင်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO6.mp3")
  },
  {
      "furi": "おっしゃいます",
      "normal": "おっしゃいます",
      "en": "say",
      "remark_en": "",
      "mm": "ပြောသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO7.mp3")
  },
  {
      "furi": "なさいます",
      "normal": "なさいます",
      "en": "do",
      "remark_en": "",
      "mm": "လုပ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO8.mp3")
  },
  {
      "furi": "ご｜＾覧（らん）｜になります",
      "normal": "ご覧になります",
      "en": "see, look at",
      "remark_en": "",
      "mm": "ကြည့်သည်၊မြင်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO9.mp3")
  },
  {
      "furi": "ご｜＾存（ぞん）｜じです",
      "normal": "ご存じです",
      "en": "know",
      "remark_en": "",
      "mm": "သိသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO10.mp3")
  },
  {
      "furi": "あいさつ",
      "normal": "あいさつ",
      "en": "greeting",
      "remark_en": "",
      "mm": "နှုတ်ဆက်စကား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO11.mp3")
  },
  {
      "furi": "＾旅（りょ）館（かん）",
      "normal": "旅館",
      "en": "Japanese-style hotel or inn",
      "remark_en": "",
      "mm": "ဂျပန်ဧည့်ရိပ်သာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO12.mp3")
  },
  {
      "furi": "バス｜＾停（てい）",
      "normal": "バス停",
      "en": "bus stop",
      "remark_en": "",
      "mm": "ဘတ်စ်ကားမှတ်တိုင်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO13.mp3")
  },
  {
      "furi": "＾奥（おく）様（さま）",
      "normal": "奥様",
      "en": "wife(someone's)",
      "remark_en": "",
      "mm": "ဇနီး(သူတစ်ပါး)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO14.mp3")
  },
  {
      "furi": "～｜＾様（さま）",
      "normal": "～様",
      "en": "Mr~,Mrs~",
      "remark_en": "",
      "mm": "ဦး～၊ဒေါ်～",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO15.mp3")
  },
  {
      "furi": "たまに",
      "normal": "たまに",
      "en": "once in a while",
      "remark_en": "",
      "mm": "တစ်ခါတစ်လေ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO16.mp3")
  },
  {
      "furi": "どなたでも",
      "normal": "どなたでも",
      "en": "Anyone",
      "remark_en": "",
      "mm": "ဘယ်သူပဲဖြစ်ဖြစ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO17.mp3")
  },
  {
      "furi": "～と｜＾言（い）｜います",
      "normal": "～と言います",
      "en": "name is call~",
      "remark_en": "",
      "mm": "～လို့ခေါ်ပါတယ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO18.mp3")
  },
  {
      "furi": "～｜＾年（ねん）｜～｜＾組（くみ）",
      "normal": "～年～組",
      "en": "class ~ of ~ th grade",
      "remark_en": "",
      "mm": "-နှစ်-အတန်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO19.mp3")
  },
  {
      "furi": "＾出（だ）｜します",
      "normal": "出します",
      "en": "run (a fever)",
      "remark_en": "",
      "mm": "ထုတ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO20.mp3")
  },
  {
      "furi": "＾熱（ねつ）｜を｜＾出（だ）｜します",
      "normal": "熱を出します",
      "en": "have a fever",
      "remark_en": "",
      "mm": "ကိုပူသည်(အဖျားရှိ)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO21.mp3")
  },
  {
      "furi": "よろしくお｜＾伝（つた）｜えください。",
      "normal": "よろしくお伝えください。",
      "en": "Give my best regards",
      "remark_en": "",
      "mm": "ကျေးဇူးပြု၍တစ်ဆင့်ပြောပေးပါ။",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO22.mp3")
  },
  {
      "furi": "＾失（しつ）礼（れい）｜いたします。",
      "normal": "失礼いたします。",
      "en": "Good-bye",
      "remark_en": "",
      "mm": "ခွင့်ပြုပါဦး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO23.mp3")
  },
  {
      "furi": "ひまわり｜＾小（しょう）｜＾学（がっ）｜＾校（こう）",
      "normal": "ひまわり小学校",
      "en": "fictitious elementary school",
      "remark_en": "",
      "mm": "နေကြာပန်းမူလတန်းကျောင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson49/vocab/4N49VO24.mp3")
  },
  {
      "furi": "＾経（けい）歴（れき）",
      "normal": "経歴",
      "en": "personal history",
      "remark_en": "",
      "mm": "ကိုရေးရာဇဝင်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾医（い）学（がく）部（ぶ）",
      "normal": "医学部",
      "en": "School of medicine",
      "remark_en": "",
      "mm": "ဆေးပညာဌာန",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾目（め）指（ざ）｜します",
      "normal": "目指します",
      "en": "Aim",
      "remark_en": "",
      "mm": "ရည်မှန်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾進（すす）｜みます",
      "normal": "進みます",
      "en": "move on",
      "remark_en": "",
      "mm": "ဆက်လက်ဖြစ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "iPS｜＾細（さい）｜＾胞（ぼう）",
      "normal": "iPS細胞",
      "en": "iPS Cell",
      "remark_en": "",
      "mm": "iPS ဆဲလ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾開（かい）発（はつ）｜します",
      "normal": "開発します",
      "en": "Develop",
      "remark_en": "",
      "mm": "ဖွံ့ဖြိုးအောင်လုပ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "マウス",
      "normal": "マウス",
      "en": "mouse",
      "remark_en": "",
      "mm": "ကြွက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "ヒト",
      "normal": "ヒト",
      "en": "man",
      "remark_en": "",
      "mm": "လူ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾受（じゅ）賞（しょう）｜します",
      "normal": "受賞します",
      "en": "be awarded a prize",
      "remark_en": "",
      "mm": "ဆုရသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾講（こう）演（えん）会（かい）",
      "normal": "講演会",
      "en": "Lecture",
      "remark_en": "",
      "mm": "ဟောပြောသရုပ်ပြပွဲ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾山（やま）中（なか）伸（しん）弥（や）",
      "normal": "山中伸弥",
      "en": "Shinya Yamanaka(name)",
      "remark_en": "",
      "mm": "ရာမနခရှင်းယ(နာမည်)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "ノーベル｜＾賞（しょう）",
      "normal": "ノーベル賞",
      "en": "Nobel Prize",
      "remark_en": "",
      "mm": "နိုဗယ်ဆု",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  }
]

/*
    {
        furi: "",
        normal: "",
        en: "",
        remark_en: "",
        mm: "",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson29/vocab/4N29VO1.mp3")
    },


*/
const test = [
  {
    'goi': '利（り）」用（よう）」します',
    'unicode': 'အသုံးချသည်',
    'zawgyi': 'အသုံးခ်သည္',
    'english': 'use',
    'long': false
  },
  {
    'goi': '勤（つと）」めます',
    'unicode': 'တာဝန်ထမ်းဆောင်သည်',
    'zawgyi': 'တာဝန္ထမ္းေဆာင္သည္',
    'english': 'work',
    'long': false
  },
  {
    'goi': '掛（か）」けます',
    'unicode': 'ချိတ်ဆက်သည်',
    'zawgyi': 'ခ်ိတ္ဆက္သည္',
    'english': 'sit on (a chair)',
    'long': false
  },
  {
    'goi': '過（す）」ごします',
    'unicode': 'ကုန်လွန်စေသည်',
    'zawgyi': 'ကုန္လြန္ေစသည္',
    'english': 'spend, pass(time)',
    'long': false
  },
  {
    'goi': 'いらっしゃいます',
    'unicode': 'ရှိသည်၊သွားသည်၊လာသည်',
    'zawgyi': 'ရွိသည္၊သြားသည္၊လာသည္',
    'english': 'be, go, come',
    'long': true
  },
  {
    'goi': '召（め）」し」上（あ）」がります',
    'unicode': 'စားသောက်သည်၊ သုံးဆောင်သည်',
    'zawgyi': 'စားေသာက္သည္၊ သုံးေဆာင္သည္',
    'english': 'eat, drink',
    'long': true
  },
  {
    'goi': 'おっしゃいます',
    'unicode': 'ပြောသည်',
    'zawgyi': 'ေျပာသည္',
    'english': 'say',
    'long': false
  },
  {
    'goi': 'なさいます',
    'unicode': 'လုပ်သည်',
    'zawgyi': 'လုပ္သည္',
    'english': 'do',
    'long': false
  },
  {
    'goi': 'ご」覧（らん）」になります',
    'unicode': 'ကြည့်သည်၊မြင်သည်',
    'zawgyi': 'ၾကည့္သည္၊ျမင္သည္',
    'english': 'see, look at',
    'long': false
  },
  {
    'goi': 'ご」存（ぞん）」じです',
    'unicode': 'သိသည်',
    'zawgyi': 'သိသည္',
    'english': 'know',
    'long': false
  },
  {
    'goi': 'あいさつ',
    'unicode': 'နှုတ်ဆက်စကား',
    'zawgyi': 'ႏႈတ္ဆက္စကား',
    'english': 'greeting',
    'long': false
  },
  {
    'goi': '旅（りょ）」館（かん）',
    'unicode': 'ဂျပန်ဧည့်ရိပ်သာ',
    'zawgyi': 'ဂ်ပန္ဧည့္ရိပ္သာ',
    'english': 'Japanese-style hotel or inn',
    'long': false
  },
  {
    'goi': 'バス」停（てい）',
    'unicode': 'ဘတ်စ်ကားမှတ်တိုင်',
    'zawgyi': 'ဘတ္စ္ကားမွတ္တိုင္',
    'english': 'bus stop',
    'long': false
  },
  {
    'goi': '奥（おく）」様（さま）',
    'unicode': 'ဇနီး(သူတစ်ပါး)',
    'zawgyi': 'ဇနီး(သူတစ္ပါး)',
    'english': 'wife(someone\'s)',
    'long': false
  },
  {
    'goi': '～」様（さま）',
    'unicode': 'ဦး～၊ဒေါ်～',
    'zawgyi': 'ဦး～၊ေဒၚ～',
    'english': 'Mr~,Mrs~',
    'long': false
  },
  {
    'goi': 'たまに',
    'unicode': 'တစ်ခါတစ်လေ',
    'zawgyi': 'တစ္ခါတစ္ေလ',
    'english': 'once in a while',
    'long': false
  },
  {
    'goi': 'どなたでも',
    'unicode': 'ဘယ်သူပဲဖြစ်ဖြစ်',
    'zawgyi': 'ဘယ္သူပဲျဖစ္ျဖစ္',
    'english': 'Anyone',
    'long': false
  },
  {
    'goi': '～と」言（い）」います',
    'unicode': '～လို့ခေါ်ပါတယ်',
    'zawgyi': '～လို႔ေခၚပါတယ္',
    'english': 'name is call~',
    'long': false
  },
  {
    'goi': '～」年（ねん）」～」組（くみ）',
    'unicode': '-နှစ်-အတန်း',
    'zawgyi': '-ႏွစ္-အတန္း',
    'english': 'class ~ of ~ th grade',
    'long': false
  },
  {
    'goi': '出（だ）」します',
    'unicode': 'ထုတ်သည်',
    'zawgyi': 'ထုတ္သည္',
    'english': 'run (a fever)',
    'long': false
  },
  {
    'goi': '熱（ねつ）」を」出（だ）」します',
    'unicode': 'ကိုပူသည်(အဖျားရှိ)',
    'zawgyi': 'ကိုပူသည္(အဖ်ားရွိ)',
    'english': 'have a fever',
    'long': false
  },
  {
    'goi': 'よろしくお」伝（つた）」えください。',
    'unicode': 'ကျေးဇူးပြု၍တစ်ဆင့်ပြောပေးပါ။',
    'zawgyi': 'ေက်းဇူးျပဳ၍တစ္ဆင့္ေျပာေပးပါ။',
    'english': 'Give my best regards',
    'long': true
  },
  {
    'goi': '失（しつ）」礼（れい）」いたします。',
    'unicode': 'ခွင့်ပြုပါဦး',
    'zawgyi': 'ခြင့္ျပဳပါဦး',
    'english': 'Good-bye',
    'long': false
  },
  {
    'goi': 'ひまわり」小（しょう）」学（がっ）」校（こう）',
    'unicode': 'နေကြာပန်းမူလတန်းကျောင်း',
    'zawgyi': 'ေနၾကာပန္းမူလတန္းေက်ာင္း',
    'english': 'fictitious elementary school',
    'long': true
  },
  {
    'goi': '経（けい）」歴（れき）',
    'unicode': 'ကိုရေးရာဇဝင်',
    'zawgyi': 'ကိုေရးရာဇဝင္',
    'english': 'personal history',
    'long': false
  },
  {
    'goi': '医（い）」学（がく）」部（ぶ）',
    'unicode': 'ဆေးပညာဌာန',
    'zawgyi': 'ေဆးပညာဌာန',
    'english': 'School of medicine',
    'long': false
  },
  {
    'goi': '目（め）」指（ざ）」します',
    'unicode': 'ရည်မှန်းသည်',
    'zawgyi': 'ရည္မွန္းသည္',
    'english': 'Aim',
    'long': false
  },
  {
    'goi': '進（すす）」みます',
    'unicode': 'ဆက်လက်ဖြစ်သည်',
    'zawgyi': 'ဆက္လက္ျဖစ္သည္',
    'english': 'move on',
    'long': false
  },
  {
    'goi': 'iPS」細（さい）」胞（ぼう）',
    'unicode': 'iPS ဆဲလ်',
    'zawgyi': 'iPS ဆဲလ္',
    'english': 'iPS Cell',
    'long': false
  },
  {
    'goi': '開（かい）」発（はつ）」します',
    'unicode': 'ဖွံ့ဖြိုးအောင်လုပ်သည်',
    'zawgyi': 'ဖြံ့ၿဖိဳးေအာင္လုပ္သည္',
    'english': 'Develop',
    'long': false
  },
  {
    'goi': 'マウス',
    'unicode': 'ကြွက်',
    'zawgyi': 'ႂကြက္',
    'english': 'mouse',
    'long': false
  },
  {
    'goi': 'ヒト',
    'unicode': 'လူ',
    'zawgyi': 'လူ',
    'english': 'man',
    'long': false
  },
  {
    'goi': '受（じゅ）」賞（しょう）」します',
    'unicode': 'ဆုရသည်',
    'zawgyi': 'ဆုရသည္',
    'english': 'be awarded a prize',
    'long': false
  },
  {
    'goi': '講（こう）」演（えん）」会（かい）',
    'unicode': 'ဟောပြောသရုပ်ပြပွဲ',
    'zawgyi': 'ေဟာေျပာသ႐ုပ္ျပပြဲ',
    'english': 'Lecture',
    'long': false
  },
  {
    'goi': '山（やま）」中（なか）」伸（しん）」弥（や）',
    'unicode': 'ရာမနခရှင်းယ(နာမည်)',
    'zawgyi': 'ရာမနခရွင္းယ(နာမည္)',
    'english': 'Shinya Yamanaka(name)',
    'long': false
  },
  {
    'goi': 'ノーベル」賞（しょう）',
    'unicode': 'နိုဗယ်ဆု',
    'zawgyi': 'နိုဗယ္ဆု',
    'english': 'Nobel Prize',
    'long': false
  }
]