
export const renshuuA = [
    {
        //  {
        // furi: 'あしたから　｜！旅（りょ）行（こう）なん｜です。',
        // normal: 'あしたから　旅行　なんです。',
        // en: 'It\'s a trip from tomorrow.',
        // mm: 'မနက်ဖြန်ကနေစပြီး ခရီးထွက်ပါမယ်။',
        // }
        sentence: [
            {
                furi: "｜＾写（しゃ）真（しん）｜を　みせながら　｜＾説（せつ）明（めい）｜します。",
                normal: "写真を　みせながら　説明します。",
                en: "I will show you the pictures and explain.",
                mm: "ပုံလေးတွေ ပြရင်းနဲ့ ရှင်းပြပေးပါ့မယ်။",
            },
            {
                furi: "ガムを　かみながら　｜＾先（せん）生（せい）｜の　｜＾話（はなし）｜を　｜＾聞（き）｜きては　いけません。",
                normal: "ガムを　かみながら　先生の　話を　聞きてはいけません",
                en: "Don't listen to the teacher while chewing gum",
                mm: "ပီကေဝါးနေရင်းနဲ့ ဆရာ့စကားကို နားမထောင်ပါနှင့်",
            },
            {
                furi: "はたらきながら　｜＾日（に）本（ほん）語（ご）｜を　｜＾勉（べん）強（きょう）｜して　います。",
                normal: "はたらきながら　日本語を　勉強して　います。",
                en: "I am studying Japanese while working.",
                mm: "အလုပ်လုပ်ရင်းနဲ့ ဂျပန်စာလေ့လာနေတယ်။",
            },
            {
                furi: "｜＾大（だい）学（がく）｜で　おしえながら　｜＾研（けん）究（きゅう）｜して　います。",
                normal: "大学で　おしえながら　研究して　います。",
                en: "I am researching while teaching at university.",
                mm: "တက္ကသိုလ်မှာ စာသင်ရင်း သုတေသနလုပ်နေတယ်။",
            },
            
        ],
        audioUrl: require("@assets/audio/lesson28/renshua/4N28RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "｜＾休（やす）｜みの　｜＾日（ひ）｜は　｜＾絵（え）｜を　かいています。",
                normal: "休みの　日は　絵を　書いています。",
                en: "I draw pictures on my days off.",
                mm: "အားလပ်ရက်တွေမှာ ပုံတွေဆွဲတယ်။",
            },
            {
                furi: "｜＾休（やす）｜みの　｜＾日（ひ）｜は　｜＾息（むす）子（こ）｜と　｜＾釣（つ）｜りに　いって　います",
                normal: "休みの　日は　息子と　釣りに　いって　います。",
                en: "On my days off, I go fishing with my son.",
                mm: "အားလပ်ရက်တွေမှာ သားလေးနဲ့အတူ ငါးမျှားထွက်တယ်။",
            },
            {
                furi: "＾休（やす）｜みの　｜＾日（ひ）｜は　｜＾娘（むすめ）｜と　｜＾買（か）｜い｜＾物（もの）｜に　行ったり、｜＾映（えい）画（が）｜を　｜＾見（み）｜たり　しています。",
                normal: "休みの　日は　娘と　買い物に　行ったり、映画を　見たり　しています。",
                en: "On my days off, I go shopping and watch movies with my daughter.",
                mm: "အားလပ်ရက်တွေမှာ သမီးနဲ့ ရုပ်ရှင်သွားကြည့်တယ်။",
            }
        ],
        audioUrl: require("@assets/audio/lesson28/renshua/4N28RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾鈴（すず）木（き）｜さんは　ピアノも　ひけるし、｜＾歌（うた）｜も　うたえるし、　それに　ダンスも　できます。",
                normal: "鈴木さんは　ピアノも　ひけるし、歌も　うたえるし、　それに　ダンスも　できます。",
                en: "Mr.Suzuki can play the piano and sing as well as dance.",
                mm: "မစ္စတာဆူဇူကီးက စန္ဒယားတီးတတ်ပြီး သီချင်းဆိုတတ်တဲ့အပြင် ကလည်း ကတတ်တယ်။",
            },
            {
                furi: "＾鈴（すず）木（き）｜さんは わかいし、｜＾体（からだ）｜も　おおきいし、　それに　｜＾力（ちから）｜も　｜＾強（つよ）｜いです。",
                normal: "鈴木さんは　わかいし、体も　おおきいし、　それに　力も　強いです。",
                en: "Mr.Suzuki is young, has a big body, and is strong.",
                mm: "မစ္စတာဆူဇူကီးက ငယ်ရွယ်ပြီး ခန္ဓာကိုယ်ကြီးကြီးမားမားရှိတဲ့အပြင် သန်မာလဲ သန်မာသည်။ ",
            },
            {
                furi: "＾鈴（すず）木（き）｜さんは　まじめだし　｜＾中（ちゅう）国（ごく）語（ご）｜も　じょうすだし、それに　｜＾経（けい）験（けん）｜も　あります。",
                normal: "鈴木さんは　まじめだし　中国語も　じょうすだし、それに　経験も　あります。",
                en: "Mr.Suzuki is serious, speaks Chinese well, and has experience.",
                mm: "မစ္စတာဆူဇူကီးသည် လေးနက်ပြီး တရုတ်စကား ကောင်းမွန်စွာပြောတတ်တဲ့အပြင် အတွေ့အကြုံလည်း ရှိသည်။ ",
            },
        ],
        audioUrl: require("@assets/audio/lesson28/renshua/4N28RSA3.mp3")
    },
    {
        sentence: [
            {
                furi: "この｜＾店（みせ）｜は　しずかだし、　ひろいし、いつも　ここで　｜＾食（た）｜べて　います。",
                normal: "この店は　しずかだし、　ひろいし、いつも　ここで　食べて　います。",
                en: "This restaurant is quiet and wide, and I always eat here.",
                mm: "ဒီစားသောက်ဆိုင်က တိတ်ဆိတ်ပြီး ကျယ်တယ်၊ ဒီမှာ အမြဲစားဖြစ်တယ်။",
            },
            {
                furi: "この｜＾店（みせ）｜は｜＾値（ね）段（でん）｜も　やすいし、｜＾味（あじ）｜も　いいし、、いつも　ここで　｜＾食（た）｜べて　います。",
                normal: "この店は　値段も　やすいし、味も　いいし、いつも　ここで　食べて　います",
                en: "This restaurant is reasonably priced and the taste is good, so I always eat here",
                mm: "ဒီဆိုင်က စျေးနှုန်းသင့်တင့်ပြီး အရသာလည်း ကောင်းတာမို့ ဒီမှာ အမြဲစားဖြစ်တယ်။",
            },
            {
                furi: "この｜＾店（みせ）｜は　おすしも　あるし、　カレーライスも　あるし、いつも　ここで　｜＾食（た）｜べて　います",
                normal: "この店は　おすしも　あるし、　カレーライスも　あるし、いつも　ここで　食べて　います",
                en: "This restaurant has sushi, curry and rice, and I always eat here.",
                mm: "ဒီစားသောက်ဆိုင်မှာ ဆူရှီလဲ  ရပြီး၊ ဟင်းနဲ့ ထမင်းလဲ ရတဲ့အတွက် ဒီမှာ အမြဲစားဖြစ်တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson28/renshua/4N28RSA4.mp3")
    },
    {
        sentence: [
            {
                furi: "どうして　｜＾日（に）本（ほん）｜の　アニメが　｜＾好（す）｜きなんでも。。",
                normal: "どうして　日本の　アニメが　好きなんでも。",
                en: "Why do you like Japanese anime?",
                mm: "ဂျပန် anime ကို ဘာကြောင့် ကြိုက်တာလဲ။",
            },
            {
                furi: "。。。。｜＾話（はなし）｜も　おもしろいし、　｜＾音（おん）楽（がく）｜も　すてきですから。",
                normal: "。。。。話も　おもしろいし、　音楽も　すてきですから。すてきですから。",
                en: "Because the story is interesting and the music is nice too.",
                mm: "ဇာတ်လမ်းက စိတ်ဝင်စားဖို့ကောင်းသလို သီချင်းကလည်း ကောင်းလို့ပါ။",
            },
            {
                furi: "＾絵（え）｜も　きれいだし、｜＾夢（ゆめ）｜も　ありますから。",
                normal: "絵も　きれいだし、夢も　ありますから。",
                en: "Because the pictures are beautiful, and I have a dream.",
                mm: "ဓါတ်ပုံတွေက လှပြီးတော့ အိပ်မက်လဲ ရှိလို့ပါ။",
            },
            {
                furi: "＾日（に）本（ほん）語（ご）｜も　｜＾勉（べん）強（きょう）｜できるし、｜＾日（に）本（ほん）｜のことも　わかりますから。",
                normal: "日本語も　勉強できるし、日本のことも　わかりますから。",
                en: "",
                mm: "ဂျပန်စာကို လေ့လာနိုင်ပြီး ဂျပန်အကြောင်းကိုလည်း နားလည်လို့ပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson28/renshua/4N28RSA5.mp3")
    },

];

/*
    {
        sentence: [
            {
                furi: "",
                normal: "",
                en: "",
                mm: "",
            },
        ],
        audioUrl: require("@assets/audio/testing.mp3")
    },




*/