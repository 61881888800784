import SizeBox from "@components/SizeBox";
import AmidioMdComponent from "@components/common/Amidio/AmidioMdComponent";
import SpeedDialComponent, { SPEDIAL } from "@components/common/SpeedDialComponent";
import SubNavComponent from "@components/common/SubNavComponent";
import ConversationComponent from "@components/kaiwa/ConversationComponent";
import TitleComponent from "@components/kaiwa/TitleComponent";
import KaiwaScreenLayout from "@layouts/home/KaiwaScreenLayout";
import { Card } from "@material-tailwind/react";
import { ScreenPropType } from "App";
import { memo, useEffect, useState } from "react";
const KaiwaScreen = ({ScoreType,getScore,setScores, showModel,setShowModel,setUnlockPremium,Helper, Routes, useNavigate, Logger, fontSize, useLocation, lang, furigana, dark, toggleFuri, Minna,routeHistory,setRouteData }: ScreenPropType) => {
    const log = Logger(7);
    const location = useLocation();
    const navigate = useNavigate();

    const { id, lesson } = location.state;
    const [desktopHeight, setDesktopHeight] = useState(window.innerHeight - (60+85));
    const [mobileHeight, setMobileHeight] = useState(window.innerHeight - (70+64));
    const { title, conversation, audioUrl } = Minna.kaiwa[id];
    // Speed Dial 
    const [showTranslate, setShowTranslate] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
 
    const nabs = [
        {
            title: "home",
            route: Routes.HOME,
        },
        {
            title: "Category",
            route: Routes.CATEGORY,
            state: { id, lesson }
        },
        {
            title: "Conversation ",
            route: null
        }
    ];
    const handleWindowResize = () => {
        setDesktopHeight(window.innerHeight - (60+85));
        setMobileHeight(window.innerHeight-(70+64))
    }
    // kanji PopOver Detail
    const kanjiDetail = (kanjiId: any) => {
        const state = { lesson, id, name: "Conversation", path: location.pathname, kanjiId };
        navigate(Routes.KANJI_DETAIL, { state });
        scoreIncrementAction();
    }
     //  spedial action
     const scoreIncrementAction = ()=>{
        const currentScore = getScore({id,key:ScoreType.KAIWA});
        console.log("Score",currentScore);
        // setScores({id,key:ScoreType.GRAMMAR,value:currentScore+1});
        asyncSetScore(currentScore);
    }
    const asyncSetScore=async(currentScore:number)=>{
        await Helper.sleep(1000);
        setScores({id,key:ScoreType.KAIWA,value:currentScore+1});
        console.log("Set Score")
    }
    const toggleFuriAction = ()=>{
        toggleFuri();
        scoreIncrementAction();
    }
    useEffect(() => {
        if (routeHistory.home.route != Routes.KAIWA) {
            setRouteData({
                home: {
                    state: { id, lesson },
                    route: Routes.KAIWA
                }
            })
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    })
    return (<>
        <KaiwaScreenLayout
            body={
                <div onClick={isHovering ? () => setIsHovering(false) : () => { }}>
                    {/* web */}
                    <div className="hidden md:flex flex-col container mx-auto overflow-hidden" style={{ height: desktopHeight +"px"}}>
                        <SizeBox vertical="h-[6px]"/>
                        <SubNavComponent nabs={nabs} lesson={lesson} />
                        <SizeBox vertical="h-[10px]"/>
                        <div className="flex flex-row justify-between items-center">
                            <TitleComponent
                                furigana={furigana}
                                furiTranslate={Helper.furiTranslate}
                                furi={title.furi}
                                normal={title.normal}
                                tran={lang == 'en' ? title.en : title.mm}
                                fontSize={fontSize}
                                showTranslate={showTranslate}
                                kanjiDetail={kanjiDetail}

                            />
                            <div className="">
                                <AmidioMdComponent audioUrl={audioUrl} scoreIncrementAction={scoreIncrementAction}/>
                            </div>
                        </div>
                        <SizeBox vertical="h-[10px]"/>
                        <Card className="flex-1 overflow-y-scroll scrollbar-hide bg-white dark:bg-darkFooter rounded-[20px]">
                            {/* card-light-shadow dark-shadow  */}
                            <ConversationComponent
                                conversation={conversation}
                                furigana={furigana}
                                furiTranslate={Helper.furiTranslate}
                                fontSize={fontSize}
                                lang={lang}
                                showTranslate={showTranslate}
                                setShowTranslate={setShowTranslate}
                                kanjiDetail={kanjiDetail}
                            />
                        </Card>
                        <SizeBox vertical="h-[10px]"/>
                    </div>
                    {/* mobile */}
                    <div className="flex flex-col md:hidden container mx-auto overflow-hidden" style={{height:mobileHeight+'px'}}>
                        <SizeBox vertical="h-[6px]" />
                        <SubNavComponent nabs={nabs} lesson={lesson} />
                        <SizeBox vertical="h-[6px]" />
                        <TitleComponent
                            furigana={furigana}
                            furiTranslate={Helper.furiTranslate}
                            furi={title.furi}
                            normal={title.normal}
                            tran={lang == 'en' ? title.en : title.mm}
                            fontSize={fontSize}
                            showTranslate={showTranslate}
                        />
                        <SizeBox vertical="h-3" />
                        <div className="w-full flex flex-row justify-center">
                            <AmidioMdComponent audioUrl={audioUrl} scoreIncrementAction={scoreIncrementAction} />
                        </div>
                        <SizeBox vertical="h-3" />
                        <Card className="flex-1 bg-white dark:bg-darkFooter rounded-[20px] overflow-y-scroll scrollbar-hide">
                            {/* card-light-shadow dark-shadow */}
                            <ConversationComponent
                                conversation={conversation}
                                furigana={furigana}
                                furiTranslate={Helper.furiTranslate}
                                fontSize={fontSize}
                                lang={lang}
                                showTranslate={showTranslate}
                                setShowTranslate={setShowTranslate}
                                kanjiDetail={kanjiDetail}
                            />
                        </Card>
                        <SizeBox vertical="h-[10px]" />
                    </div>
                    <SpeedDialComponent
                        type={SPEDIAL.FURI_TRAN}
                        showTranslate={showTranslate}
                        setShowTranslate={setShowTranslate}
                        dark={dark}
                        furigana={furigana}
                        toggleFuri={toggleFuriAction}
                        isHovering={isHovering}
                        setIsHovering={setIsHovering}
                    />
                </div>
            }
            Routes={Routes}
            Logger={Logger}
            useNavigate={useNavigate}
            showModel={showModel}
            setShowModel={setShowModel}
            setUnlockPremium={setUnlockPremium}
        />
    </>)
}
export default memo(KaiwaScreen);
