export const renshuuA = [
    {
        sentence: [
            {
                furi: "どんな　もんだいが　｜＾試（し）験（けん）｜に　でるか、わかりません。",
                normal: "どんな　もんだいが　試験に　でるか、わかりません。",
                en: "I don't know what kind of problems will be on the exam.",
                mm: "စာမေးပွဲမှာ ဘယ်လိုအခက်အခဲတွေရှိမယ် မသိဘူး။",
            },
            {
                furi: "なんじに　｜＾試（し）験（けん）｜が　はじまるか、わかりません。",
                normal: "なんじに　試験が　はじまるか、わかりません。",
                en: "I don't know when the exam will start.",
                mm: "စာမေးပွဲ ဘယ်တော့စမလဲမသိဘူး။",
            },
            {
                furi: "どうして　この　｜＾答（こた）｜えが　ただしいか、わかりません。",
                normal: "どうして　この　答えが　ただしいか、わかりません。",
                en: "ဒီအဖြေက ဘာကြောင့်မှန်လဲ နားမလည်ဘူး။",
                mm: "I don't understand why this answer is correct.",
            },
            {
                furi: "どれが　｜＾正（ただ）｜しい　こたえか、わかりません。",
                normal: "どれが　正しい　こたえか、わかりません。",
                en: "I don't know which is the correct answer.",
                mm: "ဘယ်ဟာ အဖြေမှန်လဲ မသိဘူး။",
            },
        ],
        audioUrl: require("@assets/audio/lesson40/renshua/4N40RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾会（かい）議（ぎ）室（しつ）｜が　｜！つかえる｜か　どうか、｜＾確（たし）｜かめて　ください。",
                normal: "会議室が　つかえるか　どうか、確かめて　ください。",
                en: "Please check if the conference room is available.",
                mm: "အစည်းအဝေးခန်းရှိမရှိ စစ်ဆေးပါ။",
            },
            {
                furi: "＾傷（きず）｜が　｜！ない｜か　どうか、｜＾確（たし）｜かめて　ください。",
                normal: "傷が　ないか　どうか、確かめて　ください。",
                en: "Please check if there are any scratches.",
                mm: "ခြစ်ရာများ ရှိမရှိ စစ်ဆေးပါ။",
            },
            {
                furi: "＾荷（に）物（もつ）｜が　｜！ついた｜が　ないか　どうか、｜＾確（たし）｜かめて　ください。",
                normal: "荷物が　ついたか　どうか、確かめて　ください。",
                en: "Please check if your luggage has arrived.",
                mm: "သင်၏ ခရီးဆောင်အိတ်များ ရောက်ရှိလာခြင်း ရှိမရှိ စစ်ဆေးပါ။",
            },
            {
                furi: "その　｜＾話（はなし）｜は　｜！ほんとう｜が　ないか　どうか、｜＾確（たし）｜かめて　ください。",
                normal: "その　話は　ほんとうか　どうか、確かめて　ください。",
                en: "Please confirm whether the story is true or not.",
                mm: "ဇာတ်လမ်းက မှန်သည်ဖြစ်စေ မမှန်ကန်ကြောင်း အတည်ပြုပေးပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson40/renshua/4N40RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "この　｜＾靴（くつ）｜を　｜！はいて｜　みます。",
                normal: "この　靴を　はいて　みます。",
                en: "I'll try on these shoes.",
                mm: "ဒီဖိနပ်တွေကို ငါစမ်းကြည့်မယ်။",
            },
            {
                furi: "＾先（せん）生（せい）｜に　｜！きいて｜　みます。",
                normal: "先生に　きいて　みます。",
                en: "I will ask the teacher.",
                mm: "ဆရာ့ကို မေးကြည့်မယ်။",
            },
            {
                furi: "＾日（に）本（ほん）語（ご）｜で　｜！せつめいして｜　みます。",
                normal: "日本語で　せつめいして　みます。",
                en: "I will explain in Japanese.",
                mm: "ဂျပန်လိုရှင်းပြမယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson40/renshua/4N40RSA3.mp3")
    },
];

/*
    {
        sentence: [
            {
                furi: "",
                normal: "",
                en: "",
                mm: "",
            },
        ],
        audioUrl: require("@assets/audio/testing.mp3")
    },

 // furi: 'あしたから　｜！旅（りょ）行（こう）なん｜です。',
        // normal: 'あしたから　旅行　なんです。',
        // en: 'It\'s a trip from tomorrow.',
        // mm: 'မနက်ဖြန်ကနေစပြီး ခရီးထွက်ပါမယ်။',
        //


*/