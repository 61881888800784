
export const lesson13 = [
    {"lesson":13,"level":4,"question":"かおいろ","represent":["顔","色"],"answer":["顔色","活発","活動","生活"],"true":"顔色","times":10},
    {"lesson":13,"level":4,"question":"しょうひん","represent":["品"],"answer":["商品","特色","和風","主人"],"true":"商品","times":10},
    {"lesson":13,"level":4,"question":"いっかい","represent":["一","回"],"answer":["一回","品物","活字","人形"],"true":"一回","times":10},
    {"lesson":13,"level":4,"question":"こいぬ","represent":["子","犬"],"answer":["子犬","生活","活字","和服"],"true":"子犬","times":10},
    {"lesson":13,"level":4,"question":"ようふく","represent":["洋","服"],"answer":["洋服","子犬","活発","形式"],"true":"洋服","times":10},
    {"lesson":13,"level":4,"question":"どうい","represent":["同","意"],"answer":["同意","活字","形式","子犬"],"true":"同意","times":10},
    {"lesson":13,"level":4,"question":"せいかつ","represent":["生","活"],"answer":["生活","品物","主人","活発"],"true":"生活","times":10},
    {"lesson":13,"level":4,"question":"かつようがた","represent":["活","用","形"],"answer":["活用形","民族学","食料品","日本犬"],"true":"活用形","times":10},
    {"lesson":13,"level":4,"question":"かつどう","represent":["活","動"],"answer":["活動","洋服","活字","顔色"],"true":"活動","times":10},
    {"lesson":13,"level":4,"question":"けいしき","represent":["形"],"answer":["形式","活字","顔色","子犬"],"true":"形式","times":10},
    {"lesson":13,"level":4,"question":"にほんけん","represent":["日","本","犬"],"answer":["日本犬","食料品","民族学","活用形"],"true":"日本犬","times":10},
    {"lesson":13,"level":4,"question":"みんぞくがく","represent":["民","族","学"],"answer":["民族学","日本犬","活用形","食料品"],"true":"民族学","times":10},
    {"lesson":13,"level":4,"question":"しょくりょうひん","represent":["食","料","品"],"answer":["食料品","民族学","活用形","日本犬"],"true":"食料品","times":10},
    {"lesson":13,"level":4,"question":"とくしょく","represent":["特","色"],"answer":["特色","商品","一回","人形"],"true":"特色","times":10},
    {"lesson":13,"level":4,"question":"かつじ","represent":["活","字"],"answer":["活字","商品","特色","顔色"],"true":"活字","times":10},
    {"lesson":13,"level":4,"question":"しゅじん","represent":["主","人"],"answer":["主人","洋服","顔色","和服"],"true":"主人","times":10},
    {"lesson":13,"level":4,"question":"にんぎょう","represent":["人","形"],"answer":["人形","商品","活字","生活"],"true":"人形","times":10},
    {"lesson":13,"level":4,"question":"わふく","represent":["服"],"answer":["和服","一回","主人","人形"],"true":"和服","times":10},
    {"lesson":13,"level":4,"question":"しなもの","represent":["品","物"],"answer":["品物","子犬","一回","主人"],"true":"品物","times":10},
    {"lesson":13,"level":4,"question":"みんぞく","represent":["民","族"],"answer":["民族","活発","和服","洋服"],"true":"民族","times":10},
    {"lesson":13,"level":4,"question":"かっぱつ","represent":["活","発"],"answer":["活発","商品","和服","活字"],"true":"活発","times":10},
]