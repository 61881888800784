import { BunpouType } from "..";

export const bunpou: BunpouType = {
  mondai1:{
    title: 'もんだい１（　　）に　何を　入れますか。１・２・３・４から　いちばん　いい　ものを　ーつ　えらんで　ください。',
    questions: [
      {
        number: 1,
        question: [
          '(1)この　レストランでは、料理（　　）使う野菜を　庭で　育てています。',
        ],
        answers: ['は','に','や','の'],
        pick: '',
        correct: 'に'
      },
      {
        number: 2,
        question: [
          '(2)（ケーキ屋で）',
          '客「すみません。いちごの　ケーキと、チョコレートの　ケーキを　二つ（　　）　ください。',
        ],
        answers: ['と','が','ずつ','など'],
        pick: '',
        correct: 'ずつ'
      },
      {
        number: 3,
        question: [
          '(3)きのう（　　）　違って、今日は　いい天気です。',
        ],
        answers: ['と','の','に','まで'],
        pick: '',
        correct: 'と'
      },
      {
        number: 4,
        question: [
          'A「この　カレーは　からいですね。」',
          'B「えっ？そうですか。私（　　）　からくないですよ。',
        ],
        answers: ['では','には','と','へ'],
        pick: '',
        correct: 'には'
      },
      {
        number: 5,
        question: [
          '(5)A[台所から　いいにおい（　　）　しますね。」',
          'B「クッキーを　焼いているんです。」',
        ],
        answers: ['で','に','を','が'],
        pick: '',
        correct: 'が'
      },
      {
        number: 6,
        question: [
          '(6)インタネットを　使えば、（　　）　買い物が　できて、便利です。',
        ],
        answers: ['いつでも','いつまで','どこから','どこにも'],
        pick: '',
        correct: 'いつでも'
      },
      {
        number: 7,
        question: [
          '(7)明日から　三日間　京都へ　行く　予定だが、泊まる　ホテルが（　　）決まって　いない。',
        ],
        answers: ['もう','まだ','そろそろ','もうすぐ'],
        pick: '',
        correct: 'まだ'
      },
      {
        number: 8,
        question: [
          '(8)田中「森さん、先週は　かぜで　お休みでしたね。具合は　どうですか。」',
          '森「はい、（　　）元気に　なりました。',
        ],
        answers: ['よく','あまり','すっかり','かならず'],
        pick: '',
        correct: 'すっかり'
      },
      {
        number: 9,
        question: [
          '(9)この　神社　祭りは、長い間　林の人たち（　　）　続けられている。',
        ],
        answers: ['から','より','によって','について'],
        pick: '',
        correct: 'によって'
      },
      {
        number: 10,
        question: [
          '(10)入学式の　間、子どもたちは　先生の　話を　（　　）聞いて　いました。',
        ],
        answers: ['熱心','熱心な','熱心で','熱心に'],
        pick: '',
        correct: '熱心に'
      },
      {
        number: 11,
        question: [
          '(11)私は　来年の　夏、富士山に（　　）　つもりです。'
        ],
        answers: ['のぼる','のぼった','のぼろう','のぼりたい'],
        pick: '',
        correct: 'のぼる'
      },
      {
        number: 12,
        question: [
          '(12)（駅で）',
          '客「あのう、この　さいふが　落ちて（　　）。」',
          '駅員「ありがとうございます。どこに　あらましたか。」',
          '客「トイレの　前です。」',
        ],
        answers: ['いました','しました','みました','おきました'],
        pick: '',
        correct: 'いました'
      },
      {
        number: 13,
        question: [
          '(13)朝、私は　いつも　バナナしか（　　）。'
        ],
        answers: ['食べます','食べません','食べることが　できます','食べる　かもしれません'],
        pick: '',
        correct: '食べません'
      },
      {
        number: 14,
        question: [
          '(14)山下「森さんの　たんじょうびに　何を　あげましょうか。」',
          '田中「花は　どうですか。」',
          '山下「いいですね。森さんは　花が　好きだから、きっと（　　）。',
        ],
        answers: ['よろこぶそうです','よろこんだんです','よろこぶでしょう','よろこんだようです'],
        pick: '',
        correct: 'よろこぶでしょう'
      },
      {
        number: 15,
        question: [
          '(15)（駅で）',
          'A「飲み物が　ほしいので、急いで　買ってきますね。」',
          'B「わかりました。出発まで　時間は　ありますから、（　　）よ。」',
        ],
        answers: ['急がなくなります','急がなければなりません','急がだほうが　いいです','急がなくてもいいです'],
        pick: '',
        correct: '急がなくてもいいです'
      },
    ]
  },
  mondai2:{
    title: 'もんだい（2）｜！ ★ ｜ に　何を　入れますか。１・２・３・４から　いちばん　いい　ものを　ーつ　えらんで　ください。',
    questions: [
      {
        number: 16,
        question: [
          '(16)中川「山下さん、駅から　ホテルまで　行き方が　わかりますか。」',
          '山下「いいえ、わかりません。｜！＿＿★＿｜に　聞きましょう。」',
        ],
        answers: ['で','だれ','駅','か'],
        pick: '',
        correct: 'だれ'
      },
      {
        number: 17,
        question: [
          '(17)最近、仕事が　いそがしくて、｜！＿＿★＿｜日が　多い。',
        ],
        answers: ['帰る','なる','遅く','のが'],
        pick: '',
        correct: '遅く'
      },
      {
        number: 18,
        question: [
          '(18)両親と　いっしょに｜！＿＿★＿｜貯金しています。',
        ],
        answers: ['ために','家を','住める','建てる'],
        pick: '',
        correct: '建てる'
      },
      {
        number: 19,
        question: [
          '(19)明日は　朝早く　出かけない｜！＿＿★＿｜かどうか　心配です。',
        ],
        answers: ['が','と','早起きできる','いけないのです'],
        pick: '',
        correct: 'が'
      },
      {
        number: 20,
        question: [
          '(20)きのう　パーテイーで　丸い｜！＿＿★＿｜くだものを　食べました。',
        ],
        answers: ['あまい','の','赤くて','形'],
        pick: '',
        correct: '赤くて'
      },
    ]
  }
}