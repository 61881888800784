import Icon from "@consts/icon";

export const lesson28 =  {
    title: {
        furi: '＾出（しゅっ）張（ちょう）｜も　｜＾多（おお）｜いし、　｜＾試（し）験（けん）｜も　あるし　。。。',
        normal: '出張も　多いし、　試験も　あるし　。。。',
        en: 'There are a lot of business trips, and there are also exams. . .',
        mm: 'အလုပ်ကိစ္စနဲ့ စာမေးပွဲတွေလည်း အများကြီးရှိတယ်။ . .',
    },
    conversation: [
        {
            person: {
                furi: '＾小（お）川（がわ）幸（さち）子（こ）',
                normal: '小川幸子',
                avatar: Icon.Kaiwa.WOMEN1

            },
            sentence: [
                {
                    furi: 'ミラーさん、　ちょっと　お｜＾願（ねが）｜いが　あるんですが。',
                    normal: 'ミラーさん、　ちょっと　お願いが　あるんですが。',
                    en: 'Mr. Miller, I have a request for you.',
                    mm: 'မစ္စတာ မီလာ၊ နည်းနည်း အကူအညီတောင်းဆိုစရာ ရှိလို့ပါ။ ',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '＾何（なん）｜ですか',
                    normal: '何ですか',
                    en: 'what is it',
                    mm: 'ဘာများလဲ',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）幸（さち）子（こ）',
                normal: '小川幸子',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: '＾実（じつ）｜は　８｜＾月（がつ）｜に　オーストラリアへ　ホームステイに　｜＾行（い）｜くんです。',
                    normal: '実は　８月に　オーストラリアへ　ホームステイに　行くんです。',
                    en: "Actually, I'm going to Australia for a homestay in August.",
                    mm: 'တကယ်က ငါသြဂုတ်လမှာ homestay လုပ်ဖို့ သွားပါမယ်။',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1

            },
            sentence: [
                {
                    furi: 'ホームステイですか。いいですね。',
                    normal: 'ホームステイですか。いいですね。',
                    en: 'Homestay? Sounds good.',
                    mm: 'Homestay လား? ကောင်းသားပဲ။',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）幸（さち）子（こ）',
                normal: '小川幸子',
                avatar: Icon.Kaiwa.WOMEN1

            },
            sentence: [
                {
                    furi: 'ええ。',
                    normal: 'ええ。',
                    en: 'yes.',
                    mm: 'အင်း',
                },
                {
                    furi: 'それで　｜＾今（いま）｜　｜＾友（とも）達（だち）｜と　｜＾英（えい）語（ご）｜を　｜＾勉（べん）強（きょう）｜して　いるんですが　。。。',
                    normal: 'それで　今　友達と　英語を　勉強して　いるんですが　。。。',
                    en: "So now I'm studying English with my friends. . .",
                    mm: 'ဒါကြောင့် အခု သူငယ်ချင်းတွေနဲ့ အင်္ဂလိပ်စာ လေ့လာနေတယ်။ . .',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'ええ。',
                    normal: 'ええ。',
                    en: 'yes',
                    mm: 'အင်း',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）幸（さち）子（こ）',
                normal: '小川幸子',
                avatar: Icon.Kaiwa.WOMEN1

            },
            sentence: [
                {
                    furi: 'なかなか　｜＾上（じょう）手（ず）｜に　ならないんです。',
                    normal: 'なかなか　上手に　ならないんです。',
                    en: "I'm not very good at it.",
                    mm: 'တော်တော်နဲ့ မကျွမ်းကျင်လာဘူး။',
                },
                {
                    furi: '＾先（せん）生（せい）｜も　いないし、　｜＾英（えい）語（ご）｜で　｜＾話（はな）｜す　チャンスも　ないし。。。',
                    normal: '先生も　いないし、　英語で　話す　チャンスも　ないし。。。',
                    en: 'There is no teacher and no chance to speak in English. . .',
                    mm: 'ဆရာလည်းမရှိသလို အင်္ဂလိပ်လိုပြောဖို့လည်း အခွင့်အလမ်းမရှိပါဘူး။ . .',
                },
                {
                    furi: 'ミラーさん、｜＾会（かい）話（わ）｜の　｜＾先（せん）生（せい）｜に　なって　いただけませんか。',
                    normal: 'ミラーさん、会話の　先生に　なって　いただけませんか。',
                    en: 'Mr. Miller, would you be my conversation teacher?',
                    mm: 'မစ္စတာ မီလာ၊ English speaking ဆရာ လုပ်‌ပေးလို့ရမလား။',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'え？　｜＾先（せん）生（せい）｜に？　うーん、ちょっと　｜＾仕（し）事（ごと）｜が　。。。',
                    normal: 'え？　先生に？　うーん、ちょっと　仕事が　。。。',
                    en: 'teacher? Hmm, I have some work to do. . .',
                    mm: 'ဆရာလား? ငါ့မှာ လုပ်စရာလေးတွေ ရှိသေးတယ်" . .',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）幸（さち）子（こ）',
                normal: '小川幸子',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: 'お｜＾暇（ひま）｜な　とき、お｜＾茶（ちゃ）｜でも　｜＾飲（の）｜みながら　。。。',
                    normal: 'お暇な　とき、お茶でも　飲みながら　。。。',
                    en: 'In your free time, drinking tea. . .',
                    mm: 'အားမယ်ဆိုရင် လက်ဖက်ရည်လေး ဘာလေးသောက်ရင်းနဲ့ . .',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'うーん、　｜＾出（しゅっ）張（ちょう）｜も　｜＾多（おお）｜いし、　もうすぐ　｜＾日（に）本（ほん）語（ご）｜の　｜＾試（し）験（けん）｜も　あるし　。。。',
                    normal: 'うーん、　出張も　多いし、　もうすぐ　日本語の　試験も　あるし　。。。',
                    en: 'Well, I have a lot of business trips, and I have a Japanese exam soon. . .',
                    mm: 'ဟင့်အင်း၊ အလုပ်နဲ့ ခရီးသွားရတာတွေ များပြီး သိပ်မကြာခင်မှာလဲ ဂျပန်စာ စာမေးပွဲလဲ ရှိတယ် . .',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）幸（さち）子（こ）',
                normal: '小川幸子',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: 'そうですか。',
                    normal: 'そうですか。',
                    en: 'is that so.',
                    mm: 'ဟုတ်လား။',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'すみません。',
                    normal: 'すみません。',
                    en: 'sorry',
                    mm: 'တောင်းပန်ပါတယ်။',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson28/kaiwa/4N28KW.mp3")
}

/*
    {
        title: {
            furi: '',
            normal: '',
            en: '',
            mm: '',
        },
        conversation: [
            {
                person: {
                    furi: '',
                    normal: '',
                },
                sentence: [
                    {
                        furi: '',
                        normal: '',
                        en: '',
                        mm: '',
                    },
                ],
            },
        ],
        audioUrl: require("@assets/audio/lesson26/kaiwa/4N26KW.mp3")
    }
*/