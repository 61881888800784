export const lesson36 = [
  {
      "furi": "あいます",
      "normal": "あいます",
      "en": "meet",
      "remark_en": "",
      "mm": "တွေ့သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO1.mp3")
  },
  {
      "furi": "＾貯（ちょ）金（きん）｜します",
      "normal": "貯金します",
      "en": "save money",
      "remark_en": "",
      "mm": "ငွေစုသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO2.mp3")
  },
  {
      "furi": "＾過（す）｜ぎます",
      "normal": "過ぎます",
      "en": "pass",
      "remark_en": "",
      "mm": "ကျော်လွန်သည်၊လွန်လွန်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO3.mp3")
  },
  {
      "furi": "＾慣（な）｜れます",
      "normal": "慣れます",
      "en": "get accustomed to",
      "remark_en": "",
      "mm": "အသားကျသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO4.mp3")
  },
  {
      "furi": "＾腐（くさ）｜ります",
      "normal": "腐ります",
      "en": "Rotten",
      "remark_en": "",
      "mm": "ပုတ်သိုးသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO5.mp3")
  },
  {
      "furi": "＾剣（けん）道（どう）",
      "normal": "剣道",
      "en": "kendo",
      "remark_en": "",
      "mm": "ကန်ဒို",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO6.mp3")
  },
  {
      "furi": "＾柔（じゅう）道（どう）",
      "normal": "柔道",
      "en": "judo",
      "remark_en": "",
      "mm": "ဂျူဒို",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO7.mp3")
  },
  {
      "furi": "ラッシュ",
      "normal": "ラッシュ",
      "en": "rush",
      "remark_en": "",
      "mm": "လမ်းအသွားအလာများချိန်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO8.mp3")
  },
  {
      "furi": "＾宇（う）宙（ちゅう）",
      "normal": "宇宙",
      "en": "space",
      "remark_en": "",
      "mm": "အာကာသ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO9.mp3")
  },
  {
      "furi": "＾曲（きょく）",
      "normal": "曲",
      "en": "song",
      "remark_en": "",
      "mm": "တီးလုံး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO10.mp3")
  },
  {
      "furi": "＾毎（まい）週（しゅう）",
      "normal": "毎週",
      "en": "every week",
      "remark_en": "",
      "mm": "အပတ်တိုင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO11.mp3")
  },
  {
      "furi": "＾毎（まい）月（つき）",
      "normal": "毎月",
      "en": "every month",
      "remark_en": "",
      "mm": "လတိုင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO12.mp3")
  },
  {
      "furi": "＾毎（まい）年（とし）",
      "normal": "毎年",
      "en": "every year",
      "remark_en": "",
      "mm": "နှစ်တိုင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO13.mp3")
  },
  {
      "furi": "＾毎（まい）年（ねん）",
      "normal": "毎年",
      "en": "every year",
      "remark_en": "",
      "mm": "နှစ်တိုင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO14.mp3")
  },
  {
      "furi": "このごろ",
      "normal": "このごろ",
      "en": "these days",
      "remark_en": "",
      "mm": "ဒီတလော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO15.mp3")
  },
  {
      "furi": "やっと",
      "normal": "やっと",
      "en": "finally",
      "remark_en": "",
      "mm": "အဆုံးမှာတော့",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO16.mp3")
  },
  {
      "furi": "かなり",
      "normal": "かなり",
      "en": "fairly",
      "remark_en": "",
      "mm": "တော်တော်လေး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO17.mp3")
  },
  {
      "furi": "＾必（かなら）｜ず",
      "normal": "必ず",
      "en": "by all means",
      "remark_en": "",
      "mm": "မဖြစ်မနေ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO18.mp3")
  },
  {
      "furi": "＾絶（ぜっ）対（たい）｜に",
      "normal": "絶対に",
      "en": "absolutely",
      "remark_en": "",
      "mm": "လုံးဝ၊ကျိန်းသေပေါက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO19.mp3")
  },
  {
      "furi": "＾上（じょう）手（ず）｜に",
      "normal": "上手に",
      "en": "well, skillfully",
      "remark_en": "",
      "mm": "ကျွမ်းကျင်စွာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO20.mp3")
  },
  {
      "furi": "できるだけ",
      "normal": "できるだけ",
      "en": "as much as possible",
      "remark_en": "",
      "mm": "တတ်နိုင်သလောက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO21.mp3")
  },
  {
      "furi": "ほとんど",
      "normal": "ほとんど",
      "en": "almost",
      "remark_en": "",
      "mm": "အများစု",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO22.mp3")
  },
  {
      "furi": "ショパン",
      "normal": "ショパン",
      "en": "Chopin",
      "remark_en": "",
      "mm": "ရှော့ပန်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO23.mp3")
  },
  {
      "furi": "お｜＾客（きゃく）｜＾様（さま）",
      "normal": "お客様",
      "en": "guest, customer",
      "remark_en": "",
      "mm": "ဧည့်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO24.mp3")
  },
  {
      "furi": "＾特（とく）別（べつ）｜[な]",
      "normal": "特別[な]",
      "en": "special",
      "remark_en": "",
      "mm": "အထူး၊သီးခြားဖြစ်သော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO25.mp3")
  },
  {
      "furi": "していらっしゃいます",
      "normal": "していらっしゃいます",
      "en": "be doing",
      "remark_en": "",
      "mm": "လုပ်နေပါတယ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO26.mp3")
  },
  {
      "furi": "＾水（すい）泳（えい）",
      "normal": "水泳",
      "en": "swimming",
      "remark_en": "",
      "mm": "ရေကူးခြင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO27.mp3")
  },
  {
      "furi": "＾違（ちが）｜います",
      "normal": "違います",
      "en": "wrong",
      "remark_en": "",
      "mm": "လွဲသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO28.mp3")
  },
  {
      "furi": "使っていらっしゃるんですね。",
      "normal": "使っていらっしゃるんですね。",
      "en": "You are using it.",
      "remark_en": "",
      "mm": "သုံးနေတယ်လား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO29.mp3")
  },
  {
      "furi": "チャレンジします",
      "normal": "チャレンジします",
      "en": "Challenge",
      "remark_en": "",
      "mm": "စိန်ခေါ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO30.mp3")
  },
  {
      "furi": "＾気（き）持（も）｜ち",
      "normal": "気持ち",
      "en": "feeling",
      "remark_en": "",
      "mm": "စိတ်၊ စိတ်နေစိတ်ထား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson36/vocab/4N36VO31.mp3")
  },
  {
      "furi": "＾乗（の）｜り｜＾物（もの）",
      "normal": "乗り物",
      "en": "vehicle",
      "remark_en": "",
      "mm": "ယာဉ်၊ စီးစရာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "ー｜＾世（せい）｜＾紀（き）",
      "normal": "ー世紀",
      "en": "~century",
      "remark_en": "",
      "mm": "-ရာစုနှစ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾遠（とお）｜く",
      "normal": "遠く",
      "en": "away",
      "remark_en": "",
      "mm": "အဝေး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾珍（めずら）｜しい",
      "normal": "珍しい",
      "en": "rare",
      "remark_en": "",
      "mm": "ရှားပါးသော၊ ထူးဆန်းသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾汽（き）車（しゃ）",
      "normal": "汽車",
      "en": "steamtrain",
      "remark_en": "",
      "mm": "ရေနွေးငွေ့ရထား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾汽（き）船（せん）",
      "normal": "汽船",
      "en": "steamship",
      "remark_en": "",
      "mm": "ရေနွေးငွေ့သင်္ဘော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾大（おお）勢（ぜい）｜の～",
      "normal": "大勢の～",
      "en": "in great numbers of~",
      "remark_en": "",
      "mm": "များလိုက်တဲ့～",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾運（はこ）｜びます",
      "normal": "運びます",
      "en": "transport",
      "remark_en": "",
      "mm": "သယ်ဆောင်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾利（り）用（よう）｜します",
      "normal": "利用します",
      "en": "use",
      "remark_en": "",
      "mm": "အသုံးချသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾自（じ）由（ゆう）｜に",
      "normal": "自由に",
      "en": "freely",
      "remark_en": "",
      "mm": "လွတ်လပ်စွာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  }
]

/*
    {
        furi: "",
        normal: "",
        en: "",
        remark_en: "",
        mm: "",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson29/vocab/4N29VO1.mp3")
    },


*/
const test = [
  {
    'goi': 'あいます',
    'unicode': 'တွေ့သည်',
    'zawgyi': 'ေတြ႕သည္',
    'english': 'meet',
    'long': false
  },
  {
    'goi': '貯（ちょ）」金（きん）」します',
    'unicode': 'ငွေစုသည်',
    'zawgyi': 'ေငြစုသည္',
    'english': 'save money',
    'long': false
  },
  {
    'goi': '過（す）」ぎます',
    'unicode': 'ကျော်လွန်သည်၊လွန်လွန်းသည်',
    'zawgyi': 'ေက်ာ္လြန္သည္၊လြန္လြန္းသည္',
    'english': 'pass',
    'long': false
  },
  {
    'goi': '慣（な）」れます',
    'unicode': 'အသားကျသည်',
    'zawgyi': 'အသားက်သည္',
    'english': 'get accustomed to',
    'long': false
  },
  {
    'goi': '腐（くさ）」ります',
    'unicode': 'ပုတ်သိုးသည်',
    'zawgyi': 'ပုတ္သိုးသည္',
    'english': 'Rotten',
    'long': false
  },
  {
    'goi': '剣（けん）」道（どう）',
    'unicode': 'ကန်ဒို',
    'zawgyi': 'ကန္ဒို',
    'english': 'kendo',
    'long': false
  },
  {
    'goi': '柔（じゅう）」道（どう）',
    'unicode': 'ဂျူဒို',
    'zawgyi': 'ဂ်ဴဒို',
    'english': 'judo',
    'long': false
  },
  {
    'goi': 'ラッシュ',
    'unicode': 'လမ်းအသွားအလာများချိန်',
    'zawgyi': 'လမ္းအသြားအလာမ်ားခ်ိန္',
    'english': 'rush',
    'long': false
  },
  {
    'goi': '宇（う）」宙（ちゅう）',
    'unicode': 'အာကာသ',
    'zawgyi': 'အာကာသ',
    'english': 'space',
    'long': false
  },
  {
    'goi': '曲（きょく）',
    'unicode': 'တီးလုံး',
    'zawgyi': 'တီးလုံး',
    'english': 'song',
    'long': false
  },
  {
    'goi': '毎（まい）」週（しゅう）',
    'unicode': 'အပတ်တိုင်း',
    'zawgyi': 'အပတ္တိုင္း',
    'english': 'every week',
    'long': false
  },
  {
    'goi': '毎（まい）」月（つき）',
    'unicode': 'လတိုင်း',
    'zawgyi': 'လတိုင္း',
    'english': 'every month',
    'long': false
  },
  {
    'goi': '毎（まい）」年（とし）',
    'unicode': 'နှစ်တိုင်း',
    'zawgyi': 'ႏွစ္တိုင္း',
    'english': 'every year',
    'long': false
  },
  {
    'goi': '毎（まい）」年（ねん）',
    'unicode': 'နှစ်တိုင်း',
    'zawgyi': 'ႏွစ္တိုင္း',
    'english': 'every year',
    'long': false
  },
  {
    'goi': 'このごろ',
    'unicode': 'ဒီတလော',
    'zawgyi': 'ဒီတေလာ',
    'english': 'these days',
    'long': false
  },
  {
    'goi': 'やっと',
    'unicode': 'အဆုံးမှာတော့',
    'zawgyi': 'အဆုံးမွာေတာ့',
    'english': 'finally',
    'long': false
  },
  {
    'goi': 'かなり',
    'unicode': 'တော်တော်လေး',
    'zawgyi': 'ေတာ္ေတာ္ေလး',
    'english': 'fairly',
    'long': false
  },
  {
    'goi': '必（かなら）」ず',
    'unicode': 'မဖြစ်မနေ',
    'zawgyi': 'မျဖစ္မေန',
    'english': 'by all means',
    'long': false
  },
  {
    'goi': '絶（ぜっ）」対（たい）」に',
    'unicode': 'လုံးဝ၊ကျိန်းသေပေါက်',
    'zawgyi': 'လုံးဝ၊က်ိန္းေသေပါက္',
    'english': 'absolutely',
    'long': false
  },
  {
    'goi': '上（じょう）」手（ず）」に',
    'unicode': 'ကျွမ်းကျင်စွာ',
    'zawgyi': 'ကၽြမ္းက်င္စြာ',
    'english': 'well, skillfully',
    'long': false
  },
  {
    'goi': 'できるだけ',
    'unicode': 'တတ်နိုင်သလောက်',
    'zawgyi': 'တတ္နိုင္သေလာက္',
    'english': 'as much as possible',
    'long': false
  },
  {
    'goi': 'ほとんど',
    'unicode': 'အများစု',
    'zawgyi': 'အမ်ားစု',
    'english': 'almost',
    'long': false
  },
  {
    'goi': 'ショパン',
    'unicode': 'ရှော့ပန်',
    'zawgyi': 'ေရွာ့ပန္',
    'english': 'Chopin',
    'long': false
  },
  {
    'goi': 'お」客（きゃく）」様（さま）',
    'unicode': 'ဧည့်သည်',
    'zawgyi': 'ဧည့္သည္',
    'english': 'guest, customer',
    'long': false
  },
  {
    'goi': '特（とく）」別（べつ）」[な]',
    'unicode': 'အထူး၊သီးခြားဖြစ်သော',
    'zawgyi': 'အထူး၊သီးျခားျဖစ္ေသာ',
    'english': 'special',
    'long': false
  },
  {
    'goi': 'していらっしゃいます',
    'unicode': 'လုပ်နေပါတယ်',
    'zawgyi': 'လုပ္ေနပါတယ္',
    'english': 'be doing',
    'long': false
  },
  {
    'goi': '水（すい）」泳（えい）',
    'unicode': 'ရေကူးခြင်း',
    'zawgyi': 'ေရကူးျခင္း',
    'english': 'swimming',
    'long': false
  },
  {
    'goi': '違（ちが）」います',
    'unicode': 'လွဲသည်',
    'zawgyi': 'လြဲသည္',
    'english': 'wrong',
    'long': false
  },
  {
    'goi': '使っていらっしゃるんですね。',
    'unicode': 'သုံးနေတယ်လား',
    'zawgyi': 'သုံးေနတယ္လား',
    'english': 'You are using it.',
    'long': true
  },
  {
    'goi': 'チャレンジします',
    'unicode': 'စိန်ခေါ်သည်',
    'zawgyi': 'စိန္ေခၚသည္',
    'english': 'Challenge',
    'long': false
  },
  {
    'goi': '気（き）」持（も）」ち',
    'unicode': 'စိတ်၊ စိတ်နေစိတ်ထား',
    'zawgyi': 'စိတ္၊စိတ္ေနစိတ္ထား',
    'english': 'feeling',
    'long': false
  },
  {
    'goi': '乗（の）」り」物（もの）',
    'unicode': 'ယာဉ်၊ စီးစရာ',
    'zawgyi': 'ယာဥ္၊စီးစရာ',
    'english': 'vehicle',
    'long': false
  },
  {
    'goi': 'ー」世（せい）」紀（き）',
    'unicode': '-ရာစုနှစ်',
    'zawgyi': '-ရာစုႏွစ္',
    'english': '~century',
    'long': false
  },
  {
    'goi': '遠（とお）」く',
    'unicode': 'အဝေး',
    'zawgyi': 'အေဝး',
    'english': 'away',
    'long': false
  },
  {
    'goi': '珍（めずら）」しい',
    'unicode': 'ရှားပါးသော၊ ထူးဆန်းသော',
    'zawgyi': 'ရွားပါးေသာ၊ထူးဆန္းေသာ',
    'english': 'rare',
    'long': false
  },
  {
    'goi': '汽（き）」車（しゃ）',
    'unicode': 'ရေနွေးငွေ့ရထား',
    'zawgyi': 'ေရေႏြးေငြ႕ရထား',
    'english': 'steamtrain',
    'long': false
  },
  {
    'goi': '汽（き）」船（せん）',
    'unicode': 'ရေနွေးငွေ့သင်္ဘော',
    'zawgyi': 'ေရေႏြးေငြ႕သေဘၤာ',
    'english': 'steamship',
    'long': false
  },
  {
    'goi': '大（おお）」勢（ぜい）」の～',
    'unicode': 'များလိုက်တဲ့～',
    'zawgyi': 'မ်ားလိုက္တဲ့～',
    'english': 'in great numbers of~',
    'long': false
  },
  {
    'goi': '運（はこ）」びます',
    'unicode': 'သယ်ဆောင်သည်',
    'zawgyi': 'သယ္ေဆာင္သည္',
    'english': 'transport',
    'long': false
  },
  {
    'goi': '利（り）」用（よう）」します',
    'unicode': 'အသုံးချသည်',
    'zawgyi': 'အသုံးခ်သည္',
    'english': 'use',
    'long': false
  },
  {
    'goi': '自（じ）」由（ゆう）」に',
    'unicode': 'လွတ်လပ်စွာ',
    'zawgyi': 'လြတ္လပ္စြာ',
    'english': 'freely',
    'long': false
  }
]