
export const lesson7 = [
    {"lesson":7,"level":4,"question":"土地","represent":["土","地"],"answer":["とち","りんぎょう","しんりん","こばやし"],"true":"とち","times":10},
    {"lesson":7,"level":4,"question":"森林","represent":["森","林"],"answer":["ようふく","ちほう","わふう","しんりん"],"true":"しんりん","times":10},
    {"lesson":7,"level":4,"question":"林","represent":["林"],"answer":["うみ","はやし","もり","いけ"],"true":"はやし","times":10},
    {"lesson":7,"level":4,"question":"台風","represent":["台","風"],"answer":["わふう","いちだい","たいふう","かいすい"],"true":"たいふう","times":10},
    {"lesson":7,"level":4,"question":"風","represent":["風"],"answer":["かぜ","いけ","もり","ひかり"],"true":"かぜ","times":10},
    {"lesson":7,"level":4,"question":"洋食","represent":["洋","食"],"answer":["かいがい","ようしょく","だいち","とうよう"],"true":"ようしょく","times":10},
    {"lesson":7,"level":4,"question":"小林","represent":["小","林"],"answer":["こばやし","ようふく","わふう","かいがい"],"true":"こばやし","times":10},
    {"lesson":7,"level":4,"question":"東洋","represent":["東","洋"],"answer":["わふう","でんち","とち","とうよう"],"true":"とうよう","times":10},
    {"lesson":7,"level":4,"question":"森","represent":["森"],"answer":["かぜ","もり","ゆき","うみ"],"true":"もり","times":10},
    {"lesson":7,"level":4,"question":"台地","represent":["台","地"],"answer":["だいち","かいがい","ようふく","しんりん"],"true":"だいち","times":10},
    {"lesson":7,"level":4,"question":"海水","represent":["海","水"],"answer":["とうよう","かいすい","ちほう","もりた"],"true":"かいすい","times":10},
    {"lesson":7,"level":4,"question":"西洋","represent":["西","洋"],"answer":["たいふう","せいよう","りんぎょう","しんりん"],"true":"せいよう","times":10},
    {"lesson":7,"level":4,"question":"光","represent":["光"],"answer":["かぜ","はやし","ゆき","ひかり"],"true":"ひかり","times":10},
    {"lesson":7,"level":4,"question":"観光","represent":["光"],"answer":["ちず","わふう","かんこう","とうよう"],"true":"かんこう","times":10},
    {"lesson":7,"level":4,"question":"雪国","represent":["雪","国"],"answer":["ゆきぐに","しんりん","わふう","りんぎょう"],"true":"ゆきぐに","times":10},
    {"lesson":7,"level":4,"question":"電池","represent":["電","池"],"answer":["とうよう","でんち","ようしょく","りんぎょう"],"true":"でんち","times":10},
    {"lesson":7,"level":4,"question":"海外","represent":["海","外"],"answer":["かいがい","にっこう","わふう","ちほう"],"true":"かいがい","times":10},
    {"lesson":7,"level":4,"question":"池","represent":["池"],"answer":["はやし","いけ","たい","かぜ"],"true":"いけ","times":10},
    {"lesson":7,"level":4,"question":"日光","represent":["日","光"],"answer":["きたかぜ","ちほう","かいすい","にっこう"],"true":"にっこう","times":10},
    {"lesson":7,"level":4,"question":"雪","represent":["雪"],"answer":["ゆき","ひかり","もり","たい"],"true":"ゆき","times":10},
    {"lesson":7,"level":4,"question":"地下","represent":["地","下"],"answer":["ちか","ゆきぐに","いちだい","しんりん"],"true":"ちか","times":10},
    {"lesson":7,"level":4,"question":"森田","represent":["森","田"],"answer":["ようふく","いちだい","もりた","しんりん"],"true":"もりた","times":10},
    {"lesson":7,"level":4,"question":"海","represent":["海"],"answer":["たい","ひかり","ゆき","うみ"],"true":"うみ","times":10},
    {"lesson":7,"level":4,"question":"北風","represent":["北","風"],"answer":["しんりん","わふう","きたかぜ","こばやし"],"true":"きたかぜ","times":10},
    {"lesson":7,"level":4,"question":"地方","represent":["地","方"],"answer":["ゆきぐに","たいふう","でんち","ちほう"],"true":"ちほう","times":10},
    {"lesson":7,"level":4,"question":"洋服","represent":["洋","服"],"answer":["だいち","ようふく","きたかぜ","しんりん"],"true":"ようふく","times":10},
    {"lesson":7,"level":4,"question":"地図","represent":["地","図"],"answer":["わふう","ちか","ちず","きたかぜ"],"true":"ちず","times":10},
    {"lesson":7,"level":4,"question":"一台","represent":["台"],"answer":["せいよう","いちだい","もりた","かいがい"],"true":"いちだい","times":10},
    {"lesson":7,"level":4,"question":"和風","represent":["風"],"answer":["ちか","わふう","いちだい","かんこう"],"true":"わふう","times":10},
    {"lesson":7,"level":4,"question":"林業","represent":["林","業"],"answer":["とうよう","かんこう","りんぎょう","ようふく"],"true":"りんぎょう","times":10},
    {"lesson":7,"level":4,"question":"台","represent":["台"],"answer":["はやし","ゆき","たい","うみ"],"true":"たい","times":10},
]