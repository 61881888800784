
export const lesson3 = [
    {"lesson":3,"level":4,"question":"有利な","represent":["有","利"],"answer":["ゆうりな","げんきな","ふうんな","ゆうめいな"],"true":"ゆうりな","times":10},
    {"lesson":3,"level":4,"question":"急行","represent":["急","行"],"answer":["きゅうこう","とっきゅう","とくしょく","きっぷ"],"true":"きゅうこう","times":10},
    {"lesson":3,"level":4,"question":"有名な","represent":["有","名"],"answer":["ゆうめいな","べんりな","ゆうりょくな","ゆうりな"],"true":"ゆうめいな","times":10},
    {"lesson":3,"level":4,"question":"急用","represent":["急","用"],"answer":["りし","がんじつ","きゅうよう","べんじょ"],"true":"きゅうよう","times":10},
    {"lesson":3,"level":4,"question":"切符","represent":["切"],"answer":["きっぷ","きって","べんじょ","りし"],"true":"きっぷ","times":10},
    {"lesson":3,"level":4,"question":"有力な","represent":["有","力"],"answer":["しんせつな","げんきな","とくべつな","ゆうりょくな"],"true":"ゆうりょくな","times":10},
    {"lesson":3,"level":4,"question":"特急","represent":["特","急"],"answer":["きって","とっきゅう","りし","くべつ"],"true":"とっきゅう","times":10},
    {"lesson":3,"level":4,"question":"利子","represent":["利","子"],"answer":["とっきゅう","りし","がんじつ","とくしょく"],"true":"りし","times":10},
    {"lesson":3,"level":4,"question":"親切な","represent":["親","切"],"answer":["ゆうりな","べんりな","ゆうめいな","しんせつな"],"true":"しんせつな","times":10},
    {"lesson":3,"level":4,"question":"元日","represent":["元","日"],"answer":["とくしょく","べんりな","きっぷ","がんじつ"],"true":"がんじつ","times":10},
    {"lesson":3,"level":4,"question":"区別","represent":["区","別"],"answer":["きゅうこう","くべつ","きって","りし"],"true":"くべつ","times":10},
    {"lesson":3,"level":4,"question":"元気な","represent":["元","気"],"answer":["ふべんな","ゆうりょくな","ふうんな","げんきな"],"true":"げんきな","times":10},
    {"lesson":3,"level":4,"question":"不運な","represent":["不","運"],"answer":["ふうんな","ゆうりな","とくべつな","ゆうりょくな"],"true":"ふうんな","times":10},
    {"lesson":3,"level":4,"question":"特色","represent":["特","色"],"answer":["がんじつ","きって","とくしょく","べんじょ"],"true":"とくしょく","times":10},
    {"lesson":3,"level":4,"question":"便所","represent":["所","便"],"answer":["べんじょ","きゅうよう","がんじつ","くべつ"],"true":"べんじょ","times":10},
    {"lesson":3,"level":4,"question":"不便な","represent":["不","便"],"answer":["ふうんな","ふべんな","しんせつな","ゆうりな"],"true":"ふべんな","times":10},
    {"lesson":3,"level":4,"question":"切手","represent":["切","手"],"answer":["がんじつ","りし","きって","くべつ"],"true":"きって","times":10},
    {"lesson":3,"level":4,"question":"特別な","represent":["特","別"],"answer":["べんりな","ふべんな","ゆうりな","とくべつな"],"true":"とくべつな","times":10},
    {"lesson":3,"level":4,"question":"便利な","represent":["便","利"],"answer":["ふべんな","ゆうりょくな","べんりな","ゆうめいな"],"true":"べんりな","times":10},
    {"lesson":3,"level":4,"question":"航空便","represent":["空","便"],"answer":["ふなびん","こうくびん","そくたつびん","こうくうびん"],"true":"こうくうびん","times":10},
]