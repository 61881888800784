import Score from "@assets/icon/Champion.svg";
import Home from "@assets/icon/home-icon 1.svg";
import Setting from "@assets/icon/setting 1.svg";
import Test from "@assets/icon/test.svg";
import Helper from "@helper";
import { useAppSelector as selector } from '@store/hooks';

import Routes, { HomeRoutes, TestRoutes, SettingMobileRoutes } from "@routes/Routes";
import { memo } from "react";
import { IconType } from "react-icons/lib";

const MobileTabComponent = ({ useNavigate, currentRoute }: any) => {
    const { routeHistory } = selector(({ service }) => service);

    const navItems = [
        {
            icon: Test,
            label: 'Test',
            path: Routes.TEST,
            children: TestRoutes
        },
        {
            icon: Home,
            label: 'Home',
            path: Routes.HOME,
            children: HomeRoutes
        }, 
        {
            icon: Setting,
            label: 'Setting',
            path: Routes.SETTING,
            children: SettingMobileRoutes
        },
    ]
    const navigate = useNavigate(); 
    interface navItemsPops {
        icon: IconType| any,
        label: string, 
        path: string,
        children: Array<string>
    }
    interface RedirectProps{
        path: String;
        active: boolean;
        label: String;
    }
    const redirect = ({ path, active,label }: RedirectProps) => {
        if (active) {            
            navigate(path);
        } else {
            const {home,test,setting} = routeHistory;
            if (label == 'Home') {
                navigate(home.route, { state: home.state });
            } else if (label == 'Test') {
                navigate(test.route, { state: test.state });
            } else if (label == 'Setting') {
                navigate(setting.route, { state: setting.state });
            } else {
                navigate(path);
            }
        }
    }
    const renderItem = ({ key, item, active }: any) => {     
        return (
            <div key={key}
                onClick={currentRoute === item.path ? () => { } : () => { redirect({ path:item.path, active,label:item.label})}}
                className= "flex-1 flex flex-col text-[16px] justify-center items-center btn" >
                <img src={item.icon} className={`${item.label == "Test"&&"ml-[9px]"} w-[30px] h-[30px]`}/>
                <div className={`${active ? ' text-yellow' : 'text-[#8D8D8D]'} font-[700px] text-[14px]`}>
                    {item.label}
                </div>
            </div>
        )
    }
    const tabBarStyle =  {
        box:"flex flex-row md:hidden justify-center items-center z-menu fixed -bottom-1 ",
        layout:" h-[64px] w-full",
        design: " drop-shadow-m-tab rounded-t-[20px] shadow-[2xl] text-center light-shadow dark-shadow",
        flexible:" bg-white dark:bg-darkFooter dark:text-[#8D8D8D]"
    }
    return (
        <div className={Helper.pushStyle(tabBarStyle)}>
            {
                navItems.map((item: navItemsPops, key: number) => {
                    const active = item.children.includes(currentRoute);
                    return renderItem({key,item,active})
                })
            }
        </div>
    )
}
export default memo(MobileTabComponent);