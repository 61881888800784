import SizeBox from "@components/SizeBox";
import { Minna } from "@consts";
import { memo } from "react";
import KanjiInfoMobileComponent from "./KanjiInfoMobileComponent";
import Number from "@components/common/Number";
import KanjiCompoundMobileCompenent from "./KanjiCompoundMobileCompenent";
import KanjiExampleMobileComponent from "./KanjiExampleMobileComponent";

const KanjiDetailMobileComponent = ({ lesson, dark, toggleFuri, lang, furigana, kanjiId, fontSize, furiTranslate,kanjiDetail}: any) => {
    const { id, level, kanji, mean, url, strokes, kun, on, compound, example } = Minna.kanji.information[kanjiId];

    return (
        <div className="relative md:hidden">
            <KanjiInfoMobileComponent
                id={id + 1}
                url={url}
                strokes={strokes}
                lesson={lesson}
                kun={kun}
                on={on}
                lang={lang}
                mean={mean} />
            {/* Compound */}
            <KanjiCompoundMobileCompenent
                compound={compound}
                fontSize={fontSize}
                furigana={furigana}
                furiTranslate={furiTranslate}
                kanjiDetail={kanjiDetail}
                lang={lang}
            />
        <SizeBox vertical="h-6" />
        {/* Example */}
        <KanjiExampleMobileComponent
            fontSize={fontSize}
            furigana={furigana}
            example={example}
            furiTranslate={furiTranslate}
            kanjiDetail={kanjiDetail}
            lang={lang} />            
        <SizeBox vertical="h-[120px]" />
    </div>
  )
}
export default memo(KanjiDetailMobileComponent);