export const reibun= [
    {
        sentence: [
            {
                furi: "この　｜＾本（ほん）｜は　｜＾読（よ）｜まれましたか。",
                normal: "この　本は　読まれましたか。",
                en: "Have you read this book?",
                mm: "ဒီစာအုပ်ဖတ်ပြီးပြီလား?",
            },
            {
                furi: "ええ、もう　｜＾読（よ）｜みました。",
                normal: "ええ、もう　読みました。",
                en: "Yes, I read it already.",
                mm: "ဟုတ်၊ ဖတ်ပြီးပြီ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson49/bunpou/4N49RB1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾部（ぶ）長（ちょう）｜は　どちらですか。",
                normal: "部長は　どちらですか。",
                en: "Where is the manager?",
                mm: "မန်‌နေဂျာက ဘယ်မှာပါလဲ။",
            },
            {
                furi: "さっき　お｜＾出（で）｜かけに　なりました。",
                normal: "さっき　お出かけに　なりました。",
                en: "He has just left.",
                mm: "ခုနလေးတင် အပြင်ထွက်သွားတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson49/bunpou/4N49RB2.mp3")
    },
    {
        sentence: [
            {
                furi: "よく　｜＾映（えい）画（が）｜を　ご｜＾覧（らん）｜に　なりますか。",
                normal: "よく　映画を　ご覧に　なりますか。",
                en: "Do you often watch movies?",
                mm: "ရုပ်ရှင်တွေ မကြာခဏကြည့်တတ်လား။",
            },
            {
                furi: "そうですね。たまに　｜＾妻（つま）｜と　｜＾見（み）｜に　｜＾行（い）｜きます。",
                normal: "そうですね。たまに　妻と　見に　行きます。",
                en: "Yes. Sometimes I go to watch with my wife.",
                mm: "ဟုတ်၊ တစ်ခါတလေ ကျွန်တော့်မိန်းမနဲ့အတူ သွားကြည့်တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson49/bunpou/4N49RB3.mp3")
    },
    {
        sentence: [
            {
                furi: "＾小（お）川（がわ）｜さんの　｜＾息（むす）子（こ）｜さんが　さくら｜＾大（だい）学（がく）｜に　｜＾合（ごう）格（かく）｜したのを　ご｜＾存（ぞん）｜じですか。",
                normal: "小川さんの　息子さんが　さくら大学に　合格したのを　ご存じですか。",
                en: "Did you know that Mr. Ogawa's son got into Sakura University?",
                mm: "Mr. Ogawa ရဲ့သား ဆာကူရာတက္ကသိုလ် ဝင်ခွင့်ရသွားတာ သိပါသလား။",
            },
            {
                furi: "いいえ、｜＾知（し）｜りませんでした。",
                normal: "いいえ、知りませんでした。",
                en: "No I didn't know.",
                mm: "ဟင့်အင်း၊ မသိခဲ့ပါဘူး။",
            },
        ],
        audioUrl: require("@assets/audio/lesson49/bunpou/4N49RB4.mp3")
    },
    {
        sentence: [
            {
                furi: "お｜＾名（な）前（まえ）｜は　｜＾何（なん）｜と　おっしゃいますか。",
                normal: "お名前は　何と　おっしゃいますか。",
                en: "What is your name?",
                mm: "နာမည်ဘယ်လိုခေါ်ပါသလဲ?",
            },
            {
                furi: "ワットと　いいます。",
                normal: "ワットと　いいます。",
                en: "It's called Watt.",
                mm: "ဝပ်လို့ ခေါ်တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson49/bunpou/4N49RB5.mp3")
    },
    {
        sentence: [
            {
                furi: "お｜＾仕（し）事（ごと）｜は　｜＾何（なに）｜を　なさって　いますか。",
                normal: "お仕事は　何を　なさって　いますか。",
                en: "What do you do for work?",
                mm: "အလုပ်က ဘာလုပ်တာပါလဲ။",
            },
            {
                furi: "＾銀（ぎん）行（こう）員（いん）｜です。アップル｜＾銀（ぎん）行（こう）｜に　｜＾務（つと）｜めて　います。",
                normal: "銀行員です。アップル銀行に　務めて　います。",
                en: "I'm a banker. He works for Apple Bank.",
                mm: "ကျွန်တော်က ဘဏ်ဝန်ထမ်းပါ။ Apple Bank မှာ အလုပ်လုပ်ပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson49/bunpou/4N49RB6.mp3")
    },
    {
        sentence: [
            {
                furi: "＾松（まつ）本（もと）部（ぶ）長（ちょう）｜は　いらっしゃいますか。",
                normal: "松本部長は　いらっしゃいますか。",
                en: "Is Director Matsumoto here?",
                mm: "ဒါရိုက်တာ Matsumoto ရှိပါသလား။",
            },
            {
                furi: "ええ、こちらの　お｜＾部（ぶ）屋（ちょう）｜です。どうぞ　お｜＾入（はい）｜り　ください。",
                normal: "ええ、こちらの　お部屋です。どうぞ　お入り　ください。",
                en: "Yes, it's this room. Please come in.",
                mm: "ဟုတ်ကဲ့ ဒီအခန်းမှာပါ။ ကျေးဇူးပြု၍ ကြွပါခင်ဗျာ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson49/bunpou/4N49RB7.mp3")
    },
];