import SizeBox from "@components/SizeBox";
import AmidioComponent from "@components/common/Amidio/AmidioComponent";
import SentenceComponent from "@components/common/SentenceComponent";
import TitleComponent from "@components/common/TitleComponent";
import { memo } from "react";
const RebunComponent = ({ scoreIncrementAction,reibun, furigana, lang, fontSize, furiTranslate, lesson, kanjiDetail }: any) => {
    return <div className="relative">
        <TitleComponent 
            key={Math.random()}
            furi="＾例（れい）｜＾文（ぶん）"
            normal="例 文"
            furigana={furigana} 
            furiTranslate={furiTranslate} 
            fontSize={fontSize} 
            lesson={lesson+26}  
        /> 
        <SizeBox vertical="h-16" />
        { reibun.map(({ sentence, audioUrl }: any, no: number) => {
            return (<div key={no} >
                <div className="flex flex-row px-3 md:pl-8">
                    <div>
                        {
                            sentence.map(({ furi, normal, en, mm }: any, index: number) => {
                                return (
                                    <div key={Math.random()}>
                                        <SentenceComponent
                                            no={no + 1}
                                            index={index}
                                            furigana={furigana}
                                            furi={furi}
                                            normal={normal}
                                            en={en}
                                            mm={mm}
                                            lang={lang}
                                            fontSize={fontSize}
                                            kanjiDetail={kanjiDetail}
                                            scoreIncrementAction={scoreIncrementAction}
                                        />
                                   </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="pl-8">
                    <AmidioComponent audioUrl={audioUrl} scoreIncrementAction={scoreIncrementAction}/>
                </div>
                <SizeBox vertical="h-5" />
            </div>
            )
            })
        }   
    </div>
       
        
}
export default memo(RebunComponent);