import SizeBox from "@components/SizeBox";
import { Card } from "@material-tailwind/react";
import { memo } from "react";

const GameDisplayCardComponent = ({ game, count, multiple, onPick, pickAns, width }: any) => {
    return (<>
        {/* mobile */}
        <div className="container mx-auto md:hidden">
            <SizeBox vertical="h-10" />
            <div className="container mx-auto grid grid-cols-2 gap-7">
                {
                    multiple.map(({ answer, selected, correct }: any, index: number) => {
                        return <Card
                            onClick={onPick ? () => { } : () => pickAns(answer)}
                            key={index}
                            className={`flex flex-col px-[20px] py-[5px] border border-navbar text-navbar rounded-[100px] justify-center card-light-shadow dark:bg-[#001E34] bg-white text-md text-center btn 
                            ${correct ? 'bg-[#00E392] dark:bg-[#00E392] text-white dark:text-white' : ''} ${selected && !correct ? 'bg-[#FF516E] dark:bg-[#FF516E] text-white dark:text-white' : ''}`}>
                            {answer}
                        </Card>
                    })
                }
            </div>
            <SizeBox vertical="h-10" />
            <div className="container mx-auto flex flex-row justify-center  text-white">
                <div className="bg-yellow px-7 rounded-[10px]">{count + 1} of {game.length}</div>
            </div>
        </div>
        {/* web */}
        <div className="container mx-auto hidden md:block ">
            <SizeBox vertical="h-10"/>
            <div className="container mx-auto grid grid-cols-2 gap-7 w-[60%]">
                {
                    multiple.map(({ answer, selected, correct }: any, index: number) => {
                        return <Card
                            onClick={onPick ? () => { } : () => pickAns(answer)}
                            key={index}
                            className={`flex flex-col px-[20px] py-[5px] border border-navbar text-navbar rounded-[100px] justify-center card-light-shadow dark:bg-[#001E34] bg-white text-md text-center btn 
                            ${correct ? 'bg-[#00E392] dark:bg-[#00E392] text-white dark:text-white' : ''} ${selected && !correct ? 'bg-[#FF516E] dark:bg-[#FF516E] text-white dark:text-white' : ''}`}>
                            {answer}
                        </Card>
                    })
                }
            </div>
            <SizeBox vertical="h-10" />
            <div className="container mx-auto flex flex-row justify-center  text-white">
                <div className="bg-yellow px-7 rounded-[10px]">{count + 1} of {game.length}</div>
            </div>
        </div>
    </>
               
         
    )
}
export default memo(GameDisplayCardComponent);