import { memo } from "react";
const Renshuu_C_Screen = ({ selector, name, Logger, dispatch, setName, Routes, useNavigate,useLocation}: any) => {
    const log = Logger(12);
    const location = useLocation();
    log.s(" Renshuu C Screen Console");
    return (<>
        <div className="flex flex-col items-center justify-center h-full ">
            Renshuu C Screen
            <div>Name: {name}</div>
            id:{location.state.id}
            Lesson: {location.state.lesson}
        </div>
    </>)
}
export default memo(Renshuu_C_Screen);