export const lesson45 = [
  {
      "furi": "＾信（しん）｜じます",
      "normal": "信じます",
      "en": "believe, trust",
      "remark_en": "",
      "mm": "ယုံကြည့်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO1.mp3")
  },
  {
      "furi": "キャンセルします",
      "normal": "キャンセルします",
      "en": "cancel",
      "remark_en": "",
      "mm": "ပယ်ဖျက်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO2.mp3")
  },
  {
      "furi": "＾知（し）｜らせます",
      "normal": "知らせます",
      "en": "I will inform you",
      "remark_en": "",
      "mm": "အသိပေးသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO3.mp3")
  },
  {
      "furi": "＾保（ほ）証（しょう）書（しょ）",
      "normal": "保証書",
      "en": "guarantee",
      "remark_en": "",
      "mm": "သက်သေခံအထောက်အထား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO4.mp3")
  },
  {
      "furi": "＾領（りょう）収（しゅう）書（しょ）",
      "normal": "領収書",
      "en": "receipt",
      "remark_en": "",
      "mm": "ငွေလက်ခံဖြတ်ပိုင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO5.mp3")
  },
  {
      "furi": "キャンプ",
      "normal": "キャンプ",
      "en": "camp",
      "remark_en": "",
      "mm": "ယာယီစခန်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO6.mp3")
  },
  {
      "furi": "＾中（ちゅう）止（し）",
      "normal": "中止",
      "en": "calling off, cancelling",
      "remark_en": "",
      "mm": "ရပ်ဆိုင်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO7.mp3")
  },
  {
      "furi": "＾点（てん）",
      "normal": "点",
      "en": "point, score",
      "remark_en": "",
      "mm": "အမှတ်၊ရမှတ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO8.mp3")
  },
  {
      "furi": "＾梅（うめ）",
      "normal": "梅",
      "en": "plum(tree)",
      "remark_en": "",
      "mm": "တရုတ်ဆီးသီး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO9.mp3")
  },
  {
      "furi": "１１０｜＾番（ばん）",
      "normal": "１１０番",
      "en": "Emergency number",
      "remark_en": "",
      "mm": "ရဲစခန်းဖုန်းနံပတ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO10.mp3")
  },
  {
      "furi": "１１９｜＾番（ばん）",
      "normal": "１１９番",
      "en": "Emergency number",
      "remark_en": "",
      "mm": "မီးသတ်ဖုန်းနံပတ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO11.mp3")
  },
  {
      "furi": "＾急（きゅう）｜に",
      "normal": "急に",
      "en": "suddenly",
      "remark_en": "",
      "mm": "ရုတ်တရက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO12.mp3")
  },
  {
      "furi": "＾無（む）理（り）｜に",
      "normal": "無理に",
      "en": "Forcibly",
      "remark_en": "",
      "mm": "လွန်ကဲစွာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO13.mp3")
  },
  {
      "furi": "＾楽（たの）｜しみにしています",
      "normal": "楽しみにしています",
      "en": "be looking forward to, be expecting",
      "remark_en": "",
      "mm": "ပျော်ရွှင်စွာနဲ့စောင့်မျှော်နေပါတယ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO14.mp3")
  },
  {
      "furi": "＾以（い）上（じょう）｜です。",
      "normal": "以上です。",
      "en": "That’s all",
      "remark_en": "",
      "mm": "ဒါပါပဲ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO15.mp3")
  },
  {
      "furi": "＾係（かかり）員（いん）",
      "normal": "係員",
      "en": "person in charge, attendant",
      "remark_en": "",
      "mm": "တာဝန်ရှိသူ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO16.mp3")
  },
  {
      "furi": "コース",
      "normal": "コース",
      "en": "course",
      "remark_en": "",
      "mm": "လမ်းကြောင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO17.mp3")
  },
  {
      "furi": "スタート",
      "normal": "スタート",
      "en": "start",
      "remark_en": "",
      "mm": "စတင်ခြင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO18.mp3")
  },
  {
      "furi": "～｜＾位（い）",
      "normal": "～位",
      "en": "~th (ranking)",
      "remark_en": "",
      "mm": "-အဆင့်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO19.mp3")
  },
  {
      "furi": "＾優（ゆう）勝（しょう）｜します",
      "normal": "優勝します",
      "en": "win the championship",
      "remark_en": "",
      "mm": "အနိုင်ရသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson45/vocab/4N45VO20.mp3")
  },
  {
      "furi": "＾悩（なや）｜み",
      "normal": "悩み",
      "en": "trouble, worry",
      "remark_en": "",
      "mm": "ပူပန်စရာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾目（め）覚（ざ）｜まし[｜＾時（ど）｜＾計（けい）｜]",
      "normal": "目覚まし[時計]",
      "en": "alarm clock",
      "remark_en": "",
      "mm": "နှိုးစက်(နာရီ)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾目（め）｜が｜＾覚（さ）｜めます]",
      "normal": "目が覚めます]",
      "en": "wake up",
      "remark_en": "",
      "mm": "နှိုးသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾大（だい）学（がく）生（せい）",
      "normal": "大学生",
      "en": "university student",
      "remark_en": "",
      "mm": "တက္ကသိုလ်ကျောင်းသား/သူ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾回（かい）答（とう）",
      "normal": "回答",
      "en": "answer, reply",
      "remark_en": "",
      "mm": "အဖြေ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾鳴（な）｜ります",
      "normal": "鳴ります",
      "en": "ring(phone)",
      "remark_en": "",
      "mm": "မြည်သည်(အသံ)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "セットします",
      "normal": "セットします",
      "en": "set",
      "remark_en": "",
      "mm": "ချိန်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "それでも",
      "normal": "それでも",
      "en": "for all that",
      "remark_en": "",
      "mm": "အဲဒါတောင်မှ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  }
]

/*
    {
        furi: "",
        normal: "",
        en: "",
        remark_en: "",
        mm: "",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson29/vocab/4N29VO1.mp3")
    },


*/
const test = [
  {
    'goi': '信（しん）」じます',
    'unicode': 'ယုံကြည့်သည်',
    'zawgyi': 'ယုံၾကည့္သည္',
    'english': 'believe, trust',
    'long': false
  },
  {
    'goi': 'キャンセルします',
    'unicode': 'ပယ်ဖျက်သည်',
    'zawgyi': 'ပယ္ဖ်က္သည္',
    'english': 'cancel',
    'long': false
  },
  {
    'goi': '知（し）」らせます',
    'unicode': 'အသိပေးသည်',
    'zawgyi': 'အသိေပးသည္',
    'english': 'I will inform you',
    'long': false
  },
  {
    'goi': '保（ほ）」証（しょう）」書（しょ）',
    'unicode': 'သက်သေခံအထောက်အထား',
    'zawgyi': 'သက္ေသခံအေထာက္အထား',
    'english': 'guarantee',
    'long': false
  },
  {
    'goi': '領（りょう）」収（しゅう）」書（しょ）',
    'unicode': 'ငွေလက်ခံဖြတ်ပိုင်း',
    'zawgyi': 'ေငြလက္ခံျဖတ္ပိုင္း',
    'english': 'receipt',
    'long': false
  },
  {
    'goi': 'キャンプ',
    'unicode': 'ယာယီစခန်း',
    'zawgyi': 'ယာယီစခန္း',
    'english': 'camp',
    'long': false
  },
  {
    'goi': '中（ちゅう）」止（し）',
    'unicode': 'ရပ်ဆိုင်းသည်',
    'zawgyi': 'ရပ္ဆိုင္းသည္',
    'english': 'calling off, cancelling',
    'long': false
  },
  {
    'goi': '点（てん）',
    'unicode': 'အမှတ်၊ရမှတ်',
    'zawgyi': 'အမွတ္၊ရမွတ္',
    'english': 'point, score',
    'long': false
  },
  {
    'goi': '梅（うめ）',
    'unicode': 'တရုတ်ဆီးသီး',
    'zawgyi': 'တ႐ုတ္ဆီး',
    'english': 'plum(tree)',
    'long': false
  },
  {
    'goi': '１１０」番（ばん）',
    'unicode': 'ရဲစခန်းဖုန်းနံပတ်',
    'zawgyi': 'ရဲစခန္းဖုန္းနံပတ္',
    'english': 'Emergency number',
    'long': false
  },
  {
    'goi': '１１９」番（ばん）',
    'unicode': 'မီးသတ်ဖုန်းနံပတ်',
    'zawgyi': 'မီးသတ္ဖုန္းနံပတ္',
    'english': 'Emergency number',
    'long': false
  },
  {
    'goi': '急（きゅう）」に',
    'unicode': 'ရုတ်တရက်',
    'zawgyi': '႐ုတ္တရက္',
    'english': 'suddenly',
    'long': false
  },
  {
    'goi': '無（む）」理（り）」に',
    'unicode': 'လွန်ကဲစွာ',
    'zawgyi': 'လြန္ကဲစြာ',
    'english': 'Forcibly',
    'long': false
  },
  {
    'goi': '楽（たの）」しみにしています',
    'unicode': 'ပျော်ရွှင်စွာနဲ့စောင့်မျှော်နေပါတယ်',
    'zawgyi': 'ေပ်ာ္ရႊင္စြာနဲ႔ေစာင့္ေမၽွာ္ေနပါတယ္',
    'english': 'be looking forward to, be expecting',
    'long': true
  },
  {
    'goi': '以（い）」上（じょう）」です。',
    'unicode': 'ဒါပါပဲ',
    'zawgyi': 'ဒါပါပဲ',
    'english': 'That’s all',
    'long': false
  },
  {
    'goi': '係（かかり）」員（いん）',
    'unicode': 'တာဝန်ရှိသူ',
    'zawgyi': 'တာဝန္ရွိသူ',
    'english': 'person in charge, attendant',
    'long': false
  },
  {
    'goi': 'コース',
    'unicode': 'လမ်းကြောင်း',
    'zawgyi': 'လမ္းေၾကာင္း',
    'english': 'course',
    'long': false
  },
  {
    'goi': 'スタート',
    'unicode': 'စတင်ခြင်း',
    'zawgyi': 'စတင္ျခင္း',
    'english': 'start',
    'long': false
  },
  {
    'goi': '～」位（い）',
    'unicode': '-အဆင့်',
    'zawgyi': '-အဆင့္',
    'english': '~th (ranking)',
    'long': false
  },
  {
    'goi': '優（ゆう）」勝（しょう）」します',
    'unicode': 'အနိုင်ရသည်',
    'zawgyi': 'အနိုင္ရသည္',
    'english': 'win the championship',
    'long': false
  },
  {
    'goi': '悩（なや）」み',
    'unicode': 'ပူပန်စရာ',
    'zawgyi': 'ပူပန္စရာ',
    'english': 'trouble, worry',
    'long': false
  },
  {
    'goi': '目（め）」覚（ざ）」まし[」時（ど）」計（けい）」]',
    'unicode': 'နှိုးစက်(နာရီ)',
    'zawgyi': 'ႏွိုးစက္(နာရီ)',
    'english': 'alarm clock',
    'long': false
  },
  {
    'goi': '目（め）」が」覚（さ）」めます]',
    'unicode': 'နှိုးသည်',
    'zawgyi': 'ႏွိုးသည္',
    'english': 'wake up',
    'long': false
  },
  {
    'goi': '大（だい）」学（がく）」生（せい）',
    'unicode': 'တက္ကသိုလ်ကျောင်းသား/သူ',
    'zawgyi': 'တကၠသိုလ္ေက်ာင္းသား/သူ',
    'english': 'university student',
    'long': false
  },
  {
    'goi': '回（かい）」答（とう）',
    'unicode': 'အဖြေ',
    'zawgyi': 'အေျဖ',
    'english': 'answer, reply',
    'long': false
  },
  {
    'goi': '鳴（な）」ります',
    'unicode': 'မြည်သည်(အသံ)',
    'zawgyi': 'ျမည္သည္(အသံ)',
    'english': 'ring(phone)',
    'long': false
  },
  {
    'goi': 'セットします',
    'unicode': 'ချိန်သည်',
    'zawgyi': 'ခ်ိန္သည္',
    'english': 'set',
    'long': false
  },
  {
    'goi': 'それでも',
    'unicode': 'အဲဒါတောင်မှ',
    'zawgyi': 'အဲဒါေတာင္မွ',
    'english': 'for all that',
    'long': false
  }
]