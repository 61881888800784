import HomeScreenLayout from "@layouts/home/HomeScreenLayout";
// import Tree from "@assets/icon/about/tree 1.svg";
import Educational1 from "@assets/icon/about/Educational 1.svg";
import Educators from "@assets/icon/about/Educators 1.svg";
import IntegratedSolution from "@assets/icon/about/IntegradedSolution.svg"
// import IntegratedSolution from "@assets/icon/about/Integrated Solution Logo SVG 1.svg";
import Missionary from "@assets/icon/about/Missionary 1.svg";
import NoAds from "@assets/icon/about/OBJECTS.svg";
// import Character from "@assets/icon/about/character.svg";
import Character from "@assets/icon/about/character.png";
import Data from "@assets/icon/about/data-management 1.svg";
import MultiPlatform from "@assets/icon/about/muti-platform 1.svg";
import Noserver from "@assets/icon/about/no-need-server.svg";
import Offline from "@assets/icon/about/offline 1.svg";
import Tree from "@assets/icon/about/plants.svg";
import Children from "@assets/icon/about/under15 1.svg";
import SizeBox from "@components/SizeBox";
import { memo } from "react";
const AboutScreen = ({ showModel,lang, setShowModel,setUnlockPremium, selector, name, Logger, dispatch, setName, Routes, useNavigate, useLocation }: any) => {
    const log = Logger(11);
    const location = useLocation();
    const navigate = useNavigate();
    const en = lang === "en";
    const pros = [
        {
            title: en?"1.　No Server, More Efficiency":"1. Server မရှိသော ထိရောက်မှု",
            description: en?"Unlike conventional applications that drain energy through servers, our app operates sans a central server.This strategic design not only cuts down on energy consumption but also contributes to a cleaner, greener Earth.":
            "Server ပါဝင်သော သမရိုးကျ ဆော့ဝဲများဖြင့်မတူပဲ မိမိတို့တွင် ဗဟိုရ်ထိန်းချုပ်ထားသော Server စနစ်မပါဝင်သောကြောင့် စွမ်းအင်သုံးစွဲမှုကို လျှော့ချရုံသာမက ပိုမိုသန့်ရှင်းပြီး စိမ်းလန်းသောမြေကြီးကိုပါ ပံ့ပိုးပေးပါသည်။",
            image: Noserver
        },
        {
            title: en?"2.　Empowering Offline Functionality ":"2.　Offline စွမ်းဆောင်ချက်များ",
            description: en?"Recognizing the need for connectivity independence, our app offers a static offline mode.Users can seamlessly access vital information and perform tasks without the constraints of a constant internet connection, all while saving energy.":"ကျွန်ုပ်တို့ App သည် ကနဦးလိုအပ်သောအချက်အလက်များ ဒေါင်းလုဒ်ရယူရန်အတွက်သာ အင်တာနက်လိုအပ်ပြီး အင်တာနက်မရှိချိန်တွင် Cache အဖြစ်သိမ်းဆည်းထားသော ဒေတာများမှတစ်ဆင့် ဆက်လက်အသုံးပြုနေနိုင်မည်ဖြစ်ပါသည်။ ထိုစနစ်သည် လူကြီးမင်းတို့၏ အင်တာနက်ဒေတာ စရိတ်ကို သိသာစွာလျှော့ချထားနိုင်မည်ဖြစ်ပါသည်။",
            image: Offline
        },
        {
            title: en?"3.　Smart Data Management ":"3. ကောင်းမွန်သော ဒေတာစီမံခန့်ခွဲမှု",
            description: en?"Our app employs a resourceful reusable data cache system.By optimizing data storage and retrieval, users enjoy reduced mobile data costs, making sustainable usage not only eco- friendly but also economically viable.":"ကျွန်ုပ်တို့၏ App သည် ပြန်သုံးနိုင်သော ဒေတာ cache စနစ်ကို အသုံးပြုထားသည်။ ဒေတာသိုလှောင်မှုနှင့် ထုတ်ယူမှုကို ပိုမိုကောင်းမွန်အောင်ပြုလုပ်ကာ သုံးစွဲသူများ၏ မိုဘိုင်းဒေတာကုန်ကျစရိတ်ကို လျှော့ချပေးခြင်းဖြင့် ရေရှည်သုံးစွဲနိုင်ယုံသာမက ကုန်ကျစရိတ်လျှော့ချနိုင်မည်ဖြစ်ပါသည်။",
            image: Data
        },
        {
            title: en?"4.　Universal Platform Reach":"4. Android, IOS, Tablet, PC စတဲ့ Plateform များတွင် အသုံးပြုနိုင်ခြင်း",
            description: en?"Simplifying development processes, our app employs a single code base that adapts effortlessly to various platforms.From mobile to web to desktop, users experience consistent, high- performing service, resulting in cost - effective development.":"Development လုပ်ငန်းစဉ်များကို ရိုးရှင်းစေပြီး အမျိုးမျိုးသော Device များတွင်လွယ်ကူစွာလိုက်လျောညီထွေဖြစ်စေမည့် Single Code Base အခြေခံကာ ရေးသားထားပါသည်။ Mobile, Web မှ Computer အထိ သုံးစွဲသူများသည် တသမတ်တည်း၊ စွမ်းဆောင်ရည်မြင့်မားသော ဝန်ဆောင်မှုကို ခံစားရပြီး ကုန်ကျစရိတ်သက်သာသော ဖွံ့ဖြိုးတိုးတက်မှုကို ဖြစ်ပေါ်စေပါသည်။",
            image: MultiPlatform
        },
        {
            image: NoAds,
            title: en?"5.　Experience an Ad-Free Feature":"5. အသုံးပြုနေစဉ် ကြော်ငြာများ၏အနှောက်အယှက်မှကင်းလွတ်ခြင်း",
            description: en?"Learn Japanese without interruptions.With our ad- free experience, you can immerse yourself in your studies without distractions.Additionally, by minimizing video content, you'll save on internet data costs. Join us in shaping a better future by purchasing and utilizing the unlock code.":"ကြော်ငြာများ၏ အနှောင့်အယှက်မရှိဘဲ ဂျပန်စာလေ့လာမှုတွင် စိတ်ကိုနှစ်မြှုပ်ထားနိုင်မည်ဖြစ်ပါသည်။ ထို့အပြင် ဗီဒီယိုကြော်ငြာ မထည့်သွင်းခြင်းကြောင့် အင်တာနက်ဒေတာကုန်ကျစရိတ်များကို သက်သာစေမည်ဖြစ်သည်။သင်သည် Unlock Code ကိုဝယ်ယူအသုံးပြုခြင်းဖြင့် ပိုမိုကောင်းမွန်သောအနာဂတ်ကို ပုံဖော်ရန် ကျွန်ုပ်တို့နှင့် ပါဝင်ပူးပေါင်းလိုက်ပါ။"
        }
    ]
    const csrCards = [
        {
            image: Children,
            title: en?"Poor People and Under Age 15":"မရှိနွမ်းပါးသူများနှင့် အသက်(၁၅)နှစ်အောက်လူငယ်များ",
            description: en?"Provide free access to the Minna Master N4 Application for individuals from low- income backgrounds and those under the age of 15."
                        :"Minna Master N4 App ကို ဆင်းရဲနွမ်းပါးသူများနှင့် အသက်(၁၅)နှစ်အောက် လူငယ်များအား အခမဲ့အသုံးပြုခွင့်ပေးမှာဖြစ်ပြီး Admin များထံဆက်သွယ်ကာ Unlock Code ကိုရယူနိုင်ပါသည်။"
        },
        {
            image: Educators,
            title: en?"Volunteer Japanese Free Educators":"အခမဲ့ ပညာဒါနသင်ကြားပေးသူများ",
            description: en?"Grant complimentary access to the application to volunteer educators who offer free Japanese language lessons to underserved communities.Create a dedicated platform within the application to support educators in their teaching efforts."
            :"ပညာဒါန အခမဲ့ဂျပန်ဘာသာစကား သင်ကြားပေးတဲ့ せんせい တွေကို App ကိုအခမဲ့အသုံးပြုခွင့်ပေးသွားမှာဖြစ်ပါသည်။ဤမူဝါဒသည် ဂျပန်စာသင်တန်းတက်ရန် အဆင်မပြေသူများအား တစ်ဖက်တစ်လမ်းမှ ပံ့ပိုးကူညီရန် ဖြစ်ပါသည်။"
        },
        {
            image: Missionary,
            title: en?"Missionary Staff":"သာသနာပြုဝန်ထမ်းများ",
            description: en?"Extend free access to the application to missionary staff working in Japanese- speaking regions, enabling them to enhance their language skills and cultural understanding. Collaborate with missionary organizations to identify specific needs and tailor the application's content accordingly."
            :"ဂျပန်စာ လေ့လာသည့်နယ်ပယ်ရှိ သာသနာပြုဝန်ထမ်းများအား ၄င်းတို့၏ ဘာသာစကား ကျွမ်းကျင်မှုနှင့် စာပေယဉ်ကျေးမှုဆိုင်ရာ နားလည်မှုတိုးတက်စေရန်အတွက် ကျွန်ုပ်တို့ App အားအခမဲ့ အသုံးပြုနိုင်ပါသည်။"
        },
        {
            image: Educational1 ,
            title: en?"Educational Projects and Non- Profit Organizations":"ပညာရေးဆိုင်ရာ အကျိုးအမြတ်မယူသော အဖွဲ့အစည်းများ",
            description: en?"Collaborate with recognized non- profit organizations and educational projects focused on language education or research.Provide access to the application for their beneficiaries or participants contributing to their language learning goals." 
            :"အကျိုးအမြတ်မယူသော ပညာရေးဆိုင်ရာအဖွဲ့အစည်းများနှင့် သုတေသနလုပ်ငန်းများအတွက် ၄င်းတို့၏ ဘာသာစကား သင်ယူမှုရည်မှန်းချက်များကို ပံ့ပိုးပေးသည့်အနေဖြင့် ကျွန်ုပ်တို့၏ App အားအခမဲ့ အသုံးပြုခွင့်ရရှိမှာဖြစ်ပါသည်။"
        },
        
        ]
    return (<>
        <HomeScreenLayout
            body={
                <div className="container">
                    <div className="flex flex-row mt-[30px] justify-center items-center text-[28px] font-bold">
                        {en?"About Us":"ကျွန်ုပ်တို့အကြောင်း"}
                    </div>
                    {/* Intro with Tree */}
                    <div className="flex flex-col md:flex-row items-center">
                        <div className="flex-[3]">
                            <img src={Tree}/>
                        </div>
                        <div className="flex-[7] flex flex-col justify-center ">
                            <div className="mb-5">{en?"Introducing Our Progressive Web Application: Paving the Way to a Greener Future":"ပိုမိုစိမ်းလမ်းသောအနာဂတ်ဆီသို့ ရှေးရှုလျှက်ရှိသော ကျွန်ုပ်တို့၏ Progressive Web App အကြောင်း..."} </div>
                            <div className="text-justify">
                                {en?
                                `In a world where the future of Earth's energy and natural resources is uncertain,
                                our web application is a catalyst for change. Addressing the inefficiencies of
                                energy consumption head-on, our app is engineered to redefine sustainability
                                and accessibility.`
                                :`ကမ္ဘာ့နေရာအနှံအပြားမှာရှိတဲ့ စွမ်းအင်နှင့် သဘာဝအရင်းအမြစ်တွေဟာ အနာဂါတ်အတွက် လုံလောက်ဖို့ မသေချာမရေရာတဲ့အခြေအနေတွေနဲ့ ကြုံတွေ့နေရပါတယ်။
                                မိမိတို့သည် ပြန်လည်အသုံးပြုနိုင်သော အချက်အလက်များကို ထိမ်းသိမ်းစီမံခြင်းဖြင့် တစ်ဖက်တစ်လမ်းမှ စွမ်းအင်သုံးစွဲမှုကို အစွမ်းကုန်လျှော့ခြင်းဖြင့် ပူပေါင်းဆောင်ရွက်လျှက်ရှိပါသည်။
                                `
                                }
                            </div>

                        </div>
                    </div>
                    <SizeBox vertical="h-[20px]"/>
                    <div className="w-full h-[2px] dark:bg-[#424242] bg-[#C8C8C8]"></div>
                    <SizeBox vertical="h-[20px]" />
                    {/* Pros */}
                    {
                        pros.map(({ title, description, image }, i) => {
                            return <div key={Math.random()} className={`flex ${i % 2 == 0 ? "flex-col md:flex-row" :"flex-col md:flex-row-reverse"}  items-center my-[20px] md:my-[15px] `} >
                            <div className="flex-[3] flex flex-row justify-center">
                                <img src={image} />
                            </div>
                            <div className="flex-[7] flex flex-col justify-center ">
                                <div className="mt-3 mb-5 font-semibold">{title}</div>
                                    <div className="text-justify">
                                        {description}
                                    </div>
                            </div>
                        </div>
                        })
                    }
                    {/* Summary of Pros */}
                    <SizeBox vertical="h-[20px]" />
                    <div className="text-justify">
                        {en?`In a world grappling with energy crises and rising costs, our web application emerges as a beacon of innovation.
                        By targeting energy reduction, enabling offline access, optimizing data usage, and embracing unified development,
                        we pave the way for a future that is not only sustainable but also cost-efficient. Join us in reshaping tomorrow, today.`
                        :`စွမ်းအင်အကျပ်အတည်းများနှင့် ကုန်ကျစရိတ်များ မြင့်တက်လာမှုတို့နှင့် ရင်ဆိုင်နေရသော ကမ္ဘာကြီးတွင်၊ ကျွန်ုပ်တို့၏ App သည် ဆန်းသစ်တီထွင်မှု၏ မီးရှူးတန်ဆောင်တစ်ခုအဖြစ် ပေါ်ထွက်လာပါသည်။ စွမ်းအင်လျှော့ချရေးကို ပစ်မှတ်ထားကာ Offline အသုံးပြုနိုင်ပြီး ဒေတာအသုံးပြုမှုကို အနည်းဆုံးဖြစ်အောင် ပြုလုပ်ထားခြင်းဖြင့် ရေရှည်တည်တံ့နိုင်ရုံသာမက ကုန်ကျစရိတ်လည်း သက်သာပါသည်။ ဒါကြောင့် App ကိုအသုံးပြုရင်းနဲ့ အနာဂါတ်ကို အတူတစ်ကွ ပုံဖော်ကြပါစို့ . . .`}
                    </div>
                    <SizeBox vertical="h-[20px]" />
                    <div className="w-full h-[2px] dark:bg-[#424242] bg-[#C8C8C8]"></div>
                    <SizeBox vertical="h-[20px]" />

                    {/* CSR */}
                    <div className="flex flex-col items-center">
                        <img src={Character} className="w-[100px] md:w-[150px] h-[100px] md:h-[150px]"/>
                        <div className="my-[20px] font-semibold">{en?"CSR (Corporate Social Responsibility) Plan for Minna Master N4 Application":"Minna Master App ရဲ့ CSR (Corporate Social Responsibility) အစီအစဉ်"}</div>
                    </div>
                    <div>{en?`The CSR plan aims to provide free access to the Minna Master N4 Application to specific target groups and organizations,
                        contributing to their educational and personal development. This initiative aligns with our commitment to social
                        responsibility and education.`:`CSR အစီအစဉ်သည် Minna Master N4 Application အား ၎င်းတို့၏ ပညာရေးနှင့် ကိုယ်ရေးကိုယ်တာ ဖွံ့ဖြိုးတိုးတက်မှုအတွက် အောက်ပါပုဂ္ဂိုလ်များနှင့် အဖွဲ့အစည်းများအတွက် အခမဲ့အသုံးပြုနိုင်ရန်အတွက် ကူညီထောက်ပံ့ပေးထားပါသည်။ ဤအစီအစဉ်သည် ကျွန်ုပ်တို့၏ လူမှုရေးတာဝန်ယူမှုနှင့် ပညာရေးဆိုင်ရာ ကတိကဝတ်များအတွက် ဖြစ်ပါသည်။`}
                    </div>
                    <SizeBox vertical="h-[40px]" />
                    {/* CSR */}
                    <div key={Math.random()} className="flex flex-col md:flex-row">
                        {
                            csrCards.map(({image,title,description}) => {
                                return <div key={Math.random()} style={{boxShadow:"4px 4px 4px 0px rgb(0,0,0,0.25)"}} className="border-[#424242] flex-1 flex flex-col my-4 md:mr-7 px-3 py-5 rounded-[20px] shadow-card-light-shadow bg-white dark:bg-darkFooter">
                                    <div className=" flex flex-row justify-center w-full h-[130px] ">
                                        <img src={image}/>
                                    </div>
                                        <div className="font-semibold my-[18px] text-center">{title}</div>
                                        <div className="text-justify">{description}</div>
                                    </div>
                            })
                        }
                    </div>
                    <SizeBox vertical="md:h-[40px]" />
                    {/* Intergrated Solution */}
                    <div className="flex flex-row justify-center">
                        <img src={IntegratedSolution} className="w-[200px] h-[200px] rounded-full"/>
                    </div>
                    <SizeBox vertical="h-[20px]" />
                    <div className="text-justify">
                        { en?`We are Integrated Solution Myanmar, a collective of passionate young individuals dedicated to the realm of IT technology
                        and aspiring for a brighter future. The essence of 'integrated' encapsulates our mission to unite minds from diverse fields,
                        including various engineering disciplines, all driven by their fervor for the world of IT. We actively engage in the study and
                        sharing of technologies, recognizing the transformative power they hold. While technology enhances human capabilities,
                        its improper use can result in environmental impact and energy wastage, as well as potential security vulnerabilities.
                        With this awareness, we are committed to a deliberate and cautious stride towards a future that is not only improved but
                        also safer.`
                        :`Integrated Solution Myanmar သည် IT နည်းပညာနယ်ပယ်အား စိတ်အားထက်သန်သော လူငယ်လူရွယ်များဖြင့် ဖွဲ့စည်းထားပါသည်။ "Integrated" ဆိုသည် ဝေါဟာရသည် အင်ဂျင်နီယာပညာရပ်များ အပါအဝင် အခြားသော နည်းပညာနယ်ပယ်များပေါင်းစု၍ ပိုမိုတိုးတက်ပြီး ပတ်ဝန်းကျင်အကျိုးပြုစေရန် ရည်ညွှန်းပါသည်။
                        နည်းပညာသည် လူသားများကို ပိုမိုအကျိုးရှိစေသကဲ့သို့ အသုံးပြုမှုလွဲမှားပါက ပတ်ဝန်းကျင်ထိခိုက်ခြင်း၊ စွမ်းအင်ပြုန်းတီးခြင်း၊ လုံခြုံရေးဆိုင်ရာပြဿနာများဖြစ်ပေါ်နိုင်ခြင်း စသည်တို့ ပေါ်ပေါက်နိုင်ပါသည်။ ဒါ့ကြောင့် ကျွန်ုပ်တို့သည် ‌သတိကြီးစွာဖြင့် ပိုမိုကောင်းမွန်ပြီး လုံခြုံစိတ်ချရတဲ့ အနာဂါတ်ကို ဦးတည်ကြိုးစားသွားပါမည်။`
                        }
                    </div>
                    <SizeBox vertical="h-[20px]" />
                    <div>
                        <div className="font-semibold mb-3">Collaboration With</div>
                        <div className="flex flex-row items-center">
                            <div>UI / UX design By </div>
                            <div> : Async</div>
                        </div>
                        <div className="flex flex-row items-center mb-3">
                            <div>Logo and Vector design By </div>
                            <div> : Avaline</div>
                        </div>
                        <a onClick={()=>navigate(Routes.PRIVACY)} className=" btn underline underline-offset-4 text-[#0084E8]">Privacy Policy</a>
                    </div>
                    <SizeBox vertical="h-[100px]" />
                </div>
            }
            Routes={Routes}
            Logger={Logger}
            useNavigate={useNavigate}
            showModel={showModel}
            setShowModel={setShowModel}
            setUnlockPremium={setUnlockPremium}
        />
    </>)
}
export default memo(AboutScreen);