
export const renshuuA = [
    {
        isTable: true,
        tables: [
            {
                head: {
                    one: {
                        val: "",
                        span: 1,
                        style: ""
                    },
                    two: {
                        val:"んです",
                        span: 1,
                        style: ""
                    }                    
                },
                body: [
                    {
                        one: "いく",
                        two: "んです",
                        
                    },
                    {
                        one: "いかない",
                        two: "んです",
                        
                    },
                    {
                        one: "いった",
                        two: "んです",
                        
                    },
                    {
                        one: "いかなかった",
                        two: "んです",
                        
                    },
                ],
                style: {
                    one: "p-4 text-right bg-[#729eff] dark:bg-[#003257]  ",
                    two: "p-4 text-left w-[100px] bg-white dark:bg-darkFooter",
                }
            },
            {
                head: {
                    one: {
                        val: "",
                        span: 1,
                        style: ""
                    },
                    two: {
                        val: "んです",
                        span: 1,
                        style: ""
                    }
                },
                body: [
                    {
                        three: "＊きれいな",
                        four: "んです"
                    },
                    {
                        three: "きれいじゃない",
                        four: "んです"
                    },
                    {
                        three: "きれいだった",
                        four: "んです"
                    },
                    {
                        three: "きれいじゃなかった",
                        four: "んです"
                    },
                ],
                style: {
                    three: "p-4 text-right bg-[#729eff] dark:bg-[#003257] ",
                    four: "p-4 text-left w-[100px] bg-white dark:bg-darkFooter"
                }
            },
            {
                head: {
                    one: {
                        val: "",
                        span: 1,
                        style: ""
                    },
                    two: {
                        val: "んです",
                        span: 1,
                        style: ""
                    }
                },
                body: [
                    {
                        one: "さむい",
                        two: "んです",
                    },
                    {
                        one: "さむくない",
                        two: "んです",
                    },
                    {
                        one: "さむかった",
                        two: "んです",
                    },
                    {
                        one: "さむくなかった",
                        two: "んです",
                    },
                ],
                style: {
                    one: "p-4 text-right bg-[#729eff] dark:bg-[#003257]",
                    two: "p-4 text-left w-[100px] bg-white dark:bg-darkFooter",
                }
            },
            {
                head: {
                    one: {
                        val: "",
                        span: 1,
                        style: ""
                    },
                    two: {
                        val: "んです",
                        span: 1,
                        style: ""
                    }
                },
                body: [
                    {
                        one: "＊びょうきな",
                        two: "んです",
                    },
                    {
                        one: "びょうきじゃない",
                        two: "んです",
                    },
                    {
                        one: "びょうきだった",
                        two: "んです",
                    },
                    {
                        one: "びょうきじゃなかった",
                        two: "んです",
                    },
                ],
                style: {
                    one: "p-4 text-right bg-[#729eff] dark:bg-[#003257]",
                    two: "p-4 text-left w-[100px] bg-white dark:bg-darkFooter",
                }
            },
        ],
        audioUrl: require("@assets/audio/lesson26/renshua/4N26RSA1.mp3")
    },
    {
        sentence: [
            {
                //｜＾靴（くつ）｜ですね。どこで　｜！
                furi: "チケットが　｜！いるんですか｜。",
                normal: "チケットが　いるんですか。",
                en: "Do you have a ticket?",
                mm: "မင်းမှာ လက်မှတ်ရှိလား။",
            },
            {　
                furi: "＾新（あたら）｜しい　パソコンを　｜！かったんですか｜。",
                normal: "新しい　パソコンを　かったんですか。",
                en: "Did you buy a new PC?",
                mm: "ကွန်ပြူတာအသစ်တစ်လုံးဝယ်ပြီးပြီလား။",
            },
            {
                furi: "だれに　チョコレートを　｜！あげるんですか｜。",
                normal: "だれに　チョコレートを　あげるんですかあげるんですか",
                en: "Who gave you the chocolate?",
                mm: "ချောကလက်ကို ဘယ်သူပေးတာလဲ။",
            },
            {
                furi: "いつ　｜＾日（に）｜＾本（ほん）｜へ　｜！きたんですか｜。",
                normal: "いつ　日本へ　きたんですか。",
                en: "When did you come to Japan?",
                mm: "ဂျပန်ကို ဘယ်တုန်းက ရောက်တာလဲ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson26/renshua/4N26RSA2.mp3")
    },
    {
        sentence: [
            {
                //｜＾靴（くつ）｜ですね。どこで　｜！
                furi: "どうして　｜＾会（かい）｜＾社（しゃ）｜を　｜！やすんだん　ですか｜。",
                normal: "どうして　会社を　やすんだん　ですか。",
                en: "Why did you leave the company?",
                mm: "မင်းဘာကြောင့် ကုမ္ပဏီကထွက်သွားတာလဲ။",
            },
            {
                furi: "。。。。｜＾頭（あたま）｜が　｜！いたかったんです｜。",
                normal: "。。。。頭が　いたかったんです。",
                en: "I had a headache.",
                mm: "ငါခေါင်းကိုက်လို့။",
            },
            {
                furi: "どうして　｜エアコンを　｜！つけないんですか｜。",
                normal: "どうして　エアコンを　つけないんですか。",
                en: "Don't you turn on the air conditioner?",
                mm: "လေအေးပေးစက် မဖွင့်ဘူးလား။",
            },
            {
                furi: "｜！こしょうなんです｜。",
                normal: "こしょうなんです。",
                en: "It is broken",
                mm: "ပျက်နေလို့။",
            },
      
        ],
        audioUrl: require("@assets/audio/lesson26/renshua/4N26RSA3.mp3")
    },
    {
        sentence: [
            {
                furi: "わたしは　｜＾運（うん）｜＾動（どう）｜＾会（かい）｜に　｜＾行（い）｜きません。　｜＾用（よう）｜＾事（じ）｜が　｜！あるんです｜。",
                normal: "わたしは　運動会に　行きません。　用事が　あるんです。",
                en: "I don't go to the sports festival. I have something to do.",
                mm: "အားကစားပွဲတော်ကို မသွားတော့ဘူး။ ငါလုပ်စရာရှိလို့။",
            },
            {
                furi: "わたしは　｜＾運（うん）｜＾動（どう）｜＾会（かい）｜に　｜＾行（い）｜きません。｜＾都（つ）合（ごう）｜が　｜！わるいんです｜。",
                normal: "わたしは　運動会に　行きません。　都合が　わるいんです。",
                en: "I don't go to the sports festival.　It's inconvenient.",
                mm: "အားကစားပွဲတော်ကို မသွားတော့ဘူး။　အခြေအနေမကောင်းလို့။",
            }
        ],
        audioUrl: require("@assets/audio/lesson26/renshua/4N26RSA4.mp3")
    },
    {
        sentence: [
            {
                furi: "｜＾資（し）｜＾料（りょう）｜が　｜！ほしいんですが、｜おくって　いただけませんか｜。",
                normal: "資料が　ほしいんですが、おくって　いただけませんか。",
                en: "I would like some data, could you please send them to me?",
                mm: "ဒေတာတချို့လိုချင်လို့ ကျေးဇူးပြုပြီး ပို့ပေးလို့ရမလား။",
            }, {
                furi: "｜＾書（か）｜き｜＾方（かた）｜が　｜！わからないんですが、｜おしえて　いただけませんか｜。",
                normal: "書き方がわからないんですが、おしえて　いただけませんか",
                en: "I don't know how to write it, can you teach me",
                mm: "ရေးပုံရေးနည်း မသိလို့ ပြောပြလို့ ရမလား။",
            },
        ],
        audioUrl: require("@assets/audio/lesson26/renshua/4N26RSA5.mp3")
    },
    {
        sentence: [
            {
                furi: "さくら　｜＾大（だい）｜＾学へ（がく）｜！　いきたいんですが、｜　どこて　おりたら　いいですか｜。",
                normal: "さくら　大学へ　いきたいんですが、　どこて　下りたら　いいですか。",
                en: "I want to go to Sakura University, where should I get off?",
                mm: "Sakura University ကိုသွားချင်လို့ ဘယ်မှာ ဆင်းရမလဲ။",
            },
            {
                furi: "｜＾保（ほ）｜＾険（けん）｜＾証（しょう）｜を　｜！わすれたんですが、｜　どうしたら　いいですか。",
                normal: "保険証を　わすれたんですが、　どうしたら　いいですか。",
                en: "I forgot my health insurance card, what should I do?",
                mm: "ကျန်းမာရေးအာမခံကတ်မေ့သွားလို့ ဘယ်လိုလုပ်ရမလဲ။",
            }
        ],
        audioUrl: require("@assets/audio/lesson26/renshua/4N26RSA6.mp3")
    },

];

/*
    {
        sentence: [
            {
                furi: "",
                normal: "",
                en: "",
                mm: "",
            },
        ],
        audioUrl: require("@assets/audio/testing.mp3")
    },




*/