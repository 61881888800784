export const bunkei= [
    {
        sentence: [
            {
                furi: "＾息（むす）子（こ）｜を　イギリスへ　｜＾留（りゅう）学（がく）｜させます。",
                normal: "息子を　イギリスへ　留学させます。",
                en: "I will send my son to England to study.",
                mm: "သားကို အင်္ဂလန်မှာ ပညာသင်စေမယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson48/bunpou/4N48BK1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾娘（むすめ）｜に　ピアノを　｜＾習（なら）｜わせます。",
                normal: "娘に　ピアノを　習わせます。",
                en: "I will have my daughter learn to play the piano.",
                mm: "သမီးကို စန္ဒယားတီး သင်စေမယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson48/bunpou/4N48BK2.mp3")
    },
]