export const explain= [
    {
        // ＾友（とも）達（だち）｜
        pattern_furi: '～すぎます',
        pattern_normal: '～すぎます',
        mm: [
            "　-များသွားတယ်။ -လွန်သွားတယ် ",
        ],
        en: [ 
            '　- Too much.',
        ]
    },   
    {
        pattern_furi: '～やすい',
        pattern_normal: '～やすい',
        mm: [
            "　အဆင်ပြေတယ်။ လွယ်ကူတယ်",
        ],
        en: [ 
            '　It works/ It is easy to ～',
        ]
    },   
    {
        pattern_furi: '～にくい',
        pattern_normal: '～にくい',
        mm: [
            "　ခက်ခဲတယ်",
        ],
        en: [ 
            '　It is difficult to ～',
        ]
    },   
    {
        pattern_furi: '～く/～にします',
        pattern_normal: '～く/～にします',
        mm: [
            "　လုပ်သည်/ ရွေးချယ်သည်",
        ],
        en: [ 
            '　do / choose',
        ]
    },   
 
]