
export const lesson18 =[
    {"lesson":18,"level":4,"question":"べんきょう","represent":["勉","強"],"answer":["勉強","文字","練習","教室"],"true":"勉強","times":10},
    {"lesson":18,"level":4,"question":"べんきょうする","represent":["勉","強"],"answer":["勉強する","研究する","教育する","研修する"],"true":"勉強する","times":10},
    {"lesson":18,"level":4,"question":"せんこうする","represent":["考"],"answer":["選考する","勉強する","研修する","研究する"],"true":"選考する","times":10},
    {"lesson":18,"level":4,"question":"かんじ","represent":["漢","字"],"answer":["漢字","勉強","教室","練習"],"true":"漢字","times":10},
    {"lesson":18,"level":4,"question":"きょうしつ","represent":["教","室"],"answer":["教室","文学","文字","英語"],"true":"教室","times":10},
    {"lesson":18,"level":4,"question":"けんきゅうする","represent":["研","究"],"answer":["研究する","選考する","勉強する","研修する"],"true":"研究する","times":10},
    {"lesson":18,"level":4,"question":"けんきゅうしゃ","represent":["研","究","者"],"answer":["研究者","文部省","研究所","考古学"],"true":"研究者","times":10},
    {"lesson":18,"level":4,"question":"れんしゅう","represent":["習"],"answer":["練習","漢字","教室","文字"],"true":"練習","times":10},
    {"lesson":18,"level":4,"question":"もんぶしょう","represent":["文"],"answer":["文部省","研究所","研究者","考古学"],"true":"文部省","times":10},
    {"lesson":18,"level":4,"question":"もじ","represent":["文","字"],"answer":["文字","練習","教室","漢字"],"true":"文字","times":10},
    {"lesson":18,"level":4,"question":"こうこがく","represent":["考","古","学"],"answer":["考古学","文部省","県境所","研究者"],"true":"考古学","times":10},
    {"lesson":18,"level":4,"question":"きょういくする","represent":["教"],"answer":["教育する","選考する","勉強する","研修する"],"true":"教育する","times":10},
    {"lesson":18,"level":4,"question":"けんきゅうじょ","represent":["研","究","所"],"answer":["研究所","文部省","考古学","研究者"],"true":"研究所","times":10},
    {"lesson":18,"level":4,"question":"ぶんがく","represent":["文","学"],"answer":["文学","教室","教会","文字"],"true":"文学","times":10},
    {"lesson":18,"level":4,"question":"がくしゅうする","represent":["学","習"],"answer":["学習する","選考する","研究する","研修する"],"true":"学習する","times":10},
    {"lesson":18,"level":4,"question":"けんしゅうする","represent":["研"],"answer":["研修する","勉強する","学習する","教育する"],"true":"研修する","times":10},
    {"lesson":18,"level":4,"question":"えいご","represent":["英","語"],"answer":["英語","練習","文学","教室"],"true":"英語","times":10},
    {"lesson":18,"level":4,"question":"きょうかい","represent":["教","会"],"answer":["教会","練習","英語","教室"],"true":"教会","times":10},
    {"lesson":18,"level":4,"question":"えいこく","represent":["英","国"],"answer":["英国","教会","文学","英語"],"true":"英国","times":10},
]