export const bunkei= [
    {
        sentence: [
            {
                furi: '＾音（おん）楽（がく）｜を　｜！聞（き）きながら｜　｜＾食（しょく）事（じ）｜します。',
                normal: '音楽を　聞きながら　食事します。',
                en: 'I eat while listening to music.',
                mm: 'သီချင်းနားထောင်ရင်းနဲ့ ထမင်းစားပါမယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson28/bunpou/4N28BK1.mp3")
    },
    {
        sentence: [
            {
                furi: '＾毎（まい）朝（あさ）｜　｜！ジョギングをして｜　います。',
                normal: '毎朝　ジョギングをして　います。',
                en: 'I jog every morning.',
                mm: 'မနက်တိုင်း(jogging) ပြေးလေ့ရှိပါတယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson28/bunpou/4N28BK2.mp3")
    },
    {
        sentence: [
            {
                furi: '＾地（ち）下（か）鉄（てつ）｜は　｜！速（はや）いし｜、　｜！安（やす）いし｜、　｜＾地（ち）下（か）鉄（てつ）｜で　｜＾行（い）｜きましょう。',
                normal: '地下鉄は　速いし、　安いし、　地下鉄で　行きましょう。',
                en: 'The subway is fast and cheap, so let\'s go by subway.',
                mm: 'မြေအောက်ရထားက မြန်လည်းမြန်တယ် ဈေးလည်းသက်သာလို့ မြေအောက်ရထားနဲ့ သွားကြရအောင်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson28/bunpou/4N28BK3.mp3")
    },
];

/*

{
    furi: '',
    normal: '',
    en: '',
    mm: '',
},

*/