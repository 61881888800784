export const lesson14 = {
    "米": 130,
    "料": 131,
    "理": 132,
    "肉": 133,
    "鳥": 134,
    "野": 135,
    "菜": 136,
    "茶": 137,
    "飯": 138,
    "味": 139
};