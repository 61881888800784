export const lesson47 = [
  {
      "furi": "＾吹（ふ）｜きます",
      "normal": "吹きます",
      "en": "Blow",
      "remark_en": "",
      "mm": "တိုက်ခတ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO1.mp3")
  },
  {
      "furi": "＾燃（も）｜えます",
      "normal": "燃えます",
      "en": "burn",
      "remark_en": "",
      "mm": "လောင်ကျွမ်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO2.mp3")
  },
  {
      "furi": "＾亡（な）｜くなります",
      "normal": "亡くなります",
      "en": "Will die",
      "remark_en": "",
      "mm": "သေဆုံးသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO3.mp3")
  },
  {
      "furi": "＾集（あつ）｜まります",
      "normal": "集まります",
      "en": "gather",
      "remark_en": "",
      "mm": "စုဝေးသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO4.mp3")
  },
  {
      "furi": "＾別（わか）｜れます",
      "normal": "別れます",
      "en": "separate",
      "remark_en": "",
      "mm": "ကွဲကွာသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO5.mp3")
  },
  {
      "furi": "します",
      "normal": "します",
      "en": "get",
      "remark_en": "",
      "mm": "ရရှိသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO6.mp3")
  },
  {
      "furi": "＾厳（きび）｜しい",
      "normal": "厳しい",
      "en": "Strict",
      "remark_en": "",
      "mm": "စည်းကမ်းကြီးသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO7.mp3")
  },
  {
      "furi": "ひどい",
      "normal": "ひどい",
      "en": "terrible, severe",
      "remark_en": "",
      "mm": "လွန်ကဲသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO8.mp3")
  },
  {
      "furi": "＾怖（こわ）｜い",
      "normal": "怖い",
      "en": "frightening, horrible",
      "remark_en": "",
      "mm": "ကြောက်သော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO9.mp3")
  },
  {
      "furi": "＾実（じっ）験（けん）",
      "normal": "実験",
      "en": "experiment",
      "remark_en": "",
      "mm": "လက်တွေ့စမ်းသပ်မှု",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO10.mp3")
  },
  {
      "furi": "データ",
      "normal": "データ",
      "en": "data",
      "remark_en": "",
      "mm": "အချက်အလက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO11.mp3")
  },
  {
      "furi": "＾人（じん）口（こう）",
      "normal": "人口",
      "en": "population",
      "remark_en": "",
      "mm": "လူဦးရေ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO12.mp3")
  },
  {
      "furi": "におい",
      "normal": "におい",
      "en": "smell",
      "remark_en": "",
      "mm": "အနံ့",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO13.mp3")
  },
  {
      "furi": "＾科（か）学（がく）",
      "normal": "科学",
      "en": "science",
      "remark_en": "",
      "mm": "သိပ္ပံဘာသာရပ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO14.mp3")
  },
  {
      "furi": "＾医（い）学（がく）",
      "normal": "医学",
      "en": "medicine, medical science",
      "remark_en": "",
      "mm": "ဆေးပညာဘာသာရပ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO15.mp3")
  },
  {
      "furi": "＾文（ぶん）学（がく）",
      "normal": "文学",
      "en": "literature",
      "remark_en": "",
      "mm": "ဝိဇ္ဇာဘာသာရပ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO16.mp3")
  },
  {
      "furi": "パトカー",
      "normal": "パトカー",
      "en": "patrol car",
      "remark_en": "",
      "mm": "ကင်းလှည့်ကား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO17.mp3")
  },
  {
      "furi": "＾救（きゅう）急（きゅう）車（しゃ）",
      "normal": "救急車",
      "en": "ambulance",
      "remark_en": "",
      "mm": "အရေးပေါ်လူနာတင်ယာဉ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO18.mp3")
  },
  {
      "furi": "＾賛（さん）成（せい）",
      "normal": "賛成",
      "en": "approval, agreement",
      "remark_en": "",
      "mm": "ထောက်ခံခြင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO19.mp3")
  },
  {
      "furi": "＾反（はん）対（たい）",
      "normal": "反対",
      "en": "objection, opposition",
      "remark_en": "",
      "mm": "ဆန့်ကျင်ခြင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO20.mp3")
  },
  {
      "furi": "＾大（だい）統（とう）領（りょう）",
      "normal": "大統領",
      "en": "President",
      "remark_en": "",
      "mm": "သမ္မတ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO21.mp3")
  },
  {
      "furi": "～によると",
      "normal": "～によると",
      "en": "according to ~",
      "remark_en": "",
      "mm": "～အရ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO22.mp3")
  },
  {
      "furi": "＾婚（こん）約（やく）｜します",
      "normal": "婚約します",
      "en": "get engaged",
      "remark_en": "",
      "mm": "စေ့စပ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO23.mp3")
  },
  {
      "furi": "どうも",
      "normal": "どうも",
      "en": "How do you think?",
      "remark_en": "",
      "mm": "ဘယ်လိုတွေးတွေး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO24.mp3")
  },
  {
      "furi": "＾恋（こい）人（びと）",
      "normal": "恋人",
      "en": "sweetheart, girlfriend, boyfriend",
      "remark_en": "",
      "mm": "ချစ်သူ၊ရည်စား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO25.mp3")
  },
  {
      "furi": "＾相（あい）手（て）",
      "normal": "相手",
      "en": "the other person",
      "remark_en": "",
      "mm": "တစ်ဖက်လူ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO26.mp3")
  },
  {
      "furi": "＾知（し）｜り｜＾合（あ）｜います",
      "normal": "知り合います",
      "en": "get acquainted",
      "remark_en": "",
      "mm": "သိကျွမ်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson47/vocab/4N47VO27.mp3")
  },
  {
      "furi": "＾化（け）粧（しょう）",
      "normal": "化粧",
      "en": "makeup",
      "remark_en": "",
      "mm": "အလှပြင်ခြင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾化（け）粧（しょう）｜をします",
      "normal": "化粧をします",
      "en": "put on makeup",
      "remark_en": "",
      "mm": "အလှပြင်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾女（じょ）性（せい）",
      "normal": "女性",
      "en": "female, woman",
      "remark_en": "",
      "mm": "အမျိုးသမီး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾男（だん）性（せい）",
      "normal": "男性",
      "en": "male, man",
      "remark_en": "",
      "mm": "အမျိုးသား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾長（なが）生（い）｜き",
      "normal": "長生き",
      "en": "Longevity",
      "remark_en": "",
      "mm": "အသက်ရှည်ခြင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾理（り）由（ゆう）",
      "normal": "理由",
      "en": "reason",
      "remark_en": "",
      "mm": "အကြောင်းပြချက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾関（かん）係（けい）",
      "normal": "関係",
      "en": "relation",
      "remark_en": "",
      "mm": "ဆက်နွယ်မှု",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  }
]

/*
    {
        furi: "",
        normal: "",
        en: "",
        remark_en: "",
        mm: "",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson29/vocab/4N29VO1.mp3")
    },


*/
const test = [
  {
    'goi': '吹（ふ）」きます',
    'unicode': 'တိုက်ခတ်သည်',
    'zawgyi': 'တိုက္ခတ္သည္',
    'english': 'Blow',
    'long': false
  },
  {
    'goi': '燃（も）」えます',
    'unicode': 'လောင်ကျွမ်းသည်',
    'zawgyi': 'ေလာင္ကၽြမ္းသည္',
    'english': 'burn',
    'long': false
  },
  {
    'goi': '亡（な）」くなります',
    'unicode': 'သေဆုံးသည်',
    'zawgyi': 'ေသဆုံးသည္',
    'english': 'Will die',
    'long': false
  },
  {
    'goi': '集（あつ）」まります',
    'unicode': 'စုဝေးသည်',
    'zawgyi': 'စုေဝးသည္',
    'english': 'gather',
    'long': false
  },
  {
    'goi': '別（わか）」れます',
    'unicode': 'ကွဲကွာသည်',
    'zawgyi': 'ကြဲကြာသည္',
    'english': 'separate',
    'long': false
  },
  {
    'goi': 'します',
    'unicode': 'ရရှိသည်',
    'zawgyi': 'ရရွိသည္',
    'english': 'get',
    'long': false
  },
  {
    'goi': '厳（きび）」しい',
    'unicode': 'စည်းကမ်းကြီးသော',
    'zawgyi': 'စည္းကမ္းႀကီးေသာ',
    'english': 'Strict',
    'long': false
  },
  {
    'goi': 'ひどい',
    'unicode': 'လွန်ကဲသော',
    'zawgyi': 'လြန္ကဲေသာ',
    'english': 'terrible, severe',
    'long': false
  },
  {
    'goi': '怖（こわ）」い',
    'unicode': 'ကြောက်သော',
    'zawgyi': 'ေၾကာက္ေသာ',
    'english': 'frightening, horrible',
    'long': false
  },
  {
    'goi': '実（じっ）」験（けん）',
    'unicode': 'လက်တွေ့စမ်းသပ်မှု',
    'zawgyi': 'လက္ေတြ႕စမ္းသပ္မႈ',
    'english': 'experiment',
    'long': false
  },
  {
    'goi': 'データ',
    'unicode': 'အချက်အလက်',
    'zawgyi': 'အခ်က္အလက္',
    'english': 'data',
    'long': false
  },
  {
    'goi': '人（じん）」口（こう）',
    'unicode': 'လူဦးရေ',
    'zawgyi': 'လူဦးေရ',
    'english': 'population',
    'long': false
  },
  {
    'goi': 'におい',
    'unicode': 'အနံ့',
    'zawgyi': 'အနံ့',
    'english': 'smell',
    'long': false
  },
  {
    'goi': '科（か）」学（がく）',
    'unicode': 'သိပ္ပံဘာသာရပ်',
    'zawgyi': 'သိပၸံဘာသာရပ္',
    'english': 'science',
    'long': false
  },
  {
    'goi': '医（い）」学（がく）',
    'unicode': 'ဆေးပညာဘာသာရပ်',
    'zawgyi': 'ေဆးပညာဘာသာရပ္',
    'english': 'medicine, medical science',
    'long': false
  },
  {
    'goi': '文（ぶん）」学（がく）',
    'unicode': 'ဝိဇ္ဇာဘာသာရပ်',
    'zawgyi': 'ဝိဇၨာဘာသာရပ္',
    'english': 'literature',
    'long': false
  },
  {
    'goi': 'パトカー',
    'unicode': 'ကင်းလှည့်ကား',
    'zawgyi': 'ကင္းလွည့္ကား',
    'english': 'patrol car',
    'long': false
  },
  {
    'goi': '救（きゅう）」急（きゅう）」車（しゃ）',
    'unicode': 'အရေးပေါ်လူနာတင်ယာဉ်',
    'zawgyi': 'အေရးေပၚလူနာတင္ယာဥ္',
    'english': 'ambulance',
    'long': false
  },
  {
    'goi': '賛（さん）」成（せい）',
    'unicode': 'ထောက်ခံခြင်း',
    'zawgyi': 'ေထာက္ခံျခင္း',
    'english': 'approval, agreement',
    'long': false
  },
  {
    'goi': '反（はん）」対（たい）',
    'unicode': 'ဆန့်ကျင်ခြင်း',
    'zawgyi': 'ဆန႔္က်င္ျခင္း',
    'english': 'objection, opposition',
    'long': false
  },
  {
    'goi': '大（だい）」統（とう）」領（りょう）',
    'unicode': 'သမ္မတ',
    'zawgyi': 'သမၼတ',
    'english': 'President',
    'long': false
  },
  {
    'goi': '～によると',
    'unicode': '～အရ',
    'zawgyi': '～အရ',
    'english': 'according to ~',
    'long': false
  },
  {
    'goi': '婚（こん）」約（やく）」します',
    'unicode': 'စေ့စပ်သည်',
    'zawgyi': 'ေစ့စပ္သည္',
    'english': 'get engaged',
    'long': false
  },
  {
    'goi': 'どうも',
    'unicode': 'ဘယ်လိုတွေးတွေး',
    'zawgyi': 'ဘယ္လိုေတြးေတြး',
    'english': 'How do you think?',
    'long': false
  },
  {
    'goi': '恋（こい）」人（びと）',
    'unicode': 'ချစ်သူ၊ရည်စား',
    'zawgyi': 'ခ်စ္သူ၊ရည္စား',
    'english': 'sweetheart, girlfriend, boyfriend',
    'long': false
  },
  {
    'goi': '相（あい）」手（て）',
    'unicode': 'တစ်ဖက်လူ',
    'zawgyi': 'တစ္ဖက္လူ',
    'english': 'the other person',
    'long': false
  },
  {
    'goi': '知（し）」り」合（あ）」います',
    'unicode': 'သိကျွမ်းသည်',
    'zawgyi': 'သိကၽြမ္းသည္',
    'english': 'get acquainted',
    'long': false
  },
  {
    'goi': '化（け）」粧（しょう）',
    'unicode': 'အလှပြင်ခြင်း',
    'zawgyi': 'အလွျပင္ျခင္း',
    'english': 'makeup',
    'long': false
  },
  {
    'goi': '化（け）」粧（しょう）」をします',
    'unicode': 'အလှပြင်သည်',
    'zawgyi': 'အလွျပင္သည္',
    'english': 'put on makeup',
    'long': false
  },
  {
    'goi': '女（じょ）」性（せい）',
    'unicode': 'အမျိုးသမီး',
    'zawgyi': 'အမ်ိဳးသမီး',
    'english': 'female, woman',
    'long': false
  },
  {
    'goi': '男（だん）」性（せい）',
    'unicode': 'အမျိုးသား',
    'zawgyi': 'အမ်ိဳးသား',
    'english': 'male, man',
    'long': false
  },
  {
    'goi': '長（なが）」生（い）」き',
    'unicode': 'အသက်ရှည်ခြင်း',
    'zawgyi': 'အသက္ရွည္ျခင္း',
    'english': 'Longevity',
    'long': false
  },
  {
    'goi': '理（り）」由（ゆう）',
    'unicode': 'အကြောင်းပြချက်',
    'zawgyi': 'အေၾကာင္းျပခ်က္',
    'english': 'reason',
    'long': false
  },
  {
    'goi': '関（かん）」係（けい）',
    'unicode': 'ဆက်နွယ်မှု',
    'zawgyi': 'ဆက္ႏြယ္မႈ',
    'english': 'relation',
    'long': false
  }
]