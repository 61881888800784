import Icon from "@consts/icon";

export const lesson39 = {
    title: {
        furi: '＾遅（おく）｜れて、すみません',
        normal: '遅れて、すみません',
        en: 'Sorry for late',
        mm: 'နောက်ကျသွားလို့တောင်းပန်ပါတယ်',
    },
    conversation: [
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.BOY
            },
            sentence: [
                {
                    furi: '＾課（か）長（ちょう）｜、｜＾遅（おく）｜れて、すみません。',
                    normal: '課長、遅れて、すみません。',
                    en: 'Manager, sorry for late.',
                    mm: 'မန်နေဂျာကြီး နောက်ကျသွားလို့တောင်းပန်ပါတယ်',
                },
            ],
        },
        {
            person: {
                furi: '＾中（なか）村（むら）課（か）長（ちょう）',
                normal: '中村課長',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'ミラーさん、どう　したんですか。',
                    normal: 'ミラーさん、どう　したんですか。',
                    en: "Mr. Miller, what's wrong?",
                    mm: 'မီရစံ ဘယ်လိုဖြစ်တာလည်း',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.BOY
            },
            sentence: [
                {
                    furi: '＾実（じつ）｜は　｜＾来（く）｜る　｜＾途（と）中（ちゅう）｜で　｜＾事（じ）故（こ）｜が　あって、バズが　｜＾遅（おく）｜れて　しまったんです。',
                    normal: '実は　来る　途中で　事故が　あって、バズが　遅れて　しまったんです。',
                    en: 'Actually, there was an accident on the way, and bus was late.',
                    mm: 'တစ်ကယ်က လာတဲ့လမ်းမှာ မတော်တဆမှုဖြစ်ပြီး ဘတ်စ်ကား နောက်ကျသွားတာပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾中（なか）村（むら）課（か）長（ちょう）',
                normal: '中村課長',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'バスの　｜＾事（じ）故（こ）｜ですか。',
                    normal: 'バスの　事故ですか。',
                    en: 'A bus accident?',
                    mm: 'ဘတ်စ်ကား မတော်တဆမှုဖြစ်တာလား',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.BOY
            },
            sentence: [
                {
                    furi: 'いいえ。｜＾交（こう）差（さ）点（てん）｜で　トラックと　｜＾車（くるま）｜が　ぶつかって、',
                    normal: 'いいえ。交差点で　トラックと　車が　ぶつかって、',
                    en: 'no. A truck collides with a car at an intersection',
                    mm: 'မဟုတ်ပါဘူး လမ်းဆုံမှာ ကုန်ကားနဲ့ ကားငယ် တိုက်မိပြီး',
                },
                {
                    furi: 'バズが　｜＾動（うご）｜かなかったんです。',
                    normal: 'バズが　動かなかったんです。',
                    en:"And the bus didn't move.",
                    mm: 'ဘတ်စ်ကားက ထွက်မရတော့တာပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾中（なか）村（むら）課（か）長（ちょう）',
                normal: '中村課長',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'それは　｜＾大（たい）変（へん）｜でしたね。',
                    normal: 'それは　大変でしたね。',
                    en: 'That was tough, huh.',
                    mm: 'အဲ့ဒါက အခက်တွေ့ခဲ့တာပဲ',
                },
                {
                    furi: '＾連（らん）絡（らく）｜が　ないので、　みんな　｜＾心（しん）配（ぱい）｜して　いたんですよ。',
                    normal: '連絡が　ないので、　みんな　心配して　いたんですよ。',
                    en: 'Everyone was worried because there was no contact.',
                    mm: 'အဆက်အသွယ်မလုပ်တော့ အားလုံးကစိတ်ပူနေကြတယ်',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.BOY
            },
            sentence: [
                {
                    furi: '＾電（でん）話（わ）｜したかったんですが、ケータイを　うちに　｜＾忘（わす）｜れて　しまって。。。',
                    normal: '電話したかったんですが、ケータイを　うちに　忘れて　しまって。。。',
                    en: 'I wanted to call you, but I forgot my mobile phone at home. . .',
                    mm: 'ဖုန်းဆက်ချင်ခဲ့ပေမယ့် လက်ကိုင်ဖုန်းက အိမ်မှာမေ့ကျန်ပြီးတော့ ...',
                },
                {
                    furi: 'どうも　すみませんでした。',
                    normal: 'どうも　すみませんでした。',
                    en: "I'm very sorry.",
                    mm: 'အနူးအညွှတ်တောင်းပန်ပါတယ်ခင်ဗျ',
                },
            ],
        },
        {
            person: {
                furi: '＾中（なか）村（むら）課（か）長（ちょう）',
                normal: '中村課長',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'わかりました。じゃ、｜＾会（かい）議（ぎ）｜を　｜＾始（は）｜めましょう。',
                    normal: 'わかりました。じゃ、会議を　始めましょう。',
                    en: "I got it. Well then, let's start the meeting.",
                    mm: 'နားလည်ပါပြီ၊ ကဲ အစည်းအဝေးစကြစို့',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson39/kaiwa/4N39KW.mp3")
}

/*
{
    title: {
        furi: '',
        normal: '',
        en: '',
        mm: '',
    },
    conversation: [
        {
            person: {
                furi: '',
                normal: '',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '',
                    normal: '',
                    en: '',
                    mm: '',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson36/kaiwa/4N36KW.mp3")
}
*/