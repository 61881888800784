export const renshuuA = [
    {
        sentence: [
            {
                furi: "お｜＾土（み）産（やげ）｜を　｜！かい｜　すぎました。",
                normal: "お土産を　かい　すぎました。",
                en: "I bought too many souvenirs.",
                mm: "အမှတ်တရလက်ဆောင်တွေ ဝယ်တာ များလွန်းသွားတယ်။",
            },
            {
                furi: "ごはんを　｜！たべ｜　すぎました。",
                normal: "ごはんを　たべ　すぎました。",
                en: "I ate too much rice.",
                mm: "ထမင်းစားများသွားတယ်။",
            },
            {
                furi: "この　｜＾部（へ）屋（や）｜は　｜！せま｜　すぎます。",
                normal: "この　部屋は　せま　すぎます。",
                en: "This room is too small.",
                mm: "ဒီအခန်းက ကျဉ်းလွန်းတယ်။",
            },
            {
                furi: "この　｜＾方（ほう）法（ほう）｜は　｜！ふくざつ｜　すぎます。",
                normal: "この　方法は　ふくざつ　すぎます。",
                en: "This method is too complicated.",
                mm: "ဒီနည်းလမ်းက ရှုပ်ထွေးလွန်းတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson44/renshua/4N44RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "この　｜＾薬（くすり）｜　は　｜！のみ｜　やすいです。",
                normal: "この　薬　は　のみ　やすいです。",
                en: "This medicine is easy to swallow.",
                mm: "ဤဆေးသည် မျိုချရလွယ်ကူသည်။",
            },
            {
                furi: "この　はさみ　は　｜！つかい｜　やすいです。",
                normal: "この　はさみ　は　つかい　やすいです。",
                en: "This scissors are easy to use.",
                mm: "ဤကတ်ကြေးသည် အသုံးပြုရလွယ်ကူသည်။",
            },
            {
                furi: "＾東（とう）京（きょう）｜　は　｜！すみ｜　にくいです。",
                normal: "東京　は　すみ　にくいです。",
                en: "It's hard to live in Tokyo.",
                mm: "တိုကျိုမှာနေရတာ ခက်တယ်။",
            },
            {
                furi: "この　｜＾靴（くつ）｜　は　｜！あるき｜　にくいです。",
                normal: "この　靴　は　あるき　にくいです。",
                en: "These shoes are difficult to walk on.",
                mm: "ဒီဖိနပ်တွေက လမ်းလျှောက်ရခက်တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson44/renshua/4N44RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾山（やま）｜の　｜＾天（てん）気（き）｜　は　｜！かわり｜　やすいです。",
                normal: "山の　天気　は　かわり　やすいです。",
                en: "Mountain weather is changeable.",
                mm: "တောင်ပေါ် ရာသီဥတုက အပြောင်းအလဲများတယ်။",
            },
            {
                furi: "＾事（じ）故（こ）｜　は　｜＾雨（あめ）｜の　｜＾日（ひ）｜に　｜！おき｜　やすいです。",
                normal: "事故　は　雨の　日に　おき　やすいです。",
                en: "Accidents are more likely to occur on rainy days.",
                mm: "မိုးရွာတဲ့နေ့တွေမှာ မတော်တဆမှုတွေ ပိုဖြစ်တတ်ပါတယ်။",
            },
            {
                furi: "この　コップ　は　｜！われ｜　にくいです。",
                normal: "この　コップ　は　われ　にくいです。",
                en: "This cup is hard to break.",
                mm: "ဒီခွက်က ကွဲဖို့ခက်တယ်။",
            },
            {
                furi: "＾厚（あつ）｜い　タオル　は　｜！かわき｜　にくいです。",
                normal: "厚い　タオル　は　かわき　にくいです。",
                en: "Thick towels are hard to dry.",
                mm: "ထူထဲသော မျက်နှာသုတ်ပုဝါများသည် ခြောက်သွေ့ရန်ခက်ခဲသည်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson44/renshua/4N44RSA3.mp3")
    },
    {
        sentence: [
            {
                furi: "＾髪（かみ）｜　を　｜！みじかく｜　します。",
                normal: "髪　を　みじかく　します。",
                en: "Shorten the hair.",
                mm: "ဆံပင်ကိုတိုတိုထားတယ်။",
            },
            {
                furi: "＾部（へ）屋（や）｜　を　｜！きれいに｜　します。",
                normal: "部屋　を　きれいに　します。",
                en: "I will clean the room.",
                mm: "အခန်းသန့်ရှင်းရေးလုပ်မယ်။",
            },
            {
                furi: "＾水（みず）｜の　｜＾量（りょう）｜　｜！２ばいに｜　します。",
                normal: "水の　量　２ばいに　します。",
                en: "Double the amount of water.",
                mm: "ရေပမာဏကို နှစ်ဆတိုးမယ်။။",
            },
        ],
        audioUrl: require("@assets/audio/lesson44/renshua/4N44RSA4.mp3")
    },
    {
        sentence: [
            {
                furi: "＾晩（ばん）｜ごはんは　｜！カレーライスに｜　します。",
                normal: "晩ごはんは　カレーライスに　します。",
                en: "I'll have curry rice for dinner.",
                mm: "ညစာအတွက် ဟင်းထမင်းချက်မယ်။",
            },
            {
                furi: "＾晩（ばん）｜ごはんは　｜！てんぷら｜　します。",
                normal: "晩ごはんは　てんぷら　します。",
                en: "I will have tempura for dinner.",
                mm: "ညစာအတွက် တန်ပူရာစားမယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson44/renshua/4N44RSA5.mp3")
    },
];