import HomeScreenLayout from "@layouts/home/HomeScreenLayout";
import { memo } from "react";
import ContactMobileComponent from "@components/contact/ContactMobileComponent";
import ContactWebComponent from "@components/contact/ContactWebComponent";
import { BottomSpace, TopSpace } from "@consts/layout";
import { ScreenPropType } from "App";
const ContactScreen = ({ showModel,setShowModel,setUnlockPremium,selector,lang, name, dispatch, Logger, setName, Routes, useNavigate,useLocation }: ScreenPropType) => {
    const log = Logger(10);
    const location = useLocation();
    return (<>
        <HomeScreenLayout
            body={
                <div className="container mx-auto">
                    <TopSpace/>
                    {/* Web View */}
                    <ContactWebComponent/>
                    {/* Mobile View */}
                    <ContactMobileComponent/>
                    <BottomSpace/>
                </div>
            }
            Routes={Routes}
            Logger={Logger}
            useNavigate={useNavigate}
            showModel={showModel}
            setShowModel={setShowModel}
            setUnlockPremium={setUnlockPremium}
        />
    </>)
}
export default memo(ContactScreen);