export const lesson34 = [
  {
    furi: "＾磨（みが）｜きます［はを～］",
    normal: "磨きます［はを～］",
    en: "brush, polish",
    remark_en: "",
    mm: "ပွတ်တိုက်သည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO1.mp3")
  },
  {
    furi: "＾組（く）｜み｜＾立（た）｜てます",
    normal: "組み立てます",
    en: "assemble",
    remark_en: "",
    mm: "တပ်ဆင်ဆင်သည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO2.mp3")
  },
  {
    furi: "＾折（お）｜ります",
    normal: "折ります",
    en: "bend, fold",
    remark_en: "",
    mm: "ချိုးကွေ့သည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO3.mp3")
  },
  {
    furi: "＾気（き）｜がつきます",
    normal: "気がつきます",
    en: "notice, become aware of",
    remark_en: "",
    mm: "သတိထားမိသည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO4.mp3")
  },
  {
    furi: "＾付（つ）｜けます［しょうゆを～］",
    normal: "付けます［しょうゆを～］",
    en: "put in, attach",
    remark_en: "",
    mm: "ဆမ်းသည်၊　ဆွတ်သည်",
    remark_mm: "ပဲငံပြာရည်စသည်",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO5.mp3")
  },
  {
    furi: "＾見（み）｜つかります［かぎが～］",
    normal: "見つかります［かぎが～］",
    en: "be found",
    remark_en: "",
    mm: "ရှာတွေ့သည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO6.mp3")
  },
  {
    furi: "＾質（しつ）問（もん）｜します",
    normal: "質問します",
    en: "ask a a question",
    remark_en: "",
    mm: "မေးမြန်းသည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO7.mp3")
  },
  {
    furi: "さします［かさを～］",
    normal: "さします［かさを～］",
    en: "Wear(umbrella)",
    remark_en: "",
    mm: "ဆောင်းသည်(ထီးကို)",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO8.mp3")
  },
  {
    furi: "スポーツクラブ",
    normal: "スポーツクラブ",
    en: "sport club",
    remark_en: "",
    mm: "အားကစား ကလပ်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO9.mp3")
  },
  {
    furi: "[お]｜＾城（しろ）",
    normal: "[お]城",
    en: "Castle",
    remark_en: "",
    mm: "ရဲတိုက်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO10.mp3")
  },
  {
    furi: "＾説（せつ）明（めい）書（しょ）",
    normal: "説明書",
    en: "instruction book",
    remark_en: "",
    mm: "အသုံးပြုနည်းလမ်းညွှန်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO11.mp3")
  },
  {
    furi: "＾図（ず）",
    normal: "図",
    en: "figure, drawing",
    remark_en: "",
    mm: "ပုံ၊ဇယား",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO12.mp3")
  },
  {
    furi: "＾線（せん）",
    normal: "線",
    en: "line",
    remark_en: "",
    mm: "မျည်းကြောင်း",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO13.mp3")
  },
  {
    furi: "＾矢（やじ）印（るし）",
    normal: "矢印",
    en: "arrow(sign)",
    remark_en: "",
    mm: "မြှားသင်္ကေတ",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO14.mp3")
  },
  {
    furi: "＾黒（くろ）",
    normal: "黒",
    en: "black",
    remark_en: "",
    mm: "အမဲ၊အနက်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO15.mp3")
  },
  {
    furi: "＾白（しろ）",
    normal: "白",
    en: "white",
    remark_en: "",
    mm: "အဖြူ",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO16.mp3")
  },
  {
    furi: "＾赤（あか）",
    normal: "赤",
    en: "red",
    remark_en: "",
    mm: "အနီ",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO17.mp3")
  },
  {
    furi: "＾青（あお）",
    normal: "青",
    en: "blue",
    remark_en: "",
    mm: "အပြာ",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO18.mp3")
  },
  {
    furi: "＾紺（こん）",
    normal: "紺",
    en: "dark blue",
    remark_en: "",
    mm: "နက်ပြာ",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO19.mp3")
  },
  {
    furi: "＾黄（き）色（いろ）",
    normal: "黄色",
    en: "yellow",
    remark_en: "",
    mm: "အဝါ",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO20.mp3")
  },
  {
    furi: "＾茶（ちゃ）色（いろ）",
    normal: "茶色",
    en: "brown",
    remark_en: "",
    mm: "အညို",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO21.mp3")
  },
  {
    furi: "しょうゆ",
    normal: "しょうゆ",
    en: "soy sauce",
    remark_en: "",
    mm: "ပဲငံပြာရည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO22.mp3")
  },
  {
    furi: "ソース",
    normal: "ソース",
    en: "sauce",
    remark_en: "",
    mm: "ဆော့အရည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO23.mp3")
  },
  {
    furi: "お｜＾客（きゃく）｜[さん]",
    normal: "お客[さん]",
    en: "Customer",
    remark_en: "",
    mm: "ဧည့်သည်၊ဖောက်သည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO24.mp3")
  },
  {
    furi: "～か～",
    normal: "～か～",
    en: "~ or ~",
    remark_en: "",
    mm: "～သို့မဟုတ်～",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO25.mp3")
  },
  {
    furi: "ゆうべ",
    normal: "ゆうべ",
    en: "last night",
    remark_en: "",
    mm: "မနေ့ည",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO26.mp3")
  },
  {
    furi: "さっき",
    normal: "さっき",
    en: "a short while ago",
    remark_en: "",
    mm: "ခုနက",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO27.mp3")
  },
  {
    furi: "＾茶（さ）道（どう）",
    normal: "茶道",
    en: "tea ceremony",
    remark_en: "",
    mm: "လက်ဖက်ရည်ပွဲ",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO28.mp3")
  },
  {
    furi: "お｜＾茶（ちゃ）｜をたてます",
    normal: "お茶をたてます",
    en: "make green tea",
    remark_en: "",
    mm: "ရိုးရာလက်ဖက်ရည်ဖျော်သည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO29.mp3")
  },
  {
    furi: "＾先（さき）｜に",
    normal: "先に",
    en: "first",
    remark_en: "",
    mm: "အရင်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO30.mp3")
  },
  {
    furi: "＾載（の）｜せます",
    normal: "載せます",
    en: "place on",
    remark_en: "",
    mm: "တင်သည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO31.mp3")
  },
  {
    furi: "これでいいですか。",
    normal: "これでいいですか。",
    en: "Is this all right?",
    remark_en: "",
    mm: "ဒါဆိုရပြီလား",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO32.mp3")
  },
  {
    furi: "いかがですか。",
    normal: "いかがですか。",
    en: "How do you like it.",
    remark_en: "",
    mm: "ဘယ်လို(သဘောရ)လဲ",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO33.mp3")
  },
  {
    furi: "＾苦（にが）｜い",
    normal: "苦｜い",
    en: "bitter",
    remark_en: "",
    mm: "ခါးတယ်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson34/vocab/4N34VO34.mp3")
  },
  {
    furi: "＾親（おや）子（こ）｜どんぶり",
    normal: "親子どんぶり",
    en: "Oyako Donburi",
    remark_en: "",
    mm: "ကြက်သားနှင့်ကြက်ဥပါထမင်း",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "＾材（ざい）料（りょう）",
    normal: "材料",
    en: "material, ingredient",
    remark_en: "",
    mm: "ပါဝင်ပစ္စည်း",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "～｜＾分（ぶん）",
    normal: "～分",
    en: "portion",
    remark_en: "",
    mm: "-ပုံ၊-ပိုင်း",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "～グラム",
    normal: "～グラム",
    en: "-gram",
    remark_en: "",
    mm: "-ဂရမ်",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "～｜＾個（こ）",
    normal: "～個",
    en: "counter for small objects",
    remark_en: "",
    mm: "-ခု၊-လုံး",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "たまねぎ",
    normal: "たまねぎ",
    en: "onion",
    remark_en: "",
    mm: "ကြက်သွန်နီ",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "４｜＾分（ぶん）｜の｜＾１（いち）",
    normal: "４分の１",
    en: "one fourth",
    remark_en: "",
    mm: "လေးပုံတစ်ပုံ",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "＾調（ちょう）味（み）料（りょう）",
    normal: "調味料",
    en: "seasoning , flavoring",
    remark_en: "",
    mm: "ဟင်းခတ်",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "＾適（てき）当（とう）｜な｜＾大（おお）｜きさに",
    normal: "適当な大きさに",
    en: "To a suitable size",
    remark_en: "",
    mm: "သင့်တော်သောအရယ်အစား",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "なべ",
    normal: "なべ",
    en: "pan, pot",
    remark_en: "",
    mm: "ဒယ်အိုး",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "＾火（ひ）",
    normal: "火",
    en: "fire, heating",
    remark_en: "",
    mm: "မီး",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "＾火（ひ）｜にかけます",
    normal: "火にかけます",
    en: "put on the stove",
    remark_en: "",
    mm: "မီးကိုအသုံးပြုချက်ပြုတ်သည်",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "＾煮（に）｜ます",
    normal: "煮ます",
    en: "cook, boil",
    remark_en: "",
    mm: "ချက်သည်",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "＾煮（に）｜えます",
    normal: "煮えます",
    en: "be cooked, be boiled",
    remark_en: "",
    mm: "ကျက်သည်",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "どんぶり",
    normal: "どんぶり",
    en: "ceramic bowl",
    remark_en: "",
    mm: "ဇောက်နက်ပန်းကန်လုံး",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
  {
    furi: "たちます",
    normal: "たちます",
    en: "Time(goes by)",
    remark_en: "",
    mm: "ကြာသည်(အချိန်)",
    remark_mm: "",
    group: 0,
    audioUrl: null
  },
    
]
