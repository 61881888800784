export const reibun= [
    {
        sentence: [
            {
                furi: '＾眠（ねむ）｜い　とき、　ガムを　｜！かみながら｜　｜＾運（うん）転（てん）｜します。',
                normal: '眠い　とき、　ガムを　かみながら　運転します。',
                en: 'When I\'m sleepy, I chew gum while driving.',
                mm: 'အိပ်ချင်တဲ့အခါ ပီကေကိုဝါးရင်းနဲ့ ကားမောင်းတယ်။',
            },
            {
                furi: "。。。そうですか、　わたしは　｜＾車（くるま）｜を　｜＾止（と）｜めて、　しばらく　｜＾寝（ね）｜ます。",
                normal: "。。。そうですか、　わたしは　車を　止めて、　しばらく　寝ます。",
                en: ". . . Is that so, I will stop the car and sleep for a while.",
                mm: "...ဟုတ်လား၊ ကျနော်ကတော့ ကားကိုရပ်ပြီး ခဏအိပ်တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson28/bunpou/4N28RB1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾音（おん）楽（がく）｜を　｜！聞（き）きながら｜　｜＾勉（べん）強（きょう）｜しますか。",
                normal: "音楽を　聞きながら　勉強しますか。",
                en: "Do you study while listening to music?",
                mm: "သီချင်းနားထောင်ရင်း စာကျက်နေလား?",
            },
            {
                furi: "。。。いいえ、｜＾勉（べん）強（きょう）｜する　ときは、　｜＾音（おん）楽（がく）｜を　｜＾聞（き）｜きません。",
                normal: "。。。いいえ、勉強する　ときは、　音楽を　聞きません。",
                en: ". . . No, he doesn't listen to music when he studies.",
                mm: "... ဟင့်အင်း၊ စာလုပ်နေရင်းနဲ့ သီချင်းနားမထောင်ပါဘူး။",
            },
        ],
        audioUrl: require("@assets/audio/lesson28/bunpou/4N28RB2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾彼（かれ）｜は　｜！働（はたら）きながら｜　｜＾大（だい）学（がく）｜で　｜＾勉（べん）強（きょう）｜して　います。",
                normal: "彼は　働きながら　大学で　勉強して　います。",
                en: "He is studying at university while working.",
                mm: "သူက အလုပ်လုပ်ရင်းနဲ့ တက္ကသိုလ်မှာ ပညာသင်ကြားနေပါတယ်။",
            },
            {
                furi: "。。。そうですか。｜＾偉（えら）｜いですね。",
                normal: "。。。そうですか。偉いですね。",
                en: ". . . is that so. great.",
                mm: ". . . ဟုတ်လား။ အရမ်းတော်လိုက်တာ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson28/bunpou/4N28RB3.mp3")
    },
    {
        sentence: [
            {
                furi: "＾休（やす）｜みの　｜＾日（ひ）｜は　いつも　｜！何（なに）をして｜　いますか。",
                normal: "休みの　日は　いつも　何をして　いますか。",
                en: "What do you usually do on your days off?",
                mm: "အားလပ်ရက်တွေမှာ ဘာလုပ်လေ့ရှိလဲ။",
            },
            {
                furi: "。。。そうですね。　たいてい　｜＾絵（え）｜を　｜！書（か）いて｜　います。",
                normal: "。。。そうですね。　たいてい　絵を　書いて　います。",
                en: ". . . I agree. Mostly painting and he is there.",
                mm: "...အင်း၊ များသောအားဖြင့် ပုံဆွဲလေ့ ရှိပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson28/bunpou/4N28RB4.mp3")
    },
    {
        sentence: [
            {
                furi: "ワット｜＾先（せん）生（せい）｜は　｜！熱（ねっ）心（しん）だし｜、｜！面（おも）白（しろ）いし｜、　それに　｜＾経（けい）験（けん）｜も　あります。",
                normal: "ワット先生は　熱心だし、面白いし、　それに　経験も　あります。",
                en: "Professor Watt is enthusiastic, funny, and experienced.",
                mm: "ဆရာဝပ်တိုက ရိုးသားတည်ကြည်တယ်၊ စိတ်ဝင်စားစရာကောင်းပြီးတော့ အတွေ့အကြုံတွေလည်းရှိပါတယ်။",
            },
            {
                furi: "。。。いい　｜＾先（せん）生（せい）｜ですね。",
                normal: "。。。いい　先生ですね。",
                en: ". . . Good. It's a teacher.",
                mm: "...ဆရာကောင်းတစ်ယောက်ပဲနော်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson28/bunpou/4N28RB5.mp3")
    },
    {
        sentence: [
            {
                furi: "よく　この　すし｜＾屋（や）｜べ　｜＾来（く）｜るんですか。",
                normal: "よく　この　すし屋べ　来るんですか。",
                en: "Do you often come to this sushi restaurant?",
                mm: "မင်းက ဒီဆူရှီဆိုင်ကို မကြာခဏရောက်ဖူးလား။",
            },
            {
                furi: "。。。ええ。ここは　｜＾値（ね）段（だん）｜も　｜！安（やす）いし｜、　｜＾魚（さかな）｜も　｜！新（あたら）しいし｜、　よく｜＾食（た）｜べに　｜＾来（き）｜ます。",
                normal: "。。。ええ。ここは　値段も　安いし、　魚も　新しいし、　よく食べに　来ます。",
                en: ". . . yes. The prices here are cheap, the fish are new, and I often come here to eat.",
                mm: "...အင်း၊ အဲ့ဒီမှာက ဈေးလည်းသက်သာတယ်၊ ငါးကလည်းလတ်ဆတ်တယ် အမြဲသွားစားဖြစ်ပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson28/bunpou/4N28RB6.mp3")
    },
    {
        sentence: [
            {
                furi: "どうして　｜＾富（ふ）士（じ）大（だい）学（がく）｜を　｜＾選（えら）｜んだんですか。",
                normal: "どうして　富士大学を　選んだんですか。",
                en: "Why did you choose Fuji University?",
                mm: "ဘာကြောင့် ဖူဂျီတက္ကသိုလ်ကိုရွေးချယ်တာလည်း?",
            },
            {
                furi: "。。。｜＾富（ふ）士（じ）大（だい）学（がく）｜は　｜！有（ゆう）名（めい）だし｜、　いい　｜＾先（せん）生（せい）｜も　｜！多いし｜、　｜＾寮（りょう）｜も　ありますから。",
                normal: "。。。富士大学は　有名だし、　いい　先生も　多いし、　寮も　ありますから。",
                en: ". . . Fuji University is famous, there are many good teachers, and he has a dormitory.",
                mm: ". . . ဖူဂျီတက္ကသိုလ်က နာမည်လည်းကြီးတယ်၊ ဆရာကောင်းများစွာရှိပြီး အိပ်ဆောင်လည်းရှိတာမို့လို့ပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson28/bunpou/4N28RB7.mp3")
    },
];

/*

{
    furi: "",
    normal: "",
    en: "",
    mm: "",
},

*/