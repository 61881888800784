export const lesson44 = [
  {
      "furi": "＾泣（な）｜きます",
      "normal": "泣きます",
      "en": "cry",
      "remark_en": "",
      "mm": "ငိုသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO1.mp3")
  },
  {
      "furi": "＾笑（わら）｜います",
      "normal": "笑います",
      "en": "laugh, smile",
      "remark_en": "",
      "mm": "ရယ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO2.mp3")
  },
  {
      "furi": "＾眠（ねむ）｜ります",
      "normal": "眠ります",
      "en": "sleeping",
      "remark_en": "",
      "mm": "အိပ်ပျော်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO3.mp3")
  },
  {
      "furi": "＾乾（かわ）｜きます",
      "normal": "乾きます",
      "en": "dry",
      "remark_en": "",
      "mm": "ခြောက်သွေ့သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO4.mp3")
  },
  {
      "furi": "ぬれます",
      "normal": "ぬれます",
      "en": "get wet",
      "remark_en": "",
      "mm": "စိုသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO5.mp3")
  },
  {
      "furi": "＾滑（すべ）｜ります",
      "normal": "滑ります",
      "en": "slip",
      "remark_en": "",
      "mm": "ချောသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO6.mp3")
  },
  {
      "furi": "＾起（お）｜きます",
      "normal": "起きます",
      "en": "happen",
      "remark_en": "",
      "mm": "ဖြစ်ပွားသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO7.mp3")
  },
  {
      "furi": "＾調（ちょう）節（せつ）｜します",
      "normal": "調節します",
      "en": "adjust",
      "remark_en": "",
      "mm": "ညှိသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO8.mp3")
  },
  {
      "furi": "＾安（あん）全（ぜん）｜[な]",
      "normal": "安全[な]",
      "en": "safe",
      "remark_en": "",
      "mm": "အန္တရာယ်ကင်းသော၊လုံခြုံသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO9.mp3")
  },
  {
      "furi": "＾危（き）険（けん）｜[な]",
      "normal": "危険[な]",
      "en": "dangerous",
      "remark_en": "",
      "mm": "အန္တရာယ်ရှိသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO10.mp3")
  },
  {
      "furi": "＾濃（こ）｜い",
      "normal": "濃い",
      "en": "strong (taste), dark (color)",
      "remark_en": "",
      "mm": "အရသာလေးသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO11.mp3")
  },
  {
      "furi": "＾薄（うす）｜い",
      "normal": "薄い",
      "en": "weak (taste), light (color)",
      "remark_en": "",
      "mm": "အရသာပေါ့သော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO12.mp3")
  },
  {
      "furi": "＾厚（あつ）｜い",
      "normal": "厚い",
      "en": "thick",
      "remark_en": "",
      "mm": "ထူသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO13.mp3")
  },
  {
      "furi": "＾太（ふと）｜い",
      "normal": "太い",
      "en": "thick",
      "remark_en": "",
      "mm": "ဝသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO14.mp3")
  },
  {
      "furi": "＾細（ほそ）｜い",
      "normal": "細い",
      "en": "thin",
      "remark_en": "",
      "mm": "ပိန်သော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO15.mp3")
  },
  {
      "furi": "＾空（くう）気（き）",
      "normal": "空気",
      "en": "air",
      "remark_en": "",
      "mm": "လေထု",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO16.mp3")
  },
  {
      "furi": "＾涙（なみだ）",
      "normal": "涙",
      "en": "tear",
      "remark_en": "",
      "mm": "မျက်ရည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO17.mp3")
  },
  {
      "furi": "＾和（わ）食（しょく）",
      "normal": "和食",
      "en": "Japanese dish",
      "remark_en": "",
      "mm": "ဂျပန်အစားအစာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO18.mp3")
  },
  {
      "furi": "＾洋（よう）食（しょく）",
      "normal": "洋食",
      "en": "Western dish",
      "remark_en": "",
      "mm": "အနောက်တိုင်းစာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO19.mp3")
  },
  {
      "furi": "おかず",
      "normal": "おかず",
      "en": "side dish",
      "remark_en": "",
      "mm": "ဟင်းလျာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO20.mp3")
  },
  {
      "furi": "＾量（りょう）",
      "normal": "量",
      "en": "quantity",
      "remark_en": "",
      "mm": "ပမာဏ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO21.mp3")
  },
  {
      "furi": "～｜＾倍（ばい）",
      "normal": "～倍",
      "en": "times",
      "remark_en": "",
      "mm": "-ဆ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO22.mp3")
  },
  {
      "furi": "＾シングル（single）",
      "normal": "シングル",
      "en": "single room",
      "remark_en": "",
      "mm": "တစ်ယောက်တည်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO23.mp3")
  },
  {
      "furi": "＾ツイン（twin）",
      "normal": "ツイン",
      "en": "twin-bedded room",
      "remark_en": "",
      "mm": "နှစ်ယောက်၊နှစ်ခု",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO24.mp3")
  },
  {
      "furi": "＾洗（せん）濯（たく）｜もの",
      "normal": "洗濯もの",
      "en": "washing, laundry",
      "remark_en": "",
      "mm": "လျှော်စရာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO25.mp3")
  },
  {
      "furi": "DVD",
      "normal": "DVD",
      "en": "DVD",
      "remark_en": "",
      "mm": "ဒီဗွီဒီ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO26.mp3")
  },
  {
      "furi": "ホテルひろしま",
      "normal": "ホテルひろしま",
      "en": "Hotel Hiroshima",
      "remark_en": "",
      "mm": "ဟိုတယ်ဟီရိုရှီးမား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO27.mp3")
  },
  {
      "furi": "どうなさいますか。",
      "normal": "どうなさいますか。",
      "en": "What can I do for you?",
      "remark_en": "",
      "mm": "ဘယ်လိုလုပ်ချင်ပါသလဲ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO28.mp3")
  },
  {
      "furi": "カット",
      "normal": "カット",
      "en": "haircut",
      "remark_en": "",
      "mm": "ဆံပင်ညှပ်ခြင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO29.mp3")
  },
  {
      "furi": "シャンプー",
      "normal": "シャンプー",
      "en": "shampoo",
      "remark_en": "",
      "mm": "ခေါင်းလျှော်ခြင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO30.mp3")
  },
  {
      "furi": "どういうふうになさいますか。",
      "normal": "どういうふうになさいますか。",
      "en": "How would you like it done?",
      "remark_en": "",
      "mm": "ဘယ်လိုပုံစံလုပ်ချင်ပါသလဲ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO31.mp3")
  },
  {
      "furi": "ショート",
      "normal": "ショート",
      "en": "short",
      "remark_en": "",
      "mm": "အတို(ဆံပင်)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO32.mp3")
  },
  {
      "furi": "～みたいにしてください。",
      "normal": "～みたいにしてください。",
      "en": "Do it like ~",
      "remark_en": "",
      "mm": "-ပုံစံမျိုးလုပ်ပေးပါ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO33.mp3")
  },
  {
      "furi": "これでよろしいでしょうか。",
      "normal": "これでよろしいでしょうか。",
      "en": "Would this be all right?",
      "remark_en": "",
      "mm": "ဒီလောက်ဆိုရပြီလား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO34.mp3")
  },
  {
      "furi": "[どうも]お｜＾疲（つか）｜れさまでした。",
      "normal": "[どうも]お疲れさまでした。",
      "en": "Thank you for being patient",
      "remark_en": "",
      "mm": "ပင်ပန်းသွားပြီနော်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson44/vocab/4N44VO35.mp3")
  },
  {
      "furi": "＾嫌（いや）｜がります",
      "normal": "嫌がります",
      "en": "dislike",
      "remark_en": "",
      "mm": "မနှစ်မမြို့ဖြစ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "また",
      "normal": "また",
      "en": "again",
      "remark_en": "",
      "mm": "နောက်တစ်ဖန်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "うまく",
      "normal": "うまく",
      "en": "successfully",
      "remark_en": "",
      "mm": "ကျွမ်းကျင်စွာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾順（じゅん）序（じょ）",
      "normal": "順序",
      "en": "order",
      "remark_en": "",
      "mm": "အစီအစဉ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾安（あん）心（しん）｜[な]",
      "normal": "安心[な]",
      "en": "a worry-free",
      "remark_en": "",
      "mm": "စိတ်ချရသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾表（ひょう）現（げん）",
      "normal": "表現",
      "en": "expression",
      "remark_en": "",
      "mm": "ဖော်ပြမှု",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾例（とと）｜えば",
      "normal": "例えば",
      "en": "for example",
      "remark_en": "",
      "mm": "ဥပမာအားဖြင့်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾別（わか）｜れます",
      "normal": "別れます",
      "en": "part, separate",
      "remark_en": "",
      "mm": "ကွဲကွာသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "これら",
      "normal": "これら",
      "en": "these things",
      "remark_en": "",
      "mm": "ဒါတွေ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾縁（えん）起（ぎ）｜が｜＾悪（わる）｜い",
      "normal": "縁起が悪い",
      "en": "unlucky, ill-omened",
      "remark_en": "",
      "mm": "ကံကြမ္မာဆိုးသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  }
]

/*
    {
        furi: "",
        normal: "",
        en: "",
        remark_en: "",
        mm: "",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson29/vocab/4N29VO1.mp3")
    },


*/
const test = [
  {
    'goi': '泣（な）」きます',
    'unicode': 'ငိုသည်',
    'zawgyi': 'ငိုသည္',
    'english': 'cry',
    'long': false
  },
  {
    'goi': '笑（わら）」います',
    'unicode': 'ရယ်သည်',
    'zawgyi': 'ရယ္သည္',
    'english': 'laugh, smile',
    'long': false
  },
  {
    'goi': '眠（ねむ）」ります',
    'unicode': 'အိပ်ပျော်သည်',
    'zawgyi': 'အိပ္ေပ်ာ္သည္',
    'english': 'sleeping',
    'long': false
  },
  {
    'goi': '乾（かわ）」きます',
    'unicode': 'ခြောက်သွေ့သည်',
    'zawgyi': 'ေျခာက္ေသြ႕သည္',
    'english': 'dry',
    'long': false
  },
  {
    'goi': 'ぬれます',
    'unicode': 'စိုသည်',
    'zawgyi': 'စိုသည္',
    'english': 'get wet',
    'long': false
  },
  {
    'goi': '滑（すべ）」ります',
    'unicode': 'ချောသည်',
    'zawgyi': 'ေခ်ာသည္',
    'english': 'slip',
    'long': false
  },
  {
    'goi': '起（お）」きます',
    'unicode': 'ဖြစ်ပွားသည်',
    'zawgyi': 'ျဖစ္ပြားသည္',
    'english': 'happen',
    'long': false
  },
  {
    'goi': '調（ちょう）」節（せつ）」します',
    'unicode': 'ညှိသည်',
    'zawgyi': 'ညႇိသည္',
    'english': 'adjust',
    'long': false
  },
  {
    'goi': '安（あん）」全（ぜん）」[な]',
    'unicode': 'အန္တရာယ်ကင်းသော၊လုံခြုံသော',
    'zawgyi': 'အႏၲရာယ္ကင္းေသာ၊လုံျခဳံေသာ',
    'english': 'safe',
    'long': false
  },
  {
    'goi': '危（き）」険（けん）」[な]',
    'unicode': 'အန္တရာယ်ရှိသော',
    'zawgyi': 'အႏၲရာယ္ရွိေသာ',
    'english': 'dangerous',
    'long': false
  },
  {
    'goi': '濃（こ）」い',
    'unicode': 'အရသာလေးသော',
    'zawgyi': 'အရသာေလးေသာ',
    'english': 'strong (taste), dark (color)',
    'long': false
  },
  {
    'goi': '薄（うす）」い',
    'unicode': 'အရသာပေါ့သော',
    'zawgyi': 'အရသာေပါ့ေသာ',
    'english': 'weak (taste), light (color)',
    'long': false
  },
  {
    'goi': '厚（あつ）」い',
    'unicode': 'ထူသော',
    'zawgyi': 'ထူေသာ',
    'english': 'thick',
    'long': false
  },
  {
    'goi': '太（ふと）」い',
    'unicode': 'ဝသော',
    'zawgyi': 'ဝေသာ',
    'english': 'thick',
    'long': false
  },
  {
    'goi': '細（ほそ）」い',
    'unicode': 'ပိန်သော',
    'zawgyi': 'ပိန္ေသာ',
    'english': 'thin',
    'long': false
  },
  {
    'goi': '空（くう）」気（き）',
    'unicode': 'လေထု',
    'zawgyi': 'ေလထု',
    'english': 'air',
    'long': false
  },
  {
    'goi': '涙（なみだ）',
    'unicode': 'မျက်ရည်',
    'zawgyi': 'မ်က္ရည္',
    'english': 'tear',
    'long': false
  },
  {
    'goi': '和（わ）」食（しょく）',
    'unicode': 'ဂျပန်အစားအစာ',
    'zawgyi': 'ဂ်ပန္အစားအစာ',
    'english': 'Japanese dish',
    'long': false
  },
  {
    'goi': '洋（よう）」食（しょく）',
    'unicode': 'အနောက်တိုင်းစာ',
    'zawgyi': 'အေနာက္တိုင္းစာ',
    'english': 'Western dish',
    'long': false
  },
  {
    'goi': 'おかず',
    'unicode': 'ဟင်းလျာ',
    'zawgyi': 'ဟင္းလ်ာ',
    'english': 'side dish',
    'long': false
  },
  {
    'goi': '量（りょう）',
    'unicode': 'ပမာဏ',
    'zawgyi': 'ပမာဏ',
    'english': 'quantity',
    'long': false
  },
  {
    'goi': '～」倍（ばい）',
    'unicode': '-ဆ',
    'zawgyi': '-ဆ',
    'english': 'times',
    'long': false
  },
  {
    'goi': 'シングル（single）',
    'unicode': 'တစ်ယောက်တည်း',
    'zawgyi': 'တစ္ေယာက္တည္း',
    'english': 'single room',
    'long': false
  },
  {
    'goi': 'ツイン（twin）',
    'unicode': 'နှစ်ယောက်၊နှစ်ခု',
    'zawgyi': 'ႏွစ္ေယာက္၊ႏွစ္ခု',
    'english': 'twin-bedded room',
    'long': false
  },
  {
    'goi': '洗（せん）」濯（たく）」もの',
    'unicode': 'လျှော်စရာ',
    'zawgyi': 'ေလၽွာ္စရာ',
    'english': 'washing, laundry',
    'long': false
  },
  {
    'goi': 'DVD',
    'unicode': 'ဒီဗွီဒီ',
    'zawgyi': 'ဒီဗြီဒီ',
    'english': 'DVD',
    'long': false
  },
  {
    'goi': 'ホテルひろしま',
    'unicode': 'ဟိုတယ်ဟီရိုရှီးမား',
    'zawgyi': 'ဟိုတယ္ဟီရိုရွီးမား',
    'english': 'Hotel Hiroshima',
    'long': false
  },
  {
    'goi': 'どうなさいますか。',
    'unicode': 'ဘယ်လိုလုပ်ချင်ပါသလဲ',
    'zawgyi': 'ဘယ္လိုလုပ္ခ်င္ပါသလဲ',
    'english': 'What can I do for you?',
    'long': true
  },
  {
    'goi': 'カット',
    'unicode': 'ဆံပင်ညှပ်ခြင်း',
    'zawgyi': 'ဆံပင္ညႇပ္ျခင္း',
    'english': 'haircut',
    'long': false
  },
  {
    'goi': 'シャンプー',
    'unicode': 'ခေါင်းလျှော်ခြင်း',
    'zawgyi': 'ေခါင္းေလၽွာ္ျခင္း',
    'english': 'shampoo',
    'long': false
  },
  {
    'goi': 'どういうふうになさいますか。',
    'unicode': 'ဘယ်လိုပုံစံလုပ်ချင်ပါသလဲ',
    'zawgyi': 'ဘယ္လိုပုံစံလုပ္ခ်င္ပါသလဲ',
    'english': 'How would you like it done?',
    'long': true
  },
  {
    'goi': 'ショート',
    'unicode': 'အတို(ဆံပင်)',
    'zawgyi': 'အတို(ဆံပင္)',
    'english': 'short',
    'long': false
  },
  {
    'goi': '～みたいにしてください。',
    'unicode': '-ပုံစံမျိုးလုပ်ပေးပါ',
    'zawgyi': '-ပုံစံမ်ိဳးလုပ္ေပးပါ',
    'english': 'Do it like ~',
    'long': true
  },
  {
    'goi': 'これでよろしいでしょうか。',
    'unicode': 'ဒီလောက်ဆိုရပြီလား',
    'zawgyi': 'ဒီေလာက္ဆိုရၿပီလား',
    'english': 'Would this be all right?',
    'long': true
  },
  {
    'goi': '[どうも]お」疲（つか）」れさまでした。',
    'unicode': 'ပင်ပန်းသွားပြီနော်',
    'zawgyi': 'ပင္ပန္းသြားၿပီေနာ္',
    'english': 'Thank you for being patient',
    'long': true
  },
  {
    'goi': '嫌（いや）」がります',
    'unicode': 'မနှစ်မမြို့ဖြစ်သည်',
    'zawgyi': 'မႏွစ္မၿမိဳ႕ျဖစ္သည္',
    'english': 'dislike',
    'long': false
  },
  {
    'goi': 'また',
    'unicode': 'နောက်တစ်ဖန်',
    'zawgyi': 'ေနာက္တစ္ဖန္',
    'english': 'again',
    'long': false
  },
  {
    'goi': 'うまく',
    'unicode': 'ကျွမ်းကျင်စွာ',
    'zawgyi': 'ကၽြမ္းက်င္စြာ',
    'english': 'successfully',
    'long': false
  },
  {
    'goi': '順（じゅん）」序（じょ）',
    'unicode': 'အစီအစဉ်',
    'zawgyi': 'အစီအစဥ္',
    'english': 'order',
    'long': false
  },
  {
    'goi': '安（あん）」心（しん）」[な]',
    'unicode': 'စိတ်ချရသော',
    'zawgyi': 'စိတ္ခ်ရေသာ',
    'english': 'a worry-free',
    'long': false
  },
  {
    'goi': '表（ひょう）」現（げん）',
    'unicode': 'ဖော်ပြမှု',
    'zawgyi': 'ေဖာ္ျပမႈ',
    'english': 'expression',
    'long': false
  },
  {
    'goi': '例（とと）」えば',
    'unicode': 'ဥပမာအားဖြင့်',
    'zawgyi': 'ဥပမာအားျဖင့္',
    'english': 'for example',
    'long': false
  },
  {
    'goi': '別（わか）」れます',
    'unicode': 'ကွဲကွာသည်',
    'zawgyi': 'ကြဲကြာသည္',
    'english': 'part, separate',
    'long': false
  },
  {
    'goi': 'これら',
    'unicode': 'ဒါတွေ',
    'zawgyi': 'ဒါေတြ',
    'english': 'these things',
    'long': false
  },
  {
    'goi': '縁（えん）」起（ぎ）」が」悪（わる）」い',
    'unicode': 'ကံကြမ္မာဆိုးသော',
    'zawgyi': 'ကံၾကမၼာဆိုးေသာ',
    'english': 'unlucky, ill-omened',
    'long': false
  }
]