export const bunkei= [
    {
        sentence: [
            {
                furi: "JL107｜＾便（びん）｜に　｜＾何（なん）時（じ）｜に　｜＾到（とう）着（ちゃく）｜するか、｜＾調（しら）｜べて　ください。",
                normal: "JL107便に　何時に　到着するか、調べて　ください。",
                en: "Please find out what time flight JL107 will arrive.",
                mm: "လေယာဉ် JL107 ဆိုက်ရောက်မည့်အချိန်ကို ရှာ‌ပေးပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson40/bunpou/4N40BK1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾台（たい）風（ふう）｜９｜＾号（ごう）｜は　｜＾東（とう）京（きょう）｜へ　｜＾来（く）｜るか　どうか、まだ　わかりません。",
                normal: "台風９号は　東京へ　来るか　どうか、まだ　わかりません。",
                en: "I still don't know if Typhoon No. 9 will come to Tokyo or not.",
                mm: "တိုင်ဖွန်း နံပါတ် ၉ က တိုကျိုကို လာမလာ မသိသေးဘူး။",
            },
        ],
        audioUrl: require("@assets/audio/lesson40/bunpou/4N40BK2.mp3")
    },
    {
        sentence: [
            {
                furi: "この　｜＾服（ふく）｜を　｜＾着（き）｜て　みても　いいですか。",
                normal: "この　服を　着て　みても　いいですか。",
                en: "Is it okay if I try on this outfit?",
                mm: "ဒီဝတ်စုံကို စမ်းဝတ်ကြည့်လို့ အဆင်ပြေလား။",
            },
        ],
        audioUrl: require("@assets/audio/lesson40/bunpou/4N40BK3.mp3")
    },
]