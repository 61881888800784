export const explain= [
    {
        // ＾友（とも）達（だち）｜
        pattern_furi: '～そうです',
        pattern_normal: '～そうです',
        mm: [
            "　-မဲ့ပုံဘဲ  ",
            "　(အကြည့်အမြင်ဖြင့် မှန်းဆ၍ပြောခြင်း)",
        ],
        en: [ 
            '　look',
            '　(Guessing by looking)',
        ]
    },   
    {
        pattern_furi: '～てきます',
        pattern_normal: '～てきます',
        mm: [
            "　–လာမယ် ",
        ],
        en: [ 
            '　- will come',
        ]
    },   
    {
        pattern_furi: 'Vる+つもりです',
        pattern_normal: 'Vる+つもりです',
        mm: [
            "　ရည်ရွယ်တယ် ",
            "　(လုပ်ဖို့စိတ်ကူးထားသည်) ",
        ],
        en: [ 
            'intended',
            '(plan to do)',
        ]
    },   
]