import { ExampleTile } from "@screens/home/category/KanjiDetailScreen"
import { memo } from "react"

const KanjiExampleWebComponent = ({ example,furigana ,furiTranslate,fontSize,kanjiDetail,lang}:any) => {
    return <div className="relative hidden md:flex flex-col dark:text-white ">
        <div className={`absolute left-0 px-[18px] py-[8px] rounded-r-[100px] text-[${fontSize}px] bg-[#0084E8] bunkei-title-light text-white w-fit`}>
            Example
        </div>
        <div className="mt-[70px]">
                {
                    example.map(({ furi, normal, en, mm }:any,i:number) => {
                        return (<div key={Math.random()}>
                            {<ExampleTile sentence={furigana === 'true' ?
                                furiTranslate({ context: furi, fontSize, style: "flex-wrap items-center", kanjiDetail }) : normal}
                                meaning={lang == 'en' ? en : mm}
                                fontSize={fontSize}
                                furigana={furigana}
                                i={i}
                            />}
                        </div>)
                    })
                }
            </div>
        </div>
}
export default memo(KanjiExampleWebComponent);