export const lesson50 = [
  {
      "furi": "＾参（まい）｜ります",
      "normal": "参ります",
      "en": "go, come",
      "remark_en": "",
      "mm": "သွားသည်၊လာသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO1.mp3")
  },
  {
      "furi": "おります",
      "normal": "おります",
      "en": "be(=います)",
      "remark_en": "",
      "mm": "ရှိသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO2.mp3")
  },
  {
      "furi": "いただきます",
      "normal": "いただきます",
      "en": "eat, drink, receive",
      "remark_en": "",
      "mm": "စားသောက်သည်၊လက်ခံရယူသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO3.mp3")
  },
  {
      "furi": "＾申（もう）｜します",
      "normal": "申します",
      "en": "say(=いいます)",
      "remark_en": "",
      "mm": "ပြောသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO4.mp3")
  },
  {
      "furi": "いたします",
      "normal": "いたします",
      "en": "do(=します)",
      "remark_en": "",
      "mm": "လုပ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO5.mp3")
  },
  {
      "furi": "＾拝（はい）見（けん）｜します",
      "normal": "拝見します",
      "en": "see(=みます)",
      "remark_en": "",
      "mm": "ကြည့်သည်၊မြင်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO6.mp3")
  },
  {
      "furi": "＾存（ぞん）｜じます",
      "normal": "存じます",
      "en": "know(=しります)",
      "remark_en": "",
      "mm": "သိသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO7.mp3")
  },
  {
      "furi": "＾伺（うかが）｜います",
      "normal": "伺います",
      "en": "ask,hear,visit(=ききます,いきます )",
      "remark_en": "",
      "mm": "မေးသည်၊သွားသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO8.mp3")
  },
  {
      "furi": "お｜＾目（め）｜にかかります",
      "normal": "お目にかかります",
      "en": "meet(=あいます)",
      "remark_en": "",
      "mm": "တွေ့သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO9.mp3")
  },
  {
      "furi": "＾入（い）｜れます",
      "normal": "入れます",
      "en": "put",
      "remark_en": "",
      "mm": "ထည့်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO10.mp3")
  },
  {
      "furi": "＾用（よう）意（い）｜します",
      "normal": "用意します",
      "en": "prepare",
      "remark_en": "",
      "mm": "ကြိုတင်ပြင်ဆင်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO11.mp3")
  },
  {
      "furi": "＾私（わたくし）",
      "normal": "私",
      "en": "i",
      "remark_en": "",
      "mm": "ကျွန်ပ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO12.mp3")
  },
  {
      "furi": "ガイド",
      "normal": "ガイド",
      "en": "guide",
      "remark_en": "",
      "mm": "လမ်းညွှန်သူ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO13.mp3")
  },
  {
      "furi": "メールアドレス",
      "normal": "メールアドレス",
      "en": "email address",
      "remark_en": "",
      "mm": "အီးမေးလ်လိပ်စာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO14.mp3")
  },
  {
      "furi": "スケジュール",
      "normal": "スケジュール",
      "en": "schedule",
      "remark_en": "",
      "mm": "အချိန်ဇယား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO15.mp3")
  },
  {
      "furi": "さ｜＾来（らい）｜＾週（しゅう）",
      "normal": "さ来週",
      "en": "the week after next",
      "remark_en": "",
      "mm": "နောက်အပတ်ရဲ့နောက်တစ်ပတ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO16.mp3")
  },
  {
      "furi": "さ｜＾来（らい）｜＾月（げつ）",
      "normal": "さ来月",
      "en": "the month after next",
      "remark_en": "",
      "mm": "နောက်လရဲ့နောက်တစ်လ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO17.mp3")
  },
  {
      "furi": "さ｜＾来（らい）｜＾年（ねん）",
      "normal": "さ来年",
      "en": "the year after next",
      "remark_en": "",
      "mm": "နောက်နှစ်ရဲ့နောက်တစ်နှစ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO18.mp3")
  },
  {
      "furi": "＾初（はじ）｜めに",
      "normal": "初めに",
      "en": "at first",
      "remark_en": "",
      "mm": "ရှေးဦးစွာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO19.mp3")
  },
  {
      "furi": "＾江（え）戸（ど）東（とう）京（きょう）博（はく）物（ぶつ）館（かん）",
      "normal": "江戸東京博物館",
      "en": "Edo Tokyo Museum",
      "remark_en": "",
      "mm": "အဲဒိုတိုကျိုပြတိုက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO20.mp3")
  },
  {
      "furi": "＾緊（きん）張（ちょう）｜します",
      "normal": "緊張します",
      "en": "become tense, be strained",
      "remark_en": "",
      "mm": "စိတ်ကျဉ်းကျပ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO21.mp3")
  },
  {
      "furi": "＾賞（しょう）金（きん）",
      "normal": "賞金",
      "en": "prize money",
      "remark_en": "",
      "mm": "ဆုကြေးငွေ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO22.mp3")
  },
  {
      "furi": "きりん",
      "normal": "きりん",
      "en": "giraffe",
      "remark_en": "",
      "mm": "သစ်ကုလားအုပ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO23.mp3")
  },
  {
      "furi": "ころ",
      "normal": "ころ",
      "en": "times, days",
      "remark_en": "",
      "mm": "တုန်းက",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO24.mp3")
  },
  {
      "furi": "かないます",
      "normal": "かないます",
      "en": "(dream) be realized",
      "remark_en": "",
      "mm": "ဆန္ဒပြည့်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO25.mp3")
  },
  {
      "furi": "＾応（おう）援（えん）｜します",
      "normal": "応援します",
      "en": "I support you",
      "remark_en": "",
      "mm": "အားပေးသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO26.mp3")
  },
  {
      "furi": "＾心（こころ）｜から",
      "normal": "心から",
      "en": "from my heart",
      "remark_en": "",
      "mm": "နှလုံးသားထဲမှ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO27.mp3")
  },
  {
      "furi": "＾感（かん）謝（しゃ）｜します",
      "normal": "感謝します",
      "en": "be grateful, be thankful",
      "remark_en": "",
      "mm": "ကျေးဇူးတင်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson50/vocab/4N50VO28.mp3")
  },
  {
      "furi": "お｜＾礼（れい）",
      "normal": "お礼",
      "en": "gratitude, thanks",
      "remark_en": "",
      "mm": "ကျေးဇူးတုံ့ပြန်ခြင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "お｜＾元（げん）｜＾気（き）｜でいらっしゃいますか。",
      "normal": "お元気でいらっしゃいますか。",
      "en": "How are you doing?",
      "remark_en": "",
      "mm": "နေကောင်းပါသလား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾迷（めい）惑（わく）｜をかけます",
      "normal": "迷惑をかけます",
      "en": "trouble, annoy, inconvenience",
      "remark_en": "",
      "mm": "အနှောင့်အယှက်ပေးမိသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾生（い）｜かします",
      "normal": "生かします",
      "en": "make good use of",
      "remark_en": "",
      "mm": "အသုံးချသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "ミュンヘン",
      "normal": "ミュンヘン",
      "en": "Munich(in Germany)",
      "remark_en": "",
      "mm": "မြူဟန်ဒေသ(ဂျာမဏီမှ)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  }
]

/*
    {
        furi: "",
        normal: "",
        en: "",
        remark_en: "",
        mm: "",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson29/vocab/4N29VO1.mp3")
    },


*/
const test = [
  {
    'goi': '参（まい）」ります',
    'unicode': 'သွားသည်၊လာသည်',
    'zawgyi': 'သြားသည္၊လာသည္',
    'english': 'go, come',
    'long': true
  },
  {
    'goi': 'おります',
    'unicode': 'ရှိသည်',
    'zawgyi': 'ရွိသည္',
    'english': 'be(=います)',
    'long': false
  },
  {
    'goi': 'いただきます',
    'unicode': 'စားသောက်သည်၊လက်ခံရယူသည်',
    'zawgyi': 'စားေသာက္သည္၊လက္ခံရယူသည္',
    'english': 'eat, drink, receive',
    'long': true
  },
  {
    'goi': '申（もう）」します',
    'unicode': 'ပြောသည်',
    'zawgyi': 'ေျပာသည္',
    'english': 'say(=いいます)',
    'long': false
  },
  {
    'goi': 'いたします',
    'unicode': 'လုပ်သည်',
    'zawgyi': 'လုပ္သည္',
    'english': 'do(=します)',
    'long': false
  },
  {
    'goi': '拝（はい）」見（けん）」します',
    'unicode': 'ကြည့်သည်၊မြင်သည်',
    'zawgyi': 'ၾကည့္သည္၊ျမင္သည္',
    'english': 'see(=みます)',
    'long': false
  },
  {
    'goi': '存（ぞん）」じます',
    'unicode': 'သိသည်',
    'zawgyi': 'သိသည္',
    'english': 'know(=しります)',
    'long': false
  },
  {
    'goi': '伺（うかが）」います',
    'unicode': 'မေးသည်၊သွားသည်',
    'zawgyi': 'ေမးသည္၊သြားသည္',
    'english': 'ask,hear,visit(=ききます,いきます )',
    'long': true
  },
  {
    'goi': 'お」目（め）」にかかります',
    'unicode': 'တွေ့သည်',
    'zawgyi': 'ေတြ႕သည္',
    'english': 'meet(=あいます)',
    'long': false
  },
  {
    'goi': '入（い）」れます',
    'unicode': 'ထည့်သည်',
    'zawgyi': 'ထည့္သည္',
    'english': 'put',
    'long': false
  },
  {
    'goi': '用（よう）」意（い）」します',
    'unicode': 'ကြိုတင်ပြင်ဆင်သည်',
    'zawgyi': 'ႀကိဳတင္ျပင္ဆင္သည္',
    'english': 'prepare',
    'long': false
  },
  {
    'goi': '私（わたくし）',
    'unicode': 'ကျွန်ပ်',
    'zawgyi': 'ကၽြန္ပ္',
    'english': 'i',
    'long': false
  },
  {
    'goi': 'ガイド',
    'unicode': 'လမ်းညွှန်သူ',
    'zawgyi': 'လမ္းညႊန္သူ',
    'english': 'guide',
    'long': false
  },
  {
    'goi': 'メールアドレス',
    'unicode': 'အီးမေးလ်လိပ်စာ',
    'zawgyi': 'အီးေမးလ္လိပ္စာ',
    'english': 'email address',
    'long': false
  },
  {
    'goi': 'スケジュール',
    'unicode': 'အချိန်ဇယား',
    'zawgyi': 'အခ်ိန္ဇယား',
    'english': 'schedule',
    'long': false
  },
  {
    'goi': 'さ」来（らい）」週（しゅう）',
    'unicode': 'နောက်အပတ်ရဲ့နောက်တစ်ပတ်',
    'zawgyi': 'ေနာက္အပတ္ရဲ့ေနာက္တစ္ပတ္',
    'english': 'the week after next',
    'long': false
  },
  {
    'goi': 'さ」来（らい）」月（げつ）',
    'unicode': 'နောက်လရဲ့နောက်တစ်လ',
    'zawgyi': 'ေနာက္အပတ္ရဲ့ေနာကၱစၸတ္',
    'english': 'the month after next',
    'long': false
  },
  {
    'goi': 'さ」来（らい）」年（ねん）',
    'unicode': 'နောက်နှစ်ရဲ့နောက်တစ်နှစ်',
    'zawgyi': 'ေနာက္ႏွစ္ရဲ့ေနာက္တစ္ႏွစ္',
    'english': 'the year after next',
    'long': false
  },
  {
    'goi': '初（はじ）」めに',
    'unicode': 'ရှေးဦးစွာ',
    'zawgyi': 'ေရွးဦးစြာ',
    'english': 'at first',
    'long': false
  },
  {
    'goi': '江（え）」戸（ど）」東（とう）」京（きょう）」博（はく）」物（ぶつ）」館（かん）',
    'unicode': 'အဲဒိုတိုကျိုပြတိုက်',
    'zawgyi': 'အဲဒိုတိုက်ိဳျပတိုက္',
    'english': 'Edo Tokyo Museum',
    'long': false
  },
  {
    'goi': '緊（きん）」張（ちょう）」します',
    'unicode': 'စိတ်ကျဉ်းကျပ်သည်',
    'zawgyi': 'စိတ္က်ဥ္းက်ပ္သည္',
    'english': 'become tense, be strained',
    'long': false
  },
  {
    'goi': '賞（しょう）」金（きん）',
    'unicode': 'ဆုကြေးငွေ',
    'zawgyi': 'ဆုေၾကးေငြ',
    'english': 'prize money',
    'long': false
  },
  {
    'goi': 'きりん',
    'unicode': 'သစ်ကုလားအုပ်',
    'zawgyi': 'သစ္ကုလားအုပ္',
    'english': 'giraffe',
    'long': false
  },
  {
    'goi': 'ころ',
    'unicode': 'တုန်းက',
    'zawgyi': 'တုန္းက',
    'english': 'times, days',
    'long': false
  },
  {
    'goi': 'かないます',
    'unicode': 'ဆန္ဒပြည့်သည်',
    'zawgyi': 'ဆႏၵျပည့္သည္',
    'english': '(dream) be realized',
    'long': false
  },
  {
    'goi': '夢（ゆめ）」がかないます',
    'unicode': 'အိမ်မက်က အကောင်အထည်ပေါ်သည်',
    'zawgyi': 'အိမ္မက္က အေကာင္အထည္ေပၚသည္',
    'english': 'Dreams come true',
    'long': true
  },
  {
    'goi': '応（おう）」援（えん）」します',
    'unicode': 'အားပေးသည်',
    'zawgyi': 'အားေပးသည္',
    'english': 'I support you',
    'long': false
  },
  {
    'goi': '心（こころ）」から',
    'unicode': 'နှလုံးသားထဲမှ',
    'zawgyi': 'ႏွလုံးသားထဲမွ',
    'english': 'from my heart',
    'long': false
  },
  {
    'goi': '感（かん）」謝（しゃ）」します',
    'unicode': 'ကျေးဇူးတင်သည်',
    'zawgyi': 'ေက်းဇူးတင္သည္',
    'english': 'be grateful, be thankful',
    'long': false
  },
  {
    'goi': 'お」礼（れい）',
    'unicode': 'ကျေးဇူးတုံ့ပြန်ခြင်း',
    'zawgyi': 'ေက်းဇူးတုံ႔ျပန္ျခင္း',
    'english': 'gratitude, thanks',
    'long': false
  },
  {
    'goi': 'お」元（げん）」気（き）」でいらっしゃいますか。',
    'unicode': 'နေကောင်းပါသလား',
    'zawgyi': 'ေနေကာင္းပါသလား',
    'english': 'How are you doing?',
    'long': true
  },
  {
    'goi': '迷（めい）」惑（わく）」をかけます',
    'unicode': 'အနှောင့်အယှက်ပေးမိသည်',
    'zawgyi': 'အေႏွာင့္အယွက္ေပးမိသည္',
    'english': 'trouble, annoy, inconvenience',
    'long': true
  },
  {
    'goi': '生（い）」かします　I',
    'unicode': 'အသုံးချသည်',
    'zawgyi': 'အသုံးခ်သည္',
    'english': 'make good use of',
    'long': false
  },
  {
    'goi': 'ミュンヘン',
    'unicode': 'မြူဟန်ဒေသ(ဂျာမဏီမှ)',
    'zawgyi': 'ျမဴဟန္ေဒသ(ဂ်ာမဏီမွ)',
    'english': 'Munich(in Germany)',
    'long': false
  }
]