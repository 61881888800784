import SizeBox from "@components/SizeBox";
import AnimatedProgress from "@components/common/animated_progressbar/AnimatedProgress";
import Helper from "@helper";
import { Card } from "@material-tailwind/react";
import { memo, useEffect, useRef, useState } from "react";
import CountUp from 'react-countup';
import Arrow from "@assets/icon/game/arrow-redo.svg"
import Reload from "@assets/icon/game/reload-outline.svg"
// import { BiDownArrow } from "react-icons/bi";
// import { TiArrowSortedDown } from "react-icons/ti";

const Result = ({ life, total, correct, score,tryAgain,back,setNewScore }: any) => {
    const award = useRef(new Audio(require("@assets/audio/game/award.mp3")));
    const scoreUp = useRef(new Audio(require("@assets/audio/game/score.mp3")));
    const percent = (correct / total) * 100;
    // need revise
    const result = percent > 80 ? "すばらしい" : percent>50? "すごい": percent>30?"良い":"頑張って";
    const color =  percent > 80 ? "text-[#00E392]" : percent>50? "text-[#0084E8]": percent>30?"text-[#FA0]":"text-[#E8505B]";
    // need revise
    // indicator color
    const indicateColor = percent<31? "border-[#E8505B] bg-[#E8505B]":(percent<51?"border-[#FA0] bg-[#FA0]":percent<81?"border-[#0084E8] bg-[#0084E8]":"border-[#00E392] bg-[#00E392]");
    const indicateArrowColor = percent<31? "text-[#E8505B]":(percent<51?"text-[#FA0]":percent<81?"text-[#0084E8]":"text-[#00E392]");
    const [finish,setFinish] = useState(false);
    const [showBonus,setShowBonus] = useState(false);
    const [oldScore,setOldScore] = useState(0);
    const addBonus = async ()=>{
        await Helper.sleep(3000);
        scoreUp.current.play();
        setFinish(true);
        setShowBonus(true);
        // set new Score
        setOldScore(score);
        setNewScore(score + (life*1000));
        await Helper.sleep(2000);
        setShowBonus(false);
    }
    const progressData = [
        {
            flex: "flex-[3]",
            color: "bg-[#E8505B]",
            textColor: "text-[#E8505B]",
            percent: "30%",
            text: "頑張って"
        },
        {
            flex: "flex-[2]",
            color: "bg-[#FA0]",
            textColor: "text-[#FA0]",
            percent: "50%",
            text: "良い"
        },
        {
            flex: "flex-[3]",
            color: "bg-[#0084E8]",
            textColor: "text-[#0084E8]",
            percent: "80%",
            text: "すごい"
        },
        {
            flex: "flex-[2]",
            color: "bg-[#00E392]",
            textColor: "text-[#00E392]",
            percent: "100%",
            text: "すばらしい"
        },
    ];
    // const indicatorX = `left-[${percent}%]`;
    useEffect(()=>{
        if(!finish && life != 0){
            addBonus();
        }
    });
    return <div className="container md:w-[60%] mx-auto my-4 md:my-8">
        {
            <>
                <Card className="p-[20px] rounded-[20px] dark:bg-darkFooter card-light-shadow ">
                    {/* Result Test */}
                    <div className={`${color} text-[30px] text-center font-extrabold`}>
                        {result}<span className="">！</span>
                    </div>
                    <SizeBox vertical="h-[25px]"/>
                    {/* Score */}
                    <div className="text-[25px] text-center">Scores</div>
                    <div className="text-[25px] flex flex-row justify-center">
                        <CountUp
                            start={oldScore}
                            end={score}
                            duration={2}
                        />
                        {
                            showBonus && (<div className="ml-2">+ Bonus</div>)
                        }
                    </div>
                    <SizeBox vertical="h-[40px]"/>
                    {/* Progress */}
                    <div className="flex flex-row mb-[20px] mr-[20px] relative">
                        {/* Score Indicator */}
                        <div style={{left: Math.floor(percent)+"%"}} className={`absolute -ml-[30px] -top-[40px] w-[50px] flex flex-col items-center z-menu`}>
                            {/* percent */}
                            <div className={`${indicateColor} bg-darkFooter  dark:bg-gray-400 text-[14px] text-white border-[2px] rounded-[8px] px-[4px] py-[1px]`}>
                                {Math.floor(percent)}%
                            </div>
                            {/* indicator */}
                            {/* <BiDownArrow size={25} className={`${indicateArrowColor} -mt-[7px]`}/> */}
                            {/* <TiArrowSortedDown size={40} className={`${indicateArrowColor}   -mt-[15px]`}/> */}
                            <div className="w-[15px] h-[30px] -mt-[9.4px] overflow-hidden rotate-[90deg]">
                                <div className={`${indicateColor} border-[2px] -ml-[21.2px] w-[30px] h-[30px] rotate-[45deg] bg-darkFooter dark:bg-gray-400`}></div>
                            </div>
                        </div>
                        
                        {/* Score Bar */}
                        {
                            progressData.map(({flex,color,percent,text,textColor})=>{
                                return <div className={`${flex} ${color} relative h-[9px] rounded-full`}>
                                    <div className={`${color} absolute flex flex-row justify-center items-center -top-[10.5px] -right-[10.5px] h-[30px] w-[30px] text-[10px] font-semibold text-white rounded-full z-body`}>
                                        {percent}
                                    </div>
                                    <div className={`${textColor} absolute -bottom-[30px] -right-[30px] w-[70px] text-center text-[12px] font-semibold text-white z-body`}>
                                        {text}
                                    </div>
                                </div>
                            })
                        }
                        <div className="absolute flex flex-col items-center justify-center md:flex-row -bottom-[33px] leading-tight md:-bottom-[30px] text-[11px] md:text-[14px] text-[#0084E8] font-bold ">
                            <div>Correct</div>
                            <div>Progress</div>
                        </div>
                    </div>
                </Card>
                <SizeBox vertical="h-[50px]"/>
                <div className="flex flex-row justify-evenly text-white">
                    <div onClick={tryAgain} className="flex flex-row items-center md:text-[22px] btn border border-[#FD002B] py-2 px-5 bg-[#FF516E] rounded-lg  ">
                        <div>Retry</div>
                        <img src={Reload} className="ml-2"/>
                    </div>
                    <div onClick={back} className="flex flex-row items-center md:text-[22px] btn border border-[#009B64] py-2 px-5 bg-[#00E392]  rounded-lg  ">
                        <div>Continue</div>
                        <img src={Arrow} className="ml-2"/>
                    </div>
                    </div>
                </>
        }
    </div>
}
export default memo(Result);