import DefaultLayout from "@layouts/default/DefaultLayout";
import { memo } from "react";
const GrammarScreenLayout = ({ showModel, setShowModel,setUnlockPremium,body, Routes, useNavigate, Logger, lesson }: any) => {
    return ( 
        <DefaultLayout
            navbarProps={{
                root: false,
                appBar: { title: `Grammar ${lesson+26} `, centerTitle: true, leadingIcon: true }
            }}
            body={body}
            Routes={Routes}
            useNavigate={useNavigate}
            Logger={Logger}
            showModel={showModel}
            setShowModel={setShowModel}
            setUnlockPremium={setUnlockPremium}
        /> 
    )
}
export default memo(GrammarScreenLayout);