
export const renshuuA = [
    {
        isTable: true,
        tables: [
            {
                head: {
                    a: {
                        val: "",
                        span: 1,

                        style: ""
                    },
                    b: {
                        val: "ます｜＾形（けい）",
                        span: 3,
                        style: ""
                    },
                    c: {
                        val: "＾条（じょう）件（けん）系（けい）",
                        span: 3,
                        style: ""
                    }
                },
                body: [
                    {   
                        no: "I",
                        l1: "あ",
                        l2: "い",
                        l3: "ます",
                        r1: "あ",
                        r2: "え",
                        r3: "ば"
                    },
                    {   
                        no: "",
                        l1: "き",
                        l2: "き",
                        l3: "ます",
                        r1: "き",
                        r2: "け",
                        r3: "ば"
                    },
                    {   
                        no: "",
                        l1: "いそ",
                        l2: "ぎ",
                        l3: "ます",
                        r1: "いそ",
                        r2: "げ",
                        r3: "ば"
                    },
                    {   
                        no: "",
                        l1: "だ",
                        l2: "し",
                        l3: "ます",
                        r1: "だ",
                        r2: "せ",
                        r3: "ば"
                    },
                    {   
                        no: "",
                        l1: "ま",
                        l2: "ち",
                        l3: "ます",
                        r1: "ま",
                        r2: "て",
                        r3: "ば"
                    },
                    {   
                        no: "",
                        l1: "よ",
                        l2: "び",
                        l3: "ます",
                        r1: "よ",
                        r2: "べ",
                        r3: "ば"
                    },
                    {   
                        no: "",
                        l1: "の",
                        l2: "み",
                        l3: "ます",
                        r1: "の",
                        r2: "め",
                        r3: "ば"
                    },
                    {   
                        no: "",
                        l1: "ふ",
                        l2: "り",
                        l3: "ます",
                        r1: "ふ",
                        r2: "れ",
                        r3: "ば"
                    },
                ],
                style: {
                    no: "p-2 w-[50px] text-right bg-white dark:bg-darkFooter",
                    l1: "p-2 text-right bg-white dark:bg-darkFooter",
                    l2: "p-2 w-[30px] text-right bg-[#729eff] dark:bg-[#003257]",
                    l3: "p-2 text-left bg-white dark:bg-darkFooter",
                    r1: "p-2 text-right bg-white dark:bg-darkFooter",
                    r2: "p-2 w-[30px] text-right bg-[#729eff] dark:bg-[#003257]",
                    r3: "p-2 text-left bg-white dark:bg-darkFooter"
                }
            },
            {
                head: {
                    a: {
                        val: "",
                        span: 1,

                        style: ""
                    },
                    b: {
                        val: "ます｜＾形（けい）",
                        span: 2,
                        style: ""
                    },
                    c: {
                        val: "＾条（じょう）件（けん）系（けい）",
                        span: 3,
                        style: ""
                    }
                },
                body: [
                    {
                        no: "II",
                        l1: "はれ",
                        l3: "ます",
                        r1: "はれ",
                        r2: "れ",
                        r3: "ば"
                    },
                    {
                        no: "",
                        l1: "み",
                        l3: "ます",
                        r1: "み",
                        r2: "れ",
                        r3: "ば"
                    },
                    {
                        no: "",
                        l1: "",
                        l3: "",
                        r1: "",
                        r2: "",
                        r3: ""
                    },
                    {
                        no: "III",
                        l1: "き",
                        l3: "ます",
                        r1: "く",
                        r2: "れ",
                        r3: "ば"
                    },
                    {
                        no: "",
                        l1: "し",
                        l3: "ます",
                        r1: "す",
                        r2: "れ",
                        r3: "ば"
                    },
                ],
                style: {
                    no: "p-2 w-[50px] text-right bg-white dark:bg-darkFooter",
                    l1: "p-2 text-right bg-white dark:bg-darkFooter",
                    l3: "p-2 text-left bg-white dark:bg-darkFooter",
                    r1: "p-2 text-right bg-white dark:bg-darkFooter",
                    r2: "p-2 w-[30px] text-right bg-[#729eff] dark:bg-[#003257]",
                    r3: "p-2 text-left bg-white dark:bg-darkFooter"
                }
            },
            {
                head: {
                    a: {
                        val: "",
                        span: 1,

                        style: ""
                    },
                    b: {
                        val: "",
                        span: 2,
                        style: ""
                    },
                    c: {
                        val: "＾条（じょう）件（けん）系（けい）",
                        span: 3,
                        style: ""
                    }
                },
                body: [
                    {
                        no: "い｜＾形（けい）容（よう）詞（し）",
                        l1: "たか",
                        l3: "い",
                        r1: "たか",
                        r2: "けれ",
                        r3: "ば"
                    },
                    {
                        no: "",
                        l1: "い",
                        l3: "い",
                        r1: "よ",
                        r2: "けれ",
                        r3: "ば"
                    },
                    {
                        no: "",
                        l1: "",
                        l3: "",
                        r1: "",
                        r2: "",
                        r3: ""
                    },
                    {
                        no: "な｜＾形（けい）容（よう）詞（し）",
                        l1: "きれい",
                        l3: "［な］",
                        r1: "きれい",
                        r2: "なら",
                        r3: ""
                    },
                    {
                        no: "",
                        l1: "まじめ",
                        l3: "［な］",
                        r1: "まじめ",
                        r2: "なら",
                        r3: ""
                    },
                    {
                        no: "",
                        l1: "",
                        l3: "",
                        r1: "",
                        r2: "",
                        r3: ""
                    },  
                    {
                        no: "＾名（めい）詞（し）",
                        l1: "",
                        l3: "あめ",
                        r1: "あめ",
                        r2: "なら",
                        r3: ""
                    },
                    {
                        no: "",
                        l1: "",
                        l3: "むりょう",
                        r1: "むりょう",
                        r2: "なら",
                        r3: ""
                    },
                ],
                style: {
                    no: "p-2 w-[100px] text-right bg-white dark:bg-darkFooter",
                    l1: "p-2 text-right bg-white dark:bg-darkFooter",
                    l3: "p-2 text-left bg-white dark:bg-darkFooter",
                    r1: "p-2 text-right bg-white dark:bg-darkFooter",
                    r2: "p-2 w-[80px] text-right bg-[#729eff] dark:bg-[#003257]",
                    r3: "p-2 text-left bg-white dark:bg-darkFooter"
                }
            }
        ],
        audioUrl: require("@assets/audio/lesson35/renshua/4N35RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾説（せつ）明（めい）書（しょ）｜を　｜！よめば｜、　｜＾使（つか）｜い｜＾方（かた）｜が　わかります。",
                normal: "説明書を　よめば、　使い方が　わかります。",
                en: "If you read the manual, you'll know how to use it.",
                mm: "လမ်းညွှန်စာအုပ်ကိုဖတ်ရင် ၎င်းကိုအသုံးပြုနည်းကို သင်သိပါလိမ့်မည်။",
            },
            {
                furi: "＾雨（あめ）｜が　｜！ふれば｜、　｜＾涼（すず）｜しく　なります。",
                normal: "雨が　ふれば、　涼しく　なります。",
                en: "If it rains, it will be cooler.",
                mm: "မိုးရွာရင် အေးလာမယ်။",
            },
            {
                furi: "＾意（い）見（けん）｜が　｜！あれば｜、　｜＾言（い）｜って　ください。",
                normal: "意見が　あれば、　言って　ください。",
                en: "If you have any opinions, please say so.",
                mm: "ထင်မြင်ယူဆချက်များရှိပါက ကျေးဇူးပြု၍ ပြောပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson35/renshua/4N35RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "６｜＾時（じ）｜に　｜！おきなければ｜、｜＾会（かい）社（しゃ）｜に　｜＾遅（おく）｜れます。",
                normal: "６時に　おきなければ、会社に　遅れます。",
                en: "If I don't get up at 6:00, I'll be late for work.",
                mm: "မနက် ၆ နာရီ မထရင် အလုပ်နောက်ကျမယ်။",
            },
            {
                furi: "バズが　｜！こなければ｜、タクシーで　｜＾行（い）｜きましょう。",
                normal: "バズが　こなければ、タクシーで　行きましょう。",
                en: "If there is no bus, let's go by taxi.",
                mm: "ဘတ်စ်ကားမရှိရင် တက္ကစီနဲ့သွားရအောင်။",
            },
            {
                furi: "ボールペンが　なければ、｜＾鉛（えん）筆（ぴつ）｜で　｜＾書（か）｜いて　ください。",
                normal: "ボールペンが　なければ、鉛筆で　書いて　ください。",
                en: "If you don't have a ballpoint pen, please write with a pencil.",
                mm: "ဘောပင်မရှိပါက ခဲတံဖြင့်ရေးပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson35/renshua/4N35RSA3.mp3")
    },
    {
        sentence: [
            {
                furi: "あした　｜＾体（からだ）｜の　｜＾調（ちょう）子（し）｜が　｜！よければ｜、　｜＾行（い）｜けると　｜＾思（おも）｜います。",
                normal: "あした　体の　調子が　よければ、　行けると　思います。",
                en: "If my body feels good tomorrow, I think I can go.",
                mm: "မနက်ဖြန် ကျန်းမာရေးကောင်းနေရင် ငါ သွားနိုင်မယ်လို့ထင်ပါတယ်။",
            },
            {
                furi: "あした　｜＾都（つ）合（ごう）｜が　｜！わるければ｜、　あさって　｜＾来（き）｜て　ください。",
                normal: "あした　都合が　わるければ、　あさって　来て　ください。",
                en: "If it's not convenient for you tomorrow, please come the day after tomorrow.",
                mm: "မနက်ဖြန် အဆင်မပြေရင် သဘက်ခါ လာခဲ့ပါ။",
            },
            {
                furi: "あした　｜！ひまなら｜、　｜＾釣（つ）｜りに　｜＾行（い）｜きませんか。",
                normal: "あした　ひまなら、　釣りに　行きませんか。",
                en: "If you have free time tomorrow, would you like to go fishing?",
                mm: "မနက်​ဖြန်​ အားလပ်​ချိန်​ရှိရင်​ ငါးဖမ်းချင်​လား။",
            },
            {
                furi: "あした　いい　｜！てんきなら｜、　｜＾山（やま）登（のぼ）｜りに　｜＾行（い）｜きたいです。",
                normal: "あした　いい　てんきなら、　山登りに　行きたいです。",
                en: "I want to go mountain climbing if the weather is fine tomorrow.",
                mm: "မနက်ဖြန် ရာသီဥတု‌ကောင်းရင် တောင်တက်ချင်တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson35/renshua/4N35RSA4.mp3")
    },
    {
        sentence: [
            {
                furi: "＾東（とう）京（きょう）｜スカイツリーに　｜＾上（のぼ）｜りたいんですが、　どう　すれば　いいですか。",
                normal: "東京スカイツリーに　上りたいんですが、　どう　すれば　いいですか。",
                en: "I would like to climb the Tokyo Skytree, what should I do?",
                mm: "Tokyo Sky Tree ကိုတက်ချင်လို့ ဘာလုပ်ရမလဲ။",
            },
            {
                furi: "＾東（とう）京（きょう）｜スカイツリーに　｜＾上（のぼ）｜りたいんですが、　どうやって　いけば　いいですか。",
                normal: "東京スカイツリーに　上りたいんですが、　どうやって　いけば　いいですか。",
                en: "I would like to climb the Tokyo Skytree, what should I do?",
                mm: "Tokyo Sky Tree ကိုတက်ချင်လို့ ဘာလုပ်ရမလဲ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson35/renshua/4N35RSA5.mp3")
    },
    {
        sentence: [
            {
                furi: "もみじ　なら、　｜＾日（にっ）光（こう）｜　がいいです。",
                normal: "もみじ　なら、　日光　がいいです。",
                en: "For maple leaves, nikko is good.",
                mm: "မေပယ်ရွက်အတွက်ဆိုရင် နိခိုးက မေပယ်ရွက်တွေက ကောင်းတယ်နော်။",
            },
            {
                furi: "にほんごがっこう　なら　「みんなの　｜＾学（がっ）校（こう）｜」　が　いいです。",
                normal: "にほんごがっこう　なら　「みんなの　学校」　が　いいです。",
                en: "For Japanese School, Minna No Gakkou is the best one.",
                mm: 'ဂျပန်ကျောင်းဆိုရင်တော့ 「みんなの　学校」 က ကောင်းပါတယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson35/renshua/4N35RSA6.mp3")
    },
];

/*
    {
        sentence: [
            {
                furi: "",
                normal: "",
                en: "",
                mm: "",
            },
        ],
        audioUrl: require("@assets/audio/testing.mp3")
    },




*/