export const reibun= [
    {
        sentence: [
            {
                furi: "＾上（うわ）着（ぎ）｜の　ボタンが　とれそうですよ。",
                normal: "上着の　ボタンが　とれそうですよ。",
                en: "A button on my jacket looks like it might come off.",
                mm: "မင်းရဲ့ဂျာကင်အင်္ကျီပေါ်က ကြယ်သီးက ပြုတ်နေပုံပဲ။",
            },
            {
                furi: "あっ、ほんとうですね。どうも　ありがとう　ございます。",
                normal: "あっ、ほんとうですね。どうも　ありがとう　ございます。",
                en: "Oh, that's true. thank you very much .",
                mm: "သြော် ဒါအမှန်ပါပဲ။ ကျေးဇူးအများကြီးတင်ပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson43/bunpou/4N43RB1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾暖（あたた）｜かく　なりましたね。",
                normal: "暖かく　なりましたね。",
                en: "It's getting warmer, isn't it?",
                mm: "ပိုပူလာတယ်မဟုတ်လား?",
            },
            {
                furi: "ええ、もうすぐ　｜＾桜（さくら）｜が　｜＾咲（さ）｜きそうですね。",
                normal: "ええ、もうすぐ　桜が　咲きそうですね。",
                en: "Yes, it looks like the cherry blossoms will bloom soon.",
                mm: "ဟုတ်တယ်၊ ချယ်ရီပန်းတွေ မကြာခင် ပွင့်လာပုံပဲ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson43/bunpou/4N43RB2.mp3")
    },
    {
        sentence: [
            {
                furi: "ドイツの　りんごの　ケーキです。どうぞ。",
                normal: "ドイツの　りんごの　ケーキです。どうぞ。",
                en: "German apple cake. please.",
                mm: "ဂျာမန်ပန်းသီးကိတ်မုန့်ပါ။ ကျေးဇူးပြု၍ စားပါ။",
            },
            {
                furi: "わあ、おいしそうですね。いただきます。",
                normal: "わあ、おいしそうですね。いただきます。",
                en: "Wow, that looks delicious! I'll enjoy having this.",
                mm: "အိုး၊ အဲဒါက အရသာရှိပုံပဲ။ စားပါ‌တော့မယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson43/bunpou/4N43RB3.mp3")
    },
    {
        sentence: [
            {
                furi: "この　アルバイト、よさそうですね。｜＾給（きゅう）料（りょう）｜も　いいし、｜＾仕（し）事（ごと）｜も　｜＾楽（らく）｜そうだし。",
                normal: "この　アルバイト、よさそうですね。給料も　いいし、仕事も　楽そうだし。",
                en: "This part-time job looks good. The pay is good and the work is easy.",
                mm: "ဒီအချိန်ပိုင်းအလုပ်က ကောင်းမဲ့ပုံဘဲ။ လစာကောင်းပြီး အလုပ်ကလည်း လွယ်ပါတယ်။",
            },
            {
                furi: "でも、｜＾夜（よる）｜の　１２｜＾時（じ）｜から　｜＾朝（あさ）｜の　６｜＾時（じ）｜までですよ。",
                normal: "でも、夜の　１２時から　朝の　６時までですよ。",
                en: "But from 12:00 at night to 6:00 in the morning.",
                mm: "ဒါ‌ပေမဲ့ ည ၁၂း၀၀ မှ မနက် ၆း၀၀ ထိ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson43/bunpou/4N43RB4.mp3")
    },
    {
        sentence: [
            {
                furi: "＾資（し）料（りょう）｜が　｜＾足（た）｜りませんね。",
                normal: "資料が　足りませんね。",
                en: "We don't have enough materials.",
                mm: "ကျွန်တော်တို့မှာ စာရွက်စာတမ်း အလုံအလောက်မရှိဘူး။",
            },
            {
                furi: "＾何（なん）枚（まい）｜ですか。すぐ　コピーして　｜＾来（き）｜ます。",
                normal: "何枚ですか。すぐ　コピーして　来ます。",
                en: "how many sheets? I will copy it right away.",
                mm: "ဘယ်နှရွက်လဲ၊ ချက်ချင်းကူးယူလာလိုက်မယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson43/bunpou/4N43RB5.mp3")
    },
    {
        sentence: [
            {
                furi: "ちょっと　｜＾出（で）｜かけて　｜＾来（き）｜ます。",
                normal: "ちょっと　出かけて　来ます。",
                en: "I will go out for a while.",
                mm: "ငါ အပြင်ခဏသွားမယ်။",
            },
            {
                furi: "＾何（なん）時（じ）｜ごろ　｜＾帰（かえ）｜りますか。",
                normal: "何時ごろ　帰りますか。",
                en: "Around what time will you return?",
                mm: "ဘယ်အချိန်လောက် ပြန်လာမှာလဲ",
            },
            {
                furi: "４｜＾時（じ）｜までに　｜＾帰（かえ）｜る　つもりです。",
                normal: "４時までに　帰る　つもりです。",
                en: "I plan to be home by 4:00.",
                mm: "4:00 မှာ အိမ်ပြန်ဖို့ စီစဉ်ထားတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson43/bunpou/4N43RB6.mp3")
    },
]