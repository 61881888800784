import DefaultLayout from "@layouts/default/DefaultLayout";
import { memo } from "react";
const KanjiScreenLayout = ({ showModel,setShowModel,setUnlockPremium,body, Routes, useNavigate, Logger }: any) => {
    return (
        <DefaultLayout
            navbarProps={{
                root: false,
                appBar: { title: "Kanji", centerTitle: true, leadingIcon: true }
            }}
            body={body}
            Routes={Routes}
            useNavigate={useNavigate}
            Logger={Logger}
            showModel={showModel}
            setShowModel={setShowModel}
            setUnlockPremium={setUnlockPremium}
        />
    )
}
export default memo(KanjiScreenLayout);