import Icon from "@consts/icon";

export const lesson27 =  {
    title: {
        furi: '＾何（なん）｜でも　｜＾作（つく）｜れるんですね',
        normal: '何でも　作れるんですね',
        en: 'You can make anything',
        mm: 'ဘာမဆိုလုပ်နိုင်တယ်‌နော်။',
    },
    conversation: [
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1

            },
            sentence: [
                {
                    furi: '＾明（あか）｜るくて、　いい　｜＾部（へ）屋（や）｜ですね',
                    normal: '明るくて、　いい　部屋ですね。',
                    en: "It's a bright and nice room.",
                    mm: 'တောက်ပပြီး ချစ်စရာကောင်းသော အခန်းဖြစ်တယ်။',
                }
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'ええ。｜＾天（てん）気（き）｜が　いい　｜＾日（ひ）｜には　｜＾海（うみ）｜が　｜＾見（み）｜えるんです。',
                    normal: 'ええ。天気が　いい　日には　海が　見えるんです。',
                    en: 'yes. When the weather is nice, the sea can be seen.',
                    mm: 'ဟုတ်တယ် ရာသီဥတုသာယာတဲ့နေ့တွေမှာ ပင်လယ်ရေကိုမြင်ရတယ်။',
                }
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'この　テーブルは　おもしろい　デザインですね。',
                    normal: 'この　テーブルは　おもしろい　デザインですね。',
                    en: 'This table is an interesting design.',
                    mm: 'ဒီစားပွဲက စိတ်ဝင်စားစရာကောင်းတဲ့ ဒီဇိုင်းပဲ‌။',
                },
                {
                    furi: 'どこで　｜＾買（か）｜ったんですか。',
                    normal: 'どこで　買ったんですか。',
                    en: 'Where did you buy it?',
                    mm: 'ဘယ်မှာဝယ်ခဲ့တာလဲ။',                   
                }
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'これは　わたしが　｜＾作（つく）｜ったんですよ。',
                    normal: 'これは　わたしが　作ったんですよ。',
                    en: 'I made this.',
                    mm: 'ကျွန်တော်လုပ်ထားတာပါ။',
                }
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1

            },
            sentence: [
                {
                    furi: 'えっ、ほんとうですか。',
                    normal: 'えっ、ほんとうですか。',
                    en: 'Eh,Really?',
                    mm: 'အဲ တကယ်လား',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'ええ。｜＾趣（しゅ）味（み）｜は　｜＾自（じ）分（ぶん）｜で　｜＾家（か）具（ぐ）｜を　｜＾作（つく）｜る　ことなんです。',
                    normal: 'ええ。趣味は　自分で　家具を　作る　ことなんです。',
                    en: 'yes. my hobby is to make furniture by myself.',
                    mm: 'ဟုတ်တယ် ဝါသနာက ကိုယ့်ဘာသာ ပရိဘောဂလုပ်တာ။',
                }
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1

            },
            sentence: [
                {
                    furi: 'へえ。　じゃ、あの　｜＾本（ほん）棚（だな）｜も　｜＾作（つく）｜ったんですか。',
                    normal: 'へえ。　じゃ、あの　本棚も　作ったんですか。',
                    en: 'Huh. Then did you make that bookshelf too?',
                    mm: 'ဟမ်။ ဒါဆို မင်း ဒီစာအုပ်စင်လေး လုပ်ထားတာလား။',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'ええ。',
                    normal: 'ええ。',
                    en: 'Eh',
                    mm: 'အင်း',
                }
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1

            },
            sentence: [
                {
                    furi: 'すごいですね。　｜＾鈴（すず）木（き）｜さん、　｜＾何（なん）｜でも　｜＾作（つく）｜れるんですね。',
                    normal: 'すごいですね。　鈴木さん、　何でも　作れるんですね。',
                    en: "It's amazing. Mr.Suzuki, you can make anything.",
                    mm: 'အံ့သြစရာကောင်းပါတယ်။ မစ္စဆူဇူကီး၊ မင်း ဘာမဆိုလုပ်တတ်တယ်‌နော်။',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN2
                
            },
            sentence: [
                {
                    furi: 'わたしの　｜＾夢（ゆめ）｜は　いつか　｜＾自（じ）分（ぶん）｜で　｜＾家（いえ）｜を　｜＾建（た）｜てる　ことなんです。',
                    normal: 'わたしの　夢は　いつか　自分で　家を　建てる　ことなんです。',
                    en: 'My dream is to build my own house someday.',
                    mm: 'ငါ့အိပ်မက်က တစ်နေ့နေ့ ငါ့ကိုယ်ပိုင်အိမ်ဆောက်ဖို့ပဲ။',
                }
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'すばらしい　｜＾夢（ゆめ）｜ですね。',
                    normal: 'すばらしい　夢ですね。',
                    en: "It's a wonderful dream.",
                    mm: 'အံ့သြစရာကောင်းတဲ့ စိတ်ကူးပါပဲ။',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson27/kaiwa/4N27KW.mp3")
}

/*

    {
        title: {
            furi: '',
            normal: '',
            en: '',
            mm: '',
        },
        conversation: [
            {
                person: {
                    furi: '',
                    normal: '',
                },
                sentence: [
                    {
                        furi: '',
                        normal: '',
                        en: '',
                        mm: '',
                    },
                ],
            },
        ],
        audioUrl: require("@assets/audio/lesson26/kaiwa/4N26KW.mp3")
    }

*/