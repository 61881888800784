
export const lesson12 = [
    {"lesson":12,"level":4,"question":"自分","represent":["自","分"],"answer":["りょうがえ","でし","じぶん","しゃりょう"],"true":"じぶん","times":10},
    {"lesson":12,"level":4,"question":"民族","represent":["民","族"],"answer":["りょうほう","じぶん","してい","みんぞく"],"true":"みんぞく","times":10},
    {"lesson":12,"level":4,"question":"親","represent":["親"],"answer":["おや","あに","おとうと","あね"],"true":"おや","times":10},
    {"lesson":12,"level":4,"question":"父兄","represent":["父","兄"],"answer":["かぞく","りょうがえ","ちちおや","ふけい"],"true":"ふけい","times":10},
    {"lesson":12,"level":4,"question":"兄","represent":["兄"],"answer":["あね","わたし","あに","いえ"],"true":"あに","times":10},
    {"lesson":12,"level":4,"question":"両親","represent":["両","親"],"answer":["おおや","してい","りょうしん","でし"],"true":"りょうしん","times":10},
    {"lesson":12,"level":4,"question":"父親","represent":["父","親"],"answer":["りょうしん","ちちおや","しまい","りょうほう"],"true":"ちちおや","times":10},
    {"lesson":12,"level":4,"question":"子弟","represent":["子","弟"],"answer":["でし","してい","ちちおや","じたく"],"true":"してい","times":10},
    {"lesson":12,"level":4,"question":"姉妹","represent":["姉","妹"],"answer":["じしん","みんぞく","じゆう","しまい"],"true":"しまい","times":10},
    {"lesson":12,"level":4,"question":"自信","represent":["自","信"],"answer":["じぶん","おおや","でし","じしん"],"true":"じしん","times":10},
    {"lesson":12,"level":4,"question":"大家","represent":["大","家"],"answer":["おおや","りょうがえ","じたく","みんぞく"],"true":"おおや","times":10},
    {"lesson":12,"level":4,"question":"弟子","represent":["弟","子"],"answer":["りょうがえ","でし","してい","みんぞく"],"true":"でし","times":10},
    {"lesson":12,"level":4,"question":"家","represent":["家"],"answer":["おや","いえ","いもうと","おとうと"],"true":"いえ","times":10},
    {"lesson":12,"level":4,"question":"両替","represent":["両"],"answer":["じしん","りょうしん","じたく","りょうがえ"],"true":"りょうがえ","times":10},
    {"lesson":12,"level":4,"question":"姉","represent":["姉"],"answer":["おや","いえ","わたし","あね"],"true":"あね","times":10},
    {"lesson":12,"level":4,"question":"兄弟","represent":["兄","弟"],"answer":["りょうほう","きょうだい","りょうしん","しまい"],"true":"きょうだい","times":10},
    {"lesson":12,"level":4,"question":"妹","represent":["妹"],"answer":["おや","わたし","あに","いもうと"],"true":"いもうと","times":10},
    {"lesson":12,"level":4,"question":"家族","represent":["家","族"],"answer":["りょうしん","じゆう","してい","かぞく"],"true":"かぞく","times":10},
    {"lesson":12,"level":4,"question":"自宅","represent":["自"],"answer":["みんぞく","じたく","でし","きょうだい"],"true":"じたく","times":10},
    {"lesson":12,"level":4,"question":"弟","represent":["弟"],"answer":["わたし","いえ","あね","おとうと"],"true":"おとうと","times":10},
    {"lesson":12,"level":4,"question":"自由","represent":["自"],"answer":["りょうほう","きょうだい","じたく","じゆう"],"true":"じゆう","times":10},
    {"lesson":12,"level":4,"question":"両方","represent":["両","方"],"answer":["りょうほう","りょうしん","きょうだい","おおや"],"true":"りょうほう","times":10},
    {"lesson":12,"level":4,"question":"車両","represent":["車","両"],"answer":["りょうほう","しゃりょう","してい","しまい"],"true":"しゃりょう","times":10},
    {"lesson":12,"level":4,"question":"私","represent":["兄","姉","弟","妹"],"answer":["わたし","あに","あね","おとうと"],"true":"わたし","times":10},
]