export const lesson9 = {
    "体": 80,
    "頭": 81,
    "顔": 82,
    "首": 83,
    "心": 84,
    "声": 85,
    "病": 86,
    "薬": 87,
    "科": 88,
    "内": 89
};