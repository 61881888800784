
export const lesson10 = [
    {"lesson":10,"level":4,"question":"いぜん","represent":["以","前"],"answer":["夕方","以前","一度","昼食"],"true":"以前","times":10},
    {"lesson":10,"level":4,"question":"ちゅうしょく","represent":["昼","食"],"answer":["昼食","以下","速度","以後"],"true":"昼食","times":10},
    {"lesson":10,"level":4,"question":"あさひ","represent":["朝","日"],"answer":["朝食","とけい","今夜","朝日"],"true":"朝日","times":10},
    {"lesson":10,"level":4,"question":"いご","represent":["以","後"],"answer":["夕方","以後","温度","方法"],"true":"以後","times":10},
    {"lesson":10,"level":4,"question":"こんや","represent":["今","夜"],"answer":["朝食","今夜","以下","以前"],"true":"今夜","times":10},
    {"lesson":10,"level":4,"question":"かた","represent":["方"],"answer":["晩","朝","方","昼"],"true":"方","times":10},
    {"lesson":10,"level":4,"question":"たなばた","represent":["七","夕"],"answer":["七夕","以後","以下","速度"],"true":"七夕","times":10},
    {"lesson":10,"level":4,"question":"とけい","represent":["時","計"],"answer":["七夕","時計","昼寝","以上"],"true":"時計","times":10},
    {"lesson":10,"level":4,"question":"かようび","represent":["火","曜","日"],"answer":["月曜日","火曜日","水曜日","木曜日"],"true":"火曜日","times":10},
    {"lesson":10,"level":4,"question":"たいおんけい","represent":["体","計"],"answer":["火曜日","月曜日","晩御飯","体温計"],"true":"体温計","times":10},
    {"lesson":10,"level":4,"question":"ほうほう","represent":["方"],"answer":["方法","一度","朝食","以後"],"true":"方法","times":10},
    {"lesson":10,"level":4,"question":"ひる","represent":["昼"],"answer":["方","晩","昼","朝"],"true":"昼","times":10},
    {"lesson":10,"level":4,"question":"ゆうがた","represent":["夕","方"],"answer":["夕方","朝日","今度","時計"],"true":"夕方","times":10},
    {"lesson":10,"level":4,"question":"こんばん","represent":["今","晩"],"answer":["今晩","以下","夕食","方法"],"true":"今晩","times":10},
    {"lesson":10,"level":4,"question":"すいようび","represent":["水","曜","日"],"answer":["水曜日","体温計","火曜日","晩御飯"],"true":"水曜日","times":10},
    {"lesson":10,"level":4,"question":"げつようび","represent":["月","曜","日"],"answer":["晩御飯","木曜日","水曜日","月曜日"],"true":"月曜日","times":10},
    {"lesson":10,"level":4,"question":"そくど","represent":["度"],"answer":["速度","以下","夜食","温度"],"true":"速度","times":10},
    {"lesson":10,"level":4,"question":"ひるね","represent":["昼"],"answer":["朝日","今夜","温度","昼寝"],"true":"昼寝","times":10},
    {"lesson":10,"level":4,"question":"いか","represent":["以","下"],"answer":["以下","一度","様方","夜食"],"true":"以下","times":10},
    {"lesson":10,"level":4,"question":"よる","represent":["夜"],"answer":["晩","夜","方","朝"],"true":"夜","times":10},
    {"lesson":10,"level":4,"question":"ゆうしょく","represent":["夕","食"],"answer":["以上","七夕","夕食","朝食"],"true":"夕食","times":10},
    {"lesson":10,"level":4,"question":"いちど","represent":["一","度"],"answer":["以後","温度","方法","一度"],"true":"一度","times":10},
    {"lesson":10,"level":4,"question":"ばん","represent":["晩"],"answer":["夜","晩","昼","朝"],"true":"晩","times":10},
    {"lesson":10,"level":4,"question":"やしょく","represent":["夜","食"],"answer":["今晩","夜食","今度","朝日"],"true":"夜食","times":10},
    {"lesson":10,"level":4,"question":"あさ","represent":["朝"],"answer":["方","夜","朝","昼"],"true":"朝","times":10},
    {"lesson":10,"level":4,"question":"ばんごはん","represent":["晩","飯"],"answer":["晩御飯","木曜日","火曜日","月曜日"],"true":"晩御飯","times":10},
    {"lesson":10,"level":4,"question":"さまかた","represent":["方"],"answer":["速度","方法","様方","今晩"],"true":"様方","times":10},
    {"lesson":10,"level":4,"question":"いじょう","represent":["以","上"],"answer":["昼寝","時計","以上","方法"],"true":"以上","times":10},
    {"lesson":10,"level":4,"question":"もくようび","represent":["木","曜","日"],"answer":["火曜日","水曜日","木曜日","月曜日"],"true":"木曜日","times":10},
    {"lesson":10,"level":4,"question":"こんど","represent":["今","度"],"answer":["今度","七夕","速度","夕方"],"true":"今度","times":10},
    {"lesson":10,"level":4,"question":"ちょうしょく","represent":["朝","食"],"answer":["以後","今夜","以前","朝食"],"true":"朝食","times":10},
    {"lesson":10,"level":4,"question":"おんど","represent":["度"],"answer":["夕方","時計","温度","今度"],"true":"温度","times":10},
]