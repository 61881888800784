import { jlpt2010 } from "./jlpt2010";
import { jlpt2012 } from "./jlpt2012";
import { jlpt201307 } from "./jlpt201307";
import { jlpt201312 } from "./jlpt201312";
import { jlpt201407 } from "./jlpt201407";
import { jlpt2018 } from "./jlpt2018";

export const test = [
    jlpt2018,jlpt201407,jlpt201312,jlpt201307,jlpt2012,jlpt2010
]

export interface BunpouType {
    mondai1: BunopouMondaiType;
    mondai2: BunopouMondaiType;
}

export interface BunopouMondaiType {
    title: String;
    questions: Array<BunopouQuestionType>;
}

export interface BunopouQuestionType {
    number: number,
    question: Array<String>,
    answers: Array<String>,
    pick: String | "",
    correct: String | "";
}

export interface MojiType {
    mondai1: MondaiType;
    mondai2: MondaiType;
    mondai3: MondaiType;
    mondai4: MondaiType;
    mondai5: MondaiType;
}

export interface MondaiType {
    title: String;
    questions: Array<QuestionType>;
}

export interface QuestionType {
    number: number,
    question: String,
    answers: Array<String>,
    pick: String | "",
    correct: String | "";
}