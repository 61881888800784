
export const lesson14 = [
    {"lesson":14,"level":4,"question":"げんりょう","represent":["料"],"answer":["原料","料理","分野","米国"],"true":"原料","times":10},
    {"lesson":14,"level":4,"question":"ゆうはん","represent":["夕","飯"],"answer":["夕飯","料理","趣味","紅茶"],"true":"夕飯","times":10},
    {"lesson":14,"level":4,"question":"のやま","represent":["野","山"],"answer":["野山","紅茶","鳥肉","白鳥"],"true":"野山","times":10},
    {"lesson":14,"level":4,"question":"ぶつり","represent":["物","理"],"answer":["物理","趣味","野菜","中米"],"true":"物理","times":10},
    {"lesson":14,"level":4,"question":"いみ","represent":["意","味"],"answer":["意味","分野","夕飯","原料"],"true":"意味","times":10},
    {"lesson":14,"level":4,"question":"ちゃ","represent":["茶"],"answer":["茶","鳥","味","米"],"true":"茶","times":10},
    {"lesson":14,"level":4,"question":"分野","represent":["分","野"],"answer":["分野","料理","野球","野山"],"true":"分野","times":10},
    {"lesson":14,"level":4,"question":"しらとり","represent":["白","鳥"],"answer":["白鳥","野菜","肉体","分野"],"true":"白鳥","times":10},
    {"lesson":14,"level":4,"question":"ちゅうべい","represent":["中","米"],"answer":["中米","野山","意味","野球"],"true":"中米","times":10},
    {"lesson":14,"level":4,"question":"ざいりょう","represent":["料"],"answer":["材料","中米","牛肉","野菜"],"true":"材料","times":10},
    {"lesson":14,"level":4,"question":"りょうり","represent":["料","理"],"answer":["料理","料金","紅茶","分野"],"true":"料理","times":10},
    {"lesson":14,"level":4,"question":"べいこく","represent":["米","国"],"answer":["米国","夕飯","野山","紅茶"],"true":"米国","times":10},
    {"lesson":14,"level":4,"question":"やきゅう","represent":["野"],"answer":["野球","料理","牛肉","原料"],"true":"野球","times":10},
    {"lesson":14,"level":4,"question":"にく","represent":["肉"],"answer":["肉","味","鳥","茶"],"true":"肉","times":10},
    {"lesson":14,"level":4,"question":"こうちゃ","represent":["茶"],"answer":["紅茶","材料","牛肉","理由"],"true":"紅茶","times":10},
    {"lesson":14,"level":4,"question":"ぎゅうにく","represent":["牛","肉"],"answer":["牛肉","米国","鳥肉","原料"],"true":"牛肉","times":10},
    {"lesson":14,"level":4,"question":"しゅみ","represent":["味"],"answer":["趣味","肉体","料金","材料"],"true":"趣味","times":10},
    {"lesson":14,"level":4,"question":"やちょう","represent":["野","鳥"],"answer":["野鳥","趣味","野山","夕飯"],"true":"野鳥","times":10},
    {"lesson":14,"level":4,"question":"べい","represent":["米"],"answer":["米","味","肉","茶"],"true":"米","times":10},
    {"lesson":14,"level":4,"question":"とりにく","represent":["鳥","肉"],"answer":["鳥肉","野菜","料金","意味"],"true":"鳥肉","times":10},
    {"lesson":14,"level":4,"question":"あじ","represent":["味"],"answer":["味","米","茶","鳥"],"true":"味","times":10},
    {"lesson":14,"level":4,"question":"やさい","represent":["野","菜"],"answer":["野菜","原料","中米","米国"],"true":"野菜","times":10},
    {"lesson":14,"level":4,"question":"にくたい","represent":["肉","体"],"answer":["肉体","中米","紅茶","趣味"],"true":"肉体","times":10},
    {"lesson":14,"level":4,"question":"とり","represent":["鳥"],"answer":["鳥","味","茶","鳥"],"true":"鳥","times":10},
    {"lesson":14,"level":4,"question":"りょうきん","represent":["料","金"],"answer":["料金","原料","趣味","理由"],"true":"料金","times":10},
    {"lesson":14,"level":4,"question":"りゆう","represent":["理"],"answer":["理由","野菜","趣味","物理"],"true":"理由","times":10},
]