export const reibun= [
    {
        sentence: [
            {
                furi: "けさ　｜＾部（ぶ）長（ちょう）｜に　｜＾呼（よ）｜ばれました。",
                normal: "けさ　部長に　呼ばれました。",
                en: "I was called by the manager this morning.",
                mm: "ဒီမနက် မန်နေဂျာက ခေါ်တာခံရတယ်။",
            },
            {
                furi: "＾何（なに）｜か　あったんですか。",
                normal: "何か　あったんですか。",
                en: "What happened.",
                mm: "ဘာဖြစ်တာလဲ။",
            },
            {
                furi: "＾出（しゅっ）張（ちょう）｜の　レポートの　｜＾書（か）｜き｜＾方（かた）｜に　ついて　｜＾注（ちゅう）意（い）｜されました。",
                normal: "出張の　レポートの　書き方に　ついて　注意されました。",
                en: "I was warned about how to write a business trip report.",
                mm: "ခရီးစဉ် အစီရင်ခံစာ ဘယ်လိုရေးရမလဲ ဆိုတာကို သတိပေးခဲ့ပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson37/bunpou/4N37RB1.mp3")
    },
    {
        sentence: [
            {
                furi: "どう　したんですか。",
                normal: "どう　したんですか。",
                en: "What happened.",
                mm: "ဘာဖြစ်တာလဲ။",
            },
            {
                furi: "だれかに　｜＾傘（かさ）｜を　まちがえられたんです。",
                normal: "だれかに　傘を　まちがえられたんです。",
                en: "Someone mistook my umbrella .",
                mm: "တစ်ယောက်ယောက်က ကျွန်တော့်ထီးကို မှားယူသွားခဲ့တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson37/bunpou/4N37RB2.mp3")
    },
    {
        sentence: [
            {
                furi: "また　｜＾新（あたら）｜しい　｜＾星（ほし）｜が　｜＾発（はっ）見（けん）｜されましたよ。",
                normal: "また　新しい　星が　発見されましたよ。",
                en: "Another new star has been discovered.",
                mm: "နောက်ထပ် ကြယ်ပွင့်အသစ်တစ်ခုကို ရှာဖွေတွေ့ရှိခဲ့သည်။",
            },
            {
                furi: "そうですか。",
                normal: "そうですか。",
                en: "is that so.",
                mm: "ဟုတ်လား။",
            },
        ],
        audioUrl: require("@assets/audio/lesson37/bunpou/4N37RB3.mp3")
    },
    {
        sentence: [
            {
                furi: "ことしの　｜＾世（せ）界（かい）子（こ）｜ども｜＾会（かい）議（ぎ）｜は　どこで　｜＾開（ひら）｜かれますか。",
                normal: "ことしの　世界子ども会議は　どこで　開かれますか。",
                en: "Where will this year's World Children's Conference be held?",
                mm: "ဒီနှစ် ကမ္ဘာ့ကလေးများညီလာခံကို ဘယ်မှာကျင်းပမလဲ။",
            },
            {
                furi: "＾広（ひろ）島（しま）｜で　｜＾開（ひら）｜かれます。",
                normal: "広島で　開かれます。",
                en: "It will be held in Hiroshima.",
                mm: "ဟီရိုရှီးမားတွင် ကျင်းပမည်ဖြစ်သည်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson37/bunpou/4N37RB4.mp3")
    },
    {
        sentence: [
            {
                furi: "ビールは　｜＾麦（むぎ）｜から　｜＾作（つく）｜られます。これが　｜＾原（げん）料（りょう）｜の　｜＾麦（むぎ）｜です。",
                normal: "ビールは　麦から　作られます。これが　原料の　麦です。",
                en: "Beer is made of wheat. This is the raw material, barley.",
                mm: "ဘီယာကို ဂျုံဖြင့်ပြုလုပ်သည်။ ဒါက ကုန်ကြမ်း၊မုယောစပါးပါ။",
            },
            {
                furi: "これが　ビールに　なるんですね。",
                normal: "これが　ビールに　なるんですね。",
                en: "This is going to be beer, isn't it?",
                mm: "ဒါကနေ ဘီယာဖြစ်လာတယ်၊ ဟုတ်လား?",
            },
        ],
        audioUrl: require("@assets/audio/lesson37/bunpou/4N37RB5.mp3")
    },
    {
        sentence: [
            {
                furi: "ブラジルでは　｜＾何（なに）語（ご）｜が　｜＾使（つか）｜われて　いますか。",
                normal: "ブラジルでは　何語が　使われて　いますか。",
                en: "What language is used in Brazil?",
                mm: "ဘရာဇီးတွင် မည်သည့်ဘာသာစကားကို အသုံးပြုသနည်း။",
            },
            {
                furi: "ポルトガル｜＾語（ご）｜が　｜＾使（つか）｜われて　います。",
                normal: "ポルトガル語が　使われて　います。",
                en: "Portuguese is used.",
                mm: "ပေါ်တူဂီကို အသုံးပြုသည်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson37/bunpou/4N37RB6.mp3")
    },
]