export const explain= [
    { 
        // pattern_furi: '＾命（めい）令（れい）形（けい）｜/｜＾禁（きん）止（し）形（けい）｜',
        pattern_furi: '~とおりに ',
        pattern_normal: '~とおりに ',
        en: [
            '　As (Vる/Vた/N　+　とおりに)',
            '　Various forms of verbs and nouns are followed.',
        ],
        mm: [
            '　~တဲ့အတိုင်း (Vる/Vた/N　+　とおりに)',
            '　ကြိယာပုံစံ အမျိုးမျိုးနှင့် နာမ်၏နောက်တွင် အလိုက်များသည်။',
        ]
    },
    {
        pattern_furi: '~あとで ',
        pattern_normal: '~あとで ',
        en: [
            '　later (Vた/Nの　+　あとで)',
            '　It follows the past tense of the verb and the noun.',
        ],
        mm: [
            '　ပြီးမှ (Vた/Nの　+　あとで)',
            '　အတိတ်ကြိယာပုံစံနှင့် နာမ်၏နောက်တွင် အလိုက်များသည်',
        ]
    },
    {
        pattern_furi: '~ないでV ',
        pattern_normal: '~ないでV ',
        en: [
            "　Please don't (Vないで　+　Vます/ください)",
            "　followed by the argument",
        ],
        mm: [
            '　–ဘဲနဲ့ (Vないで　+　Vます/ください)',
            '　အငြင်းကြိယာ၏နောက်တွင် လိုက်သည်',
        ]
    },
    {
        pattern_furi: 'Vると｜＾言（い）｜っていました',
        pattern_normal: 'Vると言っていました',
        en: [
            "　It is used to convey what someone has said",
        ],
        mm: [
            '　တစ်ယောက်ယောက်က ပြောသောစကားကို တဆင့်ပြန်လည်ပြောရာတွင်သုံးသည်',
        ]
    }
]