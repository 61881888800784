export const lesson29 = [
  {
    furi: '＾開（あ）｜きます',
    normal: '開きます',
    mm: 'ပွင့်သည်',
    remark_en: "",
    en: 'open(door)',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO1.mp3")
  },
  {
    furi: '＾閉（し）｜まります',
    normal: '閉まります',
    mm: 'ပိတ်သည်',
    remark_en: "",
    en: 'close(door)',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO2.mp3")
  },
  {
    furi: 'つきます',
    normal: 'つきます',
    mm: 'ပွင့်သည်',
    remark_en: "ne turned on",
    en: 'come on(a light)',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO3.mp3")
  },
  {
    furi: '＾消（き）｜えます',
    normal: '消えます',
    mm: 'ပျက်သည်',
    remark_en: "",
    en: 'go off',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO4.mp3")
  },
  {
    furi: '＾壊（こわ）｜れます',
    normal: '壊れます',
    mm: 'ပျက်စီးသည်',
    remark_en: "(a chair) break",
    en: 'break',
    remark_mm: "ကြိုးပဲ့ပျက်စီးသည်",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO5.mp3")
  },
  {
    furi: '＾割（わ）｜れます',
    normal: '割れます',
    mm: 'ကွဲသည်',
    remark_en: "(a glass) break",
    en: 'break',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO6.mp3")
  },
  {
    furi: '＾折（お）｜れます',
    normal: '折れます',
    mm: 'ကျိုးသည်',
    remark_en: "(a tree) break",
    en: 'break, snap',
    remark_mm: "(သစ်ပင်၊ သစ်ကိုင်း)ကျိုးသည်",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO6(1).mp3")
  },
  {
    furi: '＾破（やぶ）｜れます',
    normal: '破れます',
    mm: 'စုတ်ပြဲသည်',
    remark_en: "",
    en: 'tear',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO7.mp3")
  },
  {
    furi: '＾汚（よご）｜れます',
    normal: '汚れます',
    mm: 'ညစ်ပတ်သည်',
    remark_en: "",
    en: 'get dirty',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO8.mp3")
  },
  {
    furi: '＾付（つ）｜きます',
    normal: '付きます',
    mm: 'တွဲပါသည်',
    remark_en: "",
    en: 'be attached',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO9.mp3")
  },
  {
    furi: '＾外（はず）｜れます',
    normal: '外れます',
    mm: 'ပြုတ်သည်',
    remark_en: "",
    en: 'be undone',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO10.mp3")
  },
  {
    furi: '＾止（と）｜まります',
    normal: '止まります',
    mm: 'ရပ်တန့်သည်',
    remark_en: "",
    en: 'stop(car)',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO11.mp3")
  },
  {
    furi: '＾間（ま）違（ち）｜えます',
    normal: '間違えます',
    mm: 'လွဲမှားသည်',
    remark_en: "",
    en: 'make a mistake',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO12.mp3")
  },
  {
    furi: '＾落（お）｜とします',
    normal: '落とします',
    mm: 'ပြုတ်ကျသည်',
    remark_en: "",
    en: 'drop, lose',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO13.mp3")
  },
  {
    furi: '＾掛（か）｜かります',
    normal: '掛かります',
    mm: 'ခတ်(ပိတ်)သည်',
    remark_en: "",
    en: 'be locked',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO14.mp3")
  },
  {
    furi: 'ふきます',
    normal: 'ふきます',
    mm: 'သုတ်သည်',
    remark_en: "",
    en: 'Wipe',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO15.mp3")
  },
  {
    furi: '＾取（と）｜り｜＾替（か）｜えます',
    normal: '取り替えます',
    mm: 'လဲလှယ်သည်',
    remark_en: "",
    en: 'replace',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO16.mp3")
  },
  {
    furi: '＾片（かた）｜づけます',
    normal: '片づけます',
    mm: 'ရှင်းလင်းဖယ်ရှားသည်',
    remark_en: "",
    en: 'clean up',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO17.mp3")
  },
  {
    furi: '[お]さら',
    normal: '[お]さら',
    mm: 'ပန်းကန်ပြား',
    remark_en: "",
    en: 'plate, dish',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO18.mp3")
  },
  {
    furi: '[お]ちゃわん',
    normal: '[お]ちゃわん',
    mm: 'ထမင်းပန်းကန်လုံး',
    remark_en: "",
    en: 'rice bowl',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO19.mp3")
  },
  {
    furi: 'コップ',
    normal: 'コップ',
    mm: '(ဖန်)ခွက်',
    remark_en: "",
    en: 'glass(vessel)',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO20.mp3")
  },
  {
    furi: 'ガラス',
    normal: 'ガラス',
    mm: 'ဖန်၊မှန်',
    remark_en: "",
    en: 'glass(material)',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO21.mp3")
  },
  {
    furi: 'ふくろ',
    normal: 'ふくろ',
    mm: 'အိတ်',
    remark_en: "",
    en: 'bag',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO22.mp3")
  },
  {
    furi: '＾書（しょ）類（るい）',
    normal: '書類',
    mm: 'စာရွက်စာတမ်း',
    remark_en: "",
    en: 'document',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO23.mp3")
  },
  {
    furi: '＾枝（えだ）',
    normal: '枝',
    mm: 'သစ်ကိုင်း',
    remark_en: "",
    en: 'branch, twig',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO24.mp3")
  },
  {
    furi: '＾駅（えき）員（いん）',
    normal: '駅員',
    mm: 'ဘူတာဝန်ထမ်း',
    remark_en: "",
    en: 'station employee',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO25.mp3")
  },
  {
    furi: '＾交（こう）番（ばん）',
    normal: '交番',
    mm: 'ရဲကင်း၊ရဲစခန်းငယ်',
    remark_en: "",
    en: 'Police box',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO26.mp3")
  },
  {
    furi: 'スピーチ',
    normal: 'スピーチ',
    mm: 'ဟောပြောမှု',
    remark_en: "",
    en: 'speak',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO27.mp3")
  },
  {
    furi: '＾返（へん）事（じ）',
    normal: '返事',
    mm: 'အကြောင်းပြန်ခြင်း',
    remark_en: "",
    en: 'reply',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO28.mp3")
  },
  {
    furi: 'お｜＾先（さき）｜にどうぞ。',
    normal: 'お先にどうぞ。',
    mm: 'အရင်လုပ်နှင့်ပါ',
    remark_en: "",
    en: 'After you/Go ahead, please',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO29.mp3")
  },
  {
    furi: '＾源（げん）氏（じ）物（もの）語（がたり）',
    normal: '源氏物語',
    mm: 'ဂန်းဂျိမိုနိုဂတရိ',
    remark_en: "",
    en: 'Genji Monogatari',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO30.mp3")
  },
  {
    furi: '＾今（いま）｜の｜＾電（でん）車（しゃ）',
    normal: '今の電車',
    mm: 'အခုထွက်တဲ့ရထား',
    remark_en: "",
    en: 'the train which has just left',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO31.mp3")
  },
  {
    furi: '＾忘（わす）｜れ｜＾物（もの）',
    normal: '忘れ物',
    mm: 'မေ့ကျန်ပစ္စည်း',
    remark_en: "",
    en: 'things left behind, lost property',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO32.mp3")
  },
  {
    furi: 'このくらい',
    normal: 'このくらい',
    mm: 'ဒီလောက်လောက်',
    remark_en: "",
    en: 'about this size',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO33.mp3")
  },
  {
    furi: '～｜＾側（がわ）',
    normal: '～側',
    mm: '～ဘက်',
    remark_en: "",
    en: '~side',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO34.mp3")
  },
  {
    furi: 'ポケット',
    normal: 'ポケット',
    mm: 'အိတ်ကပ်',
    remark_en: "",
    en: 'pocket',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO35.mp3")
  },
  {
    furi: '～｜＾辺（へん）',
    normal: '～辺',
    mm: '～အနားတစ်ဝိုက်',
    remark_en: "",
    en: 'the place around ~',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO36.mp3")
  },
  {
    furi: '＾覚（おぼ）｜えていません。',
    normal: '覚えていません。',
    mm: 'မမှတ်မိဘူး',
    remark_en: "",
    en: 'I don’t remember',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO37.mp3")
  },
  {
    furi: '＾網（あみ）棚（だな）',
    normal: '網棚',
    mm: '(ပိုက်)ပစ္စည်းတင်စင်',
    remark_en: "",
    en: 'rack',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO38.mp3")
  },
  {
    furi: '＾確（たし）｜か',
    normal: '確か',
    mm: 'အဟုတ်အမှန်',
    remark_en: "",
    en: 'I suppose, if I am correct',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO39.mp3")
  },
  {
    furi: '[ああ、]よかった。',
    normal: '[ああ、]よかった。',
    mm: '[အမလေး]တော်ပါသေးရဲ့',
    remark_en: "",
    en: 'Thank goodness!',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO40.mp3")
  },
  {
    furi: '＾新（しん）宿（じゅく）',
    normal: '新宿',
    mm: 'ရှင်းဂျုခု',
    remark_en: "",
    en: 'Shinjuku',
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson29/vocab/4N29VO41.mp3")
  },
  {
    furi: '＾地（じ）震（しん）',
    normal: '地震',
    mm: 'မြေငလျင်',
    remark_en: "",
    en: 'earthquake',
    remark_mm: "",
    group: 0
  },
  {
    furi: '＾壁（かべ）',
    normal: '壁',
    mm: 'နံရံ',
    remark_en: "",
    en: 'wall',
    remark_mm: "",
    group: 0
  },
  {
    furi: '＾針（はり）',
    normal: '針',
    mm: 'လက်တံ',
    remark_en: "",
    en: 'hands(of a clock)',
    remark_mm: "",
    group: 0
  },
  {
    furi: '＾指（さ）｜します',
    normal: '指します',
    mm: 'ညွှန်ပြသည်',
    remark_en: "",
    en: 'point',
    remark_mm: "",
    group: 0
  },
  {
    furi: '＾駅（えき）前（まえ）',
    normal: '駅前',
    mm: 'ဘူတာရှေ့',
    remark_en: "",
    en: 'the area in front of a station',
    remark_mm: "",
    group: 0
  },
  {
    furi: '＾倒（たお）｜れます',
    normal: '倒れます',
    mm: 'လဲပြိုကျသည်',
    remark_en: "",
    en: 'fall down',
    remark_mm: "",
    group: 0
  },
  {
    furi: '＾西（にし）',
    normal: '西',
    mm: 'အနောက်',
    remark_en: "",
    en: 'west',
    remark_mm: "",
    group: 0
  },
  {
    furi: '～の｜＾方（ほう）',
    normal: '～の方',
    mm: '～(ရဲ့)ဘက်',
    remark_en: "",
    en: '~direction',
    remark_mm: "",
    group: 0
  },
  {
    furi: '＾燃（も）｜えます',
    normal: '燃えます',
    mm: 'လောင်ကျွမ်းသည်',
    remark_en: "",
    en: 'burn',
    remark_mm: "",
    group: 0
  },
  {
    furi: 'レポーター',
    normal: 'レポーター',
    mm: 'သတင်းတင်ဆက်သူ',
    remark_en: "",
    en: 'reporter',
    remark_mm: "",
    group: 0
  }   
]