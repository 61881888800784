import Icon from "@consts/icon";

export const lesson47 = {
    title: {
        furi: '＾婚（こん）約（やく）｜したそうです',
        normal: '婚約したそうです',
        en: "It looks like they're engaged.",
        mm: 'စေ့စပ်ထားတဲ့ပုံပဲ၊',
    },
    conversation: [
        {
            person: {
                furi: '＾渡（わた）辺（なべ）',
                normal: '渡辺',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'お｜＾先（さき）｜に｜＾失（しつ）礼（れい）｜します。',
                    normal: 'お先に失礼します。',
                    en: 'Pardon me, I have to go now.',
                    mm: 'အရင်သွားခွင့်ပြုပါ။',
                },
            ],
        },
        {
            person: {
                furi: '＾高（たか）橋（はし）',
                normal: '高橋',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'あっ、｜＾渡（わた）辺（なべ）｜さん、ちょっと｜＾待（ま）｜って。｜＾僕（ぼく）｜も　｜＾帰（かえ）｜ります。',
                    normal: 'あっ、渡辺さん、ちょっと　待って。僕も　帰ります。',
                    en: "Ah, Watanabe-san, wait a minute. I'm going home too.",
                    mm: 'အာ့၊ ဝါတာနာဘဲစံ ခဏစောင့်။ ငါလည်း အိမ်ပြန်မယ်။',
                },
            ],
        },
        {
            person: {
                furi: '＾渡（わた）辺（なべ）',
                normal: '渡辺',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'すみません、ちょっと｜＾急（いそ）｜ぎますから。',
                    normal: 'すみません、ちょっと　急ぎますから。',
                    en: "Excuse me, I'm in a bit of a hurry.",
                    mm: 'ခွင့်လွှတ်ပါ၊ ငါနည်းနည်းအလျင်လိုနေလို့။',
                },
            ],
        },
        {
            person: {
                furi: '＾高（たか）橋（はし）',
                normal: '高橋',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '＾渡（わた）辺（なべ）｜さん、このごろ　｜＾早（はや）｜く　｜＾帰（かえ）｜りますね。',
                    normal: '渡辺さん、このごろ　早く　帰りますね。',
                    en: "Watana Be San goes home early these days.",
                    mm: 'ဝါတာနာဘဲစံက ဒီရက်တွေ စောစော အိမ်ပြန်တယ်။',
                },
                {
                    furi: 'どうも　｜＾恋（こい）人（びと）｜が　できたようですね。',
                    normal: 'どうも　恋人が　できたようですね。',
                    en: 'It seems that he has found a girlfriend.',
                    mm: 'ရည်းစားတွေ့ပြီထင်သည်။',
                },
            ],
        },
        {
            person: {
                furi: '＾林（はやし）',
                normal: '林',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: 'あ、｜＾知（し）｜らないんですか。',
                    normal: 'あ、知らないんですか。',
                    en: "Oh, don't you know",
                    mm: 'အိုး မင်းမသိဘူးလား။',
                },
                {
                    furi: 'この｜＾間（あいだ）｜　｜＾婚（こん）約（やく）｜したそうですよ。',
                    normal: 'この間　婚約したそうですよ。',
                    en: 'It is said that he got engaged during this time.',
                    mm: 'ဒီအတောအတွင်း စေ့စပ်ကြောင်းလမ်းတယ်လို့ သိရပါတယ်။',
                },
            ],
        },
        {
            person: {
                furi: '＾高（たか）橋（はし）',
                normal: '高橋',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'えっ、だれですか、｜＾相（あい）手（て）｜は。',
                    normal: 'えっ、だれですか、相手は。',
                    en: 'Eh, who is it?',
                    mm: 'အယ်၊ အဲဒါ ဘယ်သူလဲ။',
                },
            ],
        },
        {
            person: {
                furi: '＾林（はやし）',
                normal: '林',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: 'IMCの　｜＾鈴（すず）木（き）｜さんですよ。',
                    normal: 'IMCの　鈴木さんですよ。',
                    en: 'This is Mr. Suzuki from IMC.',
                    mm: 'IMC မှ မစ္စတာဆူဇူကီးဖြစ်တယ်။',
                },
            ],
        },
        {
            person: {
                furi: '＾高（たか）橋（はし）',
                normal: '高橋',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'えっ、｜＾鈴（すず）木（き）｜さん？',
                    normal: 'えっ、鈴木さん？',
                    en: 'Eh, Mr. Suzuki?',
                    mm: 'မစ္စတာဆူဇူကီး။',
                },
            ],
        },
        {
            person: {
                furi: '＾林（はやし）',
                normal: '林',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: 'ワットさんの　｜＾結（けっ）婚（こん）式（しき）｜で　｜＾知（し）｜り｜＾合（あ）｜ったそうですよ。',
                    normal: 'ワットさんの　結婚式で　知り合ったそうですよ。',
                    en: "I heard they met at Mr. Watt's wedding.",
                    mm: 'မစ္စတာဝပ်ရဲ့ မင်္ဂလာဆောင်မှာ တွေ့ကြတယ်လို့ ကြားတယ်။',
                },
            ],
        },
        {
            person: {
                furi: '＾高（たか）橋（はし）',
                normal: '高橋',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'そうですか。',
                    normal: 'そうですか。',
                    en: 'is that so.',
                    mm: 'ဟုတ်လား။',
                },
            ],
        },
        {
            person: {
                furi: '＾林（はやし）',
                normal: '林',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: 'ところで、｜＾高（たか）橋（はし）｜さんは？',
                    normal: 'ところで、高橋さんは？',
                    en: 'By the way, what about Mr. Takahashi?',
                    mm: 'စကားမစပ်၊ မစ္စတာ Takahashi ကော ဘယ်လိုလဲ။',
                },
            ],
        },
        {
            person: {
                furi: '＾高（たか）橋（はし）',
                normal: '高橋',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '＾僕（ぼく）｜ですか。｜＾僕（ぼく）｜は　｜＾仕（し）事（ごと）｜が　｜＾恋（こい）人（びと）｜です。',
                    normal: '僕ですか。僕は　仕事が　恋人です。',
                    en: 'Is it me. I love my job.',
                    mm: 'ကျွန်တော်လား။ အလုပ်က ကျွန်တော့်ချစ်သူပါ။',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson47/kaiwa/4N47KW.mp3")
}
/*
{
    title: {
        furi: '',
        normal: '',
        en: '',
        mm: '',
    },
    conversation: [
        {
            person: {
                furi: '',
                normal: '',
                avatar: Icon.Kaiwa.OFFICER
            },
            sentence: [
                {
                    furi: '',
                    normal: '',
                    en: '',
                    mm: '',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson36/kaiwa/4N36KW.mp3")
}
*/