import Icon from "@consts/icon";

export const lesson36 = {
    title: {
        furi: '＾毎（まい）日（にち）｜　｜＾運（うん）動（どう）｜するように　して　います',
        normal: '毎日　運動するように　して　います',
        en: 'I try to exercise every day',
        mm: 'နေ့တိုင်း အားကစားလုပ်ဖြစ်အောင်လုပ်နေပါတယ်',
    },
    conversation: [
        {
            person: {
                furi: 'アナウンサー',
                normal: 'アナウンサー',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '＾皆（みな）｜さん、　こんにちは!　きょうの　お｜＾客（きゃく）様（さま）｜は　ことし　８０｜＾歳（さい）｜の　｜＾小（お）川（がわ）｜よねさんです。',
                    normal: '皆さん、　こんにちは！　きょうの　お客様は　ことし　８０歳の　小川よねさんです。',
                    en: 'Hello everyone! Her guest today is her 80-year-old Ogawa Yone .',
                    mm: 'အားလုံးပဲ မင်္ဂလာပါ၊ ဒီနေ့ရဲ့ ဧည့်သည်တော်ကတော့ ဒီနှစ်မှာ အသက် ၈၀ပြည့်ပြီဖြစ်တဲ့ အိုဂဝရော့နဲစံ ဖြစ်ပါတယ်။',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）｜よね',
                normal: '小川よね',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'こんにちは',
                    normal: 'こんにちは',
                    en: 'Hello',
                    mm: 'မင်္ဂလာပါ',
                },
            ],
        },
        {
            person: {
                furi: 'アナウンサー',
                normal: 'アナウンサー',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'お｜＾元（げん）気（き）｜ですね。　｜＾何（なに）｜か　｜＾特（とく）別（べつ）｜な　ことを　して　いらっしゃいますか。',
                    normal: 'お元気ですね。　何か　特別な　ことを　して　いらっしゃいますか。',
                    en: 'How are you? Are you doing anything special?',
                    mm: 'ကျန်းမာတယ်နော်၊ ထူးထူးခြားခြား တစ်ခုခုလုပ်ဖြစ်တာလေးတွေများရှိလား?',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）｜よね',
                normal: '小川よね',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: '＾毎（まい）日（にち）｜　｜＾運（うん）動（どう）｜するように　して　います。',
                    normal: '毎日　運動するように　して　います。',
                    en: 'I try to exercise every day',
                    mm: 'နေ့တိုင်း အားကစားလုပ်ဖြစ်အောင်လုပ်နေပါတယ်။',
                },
            ],
        },
        {
            person: {
                furi: 'アナウンサー',
                normal: 'アナウンサー',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'どんな　｜＾運（うん）動（どう）｜ですか。',
                    normal: 'どんな　運動ですか。',
                    en: 'What kind of exercise?',
                    mm: 'ဘယ်လို အားကစားမျိုးလည်း။',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）｜よね',
                normal: '小川よね',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'ダンスとか、｜＾水（すい）泳（えい）｜とか。。。',
                    normal: 'ダンスとか、水泳とか。。。',
                    en: 'dancing or swimming. . .',
                    mm: 'အက တို့ ရေကူးတာတို့ . . .',
                },
                {
                    furi: '＾最（さい）近（きん）｜　５００メートル　｜＾泳（およ）｜げるように　なりました。',
                    normal: '最近　５００メートル　泳げるように　なりました。',
                    en: 'Recently, I became able to swim 500 meters.',
                    mm: 'ခုလက်ရှိမှာ မီတာ၅၀၀ အထိရေကူးနိုင်လာခဲ့ပါပြီ။',
                },
            ],
        },
        {
            person: {
                furi: 'アナウンサー',
                normal: 'アナウンサー',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'すごいですね。｜＾食（た）｜べ｜＾物（もの）｜は？',
                    normal: 'すごいですね。食べ物は？',
                    en: "It's amazing. food is?",
                    mm: 'အံသြစရာပဲ၊ အစားအသောက်ကြတော့ကော?',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）｜よね',
                normal: '小川よね',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: '＾何（なん）｜でも　｜＾食（た）｜べますが、｜＾特（とく）｜に　｜＾魚（さかな）｜が　｜＾好（す）｜きです。',
                    normal: '何でも　食べますが、特に　魚が　好きです。',
                    en: 'I eat anything, but I especially like fish.',
                    mm: 'ဘာပဲဖြစ်ဖြစ်စားပေမယ့်လည်း အထူးသဖြင့် ငါးကိုပိုနှစ်သက်ပါတယ်။',
                },
                {
                    furi: '＾毎（まい）日（にち）｜　｜＾違（ちが）｜う　｜＾料（りょう）理（り）｜を　｜＾作（つく）｜るように　して　います。',
                    normal: '毎日　違う　料理を　作るように　して　います。',
                    en: 'I try to cook different dishes every day.',
                    mm: 'တစ်ရက်တစ်မျိုး ဟင်းကို မတူအောင်ချက်ပြုတ်စားသောက်လေ့ရှိပါတယ်။',
                },
            ],
        },
        {
            person: {
                furi: 'アナウンサー',
                normal: 'アナウンサー',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '＾頭（あたま）｜と　｜＾体（からだ）｜を　よく　｜＾使（つか）｜って　いらっしゃるんですね。',
                    normal: '頭と　体を　よく　使って　いらっしゃるんですね。',
                    en: 'I see you are using your head and body a lot.',
                    mm: 'ဦး‌နှောက်နဲ့ ကိုယ်ကာယကို ကောင်းကောင်း အသုံးချပြီးနေနေတာပဲ',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）｜よね',
                normal: '小川よね',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'ええ。｜＾来（らい）年（ねん）｜　フランスへ　｜＾行（い）｜きたいと　｜＾思（おも）｜って　います。',
                    normal: 'ええ。来年　フランスへ　行きたいと　思って　います。',
                    en: 'yes. I would like to go to France next year.',
                    mm: 'အင်း၊ လာမယ့်နှစ်မှာ ပြင်သစ်ကို သွားချင်တယ်လို့တွေးထားပါတယ်',
                },
                {
                    furi: 'それで　フランス｜＾語（ご）｜の　｜＾勉（べん）強（きょう）｜も　｜＾始（はじ）｜めました。',
                    normal: 'それで　フランス語の　勉強も　始めました。',
                    en: 'So I also started studying French.',
                    mm: 'အဲ့ဒါကြောင့် ပြင်သစ်ဘာသာစကားလေ့လာတာတောင် စနေပါပြီ',
                },
            ],
        },
        {
            person: {
                furi: 'アナウンサー',
                normal: 'アナウンサー',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '＾何（なん）｜でも　チャレンジする　｜＾気（き）持（も）｜ちが　｜＾大（たい）切（せつ）｜なんですね。',
                    normal: '何でも　チャレンジする　気持ちが　大切なんですね。',
                    en: "The feeling of taking on any challenge is important, isn't it?",
                    mm: 'ဘာမဆို စိန်ခေါ်တဲ့ စိတ်ဓါတ်က အရေးကြီးတာပဲနော်',
                },
                {
                    furi: '＾楽（たの）｜しい　お｜＾話（はなし）｜、どうも　ありがとう　ございました。',
                    normal: '楽しい　お話、どうも　ありがとう　ございました。',
                    en: 'It was a fun story, thank you very much.',
                    mm: 'ပျော်စရာကောင်းတဲ့ စကားအတွက် ကျေးဇူးအများကြီးတင်ပါတယ်',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson36/kaiwa/4N36KW.mp3")
}

/*

{
    title: {
        furi: '',
        normal: '',
        en: '',
        mm: '',
    },
    conversation: [
        {
            person: {
                furi: '',
                normal: '',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '',
                    normal: '',
                    en: '',
                    mm: '',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson36/kaiwa/4N36KW.mp3")
}

*/