export const reibun= [
    {
        sentence: [
            {
                furi: "＾地（じ）震（しん）｜で　｜＾電（でん）車（しゃ）｜が　｜＾止（と）｜まった　｜＾場（ば）合（あい）｜は、｜＾無（む）理（り）｜に　｜＾帰（かえ）｜らないで、｜＾会（かい）社（しゃ）｜に　｜＾泊（と）｜まって　ください。",
                normal: "地震で　電車が　止まった　場合は、無理に　帰らないで、会社に　泊まって　ください。",
                en: "If the train stops due to an earthquake, do not force yourself to go home and stay at the company.",
                mm: "ငလျင်‌ကြောင့် ရထားတွေ မထွက်ခဲ့ရင် အိမ်ကိုအတင်း မပြန်ဘဲ ကုမ္ပဏီမှာဘဲ တည်းခိုပါ။",
            },
            {
                furi: "はい、わかりました。",
                normal: "はい、わかりました。",
                en: "Yes, I understand.",
                mm: "ဟုတ်ကဲ့, နားလည်ပါပြီ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson45/bunpou/4N45RB1.mp3")
    },
    {
        sentence: [
            {
                furi: "これが　この　コンピューターの　｜＾保（ほ）証（しょう）書（しょ）｜です。",
                normal: "これが　この　コンピューターの　保証書です。",
                en: "This is the warranty for this computer.",
                mm: "ဒီဟာက ဒီကွန်ပျူတာအတွက် အာမခံချက်ဖြစ်တယ်။",
            },
            {
                furi: "＾調（ちょう）子（し）｜が　｜＾悪（わる）｜い　｜＾場（ば）合（あい）｜は、この　｜＾番（ばん）号（ごう）｜に　｜＾連（れん）絡（らく）｜して　ください。",
                normal: "調子が　悪い　場合は、この　番号に　連絡して　ください。",
                en: "If you feel unwell, please call this number.",
                mm: "အခြေအနေဆိုးလာရင် ဒီနံပါတ်ကို ဆက်သွယ်ပါ။",
            },
            {
                furi: "はい、わかりました。",
                normal: "はい、わかりました。",
                en: "Yes, I understand.",
                mm: "ဟုတ်ကဲ့, နားလည်ပါပြီ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson45/bunpou/4N45RB2.mp3")
    },
    {
        sentence: [
            {
                furi: "あのう、この　｜＾図（と）書（しょ）館（かん）｜では　コピーの　｜＾領（りょう）収（しゅう）書（しょ）｜が　もらえますか。",
                normal: "あのう、この　図書館では　コピーの　領収書が　もらえますか。",
                en: "Umm, can I get a copy of the receipt at this library?",
                mm: "အင်း၊ ဒီစာကြည့်တိုက်မှာ ပြေစာမိတ္တူ ရနိုင်မလား။",
            },
            {
                furi: "ええ。｜＾必（ひつ）要（よう）｜な　｜＾場（ば）合（あい）｜は、｜＾言（い）｜って　ください。",
                normal: "ええ。必要な　場合は、言って　ください。",
                en: "Yeah. Please tell me if you need it.",
                mm: "အင်း။ လိုအပ်ရင် ပြောပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson45/bunpou/4N45RB3.mp3")
    },
    {
        sentence: [
            {
                furi: "＾火（か）事（じ）｜や　｜＾地（じ）震（しん）｜の　｜＾場（ば）合（あい）｜は、｜＾絶（ぜっ）対（たい）｜に　エレベーターを　｜＾使（つか）｜わないで　ください。",
                normal: "火事や　地震の　場合は、絶対に　エレベーターを　使わないで　ください。",
                en: "In case of fire or earthquake, never use the elevator.",
                mm: "မီးလောင်မှု သို့မဟုတ် မြေငလျင်လှုပ်သောအခါ ဓာတ်လှေကားကို ဘယ်တော့မှ မသုံးပါနှင့်။",
            },
            {
                furi: "はい、わかりました。",
                normal: "はい、わかりました。",
                en: "Yes, I understand.",
                mm: "ဟုတ်ကဲ့, နားလည်ပါပြီ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson45/bunpou/4N45RB4.mp3")
    },
    {
        sentence: [
            {
                furi: "スピーチは　うまく　いきましたか。",
                normal: "スピーチは　うまく　いきましたか。",
                en: "Did your speech go well?",
                mm: "မင်းစကားပြောတာ အဆင်ပြေသွားလား။",
            },
            {
                furi: "いいえ。｜＾一（いっ）生（しょう）懸（けん）命（めい）｜　｜＾練（れん）習（しゅう）｜して　｜＾覚（おぼ）｜えたのに、｜＾途（と）中（ちゅう）｜で　｜＾忘（わす）｜れて　しましました。",
                normal: "いいえ。一生懸命　練習して　覚えたのに、途中で　忘れて　しましました。",
                en: "no. I practiced hard and memorized it, but I forgot halfway through.",
                mm: "မပြေဘူး။ ပြင်းပြင်းထန်ထန် လေ့ကျင့်ပြီး ကျက်ခဲ့ပေမယ့် တစ်ဝက်တစ်ပျက်မှာ မေ့သွားခဲ့တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson45/bunpou/4N45RB5.mp3")
    },
    {
        sentence: [
            {
                furi: "＾冬（ふゆ）｜なのに、｜＾桜（さくら）｜が　｜＾咲（さ）｜いて　いますね。",
                normal: "冬なのに、桜が　咲いて　いますね。",
                en: "Even though it's winter, the cherry blossoms are blooming.",
                mm: "ဆောင်းရာသီဆိုပေမယ့် ချယ်ရီပန်းတွေ ပွင့်နေတယ်။",
            },
            {
                furi: "えっ、あれは｜＾桜（さくら）｜じゃ　ありません。｜＾梅（うめ）｜ですよ。",
                normal: "えっ、あれは　桜じゃ　ありません。梅ですよ。",
                en: "Eh, those aren't cherry blossoms. It's plum.",
                mm: "အင်း၊ အဲဒါတွေက ချယ်ရီပန်းတွေ မဟုတ်ဘူး။ ဇီးသီး‌တွေပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson45/bunpou/4N45RB6.mp3")
    },
]