export const lesson2 = [
    {"lesson":2,"level":4,"question":"ほそい","represent":["細"],"answer":["細い","遠い","強い","暗い"],"true":"細い","times":10},
    {"lesson":2,"level":4,"question":"じゅうりょく","represent":["重","力"],"answer":["太陽","弱点","細部","重力"],"true":"重力","times":10},
    {"lesson":2,"level":4,"question":"さいぶ","represent":["細"],"answer":["太陽","遠足","弱点","細部"],"true":"細部","times":10},
    {"lesson":2,"level":4,"question":"たいじゅう","represent":["体","重"],"answer":["近所","遠足","太陽","体重"],"true":"体重","times":10},
    {"lesson":2,"level":4,"question":"つよい","represent":["強"],"answer":["弱い","細い","強い","遠い"],"true":"強い","times":10},
    {"lesson":2,"level":4,"question":"たいよう","represent":["太"],"answer":["弱点","太陽","暗室","体重"],"true":"太陽","times":10},
    {"lesson":2,"level":4,"question":"とおい","represent":["遠"],"answer":["細い","太い","遠い","軽い"],"true":"遠い","times":10},
    {"lesson":2,"level":4,"question":"えんそく","represent":["遠","足"],"answer":["体重","太陽","軽食","遠足"],"true":"遠足","times":10},
    {"lesson":2,"level":4,"question":"くらい","represent":["暗"],"answer":["弱い","細い","暗い","強い"],"true":"暗い","times":10},
    {"lesson":2,"level":4,"question":"かるい","represent":["軽"],"answer":["弱い","強い","軽い","細い"],"true":"軽い","times":10},
    {"lesson":2,"level":4,"question":"きんじょ","represent":["近","所"],"answer":["近所","暗室","体重","弱点"],"true":"近所","times":10},
    {"lesson":2,"level":4,"question":"じゃくてん","represent":["弱"],"answer":["細部","軽食","近所","弱点"],"true":"弱点","times":10},
    {"lesson":2,"level":4,"question":"ちかい","represent":["近"],"answer":["軽い","細い","太い","近い"],"true":"近い","times":10},
    {"lesson":2,"level":4,"question":"あんしつ","represent":["暗","室"],"answer":["軽食","暗室","太陽","弱点"],"true":"暗室","times":10},
    {"lesson":2,"level":4,"question":"けいしょく","represent":["軽","食"],"answer":["遠足","重力","軽食","近所"],"true":"軽食","times":10},
    {"lesson":2,"level":4,"question":"よわい","represent":["弱"],"answer":["暗い","細い","弱い","近い"],"true":"弱い","times":10},
    {"lesson":2,"level":4,"question":"ふとい","represent":["太"],"answer":["強い","細い","近い","太い"],"true":"太い","times":10},
    {"lesson":2,"level":4,"question":"とおく","represent":["遠"],"answer":["強い","細い","近い","遠く"],"true":"遠く","times":10},
]