export const reibun= [
    {
        sentence: [
            {
                furi: 'これは　｜＾新（あた）｜しい　ロボットです。',
                normal: 'これは　新しい　ロボットです。',
                en: 'This is a new robot.',
                mm: 'ဒါက စက်ရုပ်အသစ်ပါ။',
            },
            {
                furi: 'どんな　ロボットですか。',
                normal: 'どんな　ロボットですか。',
                en: 'What kind of robot is it?',
                mm: 'ဘယ်လိုစက်ရုပ်မျိုးလဲ။',
            },
            {
                furi: '＾人（ひと）｜が　した｜！とおりに｜、｜＾何（なん）｜でも　するんです。',
                normal: '人が　した　とおりに、何でも　するんです。',
                en: 'It does whatever people do.',
                mm: 'လူတွေလုပ်သမျှကို လုပ်နိုင်တယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson34/bunpou/4N34RB1.mp3")
    },
    {
        sentence: [
            {
                furi: 'この　テーブルは　｜＾自（じ）分（ぶん）｜で　｜＾組（く）｜み｜＾立（た）｜てるんですか。',
                normal: 'この　テーブルは　自分で　組み立てるんですか。',
                en: 'Do I have to assemble this table myself?',
                mm: 'ဒီစားပွဲကို ကိုယ်တိုင် တပ်ဆင်ရမှာလား။',
            },
            {
                furi: 'ええ。｜＾説（せつ）明（めい）書（しょ）｜の　｜！とおりに｜、｜＾組（く）｜み｜＾立（た）｜てて　ください。',
                normal: 'ええ。説明書の　とおりに、組み立てて　ください。',
                en: 'Yeah. Please assemble it according to the instructions.',
                mm: 'အင်း။ ညွှန်ကြားချက်အတိုင်း တပ်ဆင်ပါ။',
            },
        ],
        audioUrl: require("@assets/audio/lesson34/bunpou/4N34RB2.mp3")
    },
    {
        sentence: [
            {
                furi: 'ちょっと　｜＾待（ま）｜って　ください。しょうゆは　｜＾砂（さ）糖（とう）｜を　｜＾入（い）｜れた　あとで、｜＾入（い）｜れるんですよ。',
                normal: 'ちょっと　待って　ください。しょうゆは　砂糖を　入れた　あとで、入れるんですよ。',
                en: 'wait a minute. Soy sauce is added after adding sugar.',
                mm: 'ခဏစောင့်ပါဦး။ သကြားထည့်ပြီးမှ ပဲငံပြာရည်ထည့်ပါ။',
            },
            {
                furi: 'はい、わかりました。',
                normal: 'はい、わかりました。',
                en: 'Yes, I understand.',
                mm: 'ဟုတ်ကဲ့, နားလည်ပါပြီ။',
            },
        ],
        audioUrl: require("@assets/audio/lesson34/bunpou/4N34RB3.mp3")
    },
    {
        sentence: [
            {
                furi: '＾仕（し）事（ごと）｜の｜！あとで｜、｜＾飲（の）｜みに　｜＾行（い）｜きませんか。',
                normal: '仕事の　あとで、飲みに　行きませんか。',
                en: 'Would you like to go out for a drink after work?',
                mm: 'အလုပ်ပြီးရင် အပြင်သွားသောက်ချင်လား။',
            },
            {
                furi: 'すみません。きょうは　スポーツクラブへ　｜＾行（い）｜く　｜＾日（ひ）｜なんです。',
                normal: 'すみません。きょうは　スポーツクラブへ　行く　日なんです。',
                en: 'Excuse me. Today is the day to go to the sports club.',
                mm: 'ခွင့်ပြုပါ၊ ဒီနေ့က အားကစားကလပ်ကိုသွားမယ့်နေ့မို့လို့ပါ။',
            },
        ],
        audioUrl: require("@assets/audio/lesson34/bunpou/4N34RB4.mp3")
    },
    {
        sentence: [
            {
                furi: '＾友（とも）達（だち）｜の　｜＾結（けっ）婚（こん）式（しき）｜に　｜＾何（なに）｜を　｜＾着（き）｜て　｜＾行（い）｜ったら　いいですか。',
                normal: '友達の　結婚式に　何を　着て　行ったら　いいですか。',
                en: "What should I wear to my friend's wedding?",
                mm: 'သူငယ်ချင်းမင်္ဂလာဆောင်မှာ ဘာဝတ်ရမလဲ။',
            },
            {
                furi: 'そうですね。｜＾日（に）本（ほん）｜では　｜＾男（おとこ）｜の　｜＾人（ひと）｜は　｜＾黒（くろ）｜か　｜＾紺（こん）｜の　スーツを　｜＾着（き）｜て、｜＾白（しろ）｜い　ネクタイを　して　｜＾行（い）｜きます。',
                normal: 'そうですね。日本では　男の　人は　黒か　紺の　スーツを　着て、白い　ネクタイを　して　行きます。',
                mm: 'ဟုတ်တယ်နော်။ ဂျပန်မှာ အမျိုးသားတွေက အနက်ရောင် သို့မဟုတ် ရေတပ်အပြာရောင်ဝတ်စုံနှင့် အဖြူရောင်နက်ကတိုင်ကို ၀တ်ဆင်ကြတယ်။',
                en: 'yes. In Japan, men wear black or navy blue suits and white ties.',
            },
        ],
        audioUrl: require("@assets/audio/lesson34/bunpou/4N34RB5.mp3")
    },
    {
        sentence: [
            {
                furi: 'これは　ソースを　つけるんですか。',
                normal: 'これは　ソースを　つけるんですか。',
                en: 'Do you put sauce on this?',
                mm: 'ဒီအပေါ်မှာ ဆော့စ်ထည့်ရလား။',
            },
            {
                furi: 'いいえ、｜＾何（なに）｜も　つけないで　｜＾食（た）｜べて　ください。',
                normal: 'いいえ、何も　つけないで　食べて　ください。',
                en: 'No, please eat it without dipping it in anything.',
                mm: 'မဟုတ်ဘူး၊ ဘာမှ မထည့်ဘဲ စားပေးပါ။',
            },
        ],
        audioUrl: require("@assets/audio/lesson34/bunpou/4N34RB6.mp3")
    },
    {
        sentence: [
            {
                furi: '＾最（さい）近（きん）｜　エレベーターに　｜！乗（の）らないで｜、｜＾階（かい）段（だん）｜を　｜＾使（つか）｜って　いるんです。',
                normal: '最近　エレベーターに　乗らないで、階段を　使って　いるんです。',
                en: "Recently, I've been using the stairs instead of taking the elevator.",
                mm: 'ခုတလော ကျွန်တော် ဓာတ်လှေကားတက်မည့်အစား လှေကားကို အသုံးပြုနေပါတယ်။',
            },
            {
                furi: 'いい　｜＾運（うん）動（どう）｜に　なりますね。',
                normal: 'いい　運動に　なりますね。',
                en:"It's good exercise.",
                mm: 'ကောင်းတဲ့လေ့ကျင့်ခန်းဘဲ။',
         
            },
        ],
        audioUrl: require("@assets/audio/lesson34/bunpou/4N34RB7.mp3")
    },
]