
export const lesson17 = [
    {"lesson":17,"level":4,"question":"動物","represent":["動","物"],"answer":["どうぶつ","はつばい","がいこう","こうつう"],"true":"どうぶつ","times":10},
    {"lesson":17,"level":4,"question":"運転","represent":["運","転"],"answer":["うんてん","どうぶつ","うんそう","かいてん"],"true":"うんてん","times":10},
    {"lesson":17,"level":4,"question":"帰る","represent":["帰"],"answer":["かえる","ふる","とおる","のる"],"true":"かえる","times":10},
    {"lesson":17,"level":4,"question":"行動する","represent":["行","動"],"answer":["こうどうする","しゅっぱつなる","つうがくする","きこくする"],"true":"こうどうする","times":10},
    {"lesson":17,"level":4,"question":"外交","represent":["外","交"],"answer":["がいこう","どうぶつ","うんどう","うんそう"],"true":"がいこう","times":10},
    {"lesson":17,"level":4,"question":"乗る","represent":["乗"],"answer":["のる","きる","とおる","かえる"],"true":"のる","times":10},
    {"lesson":17,"level":4,"question":"発行","represent":["発","行"],"answer":["はっこう","かいてん","じょうきゃく","うんどう"],"true":"はっこう","times":10},
    {"lesson":17,"level":4,"question":"出発する","represent":["出","発"],"answer":["しゅっぱつする","こうどうする","きこくする","はっぴょうする"],"true":"しゅっぱつする","times":10},
    {"lesson":17,"level":4,"question":"着物","represent":["着","物"],"answer":["きもの","はっこう","かいてん","はつばい"],"true":"きもの","times":10},
    {"lesson":17,"level":4,"question":"運送","represent":["運","送"],"answer":["うんそう","かいてん","はつばい","どうぶつ"],"true":"うんそう","times":10},
    {"lesson":17,"level":4,"question":"交通","represent":["交","通"],"answer":["こうつう","がいこう","うんどう","うんそう"],"true":"こうつう","times":10},
    {"lesson":17,"level":4,"question":"降る","represent":["降"],"answer":["ふる","かえる","とおる","きる"],"true":"ふる","times":10},
    {"lesson":17,"level":4,"question":"運動","represent":["運","動"],"answer":["うんどう","こうつう","うんてん","がいこう"],"true":"うんどう","times":10},
    {"lesson":17,"level":4,"question":"乗客","represent":["乗"],"answer":["じょうきゃく","きもの","かいてん","はつばい"],"true":"じょうきゃく","times":10},
    {"lesson":17,"level":4,"question":"到着する","represent":["着"],"answer":["とうちゃくする","じょうしゃする","こうどうする","つうがくする"],"true":"とうちゃくする","times":10},
    {"lesson":17,"level":4,"question":"帰国する","represent":["帰","国"],"answer":["きこくする","とうちゃくする","つうがくする","こうどうする"],"true":"きこくする","times":10},
    {"lesson":17,"level":4,"question":"乗車する","represent":["乗","車"],"answer":["じょうしゃする","こうどうする","しゅっぱつする","とうちゃくする"],"true":"じょうしゃする","times":10},
    {"lesson":17,"level":4,"question":"通る","represent":["通"],"answer":["とおる","のる","ふる","きる"],"true":"とおる","times":10},
    {"lesson":17,"level":4,"question":"発表する","represent":["発"],"answer":["はっぴょうする","こうどうする","とうちゃくする","じょうしゃする"],"true":"はっぴょうする","times":10},
    {"lesson":17,"level":4,"question":"通学する","represent":["通","学"],"answer":["つうがくする","はっぴょうする","きこくする","こうどうする"],"true":"つうがくする","times":10},
    {"lesson":17,"level":4,"question":"発売","represent":["発","売"],"answer":["はつばい","はっこう","こうつう","じょうきゃく"],"true":"はつばい","times":10},
    {"lesson":17,"level":4,"question":"回転","represent":["回","転"],"answer":["かいてん","きもの","うんそう","どうぶつ"],"true":"かいてん","times":10},
    {"lesson":17,"level":4,"question":"着る","represent":["着"],"answer":["きる","ふる","のる","とおる"],"true":"きる","times":10},
]