
export const lesson14 = [
    {"lesson":14,"level":4,"question":"原料","represent":["料"],"answer":["げんりょう","りょうきん","りょうり","ぎゅうにく"],"true":"げんりょう","times":10},
    {"lesson":14,"level":4,"question":"白鳥","represent":["白","鳥"],"answer":["やちょう","しらとり","いみ","のやま"],"true":"しらとり","times":10},
    {"lesson":14,"level":4,"question":"紅茶","represent":["茶"],"answer":["こうちゃ","しゅみ","ちゅうべい","いみ"],"true":"こうちゃ","times":10},
    {"lesson":14,"level":4,"question":"料金","represent":["料","金"],"answer":["りょうきん","げんりょう","しらとり","ぶんや"],"true":"りょうきん","times":10},
    {"lesson":14,"level":4,"question":"米国","represent":["米","国"],"answer":["べいこく","しらとり","ぶんや","こうちゃ"],"true":"べいこく","times":10},
    {"lesson":14,"level":4,"question":"意味","represent":["意","味"],"answer":["いみ","りょうり","りょうきん","りゆう"],"true":"いみ","times":10},
    {"lesson":14,"level":4,"question":"夕飯","represent":["夕","飯"],"answer":["ゆうはん","ぶんや","こうちゃ","りょうきん"],"true":"ゆうはん","times":10},
    {"lesson":14,"level":4,"question":"野菜","represent":["野","菜"],"answer":["やさい","のやま","りょうきん","ちゅうべい"],"true":"やさい","times":10},
    {"lesson":14,"level":4,"question":"米","represent":["米"],"answer":["べい","あじ","とり","ちゃ"],"true":"べい","times":10},
    {"lesson":14,"level":4,"question":"理由","represent":["理"],"answer":["りゆう","ゆうはん","りょうり","ざいりょう"],"true":"りゆう","times":10},
    {"lesson":14,"level":4,"question":"中米","represent":["中","米"],"answer":["ちゅうべい","こうちゃ","いみ","ぎゅうにく"],"true":"ちゅうべい","times":10},
    {"lesson":14,"level":4,"question":"牛肉","represent":["牛","肉"],"answer":["ぎゅうにく","りょうり","やきゅう","にくたい"],"true":"ぎゅうにく","times":10},
    {"lesson":14,"level":4,"question":"茶","represent":["茶"],"answer":["ちゃ","べい","あじ","にく"],"true":"ちゃ","times":10},
    {"lesson":14,"level":4,"question":"趣味","represent":["味"],"answer":["ちゅうべい","やきゅう","にくたい","しゅみ"],"true":"しゅみ","times":10},
    {"lesson":14,"level":4,"question":"味","represent":["味"],"answer":["あじ","ちゃ","べい","にく"],"true":"あじ","times":10},
    {"lesson":14,"level":4,"question":"分野","represent":["分","野"],"answer":["ぶんや","にくたい","ざいりょう","ゆうはん"],"true":"ぶんや","times":10},
    {"lesson":14,"level":4,"question":"肉体","represent":["肉","体"],"answer":["にくたい","げんりょう","ぎゅうにく","りゆう"],"true":"にくたい","times":10},
    {"lesson":14,"level":4,"question":"物理","represent":["物","理"],"answer":["りょうきん","りょうり","ぶつり","とりにく"],"true":"ぶつり","times":10},
    {"lesson":14,"level":4,"question":"野山","represent":["野","山"],"answer":["のやま","にくたい","げんりょう","やちょう"],"true":"のやま","times":10},
    {"lesson":14,"level":4,"question":"肉","represent":["肉"],"answer":["にく","あじ","とり","べい"],"true":"にく","times":10},
    {"lesson":14,"level":4,"question":"野球","represent":["野"],"answer":["やきゅう","ゆうはん","ちゅうべい","いみ"],"true":"やきゅう","times":10},
    {"lesson":14,"level":4,"question":"料理","represent":["料","理"],"answer":["りょうり","のやま","にくたい","ざいりょう"],"true":"りょうり","times":10},
    {"lesson":14,"level":4,"question":"材料","represent":["料"],"answer":["ざいりょう","とりにく","やきゅう","ぶんや"],"true":"ざいりょう","times":10},
    {"lesson":14,"level":4,"question":"鳥肉","represent":["鳥","肉"],"answer":["とりにく","ゆうはん","ぶんや","しゅみ"],"true":"とりにく","times":10},
    {"lesson":14,"level":4,"question":"野鳥","represent":["野","鳥"],"answer":["やちょう","りゆう","べいこく","のやま"],"true":"やちょう","times":10},
    {"lesson":14,"level":4,"question":"鳥","represent":["鳥"],"answer":["とり","あじ","にく","べい"],"true":"とり","times":10},
]