export const lesson13 = {
    "活": 120,
    "回": 121,
    "主": 122,
    "色": 123,
    "形": 124,
    "品": 125,
    "民": 126,
    "服": 127,
    "犬": 128,
    "同": 129
};