export const lesson10 = {
    "朝": 90,
    "昼": 91,
    "夜": 92,
    "夕": 93,
    "方": 94,
    "晩": 95,
    "計": 96,
    "曜": 97,
    "以": 98,
    "度": 99
};