export const explain= [
    {
        // ＾友（とも）達（だち）｜
        pattern_furi: 'Vのは(｜＾楽（たの）｜しいです)',
        pattern_normal: 'Vのは　楽しいです',
        en: [
            "　-Doing is (fun)",
            '　別(わか)れる、話(はな)す、ケンカする are not used in verbs formed by two people.'
        ],
        mm: [ 
            '　-လုပ်ရတာ (ပျော်စရာကောင်းတယ်)',
            '　別(わか)れる、話(はな)す、ケンカするဆိုတဲ့ လူနှစ်ယောက်လုပ်မှ ဖြစ်မြောက်တဲ့ ကြိယာများတွင် သုံးလေ့မရှိပါ။'
        ]
    },
    {
        pattern_furi: 'Vのが(｜＾好（す｜きです)',
        pattern_normal: 'Vのが　好きです',
        en: [
            "　-(Like) doing",
            '　Vのが is used for adjectives that always go with が.'
        ],
        mm: [ 
            '　-လုပ်ရတာ (ကြိုက်တယ်)',
            '　がနှင့်အမြဲတွဲသုံးလေ့ရှိသော နာမဝိသေသနများအတွက် Vのがဖြင့်သုံးပါတယ်။'
        ]
    },
    {
        pattern_furi: 'Vのを(｜＾忘（わす）｜れました)',
        pattern_normal: 'Vのを　忘れました',
        en: [
            "　-(I forgot) to do",
            '　Used when you forget to do a mandatory task.'
        ],
        mm: [ 
            '　-လုပ်ဖို့ (မေ့သွားတယ်)',
            '　မဖြစ်မနေလုပ်ရမဲ့အလုပ်တခုကို လုပ်ဖို့မေ့သွားတဲ့အခါတွင် အသုံးပြုသည်'
        ]
    },
   
]