import { Card } from "@material-tailwind/react"; 
import { memo } from "react";
import CountUp from 'react-countup'; 
import SubNavComponent from "@components/common/SubNavComponent";
import SizeBox from "@components/SizeBox";
import Icon from "@consts/icon";

const KanjiListMobileComponent = ({ score,lesson, kanjiDetail, startGame,nabs,getKanji}: any) => {
    return ( 
        <div className="md:hidden ">
            {/* Sub Nav */}
            <SubNavComponent nabs={nabs} lesson={lesson} />
            <SizeBox vertical="h-[20px]" />
            {getKanji().map((list: Array<Object>) => {
                return <div key={Math.random()} className="flex flex-row justify-between items-center">
                    {
                        list.map(({ id, kanji }: any) => {
                            return <div key={Math.random()} className="relative btn mb-[20px]">
                                <Card onClick={() => kanjiDetail(id)} className="flex flex-row justify-center items-center w-[50px] h-[50px] sm:w-[80px] sm:h-[80px] text-navbar dark:text-[#D4D4D4] dark:bg-[#001E34] text-[25px] font-[500px] kanji-card-shadow dark-kanji-card-shadow rounded-[8px]">{kanji}</Card>
                                <div className="absolute flex flex-row justify-center items-center top-[6px] right-0 h-[10px] w-[14px] rounded-l-[7px] bg-[#379AE5] text-white text-[9px]">{id + 1}</div>
                            </div>
                        })
                    }
                </div>
            })
            }
            <div className="flex flex-row justify-center font-[500px] lg:text-[22px] text-[#424242] dark:text-[#D4D4D4]" >QUIZ GAME</div>
            <SizeBox vertical="h-5" />
            <div className="flex flex-col">
                <div onClick={() => startGame({ kanji2hira: true })} className="btn mb-10">
                    <img src={Icon.QuizzM1} className="rounded-t-[20px] card-light-shadow w-full h-[150px] dark:bg-[#001E34]" />
                    <div className="bg-[#AA58F5] h-[50px] border-t-[1px] border-[#C5C5C5] rounded-b-[20px] card-light-shadow">
                        <div className="flex flex-row px-2 justify-between h-full items-center">
                            {/* Left */}
                            <div className="flex-1 flex flex-row items-center text-[14px] font-semibold text-white">
                                <div className="">漢字</div>
                                <div className="mx-3"><img src={Icon.Arrow} /></div>
                                <div>ひらがな</div>
                            </div>
                            {/* Right */}
                            <div className="flex-1 text-[14px] text-white">
                                <div className="flex flex-row justify-end">
                                    <div className="mr-3 text-[#00FFA3]">Best Scored</div>
                                     <CountUp
                                        start={0}
                                        end={score.kanji}
                                        duration={3}
                                    />  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={() => startGame({ kanji2hira: false })} className="btn">
                    <img src={Icon.QuizzM2} className="rounded-t-[20px] card-light-shadow w-full h-[150px] dark:bg-[#001E34]" />
                    <div className="bg-[#AA58F5] h-[50px] border-t-[1px] border-[#C5C5C5] rounded-b-[20px] card-light-shadow">
                        <div className="flex flex-row p-2 justify-between h-full items-center">
                            <div className="flex-1 flex flex-row items-center text-[14px] font-semibold text-white">
                                <div className="">ひらがな</div>
                                <div className="mx-3"><img src={Icon.Arrow} /></div>
                                <div>漢字</div>
                            </div>
                            <div className="flex-1 text-[14px] text-white">
                                <div className="flex flex-row justify-end ">
                                    <div className="mr-3 text-[#00FFA3]">Best Scored</div>
                                    <CountUp
                                        start={0}
                                        end={score.hira}
                                        duration={3}
                                    />  
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(KanjiListMobileComponent);