export const explain= [
    { 
        pattern_furi: '~んです',
        // pattern_normal: '＾友（とも）達（だち）｜とコーヒー',
        pattern_normal: '~んです',
        en: [
            '　It is useful in conversation.',
            '　It means 「~です」 .',
            '　Using 「~んです」 helps us to feel closer and more polite.'
        ],
        mm: [
            '　စကားပြောတွင် အသုံးများသည်။',
            '「~です」（ဖြစ်သည်）နှင့် အဓိပ္ပါယ်တူသည်။',
            '「~んです」ကိုသုံးခြင်းဖြင့် ပို၍ရင်းနှီးမှုရှိစေပြီး ယဉ်ကျေးသော စကားပြောပုံစံကို ဖြစ်စေပါသည်။'
        ]
    },
    {
        pattern_furi: '~していただけませんか',
        pattern_normal: '~していただけませんか',
        en: [
            '　Used to ask for help.',
            '　It is a polite term.'
        ],
        mm: [
            '　အကူအညီတောင်းရာတွင် သုံးသည်',
            '　ယဉ်ကျေးသော အသုံးအနှုန်းဖြစ်သည်',
        ]
    }

]