export const lesson19 =[
    {"lesson":19,"level":4,"question":"わだい","represent":["話","題"],"answer":["話題","合宿","意志","表紙"],"true":"話題","times":10},
    {"lesson":19,"level":4,"question":"じゅけんする","represent":["験"],"answer":["受験する","経験する","訪問する","返答する"],"true":"受験する","times":10},
    {"lesson":19,"level":4,"question":"もんだい","represent":["問","題"],"answer":["問題","試合","手紙","用事"],"true":"問題","times":10},
    {"lesson":19,"level":4,"question":"ようい","represent":["用","意"],"answer":["用意","試合","題名","表紙"],"true":"用意","times":10},
    {"lesson":19,"level":4,"question":"ごうけい","represent":["合","計"],"answer":["合計","合宿","意味","意見"],"true":"合計","times":10},
    {"lesson":19,"level":4,"question":"いし","represent":["意"],"answer":["意志","用具","用意","合宿"],"true":"意志","times":10},
    {"lesson":19,"level":4,"question":"いけん","represent":["意","見"],"answer":["意見","用意","用事","試合"],"true":"意見","times":10},
    {"lesson":19,"level":4,"question":"ようぐ","represent":["用"],"answer":["用具","用事","宿題","性質"],"true":"用具","times":10},
    {"lesson":19,"level":4,"question":"しあい","represent":["試","合"],"answer":["試合","意見","宿題","意識"],"true":"試合","times":10},
    {"lesson":19,"level":4,"question":"いみ","represent":["意","味"],"answer":["意味","手紙","用具","合宿"],"true":"意味","times":10},
    {"lesson":19,"level":4,"question":"しけん","represent":["試","験"],"answer":["試験","宿題","表紙","意識"],"true":"試験","times":10},
    {"lesson":19,"level":4,"question":"りよう","represent":["利","用"],"answer":["利用","話題","表紙","試験"],"true":"利用","times":10},
    {"lesson":19,"level":4,"question":"じっけんする","represent":["験"],"answer":["実験する","経験する","返答する","受験する"],"true":"実験する","times":10},
    {"lesson":19,"level":4,"question":"ひょうし","represent":["紙"],"answer":["表紙","題名","宿題","合計"],"true":"表紙","times":10},
    {"lesson":19,"level":4,"question":"がっしゅく","represent":["合"],"answer":["合宿","利用","意見","用意"],"true":"合宿","times":10},
    {"lesson":19,"level":4,"question":"ようじ","represent":["用","事"],"answer":["用事","意識","問題","題名"],"true":"用事","times":10},
    {"lesson":19,"level":4,"question":"へんとうする","represent":["返","答"],"answer":["返答する","質問する","訪問する","経験する"],"true":"返答する","times":10},
    {"lesson":19,"level":4,"question":"ほうもんする","represent":["問"],"answer":["訪問する","経験する","受験する","実験する"],"true":"訪問する","times":10},
    {"lesson":19,"level":4,"question":"せいしつ","represent":["質"],"answer":["性質","手紙","宿題","意見"],"true":"性質","times":10},
    {"lesson":19,"level":4,"question":"しつもんする","represent":["質","問"],"answer":["質問する","経験する","返答する","実験する"],"true":"質問する","times":10},
    {"lesson":19,"level":4,"question":"いしき","represent":["意"],"answer":["意識","合計","手紙","意志"],"true":"意識","times":10},
    {"lesson":19,"level":4,"question":"だいめい","represent":["題","名"],"answer":["題名","意識","意志","表紙"],"true":"題名","times":10},
    {"lesson":19,"level":4,"question":"てがみ","represent":["手","紙"],"answer":["手紙","意味","意志","意見"],"true":"手紙","times":10},
    {"lesson":19,"level":4,"question":"けいけんする","represent":["験"],"answer":["経験する","受験する","実験する","質問する"],"true":"経験する","times":10},
    {"lesson":19,"level":4,"question":"しゅくだい","represent":["題"],"answer":["宿題","問題","意味","用意"],"true":"宿題","times":10},
]