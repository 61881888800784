export const reibun = [   
    //｜＾富（ふ）士（じ）大（だい）学（がく）｜を　｜＾選（えら）
    {
        sentence: [
            {
                furi: "＾駅（えき）｜の｜＾新（あたら）｜しい　トイレ、　おもしろいですね。",
                normal: "駅の　新しい　トイレ、　おもしろいですね。",
                en: "The station's new toilet is interesting.",
                mm: "ဘူတာရုံရဲ့ အိမ်သာအသစ်က စိတ်ဝင်စားဖို့ကောင်းတယ်။",
            },
            {
                furi: "。。。え？　そうですか。",
                normal: "。。。え？　そうですか。",
                en: "e, is that so.",
                mm: "အဲ, ဟုတ်လား?",
            },
            {
                furi: "壁に　花や　動物の　えが　かいて　あるんです。",
                normal: "＾壁（かべ）｜に｜＾花（はな）｜や　｜＾動（どう）｜＾物（ぶつ）｜の　えが　かいて　あるんです。",
                en: "There are pictures of flowers and animals on the walls.",
                mm: "နံရံမှာ ပန်းတွေနဲ့ တိရစ္ဆာန်ပုံတွေ ရှိတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson30/bunpou/4N30RB1.mp3")
    },
    {
        sentence: [
            {
                furi: "セロテープは　どうこですか？",
                normal: "セロテープは　どうこですか？",
                en: "Where's the sellotape?",
                mm: "ဆယ်လိုတိပ်က ဘယ်မှာလဲ",
            },
            {
                furi: "。。。あの　｜＾引（ひ）｜き｜＾出（だ）｜しに　しまって　ありますよ。",
                normal: "。。。あの　引き出しに　しまって　ありますよ。",
                en: "You can find it in her drawer.",
                mm: "အဲဒီအံဆွဲထဲမှာရှိတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson30/bunpou/4N30RB2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾来（らい）｜＾月（げつ）｜の｜＾出（しゅっ）｜＾張（ちょう）｜ですが、　ホテルを　｜＾予（よ）｜＾約（やく）｜して　おきましょうか？",
                normal: "来月の　しゅっちょうですが、　ホテルを　予約して　おきましょうか？",
                en: "Shall I make a hotel reservation for next month?",
                mm: "နောက်လအတွက် ဟိုတယ်ကြိုတင်မှာထားရမလား။",
            },
            {
                furi: "。。。ええ、　お｜＾願（ねが）｜いします。",
                normal: "。。。ええ、　お願いします。",
                en: "Yes, please.",
                mm: "ဟုတ်ကဲ့ ၊ ကျေးဇူးပြု၍။ ",
            },
            
        ],
        audioUrl: require("@assets/audio/lesson30/bunpou/4N30RB3.mp3")
    },
    {
        sentence: [
            {
                furi: "はさみを　｜＾使（つか）｜ったら、｜＾元（もと）｜の　｜＾所（ところ）｜に　｜＾戻（もど）｜して　おいて　ください。",
                normal: "はさみを　使ったら、元の　所に　戻して　おいて　ください。",
                en: "After using the scissors, please put them back where they were.",
                mm: "ကတ်ကြေးကို သုံးပြီးရင် ကျေးဇူးပြု၍ သူ့နေရာနဲ့သူ ပြန်ထားပေးပါ။",
            },
            {
                furi: "。。。はい、　わかりました。",
                normal: "。。。はい、　わかりました。",
                en: "Yes, I understand.",
                mm: "ဟုတ်ကဲ့, နားလည်ပါတယ်။",
            }
        ],
        audioUrl: require("@assets/audio/lesson30/bunpou/4N30RB4.mp3")
    },
    {
        sentence: [
            {
                furi: "＾資（し）｜＾料（りょう）｜を　｜＾片（かた）｜＾付（づけ）｜ても　いいですか。",
                normal: "資料を　片付けも　いいですか。",
                en: "Can I clean up the materials?",
                mm: "ပစ္စည်းတွေ ရှင်းလို့ရလား။",
            },
            {
                furi: "。。。いいえ、　そのままに　して　おいて　ください。",
                normal: "。。。いいえ、　そのままに　して　おいて　ください。",
                en: " No, leave them alone.",
                mm: "မဟုတ်ဘူး၊ ဒီအတိုင်း ထားထားပါ။",
            },
            {
                furi: "まだ　｜＾使（つか）｜って　いますから。",
                normal: "まだ　使って　いますから。",
                en: "I'm still using it.",
                mm: "သုံးနေတုန်းမို့လို့ပါ။",
            }
        ],
        audioUrl: require("@assets/audio/lesson30/bunpou/4N30RB5.mp3")

    }
];

/*

{
    furi: "",
    normal: "",
    en: "",
    mm: "",
},

*/