
export const renshuuA = [
    { 
        isTable: true,
        tables: [
            {
                head: {
                    one: {
                        val:"",
                        span: 1,
                        style: ""
                    },
                    two: {
                        val:"ます｜＾形（けい）",
                        span: 3,
                        style: "text-center"
                    },
                    three: {
                        val: "＾意（い）向（こう）形（けい）",
                        span: 3,
                        style: "text-center bg-white"
                    }
                },
                body: [
                    {
                        no: "I",
                        a1: "か",
                        a2: "い",
                        a3: "ます",
                        b1: "か",
                        b2: "お",
                        b3: "う",
                    },
                    {
                        no: "",
                        a1: "ある",
                        a2: "き",
                        a3: "ます",
                        b1: "ある",
                        b2: "こ",
                        b3: "う",
                    },
                    {
                        no: "",
                        a1: "いそ",
                        a2: "ぎ",
                        a3: "ます",
                        b1: "いそ",
                        b2: "ご",
                        b3: "う",
                    },
                    {
                        no: "",
                        a1: "なお",
                        a2: "し",
                        a3: "ます",
                        b1: "なお",
                        b2: "そ",
                        b3: "う",
                    },
                    {
                        no: "",
                        a1: "ま",
                        a2: "ち",
                        a3: "ます",
                        b1: "ま",
                        b2: "と",
                        b3: "う",
                    },
                    {
                        no: "",
                        a1: "あそ",
                        a2: "び",
                        a3: "ます",
                        b1: "あそ",
                        b2: "ぼ",
                        b3: "う",
                    },
                    {
                        no: "",
                        a1: "やす",
                        a2: "み",
                        a3: "ます",
                        b1: "やす",
                        b2: "も",
                        b3: "う",
                    },
                    {
                        no: "",
                        a1: "の",
                        a2: "り",
                        a3: "ます",
                        b1: "の",
                        b2: "ろ",
                        b3: "う",
                    },
                ],
                style: {
                    no: "py-2 text-right w-[30px] bg-white dark:bg-darkFooter",
                    a1: "py-2 pr-2 text-right w-[100px] bg-white dark:bg-darkFooter",
                    a2: "py-2 px-2 text-right bg-[#729eff] dark:bg-[#003257] w-[20px]",
                    a3: "py-2 text-left w-[100px] bg-white dark:bg-darkFooter",
                    b1: "py-2 pl-2 text-right w-[100px] bg-white dark:bg-darkFooter",
                    b2: "py-2 px-2 text-right w-[20px] bg-[#729eff] dark:bg-[#003257]",
                    b3: "py-2 text-left w-[100px] bg-white dark:bg-darkFooter",
                }
            },
            {
                head: {
                    a: {
                        val:"",
                        span: 1,
                        style: ""
                    },
                    b: {
                        val:"ます｜＾形（けい）",
                        span: 1,
                        style: ""
                    },
                    c: {
                        val:"",
                        span: 1,
                        style: ""
                    },
                    d: {
                        val: "＾意（い）向（こう）形（けい）",
                        span: 1,
                        style: ""
                    },
                    e: {
                        val:"",
                        span: 1,
                        style: ""
                    },
                },
                body: [
                    {
                        no: "II",
                        a1: "かえ",
                        a2: "ます",
                        b1: "かえ",
                        b2: "よう",
                    },
                    {
                        no: "",
                        a1: "おぼえ",
                        a2: "ます",
                        b1: "おぼえ",
                        b2: "よう",
                    },
                    {
                        no: "",
                        a1: "み",
                        a2: "ます",
                        b1: "み",
                        b2: "よう",
                    },
                ],
                style: {
                    no: "py-2 text-right w-[30px] bg-white dark:bg-darkFooter",
                    a1: "py-2 pr-2 text-right w-[100px] bg-white dark:bg-darkFooter",
                    a2: "py-2 px-2 text-right bg-[#729eff] dark:bg-[#003257] w-[20px]",
                    b1: "py-2 pl-2 text-right w-[100px] bg-white dark:bg-darkFooter",
                    b2: "py-2 px-2 text-right bg-[#729eff] dark:bg-[#003257] w-[20px]",
                }
            },
            {
                head: {
                    a: {
                        val:"",
                        span: 1,
                        style: ""
                    },
                    b: {
                        val:"ます｜＾形（けい）",
                        span: 1,
                        style: ""
                    },
                    c: {
                        val:"",
                        span: 1,
                        style: ""
                    },
                    d: {
                        val: "＾意（い）向（こう）形（けい）",
                        span: 1,
                        style: ""
                    },
                    e: {
                        val:"",
                        span: 1,
                        style: ""
                    },
                },
                body: [
                    {
                        no: "III",
                        a1: "き",
                        a2: "ます",
                        b1: "こ",
                        b2: "よう",
                    },
                    {
                        no: "",
                        a1: "し",
                        a2: "ます",
                        b1: "し",
                        b2: "よう",
                    },
                    {
                        no: "",
                        a1: "きゅうけいし",
                        a2: "ます",
                        b1: "きゅうけいし",
                        b2: "よう",
                    },
                ],
                style: {
                    no: "py-2 text-right w-[30px] bg-white dark:bg-darkFooter",
                    a1: "py-2 pr-2 text-right w-[100px] bg-white dark:bg-darkFooter",
                    a2: "py-2 px-2 text-right bg-[#729eff] dark:bg-[#003257] w-[20px]",
                    b1: "py-2 pl-2 text-right w-[100px] bg-white dark:bg-darkFooter",
                    b2: "py-2 px-2 text-right bg-[#729eff] dark:bg-[#003257] w-[20px]",
                }
            },
        ],
        audioUrl: require("@assets/audio/lesson31/renshua/4N31RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾買（か）｜い｜＾物（もの）｜に｜！いこう｜。",
                normal: "買い物に　いこう。",
                en: "Let's go shopping.",
                mm: "စျေးဝယ်ထွက်ရအောင်။",
            },
            {
                furi: "＾公（こう）園（えん）｜を｜！さんぽしよう｜。",
                normal: "公園を　さんぽしよう。",
                en: "Let's take a walk in the park.",
                mm: "ပန်းခြံထဲမှာ လမ်းလျှောက်ကြရအောင်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson31/renshua/4N31RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾外（がい）国（こく）｜で｜！はたらこう｜と｜＾思（おも）｜って　います。",
                normal: "外国で　はたらこうと思って　います。",
                en: "I am thinking of working in a foreign country.",
                mm: "နိုင်ငံခြားမှာ အလုပ်လုပ်ဖို့ စဉ်းစားနေတယ်။",
            },
            {
                furi: "＾外（がい）国（こく）｜で｜＾仕（し）事（ごと）｜を｜！みつけよう｜と｜＾思（おも）｜って　います。",
                normal: "外国で　仕事を　みつけようと　思って　います。",
                en: "I am thinking of finding a job abroad.",
                mm: "နိုင်ငံခြားမှာ အလုပ်ရှာဖို့ စဉ်းစားနေတယ်။",
            },
            {
                furi: "＾外（がい）国（こく）｜で｜！べんきょうしよう｜と｜＾思（おも）｜って　います。",
                normal: "外国で　べんきょうしようと　思って　います。",
                en: "I'm thinking of studying in a foreign country.",
                mm: "နိုင်ငံခြားမှာ ကျောင်းတက်ဖို့ စဉ်းစားနေတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson31/renshua/4N31RSA3.mp3")
    },
    {
        sentence: [
            {
                furi: "レポートは　まだ｜！まとめて｜いません。",
                normal: "レポートは　まだ　まとめて　いません。",
                en: "The report has not yet been compiled.",
                mm: "အစီရင်ခံစာ မပြုစုရသေးဘူး။",
            },
            {
                furi: "レポートは　まだ｜！だして｜いません。",
                normal: "レポートは　まだ　だして　いません。",
                en: "I haven't submitted the report yet.",
                mm: "ကျွန်တော် အစီရင်ခံစာ မတင်ရသေးဘူး။",
            },
        ],
        audioUrl: require("@assets/audio/lesson31/renshua/4N31RSA4.mp3")
    },
    {
        sentence: [
            {
                furi: "わたしは　ずっと　｜＾日（に）本（ほん）｜に｜！すむ｜つもりです。",
                normal: "わたしは　ずっと　日本に　すむ　つもりです。",
                en: "I plan to live in Japan forever.",
                mm: "ကျွန်တော် ဂျပန်မှာ ထာဝရနေဖို့ စီစဉ်ထားပါတယ်။",
            },
             {
                furi: "わたしは　｜＾将（しょう）来（らい）｜　｜＾大（だい）学（がく）｜で｜！けんきゅうする｜つもりです。",
                normal: "わたしは　将来　大学で　けんきゅうする　つもりです。",
                 en: "I plan to do research at university in the future.",
                 mm: "ကျွန်တော် အနာဂတ်မှာ တက္ကသိုလ်မှာ သုတေသနလုပ်ဖို့ စိတ်ကူးထားတယ်။",
            },
             {
                furi: "わたしは　｜＾国（くに）｜へ　｜！かえらない｜つもりです。",
                normal: "わたしは　国へ　かえらない　つもりです。",
                 en: "I am not going back to my country.",
                 mm: "ကျွန်တော် နိုင်ငံကို ပြန်မသွားတော့ဘူး။",
            },
             {
                furi: "わたしは　｜＾来（らい）年（ねん）｜の　｜＾試（し）験（けん）｜を　｜！うけない｜つもりです。",
                normal: "わたしは　来年の　試験を　うけない　つもりです。",
                 en: "I am not going to take the exam next year.",
                mm: "ကျွန်တော် နောက်နှစ်မှာ စာမေးပွဲမဖြေတော့ဘူး။",
            },
        ],
        audioUrl: require("@assets/audio/lesson31/renshua/4N31RSA5.mp3")
    },
    {
        sentence: [
            {
                furi: "＾部（ぶ）長（ちょう）｜は　｜＾支（し）店（てん）｜へ　｜！いく｜　｜＾予（よ）定（てい）｜です。",
                normal: "部長は　支店へ　いく　予定です。",
                en: "The manager plans to go to the branch.",
                mm: "မန်နေဂျာက ဌာနခွဲကို သွားဖို့ စီစဉ်ထားတယ်။",
            },
            {
                furi: "＾飛（ひ）行（こう）機（き）｜は　１１｜＾時（じ）｜に　｜！つく｜　｜＾予（よ）定（てい）｜です。",
                normal: "飛行機は　１１時に　つく　予定です。",
                en: "The plane is scheduled to arrive at 11:00.",
                mm: "လေယာဉ်က ၁၁နာရီမှာ ဆိုက်ရောက်ပါတယ်။",
            },
            {
                furi: "＾来（らい）週（しゅう）｜は　｜！しゅっちょうの｜　｜＾予（よ）定（てい）｜です。",
                normal: "来週は　しゅっちょうの　予定です。",
                en: "I am planning to go on a business trip next week.",
                mm: "နောက်တစ်ပတ် အလုပ်ကိစ္စနဲ့ ခရီးထွက်ဖို့ စီစဉ်နေတယ်။",
            },
            {
                furi: "＾午（ご）後（ご）｜は｜！かいぎの｜　｜＾予（よ）定（てい）｜です。",
                normal: "午後は　かいぎの　予定です。",
                en: "In the afternoon, I'm planning to go to work.",
                mm: "နေ့ခင်းဘက် အလုပ်သွားဖို့ စီစဉ်နေတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson31/renshua/4N31RSA6.mp3")
    },
];

/*
    {
        sentence: [
            {
                furi: "",
                normal: "",
                en: "",
                mm: "",
            },
        ],
        audioUrl: require("@assets/audio/testing.mp3")
    },




*/