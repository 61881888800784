export const lesson27 = [
    {
        furi: "＾飼（か）｜います",
        normal: "飼います",
        en: "keep , raise",
        remark_en: "（a pet, an animal）",
        mm: "တိရိစ္ဆာန်မွေးသည်။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO1.mp3")
    },
    {
        furi: "＾走（はし）｜ります",
        normal: "走ります",
        en: "run, drive",
        remark_en: "",
        mm: "ပြေးသည်။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO2.mp3")
    },
    {
        furi: "＾見（み）｜えます",
        normal: "見えます",
        en: "can be seen",
        remark_en: "",
        mm: "မြင်သည်",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO3.mp3")
    },
    {
        furi: "＾聞（き）｜こえます",
        normal: "聞こえます",
        en: "can be heard",
        remark_en: "",
        mm: "ကြားရသည်။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO4.mp3")
    },
    {
        furi: "できます",
        normal: "できます",
        en: "can be made",
        remark_en: "be completed, come into existence",
        mm: "လုပ်နိုင်သည်။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO5.mp3")
    },
    {
        furi: "＾開（ひら）｜きます",
        normal: "開きます",
        en: "set up, open, hold",
        remark_en: "",
        mm: "ဖွင့်လှစ်သည်။",
        remark_mm: "",
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO6.mp3")
    },
    {
        furi: "＾心（しん）配（ぱい）",
        normal: "心配",
        en: "worry",
        remark_en: "",
        mm: "စိတ်ပူသည်။",
        remark_mm: "",
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO7.mp3")
    },
    {
        furi: "ペット",
        normal: "ペット",
        en: "pet",
        remark_en: "",
        mm: "အိမ်မွေးတိရိစ္ဆာန်။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO8.mp3")
    },
    {
        furi: "＾鳥（とり）",
        normal: "鳥",
        en: "bird",
        remark_en: "",
        mm: "ကြက်၊ ငှက်။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO9.mp3")
    },
    {
        furi: "＾声（こえ）",
        normal: "声",
        en: "voice",
        remark_en: "",
        mm: "အသံ။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO10.mp3")
    },
    {
        furi: "＾波（なみ）",
        normal: "波",
        en: "wave",
        remark_en: "",
        mm: "လှိုင်း။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO11.mp3")
    },
    {
        furi: "＾花（はな）火（び）",
        normal: "花火",
        en: "fireworks",
        remark_en: "",
        mm: "မီးရှုးမီးပန်း။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO12.mp3")
    },
    {
        furi: "＾道（どう）具（ぐ）",
        normal: "道具",
        en: "tool, instrument, equipment",
        remark_en: "",
        mm: "ပစ္စည်းကရိယာ။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO13.mp3")
    },
    {
        furi: "クリーニング",
        normal: "クリーニング",
        en: "cleaning, laundry",
        remark_en: "",
        mm: "အဝတ်လျှော်ဆိုင်။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO14.mp3")
    },
    {
        furi: "＾家（いえ）",
        normal: "家",
        en: "house, home",
        remark_en: "",
        mm: "အိမ်။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO15.mp3")
    },
    {
        furi: "マンション",
        normal: "マンション",
        en: "condominium, apartment house",
        remark_en: "",
        mm: "အဆင့်မြင့်တိုက်ခန်း။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO16.mp3")
    },
    {
        furi: "キッチン",
        normal: "キッチン",
        en: "kitchen",
        remark_en: "",
        mm: "မီးဖိုချောင်။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO17.mp3")
    },
    {
        furi: "＾教（きょう）室（しつ）",
        normal: "教室",
        en: "classroom",
        remark_en: "",
        mm: "စာသင်ခန်း",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO18.mp3")
    },
    {
        furi: "パーティールーム",
        normal: "パーティールーム",
        en: "party room",
        remark_en: "",
        mm: "ပါတီအခန်း။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO19.mp3")
    },
    {
        furi: "＾方（かた）",
        normal: "方",
        en: "person",
        remark_en: "",
        mm: "လူ၊ ပုဂ္ဂိုလ်",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO20.mp3")
    },
    {
        furi: "～｜＾後（ご）",
        normal: "後",
        en: "after",
        remark_en: " ~ （duration of time）",
        mm: " ~ နောက်",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO21.mp3")
    },
    {
        furi: "～しか",
        normal: "～しか",
        en: "only",
        remark_en: " ~ （used with negatives）",
        mm: " ~ ဘဲ။ ~ တည်း။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO22.mp3")
    },
    {
        furi: "ほかの",
        normal: "ほかの",
        en: "other",
        remark_en: "",
        mm: "အခြားဟာ။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO23.mp3")
    },
    {
        furi: "はっきり",
        normal: "はっきり",
        en: "clearly",
        remark_en: "",
        mm: "သေသေချာချာ",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO24.mp3")
    },
    {
        furi: "＾家（か）具（ぐ）",
        normal: "家具",
        en: "furniture",
        remark_en: "",
        mm: "အိမ်ထောင်ပရိဘောဂ",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO25.mp3")
    },
    {
        furi: "＾本（ほん）棚（だな）",
        normal: "本棚",
        en: "bookshelf",
        remark_en: "",
        mm: "စာအုပ်စင်။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO26.mp3")
    },
    {
        furi: "＾いつか",
        normal: "いつか",
        en: "sometime, someday",
        remark_en: "",
        mm: "တစ်နေ့နေ့တော့။",
        remark_mm: "",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO27.mp3")
    },
    {
        furi: "＾建（た）｜てます",
        normal: "建てます",
        en: "build",
        remark_en: "",
        mm: "ဆောက်လုပ်သည်။",
        remark_mm: "အဆောက်အဦစသည်",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO28.mp3")
    },
    {
        furi: "すばらしい",
        normal: "すばらしい",
        en: "marvelous, wonderful",
        remark_en: "",
        mm: "ကောင်းသော။",
        remark_mm: "ပြောင်မြောက်သော။",
        group: 2,
        audioUrl: require("@assets/audio/lesson27/vocab/4N27VO29.mp3")
    }
];