import { BunpouType } from "..";

export const bunpou: BunpouType = {
  mondai1:{
    title: 'もんだい１（　　）に　何を　入れますか。１・２・３・４から　いちばん　いい　ものを　ーつ　えらんで　ください。',
    questions: [
      {
        number: 1,
        question: [
          '(1)チーズは　ぎゅうにゅう（　　）　作られます。',
        ],
        answers: ['から','が','に','まで'],
        pick: '',
        correct: 'から'
      },
      {
        number: 2,
        question: ['(2)この　料理は　電子レンジを　使えば（　　）できます。'],
        answers: ['5,6分','5,6分で','5,6分に','5,6分も'],
        pick: '',
        correct: '5,6分で'
      },
      {
        number: 3,
        question: [
          '(3)「駅は　どこですか。」と　外国人（　　）　聞かれたので、駅まで　いっしょに　行きました。',
        ],
        answers: ['を','へ','に','か'],
        pick: '',
        correct: 'に'
      },
      {
        number: 4,
        question: [
          '(4)わたしの　兄は、パソコンの　ことなら（　　）わかります。',
        ],
        answers: ['なんで','なにで','なんでも','なにも'],
        pick: '',
        correct: 'なんでも'
      },
      {
        number: 5,
        question: [
          '(5)A：「2時から　会議だから、（　　）行きましょうか。」',
          'B:[そうですね。」',
        ],
        answers: ['そろそろ','だいたい','だんだん','なかなか'],
        pick: '',
        correct: 'そろそろ'
      },
      {
        number: 6,
        question: [
          '(6)A：「草色　というのは　どんないろ　ですか。」',
          'B「今　わたしが　着ている　シャツの　ような、（　　）いろです。」',
        ],
        answers: ['あんな','こんな','どの','その'],
        pick: '',
        correct: 'こんな'
      },
      {
        number: 7,
        question: [
          '(7)今日の　じゅぎょうは　やすみだと　だれから（　　）を　聞きました。」',
        ],
        answers: ['言って　いる','言って　いるの','言って　いること','言って　いるもの'],
        pick: '',
        correct: '言って　いるの'
      },
      {
        number: 8,
        question: [
          '(8)その　日の　天気を　見て、ぼうしを　かぶるか（　　）決めて　います。'
        ],
        answers: ['かぶらない','かぶらなくて','かぶらないか','かぶらないが'],
        pick: '',
        correct: 'かぶらないか'
      },
      {
        number: 9,
        question: [
          '(9)教室い　試験の　時間割が　はって（　　）。',
        ],
        answers: ['あります','います','おきます','みます'],
        pick: '',
        correct: 'あります'
      },
      {
        number: 10,
        question: [
          '(10)最近、いろんな　しゅるいの　ガムが　スーパーマーケットで（　　）。',
        ],
        answers: ['売ります','売って　いきます','売らせます','売られて　います'],
        pick: '',
        correct: '売られて　います'
      },
      {
        number: 11,
        question: [
          '(11)学生「先生、作文で　間違えた　ところを　なおしました。」',
          '先生「はい。では、見せて　ください。」',
          '学生「お願いします。。。（　　）。」',
          '先生「ええ。よく　なりましたね。」',
        ],
        answers: ['どうしますか','どうしましょうか','どうでしょうか','どうしましたか'],
        pick: '',
        correct: 'どうでしょうか'
      },
      {
        number: 12,
        question: [
          '(12)A「何回も　電話（　　）、どうして　でて　くれなかったんですか。」',
          'B「すみません。」',
        ],
        answers: ['すれば','したら','したので','したのに'],
        pick: '',
        correct: 'したのに'
      },
      {
        number: 13,
        question: [
          '(13)田中「山川さん、今日の　夕食は　何に　するんですか。」',
          '山川「これから　買い物に　行って、カレーを（　　）と　思います。」',
        ],
        answers: ['作ろう','作ります','作って　います','作った'],
        pick: '',
        correct: '作ろう'
      },
      {
        number: 14,
        question: [
          '(14)わたしの　ジュースを　弟に　ぜんぶ（　　）。',
        ],
        answers: ['飲みそうです','飲むでしょう','飲まれて　しまいました','飲んで　みました'],
        pick: '',
        correct: '飲まれて　しまいました'
      },
      {
        number: 15,
        question: [
          '(15)「すみません。このファクスの　使い方を　教えて　ください。」',
          'B「ええと、ちょっと　今　いそがしので、ほかの人に（　　）。',
          'A「分かりました。では、そうします。」',
        ],
        answers: ['聞いて　みたのですが','聞きたいんですが','聞こうと　思いますか','聞いて　もらえますか'],
        pick: '',
        correct: '聞いて　もらえますか'
      },
    ]
  },
  mondai2:{
    title: 'もんだい（2）｜！ ★ ｜ に　何を　入れますか。１・２・３・４から　いちばん　いい　ものを　ーつ　えらんで　ください。',
    questions: [
      {
        number: 16,
        question: [
          '(16)この　パーテイーは｜！＿＿★＿｜入れません。',
        ],
        answers: ['を','しか','チケット','買った人'],
        pick: '',
        correct: '買った人'
      },
      {
        number: 17,
        question: [
          '(17)あしたは　会議が　あるので｜！＿＿★＿｜に　いきます。',
        ],
        answers: ['早く','いつも','会社','より'],
        pick: '',
        correct: '早く'
      },
      {
        number: 18,
        question: [
          '(18)A「すみません。日曜日は　図書館は　何時まで｜！＿＿★＿｜。」',
          'B「日曜日は　5時までです。」',
        ],
        answers: ['開いて','教えて','くださいませんか','いるか'],
        pick: '',
        correct: '教えて'
      },
      {
        number: 19,
        question: [
          '(19)（ろうかで）',
          '学生「さようなら。」',
          '先生「さようなら。」',
          '先生「さようなら。教室には、もう｜！＿＿★＿｜。」',
          '学生「いいえ。田中さんが　べんきょうして　います。」',
        ],
        answers: ['も','か','いません','だれ'],
        pick: '',
        correct: 'いません'
      },
      {
        number: 20,
        question: [
          '(20)わたしは　この　音楽｜！＿＿★＿｜ねむく　なります。',
        ],
        answers: ['かならず','を','聞く','と'],
        pick: '',
        correct: 'と'
      },
    ]
  },
}