export const reibun= [
    {
        sentence: [
            {
                furi: '＾車（くるま）｜の　｜＾窓（まど）｜が　｜＾開（あ）｜かないんですが。',
                normal: '車の　窓が　開かないんですが。',
                en: "The car window won't open.",
                mm: 'ကားပြတင်းပေါက်က မပွင့်ဘူး။',
            },
            {
                furi: 'その　ボタンを　｜＾押（お）｜せば、｜＾開（あ）｜きますよ。',
                normal: 'その　ボタンを　押せば、開きますよ。',
                en: 'If you press that button, it will open.',
                mm: 'အဲဒီခလုတ်ကို နှိပ်လိုက်ရင် ပွင့်လာလိမ့်မယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson35/bunpou/4N35RB1.mp3")
    },
    {
        sentence: [
            {
                furi: 'ほかに　｜＾意（い）見（けん）｜が　ありますか。',
                normal: 'ほかに　意見が　ありますか。',
                en: 'Do you have any other opinions?',
                mm: 'အခြားထင်မြင်ချက်ရှိပါသလား။',
            },
            {
                furi: 'いいえ、｜＾特（とく）｜に　ありません。',
                normal: 'いいえ、特に　ありません。',
                en: 'No, not in particular.',
                mm: 'ထွေထွေထူးထူး မရှိပါဘူး။',
            },
            {
                furi: 'なければ、これで　｜＾終（お）｜わりましょう。',
                normal: 'なければ、これで　終わりましょう。',
                en: "If not, let's finish with this.",
                mm: 'မရှိရင် ဒီမှာဘဲ ပြီးလိုက်ကြရအောင်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson35/bunpou/4N35RB2.mp3")
    },
    {
        sentence: [
            {
                furi: '＾日（に）本（ほん）｜の　｜＾生（せい）活（かつ）｜は　どうですか。',
                normal: '日本の　生活は　どうですか。',
                en: 'How is life in Japan?',
                mm: 'ဂျပန်မှာ နေရတဲ့ ဘဝက ဘယ်လိုလဲ။',
              
            },
            {
                furi: 'とても　｜＾便（べん）利（り）｜です。でも、もう　｜＾少（すこ）｜し　｜＾物（ぶ）価（っか）｜が　｜＾安（やす）｜ければ、もっと　いいと　｜＾思（おも）｜います。',
                normal: 'とても　便利です。でも、もう　少し　物価が　安ければ、もっと　いいと　思います。',
                en: "very convenient. But I think it'd be better if the prices were a little cheaper.",
                mm: 'အရမ်းအဆင်ပြေတယ်။ ဒါပေမယ့် ဈေးနည်းနည်းသက်သာရင် ပိုကောင်းမယ်ထင်တယ်။',
            }
        ],
        audioUrl: require("@assets/audio/lesson35/bunpou/4N35RB3.mp3")
    },
    {
        sentence: [
            {
                furi: 'あしたまでに　レポートを　｜＾出（だ）｜さなければ　なりませんか。',
                normal: 'あしたまでに　レポートを　出さなければ　なりませんか。',
                en: 'Do I have to submit the report by tomorrow?',
                mm: 'မနက်ဖြန် အစီရင်ခံစာ မတင်ရင် မရဘူးလား။',
            },
            {
                furi: '＾無（む）理（り）｜なら、｜＾金（きん）曜（よう）日（び）｜までに　｜＾出（だ）｜して　ください。',
                normal: '無理なら、金曜日までに　出して　ください。',
                en: "If you can't, please put it out by Friday.",
                mm: 'မတတ်နိုင်ရင် သောကြာနေ့ နောက်ဆုံးထားပြီး တင်ပေးပါ။',
            }
        ],
        audioUrl: require("@assets/audio/lesson35/bunpou/4N35RB4.mp3")
    },
    {
        sentence: [
            {
                furi: '＾本（ほん）｜を　｜＾借（か）｜りたいんですが、どう　すれば　いいですか。',
                normal: '本を　借りたいんですが、どう　すれば　いいですか。',
                en: 'I would like to borrow a book, what should I do?',
                mm: 'စာအုပ်ငှားချင်လို့ ဘာလုပ်ရမလဲ။',
            },
            {
                furi: '＾受（うけ）付（つけ）｜で　カードを　｜＾作（つく）｜って　もらって　ください。',
                normal: '受付で　カードを　作って　もらって　ください。',
                en: 'Please ask the receptionist to make a card for you.',
                mm: 'သင့်အတွက် ကတ်တစ်ခုပြုလုပ်ရန် ဧည့်ခံဝန်ထမ်းအား ကျေးဇူးပြု၍ တောင်းဆိုပါ။',
            }
        ],
        audioUrl: require("@assets/audio/lesson35/bunpou/4N35RB5.mp3")
    },
    {
        sentence: [
            {
                furi: '２，３｜＾日（にち）｜　｜＾旅（りょ）行（こう）｜を　しようと　｜＾思（おも）｜って　いるんですが、どこか　いい　ところは　ありませんか。',
                normal: '２，３日　旅行を　しようと　思って　いるんですが、どこか　いい　ところは　ありませんか。',
                en: "As I'm thinking of traveling for a few days, is there anywhere good?",
                mm: 'ရက်အနည်းငယ်လောက် ခရီးထွက်ဖို့ စိတ်ကူးထားလို့ ဘယ်နေရာကို သွားရင် ကောင်းမလဲ။',
            },
            {
                furi: 'そうですね。２，３｜＾日（にち）｜なら、｜＾箱（はこ）根（ね）｜か　｜＾日（にっ）光（こう）｜が　いいと　｜＾思（おも）｜います。',
                normal: 'そうですね。２，３日なら、箱根か　日光が　いいと　思います。',
                en: 'I agree. For 2-3 days, I think she would like Hakone or Nikko.',
                mm: 'ရက်အနည်းငယ်ဆိုရင်တော့ Hakone သို့မဟုတ် Nikko ကောင်းမယ်ထင်တယ်။',
            },
            // {
            //     furi: '',
            //     normal: '',
            //     en: '',
            //     mm: '',
            // },
        ],
        audioUrl: require("@assets/audio/lesson35/bunpou/4N35RB6.mp3")
    }
]