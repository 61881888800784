
export const lesson19 = [
    {"lesson":19,"level":4,"question":"意見","represent":["意","見"],"answer":["いけん","ようい","いしき","わだい"],"true":"いけん","times":10},
    {"lesson":19,"level":4,"question":"試合","represent":["試","合"],"answer":["しあい","ごうけい","しけん","がっしゅく"],"true":"しあい","times":10},
    {"lesson":19,"level":4,"question":"用事","represent":["用","事"],"answer":["ようじ","わだい","てがみ","いし"],"true":"ようじ","times":10},
    {"lesson":19,"level":4,"question":"経験する","represent":["験"],"answer":["けいけんする","じゅけんする","しつもんする","じっけんする"],"true":"けいけんする","times":10},
    {"lesson":19,"level":4,"question":"宿題","represent":["題"],"answer":["しゅくだい","せいしつ","しけん","いけん"],"true":"しゅくだい","times":10},
    {"lesson":19,"level":4,"question":"意志","represent":["意"],"answer":["いし","しゅくだい","いしき","しあい"],"true":"いし","times":10},
    {"lesson":19,"level":4,"question":"用意","represent":["用","意"],"answer":["ようい","もんだい","しけん","いし"],"true":"ようい","times":10},
    {"lesson":19,"level":4,"question":"手紙","represent":["手","紙"],"answer":["てがみ","せいしつ","わだい","がっしゅく"],"true":"てがみ","times":10},
    {"lesson":19,"level":4,"question":"質問する","represent":["質","問"],"answer":["しつもんする","じっけんする","へんとうする","けいけんする"],"true":"しつもんする","times":10},
    {"lesson":19,"level":4,"question":"表紙","represent":["紙"],"answer":["ひょうし","しあい","せいしつ","いし"],"true":"ひょうし","times":10},
    {"lesson":19,"level":4,"question":"訪問する","represent":["問"],"answer":["ほうもんする","じゅけんする","へんとうする","けいけんする"],"true":"ほうもんする","times":10},
    {"lesson":19,"level":4,"question":"意識","represent":["意"],"answer":["いしき","がっしゅく","ひょうし","わだい"],"true":"いしき","times":10},
    {"lesson":19,"level":4,"question":"用具","represent":["用"],"answer":["ようぐ","だいめい","ひょうし","ごうけい"],"true":"ようぐ","times":10},
    {"lesson":19,"level":4,"question":"受験する","represent":["験"],"answer":["じゅけんする","じっけんする","へんとうする","ほうもんする"],"true":"じゅけんする","times":10},
    {"lesson":19,"level":4,"question":"合宿","represent":["合"],"answer":["がっしゅく","ようじ","いしき","しあい"],"true":"がっしゅく","times":10},
    {"lesson":19,"level":4,"question":"意味","represent":["意","味"],"answer":["いみ","いしき","りよう","ごうけい"],"true":"いみ","times":10},
    {"lesson":19,"level":4,"question":"話題","represent":["話","題"],"answer":["わだい","いし","ごうけい","だいめい"],"true":"わだい","times":10},
    {"lesson":19,"level":4,"question":"利用","represent":["利","用"],"answer":["りよう","ようい","ようじ","しゅくだい"],"true":"りよう","times":10},
    {"lesson":19,"level":4,"question":"実験する","represent":["験"],"answer":["じっけんする","けいけんする","しつもんする","じゅけんする"],"true":"じっけんする","times":10},
    {"lesson":19,"level":4,"question":"問題","represent":["問","題"],"answer":["もんだい","しけん","いみ","りよう"],"true":"もんだい","times":10},
    {"lesson":19,"level":4,"question":"性質","represent":["質"],"answer":["せいしつ","いみ","ごうけい","いし"],"true":"せいしつ","times":10},
    {"lesson":19,"level":4,"question":"合計","represent":["合","計"],"answer":["ごうけい","りよう","ようぐ","せいしつ"],"true":"ごうけい","times":10},
    {"lesson":19,"level":4,"question":"題名","represent":["題","名"],"answer":["だいめい","しあい","がっしゅく","もんだい"],"true":"だいめい","times":10},
    {"lesson":19,"level":4,"question":"試験","represent":["試","験"],"answer":["しけん","しあい","りよう","ようい"],"true":"しけん","times":10},
    {"lesson":19,"level":4,"question":"返答する","represent":["返","答"],"answer":["へんとうする","けいけんする","じゅけんする","じっけんする"],"true":"へんとうする","times":10},
]