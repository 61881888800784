import SizeBox from "@components/SizeBox";
import AudioPlayer from "@components/common/AudioPlayComponent";
import Number from "@components/common/Number";
import SubNavComponent from "@components/common/SubNavComponent";
import { memo } from "react";

const VocabMobileComponent = ({nabs,data,furigana,showVocab,furiTranslate,fontSize,showTranslate,lang,dark,onShuffle,lesson,kanjiDetail}:any)=>{
    return (<>
        {/* Mobile View */}
        <div className="container mx-auto flex flex-col md:hidden ">
            <SubNavComponent nabs={nabs} lesson={lesson} />
            <SizeBox vertical="h-5" />
                {
                    data.map(({ furi, normal, mm, remark_mm, en, remark_en,audioUrl }:any, i:any) => {
                        return (
                            <div key={i} className={`flex flex-col p-2 z-0`}>
                                <div className={`flex-1 flex flex-row relative `}>
                                    {showVocab && <>
                                        <Number fontSize={fontSize} furigana={furigana} style="mr-2">
                                            {i + 1})
                                        </Number>
                                        <div className="flex-1 mr-4">
                                            {/* <div className="flex-1 flex flex-row items-center justify-between mr-4"> */}
                                            {furigana === 'true' ? furiTranslate({ context: furi, fontSize, style: "flex-wrap items-center", kanjiDetail }) : normal}

                                        </div>
                                        <div className="absolute right-0 z-0">
                                            {audioUrl && !onShuffle && <AudioPlayer audioUrl={audioUrl} />}
                                        </div>
                                    </>
                                    }
                                </div>
                                
                                {showTranslate && <div className="flex-1 flex flex-row items-center leading-loose my-2">
                                    <Number fontSize={fontSize} furigana={furigana} style="mr-2 opacity-0">
                                        {i + 1})
                                    </Number>
                                    <div>
                                        
                                        {(lang === "en") &&
                                            <div>
                                                <div className="">{en}</div>
                                                {
                                                    remark_en &&
                                                    <div className="flex flex-row items-center">
                                                        ※
                                                        <div className="text-sm ml-2">{remark_en}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    
                                        {
                                            (lang === "mm") &&
                                            <div>
                                                <div className="">{mm}</div>
                                                {
                                                    remark_mm &&
                                                    <div className={`flex flex-row items-center  `}>
                                                        ※
                                                        <div className="text-sm ml-2"> {remark_mm}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>                                   
                                </div>
                                }
                                <div className={`border flex flex-row  ${dark === 'dark' ? 'border-[#424242] ' : 'border-[#C9C9C9]'}`}></div>
                            </div>
                        )
                    })
                }
        </div>
    </>)
}

export default memo(VocabMobileComponent);