export const explain= [
    {
        // ＾友（とも）達（だち）｜
        pattern_furi: 'Vる+ところ',
        pattern_normal: 'Vる+ところ',
        mm: [
            "　လုပ်တော့မလို့",
        ],
        en: [ 
            'going to do',
        ]
    },   
    {
        pattern_furi: 'Vている+ところ',
        pattern_normal: 'Vている+ところ',
        mm: [
            "　လုပ်နေတုန်း",
        ],
        en: [ 
            'still doing',
        ]
    },   
    {
        pattern_furi: 'Vた+ところ',
        pattern_normal: 'Vた+ところ',
        mm: [
            "　လုပ်ပြီးခါစ‌",
        ],
        en: [ 
            'just',
        ]
    },   
    {
        pattern_furi: 'Vた+ばかり',
        pattern_normal: 'Vた+ばかり',
        mm: [
            "　လုပ်ပြီးခါစ‌",
            "　ばかりကို တစ်သက်မှာ တစ်ခါလောက်သာ လုပ်သော ကိစ္စများတွင် အသုံးများသည်။‌",
        ],
        en: [ 
            'just',
            'It is commonly used in once-in-a-lifetime situations.',
        ]
    },   
    {
        pattern_furi: 'Vる/Vない/Adjな/Nの+はず',
        pattern_normal: 'Vる/Vない/Adjな/Nの+はず',
        mm: [
            "　ကျိန်းသေဖြစ်လိမ့်မယ်‌",
            "　ဖြစ်နိုင်ချေနှုန်းမြင့်မားတယ် ဆိုတဲ့ အဓိပ္ပါယ် သက်ရောက်ပါတယ်။‌",
        ],
        en: [ 
            'It will definitely happen',
            'It means that the probability rate is high.',
        ]
    },   
    
]