import Helper from "@helper";

export const fukushuu5 = {
    mondai1:
    Helper.shuffle(
        [
            {
                question: "＾変（へん）｜な　におい｜＄０｜しますね。ごみ｜＄１｜＾燃（も）｜えて　いるようです。",
                picked: ["",""],
                answers:Helper.shuffle(["が","は","を","に"]),
                correct: ["が","が"],
            },
            {
                question: "＾息（むす）子（こ）｜は　ことし　｜＾高（こう）校（こう）｜＄０｜＾卒（そつ）業（ぎょう）｜して　｜＾富（ふ）士（じ）大（だい）学（がく）｜＄１｜＾入（にゅう）学（がく）｜しました。",
                picked: ["",""],
                answers:Helper.shuffle(["を","に","は","が"]),
                correct: ["を","に"],
            },
            {
                question: "＾母（はは）｜は　｜＾妹（いもうと）｜＄０｜＾塾（じゅく）｜＄１｜＾通（かよ）｜わせて　います。",
                picked: ["",""],
                answers:Helper.shuffle(["を","に","と","が"]),
                correct: ["を","に"],
            },
            {
                question: "わたしは　｜＾息（むす）子（こ）｜＄０｜＾犬（いぬ）｜の　｜＾世（せ）話（わ）｜＄１｜させました。",
                picked: ["",""],
                answers:Helper.shuffle(["に","を","が","へ"]),
                correct: ["に","を"],
            },
            {
                question: "どうぞ　この　いす｜＄０｜お｜＾掛（か）｜け　ください。",
                picked: [""],
                answers:Helper.shuffle(["に","を","が","で"]),
                correct: ["に"],
            },
            {
                question: "ＩＭＣ｜＄０｜＾務（つと）｜めて　おります。",
                picked: [""],
                answers:Helper.shuffle(["に","で","へ","の"]),
                correct: ["に"],
            }
    ]
    )
    ,
    mondai2: //Helper.shuffle(
        [
        {
            question: "雨の日には｜！交通｜事故が発生する可能性が高いです。",
            picked: "",
            answers: Helper.shuffle(["こうつう", "こつう", "こうつ", "ごうつう"]),
            correct: "こうつう",
        },
        {
            question: "！運動｜した方がいいよ。",
            picked: "",
            answers: Helper.shuffle(["うんどう", "かんどう", "じどう", "たいどう"]),
            correct: "うんどう",
        },
        {
            question: "電車に乗るときは、｜！乗車｜口から入ってください",
            picked: "",
            answers: Helper.shuffle(["じょうしゃ", "げしゃ", "にゅうしゃ", "れっしゃ"]),
            correct: "じょうしゃ",
        },
        {
            question: "次の駅で｜！降｜ります。",
            picked: "",
            answers: Helper.shuffle(["お", "ふ", "の", "う"]),
            correct: "お",
        },
        {
            question: "彼は毎日車を｜！運転｜して通勤しています。",
            picked: "",
            answers: Helper.shuffle(["うんてん", "うんどう", "じてん", "うんそう"]),
            correct: "うんてん",
        },
        {
            question: "さとうさんは｜！帰｜りましたか？",
            picked: "",
            answers: Helper.shuffle(["かえ", "の", "ふ", "う"]),
            correct: "かえ",
        },
        {
            question: "試験の結果は明日｜！発表｜される。",
            picked: "",
            answers: Helper.shuffle(["はっぴょう", "はっぴょ", "はつびょう", "はつぴょう"]),
            correct: "はっぴょう",
        },
        {
            question: "今日の飛行機は予定通りに｜！到着｜しました。",
            picked: "",
            answers: Helper.shuffle(["とうちゃく", "とちゃく", "とっちゃく", "どうちゃく"]),
            correct: "とうちゃく",
        },
        {
            question: "！漢字｜は読むのが難しい。",
            picked: "",
            answers: Helper.shuffle(["かんじ", "けんじ", "かんし", "けんし"]),
            correct: "かんじ",
        },
        
        {
            question: "私の｜！文｜は正しいですか？",
            picked: "",
            answers: Helper.shuffle(["ぶん", "ふん", "ぷん", "ぼん"]),
            correct: "ぶん",
        },
        {
            question: "ぞの｜！教室｜は静かだった。",
            picked: "",
            answers: Helper.shuffle(["きょうしつ", "へや", "しんしつ", "きょうし"]),
            correct: "きょうしつ",
        },
        {
            question: "私は日本語を｜！勉強｜している。",
            picked: "",
            answers: Helper.shuffle(["べんきょう", "れんしゅう", "ふくしゅう", "けんきゅう"]),
            correct: "べんきょう",
        },
        {
            question: "試験のために｜！練習｜しています。",
            picked: "",
            answers: Helper.shuffle(["べんきょう", "れんしゅう", "ふくしゅう", "けんきゅう"]),
            correct: "れんしゅう",
        },
        {
            question: "私の｜！考｜えはあなたのとは違う。",
            picked: "",
            answers: Helper.shuffle(["かんが", "おし", "むか", "か"]),
            correct: "かんが",
        },
        
        {
            question: "彼女は科学の｜！研究｜に一生をささげた。",
            picked: "",
            answers: Helper.shuffle(["けんきゅう", "れんしゅう", "ふくしゅう", "がくしゅう"]),
            correct: "けんきゅう",
        },
        {
            question: "この｜！問題｜はとても難しい。",
            picked: "",
            answers: Helper.shuffle(["もんだい", "かだい", "じだい", "しゅくだい"]),
            correct: "もんだい",
        },
        {
            question: "！試験｜のために練習しています。",
            picked: "",
            answers: Helper.shuffle(["しけん", "いけん", "じけん", "けいけん"]),
            correct: "しけん",
        },
        {
            question: "全ての｜！質問｜に答えなさい。",
            picked: "",
            answers: Helper.shuffle(["しつもん", "せいもん", "ちゅうもん", "せんもん"]),
            correct: "しつもん",
        },
        {
            question: "彼は｜！具合｜が悪そうだ。",
            picked: "",
            answers: Helper.shuffle(["ぐあい", "しあい", "ばあい", "しりあい"]),
            correct: "ぐあい",
        },
        {
            question: "お友達からのメッセージに、すぐに｜！返答｜しました。",
            picked: "",
            answers: Helper.shuffle(["へんとう", "へんしん", "かいとう", "へんじ"]),
            correct: "へんとう",
        },
        {
            question: "！用意｜の必要があるのでおさきにしつれいします。",
            picked: "",
            answers: Helper.shuffle(["ようじ", "かじ", "ようい", "ちゅうい"]),
            correct: "ようい",
        },
        {
            question: "この店では、商品を買うときに｜！割引｜があります。",
            picked: "",
            answers: Helper.shuffle(["わりびき", "わりぴき", "わりひき", "ねりびき"]),
            correct: "わりびき",
        },
        {
            question: "会議は午後2時から｜！開始｜します。",
            picked: "",
            answers: Helper.shuffle(["かいし", "かいじ", "かいはつ", "かいてん"]),
            correct: "かいし",
        },
        {
            question: "！閉店｜時間は何時ですか。",
            picked: "",
            answers: Helper.shuffle(["へいてん", "かいてん", "してん", "ばいてん"]),
            correct: "へいてん",
        },
        {
            question: "！去年｜は暖冬だったんですよ。",
            picked: "",
            answers: Helper.shuffle(["きょねん", "さくねん", "らいねん", "きょうねん"]),
            correct: "きょねん",
        },
        {
            question: "会社は新しい社員を｜！募集｜しています。",
            picked: "",
            answers: Helper.shuffle(["ぼしゅう", "かいしゅう", "れんしゅう", "ふくしゅう"]),
            correct: "ぼしゅう",
        },
        {
            question: "新しい言葉を学ぶことは｜！知識｜を増やすことができます。",
            picked: "",
            answers: Helper.shuffle(["ちしき", "かいしき", "わしき", "けしき"]),
            correct: "ちしき",
        },
        {
            question: "彼は今｜！小説｜を読んでいる。",
            picked: "",
            answers: Helper.shuffle(["しょうせつ", "こせつ", "しょせつ", "こっせつ"]),
            correct: "しょうせつ",
        },
    ]
    //)
}