import Icon from '@consts/icon';
import * as React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

import Helper from '@helper';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { RootState } from '@store';
import { useSelector } from 'react-redux';
interface PatternType {
    pattern_furi: string;
    pattern_normal: string;
    en: Array<string>;
    mm: Array<string>;
}
interface AccordionProp {
    patterns: Array<PatternType>;
    furigana: string;
    fontSize: number;
    lang: string;
}

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const CustomizedAccordions = ({ patterns, furigana, fontSize, lang }: AccordionProp)=> {
    const [expanded, setExpanded] = React.useState<string | false>();
    const dark = useSelector((state:RootState)=>state.service.dark);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        }; 
    const ExplainRender = ({ explain }: {
        explain: string
    }) => {
        return <div className='flex flex-row mb-1 text-[16px] items-start'>
            <img src={Icon.Grammars.Bullet} style={{ marginTop: 6 }} />
            {explain}
        </div>
    }

    const Accordion = styled((props: AccordionProps) => (
        <MuiAccordion disableGutters elevation={0} square {...props}
     />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        // borderColor: localStorage.getItem("dark")==='dark' ? '#424242' :'#C8C8C8',
        borderColor: dark==='dark' ? '#424242' :'#C8C8C8',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));

    return (
        <div>
            {
                patterns.map(({ pattern_furi,pattern_normal, en, mm }: PatternType, index: number) => {
                    return <Accordion
                        key={index}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                        className='dark:bg-darkFooter dark:text-white'
                    >
                        <AccordionSummary
                            aria-controls={`panel${index}d-content`}
                            expandIcon={<ExpandMoreIcon className='dark:text-white' />}
                            id={`panel${index}d-header`}
                        >
                            <Typography className='flex flex-row items-center'>
                                <div className='mr-2'>{index + 1}.</div>
                                {
                                    furigana === 'true' && pattern_furi.includes("＾") ? Helper.furiTranslate({ context: pattern_furi, fontSize, style: "" })
                                        : pattern_normal 
                                }
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                {(lang === 'en' ? en : mm).map((explain: string, index: number) => {
                                    return <div key={index}><ExplainRender explain={explain} /></div>
                                })}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                })
               
            }
        </div>
    );
}
export default React.memo(CustomizedAccordions);