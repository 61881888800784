export const reibun= [
    {
        sentence: [
            {
                furi: "＾泣（な）｜いて　いるんですか。",
                normal: "泣いて　いるんですか。",
                en: "Are you crying?",
                mm: "မင်း ငိုနေတာလား။",
            },
            {
                furi: "いいえ、｜＾笑（わら）｜いすぎて、｜＾涙（なみだ）｜が　｜＾出（で）｜たんです。",
                normal: "いいえ、笑いすぎて、涙が　出たんです。",
                en: "No, I laughed so hard that I cried.",
                mm: "မဟုတ်ဘူး၊ အရမ်းရယ်ပြီး မျက်ရည်ထွက်လာတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson44/bunpou/4N44RB1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾最（さい）近（きん）｜の　｜＾車（くるま）｜は　｜＾操（そう）作（さ）｜が　｜＾簡（かん）単（たん）｜ですね。",
                normal: "最近の　車は　操作が　簡単ですね。",
                en: "Modern cars are easy to operate.",
                mm: "ခုခေတ်ကားတွေက လည်ပတ်ရလွယ်တယ်။",
            },
            {
                furi: "ええ。でも、｜＾簡（かん）単（たん）｜すぎて、｜＾運（うん）転（てん）｜が　おもしろくないです。",
                normal: "ええ。でも、簡単すぎて、運転が　おもしろくないです。",
                en: "Yeah. But it's too easy and it's not fun to drive.",
                mm: "အင်း။ ဒါပေမယ့် အရမ်းလွယ်ပြီး ကားမောင်းရတာ မပျော်‌တော့ဘူး။",
            },
        ],
        audioUrl: require("@assets/audio/lesson44/bunpou/4N44RB2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾田（い）舎（なか）｜と　｜＾町（まち）｜と、どちらが　｜＾住（す）｜みやすいですか。",
                normal: "田舎と　町と、どちらが　住みやすいですか。",
                en: "Which is easier to live in, the countryside or the town?",
                mm: "မြို့နဲ့ကျေးလက်မှာ ဘယ်ဟာက နေရတာ ပိုလွယ်လဲ။",
            },
            {
                furi: "＾田（い）舎（なか）｜の　ほうが　｜＾住（す）｜みやすいと　｜＾思（おも）｜います。",
                normal: "田舎の　ほうが　住みやすいと　思います。",
                en: "I think living in the countryside is easier.",
                mm: "ကျေးလက်မှာနေရတာ ပိုလွယ်မယ်ထင်တယ်။",
            },
            {
                furi: "＾物（ぶ）価（っか）｜も　｜＾安（やす）｜いし、｜＾空（くう）気（き）｜も　きれいですから。",
                normal: "物価も　安いし、空気も　きれいですから。",
                en: "It's cheap and the air is clean.",
                mm: "စျေးနှုန်းချိုသာပြီး လေကောင်းလေသန့်ရတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson44/bunpou/4N44RB3.mp3")
    },
    {
        sentence: [
            {
                furi: "この　コップは　｜＾丈（じょう）夫（ぶ）｜で　｜＾割（わ）｜れにくいですよ。",
                normal: "この　コップは　丈夫で　割れにくいですよ。",
                en: "This cup is strong and hard to break.",
                mm: "ဒီခွက်က ခိုင်ခံ့ပြီး မကွဲလွယ်ဘူး။",
            },
            {
                furi: "＾子（こ）｜どもが　｜＾使（つか）｜うのに　｜＾安（あん）全（せん）｜で、いいですね。",
                normal: "子どもが　使うのに　あんぜんで、いいですね。",
                en: "It's nice and warm for children to use.",
                mm: "ကလေးတွေ သုံးဖို့ ဘေးကင်းလို့ ကောင်းလိုက်တာ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson44/bunpou/4N44RB4.mp3")
    },
    {
        sentence: [
            {
                furi: "もう　｜＾夜（よる）｜　｜＾遅（おそ）｜いですから、｜＾静（しず）｜かに　して　いただけませんか。",
                normal: "もう　夜　遅いですから、静かに　して　いただけませんか。",
                en: "It's already late at night, so could you please be quiet?",
                mm: "ညနက်နေပြီမို့ တိတ်တိတ်ဆိတ်ဆိတ် နေပေးလို့ ရနိုင်မလား။",
            },
            {
                furi: "はい。すみません。",
                normal: "はい。すみません。",
                en: "yes. sorry.",
                mm: "ဟုတ်ကဲ့၊ တောင်းပန်ပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson44/bunpou/4N44RB5.mp3")
    },
    {
        sentence: [
            {
                furi: "＾飲（の）｜み｜＾物（もの）｜は　｜＾何（なん）｜に　しますか。",
                normal: "飲み物は　何に　しますか。",
                en: "What would you like to drink.",
                mm: "သောက်စရာက ဘာယူမှာလဲ။",
            },
            {
                furi: "ビールに　します。",
                normal: "ビールに　します。",
                en: "I'll have a beer.",
                mm: "ဘီယာယူမယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson44/bunpou/4N44RB6.mp3")
    },
]