export const explain= [
    {
        pattern_furi: '～ようになります',
        pattern_normal: '～ようになります',
        en: [
            '　to try to/ to make sure that',
            '　Describing changing circumstances; orders and instructions; Used in prayers.'
        ],
        mm: [ 
            '　–အောင်လုပ်တယ်',
            '　ပြောင်းလဲဖြစ်ပေါ်သော အခြေအနေများကို ဖေါ်ပြခြင်း၊ အမိန့်ပေးညွန်ကြားခြင်း၊ ဆုတောင်းခြင်းများပြုလုပ်ရာတွင်သုံးသည်။'
        ]
    },
    {
        pattern_furi: '～ようにしてください',
        pattern_normal: '～ようにしてください',
        en: [
            '　try to make sure that',
            '　Describing changing circumstances; orders and instructions; Used in prayers.'
        ],
        mm: [ 
            '　–အောင်လုပ်ပါ',
            '　ပြောင်းလဲဖြစ်ပေါ်သော အခြေအနေများကို ဖေါ်ပြခြင်း၊ အမိန့်ပေးညွန်ကြားခြင်း၊ ဆုတောင်းခြင်းများပြုလုပ်ရာတွင်သုံးသည်။'
        ]
    },
    {
        pattern_furi: '~しなくなります',
        pattern_normal: '~しなくなります',
        en: [
            "　- I don't do it anymore",
            '　Used after negative verbs.',
            '　Stopping an intended item; It is used when it is discontinued',
        ],
        mm: [
            '　–မလုပ်ဖြစ်တော့ဘူး',
            '　အငြင်းပုံစံ ကြိယာများ၏ နောက်တွင်ထား၍သုံးသည်။',
            '　ရည်ရွယ်ထားသော အကြောင်းအရာတစ်ခုကို ရပ်တန့်လိုက်ခြင်း၊ ဆက်မလုပ်တော့သည့် အခါများတွင်သုံးသည်။',
        ]
    },
    {
        pattern_furi: 'やっと',
        pattern_normal: 'やっと',
        en: [
            '　But now–',
            '　Used when a pending case is successful.',
            '　Used in good cases.',
        ],
        mm: [
            '　အခုမှဘဲ–',
            '　မျှော်လင့်စောင့်ဆိုင်းနေသော ကိစ္စတစ်ခု အထမြောက်အောင်မြင်သောအခါတွင်သုံးသည်။ ',
            '　ကောင်းသောကိစ္စရပ်များတွင်သုံးသည်။',
        ]
    }
]