import Icon from "@consts/icon";

export const lesson50 = {
    title: {
        furi: '＾心（こころ）｜から　｜＾感（かん）謝（しゃ）｜いたします',
        normal: '心から　感謝いたします',
        en: 'Sincerely thank',
        mm: 'နှလုံးသားထဲကနေပြီးတော့ ကျေးဇူးတင်ပါတယ်',
    },
    conversation: [
        {
            person: {
                furi: '＾司（し）会（かい）者（しゃ）',
                normal: '司会者',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '＾優（ゆう）勝（しょう）｜　おめでとう　ございます。',
                    normal: '優勝　おめでとう　ございます。',
                    en: 'Congratulations on your victory',
                    mm: 'အနိုင်ရတဲ့အတွက် ဂုဏ်ယူပါတယ်',
                },
                {
                    furi: 'すばらしい　スピーチでした。',
                    normal: 'すばらしい　スピーチでした。',
                    en: 'It was a wonderful speech.',
                    mm: 'အံ့သြစရာကောင်းတဲ့ မိန့်ခွန်းဖြစ်ခဲ့ပါတယ်',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'ありがとう　ございます。',
                    normal: 'ありがとう　ございます。',
                    en: 'Thank you so much',
                    mm: 'ကျေးဇူးတင်ပါတယ်',
                },
            ],
        },
        {
            person: {
                furi: '＾司（し）会（かい）者（しゃ）',
                normal: '司会者',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '＾緊（きん）張（ちょう）｜なさいましたか。',
                    normal: '緊張なさいましたか。',
                    en: 'Are you nervous?',
                    mm: 'အရမ်းစိတ်လှုပ်ရှားနေလားမသိဘူး',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'はい、とても　｜＾緊（きん）張（ちょう）｜いたしました。',
                    normal: 'はい、とても　緊張いたしました。',
                    en: 'Yes, I was very nervous.',
                    mm: 'ဟုတ်ကဲ့ ‌တော်တော်လေး စိတ်လှုပ်ရှားနေပါတယ်',
                },
            ],
        },
        {
            person: {
                furi: '＾司（し）会（かい）者（しゃ）',
                normal: '司会者',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '＾練（れん）習（しゅう）｜は　｜＾大（たい）変（へん）｜でしたか。',
                    normal: '練習は　大変でしたか。',
                    en: 'Was the practice hard?',
                    mm: 'လေ့ကျင့်တာ တော်တော်လေးပင်ပန်းခဲ့လား',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'ええ。｜＾忙（いそが）｜しくて、なかなか　｜＾練（れん）習（しゅう）｜の｜＾時（じ）間（かん）｜が　ありませんでした。',
                    normal: 'ええ。忙しくて、なかなか　練習の時間が　ありませんでした。',
                    en: "Yeah. I was so busy that I didn't have much time to practice.",
                    mm: 'အင်း၊ အလုပ်များတဲ့အတွက် တော်တော်နဲ့ လေ့ကျင့်ဖို့အချိန်သိပ်မရခဲ့ဘူး',
                },
            ],
        },
        {
            person: {
                furi: '＾司（し）会（かい）者（しゃ）',
                normal: '司会者',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '＾賞（しょう）金（きん）｜は　｜＾何（なん）｜に　お｜＾使（つか）｜いに　なりますか。',
                    normal: '賞金は　何に　お使いに　なりますか。',
                    en: 'What are you going to use the prize money for?',
                    mm: 'ဆုကြေးငွေကို ဘာအတွက်အသုံးပြုမလဲ',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'そうですね。わたしは　｜＾動（どう）物（ぶつ）｜が　｜＾好（す）｜きで、　｜＾子（こ）｜どもの　ときから　アフリカへ　｜＾行（い）｜くのが　｜＾夢（ゆめ）｜でした。',
                    normal: 'そうですね。わたしは　動物が　好きで、　子どもの　ときから　アフリカへ　行くのが　夢でした。',
                    en: 'I agree. I love animals and have dreamed of going to Africa since I was a child.',
                    mm: 'ဟုတ်ကဲ့ ကျနော် တိရစ္ဆာန်ချစ်တတ်ပြီးတော့ ကလေးဘဝကတည်းက အာဖရိကကို သွားချင်တဲ့အိမ်မက်ရှိခဲ့တယ်',
                },
            ],
        },
        {
            person: {
                furi: '＾司（し）会（かい）者（しゃ）',
                normal: '司会者',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'じゃ、アフリカへ　｜＾行（い）｜かれますか。',
                    normal: 'じゃ、アフリカへ　行かれますか。',
                    en: 'Then, would you like to go to Africa?',
                    mm: 'ဒါဆိုရင် အာဖရိက သွားနိုင်ပြီမလား',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'はい、アフリカの　｜＾自（し）然（ぜん）｜の　｜＾中（なか）｜で　きりんや　｜＾像（ぞう）｜を　｜＾見（み）｜たいと　｜＾思（おも）｜います。',
                    normal: 'はい、アフリカの　自然の　中で　きりんや　像を　見たいと　思います。',
                    en: 'Yes, I would like to see giraffes and statues in African nature.',
                    mm: 'ဟုတ်ကဲ့ အာဖရိကရဲ့ သဘာဝနဲ့ သစ်ကုလားအုပ်တွေ ဆင်တွေကို ကြည့်ချင်တယ်လို့ တွေးထားပါတယ်',
                },
            ],
        },
        {
            person: {
                furi: '＾司（し）会（かい）者（しゃ）',
                normal: '司会者',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '＾子（こ）｜どもの　ころの　｜＾夢（ゆめ）｜が　かなうんですね。',
                    normal: '子どもの　ころの　夢が　かなうんですね。',
                    en: "It's a childhood dream come true.",
                    mm: 'ကလေးတုန်းက အိမ်မက်က တစ်ကယ်ဖြစ်လာပြီပေါ့နော်',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'はい。うれしいです。',
                    normal: 'はい。うれしいです。',
                    en: "yes. I'm glad.",
                    mm: 'ဟုတ်ကဲ့ ဝမ်းသာပါတယ်',
                },
                {
                    furi: '＾応（おう）援（えん）｜して　くださった　｜＾皆（みな）様（さま）｜に　｜＾心（こころ）｜から　｜＾感（かん）謝（しゃ）｜いたします。',
                    normal: '応援して　くださった　皆様に　心から　感謝いたします。',
                    en: 'We would like to express our sincere gratitude to everyone who supported us.',
                    mm: 'အားပေးခဲ့တဲ့ ဂုဏ်သရေရှိ လူကြီးများအား နှလုံးသားထဲကနေ',
                },
                {
                    furi: 'どうも　ありがとう　ございました。',
                    normal: 'どうも　ありがとう　ございました。',
                    en: 'thank you very much.',
                    mm: 'ကျေးဇူးအထူးတင်ရှိပါတယ်ခင်ဗျား',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson50/kaiwa/4N50KW.mp3")
}