export const renshuuA = [
    {
        sentence: [
            {
                furi: "わたしは　しゃちょう　に　お｜＾土（み）産（やげ）｜を　いただきました。",
                normal: "わたしは　しゃちょう　に　お土産を　いただきました。",
                en: "I received a souvenir from the president.",
                mm: "သူဌေးထံမှ အမှတ်တရ လက်ဆောင် လက်ခံရရှိခဲ့သည်။",
            },
            {
                furi: "わたしは　せんせい　に　お｜＾土（み）産（やげ）｜を　いただきました。",
                normal: "わたしは　せんせい　に　お土産を　いただきました。",
                en: "I received a souvenir from teacher.",
                mm: "ဆရာထံမှ အမှတ်တရလက်ဆောင်တစ်ခု ရရှိခဲ့သည်။",
            },
            {
                furi: "わたしは　やまださん　に　お｜＾土（み）産（やげ）｜を　いただきました。",
                normal: "わたしは　やまださん　に　お土産を　いただきました。",
                en: "I received a souvenir from Mr. Yamada.",
                mm: "Mr. Yamada ထံမှ အမှတ်တရလက်ဆောင်တစ်ခု ရရှိခဲ့ပါသည်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson41/renshua/4N41RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "しゃちょう　が　わたしに　お｜＾土（み）産（やげ）｜を　くださいました。",
                normal: "しゃちょう　が　わたしに　お土産を　くださいました。",
                en: "The president gave me a souvenir.",
                mm: "ဥက္ကဌကြီးက အမှတ်တရလက်ဆောင်ပေးခဲ့တယ်။",
            },
            {
                furi: "せんせい　が　わたしに　お｜＾土（み）産（やげ）｜を　くださいました。",
                normal: "せんせい　が　わたしに　お土産を　くださいました。",
                en: "The teacher gave me a souvenir.",
                mm: "ဆရာက ကျွန်တော့်ကို အမှတ်တရလက်ဆောင်ပေးတယ်။",
            },
            {
                furi: "やまださん　が　わたしに　お｜＾土（み）産（やげ）｜を　くださいました。",
                normal: "やまださん　が　わたしに　お土産を　くださいました。",
                en: "Mr. Yamada gave me a souvenir.",
                mm: "Mr. Yamada က ကျွန်တော့်ကို အမှတ်တရလက်ဆောင်ပေးတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson41/renshua/4N41RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "わたしは　まご　に　お｜＾菓（か）子（し）｜　を　やりました。",
                normal: "わたしは　まご　に　お菓子　を　やりました。",
                en: "I gave sweets to my grandson.",
                mm: "ငါ့မြေးကို ချိုချဉ်တွေ ပေးခဲ့တယ်။",
            },
            {
                furi: "わたしは　き　に　｜＾水（みず）｜を　やりました。",
                normal: "わたしは　き　に　水を　やりました。",
                en: "I watered it every day.",
                mm: "ငါ နေ့တိုင်း အပင်ရေလောင်းတယ်။",
            },
            {
                furi: "わたしは　ねこ　に　えさを　やりました。",
                normal: "わたしは　ねこ　に　えさを　やりました。",
                en: "I fed the cat.",
                mm: "ကြောင်ကို ငါအစာကျွေးတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson41/renshua/4N41RSA3.mp3")
    },
    {
        sentence: [
            {
                furi: "わたしは　｜＾先（せん）生（せい）｜に　｜＾旅（りょ）行（こう）｜の　｜＾写（しゃ）真（しん）｜を　みせて　いただきました。",
                normal: "わたしは　先生に　旅行の　写真を　みせて　いただきました。",
                en: "My teacher showed me the photos of the trip.",
                mm: "ဆရာက ခရီးစဉ်ရဲ့ ဓာတ်ပုံတွေကို ပြတယ်။",
            },
            {
                furi: "わたしは　｜＾先（せん）生（せい）｜に　｜＾京（きょう）都（と）｜へ　つれて　いって　いただきました。",
                normal: "わたしは　先生に　京都へ　つれて　いって　いただきました。",
                en: "My teacher took me to Kyoto.",
                mm: "ဆရာက ကျိုတိုကို ခေါ်သွားတယ်။",
            },
            {
                furi: "わたしは　｜＾先（せん）生（せい）｜に　レポートを　なおして　いただきました。",
                normal: "わたしは　先生に　レポートを　なおして　いただきました。",
                en: "My teacher corrected my report.",
                mm: "ဆရာက ငါ့အစီရင်ခံစာကို ပြင်‌ပေးတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson41/renshua/4N41RSA4.mp3")
    },
    {
        sentence: [
            {
                furi: "＾先（せん）生（せい）｜が　｜＾旅（りょ）行（こう）｜の　｜＾写（しゃ）真（しん）｜を　みせて　くださいました。",
                normal: "先生が　旅行の　写真を　みせて　くださいました。",
                en: "My teacher showed me a photo of the trip.",
                mm: "ဆရာက ခရီးစဉ်ရဲ့ ဓာတ်ပုံကို ပြတယ်။",
            },
            {
                furi: "＾先（せん）生（せい）｜が　｜＾京（きょう）都（と）｜へ　つれて　いって　くださいました。",
                normal: "先生が　京都へ　つれて　いって　くださいました。",
                en: "My teacher took me to Kyoto.",
                mm: "ဆရာက ကျိုတိုကို ခေါ်သွားတယ်။",
            },
            {
                furi: "＾先（せん）生（せい）｜が　レポートを　｜＾直（なお）｜して　くださいました。",
                normal: "先生が　レポートを　直して　くださいました。",
                en: "The teacher corrected the report. ",
                mm: "ဆရာက အစီရင်ခံစာကို ပြင်ပေးတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson41/renshua/4N41RSA5.mp3")
    },
    {
        sentence: [
            {
                furi: "わたしは　｜＾孫（まご）｜に　｜＾英（えい）語（ご）｜を　おしえて　やりました。",
                normal: "わたしは　孫に　英語を　おしえて　やりました。",
                en: "I taught my grandson English.",
                mm: "ငါ့မြေးကို အင်္ဂလိပ်စာ သင်ပေးတယ်။",
            },
            {
                furi: "わたしは　｜＾孫（まご）｜を　｜＾幼（よう）稚（ち）園（えん）｜まで　むかえに　いって　やりました。",
                normal: "わたしは　孫を　幼稚園まで　むかえに　いって　やりました。",
                en: "I picked up my grandson from kindergarten.",
                mm: "ကျွန်တော်က မြေးလေးကို သူငယ်တန်းအထိကြိုခဲ့တယ်။",
            },
            {
                furi: "わたしは　｜＾孫（まご）｜の　｜＾宿（しゅく）題（だい）｜を　みて　やりました。",
                normal: "わたしは　孫の　宿題を　みて　やりました。",
                en: "I did my grandson's homework.",
                mm: "ကျွန်တော် မြေးရဲ့ အိမ်စာကို လုပ်ပေးတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson41/renshua/4N41RSA6.mp3")
    },
    {
        sentence: [
            {
                furi: "ひらがなで　かいて　くださいませんか。",
                normal: "ひらがなで　かいて　くださいませんか。",
                en: "Could you please write in hiragana?",
                mm: "ကျေးဇူးပြုပြီး hiragana နဲ့ရေးလို့ရမလား",
            },
            {
                furi: "もう　｜＾少（すこ）｜し　ゆっくり　はなして　くださいませんか。",
                normal: "もう　少し　ゆっくり　はなして　くださいませんか。",
                en: "Could you please speak a little more slowly?",
                mm: "ကျေးဇူးပြုပြီး နည်းနည်း ဖြည်းဖြည်းပြောနိုင်မလား။",
            },
            {
                furi: "この　｜＾漢（かん）字（じ）｜の　｜＾読（よ）｜み｜＾方（かた）｜を　おしえて　くださいませんか。",
                normal: "この　漢字の　読み方を　おしえて　くださいませんか。",
                en: "Could you tell me how to read this kanji?",
                mm: "ဒီ kanji ကို ဘယ်လိုဖတ်ရမလဲဆိုတာ ပြောပြနိုင်မလား။",
            },
        ],
        audioUrl: require("@assets/audio/lesson41/renshua/4N41RSA7.mp3")
    },
];