import { memo } from "react";

const ConversationComponent = ({ furigana, furiTranslate, fontSize, lang, conversation, showTranslate,kanjiDetail }: any) => {
    return <div key={Math.random()}
        className="p-4 md:p-6  dark:bg-darkFooter">
        <div className="flex flex-col md:flex-row ">
            <div className="w-full ">
                {
                    conversation.map(({ person, sentence }: any, i: number) => {
                        return <div key={i} className="flex flex-col">
                            <div className={` flex flex-row ${i % 2 == 0 ? '' : ' flex-row-reverse'}`}>
                                {/* Avatar */}
                                <div className={` ${i % 2 == 0 ? 'mr-1 md:mr-2' : 'ml-1 md:ml-2'}`}>
                                    <img src={person.avatar} className="w-fit h-fit"/>
                                </div>
                                <div className="flex-1 flex flex-col ">
                                    {/* Name  */}
                                    <div className={` flex flex-row ${i % 2 == 0 ? '' : 'justify-end'}`}>
                                        {furigana === 'true' && person.furi.includes("＾") ? furiTranslate({ context: person.furi, fontSize, style: "flex-wrap items-center",kanjiDetail }) : person.normal}
                                    </div>
                                    {/* Conversation */}
                                    <div className={`flex flex-col ${i % 2 == 0 ? '' : 'items-end'}`}>
                                        {(sentence.map(({ furi, normal, en, mm }: any, i: number) => {
                                            return <div key={i} className="flex flex-col bg-[#C7E7FF] pl-4 pr-2  py-2 mb-2 w-fit rounded-[15px] text-[#001E34] font-[400px] ">
                                                    <div>
                                                    {(furigana === 'true' && furi.includes("＾") ? furiTranslate({ context: furi, fontSize, style: "flex-wrap items-center" ,kanjiDetail}) : normal)}
                                                    </div>
                                                    <div className="mr-2">{showTranslate ? (lang === 'en' ? en : mm) : null}</div>                                                        
                                             </div>
                                             
                                        }))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </div>
}
export default memo(ConversationComponent);