import agree from "@assets/icon/about/agree.svg";
import cancel from "@assets/icon/cancel.svg";
import copy from "@assets/unlock/copy.svg";
import unlock from "@assets/unlock/unlock-victor.svg";
import Helper from "@helper";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Routes from '@routes/Routes';
import { useAppSelector as selector, useAppDispatch } from '@store/hooks';
import { Dispatch, SetStateAction, memo, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { GiCheckMark } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: '20px',
  boxShadow: 24,
  // p: 70,
};

interface MoldelProps {
  showModel: boolean,
  setUnlockPremium: (unlockCode: string)=>void;
  setShowModel: Dispatch<SetStateAction<boolean>>
}

const UnlockModal = ({  showModel, setShowModel,setUnlockPremium }: MoldelProps) => {
  const { lang,dark } = selector(({ service }) => service);
  const navigate = useNavigate();

  const [unlockStatus,setUnlockStatus] = useState(false);
  const [errMsg,setErrMsg] = useState("")
  const [unlockCode,setUnlockCode] = useState<string>("");
  const [width, setWidth] = useState(window.innerWidth);
  const [onCopy, setOnCopy] = useState(false);
  const dispatch = useAppDispatch();
  const uuid = localStorage.getItem("uuid");
  const [a,b,c,d,requestCode] = uuid!.split("-");

  const handleClose = () => {
    setShowModel(false);
  }
  const unlockParagraph = {
    first: lang == 'en' ? "Click copy button to get request code and send it admin to unlock." :"Copy ခလုတ်ကို နှိပ်ပြီး ကုဒ်ကို Adminထံ ပေးပို့ကာ Unlockကုဒ်ကို ဝယ်ယူနိုင်ပါသည်။",
    second: lang == 'en' ? "If your receive unlock code from admin, please enter that code and unlock." : "ဝယ်ယူလာသော unlock ကုတ်ကို ထည့်သွင်းပြီး unlock ကိုနိပ်ပါ။",
    third: lang == 'en' ? "Get the new experience" : ""
  }
  const unlockAction = ()=>{
    setErrMsg("");
    const satisfied= Helper.checkAuth(unlockCode);
    if(satisfied){
      setUnlockPremium(unlockCode);
      setUnlockStatus(true);
    }else{
      setErrMsg("Wrong unlock code!");
    }
  }
  const resizeHandler = () => {
    setWidth(window.innerWidth);
  }
  const onCopyFinish = async() => {
    setOnCopy(true);
    await Helper.sleep(3000);
    setOnCopy(false);
 }
  useEffect(() => {
    window.addEventListener("resize", resizeHandler)
    return () => {
      window.removeEventListener("resize", resizeHandler);
    }
  })
  // console.log(Helper.encrypt("119c749645ec", "119c749645ecAKMAAMML.MINNAN4").toString());
  return (
    <div>
      <Modal
        open={showModel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: 9999 }}
      >
        <Box sx={{ ...style, width: width > 400 ? 400 : width - 40 }}>
          {
            unlockStatus?
            // Unlock Success
            <div className=' dark:bg-darkFooter flex flex-col rounded-[20px] p-[20px] py-[35px] pt-[15px] md:p-[20px]  shadow-[4px_4px_4px_0px_rgb(0,0,0,0.25),inset_1px_1px_1px_0px_rgb(0,0,0,0.25)]'>
                <div onClick={handleClose} className="flex flex-row justify-end btn">
                  <img src={cancel} />
                </div>
                <div className="text-center">
                  Thank You.
                </div>
                <div className="text-center my-4">
                 🎉You are Successfully unlock.🎉
                </div>
                <div className="text-center"> ありがとうございました。</div>
            </div>
            :
              // Unlock Screen
              <div
                className=' dark:bg-darkFooter dark:border-none flex flex-col rounded-[20px] p-[20px] py-[35px] pt-[15px] md:p-[20px]  shadow-[4px_4px_4px_0px_rgb(0,0,0,0.25),inset_1px_1px_1px_0px_rgb(0,0,0,0.25)]'>
                <div onClick={handleClose} className="flex flex-row justify-end btn">
                  <img src={cancel} />
                </div>
                {/* hero section */}
                <div className='flex flex-row mt-5'>
                  <img className="flex-1 w-[150px] h-[150px]" src={unlock} />
                  <div className="flex-1 flex flex-col text-sm ml-[12px] justify-between">
                    <span className="text-justify">{unlockParagraph.first}</span>
                    <span className="text-justify">{unlockParagraph.second}</span>
                    <span className="text-justify">{unlockParagraph.third}</span>
                  </div>
                </div>
                {/* request code */}
                <div className="my-[10px]"></div>
                {/* Error Occour On Android Version */}
                {/* <CopyComponent text={requestCode+"M4"} onFinish={onCopyFinish}>
                  <div className="flex flex-row px-[20px] py-[8px] items-center bg-[#DDF0FF] rounded-full cursor-pointer">
                    <span className="flex-1 overflow-clip text-[18px] text-navbar">{requestCode+"M4"}</span>
                    <span className="w-[1.5px] h-[30px] mx-[20px] bg-[#BABABA]"></span>
                    <div className="flex flex-row bg-navbar text-[18px] text-white px-[18px] py-[2px] rounded-full">
                      <div className="flex flex-row items-center justify-evenly">
                        {onCopy ? "Copied" : "Copy"}
                        {
                          onCopy ? <GiCheckMark className="ml-1 text-green-400" />
                          : <img src={copy} className="ml-1" />
                        }
                      </div>
                    </div>
                  </div>
                </CopyComponent> */}
                {/* Testing With react-copy-to-clipboard */}
                <CopyToClipboard text={requestCode+"M4"} onCopy={onCopyFinish}>
                  <div className="flex flex-row px-[20px] py-[8px] items-center bg-[#DDF0FF] rounded-full cursor-pointer">
                      <span className="flex-1 overflow-clip text-[18px] text-navbar">{requestCode+"M4"}</span>
                      <span className="w-[1.5px] h-[30px] mx-[20px] bg-[#BABABA]"></span>
                      <div className="flex flex-row bg-navbar text-[18px] text-white px-[18px] py-[2px] rounded-full">
                        <div className="flex flex-row items-center justify-evenly">
                          {onCopy ? "Copied" : "Copy"}
                          {
                            onCopy ? <GiCheckMark className="ml-1 text-green-400" />
                            : <img src={copy} className="ml-1" />
                          }
                        </div>
                      </div>
                    </div>
                </CopyToClipboard>
                <div className="my-[5px]"></div>
                {/* Err msg */}
                {
                  errMsg && <div className="text-red-500 text-sm">{errMsg}</div>
                }
                {/* Err msg */}
                <div className="my-[5px]"></div>
                {/* input */}
                <TextField
                  onChange={(event) => setUnlockCode(event.target.value)}
                  label="Enter Code"
                  variant="outlined"
                  sx={{
                    '& label': {
                      color: dark === 'dark' ? '#CBCBCB' :'#424242', // Change label color to white
                    },
                    '& fieldset': {
                      borderColor: '#B4B4B4 !important', // Change border color to white
                    },
                    '& input': {
                      color: dark === 'dark' ? '#CBCBCB' : '#000', // Change text color to white
                    },
                  }}
                />
                <div className="my-[10px]"></div>
                {/* Unlock Btn */}
                <div className="flex flex-row justify-center">
                    <div
                      onClick={unlockAction} style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
                      className="bg-navbar w-full text-center px-[15px] py-[3px] text-white text-[18px] mx:text-[20px] rounded-[5px] btn ">Unlock</div>
                  </div>
                  <div className="my-[10px]"></div>
                  <div className="flex flex-row items-start">
                    <div><img src={agree} /></div>
                    <div className="ml-2 text-[13px] text-black dark:text-[#D4D4D4]">I have read and agree to <span onClick={() => { navigate(Routes.PRIVACY); setShowModel(false); }} className="btn text-navbar">Terms and Privacy Policy</span></div>
                </div>
              </div>
          }
        </Box>
      </Modal>
    </div>
  );
}
export default memo(UnlockModal);


