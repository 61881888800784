import SizeBox from "@components/SizeBox"
import CategoryViewBtn from "@components/common/CategoryViewBtn"
import { Card } from "@material-tailwind/react"
import { memo } from "react"
 
const Exercise = ({cagetories,id}:any) => {
    return <Card onClick={cagetories[3].onclick} 
        className="hover:scale-105 transition duration-500 flex-1  md:h-[250px] rounded-[20px] text-[16px] md:text-[22px] btn p-6 dark:bg-darkFooter dark-shadow ">
        <div className="flex flex-row items-center mb-8 md:justify-center">
            <div className=""><img src={cagetories[3].Icon} /></div>
            <div className=" text-black dark:text-white font-semibold ml-[10px] md:ml-[20px]">{cagetories[3].label}</div>
        </div>
        <div className="text-[#424242] dark:text-[#D4D4D4] text-center">
            <div>Let's practice</div>
            <div>Lesson {id+22} to Lesson {id+26} </div>
        </div>
        <SizeBox vertical="h-10" />
        <CategoryViewBtn cagetories={cagetories} />
    </Card>
}
export default memo(Exercise);