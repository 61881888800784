
import { ChangeEvent, memo, useEffect, useRef } from "react";
interface ProgressSliderType {
    value: number | string;
    width: number;
    color: string;
    bgColor: string;
    thumbSize: number;
    thumbColor?: string;
    scrollHeight: number;
    name: string;

    //
    onSliderChange: (event: ChangeEvent<HTMLInputElement>) => void; 
}
const ProgressSliderComponent = ({ value, color, width, bgColor, thumbColor, thumbSize, scrollHeight, onSliderChange, name }: ProgressSliderType) => { //#B3B3B3
    const slide = useRef<HTMLInputElement>(null);

    // slider
    useEffect(() => {
        const min = parseInt(slide.current?.min || '0');
        const max = parseInt(slide.current?.max || '100');
        const svalue = parseInt(value.toString());
        const background = `linear-gradient(to right, ${color} 0%, ${color} ${ ((svalue - min) / (max - min) * 100) + ((svalue<50)? 1 : 0) }%, ${bgColor} ${ ((svalue - min) / (max - min) * 100)}%, ${bgColor} 100%)`;
        slide.current?.style.setProperty('background', background);
    });
    return <>
        <style>{`
            .${name} {
                border-radius: ${scrollHeight}px;
                height: ${scrollHeight}px;
                outline: none;
                -webkit-appearance: none;
                margin: 0;
                background: ${bgColor};
            }
            .${name}::-webkit-slider-thumb {
                width: ${thumbSize}px;
                -webkit-appearance: none;
                height: ${thumbSize}px;
                border-radius: 50%;
                background: ${thumbColor || color};
            }
        `}</style>
        {/* Slider */}
        <div className={`flex flex-row w-full justify-center items-center overflow-hidden`} style={{ width: width, height: thumbSize }}>
            <input
                ref={slide}
                className={`${name} btn w-full`}
                onChange={onSliderChange} type="range" max={100} min={0} value={value}
            />
        </div>
    </>
}
export default memo(ProgressSliderComponent);