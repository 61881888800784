import SizeBox from "@components/SizeBox";

export const TopSpace = ()=>{
    return <SizeBox vertical="h-[16px] md:h-[15px]"/>
};

export const WhiteSpace = ()=>{
    return <SizeBox vertical="h-[22px] md:h-[35px]"/>
};

export const BottomSpace = ()=>{
    return <SizeBox vertical="h-[100px] md:h-[100px]"/>
};