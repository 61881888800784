import Icon from "@consts/icon";
import { useAppSelector as selector } from '@store/hooks';
import { memo } from "react";
import { IconType } from "react-icons/lib";

const DesktopViewComponent = ({ navItems, currentRoute, useNavigate, Routes,setShowModel }: any) => {
    const navigate = useNavigate();
    const { routeHistory,premium,pro } = selector(({ service }) => service);

    interface navItemsPops {
        icon: IconType,
        label: string,  
        path: string,
        children: Array<string> 
    }
    const redirect = (path:string,label:string,active:boolean)=>{
        if(active){
            navigate(path);
        }else{
            const {home,test,setting} = routeHistory;
            if(label == "Home"){
                navigate(home.route,{state: home.state});
            }else if(label == "Test"){
                navigate(test.route,{state: test.state});
            }else if(label == "Setting"){
                navigate(setting.route,{state: setting.state});
            }else{
                navigate(path);
            }
        }
    }
    const renderItem = ({ key, item, active }: any) => {
        return (
            <div key={key}
                onClick={currentRoute === item.path ? () => { } : () => redirect(item.path,item.label,active)}
                className={`h-fit mr-[25px]`}
            >
                <div
                    className={`${active ? 'text-yellow font-[400px]' : 'text-[#fff]'} relative leading-7 mx-1 pt-0.5 pb-0 btn`} >
                    {item.label}
                </div>
                {
                    active && <div className="w-full border-yellow border-b-[3px]"></div>
                }
            </div>
        )
    }
    return (
        <div className="hidden md:flex w-screen fixed top-0 z-menu bg-navbar text-white h-[85px]">
            <div className="container mx-auto flex flex-row justify-between items-center">
                <div className="flex flex-row h-16">
                    <div className="flex flex-row items-center mr-4">
                        <img src={Icon.Logo} className="w-[60px] h-[59px]]" />
                    </div>
                    <div className="flex flex-col justify-center h-full text-white ">
                        <div className={`text-[22px] `}>Minna</div>
                        <div className="text-[16px]">Master N4</div>
                    </div>
                </div>
                <div className="flex flex-row text-[20px] md:text-[18px]">
                    {
                        navItems.map((item: navItemsPops, key: number) => {
                            const active = item.children.includes(currentRoute);
                            return renderItem({ key, item, active })
                        })
                    }
                </div>
                <div onClick={premium || pro?()=>{}:()=>setShowModel(true)}
                    style={{ boxShadow: "0px 4px 4px 0px #00000040" }} className={`flex flex-row items-center btn h-[25px] text-white border-2 px-2 py-1 rounded-[20px] shadow-md ${premium || pro? "bg-[#00E392] border-[#00E392]": "bg-yellow border-yellow"}`}>
                    <div className="mr-2 text-[18px]">Premium</div>
                    <div className="h-[27px] w-[27px]"><img src={Icon.Premium} alt="Premium Logo" /></div>
                </div>
            </div>
        </div>
    );
}
export default memo(DesktopViewComponent);