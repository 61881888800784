import SizeBox from "@components/SizeBox";
import UnlockModal from "@components/common/UnlockModel";
import FooterComponent from "@components/footer/FooterComponent";
import NavbarComponent from "@components/header/NavbarComponent";

import { memo } from "react";
import { useLocation } from "react-router-dom";
const DefaultLayout = ({ body, Routes,setUnlockPremium, useNavigate, Logger, navbarProps,showModel,setShowModel,lang }: any) => {
    const currentRoute = useLocation().pathname;
    return (
        <div>
            <div> <NavbarComponent {...navbarProps} Routes={Routes} showModel={showModel} setShowModel={setShowModel} useNavigate={useNavigate} Logger={Logger} currentRoute={currentRoute} /></div>
            <SizeBox vertical="h-[70px] md:h-[85px]" />
            <div className="relative">
                {body}
            </div>
            <FooterComponent Routes={Routes} useNavigate={useNavigate} Logger={Logger} currentRoute={currentRoute} />
            <UnlockModal setUnlockPremium={setUnlockPremium} showModel={showModel} setShowModel={setShowModel}/>
        </div>
    )
}
export default memo(DefaultLayout);