export const reibun= [
    {
        sentence: [
            {
                furi: "＾盆（ぼん）踊（おど）｜りに　｜＾参（さん）加（か）｜する　ために、｜＾毎（まい）日（にち）｜　｜＾練（れん）習（しゅう）｜して　います。",
                normal: "盆踊りに　参加する　ために、毎日　練習して　います。",
                en: "In order to participate in Bon Odori, I practice every day.",
                mm: "Bon Odori မှာ ပါဝင်ဖို့အတွက် နေ့တိုင်း လေ့ကျင့်နေပါတယ်။",
            },
            {
                furi: "そうですか。｜＾楽（たの）｜しみですんね。",
                normal: "そうですか。楽しみですんね。",
                en: "Is that so. I'm looking forward to it.",
                mm: "ဟုတ်လား။ အဲဒါကို စောင့်မျှော်နေပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson42/bunpou/4N42RB1.mp3")
    },
    {
        sentence: [
            {
                furi: "なぜ　｜＾一（ひと）人（り）｜で　｜＾山（やま）｜に　｜＾登（のぼ）｜るんですか。",
                normal: "なぜ　一人で　山に　登るんですか。",
                en: "Why are you climbing the mountain alone?",
                mm: "ဘာလို့ တောင်ပေါ်ကို တစ်ယောက်တည်း တက်နေတာလဲ။",
            },
            {
                furi: "＾一（ひと）人（り）｜に　なって　｜＾考（かんが）｜える　ために、｜＾山（やま）｜に　｜＾行（い）｜くんです。",
                normal: "一人に　なって　考える　ために、山に　行くんです。",
                en: "I go to the mountains to be alone and think.",
                mm: "တစ်ယောက်တည်း ‌နေပြီးတွေးဖို့ တောင်ကို သွားလိုက်တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson42/bunpou/4N42RB2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾健（けん）康（こう）｜の　ために、｜＾何（なに）｜か　して　いますか。",
                normal: "健康の　ために、何か　して　いますか。",
                en: "What are you doing for your health?",
                mm: "မင်းကျန်းမာရေးအတွက် ဘာလုပ်နေလဲ။",
            },
            {
                furi: "いいえ。でも、｜＾来（らい）週（しゅう）｜から　｜＾毎（まい）朝（あさ）｜　｜＾走（はし）｜ろうと　｜＾思（おも）｜って　います。",
                normal: "いいえ。でも、来週から　毎朝　走ろうと　思って　います。",
                en: "no. But I'm thinking of running every morning from next week.",
                mm: "မလုပ်ဘူး။ ဒါပေမယ့် နောက်အပတ်ကစပြီး မနက်တိုင်း ပြေးဖို့ တွေးနေတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson42/bunpou/4N42RB3.mp3")
    },
    {
        sentence: [
            {
                furi: "きれいな　｜＾曲（きょく）｜ですね。",
                normal: "きれいな　曲ですね。",
                en: "It's a beautiful song.",
                mm: "လှပတဲ့ သံစဉ်တစ်ပုဒ်ပဲ။",
            },
            {
                furi: "「エリーゼの　ために」ですよ。ベートーベンが　ある　｜＾女（おんな）｜の　｜＾人（ひと）｜の　ために、｜＾作（つ）｜った　｜＾曲（きょく）｜です。",
                normal: "「エリーゼの　ために」ですよ。ベートーベンが　ある　女の　人の　ために、作った　曲です。",
                en: "It's 'For Elise'.This is a song that Beethoven wrote for his girlfriend.",
                mm: '"အဲလစ်အတွက်" ပါ။ ဒီသီချင်းကတော့ Beethoven က မိန်းမတစ်ယောက်အတွက် ဖန်တီးထားတဲ့ သီချင်းပါ။',
            },
        ],
        audioUrl: require("@assets/audio/lesson42/bunpou/4N42RB4.mp3")
    },
    {
        sentence: [
            {
                furi: "これは　｜＾何（なん）｜に　｜＾使（つか）｜うんですか。",
                normal: "これは　何に　使うんですか。",
                en: "What are you going to use this for?",
                mm: "ဒါဘာအတွက်သုံးမှာလဲ?",
            },
            {
                furi: "ワインを　｜＾開（あ）｜けるのに　｜＾使（つか）｜います。",
                normal: "ワインを　開けるのに　使います。",
                en: "I use it to open wine.",
                mm: "ဝိုင်ဖွင့်ဖို့ သုံးတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson42/bunpou/4N42RB5.mp3")
    },
    {
        sentence: [
            {
                furi: "２，３｜＾日（にち）｜の　｜＾出（しゅっ）張（ちょう）｜に　いい　かばんが　ありますか。",
                normal: "２，３日の　出張に　いい　かばんが　ありますか。",
                en: "Do you have a good bag for a business trip for a few days?",
                mm: "ရက်အနည်းငယ်ကြာ အလုပ်အတွက် ခရီးထွက်ဖို့ သင့်မှာ အိတ်ကောင်းကောင်း ရှိပါသလား။",
            },
            {
                furi: "こちらは　いかがですか。パソコンも　入って、｜＾便（べん）利（り）｜ですよ。",
                normal: "こちらは　いかがですか。パソコンも　入って、便利ですよ。",
                en: "How about this? A computer is also included, which is convenient.",
                mm: "ဒါကို ဘယ်လိုလဲ။ ကွန်ပြူတာတစ်လုံးလည်း ထည့်လို့ရတာကြောင့် အဆင်ပြေပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson42/bunpou/4N42RB6.mp3")
    },
    {
        sentence: [
            {
                furi: "この　｜＾橋（はし）｜を　｜＾造（つく）｜るのに　｜＾何（なん）年（ねん）｜　かかりましたか。",
                normal: "この　橋を　造るのに　何年　かかりましたか。",
                en: "How many years did it take to build this bridge?",
                mm: "ဒီတံတားဆောက်ဖို့ နှစ်ဘယ်လောက်ကြာခဲ့လဲ။",
            },
            {
                furi: "１２｜＾年（ねん）｜　かかりました。",
                normal: "１２年　かかりました。",
                en: "It took 12 years.",
                mm: "၁၂ နှစ်ကြာတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson42/bunpou/4N42RB7.mp3")
    },
]