export const lesson37 = [
  {
      "furi": "＾褒（ほ）｜めます",
      "normal": "褒めます",
      "en": "praise",
      "remark_en": "",
      "mm": "ချီးမွမ်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO1.mp3")
  },
  {
      "furi": "しかります",
      "normal": "しかります",
      "en": "scold",
      "remark_en": "",
      "mm": "ဆူပူကြိမ်းမောင်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO2.mp3")
  },
  {
      "furi": "＾誘（さそ）｜います",
      "normal": "誘います",
      "en": "invite, ask someone to join",
      "remark_en": "",
      "mm": "အဖော်စပ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO3.mp3")
  },
  {
      "furi": "＾招（しょう）待（たい）｜します",
      "normal": "招待します",
      "en": "invite",
      "remark_en": "",
      "mm": "ဖိတ်ကြားသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO4.mp3")
  },
  {
      "furi": "＾頼（たの）｜みます",
      "normal": "頼みます",
      "en": "ask, request",
      "remark_en": "",
      "mm": "တောင်းဆိုသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO5.mp3")
  },
  {
      "furi": "＾注（ちゅう）意（い）｜します",
      "normal": "注意します",
      "en": "warn, advise",
      "remark_en": "",
      "mm": "သတိပေးသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO6.mp3")
  },
  {
      "furi": "とります",
      "normal": "とります",
      "en": "rob, steal",
      "remark_en": "",
      "mm": "ခိုးယူသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO7.mp3")
  },
  {
      "furi": "＾踏（ふ）｜みます",
      "normal": "踏みます",
      "en": "step on(someone's foot)",
      "remark_en": "",
      "mm": "နင်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO8.mp3")
  },
  {
      "furi": "＾壊（こわ）｜します",
      "normal": "壊します",
      "en": "break, destroy",
      "remark_en": "",
      "mm": "ဖျက်ဆီးသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO9.mp3")
  },
  {
      "furi": "＾汚（よご）｜します",
      "normal": "汚します",
      "en": "make ~ dirty",
      "remark_en": "",
      "mm": "ညစ်ပေသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO10.mp3")
  },
  {
      "furi": "＾行（おこな）｜います",
      "normal": "行います",
      "en": "hold, carry out, practice",
      "remark_en": "",
      "mm": "ကျင်းပသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO11.mp3")
  },
  {
      "furi": "＾輸（ゆ）出（しゅつ）｜します",
      "normal": "輸出します",
      "en": "export",
      "remark_en": "",
      "mm": "တင်ပို့သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO12.mp3")
  },
  {
      "furi": "＾輸（ゆ）入（りゅう）｜します",
      "normal": "輸入します",
      "en": "import",
      "remark_en": "",
      "mm": "တင်သွင်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO13.mp3")
  },
  {
      "furi": "＾翻（ほん）訳（やく）｜します",
      "normal": "翻訳します",
      "en": "translate",
      "remark_en": "",
      "mm": "ဘာသာပြန်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO14.mp3")
  },
  {
      "furi": "＾発（はつ）明（めい）｜します",
      "normal": "発明します",
      "en": "invent",
      "remark_en": "",
      "mm": "တီထွင်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO15.mp3")
  },
  {
      "furi": "＾発（はっ）見（けん）｜します",
      "normal": "発見します",
      "en": "discover",
      "remark_en": "",
      "mm": "ရှာဖွေတွေ့ရှိသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO16.mp3")
  },
  {
      "furi": "＾米（こめ）",
      "normal": "米",
      "en": "rice",
      "remark_en": "",
      "mm": "ဆန်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO17.mp3")
  },
  {
      "furi": "＾麦（むぎ）",
      "normal": "麦",
      "en": "barley, wheat",
      "remark_en": "",
      "mm": "ဂျုံ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO18.mp3")
  },
  {
      "furi": "＾石（せき）油（ゆ）",
      "normal": "石油",
      "en": "oil",
      "remark_en": "",
      "mm": "လောင်စာဆီ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO19.mp3")
  },
  {
      "furi": "＾原（げん）料（りょう）",
      "normal": "原料",
      "en": "raw material",
      "remark_en": "",
      "mm": "ကုန်ကြမ်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO20.mp3")
  },
  {
      "furi": "インスタントラーメン",
      "normal": "インスタントラーメン",
      "en": "Instant noodle",
      "remark_en": "",
      "mm": "အသင့်စားခေါက်ဆွဲ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO21.mp3")
  },
  {
      "furi": "デート",
      "normal": "デート",
      "en": "date",
      "remark_en": "",
      "mm": "ချိန်းတွေ့သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO22.mp3")
  },
  {
      "furi": "＾泥（どろ）棒（ぼう）",
      "normal": "泥棒",
      "en": "thief, robber",
      "remark_en": "",
      "mm": "သူခိုး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO23.mp3")
  },
  {
      "furi": "＾警（けい）官（かん）",
      "normal": "警官",
      "en": "policeman",
      "remark_en": "",
      "mm": "ရဲအရာရှိ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO24.mp3")
  },
  {
      "furi": "＾世（せ）界（かい）中（じゅう）",
      "normal": "世界中",
      "en": "all over the world",
      "remark_en": "",
      "mm": "ကမ္ဘာတစ်ဝှမ်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO25.mp3")
  },
  {
      "furi": "～｜＾中（じゅう）",
      "normal": "～中",
      "en": "all over ~",
      "remark_en": "",
      "mm": "～တစ်ဝှမ်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO26.mp3")
  },
  {
      "furi": "ー｜＾世（せい）｜＾紀（き）",
      "normal": "ー世紀",
      "en": "~century",
      "remark_en": "",
      "mm": "-ရာစုနှစ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO27.mp3")
  },
  {
      "furi": "＾何（なに）語（ご）",
      "normal": "何語",
      "en": "what language",
      "remark_en": "",
      "mm": "ဘယ် ဘာသာစကား?",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO28.mp3")
  },
  {
      "furi": "だれか",
      "normal": "だれか",
      "en": "someone",
      "remark_en": "",
      "mm": "တစ်ယောက်ယောက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO29.mp3")
  },
  {
      "furi": "よかったですね。",
      "normal": "よかったですね。",
      "en": "It was good",
      "remark_en": "",
      "mm": "ကောင်းတာပေါ့နော်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO30.mp3")
  },
  {
      "furi": "オリンピック",
      "normal": "オリンピック",
      "en": "Olympic",
      "remark_en": "",
      "mm": "အိုလံပစ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO31.mp3")
  },
  {
      "furi": "ワールドカップ",
      "normal": "ワールドカップ",
      "en": "World Cup",
      "remark_en": "",
      "mm": "ကမ္ဘာ့ဖလား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO32.mp3")
  },
  {
      "furi": "＾東（とう）大（だい）寺（じ）",
      "normal": "東大寺",
      "en": "Todaiji Temple",
      "remark_en": "",
      "mm": "တိုးဒိုင်းဂျိ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO33.mp3")
  },
  {
      "furi": "＾大（だい）仏（ぶつ）",
      "normal": "大仏",
      "en": "Big Buddha",
      "remark_en": "",
      "mm": "ဘုရားကြီး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO34.mp3")
  },
  {
      "furi": "＾江（え）戸（ど）時（じ）代（だい）",
      "normal": "江戸時代",
      "en": "Edo Period",
      "remark_en": "",
      "mm": "အဲဒိုခေတ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO35.mp3")
  },
  {
      "furi": "ポルトガル",
      "normal": "ポルトガル",
      "en": "Portugal",
      "remark_en": "",
      "mm": "ပေါ်တူဂီ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO36.mp3")
  },
  {
      "furi": "サウジアラビア",
      "normal": "サウジアラビア",
      "en": "Saudi Arabia",
      "remark_en": "",
      "mm": "ဆောဒီအာရေးဗီးယား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO37.mp3")
  },
  {
      "furi": "ロシア",
      "normal": "ロシア",
      "en": "Russia",
      "remark_en": "",
      "mm": "ရုရှား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO38.mp3")
  },
  {
      "furi": "＾皆（みんな）様（さま）",
      "normal": "皆様",
      "en": "ladies and gentlemen",
      "remark_en": "",
      "mm": "လူကြီးမင်းတို့",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO39.mp3")
  },
  {
      "furi": "＾焼（や）｜けます",
      "normal": "焼けます",
      "en": "burn",
      "remark_en": "",
      "mm": "လောင်ကျွမ်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO40.mp3")
  },
  {
      "furi": "その｜＾後（ご）",
      "normal": "その後",
      "en": "after that",
      "remark_en": "",
      "mm": "အဲဒီနောက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO41.mp3")
  },
  {
      "furi": "＾世（せ）界（かい）遺（い）産（さん）",
      "normal": "世界遺産",
      "en": "world Heritage",
      "remark_en": "",
      "mm": "ကမ္ဘာ့အမွေအနှစ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO42.mp3")
  },
  {
      "furi": "～の｜＾一（ひと）｜つ",
      "normal": "～の一つ",
      "en": "One of～",
      "remark_en": "",
      "mm": "～မှတစ်ခု",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO43.mp3")
  },
  {
      "furi": "＾金（きん）色（いろ）",
      "normal": "金色",
      "en": "Golden",
      "remark_en": "",
      "mm": "ရွှေရောင်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO44.mp3")
  },
  {
      "furi": "＾本（ほん）物（もの）",
      "normal": "本物",
      "en": "Genuine",
      "remark_en": "",
      "mm": "အစစ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO45.mp3")
  },
  {
      "furi": "＾金（きん）",
      "normal": "金",
      "en": "gold",
      "remark_en": "",
      "mm": "ရွှေ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO46.mp3")
  },
  {
      "furi": "ーキロ",
      "normal": "ーキロ",
      "en": "-kilo",
      "remark_en": "",
      "mm": "-ကီလို",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO47.mp3")
  },
  {
      "furi": "＾美（うつく）｜しい",
      "normal": "美しい",
      "en": "beautiful",
      "remark_en": "",
      "mm": "လှပသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson37/vocab/4N37VO48.mp3")
  },
  {
      "furi": "＾豪（ごう）華（か）｜な",
      "normal": "豪華な",
      "en": "Gorgeous",
      "remark_en": "",
      "mm": "ခမ်းနားထည်ဝါသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾彫（ちょう）刻（こく）",
      "normal": "彫刻",
      "en": "engraving, carving",
      "remark_en": "",
      "mm": "ပန်းပု",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾言（い）｜い｜＾伝（つた）｜え",
      "normal": "言い伝え",
      "en": "Legend",
      "remark_en": "",
      "mm": "တစ်ဆင့်စကား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾眠（ねむ）｜ります",
      "normal": "眠ります",
      "en": "sleep",
      "remark_en": "",
      "mm": "အိပ်ပျော်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾彫（ほ）｜ります",
      "normal": "彫ります",
      "en": "engrave, carve",
      "remark_en": "",
      "mm": "ထွင်းထုသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾仲（なか）間（ま）",
      "normal": "仲間",
      "en": "colleague, friend",
      "remark_en": "",
      "mm": "အပေါင်းအပါ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "しかし",
      "normal": "しかし",
      "en": "However",
      "remark_en": "",
      "mm": "သို့သော်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "そのあと",
      "normal": "そのあと",
      "en": "after that",
      "remark_en": "",
      "mm": "အဲဒီနောက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾一（いっ）生（しょう）懸（けん）命（めい）",
      "normal": "一生懸命",
      "en": "with all one's effort",
      "remark_en": "",
      "mm": "အားသွန်ခွန်စိုက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "ねずみ",
      "normal": "ねずみ",
      "en": "mouse",
      "remark_en": "",
      "mm": "ကြွက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾一（いっ）匹（ぴき）｜もいません。",
      "normal": "一匹もいません。",
      "en": "There is not a single(mouse)",
      "remark_en": "",
      "mm": "တစ်ကောင်မှမရှိဘူး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾東（とう）照（しょう）宮（ぐう）",
      "normal": "東照宮",
      "en": "Toshogu",
      "remark_en": "",
      "mm": "တိုးရှောဂူး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾眠（ねむ）｜り｜＾猫（ねこ）",
      "normal": "眠り猫",
      "en": "The Sleeping Cat",
      "remark_en": "",
      "mm": "အိပ်ပျော်နေသောကြောင်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾左（ひだり）甚（じん）五（ご）郎（ろう）",
      "normal": "左甚五郎",
      "en": "famous japanese sculptor",
      "remark_en": "",
      "mm": "ဟိဒရိဂျင်းဂေါ့ရော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  }
]

/*
    {
        furi: "",
        normal: "",
        en: "",
        remark_en: "",
        mm: "",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson29/vocab/4N29VO1.mp3")
    },


*/
const test = [
  {
    'goi': '褒（ほ）」めます',
    'unicode': 'ချီးမွမ်းသည်',
    'zawgyi': 'ခ်ီးမြမ္းသည္',
    'english': 'praise',
    'long': false
  },
  {
    'goi': 'しかります',
    'unicode': 'ဆူပူကြိမ်းမောင်းသည်',
    'zawgyi': 'ဆူပူႀကိမ္းေမာင္းသည္',
    'english': 'scold',
    'long': false
  },
  {
    'goi': '誘（さそ）」います',
    'unicode': 'အဖော်စပ်သည်',
    'zawgyi': 'အေဖာ္စပ္သည္',
    'english': 'invite, ask someone to join',
    'long': false
  },
  {
    'goi': '招（しょう）」待（たい）」します',
    'unicode': 'ဖိတ်ကြားသည်',
    'zawgyi': 'ဖိတ္ၾကားသည္',
    'english': 'invite',
    'long': false
  },
  {
    'goi': '頼（たの）」みます',
    'unicode': 'တောင်းဆိုသည်',
    'zawgyi': 'ေတာင္းဆိုသည္',
    'english': 'ask, request',
    'long': false
  },
  {
    'goi': '注（ちゅう）」意（い）」します',
    'unicode': 'သတိပေးသည်',
    'zawgyi': 'သတိေပးသည္',
    'english': 'warn, advise',
    'long': false
  },
  {
    'goi': 'とります',
    'unicode': 'ခိုးယူသည်',
    'zawgyi': 'ခိုးယူသည္',
    'english': 'rob, steal',
    'long': false
  },
  {
    'goi': '踏（ふ）」みます',
    'unicode': 'နင်းသည်',
    'zawgyi': 'နင္းသည္',
    'english': 'step on(someone\'s foot)',
    'long': false
  },
  {
    'goi': '壊（こわ）」します',
    'unicode': 'ဖျက်ဆီးသည်',
    'zawgyi': 'ဖ်က္ဆီးသည္',
    'english': 'break, destroy',
    'long': false
  },
  {
    'goi': '汚（よご）」します',
    'unicode': 'ညစ်ပေသည်',
    'zawgyi': 'ညစ္ေပသည္',
    'english': 'make ~ dirty',
    'long': false
  },
  {
    'goi': '行（おこな）」います',
    'unicode': 'ကျင်းပသည်',
    'zawgyi': 'က်င္းပသည္',
    'english': 'hold, carry out, practice',
    'long': false
  },
  {
    'goi': '輸（ゆ）」出（しゅつ）」します',
    'unicode': 'တင်ပို့သည်',
    'zawgyi': 'တင္ပို႔သည္',
    'english': 'export',
    'long': false
  },
  {
    'goi': '輸（ゆ）」入（りゅう）」します',
    'unicode': 'တင်သွင်းသည်',
    'zawgyi': 'တင္သြင္းသည္',
    'english': 'import',
    'long': false
  },
  {
    'goi': '翻（ほん）」訳（やく）」します',
    'unicode': 'ဘာသာပြန်သည်',
    'zawgyi': 'ဘာသာျပန္သည္',
    'english': 'translate',
    'long': false
  },
  {
    'goi': '発（はつ）」明（めい）」します',
    'unicode': 'တီထွင်သည်',
    'zawgyi': 'တီထြင္သည္',
    'english': 'invent',
    'long': false
  },
  {
    'goi': '発（はっ）」見（けん）」します',
    'unicode': 'ရှာဖွေတွေ့ရှိသည်',
    'zawgyi': 'ရွာေဖြေတြ႕ရွိသည္',
    'english': 'discover',
    'long': false
  },
  {
    'goi': '米（こめ）',
    'unicode': 'ဆန်',
    'zawgyi': 'ဆန္',
    'english': 'rice',
    'long': false
  },
  {
    'goi': '麦（むぎ）',
    'unicode': 'ဂျုံ',
    'zawgyi': 'ဂ်ဳံ',
    'english': 'barley, wheat',
    'long': false
  },
  {
    'goi': '石（せき）」油（ゆ）',
    'unicode': 'လောင်စာဆီ',
    'zawgyi': 'ေလာင္စာဆီ',
    'english': 'oil',
    'long': false
  },
  {
    'goi': '原（げん）」料（りょう）',
    'unicode': 'ကုန်ကြမ်း',
    'zawgyi': 'ကုန္ၾကမ္း',
    'english': 'raw material',
    'long': false
  },
  {
    'goi': 'インスタントラーメン',
    'unicode': 'အသင့်စားခေါက်ဆွဲ',
    'zawgyi': 'အသင့္စားေခါက္ဆြဲ',
    'english': 'Instant noodle',
    'long': false
  },
  {
    'goi': 'デート',
    'unicode': 'ချိန်းတွေ့သည်',
    'zawgyi': 'ခ်ိန္းေတြ႕သည္',
    'english': 'date',
    'long': false
  },
  {
    'goi': '泥（どろ）」棒（ぼう）',
    'unicode': 'သူခိုး',
    'zawgyi': 'သူခိုး',
    'english': 'thief, robber',
    'long': false
  },
  {
    'goi': '警（けい）」官（かん）',
    'unicode': 'ရဲအရာရှိ',
    'zawgyi': 'ရဲအရာရွိ',
    'english': 'policeman',
    'long': false
  },
  {
    'goi': '世（せ）」界（かい）」中（じゅう）',
    'unicode': 'ကမ္ဘာတစ်ဝှမ်း',
    'zawgyi': 'ကမၻာတစ္ဝွမ္း',
    'english': 'all over the world',
    'long': false
  },
  {
    'goi': '～」中（じゅう）',
    'unicode': '～တစ်ဝှမ်း',
    'zawgyi': '～တစ္ဝွမ္း',
    'english': 'all over ~',
    'long': false
  },
  {
    'goi': 'ー」世（せい）」紀（き）',
    'unicode': '-ရာစုနှစ်',
    'zawgyi': '-ရာစုႏွစ္',
    'english': '~century',
    'long': false
  },
  {
    'goi': '何（なに）」語（ご）',
    'unicode': 'ဘယ် ဘာသာစကား?',
    'zawgyi': 'ဘယ္ ဘာသာစကား?',
    'english': 'what language',
    'long': false
  },
  {
    'goi': 'だれか',
    'unicode': 'တစ်ယောက်ယောက်',
    'zawgyi': 'တစ္ေယာက္ေယာက္',
    'english': 'someone',
    'long': false
  },
  {
    'goi': 'よかったですね。',
    'unicode': 'ကောင်းတာပေါ့နော်',
    'zawgyi': 'ေကာင္းတာေပါ့ေနာ္',
    'english': 'It was good',
    'long': false
  },
  {
    'goi': 'オリンピック',
    'unicode': 'အိုလံပစ်',
    'zawgyi': 'အိုလံပစ္',
    'english': 'Olympic',
    'long': false
  },
  {
    'goi': 'ワールドカップ',
    'unicode': 'ကမ္ဘာ့ဖလား',
    'zawgyi': 'ကမၻာ့ဖလား',
    'english': 'World Cup',
    'long': false
  },
  {
    'goi': '東（とう）」大（だい）」寺（じ）',
    'unicode': 'တိုးဒိုင်းဂျိ',
    'zawgyi': 'တိုးဒိုင္းဂ်ိ',
    'english': 'Todaiji Temple',
    'long': false
  },
  {
    'goi': '大（だい）」仏（ぶつ）',
    'unicode': 'ဘုရားကြီး',
    'zawgyi': 'ဘုရားႀကီး',
    'english': 'Big Buddha',
    'long': false
  },
  {
    'goi': '江（え）」戸（ど）」時（じ）」代（だい）',
    'unicode': 'အဲဒိုခေတ်',
    'zawgyi': 'အဲဒိုေခတ္',
    'english': 'Edo Period',
    'long': false
  },
  {
    'goi': 'ポルトガル',
    'unicode': 'ပေါ်တူဂီ',
    'zawgyi': 'ေပၚတူဂီ',
    'english': 'Portugal',
    'long': false
  },
  {
    'goi': 'サウジアラビア',
    'unicode': 'ဆောဒီအာရေးဗီးယား',
    'zawgyi': 'ေဆာဒီအာေရးဗီးယား',
    'english': 'Saudi Arabia',
    'long': false
  },
  {
    'goi': 'ロシア',
    'unicode': 'ရုရှား',
    'zawgyi': '႐ုရွား',
    'english': 'Russia',
    'long': false
  },
  {
    'goi': '皆（みんな）」様（さま）',
    'unicode': 'လူကြီးမင်းတို့',
    'zawgyi': 'လူႀကီးမင္းတို႔',
    'english': 'ladies and gentlemen',
    'long': false
  },
  {
    'goi': '焼（や）」けます',
    'unicode': 'လောင်ကျွမ်းသည်',
    'zawgyi': 'ေလာင္ကၽြမ္းသည္',
    'english': 'burn',
    'long': false
  },
  {
    'goi': 'その」後（ご）',
    'unicode': 'အဲဒီနောက်',
    'zawgyi': 'အဲဒီေနာက္',
    'english': 'after that',
    'long': false
  },
  {
    'goi': '世（せ）」界（かい）」遺（い）」産（さん）',
    'unicode': 'ကမ္ဘာ့အမွေအနှစ်',
    'zawgyi': 'ကမၻာ့အေမြအႏွစ္',
    'english': 'world Heritage',
    'long': false
  },
  {
    'goi': '～の」一（ひと）」つ',
    'unicode': '～မှတစ်ခု',
    'zawgyi': '～မွတစ္ခု',
    'english': 'One of～',
    'long': false
  },
  {
    'goi': '金（きん）」色（いろ）',
    'unicode': 'ရွှေရောင်',
    'zawgyi': 'ေရႊေရာင္',
    'english': 'Golden',
    'long': false
  },
  {
    'goi': '本（ほん）」物（もの）',
    'unicode': 'အစစ်',
    'zawgyi': 'အစစ္',
    'english': 'Genuine',
    'long': false
  },
  {
    'goi': '金（きん）',
    'unicode': 'ရွှေ',
    'zawgyi': 'ေရႊ',
    'english': 'gold',
    'long': false
  },
  {
    'goi': 'ーキロ',
    'unicode': '-ကီလို',
    'zawgyi': '-ကီလို',
    'english': '-kilo',
    'long': false
  },
  {
    'goi': '美（うつく）」しい',
    'unicode': 'လှပသော',
    'zawgyi': 'လွပေသာ',
    'english': 'beautiful',
    'long': false
  },
  {
    'goi': '豪（ごう）」華（か）」な',
    'unicode': 'ခမ်းနားထည်ဝါသော',
    'zawgyi': 'ခမ္းနားထည္ဝါေသာ',
    'english': 'Gorgeous',
    'long': false
  },
  {
    'goi': '彫（ちょう）」刻（こく）',
    'unicode': 'ပန်းပု',
    'zawgyi': 'ပန္းပု',
    'english': 'engraving, carving',
    'long': false
  },
  {
    'goi': '言（い）」い」伝（つた）」え',
    'unicode': 'တစ်ဆင့်စကား',
    'zawgyi': 'တစ္ဆင့္စကား',
    'english': 'Legend',
    'long': false
  },
  {
    'goi': '眠（ねむ）」ります',
    'unicode': 'အိပ်ပျော်သည်',
    'zawgyi': 'အိပ္ေပ်ာ္သည္',
    'english': 'sleep',
    'long': false
  },
  {
    'goi': '彫（ほ）」ります',
    'unicode': 'ထွင်းထုသည်',
    'zawgyi': 'ထြင္းထုသည္',
    'english': 'engrave, carve',
    'long': false
  },
  {
    'goi': '仲（なか）」間（ま）',
    'unicode': 'အပေါင်းအပါ',
    'zawgyi': 'အေပါင္းအပါ',
    'english': 'colleague, friend',
    'long': false
  },
  {
    'goi': 'しかし',
    'unicode': 'သို့သော်',
    'zawgyi': 'သို႔ေသာ္',
    'english': 'However',
    'long': false
  },
  {
    'goi': 'そのあと',
    'unicode': 'အဲဒီနောက်',
    'zawgyi': 'အဲဒီေနာက္',
    'english': 'after that',
    'long': false
  },
  {
    'goi': '一（いっ）」生（しょう）」懸（けん）」命（めい）',
    'unicode': 'အားသွန်ခွန်စိုက်',
    'zawgyi': 'အားသြန္ခြန္စိုက္',
    'english': 'with all one\'s effort',
    'long': false
  },
  {
    'goi': 'ねずみ',
    'unicode': 'ကြွက်',
    'zawgyi': 'ႂကြက္',
    'english': 'mouse',
    'long': false
  },
  {
    'goi': '一（いっ）」匹（ぴき）」もいません。',
    'unicode': 'တစ်ကောင်မှမရှိဘူး',
    'zawgyi': 'တစ္ေကာင္မွမရွိဘူး',
    'english': 'There is not a single(mouse)',
    'long': true
  },
  {
    'goi': '東（とう）」照（しょう）」宮（ぐう）',
    'unicode': 'တိုးရှောဂူး',
    'zawgyi': 'တိုးေရွာဂူး',
    'english': 'Toshogu',
    'long': false
  },
  {
    'goi': '眠（ねむ）」り」猫（ねこ）',
    'unicode': 'အိပ်ပျော်နေသောကြောင်',
    'zawgyi': 'အိပ္ေပ်ာ္ေနေသာေၾကာင္',
    'english': 'The Sleeping Cat',
    'long': false
  },
  {
    'goi': '左（ひだり）」甚（じん）」五（ご）」郎（ろう）',
    'unicode': 'ဟိဒရိဂျင်းဂေါ့ရော',
    'zawgyi': 'ဟိဒရိဂ်င္းေဂါ့ေရာ',
    'english': 'famous japanese sculptor',
    'long': false
  }
]