import Icon from "@consts/icon";

export const lesson33 = {
    title: {
        furi: 'これは　どういう　｜＾意（い）味（み）｜ですか',
        normal: 'これは　どういう　意味ですか',
        en: 'What does this mean?',
        mm: 'ဒီဟာက ဘယ်လိုအဓိပ္ပါယ်လဲ?',
    },
    conversation: [
        {
            person: {
                furi: 'ワット',
                normal: 'ワット',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'すみません。わたしの　｜＾車（くるま）｜に　こんな　｜＾紙（かみ）｜が　はって あったんですが、　この｜＾漢（かん）字（じ）｜は　｜＾何（なん）｜と　｜＾読（よ）｜むんですか。',
                    normal: 'すみません。わたしの　車に　こんな　紙が　はって あったんですが、　この漢字は　何と　読むんですか。',
                    en: 'excuse me. There was a piece of paper stuck to my car , how do you read this kanji?',
                    mm: 'တစ်ဆိတ်လောက်ပါ၊ ကျနော့ရဲ့ကားမှာ ဒီစာရွက်ကပ်ထားလို့ပါ။ ဒီခန်းကြီးက ဘယ်လို ဖတ်ရတာလဲ?',
                },
            ],
        },
        {
            person: {
                furi: '＾大（だい）学（がく）職（しょく）員（いん）',
                normal: '大学職員',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: '「ちゅうしゃいはん」です。',
                    normal: '「ちゅうしゃいはん」です。',
                    en: 'It is "Chuushaihan".',
                    mm: '"ယာဉ်ရပ်နားခွင့်ချိုးဖောက်ခြင်း" ဖြစ်ပါတယ်။',
                },
            ],
        },
        {
            person: {
                furi: 'ワット',
                normal: 'ワット',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'ちゅうしゃいはん。。。、どういう　｜＾意（い）味（み）｜ですか。',
                    normal: 'ちゅうしゃいはん。。。、どういう　意味ですか。',
                    en: '"Chuushaihan" ,what do you mean.',
                    mm: '"ယာဉ်ရပ်နားခွင့်ချိုးဖောက်ခြင်း" . . . ဘယ်လို အဓိပ္ပါယ်ပါလဲ',
                },
            ],
        },
        {
            person: {
                furi: '＾大（だい）学（がく）職（しょく）員（いん）',
                normal: '大学職員',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: '＾止（と）｜めては　いけない　｜＾場（ば）所（しょ）｜に　｜＾車（くるま）｜を　｜＾止（と）｜めたと　いう　｜＾意（い）味（み）｜です。どこに　｜＾止（と）｜めたんですか。',
                    normal: '止めては　いけない　場所に　車を　止めたと　いう　意味です。どこに　止めたんですか。',
                    en: 'It means that the car was parked in a place where it should not be parked. where did you stop?',
                    mm: 'ကားရပ်လို့မရတဲ့ နေရာမှာ ရပ်ခဲ့တယ်ဆိုတဲ့ အဓိပ္ပါယ်ဖြစ်ပါတယ်။ ဘယ်မှာရပ်ခဲ့တာလည်း။',
                },
            ],
        },
        {
            person: {
                furi: 'ワット',
                normal: 'ワット',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: '＾駅（えき）｜の　｜＾前（まえ）｜です。｜＾雑（ざっ）誌（し）｜を　｜＾買（か）｜いに　｜＾行（い）｜って　｜＾１０（じゅっ）分（ぷん）｜だけ。。。',
                    normal: '駅の　前です。雑誌を　買いに　行って　１０分だけ。。。',
                    en: "It's in front of the station. I went to buy a magazine and he only had 10 minutes. . .",
                    mm: 'ဘူတာရဲ့အရှေ့မှာပါ။ မဂ္ဂဇင်းကို ဝယ်ဖို့ သွားနေတဲ့ ၁၀မိနစ်လောက်ထဲပါ . . ',
                },
            ],
        },
        {
            person: {
                furi: '＾大（だい）学（がく）職（しょく）員（いん）',
                normal: '大学職員',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: '＾駅（えき）｜の　｜＾前（まえ）｜だったら　｜＾１０（じゅっ）分（ぷん）｜でも　だめですよ。',
                    normal: '駅の　前だったら　１０分でも　だめですよ。',
                    en: "If it's in front of the station, you can't even 10 minutes.",
                    mm: 'ဘူတာရဲ့ အရှေ့ဖြစ်တာကြောင့် ၁၀မိနစ်ထဲဆိုပေမယ့်လည်း မရပါဘူး',
                },
            ],
        },
        {
            person: {
                furi: 'ワット',
                normal: 'ワット',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'そうですか　｜＾罰（ばっ）金（きん）｜を　｜＾払（はら）｜わなければ　なりませんか。',
                    normal: 'そうですか　罰金を　払わなければ　なりませんか。',
                    en: 'Is that right? Do I have to pay the fine?',
                    mm: 'ဟုတ်လား၊ ဒါဏ်ငွေကို မဆောင်လို့မရဘူးလား။',
                },
            ],
        },
        {
            person: {
                furi: '＾大（だい）学（がく）職（しょく）員（いん）',
                normal: '大学職員',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'ええ　１５、０００｜＾円（えん）｜　｜＾払（はら）｜わないと　いけません。',
                    normal: 'ええ　１５、０００円　払わないと　いけません。',
                    en: 'Yes, you have to pay 15,000 yen.',
                    mm: 'အင်း ယန်း ၁၅၀၀၀ မပေးဆောင်လို့မရဘူး',
                },
            ],
        },
        {
            person: {
                furi: 'ワット',
                normal: 'ワット',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'えっ。　１５、０００｜＾円（えん）｜ですか。｜＾雑（ざっ）誌（し）｜は　３００｜＾円（えん）｜だったんですけど。。。',
                    normal: 'えっ。　１５、０００円ですか。雑誌は　３００円だったんですけど。。。',
                    en: 'eh. 15,000 yen? The magazine was 300 yen. . .',
                    mm: 'အဲ့ ယန်း ၁၅၀၀၀လား၊ မဂ္ဂဇင်းက ယန်း ၃၀၀ ပဲ ဖြစ်ပေမယ့်',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson33/kaiwa/4N33KW.mp3")
}

/*

{
        title: {
            furi: '',
            normal: '',
            en: '',
            mm: '',
        },
        conversation: [
            {
                person: {
                    furi: '',
                    normal: '',
                    avatar: Icon.Kaiwa.MEN1
                },
                sentence: [
                    {
                        furi: '',
                        normal: '',
                        en: '',
                        mm: '',
                    },
                ],
            },
        ],
        audioUrl: require("@assets/audio/lesson26/kaiwa/4N26KW.mp3")
    }

*/