export const lesson6 = {
    "仕": 50,
    "事": 51,
    "銀": 52,
    "員": 53,
    "医": 54,
    "者": 55,
    "働": 56,
    "屋": 57,
    "産": 58,
    "業": 59
};