
export const lesson11 = [
    {"lesson":11,"level":4,"question":"とくしょく","represent":["特","色"],"answer":["歩道","借金","特色","走者"],"true":"特色","times":10},
    {"lesson":11,"level":4,"question":"そうしゃ","represent":["走","者"],"answer":["起立","終電","開始","走者"],"true":"走者","times":10},
    {"lesson":11,"level":4,"question":"しはつ","represent":["始","発"],"answer":["終電","歩道","始発","起立"],"true":"始発","times":10},
    {"lesson":11,"level":4,"question":"きりつ","represent":["起","立"],"answer":["歩道","走者","特色","起立"],"true":"起立","times":10},
    {"lesson":11,"level":4,"question":"しゅうでん","represent":["終","電"],"answer":["始発","特色","走者","終電"],"true":"終電","times":10},
    {"lesson":11,"level":4,"question":"ほどう","represent":["歩","道"],"answer":["歩道","始発","起立","終点"],"true":"歩道","times":10},
    {"lesson":11,"level":4,"question":"とっきゅう","represent":["特","急"],"answer":["借金","起立","特急","始発"],"true":"特急","times":10},
    {"lesson":11,"level":4,"question":"かいし","represent":["開","始"],"answer":["借金","始発","終点","開始"],"true":"開始","times":10},
    {"lesson":11,"level":4,"question":"しゃっきん","represent":["借","金"],"answer":["借金","特色","走者","終点"],"true":"借金","times":10},
    {"lesson":11,"level":4,"question":"しゅうてん","represent":["終"],"answer":["歩道","走者","終点","始発"],"true":"終点","times":10},
]