
export const lesson18 = [
    {"lesson":18,"level":4,"question":"練習","represent":["習"],"answer":["れんしゅう","きょうしつ","べんきょう","えいご"],"true":"れんしゅう","times":10},
    {"lesson":18,"level":4,"question":"学習する","represent":["学","習"],"answer":["がくしゅうする","せんこうする","べんきょうする","けんきゅうする"],"true":"がくしゅうする","times":10},
    {"lesson":18,"level":4,"question":"研究する","represent":["研","究"],"answer":["けんきゅうする","けんしゅうする","べんきょうする","せんこうする"],"true":"けんきゅうする","times":10},
    {"lesson":18,"level":4,"question":"教会","represent":["教","会"],"answer":["きょうかい","れんしゅう","ぶんがく","きょうしつ"],"true":"きょうかい","times":10},
    {"lesson":18,"level":4,"question":"選考する","represent":["考"],"answer":["せんこうする","けんしゅうする","きょういくする","けんきゅうする"],"true":"せんこうする","times":10},
    {"lesson":18,"level":4,"question":"考古学","represent":["考","古","学"],"answer":["こうこがく","けんきゅうしゃ","もんぶんしょう","けんきゅうじょ"],"true":"こうこがく","times":10},
    {"lesson":18,"level":4,"question":"勉強する","represent":["勉","強"],"answer":["べんきょうする","けんしゅうする","せんこうする","けんきゅうする"],"true":"べんきょうする","times":10},
    {"lesson":18,"level":4,"question":"英国","represent":["英","国"],"answer":["えいこく","べんきょう","れんしゅう","えいご"],"true":"えいこく","times":10},
    {"lesson":18,"level":4,"question":"教室","represent":["教","室"],"answer":["きょうしつ","かんじ","きょうかい","べんきょう"],"true":"きょうしつ","times":10},
    {"lesson":18,"level":4,"question":"研究所","represent":["研","究","所"],"answer":["けんきゅうじょ","こうこがく","けんきゅうしゃ","もんぶしょう"],"true":"けんきゅうじょ","times":10},
    {"lesson":18,"level":4,"question":"文字","represent":["文","字"],"answer":["もじ","べんきょう","きょうしつ","えいご"],"true":"もじ","times":10},
    {"lesson":18,"level":4,"question":"勉強","represent":["勉","強"],"answer":["べんきょう","ぶんがく","きょうしつ","れんしゅう"],"true":"べんきょう","times":10},
    {"lesson":18,"level":4,"question":"漢字","represent":["漢","字"],"answer":["かんじ","べんきょう","れんしゅう","きょうかい"],"true":"かんじ","times":10},
    {"lesson":18,"level":4,"question":"文学","represent":["文","学"],"answer":["ぶんがく","えいご","きょうかい","えいこく"],"true":"ぶんがく","times":10},
    {"lesson":18,"level":4,"question":"研究者","represent":["研","究","者"],"answer":["けんきゅうしゃ","けんきゅうじょ","もんぶしょう","こうこがく"],"true":"けんきゅうしゃ","times":10},
    {"lesson":18,"level":4,"question":"英語","represent":["英","語"],"answer":["えいご","かんじ","ぶんがく","きょうしつ"],"true":"えいご","times":10},
    {"lesson":18,"level":4,"question":"研修する","represent":["研"],"answer":["けんきゅうする","せんこうする","がくしゅうする","きょういくする"],"true":"けんきゅうする","times":10},
    {"lesson":18,"level":4,"question":"教育する","represent":["教"],"answer":["きょういくする","がくしゅうする","けんしゅうする","せんこうする"],"true":"きょういくする","times":10},
    {"lesson":18,"level":4,"question":"文部省","represent":["文"],"answer":["もんぶしょう","けんきゅうしゃ","けんきゅうじょ","こうこがく"],"true":"もんぶしょう","times":10},
]