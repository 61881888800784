import { exercises } from "./exercise";
import { grammar } from "./grammar";
import { kaiwa } from "./kaiwa";
import { kanji } from "./kanji";
import { test } from "./test";
import { vocab } from "./vocab";

export const Minna = {
    vocab,
    kanji,
    grammar,
    kaiwa,
    exercises,
    test
}

// https://github.com/arkarMannAung/minna/blob/main/4N27VO.mp3

// http://mm-biz.com/minna_no_nihongo_myanmar/lesson-28-grammar/