
export const lesson11 = [ 
    {"lesson":11,"level":4,"question":"終点","represent":["終"],"answer":["しゃっきん","きりつ","とくしょく","しゅうてん"],"true":"しゅうてん","times":10},
    {"lesson":11,"level":4,"question":"開始","represent":["開","始"],"answer":["しはつ","かいし","しゅうてん","しゃっきん"],"true":"かいし","times":10},
    {"lesson":11,"level":4,"question":"特急","represent":["特","急"],"answer":["しゃっきん","とっきゅう","そうしゃ","とくしょく"],"true":"とっきゅう","times":10},
    {"lesson":11,"level":4,"question":"終電","represent":["終","電"],"answer":["そうしゃ","かいし","しゅうてん","しゅうでん"],"true":"しゅうでん","times":10},
    {"lesson":11,"level":4,"question":"借金","represent":["借","金"],"answer":["とくしょく","しゃっきん","しはつ","かいし"],"true":"しゃっきん","times":10},
    {"lesson":11,"level":4,"question":"始発","represent":["始","発"],"answer":["しはつ","とっきゅう","とくしょく","ほどう"],"true":"しはつ","times":10},
    {"lesson":11,"level":4,"question":"起立","represent":["起","立"],"answer":["しゅうでん","ほどう","きりつ","とくしょく"],"true":"きりつ","times":10},
    {"lesson":11,"level":4,"question":"特色","represent":["特","色"],"answer":["ほどう","とっきゅう","とくしょく","しはつ"],"true":"とくしょく","times":10},
    {"lesson":11,"level":4,"question":"走者","represent":["走","者"],"answer":["しゅうでん","そうしゃ","かいし","しゃっきん"],"true":"そうしゃ","times":10},
    {"lesson":11,"level":4,"question":"歩道","represent":["歩","道"],"answer":["しゃっきん","ほどう","かいし","きりつ"],"true":"ほどう","times":10},
    {"lesson":11,"level":4,"question":"家賃","represent":["家"],"answer":["かす","やちん","かしや","かかす"],"true":"やちん","times":10},
    {"lesson":11,"level":4,"question":"貸家","represent":["貸","家"],"answer":["かす","やちん","かしや","かかす"],"true":"かしや","times":10},
    {"lesson":11,"level":4,"question":"中止","represent":["中","止"],"answer":["ちゅうし","かいし","ちょうし","しはつ"],"true":"ちゅうし","times":10},
    {"lesson":11,"level":4,"question":"散歩","represent":["歩"],"answer":["さんぽ","しんぽ","さんほ","しんほ"],"true":"さんぽ","times":10},
    {"lesson":11,"level":4,"question":"進歩","represent":["進","歩"],"answer":["さんぽ","しんぽ","さんほ","しんほ"],"true":"しんぽ","times":10},
]