
export const lesson7 =[
    {"lesson":7,"level":4,"question":"ひかり","represent":["光"],"answer":["海","林","池","光"],"true":"光","times":10},
    {"lesson":7,"level":4,"question":"とち","represent":["土","地"],"answer":["土地","地方","洋服","観光"],"true":"土地","times":10},
    {"lesson":7,"level":4,"question":"いけ","represent":["池"],"answer":["雪","森","池","林"],"true":"池","times":10},
    {"lesson":7,"level":4,"question":"ちか","represent":["地","下"],"answer":["地下","洋服","北風","土地"],"true":"地下","times":10},
    {"lesson":7,"level":4,"question":"かんこう","represent":["光"],"answer":["観光","海水","一台","森田"],"true":"観光","times":10},
    {"lesson":7,"level":4,"question":"もりた","represent":["森","田"],"answer":["台地","森田","小林","東洋"],"true":"森田","times":10},
    {"lesson":7,"level":4,"question":"ようしょく","represent":["洋","食"],"answer":["電池","台風","洋食","西洋"],"true":"洋食","times":10},
    {"lesson":7,"level":4,"question":"いちだい","represent":["一","台"],"answer":["雪国","一台","海外","森田"],"true":"一台","times":10},
    {"lesson":7,"level":4,"question":"ちず","represent":["地","図"],"answer":["観光","地図","北風","台地"],"true":"地図","times":10},
    {"lesson":7,"level":4,"question":"たいふう","represent":["台","風"],"answer":["土地","台風","和風","洋服"],"true":"台風","times":10},
    {"lesson":7,"level":4,"question":"もり","represent":["森"],"answer":["風","森","雪","台"],"true":"森","times":10},
    {"lesson":7,"level":4,"question":"しんりん","represent":["森","林"],"answer":["森林","和風","北風","西洋"],"true":"森林","times":10},
    {"lesson":7,"level":4,"question":"でんち","represent":["電","池"],"answer":["電池","地下","小林","日記"],"true":"電池","times":10},
    {"lesson":7,"level":4,"question":"かいすい","represent":["海","水"],"answer":["日光","雪国","森田","海水"],"true":"海水","times":10},
    {"lesson":7,"level":4,"question":"たい","represent":["台"],"answer":["地","台","森","光"],"true":"台","times":10},
    {"lesson":7,"level":4,"question":"わふう","represent":["風"],"answer":["森林","地図","一台","和風"],"true":"和風","times":10},
    {"lesson":7,"level":4,"question":"うみ","represent":["海"],"answer":["森","海","台","池"],"true":"海","times":10},
    {"lesson":7,"level":4,"question":"にっこう","represent":["日","光"],"answer":["台地","日光","電池","雪国"],"true":"日光","times":10},
    {"lesson":7,"level":4,"question":"こばやし","represent":["小","林"],"answer":["小林","森田","地方","西洋"],"true":"小林","times":10},
    {"lesson":7,"level":4,"question":"とうよう","represent":["東","洋"],"answer":["海水","雪国","東洋","一台"],"true":"東洋","times":10},
    {"lesson":7,"level":4,"question":"だいち","represent":["台","地"],"answer":["台風","地下","台地","海外"],"true":"台地","times":10},
    {"lesson":7,"level":4,"question":"はやし","represent":["林"],"answer":["海","林","風","雪"],"true":"林","times":10},
    {"lesson":7,"level":4,"question":"ゆきぐに","represent":["雪","国"],"answer":["森林","観光","雪国","洋食"],"true":"雪国","times":10},
    {"lesson":7,"level":4,"question":"ようふく","represent":["洋","服"],"answer":["洋服","洋食","西洋","林業"],"true":"洋服","times":10},
    {"lesson":7,"level":4,"question":"かぜ","represent":["風"],"answer":["台","風","池","林"],"true":"風","times":10},
    {"lesson":7,"level":4,"question":"ゆき","represent":["雪"],"answer":["海","森","雪","光"],"true":"雪","times":10},
    {"lesson":7,"level":4,"question":"せいよう","represent":["西","洋"],"answer":["観光","西洋","台地","海水"],"true":"西洋","times":10},
    {"lesson":7,"level":4,"question":"りんぎょう","represent":["林","業"],"answer":["日光","観光","林業","森林"],"true":"林業","times":10},
    {"lesson":7,"level":4,"question":"きたかぜ","represent":["北","風"],"answer":["小林","日光","北風","台地"],"true":"北風","times":10},
    {"lesson":7,"level":4,"question":"ちほう","represent":["地","方"],"answer":["東洋","海外","地方","洋食"],"true":"地方","times":10},
    {"lesson":7,"level":4,"question":"かいがい","represent":["海","外"],"answer":["海水","地図","海外","雪国"],"true":"海外","times":10},
]