export const bunkei= [
    {
        sentence: [
            {
                furi: "カードを　なくした　｜＾場（ば）合（あい）｜は、すぐ　カード｜＾会（かい）社（しゃ）｜に　｜＾連（れん）絡（らく）｜して　ください。",
                normal: "カードを　なくした　場合は、すぐ　カード会社に　連絡して　ください。",
                en: "If you lose your card, please contact your credit card company immediately.",
                mm: "သင့်ကတ်ပျောက်ဆုံးပါက သင့်ခရက်ဒစ်ကတ်ကုမ္ပဏီသို့ ချက်ချင်းဆက်သွယ်ပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson45/bunpou/4N45BK1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾約（やく）束（そく）｜を　したのに、｜＾彼（かの）女（じょ）｜は　｜＾来（き）｜ませんでした。",
                normal: "約束を　したのに、彼女は　来ませんでした。",
                en: "She didn't come even though she promised me.",
                mm: "ချိန်းထားခဲ့ပေမဲ့လဲ သူမ မလာခဲ့ဘူး။",
            },
        ],
        audioUrl: require("@assets/audio/lesson45/bunpou/4N45BK2.mp3")
    },
]