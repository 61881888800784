import Helper from '@helper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Routes from '@routes/Routes';
import LocalStorage from '@store/LocalStorage';
export interface RouteHistoryType {
    test: TabBarType;
    home: TabBarType;
    setting: TabBarType;
}
export interface TabBarType {
    state: Object;
    route: String;
}
const routeHistory: RouteHistoryType = {
    test: {
        state: {},
        route:Routes.TEST
    },
    home: {
        state: {},
        route: Routes.HOME
    },
    setting: {
        state: {},
        route: Routes.SETTING
    }
};

const testPoint = () => {
    let test = localStorage.getItem('testPoint');
    if (test) {
        const { jlpt2010, jlpt2012, jlpt201307, jlpt201312, jlpt201407, jlpt2018 } = JSON.parse(test);
        return {
            jlpt2018: jlpt2018 || -1,
            jlpt201407: jlpt201407 || -1,
            jlpt201312: jlpt201312 || -1,
            jlpt201307: jlpt201307 || -1,
            jlpt2012: jlpt2012 || -1,
            jlpt2010: jlpt2010 || -1,
        }
    } else {
        return {
            jlpt2018: -1,
            jlpt201407: -1,
            jlpt201312: -1,
            jlpt201307: -1,
            jlpt2012: -1,
            jlpt2010: -1
        }
    }
}
const localPremium = (localStorage.getItem("premium")) ;

const initialState = {
    pro: false, // main-> false, pro-> true
    premium:( localPremium&& Helper.checkAuth(localPremium)) || false,
    testPoint:testPoint(),
    dark: localStorage.getItem('dark') || 'light',
    name: 'A Mie Mie ',
    fontSize: parseInt(localStorage.getItem('fontSize') || '16'),
    lang: localStorage.getItem('lang') || 'en',
    furigana: localStorage.getItem('furigana') || "true",
    scores: LocalStorage.getScore() || Helper.listGenerate(25, (i:number) => {
        // return {
        //     vocab:  i%4===0? 25: i%6===0? 16: i*2,
        //     grammar: i%4===0? 40: i%6===0? 20: i*2,
        //     kanjiScore: {
        //         kanji: 0,
        //         hira: 0,
        //         count: i%4===0? 25: i%6===0? 12: i*2
        //     },
        //     kaiwa: i%4===0? 10: i%6===0? 5: i*2
        // }
        return {
            vocab:  0,
            grammar: 0,
            kanjiScore: {
                kanji: 0,
                hira: 0,
                count: 0
            },
            kaiwa: 0
        }
    }),
    routeHistory
}

export const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        setDark: (state, action: PayloadAction<string>) => {
            state.dark = action.payload;
        },
        setName: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
        setFontSize: (state, action: PayloadAction<number>) => {
            state.fontSize = action.payload;
        },
        setLang: (state, action: PayloadAction<string>) => {
            state.lang = action.payload;
        },
        setFurigana: (state, action: PayloadAction<string>) => {
            state.furigana = action.payload;
        },
        setScore: (state, action: PayloadAction<Array<any>>) => {
            state.scores = action.payload;
        },
        setRouteHistory: (state,action: PayloadAction<RouteHistoryType>)=>{
            state.routeHistory = action.payload;
        },
        setTestPoint: (state, action: PayloadAction<any>) => {
            state.testPoint = action.payload;
        },
        setPremium:(state, action: PayloadAction<boolean>)=>{
            state.premium = action.payload;
        }
    },
});
 
export const { setDark, setName, setFontSize, setLang, setFurigana, setScore, setRouteHistory, setTestPoint,setPremium } = serviceSlice.actions;
export default serviceSlice.reducer;
// [
//     {
//         vocab: 0,
//         grammar: 0,
//         kanjiScore: {
//             kanji: 0,
//             hira: 0,
//             count: 0
//         },
//         kaiwa: 0
//     }
// ]