import Helper from "@helper";

export const fukushuu1 = {
    mondai1:
        Helper.shuffle(
        [
            {
                question: "８時の　｜＾電（でん）車（しゃ）｜＄０｜＾遅（おく）｜れて　しまいました。",
                picked: [""],
                answers:Helper.shuffle(["に","で","を","が"]),
                correct: ["に"],
            },
            {
                question: "＾書（か）｜き｜＾方（かた）｜＄０｜　わからないんです｜＄１｜、　｜＾教（おし）｜えて　いただけませんか。",
                picked: ["", ""],
                answers:Helper.shuffle( ["は", "で", "を","が"]),
                correct: ["が", "が"],
            },
            {
                question: "バスの　｜＾時（じ）間（かん）｜＄０｜＾間（ま）｜に｜＾合（あ）｜いませんでした。",
                picked: [""],
                answers:Helper.shuffle( ["を","に","で","は"]),
                correct: ["に"],
            },
            {
                question: "＾窓（まど）｜から　｜＾山（やま）｜＄０｜＾見（み）｜えます。",
                picked: [""],
                answers:Helper.shuffle( ["を","が","に","へ"]),
                correct: ["が"],
            },
            {
                question: "ひらがな｜＄０｜＾書（か）｜けますが、｜＾漢（かん）字（じ）｜＄１｜＾書（か）｜けません。",
                picked: ["",""],
                answers:Helper.shuffle( ["が", "を", "は", "で"]),
                correct: ["は","は"],
            },
            {
                question: "＾近（ちか）｜くに　スーパー｜＄０｜できました。",
                picked: [""],
                answers:Helper.shuffle( ["は","を","が","に"]),
                correct: ["が"],
            },
            {
                question: "アリナさんは　フランス｜＾語（ご）｜＄０｜＾話（はな）｜せます。",
                picked: [""],
                answers:Helper.shuffle( ["は", "で", "を","が"]),
                correct: ["が"],
            },
            {
                question: "デザイン｜＄０｜いいし、｜＾値（ね）段（だん）｜＄１｜＾安（やす）｜いし、この　テーブルを｜＾買（か）｜います。",
                picked: ["",""],
                answers:Helper.shuffle( ["は", "が", "も", "を"]),
                correct: ["も","も"],
            },
            {
                question: "＾電（でん）車（しゃ）｜＄０｜＾傘（かさ）｜を　｜＾忘（わす）｜れて　しまいました。",
                picked: [""],
                answers:Helper.shuffle( ["に","で","へ","が"]),
                correct: ["に"],
            },
            {
                question: "＾窓（まど）｜＄０｜＾開（あ）｜いて　いますから、｜＾閉（し）｜めて　ください。",
                picked: [""],
                answers:Helper.shuffle( ["が","を","は","に"]),
                correct: ["が"],
            },
            {
                question: "この　コピー｜＾機（き）｜＄０｜＾故（こ）障（しょう）｜して　いますから、あちらのを　｜＾使（つか）｜って　ください。",
                picked: [""],
                answers:Helper.shuffle( ["は", "を", "が", "も"]),
                correct: ["は"],
            },
            {
                question: "＾壁（かべ）｜＄０｜カレンダー｜＄１｜＾掛（か）｜けて　あります。",
                picked: ["",""],
                answers:Helper.shuffle( ["に", "を", "が", "で"]),
                correct: ["に","が"],
            },
            {
                question: "＾聞（き）｜こえません　から、もう　｜＾少（すこ）｜し　｜＾大（おお）｜きい　｜＾声（こえ）｜＄０｜＾話（はな）｜して　ください。",
                picked: [""],
                answers:Helper.shuffle( ["で","を","が","に"]),
                correct: ["で"],
            },
            {
                question: "＾廊（ろう）下（か）｜＄０｜＾走（はし）｜っては　いけません。",
                picked: [""],
                answers:Helper.shuffle( ["で","に","を","は"]),
                correct: ["を"],
            },
            {
                question: "あの　｜＾先（せん）生（せい）｜は｜＾学（がく）生（せい）｜＄０｜＾人（にん）気（き）｜＄１｜あります。",
                picked: ["",""],
                answers:Helper.shuffle( ["に", "が", "を", "は"]),
                correct: ["に","が"],
            }
        ]
    )
    ,
    mondai2: //Helper.shuffle(
        [
        {
            question: "ここで　名前と　｜！住所｜を　書いて　ください。",
            picked: "",
            answers: Helper.shuffle(["じゅうしょ", "じゅうしょう", "じゆうしょ", "しゅうしょ"]),
            correct: "じゅうしょ",
        },
        {
            question: "これは｜！東京｜行きの電車です。",
            picked: "",
            answers: Helper.shuffle(["とうきょう", "ときょう", "とうきょ", "とうぎょう"]),
            correct: "とうきょう",
        },
        {
            question: "！京都｜に行ったことがありますか。",
            picked: "",
            answers: Helper.shuffle(["きょうと", "ぎょうと", "きょと", "きょうとう"]),
            correct: "きょうと",
        },
        {
            question: "新しい｜！政府｜が選挙された。",
            picked: "",
            answers: Helper.shuffle(["せいふ", "ぜいふ", "せふ", "せいふう"]),
            correct: "せいふ",
        },
        {
            question: "日本には、｜！県｜がいくつありますか。",
            picked: "",
            answers: Helper.shuffle(["けん", "げん", "かん", "がん"]),
            correct: "けん",
        },
        {
            question: "！市役所｜は　どこにありますか。",
            picked: "",
            answers: Helper.shuffle(["しやくしょ", "しやしょ", "しやくじょ", "しゃくしょ"]),
            correct: "しやくしょ",
        },
        {
            question: "私は｜！北区｜に　住んでいます。",
            picked: "",
            answers: Helper.shuffle(["きたく", "きたぐ", "ぎたぐ", "ぎたく"]),
            correct: "きたく",
        },
        {
            question: "私の　｜！町｜の　人口は　約　３万人です。",
            picked: "",
            answers: Helper.shuffle(["まち", "みち", "まし", "みし"]),
            correct: "まち",
        },
        {
            question: "彼女は　小さな　｜！村｜で　生まれました。",
            picked: "",
            answers: Helper.shuffle(["むら", "むち", "もら", "もち"]),
            correct: "むら",
        },
        {
            question: "！明日｜の　朝は　早く　でかけないと　いけない。",
            picked: "",
            answers: Helper.shuffle(["あした", "きょう", "きのう", "あさって"]),
            correct: "あした",
        },
        {
            question: "！暗｜くなるまで　帰って　ください。",
            picked: "",
            answers: Helper.shuffle(["くら", "きら", "ぐら", "くち"]),
            correct: "くら",
        },
        {
            question: "駅までは　かなり　｜！遠い｜",
            picked: "",
            answers: Helper.shuffle(["とおい", "どおい", "ちかい", "ちがい"]),
            correct: "とおい",
        },
        {
            question: "駅の　｜！近く｜に　高層ビルが　並んでいます。",
            picked: "",
            answers: Helper.shuffle(["ちかく", "とおく", "おおく", "かるく"]),
            correct: "ちかく",
        },
        {
            question: "台風が　あったので　風が｜！強く｜吹いています。",
            picked: "",
            answers: Helper.shuffle(["つよく", "よわく", "たかく", "とおく"]),
            correct: "つよく",
        },
        {
            question: "台風が　終ったので　風も｜！弱く｜なってきました。",
            picked: "",
            answers: Helper.shuffle(["つよく", "よわく", "たかく", "とおく"]),
            correct: "よわく",
        },
        {
            question: "自分の　｜！体重｜を　知っていますか。",
            picked: "",
            answers: Helper.shuffle(["たいじゅう", "だいしゅう", "だいじゅう", "たいしゅう"]),
            correct: "たいじゅう",
        },
        {
            question: "！軽｜くて　じょうぶな　かばんを　買いました。",
            picked: "",
            answers: Helper.shuffle(["ほそ", "やす", "かる", "おも"]),
            correct: "かる",
        },
        {
            question: "彼女は｜！太｜りすぎている。",
            picked: "",
            answers: Helper.shuffle(["ふと", "ぶと", "ふど", "ぶど"]),
            correct: "ふと",
        },
        {
            question: "あの　道は｜！細くて｜　暗い。",
            picked: "",
            answers: Helper.shuffle(["ほそくて", "こまかくて", "かるくて", "あかるくて"]),
            correct: "ほそくて",
        },
        {
            question: "何か　｜！特に｜　ほしいものが　ありますか。",
            picked: "",
            answers: Helper.shuffle(["とくに", "どくに", "とっくに", "どっくに"]),
            correct: "とくに",
        },
        {
            question: "！別｜の　ものを　見せてください。",
            picked: "",
            answers: Helper.shuffle(["べつ", "ぺつ", "べっつ", "ぺっつ"]),
            correct: "べつ",
        },
        {
            question: "彼は　歌手　として　｜！有名｜だ。",
            picked: "",
            answers: Helper.shuffle(["ゆうめい", "ゆうめ", "ゆめい", "ゆめ"]),
            correct: "ゆうめい",
        },
        {
            question: "電話が　ないのは｜！不便｜だ。",
            picked: "",
            answers: Helper.shuffle(["ふべん", "ふり", "ふうべん", "ぶり"]),
            correct: "ふべん",
        },
        {
            question: "これを｜！利用｜することが　できません。",
            picked: "",
            answers: Helper.shuffle(["りよう", "りよ", "りょう", "りょ"]),
            correct: "りよう",
        },
        {
            question: "何でも　あきらめない　気持ちが｜！大切｜だ。",
            picked: "",
            answers: Helper.shuffle(["たいせつ", "だいせつ", "たいぜつ", "だいぜつ"]),
            correct: "たいせつ",
        },
        {
            question: "おかげで｜！元気｜に　なりました。",
            picked: "",
            answers: Helper.shuffle(["げんき", "げんぎ", "けんき", "けんぎ"]),
            correct: "げんき",
        },
        {
            question: "どんな　音楽が｜！好き｜ですか？",
            picked: "",
            answers: Helper.shuffle(["すき", "ずき", "つき", "づき"]),
            correct: "すき",
        },
        {
            question: "！急｜ぐ必要は　ありません。",
            picked: "",
            answers: Helper.shuffle(["いそ", "おそ", "およ", "はたら"]),
            correct: "いそ",
        },
        {
            question: "月が　空に　｜！低｜く出ている。",
            picked: "",
            answers: Helper.shuffle(["ひく", "とお", "き", "たか"]),
            correct: "ひく",
        },
        {
            question: "コロナが　急に｜！広｜がって　いる。",
            picked: "",
            answers: Helper.shuffle(["ひろ", "ひる", "てい", "こう"]),
            correct: "ひろ",
        },
        {
            question: "その文を｜！短｜く　してください。",
            picked: "",
            answers: Helper.shuffle(["みじか", "なが", "とお", "ちか"]),
            correct: "みじか",
        },
        {
            question: "それは｜！良｜かったですね。　",
            picked: "",
            answers: Helper.shuffle(["よ", "よう", "よっ", "よい"]),
            correct: "よ",
        },
        {
            question: "天気が　｜！悪｜くて　残念です。",
            picked: "",
            answers: Helper.shuffle(["わる", "わろ", "あ", "あっ"]),
            correct: "わる",
        }
    ]
    // )
}
   
    // {
    //     question: "｜！｜",
    //     picked: "",
    //     answers: Helper.shuffle(["", "", "", ""]),
    //         correct: "",
    //     },
