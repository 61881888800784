
export const lesson8 = [
    {"lesson":8,"level":4,"question":"春","represent":["春"],"answer":["はる","ふゆ","なつ","あき"],"true":"はる","times":10},
    {"lesson":8,"level":4,"question":"暖冬","represent":["暖","冬"],"answer":["きせつ","せつぶん","だんとう","れいか"],"true":"だんとう","times":10},
    {"lesson":8,"level":4,"question":"暑","represent":["暑"],"answer":["なつ","あき","あつ","ふゆ"],"true":"あつ","times":10},
    {"lesson":8,"level":4,"question":"節分","represent":["節","分"],"answer":["だんとう","せつぶん","だんぼう","しき"],"true":"せつぶん","times":10},
    {"lesson":8,"level":4,"question":"晩秋","represent":["晩","秋"],"answer":["きせつ","れいか","ばんしゅう","だんとう"],"true":"ばんしゅう","times":10},
    {"lesson":8,"level":4,"question":"涼風","represent":["涼","風"],"answer":["きせつ","せつぶん","すずかぜ","ばんしゅう"],"true":"すずかぜ","times":10},
    {"lesson":8,"level":4,"question":"秋","represent":["秋"],"answer":["なつ","ふゆ","あつ","あき"],"true":"あき","times":10},
    {"lesson":8,"level":4,"question":"暖房","represent":["暖"],"answer":["だんぼう","せいしゅん","ばんしゅう","だんとう"],"true":"だんぼう","times":10},
    {"lesson":8,"level":4,"question":"四季","represent":["四","季"],"answer":["せいしゅん","だんとう","きせつ","しき"],"true":"しき","times":10},
    {"lesson":8,"level":4,"question":"冬","represent":["冬"],"answer":["あつ","なつ","ふゆ","はる"],"true":"ふゆ","times":10},
    {"lesson":8,"level":4,"question":"冷夏","represent":["夏"],"answer":["すずかぜ","しき","れいか","せつぶん"],"true":"れいか","times":10},
    {"lesson":8,"level":4,"question":"夏","represent":["夏"],"answer":["なつ","あき","あつ","はる"],"true":"なつ","times":10},
    {"lesson":8,"level":4,"question":"季節","represent":["季","節"],"answer":["きせつ","れいか","だんとう","せつぶん"],"true":"きせつ","times":10},
    {"lesson":8,"level":4,"question":"青春","represent":["青","春"],"answer":["だんとう","せいしゅん","きせつ","すずかぜ"],"true":"せいしゅん","times":10},
]