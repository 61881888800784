export const lesson17 = {
    "交": 160,
    "通": 161,
    "動": 162,
    "乗": 163,
    "降": 164,
    "運": 165,
    "転": 166,
    "帰": 167,
    "発": 168,
    "着": 169
};