import Logger from '@helper/logger';
interface VocabType {
    furi: string;
    normal: string;
    en: string;
    remark_en: string;
    mm: string;
    remark_mm: string;
    group: number
}
interface ObjectType {
    keyCheck: Function
}
const log = Logger(15);
export const vocab:ObjectType = {
    keyCheck: (data: Array<VocabType>)=>{
        data.map(({furi,normal,en,remark_en,mm,remark_mm,group})=>{
            if(furi===undefined){
                log.e('furi key not found at: '+normal);
            }
            if(normal===undefined){
                log.e('normal key not found at: '+furi);
            }
            if(en===undefined){
                log.e('en key not found at: '+furi);
            }
            if(remark_en===undefined){
                log.e('remark_en key not found at: '+furi);
            }
            if(mm===undefined){
                log.e('mm key not found at: '+furi);
            }
            if(remark_mm===undefined){
                log.e('remark_mm key not found at: '+furi);
            }
            if(group===undefined){
                log.e('group key not found at: '+furi);
            }
        })
        log.i('complete key check')
    }
}
// {
//     furi: "わたし",
//     normal: "わたし",
//     en: "I",
//     remark_en: "",
//     mm: "ကျွန်တော်၊ ကျွန်မ",
//     remark_mm: "",
//     group: 0
// },