export const lesson32 = [
  {
    furi: "＾運（うん）動（どう）｜します",
    normal: "運動します",
    en: "take exercise",
    remark_en: "",
    mm: "ကိုယ်လက်လှုပ်ရှားသည်၊ အားကစားလုပ်သည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO1.mp3")
  },
  {
    furi: "＾成（せい）功（こう）｜します",
    normal: "成功します",
    en: "succeed",
    remark_en: "",
    mm: "အောင်မြင်သည်၊ ဖြစ်ထွန်းသည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO2.mp3")
  },
  {
    furi: "＾失（しっ）敗（ぱい）｜します［｜＾試（し）験（けん）｜に］～",
    normal: "失敗します［試験に］～",
    en: "fail",
    remark_en: "an exam",
    mm: "ရှုံးနိမ့်သည်၊ ကျရှုံးသည်",
    remark_mm: "စာမေးပွဲ(မှာ)～",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO3.mp3")
  },
  {
    furi: "＾合（ごう）格（かく）｜します［｜＾試（し）験（けん）｜に］～",
    normal: "合格します［試験に］～",
    en: "pass",
    remark_en: "an exam",
    mm: "အောင်သည်၊ အောင်မြင်သည်",
    remark_mm: "စာမေးပွဲ(မှာ)～",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO4.mp3")
  },
  {
    furi: "やみます［｜＾雨（あめ）｜が］～",
    normal: "やみます［雨が］～",
    en: "stop",
    remark_en: "rain",
    mm: "တိတ်သည်၊ စဲသည်",
    remark_mm: "မိုး(က)～",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO5.mp3")
  },
  {
    furi: "＾晴（は）｜れます",
    normal: "晴れます",
    en: "clear up",
    remark_en: "",
    mm: "နေသာသည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO6.mp3")
  },
  {
    furi: "＾曇（くも）｜ります",
    normal: "曇ります",
    en: "get cloudy",
    remark_en: "",
    mm: "တိမ်ထူသည်၊ မိုးအုံ့သည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO7.mp3")
  },
  {
    furi: "＾続（つづ）｜きます［｜＾熱（ねつ）｜が］～",
    normal: "続きます［熱が］～",
    en: "continuous",
    remark_en: "hight temperature/ fever",
    mm: "ဆက်သည်၊ ဆက်လက်ဖြစ်သည်",
    remark_mm: "အဖျား(က)～",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO8.mp3")
  },
  {
    furi: "ひきます［かぜを］",
    normal: "ひきます［かぜを］",
    en: "catch ",
    remark_en: "a cold",
    mm: "အအေးမိသည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO9.mp3")
  },
  {
    furi: "＾冷（ひ）｜やします",
    normal: "冷やします",
    en: "cool",
    remark_en: "",
    mm: "အေးအောင်လုပ်သည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO10.mp3")
  },
  {
    furi: "＾込（こ）｜みます［｜＾道（みち）｜］が～",
    normal: "込みます［道］が～",
    en: "get crowded(a road)",
    remark_en: "",
    mm: "ပိတ်သည်၊ ထူထပ်သည်",
    remark_mm: "လမ်း(က)～",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO11.mp3")
  },
  {
    furi: "すきます［｜＾道（みち）｜］が",
    normal: "すきます［道］が",
    en: "get less crowded(a road)",
    remark_en: "",
    mm: "ချောင်သည်၊ ရှင်းသည်",
    remark_mm: "လမ်း(က)～",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO12.mp3")
  },
  {
    furi: "＾出（で）｜ます［｜＾試（し）合（あい）｜］に",
    normal: "出ます［試合］に",
    en: "participate,attend",
    remark_en: "in the match or a party",
    mm: "ပြုင်ပွဲဝင်သည်၊ ပါဝင်ဆင်နွဲသည်",
    remark_mm: "ပြိုင်ပွဲ(၌)",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO13.mp3")
  },
  {
    furi: "＾無（む）理（り）｜をします",
    normal: "無理をします",
    en: "overdo things",
    remark_en: "",
    mm: "မနိုင်ဝန်ထမ်းသည်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO14.mp3")
  },
  {
    furi: "＾十（じゅう）分（ぶん）｜［な］",
    normal: "十分［な］",
    en: "enough, sufficient",
    remark_en: "",
    mm: "လုံလောက်သော",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO15.mp3")
  },
  {
    furi: "おかしい",
    normal: "おかしい",
    en: "strange, funny",
    remark_en: "",
    mm: "ရယ်စရာကောင်းသော၊ ကြောင်သော",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO16.mp3")
  },
  {
    furi: "うるさい",
    normal: "うるさい",
    en: "noisy",
    remark_en: "",
    mm: "ဆူညံသော၊ နားငြီးသော",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO17.mp3")
  },
  {
    furi: "＾先（せん）生（せい）",
    normal: "先生",
    en: "doctor",
    remark_en: "",
    mm: "ဆရာ၊ ဆရာဝန်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO18.mp3")
  },
  {
    furi: "やけど",
    normal: "やけど",
    en: "burning",
    remark_en: "",
    mm: "အပူလောင်ခြင်း",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO19.mp3")
  },
  {
    furi: "けが",
    normal: "けが",
    en: "injury",
    remark_en: "",
    mm: "ဒဏ်ရာ",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO20.mp3")
  },
  {
    furi: "せき",
    normal: "せき",
    en: "cough",
    remark_en: "",
    mm: "ချောင်းဆိုးခြင်း",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO21.mp3")
  },
  {
    furi: "インフルエンザ",
    normal: "インフルエンザ",
    en: "influenza",
    remark_en: "",
    mm: "တုတ်ကွေး",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO22.mp3")
  },
  {
    furi: "＾空（そら）",
    normal: "空",
    en: "sky",
    remark_en: "",
    mm: "မိုးကောင်းကင်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO23.mp3")
  },
  {
    furi: "＾太（たい）陽（よう）",
    normal: "太陽",
    en: "sun",
    remark_en: "",
    mm: "နေမင်း",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO24.mp3")
  },
  {
    furi: "＾星（ほし）",
    normal: "星",
    en: "star",
    remark_en: "",
    mm: "ကြယ်၊ ဂြိုလ်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO25.mp3")
  },
  {
    furi: "＾風（かぜ）",
    normal: "風",
    en: "wind",
    remark_en: "",
    mm: "လေ",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO26.mp3")
  },
  {
    furi: "＾東（ひがし）",
    normal: "東",
    en: "east",
    remark_en: "",
    mm: "အရှေ့",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO27.mp3")
  },
  {
    furi: "＾西（にし）",
    normal: "西",
    en: "west",
    remark_en: "",
    mm: "အနောက်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO28.mp3")
  },
  {
    furi: "＾南（みなみ）",
    normal: "南",
    en: "south",
    remark_en: "",
    mm: "တောင်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO29.mp3")
  },
  {
    furi: "＾北（きた）",
    normal: "北",
    en: "north",
    remark_en: "",
    mm: "မြောက်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO30.mp3")
  },
  {
    furi: "＾国（こく）際（さい）｜～",
    normal: "国際～",
    en: "international",
    remark_en: "",
    mm: "အပြည်ပြည်ဆိုင်ရာ",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO31.mp3")
  },
  {
    furi: "＾水（すい）道（どう）",
    normal: "水道",
    en: "water supply",
    remark_en: "",
    mm: "ရေပိုက်လိုင်း",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO32.mp3")
  },
  {
    furi: "エンジン",
    normal: "エンジン",
    en: "engine",
    remark_en: "",
    mm: "အင်ဂျင်၊ စက်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO33.mp3")
  },
  {
    furi: "チーム",
    normal: "チーム",
    en: "team",
    remark_en: "",
    mm: "အသင်း၊ အဖွဲ့",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO34.mp3")
  },
  {
    furi: "＾今（こん）夜（や）",
    normal: "今夜",
    en: "this evening",
    remark_en: "",
    mm: "ဒီည၊ ယနေ့ည",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO35.mp3")
  },
  {
    furi: "＾夕（ゆう）方（がた）",
    normal: "夕方",
    en: "late aftermoon",
    remark_en: "",
    mm: "ညနေစောင်း",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO36.mp3")
  },
  {
    furi: "＾前（まえ）",
    normal: "前",
    en: "before, a time in the past",
    remark_en: "",
    mm: "ယခင်က၊ မတိုင်မီ",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO37.mp3")
  },
  {
    furi: "＾遅（おそ）｜く",
    normal: "遅く",
    en: "late (time)",
    remark_en: "",
    mm: "အချိန်နောက်ကြခြင်း",
    remark_mm: "ညအချိန်မတော်၊ ညကြီးမိုးချုပ်",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO38.mp3")
  },
  {
    furi: "こんなに",
    normal: "こんなに",
    en: "this (much etc)",
    remark_en: "",
    mm: "ဒီလောက်တောင်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO39.mp3")
  },
  {
    furi: "そんなに",
    normal: "そんなに",
    en: "that (much etc)",
    remark_en: "",
    mm: "အဲ့ဒီလောက်တောင်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO40.mp3")
  },
  {
    furi: "あんなに",
    normal: "あんなに",
    en: "that (much etc)",
    remark_en: "",
    mm: "အဲ့ဒီလောက်တောင်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO41.mp3")
  },
  {
    furi: "ヨーロッパ",
    normal: "ヨーロッパ",
    en: "Europe",
    remark_en: "",
    mm: "ဥရောပ",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO42.mp3")
  },
  {
    furi : "＾元（げん）気（き）",
    normal: "元気",
    en: "vigour",
    remark_en: "",
    mm: "သွက်လက်တက်ကြွမှု၊ ကျန်းမာမှု",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO43.mp3")
  },
  {
    furi: "＾胃（い）",
    normal: "胃",
    en: "stomach",
    remark_en: "",
    mm: "အစာအိမ်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO44.mp3")
  },
  {
    furi: "ストレス",
    normal: "ストレス",
    en: "stress",
    remark_en: "",
    mm: "စိတ်ဖိစီးမှု",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO45.mp3")
  },
  {
    furi: "それは　いけませんね。",
    normal: "それは　いけませんね。",
    en: "I'm sorry to hear that",
    remark_en: "",
    mm: "အဲ့ဒါတော့ မဖြစ်သင့်ဘူးနော်",
    remark_mm: "",
    group: 0,
    audioUrl: require("@assets/audio/lesson32/vocab/4N32VO46.mp3")
  },
  {
    furi: "＾星（ほし）占（うらな）｜い",
    normal: "星占い",
    en: "horoscope",
    remark_en: "",
    mm: "နက္ခတဗေဒင်",
    remark_mm: "",
    group: 0
  },
  {
    furi: "＾牡（お）牛（うし）座（ざ）",
    normal: "牡牛座",
    en: "Taurus",
    remark_en: "",
    mm: "ပြိဿရာသီ",
    remark_mm: "",
    group: 0
  },
  {
    furi: "＾働（はたら）｜きすぎ",
    normal: "働きすぎ",
    en: "overworking",
    remark_en: "",
    mm: "အလုပ်လုပ်လွန်ခြင်း",
    remark_mm: "",
    group: 0
  },
  {
    furi: "＾困（こま）｜ります",
    normal: "困ります",
    en: "being a trouble",
    remark_en: "",
    mm: "အခက်တွေ့သည်၊ ဒုက္ခရောက်သည်",
    remark_mm: "",
    group: 0
  },
  {
    furi: "＾宝（たから）｜くじ",
    normal: "宝くじ",
    en: "lottery",
    remark_en: "",
    mm: "ထီ",
    remark_mm: "",
    group: 0
  },
  {
    furi: "＾当（あ）｜たります",
    normal: "当たります",
    en: "win (a lottery)",
    remark_en: "",
    mm: "ထိမှန်သည်၊ ပေါက်သည်",
    remark_mm: "ထီ(က) ပေါက်သည်",
    group: 0
  },
  {
    furi: "＾健（けん）康（こう）",
    normal: "健康",
    en: "health",
    remark_en: "",
    mm: "ကျန်းမာရေး",
    remark_mm: "",
    group: 0
  },
  {
    furi: "＾恋（れん）愛（あい）",
    normal: "恋愛",
    en: "romantic love",
    remark_en: "",
    mm: "အချစ်၊ မေတ္တာ",
    remark_mm: "",
    group: 0
  },
  {
    furi: "＾恋（こい）人（びと）",
    normal: "恋人",
    en: "sweet heart, boyfriend, girlfriend",
    remark_en: "",
    mm: "ချစ်သူ၊ ရည်းစား",
    remark_mm: "",
    group: 0
  },
  {
    furi: "ラッキーアイテム",
    normal: "ラッキーアイテム",
    en: "something lucky",
    remark_en: "",
    mm: "လာဘ်ပွင့်အဆောင်၊ လက်ကီးအဆောင်ပစ္စည်း",
    remark_mm: "",
    group: 0
  },
  {
    furi: "＾石（いし）",
    normal: "石",
    en: "stone",
    remark_en: "",
    mm: "ကျောက်တုံး၊ ကျောက်ခဲ",
    remark_mm: "",
    group: 0
  }
]

/*
    {
        furi: "",
        normal: "",
        en: "",
        remark_en: "",
        mm: "",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson29/vocab/4N29VO1.mp3")
    },


*/
const test = [
  // 32S
  {
    'goi': '運（うん）」動（どう）」します　Ⅲ',
    'unicode': 'အားကစားလုပ်သည်',
    'zawgyi': 'အားကစားလုပ္သည္',
    'english': 'take exercise',
    'long': false
  },
  {
    'goi': '成（せい）」功（こう）」します　Ⅲ',
    'unicode': 'အောင်မြင်သည်',
    'zawgyi': 'ေအာင္ျမင္သည္',
    'english': 'succeed',
    'long': false
  },
  {
    'goi': '失（しっ）」敗（ぱい）」します　Ⅲ',
    'unicode': 'ကျရှုံးသည်',
    'zawgyi': 'က်ရႈံးသည္',
    'english': 'fail',
    'long': false
  },
  {
    'goi': '試（し）」験（けん）」に」失（しっ）」敗（ぱい）」します',
    'unicode': 'စာမေးပွဲမှာ ကျရှုံးသည်',
    'zawgyi': 'စာေမးပြဲမွာ က်ရႈံးသည္',
    'english': 'fail an examination',
    'long': false
  },
  {
    'goi': '合（ごう）」格（かく）」します　Ⅲ',
    'unicode': 'အောင်မြင်သည်',
    'zawgyi': 'ေအာင္ျမင္သည္',
    'english': 'succeed',
    'long': false
  },
  {
    'goi': '試（し）」験（けん）」に」合（ごう）」格（かく）」します',
    'unicode': 'စာမေးပွဲမှာ အောင်မြင်သည်',
    'zawgyi': 'စာေမးပြဲမွာ ေအာင္ျမင္သည္',
    'english': 'pass an examination',
    'long': true
  },
  {
    'goi': 'やみます　Ⅰ',
    'unicode': 'တိတ်စဲသည်',
    'zawgyi': 'တိတ္စဲသည္',
    'english': 'stop',
    'long': false
  },
  {
    'goi': '雨（あめ）」がやみます',
    'unicode': 'မိုး(က)တိတ်(စဲ)သည်',
    'zawgyi': 'မိုး(က)တိတ္(စဲ)သည္',
    'english': 'stop raining',
    'long': false
  },
  {
    'goi': '晴（は）」れます　Ⅱ',
    'unicode': 'နေသာသည်',
    'zawgyi': 'ေနသာသည္',
    'english': 'clear up',
    'long': false
  },
  {
    'goi': '曇（くも）」ります　Ⅰ',
    'unicode': 'တိမ်ထူသည်',
    'zawgyi': 'တိမ္ထူသည္',
    'english': 'get cloudy',
    'long': false
  },
  {
    'goi': '続（つづ）」きます　Ⅰ',
    'unicode': 'ဆက်လက်ဖြစ်သည်',
    'zawgyi': 'ဆက္လက္ျဖစ္သည္',
    'english': 'continue',
    'long': false
  },
  {
    'goi': '熱（ねつ）」が」続（つづ）」きます',
    'unicode': 'အများ(က)ဆက်ဖြစ်သည်',
    'zawgyi': 'အမ်ား(က)ဆက္ျဖစ္သည္',
    'english': 'high temperature continues',
    'long': false
  },
  {
    'goi': 'ひきます　I',
    'unicode': 'မိသည်(အအေး)',
    'zawgyi': 'မိသည္(အေအး)',
    'english': 'catch',
    'long': false
  },
  {
    'goi': 'かぜをひきます',
    'unicode': 'အအေးမိသည်',
    'zawgyi': 'အေအးမိသည္',
    'english': 'catch a cold',
    'long': false
  },
  {
    'goi': '冷（ひ）」やします　Ⅰ',
    'unicode': 'အအေးခံသည်',
    'zawgyi': 'အေအးခံသည္',
    'english': 'cool',
    'long': false
  },
  {
    'goi': '込（こ）」みます　Ⅰ',
    'unicode': 'ထူထပ်(ကျပ်ပိတ်)သည်',
    'zawgyi': 'ထူထပ္(က်ပ္ပိတ္)သည္',
    'english': 'crowded(road)',
    'long': false
  },
  {
    'goi': '道（みち）」が」込（こ）」みます',
    'unicode': 'လမ်း(က)ကြပ်သည်',
    'zawgyi': 'လမ္း(က)ၾကပ္သည္',
    'english': 'The road is crowded',
    'long': false
  },
  {
    'goi': 'すきます　Ⅰ',
    'unicode': 'ချောင်သည်',
    'zawgyi': 'ေခ်ာင္သည္',
    'english': 'free(road)',
    'long': false
  },
  {
    'goi': '道（みち）」がすきます',
    'unicode': 'လမ်း(က)ချောင်သည်',
    'zawgyi': 'လမ္း(က)ေခ်ာင္သည္',
    'english': 'The road is free',
    'long': false
  },
  {
    'goi': '出（で）」ます　Ⅱ',
    'unicode': 'ပါဝင်သည်',
    'zawgyi': 'ပါဝင္သည္',
    'english': 'Participate',
    'long': false
  },
  {
    'goi': '試（し）」合（あい）」に」出（で）」ます',
    'unicode': 'ပြိုင်ပွဲ(၌)ပါဝင်သည်',
    'zawgyi': 'ၿပိဳင္ပြဲ(၌)ပါဝင္သည္',
    'english': 'Go to the match',
    'long': false
  },
  {
    'goi': 'パーティーに」出（で）」ます',
    'unicode': 'ပါတီ(၌)ပါဝင်ဆင်နွှဲသည်',
    'zawgyi': 'ပါတီ(၌)ပါဝင္ဆင္ႏႊဲသည္',
    'english': 'Go to the party',
    'long': true
  },
  {
    'goi': '無（む）」理（り）」をします　Ⅲ',
    'unicode': 'မနိုင်ဝန်ထမ်းသည်',
    'zawgyi': 'မနိုင္ဝန္ထမ္းသည္',
    'english': 'work more than one\'s capacity',
    'long': true
  },
  {
    'goi': '十（じゅう）」分（ぶん）」[な]',
    'unicode': 'ပြည့်ဝသော',
    'zawgyi': 'ျပည့္ဝေသာ',
    'english': 'enough,sufficient',
    'long': false
  },
  {
    'goi': 'おかしい',
    'unicode': 'ရယ်စရာကောင်းသော',
    'zawgyi': 'ရယ္စရာေကာင္းေသာ',
    'english': 'strange,funny',
    'long': false
  },
  {
    'goi': 'うるさい',
    'unicode': 'ဆူညံသော၊နားငြီးသော',
    'zawgyi': 'ဆူညံေသာ၊နားၿငီးေသာ',
    'english': 'noisy',
    'long': false
  },
  {
    'goi': '先（せん）」生（せい）',
    'unicode': 'ဆရာ',
    'zawgyi': 'ဆရာ',
    'english': 'teacher',
    'long': false
  },
  {
    'goi': 'やけど',
    'unicode': 'အပူလောင်ခြင်း',
    'zawgyi': 'အပူေလာင္ျခင္း',
    'english': 'burning',
    'long': false
  },
  {
    'goi': 'けが',
    'unicode': 'ဒဏ်ရာ',
    'zawgyi': 'ဒဏ္ရာ',
    'english': 'injury',
    'long': false
  },
  {
    'goi': 'せき',
    'unicode': 'ချောင်းဆိုးခြင်း',
    'zawgyi': 'ေခ်ာင္းဆိုးျခင္း',
    'english': 'cough',
    'long': false
  },
  {
    'goi': 'インフルエンザ',
    'unicode': 'တုတ်ကွေး',
    'zawgyi': 'တုတ္ေကြး',
    'english': 'influenza',
    'long': false
  },
  {
    'goi': '空（そら）',
    'unicode': 'မိုးကောင်းကင်',
    'zawgyi': 'မိုးေကာင္းကင္',
    'english': 'sky',
    'long': false
  },
  {
    'goi': '太（たい）」陽（よう）',
    'unicode': 'နေမင်း',
    'zawgyi': 'ေနမင္း',
    'english': 'sun',
    'long': false
  },
  {
    'goi': '星（ほし）',
    'unicode': 'ကြယ်၊ဂြိုလ်',
    'zawgyi': 'ၾကယ္၊ၿဂိဳလ္',
    'english': 'star',
    'long': false
  },
  {
    'goi': '風（かぜ）',
    'unicode': 'လေ',
    'zawgyi': 'ေလ',
    'english': 'wind',
    'long': false
  },
  {
    'goi': '東（ひがし）',
    'unicode': 'အရှေ့',
    'zawgyi': 'အေရွ႕',
    'english': 'east',
    'long': false
  },
  {
    'goi': '西（にし）',
    'unicode': 'အနောက်',
    'zawgyi': 'အေနာက္',
    'english': 'west',
    'long': false
  },
  {
    'goi': '南（みなみ）',
    'unicode': 'တောင်',
    'zawgyi': 'ေတာင္',
    'english': 'south',
    'long': false
  },
  {
    'goi': '北（きた）',
    'unicode': 'မြောက်',
    'zawgyi': 'ေျမာက္',
    'english': 'north',
    'long': false
  },
  {
    'goi': '国（こく）」際（さい）」～',
    'unicode': 'အပြည်ပြည်ဆိုင်ရာ～',
    'zawgyi': 'အျပည္ျပည္ဆိုင္ရာ～',
    'english': 'international~',
    'long': false
  },
  {
    'goi': '水（すい）」道（どう）',
    'unicode': 'ရေပိုက်လိုင်း',
    'zawgyi': 'ေရပိုက္လိုင္း',
    'english': 'tap,water supply',
    'long': false
  },
  {
    'goi': 'エンジン',
    'unicode': 'အင်ဂျင်စက်',
    'zawgyi': 'အင္ဂ်င္စက္',
    'english': 'engine',
    'long': false
  },
  {
    'goi': 'チーム',
    'unicode': 'အသင်းအဖွဲ့',
    'zawgyi': 'အသင္းအဖြဲ႕',
    'english': 'team',
    'long': false
  },
  {
    'goi': '今（こん）」夜（や）',
    'unicode': 'ဒီည',
    'zawgyi': 'ဒီည',
    'english': 'this evening',
    'long': false
  },
  {
    'goi': '夕（ゆう）」方（がた）',
    'unicode': 'ညနေခင်း',
    'zawgyi': 'ညေနခင္း',
    'english': 'late afternoon',
    'long': false
  },
  {
    'goi': '前（まえ）',
    'unicode': 'မတိုင်မှီ',
    'zawgyi': 'မတိုင္မွီ',
    'english': 'before',
    'long': false
  },
  {
    'goi': '遅（おそ）」く',
    'unicode': 'နောက်ကျခြင်း(အချိန်)',
    'zawgyi': 'ေနာက္က်ျခင္း(အခ်ိန္)',
    'english': 'late (time)',
    'long': false
  },
  {
    'goi': 'こんなに',
    'unicode': 'ဒီလောက်တောင်',
    'zawgyi': 'ဒီေလာက္ေတာင္',
    'english': 'like this',
    'long': false
  },
  {
    'goi': 'そんなに',
    'unicode': 'အဲဒီလောက်တောင်',
    'zawgyi': 'အဲဒီေလာက္ေတာင္',
    'english': 'like that',
    'long': false
  },
  {
    'goi': 'あんなに',
    'unicode': 'အဲဒီလောက်တောင်',
    'zawgyi': 'အဲဒီေလာက္ေတာင္',
    'english': 'like that',
    'long': false
  },
  {
    'goi': 'ヨーロッパ',
    'unicode': 'ဥရောပ',
    'zawgyi': 'ဥေရာပ',
    'english': 'Europe',
    'long': false
  },
  {
    'goi': '元（げん）」気（き）',
    'unicode': 'ကျန်းမာသော',
    'zawgyi': 'က်န္းမာေသာ',
    'english': 'vigor',
    'long': false
  },
  {
    'goi': '胃（い）',
    'unicode': 'အစာအိမ်',
    'zawgyi': 'အစာအိမ္',
    'english': 'stomach',
    'long': false
  },
  {
    'goi': 'ストレス',
    'unicode': 'စိတ်ဖိစီးမှု',
    'zawgyi': 'စိတ္ဖိစီးမႈ',
    'english': 'stress',
    'long': false
  },
  {
    'goi': 'それはいけませんね。',
    'unicode': 'ဒါတော့မဖြစ်သင့်ဘူးနော်',
    'zawgyi': 'ဒါေတာ့မျဖစ္သင့္ဘူးေနာ္',
    'english': 'That\'s too bad',
    'long': true
  },
  {
    'goi': '星（ほし）」占（うらな）」い',
    'unicode': 'နက္ခတဗေဒင်',
    'zawgyi': 'နကၡတေဗဒင္',
    'english': 'horoscope',
    'long': false
  },
  {
    'goi': '牡（お）」牛（うし）」座（ざ）',
    'unicode': 'ပြိဿရာသီ',
    'zawgyi': 'ၿပိႆရာသီ',
    'english': 'Taurus',
    'long': false
  },
  {
    'goi': '働（はたら）」きすぎ',
    'unicode': 'အလုပ်လုပ်ခြင်း',
    'zawgyi': 'အလုပ္လုပ္ျခင္း',
    'english': 'working too much',
    'long': false
  },
  {
    'goi': '困（こま）」ります　Ⅰ',
    'unicode': 'အခက်တွေ့သည်',
    'zawgyi': 'အခက္ေတြ႕သည္',
    'english': 'be in trouble',
    'long': false
  },
  {
    'goi': '宝（たから）」くじ',
    'unicode': 'ထီ',
    'zawgyi': 'ထီ',
    'english': 'lottery',
    'long': false
  },
  {
    'goi': '当（あ）」たります　Ⅰ',
    'unicode': 'ပေါက်သည်',
    'zawgyi': 'ေပါက္သည္',
    'english': 'win',
    'long': false
  },
  {
    'goi': '宝（たから）」くじが」当（あ）」たります',
    'unicode': 'ထီပေါက်သည်',
    'zawgyi': 'ထီေပါက္သည္',
    'english': 'win a lottery',
    'long': false
  },
  {
    'goi': '健（けん）」康（こう）',
    'unicode': 'ကျန်းမာရေး',
    'zawgyi': 'က်န္းမာေရး',
    'english': 'health',
    'long': false
  },
  {
    'goi': '恋（れん）」愛（あい）',
    'unicode': 'အချစ်၊မေတ္တာ',
    'zawgyi': 'အခ်စ္၊ေမတၱာ',
    'english': 'romantic love',
    'long': false
  },
  {
    'goi': '恋（こい）」人（びと）',
    'unicode': 'ချစ်သူ',
    'zawgyi': 'ခ်စ္သူ',
    'english': 'boyfriend,girlfriend',
    'long': false
  },
  {
    'goi': 'ラッキーアイテム',
    'unicode': 'လဘ်ပွင့်အဆောင်',
    'zawgyi': 'လဘ္ပြင့္အေဆာင္',
    'english': 'Lucky item',
    'long': false
  },
  {
    'goi': '石（いし）',
    'unicode': 'ကျောက်တုံး၊ခဲလုံး',
    'zawgyi': 'ေက်ာက္တုံး၊ခဲလုံး',
    'english': 'stone',
    'long': false
  },
  {'goi': '','unicode': '','zawgyi': '','english': '','long': false},
]