
export const renshuuA = [
    {
        //  {
        // furi: 'あしたから　｜！旅（りょ）行（こう）なん｜です。',
        // normal: 'あしたから　旅行　なんです。',
        // en: 'It\'s a trip from tomorrow.',
        // mm: 'မနက်ဖြန်ကနေစပြီး ခရီးထွက်ပါမယ်။',
        // }
        sentence: [
            {
                furi: "ドアが　あいて　います。",
                normal: "ドアが　あいて　います。",
                en: "The door is open.",
                mm: "တံခါးပွင့်နေတယ်။",
            },
            {
                furi: "くるまが　とまって　います。",
                normal: "くるまが　とまって　います。",
                en: "The car is stopped.",
                mm: "ကားက ရပ်နေတယ်။",
            },
            {
                furi: "ガラスが　われて　います。。",
                normal: "ガラスが　われて　います。。",
                en: "The glass is broken.",
                mm: "မှန်က ကွဲနေတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson29/renshua/4N29RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "このふくろは　やぶれて　います。",
                normal: "このふくろは　やぶれて　います。",
                en: "This bag is torn.",
                mm: "ဒီအိတ်က စုတ်ပြဲနေတယ်။",
            },
            {
                furi: "その　コピーきは　こしょうして　います。",
                normal: "その　コピーきは　こしょうして　います。",
                en: "This copier is broken.",
                mm: "ဒီမိတ္တူစက်က ပျက်နေတယ်။",
            },
            {
                furi: "あのきは　おれて　います。",
                normal: "あのきは　おれて　います。",
                en: "That tree is broken.",
                mm: "အဲဒီသစ်ပင်က ကျိုးနေတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson29/renshua/4N29RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "『げんじものがたり』は　｜＾全（ぜん）部（ぶ）｜　よんで　しまいました。",
                normal: "『げんじものがたり』は　全部　よんで　しまいました。",
                en: "I have read all of The Tale of Genji.",
                mm: "Genji ပုံပြင် အားလုံးကို ဖတ်ပြီးပြီ။",
            },
            {
                furi: "かんじの　しゅくだいは　もう　やって　しまいました。",
                normal: "かんじの　しゅくだいは　もう　やって　しまいました。",
                en: "I have already done all my kanji homework.",
                mm: "ငါ kanji အိမ်စာအားလုံး ပြီးပြီ။",
            },
            {
                furi: "レポートは　｜＾今（こん）晩（ばん）｜　かいて　しまいます。。",
                normal: "レポートは　今晩 かいて　しまいます。",
                en: "I will finish the report tonight.",
                mm: "ဒီည အစီရင်ခံစာကို အပြီးရေးမယ်။",
            },
            {
                furi: "しりょうは　｜＾金（きん）曜（よう）日（び）｜に　つくって　しまいます。",
                normal: "しりょうは　金曜日に　つくって　しまいます。",
                en: "I will make the materials on Friday.",
                mm: "သောကြာနေ့ကျရင် ငါဒေတာတွေ အပြီးလုပ်မယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson29/renshua/4N29RSA3.mp3")
    },
    {
        sentence: [
            {
                furi: "｜＾財（さい）布（ふ）｜を　おとして　しまいました。",
                normal: "財布を　おとして　しまいました。",
                en: "I lost my wallet.",
                mm: "ကျွန်တော့်ပိုက်ဆံအိတ်ပျောက်သွားတယ်။",
            },
            {
                furi: "｜＾電（でん）話（わ）番（べん）号（ごう）｜を　まちがえて　しまいました。",
                normal: "電話番号を　まちがえて　しまいました。",
                en: "I made a mistake with the phone number.",
                mm: "ဖုန်းနံပါတ် မှားသွားတယ်။",
            },
            {
                furi: "パソコンは　こわれて　しまいました。",
                normal: "パソコンは　こわれて　しまいました。",
                en: "My computer is broken.",
                mm: "ငါ့ကွန်ပြူတာ　ပျက်သွားတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson29/renshua/4N29RSA4.mp3")
    },

    
];

/*
    {
        sentence: [
            {
                furi: "",
                normal: "",
                en: "",
                mm: "",
            },
        ],
        audioUrl: require("@assets/audio/testing.mp3")
    },




*/