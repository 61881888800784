export const reibun= [
    {
        sentence: [
            {
                furi: '＾渡辺（わたなべ）｜さんは　｜＾時（とき）｜々　｜＾大（おお）阪（さか）弁（べん）｜を　｜＾使（つか）｜いますね。',
                normal: '渡辺さんは　時々　大阪弁を　使いますね。',
                en: 'Mr.watanabe sometimes uses the Osaka dialect.',
                mm: 'Mr.watanabe သည် တစ်ခါတစ်ရံတွင် Osaka ဒေသစကားကို သုံးတယ်နော်။',
            },
            {
                furi: '＾大（おお）阪（さか）｜に　｜＾住（す）｜んで　｜！いたん｜ですか。',
                normal: '大阪に　住んで　いたんですか。',
                en: 'Did you live in Osaka?',
                mm: 'အိုဆာကာမှာနေခဲ့တာလား?',
            },
            {
                furi: '。。。ええ、１５｜＾歳（さい）｜まで　｜＾大（おお）阪（さか）｜に　｜＾住（す）｜んで　いました。',
                normal: '。。。ええ、１５歳まで　大阪に　住んで　いました。',
                en: '. . . Yes, I lived in Osaka until I was 15.',
                mm: '. . . ဟုတ်တယ်၊ အသက် ၁၅ နှစ်အထိ အိုဆာကာမှာ နေခဲ့တယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson26/bunpou/4N26RB1.mp3")
    },
    {
        sentence: [
            {
                furi: 'おもしろい　デザインの　｜＾靴（くつ）｜ですね。どこで　｜！買（か）ったん｜ですか。',
                normal: 'おもしろい　デザインの　靴ですね。どこで　買たんですか。',
                en: 'These shoes have an interesting design. Where did you buy it?',
                mm: 'ဖိနပ်ရဲ့ ဒီဇိုင်းက စိတ်ဝင်စားစရာကောင်းလိုက်တာ။ ဘယ်မှာဝယ်ခဲ့တာလဲ?',
            },
            {
                furi: '。。。エドヤストアで　｜＾買（か）｜いました。スペインの　｜＾靴（くつ）｜です。',
                normal: '。。。エドヤストアで　買いました。スペインの　靴です。',
                en: '. . . These are Spanish shoes that I bought at the Edoya store.',
                mm: '. . . Edoya စတိုးမှာ ဝယ်ခဲ့တဲ့ စပိန်ဖိနပ်ဖြစ်ပါသည်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson26/bunpou/4N26RB2.mp3")
    },
    {
        sentence: [
            {
                furi: 'どうして　｜！遅（おく）れたん｜ですか。',
                normal: 'どうして　遅れたんですか。',
                en: 'Why are you late?',
                mm: 'မင်းဘာလို့နောက်ကျနေတာလဲ?',
            },
            {
                furi: '。。。バスが　｜！来（こ）なかったん｜です。',
                normal: '。。。バスが　来なかったんです。',
                en: '. . . The bus didn\'t come.',
                mm: '. . . ဘတ်စ်ကားက မလာလို့ပါ။',
            },
        ],
        audioUrl: require("@assets/audio/lesson26/bunpou/4N26RB3.mp3")
    },
    {
        sentence: [
            {
                furi: 'よく　カラオケに　｜＾行（い）｜きますか。',
                normal: 'よく　カラオケに　行きますか。',
                en: 'Do you often go to karaoke?',
                mm: 'ကာရာအိုကေ သွားလေ့ရှိသလား?',
            },
            {
                furi: '。。。いいえ、あまり　｜＾行（い）｜きません。　カラオケは　｜！好（す）きじゃないん｜です。',
                normal: '。。。いいえ、あまり　行きません。　カラオケは　好きじゃないんです。',
                en: '. . . No, I don\'t go often. "I don\'t like karaoke."',
                mm: '. . . ဟင့်အင်း၊ ကာရာအိုကေမကြိုက်လို့ သိပ်ပြီးမသွားဖြစ်ပါဘူး။',
            },
        ],
        audioUrl: require("@assets/audio/lesson26/bunpou/4N26RB4.mp3")
    },
    {
        sentence: [
            {
                furi: '＾日（に）本（ほん）語（ご）｜で　レポートを　｜！書（か）いたん｜ですが、　ちょっと　｜＾見（み）｜て　いただけませんか。',
                normal: '日本語で　レポートを　書いたんですが、　ちょっと　見て　いただけませんか。',
                en: 'I wrote the report in Japanese, but could you take a look at it?',
                mm: 'ဂျပန်စာနဲ့ အစီအရင်ခံစာ ရေးထားတာမို့လို့ တစ်ချက်လောက်ကြည့်ပေးလို့ရမလား?',
            },
            {
                furi: '。。。いいですよ。',
                normal: '。。。いいですよ。',
                en: '. . . OK.',
                mm: 'ဟုတ်ကဲ့ရပါတယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson26/bunpou/4N26RB5.mp3")
    },
    {
        sentence: [
            {
                furi: '＾国（こっ）会（かい）議（ぎ）事（じ）堂（どう）｜を　｜！見（けん）学（がく）したいん｜ですが、　どう　したら　いいですか。',
                normal: '国会議事堂を　見学したいんですが、　どう　したら　いいですか。',
                en: 'I would like to visit the National Diet Building, what should I do?',
                mm: 'ပါလီမန်လွှတ်တော်ကို သွားကြည့်ချင်တယ်လို့ ဘယ်လိုလုပ်ရင်ကောင်းမလဲ?',
            },
            {
                furi: '。。。｜＾直（ちょく）接（せつ）｜　｜＾行（い）｜ったら　いいでよ。｜＾平（へい）日（じつ）｜は　いつでも　｜＾見（み）｜ることが　できます。',
                normal: '。。。直接　行ったら　いいでよ。平日は　いつでも　見ることが　できます。',
                en: '. . .It\'s fine if you go directly. You can watch it anytime on weekdays.',
                mm: '. . . တိုက်ရိုက်သွားရင်တော့ ကောင်းပါတယ်။ ကြားရက်တွေမှာဆိုရင် အချိန်မရွေး ကြည့်ရှုနိုင်ပါသည်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson26/bunpou/4N26RB6.mp3")
    },
]