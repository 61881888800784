import Icon from "@consts/icon";

export const lesson30 = {
    title: {
        furi: '＾非（ひ）常（じょう）袋（ぶくろ）｜を　｜＾準（じゅん）備（び）｜して　おかないと',
        normal: '非常袋を　準備して　おかないと',
        en: 'I have to prepare an emergency bag',
        mm: 'အရေးပေါ် အိတ်တစ်လုံး ပြင်ထားရမယ်',
        avatar:Icon
    },
    conversation: [
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'こんにちは。',
                    normal: 'こんにちは。',
                    en: 'Hello.',
                    mm: 'မင်္ဂလာပါ။',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'いらっしゃい。　さあ、どうぞ。',
                    normal: 'いらっしゃい。　さあ、どうぞ。',
                    en: 'welcome. Now go ahead.',
                    mm: 'ကြိုဆိုပါတယ်၊ အထဲကို ကြွပါ။',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '＾大（おお）｜きい　リュックが　｜＾置（お）｜いて　ありますね。',
                    normal: '大きい　リュックが　置いて　ありますね。',
                    en: 'There\'s a big rucksack.',
                    mm: 'အိတ်အကြီးကြီး ထားထားတယ်နော်။',
                },
                {
                    furi: '＾山（やま）｜へ　｜＾行（い）｜くんですか。',
                    normal: '山へ　行くんですか。',
                    en: 'Are you going to the mountains?',
                    mm: 'တောင်တွေဆီကို တက်ဖို့လား။',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'いいえ。｜＾非（ひ）常（じょう）袋（ふくろ）｜ですよ。',
                    normal: 'いいえ。非常袋ですよ。',
                    en: "no. It's an emergency bag.",
                    mm: 'မဟုတ်ဘူး အဲဒါ အရေးပေါ်အိတ်ပါ။',
                }
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '＾非（ひ）｜＾常（じょう）｜　＾袋（ふくろ）？｜＾何（なん）｜ですか。',
                    normal: '非常袋？何ですか。',
                    en: 'Emergency bag? what is it.',
                    mm: 'အရေးပေါ်အိတ်? ဘာတွေလဲ။',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: '＾非（ひ）常（じょう）時（じ）｜に　｜＾使（つか）｜う　｜＾物（もの）｜を　｜＾入（い）｜れて　おく　｜＾袋（ふくろ）｜です。',
                    normal: '非常時に　使う　物を　入れて　おく　袋です。',
                    en: "It's a bag to put things to use in an emergency.",
                    mm: 'အရေးပေါ်အခြေအနေမှာသုံးဖို့ ပစ္စည်းတွေ ထည့်ထားတဲ့ အိတ်တစ်ခုပါ။',
                },
                {
                    furi: '＾電（でん）気（き）｜や　ガスが　｜＾止（と）｜まっても、　｜＾３（みっ）日（か）｜ぐらい　｜＾生（せい）活（かつ）｜できる　｜＾物（もの）｜が　｜＾入（い）｜れて　あるんです。',
                    normal: '電気や　ガスが　止まっても、　３日ぐらい　生活できる　物が　入れて　あるんです。',
                    en: 'I have things in it that will allow me to live for about three days even if the electricity or gas stops.',
                    mm: 'လျှပ်စစ်မီး ဒါမှမဟုတ် ဂတ်စ်တွေ ပြတ်‌တောက်သွားတောင်မှ သုံးရက်လောက် အသက်ရှင်နိုင်စေမယ့် အရာတွေထည့်ထားတယ်။',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '＾水（むず）｜や　｜＾食（た）｜べ｜＾物（もの）｜ですか。',
                    normal: '水や　食べ物ですか。',
                    en: 'Is it water or food?',
                    mm: 'ရေနဲ့ စားစရာတွေလား။',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'ええ、ほかにも　いろいろ　ありますよ。',
                    normal: 'ええ、ほかにも　いろいろ　ありますよ。',
                    en: 'Yes, there are many other things.',
                    mm: 'အင်း၊ တခြားအရာတွေ အများကြီးရှိတယ်။',
                },
                {
                    furi: '＾懐（かい）中（ちゅう）電（でん）灯（とう）｜とか、ラジオとか　。。。',
                    normal: '懐中電灯とか、ラジオとか　。。。',
                    en: 'flashlight or radio',
                    mm: 'ဓာတ်မီး သို့မဟုတ် ရေဒီယို',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'わたしも　｜＾準（じゅん）備（び）｜して　おかないと。',
                    normal: 'わたしも　準備して　おかないと。',
                    en: 'I have to prepare too.',
                    mm: 'ကျွန်တော်လဲ မပြင်ဆင်ထားလို့ မဖြစ်တော့ဘူး။',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: '＾非（ひ）常（じょう）袋（ぶくろ）｜は　スーパーでも　｜＾売（う）｜って　いますよ。',
                    normal: '非常袋は　スーパーでも　売って　いますよ。',
                    en: 'Emergency bags are also sold at supermarkets.',
                    mm: 'အရေးပေါ်အိတ်တွေကို စူပါမားကတ်တွေမှာလည်း ရောင်းပါတယ်။',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'そうですか。じゃ、｜＾買（か）｜って　おきます。',
                    normal: 'そうですか。じゃ、買って　おきます。',
                    en: 'Is that so. Well then, I\'ll buy it.',
                    mm: 'ဟုတ်လား။ ဒါဆို ဝယ်ထားလိုက်ပါမယ်။',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson30/kaiwa/4N30KW.mp3")
}

