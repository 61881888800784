import Icon from "@consts/icon";
import { RootState } from "@store";
import { useAppSelector } from "@store/hooks";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
const MobileChildHeaderComponent = ({
    title = '',
    centerTitle = true,
    leadingIcon = false,
    leadingRoute = -1,
    setShowModel 
}: any) => {
    const navigate = useNavigate();
    const { fontSize,premium,pro } = useAppSelector(({ service }: RootState) => service);
    const LogoComponent = () => {
        return <div className="flex flex-row items-center h-[50px] overflow-hidden">
            <div className="flex flex-row items-center mr-2 ">
                <img src={Icon.Logo} className="w-[45px] h-[44px]"/>
            </div>
            <div className="flex flex-col justify-center  text-white ">
                <div className={`text-[19px] `}>Minna</div>
                <div className="text-[15px]">Master N4</div>
            </div>
        </div>
    }
    return ( 
        <>
            <div className="z-menu md:hidden fixed top-0 w-screen bg-navbar shadow-lg">
                <div className={`container mx-auto flex flex-row justify-between h-[70px] items-center text-black dark:text-white relative`}>
                    {/* stack icon */}                  
                    <LogoComponent />
                    <div onClick={premium || pro?()=>{}:() => setShowModel(true)}
                        style={{ boxShadow: "0px 4px 4px 0px #00000040" }}  className={`flex flex-row items-center btn h-[20px] text-white border-2 px-2 lg:px-5 py-1 rounded-[50px] shadow-md ${premium || pro? "bg-[#00E392] border-[#00E392]": "bg-yellow border-yellow"} `}>
                        <div className="mr-2 text-[15px]">Premium</div>
                        <div className="h-[16px] w-[16px]">
                            <img src={Icon.Premium} alt="Premium Logo" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default memo(MobileChildHeaderComponent);