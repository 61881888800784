import SizeBox from "@components/SizeBox";
import TestSubNavComponent from "@components/common/TestSubNavComponent";
import TitleComponent from "@components/common/TitleComponent";
import BunpouM1Component from "@components/test/BunpouM1Component";
import BunpouStarComponent from "@components/test/BunpouStarComponent";
import Mondai13Component from "@components/test/Mondai13Component";
import Mondai45Component from "@components/test/Mondai45Component";
import { Minna } from "@consts";
import { TopSpace } from "@consts/layout";
import { BunopouMondaiType, MondaiType } from "@consts/test";
import TestScreenLayout from "@layouts/test/TestScreenLayout";
import { Card } from "@material-tailwind/react";
import { ScreenPropType } from "App";
import { memo, useEffect, useState } from "react";
import { BsUiChecks } from "react-icons/bs";
const TestDetailScreen = ({ showModel,setShowModel,setUnlockPremium,selector, name, Logger, Helper, dispatch, setName, routeHistory, setRouteData, Routes, useNavigate, useLocation, lang, fontSize, dark, furigana, toggleFuri ,setPoint:setPointGlobal}: ScreenPropType) => {
    const [finish, setFinish] = useState<Boolean>(false);
    const [total,setTotal] = useState<number>(0);
    const [totalCorrect, setTotalCorrect] = useState<number>(0);
    const [point, setPoint] = useState<number>(30);
    const location = useLocation();
    const { id } = location.state;
    // moji
    const [mondai1, setMondai1] = useState<MondaiType>(Helper.deepClone(Minna.test[id].moji.mondai1));
    const [mondai2, setMondai2] = useState<MondaiType>(Helper.deepClone(Minna.test[id].moji.mondai2));
    const [mondai3, setMondai3] = useState<MondaiType>(Helper.deepClone(Minna.test[id].moji.mondai3));
    const [mondai4, setMondai4] = useState<MondaiType>(Helper.deepClone(Minna.test[id].moji.mondai4));
    const [mondai5, setMondai5] = useState<MondaiType>(Helper.deepClone(Minna.test[id].moji.mondai5));
    // bunpou
    const [bMondai1, setBMondai1] = useState<BunopouMondaiType>(Helper.deepClone(Minna.test[id].bunpou.mondai1));
    const [bMondai2, setBMondai2] = useState<BunopouMondaiType>(Helper.deepClone(Minna.test[id].bunpou.mondai2));
    const nabs = [
        {
            title: "Test",
            route: Routes.TEST,
        },
        {
            title: "Test"+(id+1),
            route: null,
            state: { id }
        }
    ];

    const navigate = useNavigate();
    const Result = () => {
        return <div className="flex flex-col items-center justify-center">
            <div className={`outline-navbar -rotate-[33deg] my-6 w-[120px] h-[120px] outline outline-offset-2 outline-8 rounded-full flex flex-col justify-center items-center`}>
                <div className="font-bold text-2xl ml-4 mb-2 flex flex-row items-center" >
                    <div className="text-navbar"> {totalCorrect}</div>
                    <span className="text-sm font-bold ml-1 text-navbar">marks</span>
                </div>
                <div className="w-[100px] h-1 bg-navbar"></div>
                <div className="font-bold text-2xl flex flex-row items-center">
                    <div className="text-navbar">{total}</div>
                    <span className="text-sm font-bold ml-1 text-navbar">marks</span>
                </div>
            </div>
            <div className={`text-xl mt-2 ${point > 18 ? 'text-green-500' : 'text-red-500 dark:text-red-400'}`}>
                Score: <span className="font-bold">{point}</span> points
            </div>
            <div className={`text-sm md:text-xl mt-2 mb-5 ${point > 18 ? 'text-green-500' : 'text-red-500 dark:text-red-400'}`}>
                {point>18?'🎉　おめでとうございました！　🎉': '💔　ごめんね、あきらめないで、やりなおして！　💔'}
            </div>
        </div>
    }

    const FinishBtn = () => {
        return <Card className="z-menu fixed container mx-auto flex flex-row justify-end h-[0.01px] bottom-[100px] left-0 w-full">
            {
                !finish?<Card
                onClick={onFinish}
                style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
                className="absolute bottom-0 text-lg bg-navbar text-white px-[20px] py-[5px] rounded-full btn flex flex-row justify-center items-center ">
                Finish
                <BsUiChecks className="ml-2"/>
                </Card>
                : <Card
                    onClick={onFinish}
                    style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
                    className="absolute bottom-0 text-lg bg-navbar text-white px-[20px] py-[5px] rounded-full btn flex flex-row justify-end items-center ">
                    Go Back
                </Card>
            }
        </Card>
    }
    const onFinish = () => {
        if (!finish) {
            let total = 0;
            let totalCorrect = 0;
            window.scrollTo(0, 0);
            mondai1.questions.map(({ correct, pick }) => {
                total += 1;
                if (correct === pick) totalCorrect += 1;
            });
            mondai2.questions.map(({ correct, pick }) => {
                total += 1;
                if (correct === pick) totalCorrect += 1;

            })
            mondai3.questions.map(({ correct, pick }) => {
                total += 1;
                if (correct === pick) totalCorrect += 1;

            })
            mondai4.questions.map(({ correct, pick }) => {
                total += 1;
                if (correct === pick) totalCorrect += 1;

            })
            mondai5.questions.map(({ correct, pick }) => {
                total += 1;
                if (correct === pick) totalCorrect += 1;

            })
            bMondai1.questions.map(({ correct, pick }) => {
                total += 1;
                if (correct === pick) totalCorrect += 1;

            })
            bMondai2.questions.map(({ correct, pick }) => {
                total += 1;
                if (correct === pick) totalCorrect += 1;

            })
            // Minna.test[id].key

            setTotal(total);
            setTotalCorrect(totalCorrect);
            // total = 60pt
            // totalCorrect =?
            setFinish(true);
            const point = Math.floor((60 * totalCorrect) / total);
            setPoint(point);
            setPointGlobal({ key: Minna.test[id].key, point });
        } else {
            navigate(Routes.TEST);
        }
    }
    useEffect(() => {
        if (routeHistory.test.route != Routes.TEST_DETAIL) {
            setRouteData({
                test: {
                    state: { id },
                    route: Routes.TEST_DETAIL
                }
            })
        }
    })


    return <TestScreenLayout
        body={
            <div className="container mx-auto relative">
                <TopSpace/>
                <FinishBtn />
                <TestSubNavComponent
                    nabs={nabs} lesson={1} style="py-2 pl-2 pr-4" />
                <SizeBox vertical="h-[20px]" />
                {finish && <Result />}
                {true&&<div>
                    <TitleComponent 
                        furigana="true"
                        furi="＾文（も）字（じ）｜・｜＾語（ご）彙（い）"
                        normal=""
                        furiTranslate={Helper.furiTranslate}
                        fontSize={fontSize}
                        lesson="" />
                    <SizeBox vertical="h-[60px]" />
                    {/* Mondai1 */}
                    <Mondai13Component
                        mondai={mondai1}
                        setMondai={setMondai1}
                        fontSize={fontSize}
                        furigana="true"
                        finish={finish}
                    />
                    {/* Mondai2 */}
                    <Mondai13Component
                        mondai={mondai2}
                        setMondai={setMondai2}
                        fontSize={fontSize}
                        furigana="true"
                        finish={finish}
                    />
                    {/* Mondai3 */}
                    <Mondai13Component
                        mondai={mondai3}
                        setMondai={setMondai3}
                        fontSize={fontSize}
                        furigana="true"
                        finish={finish}
                    />
                    {/* Mondai4 */}
                    <Mondai45Component
                        mondai={mondai4}
                        setMondai={setMondai4}
                        fontSize={fontSize}
                        furigana="false"
                        finish={finish}
                    />
                    {/* Mondai5 */}
                    <Mondai45Component
                        mondai={mondai5}
                        setMondai={setMondai5}
                        fontSize={fontSize}
                        furigana="false"
                        finish={finish}
                    />
                </div>}
                {/* Bunpou */}
                <TitleComponent
                    furigana="true"
                    furi="＾文（ぶん）｜・｜＾法（ぽう）"
                    normal=""
                    furiTranslate={Helper.furiTranslate}
                    fontSize={fontSize}
                    lesson="" />
                <SizeBox vertical="h-[60px]" />
                <BunpouM1Component
                    mondai={bMondai1}
                    setMondai={setBMondai1}
                    fontSize={fontSize}
                    furigana="true"
                    finish={finish}
                />
                <BunpouStarComponent
                    mondai={bMondai2}
                    setMondai={setBMondai2}
                    fontSize={fontSize}
                    furigana="false"
                    finish={finish}
                />
            </div>
        }
        Routes={Routes}
        Logger={Logger}
        useNavigate={useNavigate}
        showModel={showModel}
        setShowModel={setShowModel}
        setUnlockPremium={setUnlockPremium}
    />
}
export default memo(TestDetailScreen);