export const explain = [
    // ＾友（とも）達（だち）｜
    {
        pattern_furi: 'お＋Vます⇒お＋Vになります',
        pattern_normal: 'お＋Vます⇒お＋Vになります',
        en: [
            '　A more polite use of the Main Verb',
            ],
        mm: [
            '　Main Verb ကို ပိုမိုယဉ်ကျေးစွာ သုံးသောအသုံး',
        ]
    },
    {
        pattern_furi: 'お＋Vます⇒お＋ください',
        pattern_normal: 'お＋Vます⇒お＋ください',
        en: [
            '　A more polite use of the Main Verb',
        ],
        mm: [
            '　Main Verb ကို ပိုမိုယဉ်ကျေးစွာ သုံးသောအသုံး',
        ]
    },
    {
        pattern_furi: 'お+｜＾和（わ）語（ご）｜N',
        pattern_normal: 'お+和語N',
        en: [
            '　It is used by placing 「お」 in front of some Japanese phonetic characters.',
        ],
        mm: [
            '　တချို့ဂျပန်အသံထွက် စာလုံးများ၏ရှေ့တွင် 「お」ကိုထား၍ သုံးသည်။',
        ]
    },
    {
        pattern_furi: 'ご+｜＾漢（かん）語（ご）｜N ',
        pattern_normal: 'ご+漢語(かんご)',
        en: [
            '　It is used by placing 「ご」 in front of some Chinese phonetic characters.',
        ],
        mm: [
            '　တချို့တရုတ်အသံထွက် စာလုံးများ၏ရှေ့တွင် 「ご」ကိုထား၍ သုံးသည်။',
        ]
    },
    {
        pattern_furi: 'お＋Vます＋です ',
        pattern_normal: 'お＋Vます＋です',
        en: [
            '　A more polite use of the Main Verb',
        ],
        mm: [
            '　Main Verb ကို ပိုမိုယဉ်ကျေးစွာ သုံးသောအသုံး',
        ]
    },
]