import Icon from "@consts/icon";
import Helper from "@helper";
import { Card } from "@material-tailwind/react";
import { memo } from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const LessonCard = ({ setShowModel, starComponent, isLock,premium, cardAction, Lock, fontSize, id, percent,dark }:any) => {
    // console.log(typeof setShowModel);

    const onClickHandler = () => {
        if (premium || !isLock) {
            cardAction({ id })
        } else {
            // todo: go unlock page
            setShowModel(true);
        }
    }
    const containerStyle = {
        box: 'flex flex-col items-center justify-center z-bottom',
        layout: 'py-[10px] md:py-[12px] hover:scale-110 transition duration-500 ',
        design: 'rounded-[10px] btn dark-shadow',
        // card-light-shadow dark-shadow
        lockTheme: !premium && isLock ?'bg-[#D9D9D9] bg-opacity-30 dark:border-[#C8C8C8] dark:border-opacity-25 dark:bg-opacity-25 dark:bg-[#C8C8C8]'
            : 'bg-[#FFFFFF] dark:bg-[#001E34] dark:border-[#001E34]  '
    }
    return <Card
            onClick={onClickHandler}
            className={Helper.pushStyle(containerStyle)}
        >
        <div className="text-lg relative w-full">
            { !premium && isLock && <img src={Icon.Lock} width={22} height={22} className="absolute right-3 top-0" />}
            <div className="flex flex-row justify-center text-[#242424] dark:text-[#FFFFFF]">
                <div className="mr-2">Lesson</div>
                <div>{id + 26}</div>
            </div>
        </div>
        <div className="w-[40%] z-body my-2">
            <CircularProgressbar
                value={percent}
                text={percent + '%'}
                strokeWidth={11}
                styles={buildStyles({
                    // Rotation of path and trail, in number of turns (0-1)
                    rotation: 0,

                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: 'butt',

                    // Text size
                    textSize: '25px',

                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,

                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',

                    // Colors //Green: #00FA9A Yellow:	#FFFF00 Red: #DC143C
                    pathColor: "#0084E8",
                    textColor: dark === 'dark'? "#C8C8C8":"#424242",
                    trailColor: '#d6d6d6',
                    backgroundColor: dark === 'dark' ? '#FFFFFF' : '#D9D9D9',
                })}
            />
        </div>
        <div className={`flex flex-row justify-center items-center mb-[15px]`}>
            {/* 15,30 45,60 75,90 */}
            <div className="">{starComponent({ half: 15, full: 30 })}</div>
            <div className="-mb-[20px] mx-3">{starComponent({ half: 45, full: 60 })}</div>
            <div className="">{starComponent({ half: 75, full: 90 })}</div>
        </div>
    </Card>
}
export default memo(LessonCard);