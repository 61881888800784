export const lesson35 = [
    {
        "furi": "＾咲（さ）｜きます",
        "normal": "咲きます",
        "en": "bloom",
        "remark_en": "",
        "mm": "ပွင့်သည်",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO1.mp3")
    },
    {
        "furi": "＾変（か）｜わります",
        "normal": "変わります",
        "en": "change",
        "remark_en": "",
        "mm": "ပြောင်းလဲသည်",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO2.mp3")
    },
    {
        "furi": "＾困（こま）｜ります",
        "normal": "困ります",
        "en": "be in trouble, have a problem",
        "remark_en": "",
        "mm": "အခက်တွေ့သည်",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO3.mp3")
    },
    {
        "furi": "＾付（つ）｜けます",
        "normal": "付けます",
        "en": "draw, mark",
        "remark_en": "",
        "mm": "အမှတ်အသားပြုသည်",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO4.mp3")
    },
    {
        "furi": "＾治（なお）｜ります",
        "normal": "治ります",
        "en": "recover,cured",
        "remark_en": "",
        "mm": "ပြန်ကောင်းသည်",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO5.mp3")
    },
    {
        "furi": "クリックします",
        "normal": "クリックします",
        "en": "do click",
        "remark_en": "",
        "mm": "ကလစ်သည်(ကွန်ပျူတာ)",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO6.mp3")
    },
    {
        "furi": "＾入（にゅう）力（りょく）｜します",
        "normal": "入力します",
        "en": "input data",
        "remark_en": "",
        "mm": "အချက်အလက်သွင်းသည်(ကွန်ပျူတာ)",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO7.mp3")
    },
    {
        "furi": "＾正（ただ）｜しい",
        "normal": "正しい",
        "en": "correct, right",
        "remark_en": "",
        "mm": "မှန်ကန်သော",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO8.mp3")
    },
    {
        "furi": "＾向（む）｜こう",
        "normal": "向こう",
        "en": "over there, the other side",
        "remark_en": "",
        "mm": "အခြားတစ်ဖက်",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO9.mp3")
    },
    {
        "furi": "＾島（しま）",
        "normal": "島",
        "en": "island",
        "remark_en": "",
        "mm": "ကျွန်း၊ သောင်",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO10.mp3")
    },
    {
        "furi": "＾港（みなと）",
        "normal": "港",
        "en": "harbor",
        "remark_en": "",
        "mm": "ဆိပ်ကမ်း",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO11.mp3")
    },
    {
        "furi": "＾近（きん）所（じょ）",
        "normal": "近所",
        "en": "neighborhood",
        "remark_en": "",
        "mm": "အနီးနား",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO12.mp3")
    },
    {
        "furi": "＾屋（おく）上（じょう）",
        "normal": "屋上",
        "en": "rooftop",
        "remark_en": "",
        "mm": "ခေါင်မိုးပေါ်",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO13.mp3")
    },
    {
        "furi": "＾海（かい）外（がい）",
        "normal": "海外",
        "en": "overseas",
        "remark_en": "",
        "mm": "နိုင်ငံခြား၊ပြည်ပ",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO14.mp3")
    },
    {
        "furi": "＾山（やま）登（のぼ）｜り",
        "normal": "山登り",
        "en": "mountain climbing",
        "remark_en": "",
        "mm": "တောင်တက်ခြင်း",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO15.mp3")
    },
    {
        "furi": "れきし",
        "normal": "れきし",
        "en": "History",
        "remark_en": "",
        "mm": "သမိုင်း",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO16.mp3")
    },
    {
        "furi": "＾機（き）会（かい）",
        "normal": "機会",
        "en": "chance, opportunity",
        "remark_en": "",
        "mm": "အခွင့်အရေး",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO17.mp3")
    },
    {
        "furi": "＾許（きょ）可（か）",
        "normal": "許可",
        "en": "permission",
        "remark_en": "",
        "mm": "အခွင့်၊ အမိန့်",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO18.mp3")
    },
    {
        "furi": "＾丸（まる）",
        "normal": "丸",
        "en": "circle",
        "remark_en": "",
        "mm": "အဝိုင်း",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO19.mp3")
    },
    {
        "furi": "ふりがな",
        "normal": "ふりがな",
        "en": "furigana",
        "remark_en": "",
        "mm": "အသံထွက်(ခန်းဂျီး)",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO20.mp3")
    },
    {
        "furi": "せつび",
        "normal": "せつび",
        "en": "equipment, facilities",
        "remark_en": "",
        "mm": "အပြင်အဆင်",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO21.mp3")
    },
    {
        "furi": "レバー",
        "normal": "レバー",
        "en": "lever",
        "remark_en": "",
        "mm": "လီဘာတံ",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO22.mp3")
    },
    {
        "furi": "キー",
        "normal": "キー",
        "en": "key",
        "remark_en": "",
        "mm": "ခလုတ်",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO23.mp3")
    },
    {
        "furi": "カーテン",
        "normal": "カーテン",
        "en": "curtain",
        "remark_en": "",
        "mm": "ကာတင်၊လိုက်ကာ",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO24.mp3")
    },
    {
        "furi": "ひも",
        "normal": "ひも",
        "en": "string, rope",
        "remark_en": "",
        "mm": "ကြိုး",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO25.mp3")
    },
    {
        "furi": "＾炊（すい）飯（はん）器（き）",
        "normal": "炊飯器",
        "en": "rice cooker",
        "remark_en": "",
        "mm": "ထမင်းပေါင်းအိုး",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO26.mp3")
    },
    {
        "furi": "＾葉（は）",
        "normal": "葉",
        "en": "leaf",
        "remark_en": "",
        "mm": "သစ်ရွက်",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO27.mp3")
    },
    {
        "furi": "＾昔（むかし）",
        "normal": "昔",
        "en": "long ago",
        "remark_en": "",
        "mm": "ဟိုးရှေးရှေး",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO28.mp3")
    },
    {
        "furi": "もっと",
        "normal": "もっと",
        "en": "more",
        "remark_en": "",
        "mm": "ပို၍",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO29.mp3")
    },
    {
        "furi": "これで｜＾終（お）｜わりましょう。",
        "normal": "これで終わりましょう。",
        "en": "Let's end with this.",
        "remark_en": "",
        "mm": "ဒီမှာ(တင်)ပဲအဆုံးသတ်ကြစို့",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO30.mp3")
    },
    {
        "furi": "＾箱（はこ）根（ね）",
        "normal": "箱根",
        "en": "Hakone",
        "remark_en": "",
        "mm": "ဟခိုးနဲ",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO31.mp3")
    },
    {
        "furi": "＾日（にっ）光（こう）",
        "normal": "日光",
        "en": "sunlight",
        "remark_en": "",
        "mm": "နီကိုး",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO32.mp3")
    },
    {
        "furi": "アフリカ",
        "normal": "アフリカ",
        "en": "Africa",
        "remark_en": "",
        "mm": "အာဖရိက",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO33.mp3")
    },
    {
        "furi": "マンガミュージアム",
        "normal": "マンガミュージアム",
        "en": "Manga Museum",
        "remark_en": "",
        "mm": "ကာတွန်းပြတိုက်",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO34.mp3")
    },
    {
        "furi": "＾皆（みんな）｜の｜＾学（がっ）｜＾校（こう）",
        "normal": "皆の学校",
        "en": "Everyone's school",
        "remark_en": "",
        "mm": "စာသင်ကျောင်း(အမည်)",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO35.mp3")
    },
    {
        "furi": "＾大（だい）黒（こく）｜ずし",
        "normal": "大黒ずし",
        "en": "Daikoku Sushi(name)",
        "remark_en": "",
        "mm": "ဆူရှီဆိုင်(အမည်)",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO36.mp3")
    },
    {
        "furi": "IMCパソコン｜＾教（きょう）｜＾室（しつ）",
        "normal": "IMCパソコン教室",
        "en": "IMC PC classroom",
        "remark_en": "",
        "mm": "သင်တန်း(အမည်)",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO37.mp3")
    },
    {
        "furi": "＾母（はは）｜の｜＾味（あじ）",
        "normal": "母の味",
        "en": "Book(name)",
        "remark_en": "",
        "mm": "စာအုပ်(အမည်)",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO38.mp3")
    },
    {
        "furi": "はる",
        "normal": "はる",
        "en": "harus",
        "remark_en": "",
        "mm": "ဟရု(ဇတ်ကောင်အမည်)",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO39.mp3")
    },
    {
        "furi": "＾佐（さ）藤（とう）歯（し）科（か）",
        "normal": "佐藤歯科",
        "en": "hospital(name)",
        "remark_en": "",
        "mm": "ဆေးရုံ(အမည်)",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO40.mp3")
    },
    {
        "furi": "＾毎（まい）日（にち）｜クッキング",
        "normal": "毎日クッキング",
        "en": "Cooking course(name)",
        "remark_en": "",
        "mm": "ဟင်ချက်သင်းတန်း(အမည်)",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO41.mp3")
    },
    {
        "furi": "それなら",
        "normal": "それなら",
        "en": "in that case",
        "remark_en": "",
        "mm": "အဲဒါဆို",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO42.mp3")
    },
    {
        "furi": "＾夜（や）行（こう）｜バス",
        "normal": "夜行バス",
        "en": "night bus",
        "remark_en": "",
        "mm": "ညကား",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO43.mp3")
    },
    {
        "furi": "さあ",
        "normal": "さあ",
        "en": "here we go",
        "remark_en": "",
        "mm": "အင်း",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO44.mp3")
    },
    {
        "furi": "＾旅（りょ）行（こう）者（しゃ）",
        "normal": "旅行者",
        "en": "travel agency",
        "remark_en": "",
        "mm": "ခရီးသွားကုမ္ပဏီ",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO45.mp3")
    },
    {
        "furi": "＾詳（くわ）｜しい",
        "normal": "詳しい",
        "en": "detailed",
        "remark_en": "",
        "mm": "အသေးစိတ်",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO46.mp3")
    },
    {
        "furi": "スキー｜＾場（じょう）",
        "normal": "スキー場",
        "en": "ski resort",
        "remark_en": "",
        "mm": "စကီးကွင်း",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO47.mp3")
    },
    {
        "furi": "＾草（くさ）津（つ）",
        "normal": "草津",
        "en": "place(name)",
        "remark_en": "",
        "mm": "ခုဆဆု(အမည်)",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO48.mp3")
    },
    {
        "furi": "＾志（し）賀（が）高（こう）原（げん）",
        "normal": "志賀高原",
        "en": "place(name)",
        "remark_en": "",
        "mm": "ရှီဂကုန်းပြင်မြင့်",
        "remark_mm": "",
        "group": 0,
        "audioUrl": require("@assets/audio/lesson35/vocab/4N35VO49.mp3")
    },
    {
        "furi": "＾朱（しゅ）",
        "normal": "朱",
        "en": "Vermilion",
        "remark_en": "",
        "mm": "ဟင်္သပဒါး",
        "remark_mm": "",
        "group": 0,
        "audioUrl": null
    },
    {
        "furi": "＾交（まじ）｜わります",
        "normal": "交わります",
        "en": "keep company with",
        "remark_en": "",
        "mm": "ပေါင်းသင်းဆက်ဆံသည်",
        "remark_mm": "",
        "group": 0,
        "audioUrl": null
    },
    {
        "furi": "ことわざ",
        "normal": "ことわざ",
        "en": "proverb",
        "remark_en": "",
        "mm": "စကားပုံ",
        "remark_mm": "",
        "group": 0,
        "audioUrl": null
    },
    {
        "furi": "＾関（かん）係（けい）",
        "normal": "関係",
        "en": "relationship",
        "remark_en": "",
        "mm": "ပတ်သက်မှု",
        "remark_mm": "",
        "group": 0,
        "audioUrl": null
    },
    {
        "furi": "＾仲（なか）｜よくします",
        "normal": "仲よくします",
        "en": "be on good terms with",
        "remark_en": "",
        "mm": "တည့်အောင်နေသည်",
        "remark_mm": "",
        "group": 0,
        "audioUrl": null
    },
    {
        "furi": "必要[な]",
        "normal": "必要[な]",
        "en": "neccessary, essential",
        "remark_en": "",
        "mm": "လိုအပ်သော",
        "remark_mm": "",
        "group": 0,
        "audioUrl": null
    }
]

/*
    {
        furi: "",
        normal: "",
        en: "",
        remark_en: "",
        mm: "",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson29/vocab/4N29VO1.mp3")
    },


*/
const test = [
  {
    'goi': '咲（さ）」きます',
    'unicode': 'ပွင့်သည်',
    'zawgyi': 'ပြင့္သည္',
    'english': 'bloom',
    'long': false
  },
  {
    'goi': '変（か）」わります',
    'unicode': 'ပြောင်းလဲသည်',
    'zawgyi': 'ေျပာင္းလဲသည္',
    'english': 'change',
    'long': false
  },
  {
    'goi': '困（こま）」ります',
    'unicode': 'အခက်တွေ့သည်',
    'zawgyi': 'အခက္ေတြ႕သည္',
    'english': 'be in trouble, have a problem',
    'long': false
  },
  {
    'goi': '付（つ）」けます',
    'unicode': 'အမှတ်အသားပြုသည်',
    'zawgyi': 'အမွတ္အသားျပဳသည္',
    'english': 'draw, mark',
    'long': false
  },
  {
    'goi': '治（なお）」ります',
    'unicode': 'ပြန်ကောင်းသည်',
    'zawgyi': 'ျပန္ေကာင္းသည္',
    'english': 'recover,cured',
    'long': false
  },
  {
    'goi': 'クリックします',
    'unicode': 'ကလစ်သည်(ကွန်ပျူတာ)',
    'zawgyi': 'ကလစ္သည္(ကြန္ပ်ဴတာ)',
    'english': 'do click',
    'long': false
  },
  {
    'goi': '入（にゅう）」力（りょく）」します',
    'unicode': 'အချက်အလက်သွင်းသည်(ကွန်ပျူတာ)',
    'zawgyi': 'အခ်က္အလက္သြင္းသည္(ကြန္ပ်ဴတာ)',
    'english': 'input data',
    'long': true
  },
  {
    'goi': '正（ただ）」しい',
    'unicode': 'မှန်ကန်သော',
    'zawgyi': 'မွန္ကန္ေသာ',
    'english': 'correct, right',
    'long': false
  },
  {
    'goi': '向（む）」こう',
    'unicode': 'အခြားတစ်ဖက်',
    'zawgyi': 'အျခားတစ္ဖက္',
    'english': 'over there, the other side',
    'long': false
  },
  {
    'goi': '島（しま）',
    'unicode': 'ကျွန်း၊ သောင်',
    'zawgyi': 'ကၽြန္း၊ ေသာင္',
    'english': 'island',
    'long': false
  },
  {
    'goi': '港（みなと）',
    'unicode': 'ဆိပ်ကမ်း',
    'zawgyi': 'ဆိပ္ကမ္း',
    'english': 'harbor',
    'long': false
  },
  {
    'goi': '近（きん）」所（じょ）',
    'unicode': 'အနီးနား',
    'zawgyi': 'အနီးနား',
    'english': 'neighborhood',
    'long': false
  },
  {
    'goi': '屋（おく）」上（じょう）',
    'unicode': 'ခေါင်မိုးပေါ်',
    'zawgyi': 'ေခါင္မိုးေပၚ',
    'english': 'rooftop',
    'long': false
  },
  {
    'goi': '海（かい）」外（がい）',
    'unicode': 'နိုင်ငံခြား၊ပြည်ပ',
    'zawgyi': 'နိုင္ငံျခား၊ျပည္ပ',
    'english': 'overseas',
    'long': false
  },
  {
    'goi': '山（やま）」登（のぼ）」り',
    'unicode': 'တောင်တက်ခြင်း',
    'zawgyi': 'ေတာင္တက္ျခင္း',
    'english': 'mountain climbing',
    'long': false
  },
  {
    'goi': 'れきし',
    'unicode': 'သမိုင်း',
    'zawgyi': 'သမိုင္း',
    'english': 'History',
    'long': false
  },
  {
    'goi': '機（き）」会（かい）',
    'unicode': 'အခွင့်အရေး',
    'zawgyi': 'အခြင့္အေရး',
    'english': 'chance, opportunity',
    'long': false
  },
  {
    'goi': '許（きょ）」可（か）',
    'unicode': 'အခွင့်၊ အမိန့်',
    'zawgyi': 'အခြင့္၊ အမိန႔္',
    'english': 'permission',
    'long': false
  },
  {
    'goi': '丸（まる）',
    'unicode': 'အဝိုင်း',
    'zawgyi': 'အဝိုင္း',
    'english': 'circle',
    'long': false
  },
  {
    'goi': 'ふりがな',
    'unicode': 'အသံထွက်(ခန်းဂျီး)',
    'zawgyi': 'အသံထြက္(ခန္းဂ်ီး)',
    'english': 'furigana',
    'long': false
  },
  {
    'goi': 'せつび',
    'unicode': 'အပြင်အဆင်',
    'zawgyi': 'အျပင္အဆင္',
    'english': 'equipment, facilities',
    'long': false
  },
  {
    'goi': 'レバー',
    'unicode': 'လီဘာတံ',
    'zawgyi': 'လီဘာတံ',
    'english': 'lever',
    'long': false
  },
  {
    'goi': 'キー',
    'unicode': 'ခလုတ်',
    'zawgyi': 'ခလုတ္',
    'english': 'key',
    'long': false
  },
  {
    'goi': 'カーテン',
    'unicode': 'ကာတင်၊လိုက်ကာ',
    'zawgyi': 'ကာတင္၊လိုက္ကာ',
    'english': 'curtain',
    'long': false
  },
  {
    'goi': 'ひも',
    'unicode': 'ကြိုး',
    'zawgyi': 'ႀကိဳး',
    'english': 'string, rope',
    'long': false
  },
  {
    'goi': '炊（すい）」飯（はん）」器（き）',
    'unicode': 'ထမင်းပေါင်းအိုး',
    'zawgyi': 'ထမင္းေပါင္းအိုး',
    'english': 'rice cooker',
    'long': false
  },
  {
    'goi': '葉（は）',
    'unicode': 'သစ်ရွက်',
    'zawgyi': 'သစ္ရြက္',
    'english': 'leaf',
    'long': false
  },
  {
    'goi': '昔（むかし）',
    'unicode': 'ဟိုးရှေးရှေး',
    'zawgyi': 'ဟိုးေရွးေရွး',
    'english': 'long ago',
    'long': false
  },
  {
    'goi': 'もっと',
    'unicode': 'ပို၍',
    'zawgyi': 'ပို၍',
    'english': 'more',
    'long': false
  },
  {
    'goi': 'これで」終（お）」わりましょう。',
    'unicode': 'ဒီမှာ(တင်)ပဲအဆုံးသတ်ကြစို့',
    'zawgyi': 'ဒီမွာ(တင္)ပဲအဆုံးသတ္ၾကစို႔',
    'english': 'Let\'s end with this.',
    'long': true
  },
  {
    'goi': '箱（はこ）」根（ね）',
    'unicode': 'ဟခိုးနဲ',
    'zawgyi': 'ဟခိုးနဲ',
    'english': 'Hakone',
    'long': false
  },
  {
    'goi': '日（にっ）」光（こう）',
    'unicode': 'နီကိုး',
    'zawgyi': 'နီကိုး',
    'english': 'sunlight',
    'long': false
  },
  {
    'goi': 'アフリカ',
    'unicode': 'အာဖရိက',
    'zawgyi': 'အာဖရိက',
    'english': 'Africa',
    'long': false
  },
  {
    'goi': 'マンガミュージアム',
    'unicode': 'ကာတွန်းပြတိုက်',
    'zawgyi': 'ကာတြန္းျပတိုက္',
    'english': 'Manga Museum',
    'long': false
  },
  {
    'goi': '皆（みんな）」の」学（がっ）」校（こう）',
    'unicode': 'စာသင်ကျောင်း(အမည်)',
    'zawgyi': 'စာသင္ေက်ာင္း(အမည္)',
    'english': 'Everyone\'s school',
    'long': false
  },
  {
    'goi': '大（だい）」黒（こく）」ずし',
    'unicode': 'ဆူရှီဆိုင်(အမည်)',
    'zawgyi': 'ဆူရွီဆိုင္(အမည္)',
    'english': 'Daikoku Sushi(name)',
    'long': false
  },
  {
    'goi': 'IMCパソコン」教（きょう）」室（しつ）',
    'unicode': 'သင်တန်း(အမည်)',
    'zawgyi': 'သင္တန္း(အမည္)',
    'english': 'IMC PC classroom',
    'long': false
  },
  {
    'goi': '母（はは）」の」味（あじ）',
    'unicode': 'စာအုပ်(အမည်)',
    'zawgyi': 'စာအုပ္(အမည္)',
    'english': 'Book(name)',
    'long': false
  },
  {
    'goi': 'はる',
    'unicode': 'ဟရု(ဇတ်ကောင်အမည်)',
    'zawgyi': 'စာအုပ္(အမည္)',
    'english': 'harus',
    'long': false
  },
  {
    'goi': '佐（さ）」藤（とう）」歯（し）」科（か）',
    'unicode': 'ဆေးရုံ(အမည်)',
    'zawgyi': 'ေဆး႐ုံ(အမည္)',
    'english': 'hospital(name)',
    'long': false
  },
  {
    'goi': '毎（まい）」日（にち）」クッキング',
    'unicode': 'ဟင်ချက်သင်းတန်း(အမည်)',
    'zawgyi': 'ဟင္ခ်က္သင္းတန္း(အမည္)',
    'english': 'Cooking course(name)',
    'long': false
  },
  {
    'goi': 'それなら',
    'unicode': 'အဲဒါဆို',
    'zawgyi': 'အဲဒါဆို',
    'english': 'in that case',
    'long': false
  },
  {
    'goi': '夜（や）」行（こう）」バス',
    'unicode': 'ညကား',
    'zawgyi': 'ညကား',
    'english': 'night bus',
    'long': false
  },
  {
    'goi': 'さあ',
    'unicode': 'အင်း',
    'zawgyi': 'အင္း',
    'english': 'here we go',
    'long': false
  },
  {
    'goi': '旅（りょ）」行（こう）」者（しゃ）',
    'unicode': 'ခရီးသွားကုမ္ပဏီ',
    'zawgyi': 'ခရီးသြားကုမၸဏီ',
    'english': 'travel agency',
    'long': false
  },
  {
    'goi': '詳（くわ）」しい',
    'unicode': 'အသေးစိတ်',
    'zawgyi': 'အေသးစိတ္',
    'english': 'detailed',
    'long': false
  },
  {
    'goi': 'スキー」場（じょう）',
    'unicode': 'စကီးကွင်း',
    'zawgyi': 'စကီးကြင္း',
    'english': 'ski resort',
    'long': false
  },
  {
    'goi': '草（くさ）」津（つ）',
    'unicode': 'ခုဆဆု(အမည်)',
    'zawgyi': 'ခုဆဆု(အမည္)',
    'english': 'place(name)',
    'long': false
  },
  {
    'goi': '志（し）」賀（が）」高（こう）」原（げん）',
    'unicode': 'ရှီဂကုန်းပြင်မြင့်',
    'zawgyi': 'ရွီဂကုန္းျပင္ျမင့္',
    'english': 'place(name)',
    'long': false
  },
  {
    'goi': '朱（しゅ）',
    'unicode': 'ဟင်္သပဒါး',
    'zawgyi': 'ဟသၤပဒါး',
    'english': 'Vermilion',
    'long': false
  },
  {
    'goi': '交（まじ）」わります',
    'unicode': 'ပေါင်းသင်းဆက်ဆံသည်',
    'zawgyi': 'ေပါင္းသင္းဆက္ဆံသည္',
    'english': 'keep company with',
    'long': false
  },
  {
    'goi': 'ことわざ',
    'unicode': 'စကားပုံ',
    'zawgyi': 'စကားပုံ',
    'english': 'proverb',
    'long': false
  },
  {
    'goi': '関（かん）」係（けい）',
    'unicode': 'ပတ်သက်မှု',
    'zawgyi': 'ပတ္သက္မႈ',
    'english': 'relationship',
    'long': false
  },
  {
    'goi': '仲（なか）」よくします',
    'unicode': 'တည့်အောင်နေသည်',
    'zawgyi': 'တည့္ေအာင္ေနသည္',
    'english': 'be on good terms with',
    'long': false
  },
  {
    'goi': '必要[な]',
    'unicode': 'လိုအပ်သော',
    'zawgyi': 'လိုအပ္ေသာ',
    'english': 'neccessary, essential',
    'long': false
  }
]