export const lesson42 = [
  {
      "furi": "＾包（つつ）｜みます",
      "normal": "包みます",
      "en": "wrap",
      "remark_en": "",
      "mm": "ထုပ်ပိုးသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO1.mp3")
  },
  {
      "furi": "＾沸（わ）｜かします",
      "normal": "沸かします",
      "en": "boil",
      "remark_en": "",
      "mm": "ဆူအောင်တည်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO2.mp3")
  },
  {
      "furi": "＾混（ま）｜ぜます",
      "normal": "混ぜます",
      "en": "mix",
      "remark_en": "",
      "mm": "ရောသည်၊မွှေသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO3.mp3")
  },
  {
      "furi": "＾計（けい）算（さん）｜します",
      "normal": "計算します",
      "en": "calculate",
      "remark_en": "",
      "mm": "တွက်ချက်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO4.mp3")
  },
  {
      "furi": "＾並（なら）｜びます",
      "normal": "並びます",
      "en": "to line up",
      "remark_en": "",
      "mm": "စီတန်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO5.mp3")
  },
  {
      "furi": "＾丈（じょう）夫（ぶ）｜[な]",
      "normal": "丈夫[な]",
      "en": "durable",
      "remark_en": "",
      "mm": "ခိုင်မာသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO6.mp3")
  },
  {
      "furi": "アパート",
      "normal": "アパート",
      "en": "Apartment",
      "remark_en": "",
      "mm": "အိမ်ခန်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO7.mp3")
  },
  {
      "furi": "＾弁（べん）護（ご）士（し）",
      "normal": "弁護士",
      "en": "lawyer, attorney",
      "remark_en": "",
      "mm": "ရှေ့နေ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO8.mp3")
  },
  {
      "furi": "＾音（おん）楽（がく）家（か）",
      "normal": "音楽家",
      "en": "musician",
      "remark_en": "",
      "mm": "ဂီတပညာရှင်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO9.mp3")
  },
  {
      "furi": "＾子（こ）｜どもたち",
      "normal": "子どもたち",
      "en": "children",
      "remark_en": "",
      "mm": "ကလေးများ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO10.mp3")
  },
  {
      "furi": "＾自（し）然（ぜん）",
      "normal": "自然",
      "en": "nature",
      "remark_en": "",
      "mm": "သဘာဝ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO11.mp3")
  },
  {
      "furi": "＾教（きょう）育（いく）",
      "normal": "教育",
      "en": "education",
      "remark_en": "",
      "mm": "ပညာရေး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO12.mp3")
  },
  {
      "furi": "＾文（ぶん）化（か）",
      "normal": "文化",
      "en": "culture",
      "remark_en": "",
      "mm": "ယဉ်ကျေးမှု",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO13.mp3")
  },
  {
      "furi": "＾社（しゃ）会（かい）",
      "normal": "社会",
      "en": "society",
      "remark_en": "",
      "mm": "လူမှုပတ်ဝန်းကျင်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO14.mp3")
  },
  {
      "furi": "＾政（せい）治（じ）",
      "normal": "政治",
      "en": "politiccal",
      "remark_en": "",
      "mm": "နိုင်ငံရေး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO15.mp3")
  },
  {
      "furi": "＾法（ほう）律（りつ）",
      "normal": "法律",
      "en": "law",
      "remark_en": "",
      "mm": "ဥပဒေ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO16.mp3")
  },
  {
      "furi": "＾戦（せん）争（そう）",
      "normal": "戦争",
      "en": "war",
      "remark_en": "",
      "mm": "တိုက်ပွဲ၊စစ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO17.mp3")
  },
  {
      "furi": "＾平（へい）和（わ）",
      "normal": "平和",
      "en": "peace",
      "remark_en": "",
      "mm": "ငြိမ်းချမ်းရေး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO18.mp3")
  },
  {
      "furi": "＾目（もく）的（てき）",
      "normal": "目的",
      "en": "purpose",
      "remark_en": "",
      "mm": "ရည်မှန်းချက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO19.mp3")
  },
  {
      "furi": "＾論（ろん）文（ぶん）",
      "normal": "論文",
      "en": "thesis, academic paper",
      "remark_en": "",
      "mm": "စာတမ်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO20.mp3")
  },
  {
      "furi": "＾楽（たの）｜しみ",
      "normal": "楽しみ",
      "en": "enjoyment",
      "remark_en": "",
      "mm": "ပျော်ရွှင်စရာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO21.mp3")
  },
  {
      "furi": "ミキサー",
      "normal": "ミキサー",
      "en": "mixer",
      "remark_en": "",
      "mm": "မွှေစက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO22.mp3")
  },
  {
      "furi": "やかん",
      "normal": "やかん",
      "en": "Kettle",
      "remark_en": "",
      "mm": "ရေနွေးအိုး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO23.mp3")
  },
  {
      "furi": "ふた",
      "normal": "ふた",
      "en": "lid",
      "remark_en": "",
      "mm": "အဖုံး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO24.mp3")
  },
  {
      "furi": "＾栓（せん）抜（ぬ）｜き",
      "normal": "栓抜き",
      "en": "cap opener",
      "remark_en": "",
      "mm": "အဆို့ဖောက်တံ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO25.mp3")
  },
  {
      "furi": "＾缶（かん）切（き）｜り",
      "normal": "缶切り",
      "en": "can opener",
      "remark_en": "",
      "mm": "ဆီသွတ်ဘူးဖောက်တံ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO26.mp3")
  },
  {
      "furi": "＾缶（かん）詰（づめ）",
      "normal": "缶詰",
      "en": "can",
      "remark_en": "",
      "mm": "ဆီသွတ်ဘူး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO27.mp3")
  },
  {
      "furi": "のし｜＾袋（ぶくろ）",
      "normal": "のし袋",
      "en": "Envelope with money",
      "remark_en": "",
      "mm": "ဂုဏ်ပြုချီးမြှင့်ငွေပါ စာအိတ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO28.mp3")
  },
  {
      "furi": "ふろしき",
      "normal": "ふろしき",
      "en": "wrapping cloth used to carry things",
      "remark_en": "",
      "mm": "ပါကင်ပိတ်စ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO29.mp3")
  },
  {
      "furi": "そろばん",
      "normal": "そろばん",
      "en": "abacus",
      "remark_en": "",
      "mm": "ပေသီး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO30.mp3")
  },
  {
      "furi": "＾体（たい）温（おん）計（けい）",
      "normal": "体温計",
      "en": "thermometer",
      "remark_en": "",
      "mm": "ကိုယ်အပူချိန်တိုင်းကိရိယာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO31.mp3")
  },
  {
      "furi": "＾材（ざい）料（りょう）",
      "normal": "材料",
      "en": "material, ingredient",
      "remark_en": "",
      "mm": "ပါဝင်ပစ္စည်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO32.mp3")
  },
  {
      "furi": "ある～",
      "normal": "ある～",
      "en": "some~",
      "remark_en": "",
      "mm": "တချို့",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO33.mp3")
  },
  {
      "furi": "＾一（いっ）生（しょう）懸（けん）命（めい）",
      "normal": "一生懸命",
      "en": "will all one’s effort",
      "remark_en": "",
      "mm": "အားသွန်ခွန်စိုက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO34.mp3")
  },
  {
      "furi": "なぜ",
      "normal": "なぜ",
      "en": "why",
      "remark_en": "",
      "mm": "ဘာကြောင့်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO35.mp3")
  },
  {
      "furi": "どのくらい",
      "normal": "どのくらい",
      "en": "How much",
      "remark_en": "",
      "mm": "ဘယ်လောက်လောက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO36.mp3")
  },
  {
      "furi": "＾国（こく）連（れん）",
      "normal": "国連",
      "en": "United Nations",
      "remark_en": "",
      "mm": "ကုလသမဂ္ဂ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO37.mp3")
  },
  {
      "furi": "エリーゼのために",
      "normal": "エリーゼのために",
      "en": "For Elise",
      "remark_en": "",
      "mm": "အဲရီးဇဲ အတွက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO38.mp3")
  },
  {
      "furi": "ベートーベン",
      "normal": "ベートーベン",
      "en": "Beethoven(composer)",
      "remark_en": "",
      "mm": "ဘဲတိုးဗန်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO39.mp3")
  },
  {
      "furi": "＾子（こ）｜どもニュース",
      "normal": "子どもニュース",
      "en": "Children news",
      "remark_en": "",
      "mm": "ကလေးများအတွက်သတင်းစာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO40.mp3")
  },
  {
      "furi": "＾出（で）｜ます",
      "normal": "出ます",
      "en": "get(bonus)",
      "remark_en": "",
      "mm": "ထွက်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO41.mp3")
  },
  {
      "furi": "＾半（はん）分（ぶん）",
      "normal": "半分",
      "en": "half",
      "remark_en": "",
      "mm": "တစ်ဝက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO42.mp3")
  },
  {
      "furi": "ローン",
      "normal": "ローン",
      "en": "Loan",
      "remark_en": "",
      "mm": "ချေးငွေ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson42/vocab/4N42VO43.mp3")
  },
  {
      "furi": "カップめん",
      "normal": "カップめん",
      "en": "\tinstant noodles",
      "remark_en": "",
      "mm": "ခေါက်ဆွဲခြောက်ဘူး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾世（せ）界（かい）初（はつ）",
      "normal": "世界初",
      "en": "World First",
      "remark_en": "",
      "mm": "ကမ္ဘာ့ပထမဦးဆုံး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "～によって",
      "normal": "～によって",
      "en": "from~",
      "remark_en": "",
      "mm": "～မှ၊～က",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "どんぶり",
      "normal": "どんぶり",
      "en": "Curry rice",
      "remark_en": "",
      "mm": "ဟင်းပုံထမင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "めん",
      "normal": "めん",
      "en": "noodles",
      "remark_en": "",
      "mm": "ခေါက်ဆွဲ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾広（ひろ）｜めます",
      "normal": "広めます",
      "en": "Spread",
      "remark_en": "",
      "mm": "ပြန့်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾市（し）場（じょう）調（ちょう）査（さ）",
      "normal": "市場調査",
      "en": "Market research",
      "remark_en": "",
      "mm": "စျေးကွက်သုတေသန",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾割（わ）｜ります",
      "normal": "割ります",
      "en": "Divide",
      "remark_en": "",
      "mm": "ခွဲသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾注（そそ）｜ぎます",
      "normal": "注ぎます",
      "en": "Pour",
      "remark_en": "",
      "mm": "လောင်းချသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "チキンラーメン",
      "normal": "チキンラーメン",
      "en": "Chicken Ramen",
      "remark_en": "",
      "mm": "ကြက်သားခေါက်ဆွဲ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾安（あん）藤（どう）百（もも）福（ふく）",
      "normal": "安藤百福",
      "en": "Momofuku Ando",
      "remark_en": "",
      "mm": "အန်းဒိုမိုမိုဖုခု",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  }
]

/*
    {
        furi: "",
        normal: "",
        en: "",
        remark_en: "",
        mm: "",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson29/vocab/4N29VO1.mp3")
    },


*/
const test = [
  {
    'goi': '包（つつ）」みます',
    'unicode': 'ထုပ်ပိုးသည်',
    'zawgyi': 'ထုပ္ပိုးသည္',
    'english': 'wrap',
    'long': false
  },
  {
    'goi': '沸（わか）」かします',
    'unicode': 'ဆူအောင်တည်သည်',
    'zawgyi': 'ဆူေအာင္တည္သည္',
    'english': 'boil',
    'long': false
  },
  {
    'goi': '混（ま）」ぜます',
    'unicode': 'ရောသည်၊မွှေသည်',
    'zawgyi': 'ေရာသည္၊ေမႊသည္',
    'english': 'mix',
    'long': false
  },
  {
    'goi': '計（けい）」算（さん）」します',
    'unicode': 'တွက်ချက်သည်',
    'zawgyi': 'တြက္ခ်က္သည္',
    'english': 'calculate',
    'long': false
  },
  {
    'goi': '並（なら）」びます',
    'unicode': 'စီတန်းသည်',
    'zawgyi': 'စီတန္းသည္',
    'english': 'to line up',
    'long': false
  },
  {
    'goi': '丈（じょう）」夫（ぶ）」[な]',
    'unicode': 'ခိုင်မာသော',
    'zawgyi': 'ခိုင္မာေသာ',
    'english': 'durable',
    'long': false
  },
  {
    'goi': 'アパート',
    'unicode': 'အိမ်ခန်း',
    'zawgyi': 'အိမ္ခန္း',
    'english': 'Apartment',
    'long': false
  },
  {
    'goi': '弁（べん）」護（ご）」士（し）',
    'unicode': 'ရှေ့နေ',
    'zawgyi': 'ေရွ႕ေန',
    'english': 'lawyer, attorney',
    'long': false
  },
  {
    'goi': '音（おん）」楽（がく）」家（か）',
    'unicode': 'ဂီတပညာရှင်',
    'zawgyi': 'ဂီတပညာရွင္',
    'english': 'musician',
    'long': false
  },
  {
    'goi': '子（こ）」どもたち',
    'unicode': 'ကလေးများ',
    'zawgyi': 'ကေလးမ်ား',
    'english': 'children',
    'long': false
  },
  {
    'goi': '自（し）」然（ぜん）',
    'unicode': 'သဘာဝ',
    'zawgyi': 'သဘာဝ',
    'english': 'nature',
    'long': false
  },
  {
    'goi': '教（きょう）」育（いく）',
    'unicode': 'ပညာရေး',
    'zawgyi': 'ပညာေရး',
    'english': 'education',
    'long': false
  },
  {
    'goi': '文（ぶん）」化（か）',
    'unicode': 'ယဉ်ကျေးမှု',
    'zawgyi': 'ယဥ္ေက်းမႈ',
    'english': 'culture',
    'long': false
  },
  {
    'goi': '社（しゃ）」会（かい）',
    'unicode': 'လူမှုပတ်ဝန်းကျင်',
    'zawgyi': 'လူမႈပတ္ဝန္းက်င္',
    'english': 'society',
    'long': false
  },
  {
    'goi': '政（せい）」治（じ）',
    'unicode': 'နိုင်ငံရေး',
    'zawgyi': 'နိုင္ငံေရး',
    'english': 'politiccal',
    'long': false
  },
  {
    'goi': '法（ほう）」律（りつ）',
    'unicode': 'ဥပဒေ',
    'zawgyi': 'ဥပေဒ',
    'english': 'law',
    'long': false
  },
  {
    'goi': '戦（せん）」争（そう）',
    'unicode': 'တိုက်ပွဲ၊စစ်',
    'zawgyi': 'တိုက္ပြဲ၊စစ္',
    'english': 'war',
    'long': false
  },
  {
    'goi': '平（へい）」和（わ）',
    'unicode': 'ငြိမ်းချမ်းရေး',
    'zawgyi': 'ၿငိမ္းခ်မ္းေရး',
    'english': 'peace',
    'long': false
  },
  {
    'goi': '目（もく）」的（てき）',
    'unicode': 'ရည်မှန်းချက်',
    'zawgyi': 'ရည္မွန္းခ်က္',
    'english': 'purpose',
    'long': false
  },
  {
    'goi': '論（ろん）」文（ぶん）',
    'unicode': 'စာတမ်း',
    'zawgyi': 'စာတမ္း',
    'english': 'thesis, academic paper',
    'long': false
  },
  {
    'goi': '楽（たの）」しみ',
    'unicode': 'ပျော်ရွှင်စရာ',
    'zawgyi': 'ေပ်ာ္ရႊင္စရာ',
    'english': 'enjoyment',
    'long': false
  },
  {
    'goi': 'ミキサー',
    'unicode': 'မွှေစက်',
    'zawgyi': 'ေမႊစက္',
    'english': 'mixer',
    'long': false
  },
  {
    'goi': 'やかん',
    'unicode': 'ရေနွေးအိုး',
    'zawgyi': 'ေရေႏြးအိုး',
    'english': 'Kettle',
    'long': false
  },
  {
    'goi': 'ふた',
    'unicode': 'အဖုံး',
    'zawgyi': 'အဖုံး',
    'english': 'lid',
    'long': false
  },
  {
    'goi': '栓（せん）」抜（ぬ）」き',
    'unicode': 'အဆို့ဖောက်တံ',
    'zawgyi': 'အဆို႔ေဖာက္တံ',
    'english': 'cap opener',
    'long': false
  },
  {
    'goi': '缶（かん）」切（き）」り',
    'unicode': 'ဆီသွတ်ဘူးဖောက်တံ',
    'zawgyi': 'ဆီသြတ္ဘူးေဖာက္တံ',
    'english': 'can opener',
    'long': false
  },
  {
    'goi': '缶（かん）」詰（づめ）',
    'unicode': 'ဆီသွတ်ဘူး',
    'zawgyi': 'ဆီသြတ္ဘူး',
    'english': 'ဆီသြတ္ဘူးေဖာက္တံ',
    'long': false
  },
  {
    'goi': 'のし」袋（ぶくろ）',
    'unicode': 'ဂုဏ်ပြုချီးမြှင့်ငွေပါ စာအိတ်',
    'zawgyi': 'ဂုဏ္ျပဳခ်ီးျမႇင့္ေငြပါ စာအိတ္',
    'english': 'Envelope with money',
    'long': false
  },
  {
    'goi': 'ふろしき',
    'unicode': 'ပါကင်ပိတ်စ',
    'zawgyi': 'ပါကင္ပိတ္စ',
    'english': 'wrapping cloth used to carry things',
    'long': false
  },
  {
    'goi': 'そろばん',
    'unicode': 'ပေသီး',
    'zawgyi': 'ေပသီး',
    'english': 'abacus',
    'long': false
  },
  {
    'goi': '体（たい）」温（おん）」計（けい）',
    'unicode': 'ကိုယ်အပူချိန်တိုင်းကိရိယာ',
    'zawgyi': 'ကိုယ္အပူခ်ိန္တိုင္းကိရိယာ',
    'english': 'thermometer',
    'long': false
  },
  {
    'goi': '材（ざい）」料（りょう）',
    'unicode': 'ပါဝင်ပစ္စည်း',
    'zawgyi': 'ပါဝင္ပစၥည္း',
    'english': 'material, ingredient',
    'long': false
  },
  {
    'goi': 'ある～',
    'unicode': 'တချို့',
    'zawgyi': 'တခ်ိဳ႕',
    'english': 'some~',
    'long': false
  },
  {
    'goi': '一（いっ）」生（しょう）」懸（けん）」命（めい）',
    'unicode': 'အားသွန်ခွန်စိုက်',
    'zawgyi': 'အားသြန္ခြန္စိုက္',
    'english': 'will all one’s effort',
    'long': false
  },
  {
    'goi': 'なぜ',
    'unicode': 'ဘာကြောင့်',
    'zawgyi': 'ဘာေၾကာင့္',
    'english': 'why',
    'long': false
  },
  {
    'goi': 'どのくらい',
    'unicode': 'ဘယ်လောက်လောက်',
    'zawgyi': 'ဘယ္ေလာက္ေလာက္',
    'english': 'How much',
    'long': false
  },
  {
    'goi': '国（こく）」連（れん）',
    'unicode': 'ကုလသမဂ္ဂ',
    'zawgyi': 'ကုလသမဂၢ',
    'english': 'United Nations',
    'long': false
  },
  {
    'goi': 'エリーゼのために',
    'unicode': 'အဲရီးဇဲ အတွက်',
    'zawgyi': 'အဲရီးဇဲ အတြက္',
    'english': 'For Elise',
    'long': false
  },
  {
    'goi': 'ベートーベン',
    'unicode': 'ဘဲတိုးဗန်း',
    'zawgyi': 'ဘဲတိုးဗန္း',
    'english': 'Beethoven(composer)',
    'long': false
  },
  {
    'goi': '子（こ）」どもニュース',
    'unicode': 'ကလေးများအတွက်သတင်းစာ',
    'zawgyi': 'ကေလးမ်ားအတြက္သတင္းစာ',
    'english': 'Children news',
    'long': true
  },
  {
    'goi': '出（で）」ます',
    'unicode': 'ထွက်သည်',
    'zawgyi': 'ထြက္သည္',
    'english': 'get(bonus)',
    'long': false
  },
  {
    'goi': '半（はん）」分（ぶん）',
    'unicode': 'တစ်ဝက်',
    'zawgyi': 'တစ္ဝက္',
    'english': 'half',
    'long': false
  },
  {
    'goi': 'ローン',
    'unicode': 'ချေးငွေ',
    'zawgyi': 'ေခ်းေငြ',
    'english': 'Loan',
    'long': false
  },
  {
    'goi': 'カップめん',
    'unicode': 'ခေါက်ဆွဲခြောက်ဘူး',
    'zawgyi': 'ေခါက္ဆြဲေျခာက္ဘူး',
    'english': '	instant noodles',
    'long': false
  },
  {
    'goi': '世（せ）」界（かい）」初（はつ）',
    'unicode': 'ကမ္ဘာ့ပထမဦးဆုံး',
    'zawgyi': 'ကမၻာ့ပထမဦးဆုံး',
    'english': 'World First',
    'long': false
  },
  {
    'goi': '～によって',
    'unicode': '～မှ၊～က',
    'zawgyi': '～မွ၊～က',
    'english': 'from~',
    'long': false
  },
  {
    'goi': 'どんぶり',
    'unicode': 'ဟင်းပုံထမင်း',
    'zawgyi': 'ဟင္းပုံထမင္း',
    'english': 'Curry rice',
    'long': false
  },
  {
    'goi': 'めん',
    'unicode': 'ခေါက်ဆွဲ',
    'zawgyi': 'ေခါက္ဆြဲ',
    'english': 'noodles',
    'long': false
  },
  {
    'goi': '広（ひろ）」めます',
    'unicode': 'ပြန့်သည်',
    'zawgyi': 'ျပန႔္သည္',
    'english': 'Spread',
    'long': false
  },
  {
    'goi': '市（し）」場（じょう）」調（ちょう）」査（さ）',
    'unicode': 'စျေးကွက်သုတေသန',
    'zawgyi': 'ေစ်းကြက္သုေတသန',
    'english': 'Market research',
    'long': false
  },
  {
    'goi': '割（わ）」ります',
    'unicode': 'ခွဲသည်',
    'zawgyi': 'ခြဲသည္',
    'english': 'Divide',
    'long': false
  },
  {
    'goi': '注（そそ）」ぎます',
    'unicode': 'လောင်းချသည်',
    'zawgyi': 'ေလာင္းခ်သည္',
    'english': 'Pour',
    'long': false
  },
  {
    'goi': 'チキンラーメン',
    'unicode': 'ကြက်သားခေါက်ဆွဲ',
    'zawgyi': 'ၾကက္သားေခါက္ဆြဲ',
    'english': 'Chicken Ramen',
    'long': false
  },
  {
    'goi': '安（あん）」藤（どう）」百（もも）」福（ふく）',
    'unicode': 'အန်းဒိုမိုမိုဖုခု',
    'zawgyi': 'အန္းဒိုမိုမိုဖခု',
    'english': 'Momofuku Ando',
    'long': false
  }
]