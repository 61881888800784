
export const lesson2 = [
    {"lesson":2,"level":4,"question":"細部","represent":["細"],"answer":["たいじゅう","あんしつ","じゅうりょく","さいぶ"],"true":"さいぶ","times":10},
    {"lesson":2,"level":4,"question":"暗い","represent":["暗"],"answer":["くらい","かるい","つよい","よわい"],"true":"くらい","times":10},
    {"lesson":2,"level":4,"question":"弱点","represent":["弱"],"answer":["たいじゅう","けいしょく","じゃくてん","たいよう"],"true":"じゃくてん","times":10},
    {"lesson":2,"level":4,"question":"太陽","represent":["太"],"answer":["じゅうりょく","えんそく","たいよう","けいしょく"],"true":"たいよう","times":10},
    {"lesson":2,"level":4,"question":"軽食","represent":["食","軽"],"answer":["たいよう","けいしょく","じゃくてん","きんじょ"],"true":"けいしょく","times":10},
    {"lesson":2,"level":4,"question":"弱い","represent":["弱"],"answer":["とおい","よわい","つよい","かるい"],"true":"よわい","times":10},
    {"lesson":2,"level":4,"question":"強い","represent":["強"],"answer":["ほそい","つよい","ふとい","ちかい"],"true":"つよい","times":10},
    {"lesson":2,"level":4,"question":"太い","represent":["太"],"answer":["つよい","ふとい","ちかい","ほそい"],"true":"ふとい","times":10},
    {"lesson":2,"level":4,"question":"近所","represent":["所","近"],"answer":["じゅうりょく","たいじゅう","あんしつ","きんじょ"],"true":"きんじょ","times":10},
    {"lesson":2,"level":4,"question":"近い","represent":["近"],"answer":["くらい","とおい","ほそい","ちかい"],"true":"ちかい","times":10},
    {"lesson":2,"level":4,"question":"暗室","represent":["暗","室"],"answer":["きんじょ","さいぶ","けいしょく","あんしつ"],"true":"あんしつ","times":10},
    {"lesson":2,"level":4,"question":"重力","represent":["重","力"],"answer":["きんじょ","けいしょく","じゅうりょく","じゃくてん"],"true":"じゅうりょく","times":10},
    {"lesson":2,"level":4,"question":"細い","represent":["細"],"answer":["かるい","くらい","ほそい","ちかい"],"true":"ほそい","times":10},
    {"lesson":2,"level":4,"question":"体重","represent":["体","重"],"answer":["あんしつ","じゅうりょく","たいよう","たいじゅう"],"true":"たいじゅう","times":10},
    {"lesson":2,"level":4,"question":"遠い","represent":["遠"],"answer":["とおい","よわい","つよい","くらい"],"true":"とおい","times":10},
    {"lesson":2,"level":4,"question":"軽い","represent":["軽"],"answer":["ほそい","くらい","かるい","とおい"],"true":"かるい","times":10},
    {"lesson":2,"level":4,"question":"遠足","represent":["遠","足"],"answer":["あんしつ","きんじょ","じゃくてん","えんそく"],"true":"えんそく","times":10},
]