import Icon from "@consts/icon";

export const lesson26 = {
    title: {
        furi: 'ごみは　どこに　｜＾出（だ）｜したら　いいですか',
        normal: 'ごみは　どこに　出したら　いいですか',
        en: 'Where should I put out the garbage?',
        mm: 'အမှိုက်ကို ဘယ်မှာပစ်ရမလဲ။',
    },
    conversation: [
        {
            person: {
                furi: '＾管（かん）理（り）人（にん）',
                normal: '管理人',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'ミラーさん、｜＾引（ひ）｜っ｜＾越（こ）｜しの　｜＾荷（に）物（もつ）｜は　｜＾片（かた）｜づきましたか。',
                    normal: 'ミラーさん、引っ越しの　荷物は　片付きましたか。',
                    en: 'Mr. Miller, have you packed up your moving luggage?',
                    mm: 'မိရစံ အိမ်ပြောင်းဖို့ပစ္စည်းတွေ သိမ်းပြီးပြီလား?',
                }
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'はい、だいたい　｜＾片（かた）｜づきました。',
                    normal: 'はい、だいたい　片づきました。',
                    en: "Yes, it's almost finished.",
                    mm: 'ဟုတ်ကဲ့၊ တော်တော်များများ ပြီးသွားပါပြီ။',
                },
                {
                    furi: 'あのう、ごみを　｜＾捨（す）｜てたいんですが、どこに　｜＾出（だ）｜したら　いいですか。',
                    normal: 'あのう、ごみを　捨てたいんですが、どこに　出したら　いいですか。',
                    en: 'Um, do you want to throw away the garbage, where should I put it?',
                    mm: 'ဟိုလေ၊ အမှိုက်ပစ်ချင်လို့ ဘယ်မှာပစ်ရင်ကောင်းမလဲ?',
                },
            ],
        },
        {
            person: {
                furi: '＾管（かん）理（り）人（にん）',
                normal: '管理人',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '＾燃（も）｜える　ごみは　｜＾月（げつ）曜（よう）日（び）｜と　｜＾木（もく）曜（よう）日（び）｜の　｜＾朝（あさ）｜　｜＾出（だ）｜して　ください。',
                    normal: '燃える　ごみは　月曜日と　木曜日の　朝　出して　ください。',
                    en: 'Please put out burnable garbage on Monday and Thursday mornings.',
                    mm: 'မီးလောင်နိုင်တဲ့အမှိုက်က တနင်္လာနေ့နဲ့ ကြာသာပတေးနေ့ မနက်ပိုင်းမှာစွန့်ပစ်ပေးပါ။',
                },
                {
                    furi: 'ごみ｜＾置（お）｜き｜＾場（ば）｜は　｜＾駐（ちゅう）車（しゃ）場（じょう）｜の　｜＾横（よこ）｜です。',
                    normal: 'ごみ置き場は　駐車場の　横です。',
                    en: 'The garbage place is next to the parking lot.',
                    mm: 'အမှိုက်ပစ်တဲ့နေရာက ကားပါကင်ရဲ့ နံဘေးမှာပါ။',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '＾瓶（びん）｜や　｜＾缶（かん）｜は　いつですか。',
                    normal: '瓶や　缶は　いつですか。',
                    en: 'When will the bottles and cans be available?',
                    mm: 'ပုလင်းနဲ့ သတ္တုဘူးခွံတွေက ဘယ်တော့လည်း။',
                },
            ],
        },
        {
            person: {
                furi: '＾管（かん）理（り）人（にん）',
                normal: '管理人',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '＾土（ど）曜（よう）日（び）｜です。',
                    normal: '土曜日です。',
                    en: "It's Saturday.",
                    mm: 'စနေနေ့ပါ။',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'わかりました。それから　お｜＾湯（ゆ）｜が　｜＾出（で）｜ないんですが。。。',
                    normal: 'わかりました。それから　お湯が　出ないんですが。。。',
                    en: "got it. And she doesn't have hot water. . .",
                    mm: 'နားလည်ပါပြီ၊ ပြီးတော့ ရေနွေးက မလာလို့ . . .',
                },
            ],
        },
        {
            person: {
                furi: '＾管（かん）理（り）人（にん）',
                normal: '管理人',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'ガス｜＾会（かい）社（しゃ）｜に　｜＾連（れん）絡（らく）｜したら、すぐ　｜＾来（き）｜て　くれますよ。',
                    normal: 'ガス会社に　連絡したら、すぐ　来て　くれますよ。',
                    en: 'If you contact the gas company, they will come right away.',
                    mm: 'ဂက်စ်ကုမ္ပဏီကို ဆက်သွယ်လိုက်ရင် ချက်ချင်းလာပေးပါတယ်။',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'すみませんが、　｜＾電（でん）話（わ）番（ばん）号（ごう）｜を　｜＾教（おし）｜えて　いただけませんか。',
                    normal: 'すみませんが、　電話番号を　教えて　いただけませんか。',
                    en: 'Excuse me, but could you tell me your phone number?',
                    mm: 'တစ်ဆိတ်လောက် ဖုန်းနံပါတ်ကို ပြောပြပေးလို့ရမလား?',
                },
            ],
        },
        {
            person: {
                furi: '＾管（かん）理（り）人（にん）',
                normal: '管理人',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'ええ、いいですよ。',
                    normal: 'ええ、いいですよ。',
                    en: "Yes, that's fine.",
                    mm: 'အဲ . . ရတယ်လေ။',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson26/kaiwa/4N26KW.mp3")
}

/*
    {
        title: {
            furi: '',
            normal: '',
            en: '',
            mm: '',
        },
        conversation: [
            {
                person: {
                    furi: '',
                    normal: '',
                },
                sentence: [
                    {
                        furi: '',
                        normal: '',
                        en: '',
                        mm: '',
                    }
                ],
            },
        ],
        audioUrl: require("@assets/audio/lesson26/kaiwa/4N26KW.mp3")
    }
*/