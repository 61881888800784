export const renshuuA = [
    {
        sentence: [
            {
                furi: "＾一（ひと）人（り）｜で　この　｜＾荷（に）物（もつ）｜を　｜！はこぶ｜　のは　｜＾無（む）理（り）｜です。",
                normal: "一人で　この　荷物を　はこぶ　のは　無理です。",
                en: "It's impossible for me to carry this luggage by myself.",
                mm: "ဒီခရီးဆောင်အိတ်ကို တစ်ယောက်တည်း သယ်ဖို့ဆိုတာ မဖြစ်နိုင်ပါဘူး။",
            },
            {
                furi: "＾朝（あさ）｜　｜＾早（はや）｜く　｜！さんぽする｜　のは　気持ちが　いいです。",
                normal: "朝　早く　さんぽする　のは　気持ちが　いいです。",
                en: "It feels good to take a walk early in the morning.",
                mm: "မနက်စောစော လမ်းလျှောက်ရတာ ကောင်းပါတယ်။",
            },
            {
                furi: "ボランティアに　｜！さんかする｜　のは　おもしろいです。",
                normal: "ボランティアに　さんかする　のは　おもしろいです。",
                en: "It's fun to hang out with volunteers.",
                mm: "စေတနာ့ဝန်ထမ်းတွေနဲ့ တွဲရတာ ပျော်စရာကောင်းပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson38/renshua/4N38RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "わたしは　クラシック　｜＾音（おん）楽（がく）｜を　｜！きく｜　のが　｜＾好（す）｜きです。",
                normal: "わたしは　クラシック　音楽を　きく　のが　好きです。",
                en: "I like listening to classical music.",
                mm: "classical music နားထောင်ရတာကြိုက်တယ်။",
            },
            {
                furi: "わたしは　｜＾絵（え）｜を　｜！かく｜　のが　｜＾下（へ）手（た）｜です。",
                normal: "わたしは　絵を　かく　のが　下手です。",
                en: "I'm not good at drawing pictures.",
                mm: "ကျွန်တော်က ပုံတွေဆွဲတာ မကျွမ်းကျင်ဘူး။",
            },
            {
                furi: "わたしは　｜！たべる｜　のが　｜＾遅（おそ）｜いです。",
                normal: "わたしは　たべる　のが　遅いです。",
                en: "I am slow at eating",
                mm: "ငါထမင်းစားတာ နှေးတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson38/renshua/4N38RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾電（でん）気（き）｜を　｜！けす｜　のを　｜＾忘（わす）｜れました。",
                normal: "電気を　けす　のを　忘れました。",
                en: "I forgot to turn off the electricity.",
                mm: "လျှပ်စစ်မီးပိတ်ဖို့ မေ့သွားတယ်။",
            },
            {
                furi: "＾薬（くすり）｜を　｜！のむ｜　のを　｜＾忘（わす）｜れました。",
                normal: "薬を　のむ　のを　忘れました。",
                en: "I forgot to take my medicine.",
                mm: "ဆေးသောက်ဖို့ မေ့သွားတယ်။",
            },
            {
                furi: "＾山（やま）田（だ）｜さんに　｜！れんらくする｜　のを　｜＾忘（わす）｜れました。",
                normal: "山田さんに　れんらくする　のを　忘れました。",
                en: "I forgot to contact Mr. Yamada.",
                mm: "မစ္စတာယာမာဒါကို ဆက်သွယ်ဖို့ မေ့သွားတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson38/renshua/4N38RSA3.mp3")
    },
    {
        sentence: [
            {
                furi: "あした　｜＾田（た）中（なか）｜さんが　｜！たいいんする｜　のを　｜＾知（し）｜って　いますか。",
                normal: "あした　田中さんが　たいいんする　のを　知って　いますか。",
                en: "Do you know that Mr. Tanaka will leave the hospital tomorrow?",
                mm: "မစ္စတာ တာနာကာ မနက်ဖြန် ဆေးရုံက ဆင်းမယ်ဆိုတာ သိလား။",
            },
            {
                furi: "＾来（らい）週（しゅう）｜の　｜＾金（きん）曜（よう）日（び）｜は　｜＾授（じゅ）業（ぎょう）｜が　｜！ない｜　のを　｜＾知（し）｜って　いますか。",
                normal: "来週の　金曜日は　授業が　ない　のを　知って　いますか。",
                en: "Do you know that there will be no class next Friday?",
                mm: "​လာမဲ့သောကြာ​နေ့ အတန်းမရှိ​တော့ဘူးဆိုတာ မင်းသိလား။",
            },
            {
                furi: "＾駅（えき）前（まえ）｜に　｜＾大（おお）｜きな　ホテルが　｜！できた｜　のを　｜＾知（し）｜って　いますか。",
                normal: "駅前に　大きな　ホテルが　できた　のを　知って　いますか。",
                en: "Did you know that a large hotel was built in front of the station?",
                mm: "ဘူတာရုံရှေ့တွင် ဟိုတယ်ကြီးတစ်ခု ဆောက်ထားသည်ကို သင်သိပါသလား။",
            },
        ],
        audioUrl: require("@assets/audio/lesson38/renshua/4N38RSA4.mp3")
    },
    {
        sentence: [
            {
                furi: "＾父（ちち）｜が　｜！うまれた｜　のは　｜＾北（ほっ）海（かい）道（どう）｜の　｜＾小（ちい）｜さな　｜＾村（むら）｜　です。",
                normal: "父が　うまれた　のは　北海道の　小さな　村　です。",
                en: "My father was born in a small village in Hokkaido.",
                mm: "အဖေက ဟော့ကိုင်းဒိုးမှာရှိတဲ့ ရွာလေးတစ်ရွာမှာ မွေးတယ်။",
            },
            {
                furi: "わたしが　｜！ほしい｜　のは　イタリア｜＾製（せい）｜の　｜＾靴（くつ）｜　です。",
                normal: "わたしが　ほしい　のは　イタリア製の　靴　です。",
                en: "What I want are Italian shoes.",
                mm: "ငါလိုချင်တာက အီတလီဖိနပ်။",
            },
            {
                furi: "いちばん　｜！たいせつな｜　のは　｜＾家（か）族（そく）｜の　｜＾健（けん）康（こう）｜　です。",
                normal: "いちばん　たいせつな　のは　家族の　健康　です。",
                en: "The most important thing is my family's health.",
                mm: "အရေးကြီးဆုံးက မိသားစုကျန်းမာရေးပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson38/renshua/4N38RSA5.mp3")
    },
];

/*
    {
        sentence: [
            {
                furi: "",
                normal: "",
                en: "",
                mm: "",
            },
        ],
        audioUrl: require("@assets/audio/testing.mp3")
    },

 // furi: 'あしたから　｜！旅（りょ）行（こう）なん｜です。',
        // normal: 'あしたから　旅行　なんです。',
        // en: 'It\'s a trip from tomorrow.',
        // mm: 'မနက်ဖြန်ကနေစပြီး ခရီးထွက်ပါမယ်။',
        //


*/