export const bunkei= [
    {
        sentence: [
            {
                furi: "＾今（こん）月（げつ）｜の　スケジュールを　お｜＾送（おく）｜ります。",
                normal: "今月の　スケジュールを　お送ります。",
                en: "I will send you this month's schedule.",
                mm: "ဒီလအတွက် အချိန်ဇယားလေး ပို့ပေးပါမယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson50/bunpou/4N50BK1.mp3")
    },
    {
        sentence: [
            {
                furi: "あした　３｜＾時（じ）｜に　｜＾伺（うかが）｜います。",
                normal: "あした　３時に　伺います。",
                en: "I will visit at 3 o'clock tomorrow.",
                mm: "မနက်​ဖြန်​ ၃ နာရီမှာ အလည်လာပါမယ်​။",
            },
        ],
        audioUrl: require("@assets/audio/lesson50/bunpou/4N50BK2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾私（わたくし）｜は　アメリカから　｜＾参（まい）｜りました。",
                normal: "私は　アメリカから　参りました。",
                en: "I am from America.",
                mm: "ကျွန်တော်က အမေရိကက လာတာပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson50/bunpou/4N50BK3.mp3")
    },
];