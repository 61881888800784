export const reibun= [
    {
        sentence: [
            {
                furi: "きれいな　お｜＾皿（さら）｜ですね。",
                normal: "きれいな　お皿ですね。",
                en: "It's a beautiful plate.",
                mm: "ပန်းကန်လှလှလေးတစ်လုံးပဲ။",
            },
            {
                furi: "ええ。｜＾結（けっ）婚（こん）｜の　お｜＾祝（いわ）｜いに　｜＾田（た）中（なか）｜さんが　くださいました。",
                normal: "ええ。結婚の　お祝いに　田中さんが　くださいました。",
                en: "Yeah. Mr. Tanaka gave it to me as a wedding gift.",
                mm: "အင်း။ Mr. Tanaka က ကျွန်တော့်ကို မင်္ဂလာလက်ဆောင်အဖြစ် ပေးခဲ့တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson41/bunpou/4N41RB1.mp3")
    },
    {
        sentence: [
            {
                furi: "お｜＾母（かあ）｜さん、｜＾猿（さる）｜に　お｜＾菓（か）子（し）｜を　やっても　いい？",
                normal: "お母さん、猿に　お菓子を　やっても　いい？",
                en: "Mother, can I give the monkey some sweets?",
                mm: "မေမေ၊ မျောက်ကို သကြားလုံး ပေးလို့ရမလား။",
            },
            {
                furi: "だめよ。あそこに　えさを　やっては　いけないと　｜＾書（か）｜いて　あるでしょう？",
                normal: "だめよ。あそこに　えさを　やっては　いけないと　書いて　あるでしょう？",
                en: "Can not. It says you shouldn't feed over there, right?",
                mm: "မရဘူး။ အဲဒါတွေကို မကျွေးရဘူးလို့ ဟိုမှာရေးထားတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson41/bunpou/4N41RB2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾相（す）撲（もう）｜を　｜＾見（み）｜に　｜＾行（い）｜った　ことが　ありますか。",
                normal: "相撲を　見に　行った　ことが　ありますか。",
                en: "Have you ever been to a sumo wrestling match?",
                mm: "ဆူမိုနပန်းပြိုင်ပွဲကို သင်ရောက်ဖူးပါသလား။",
            },
            {
                furi: "ええ。この｜＾間（あいだ）｜　｜＾部（ぶ）長（ちょう）｜に　｜＾連（つ）｜れて　｜＾行（い）｜って　いただきました。とても　おもしろかったです。",
                normal: "ええ。この間　部長に　連れて　行って　いただきました。とても　おもしろかったです。",
                en: "Yeah. The other day, the manager took me there. It was very interesting.",
                mm: "အင်း။ ဟိုနေ့က မန်နေဂျာက ကျွန်တော့်ကို အဲဒီကို ခေါ်သွားတယ်။ အရမ်းစိတ်ဝင်စားဖို့ကောင်းတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson41/bunpou/4N41RB3.mp3")
    },
    {
        sentence: [
            {
                furi: "＾夏（なつ）休（やす）｜みの　ホームステイは　どうでしたか。",
                normal: "夏休みの　ホームステイは　どうでしたか。",
                en: "How was your summer homestay?",
                mm: "မင်းရဲ့ နွေရာသီ အိမ်ဂေဟာ ဘယ်လိုလဲ။",
            },
            {
                furi: "＾楽（たの）｜しかったです。｜＾家（か）族（ぞく）｜の　｜＾皆（みな）｜さんが　とても　｜＾親（しん）切（せつ）｜に　して　くださいました。",
                normal: "楽しかったです。家族の　皆さんが　とても　親切に　して　くださいました。",
                en: "It was fun. Everyone in my family was very kind to me.",
                mm: "ပျော်စရာကြီး။ ကျွန်တော့်မိသားစုထဲက လူတိုင်းက ကျွန်တော့်ကို အရမ်းကြင်နာကြတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson41/bunpou/4N41RB4.mp3")
    },
    {
        sentence: [
            {
                furi: "＾連（れん）休（きゅう）｜は　｜＾何（なに）｜を　しますか。",
                normal: "連休は　何を　しますか。",
                en: "What are you going to do during the holidays?",
                mm: "အားလပ်ရက်မှာ မင်းဘာလုပ်မလဲ။",
            },
            {
                furi: "＾子（こ）｜どもを　ディズニーランドへ　｜＾連（つ）｜れて　｜＾行（い）｜って　やります。",
                normal: "子どもを　ディズニーランドへ　連れて　行って　やります。",
                en: "I will take my child to Disneyland.",
                mm: "ငါ့ကလေးကို Disneyland ကို ခေါ်သွားမယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson41/bunpou/4N41RB5.mp3")
    },
    {
        sentence: [
            {
                furi: "＾新（あたら）｜しい　コピー｜＾機（き）｜の　｜＾使（つか）｜い｜＾方（かた）｜が　よく　わからないんですが、ちょっと　｜＾教（おし）｜えて　くださいませんか。",
                normal: "新しい　コピー機の　使い方が　よく　わからないんですが、ちょっと　教えて　くださいませんか。",
                en: "I don't really understand how to use the new copier, can you teach me a little?",
                mm: "မိတ္တူစက်အသစ်ကို ဘယ်လိုသုံးရမယ်ဆိုတာ နားမလည်ဘူး၊ နည်းနည်းလောက် သင်ပေးလို့ ရမလား။",
            },
            {
                furi: "いいですよ。",
                normal: "いいですよ。",
                en: "OK.",
                mm: "ရပါတယ်",
            },
        ],
        audioUrl: require("@assets/audio/lesson41/bunpou/4N41RB6.mp3")
    },
]