import Icon from "@consts/icon";
import Helper from "@helper";
import { memo, useEffect, useState } from "react";
import Number from "./Number";

const SentenceComponent = ({ scoreIncrementAction, furigana, furi, fontSize, normal, en, mm, lang, kanjiDetail,index,no }: any) => {
    const [showTranslate, setShowTranslate] = useState(false);
    const toggleTranslate = async() => {
        setShowTranslate(!showTranslate);
        if(showTranslate){
            scoreIncrementAction();
        }
    }
    useEffect(()=>{
        console.log("After Render Sentence Component");
    })
    return (
        <div key={Math.random()}>
            <div className="flex flex-row  mb-2">
                <div className={`${index!=0 && 'opacity-0'}`}>
                    <Number fontSize={fontSize} furigana={furigana}>
                        {no}
                        <span className="ml-0">.</span>
                    </Number>
                </div>
                <div className="flex-1">
                    {furigana === 'true' ? Helper.furiTranslate({ context: furi, fontSize, style: "flex-wrap", kanjiDetail }) : normal}
                </div>
                <div onClick={toggleTranslate} className={`cursor-pointer`} >
                    {showTranslate ? <img src={Icon.Grammars.Translate} /> : <img src={Icon.Grammars.TranslateDisable}/>}
                </div>
            </div>
            {
                showTranslate &&
                <div className="mb-3 pl-5"  >
                    {lang === 'mm' ? mm : en}
                </div>
            }
        </div>

    )
}
export default memo(SentenceComponent);