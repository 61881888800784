export const explain= [
    {
        // ＾友（とも）達（だち）｜
        pattern_furi: '～かどうか',
        pattern_normal: '～かどうか',
        en: [
            "　whether if or not",
            "　A subject is used in a combination of question and negative form.",
        ],
        mm: [ 
            '　–လား၊ ဘာလားဆိုတာ',
            '　အကြောင်းတစ်ခုကို မေးခွန်းနှင့် အငြင်းမေးခွန်းပုံစံတွဲ၍ သုံးပါသည်။ ',
        ]
    },
    {
        pattern_furi: '～てみます',
        pattern_normal: '～てみます',
        en: [
            "　I'll try",
        ],
        mm: [ 
            '　～Vကြည့်ကြည့်မယ်',
        ]
    },
    {
        pattern_furi: 'Adjい⇒Adjさ',
        pattern_normal: 'Adjい⇒Adjさ',
        en: [
            "　Adjい can be used as a noun by removing い and adding さ.",
        ],
        mm: [ 
            '　Adjいကိုい ဖြုတ်၍ さထည့်ခြင်းဖြင့် Noun အဖြစ်အသုံးပြုနိုင်သည်။',
        ]
    },
   
]