export const reibun= [
    {
        sentence: [
            {
                furi: "＾新（しん）聞（ぶん）｜で　｜＾読（よ）｜んだんですが、１｜＾月（がつ）｜に　｜＾日（に）本（ほん）語（ご）｜の　スビーチ｜＾大（たい）会（かい）｜が　あるそうですよ。",
                normal: "新聞で　読んだんですが、１月に　日本語の　スビーチ大会が　あるそうですよ。",
                en: "I read in the newspaper that there will be a Japanese speech competition in January.",
                mm: "ဇန်န၀ါရီလမှာ ဂျပန်မိန့်ခွန်းပြောပြိုင်ပွဲကျင်းပမယ်လို့ သတင်းစာထဲမှာ ဖတ်ရတယ်။",
            },
            {
                furi: "ミラーさんも　｜＾出（で）｜て　みませんか。",
                normal: "ミラーさんも　出て　みませんか。",
                en: "Mr. Miller Do you ‌also want to compete?",
                mm: "မစ္စတာ မီလာ၊ မင်းလည်း ပြိုင်ကြည့်မလား။",
            },
            {
                furi: "そうですね。｜＾考（かんが）｜えて　みます。",
                normal: "そうですね。考えて　みます。",
                en: "I agree. I'll think about it.",
                mm: "ကျွန်တော်သဘောတူသည်။ ကျွန်တော် စဉ်းစားကြည့်မယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson47/bunpou/4N47RB1.mp3")
    },
    {
        sentence: [
            {
                furi: "クララさんは　｜＾子（こ）｜どもの　とき、フランスに　｜＾住（す）｜んで　いたそうです。",
                normal: "クララさんは　子どもの　とき、フランスに　住んで　いたそうです。",
                en: "Clara lived in France when she was a child.",
                mm: "Clara ငယ်စဉ်က ပြင်သစ်တွင် နေထိုင်ခဲ့တယ်တဲ့။",
            },
            {
                furi: "それで、フランス｜＾語（ご）｜も　わかるんですね。",
                normal: "それで、フランス語も　わかるんですね。",
                en: "So he understands French.",
                mm: "ဒီတော့ ပြင်သစ်ကို နားလည်တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson47/bunpou/4N47RB2.mp3")
    },
    {
        sentence: [
            {
                furi: "パワー｜＾電（でん）気（き）｜の　｜＾新（あたら）｜しい　｜＾電（でん）子（し）辞（じ）書（しょ）｜は　とても　｜＾使（つか）｜いやすくて、いいそうですよ。",
                normal: "パワー電気の　新しい　電子辞書は　とても　使いやすくて、いいそうですよ。",
                en: "Power Denki's new electronic dictionary is very easy to use and seems to be good.",
                mm: "Power Denki ၏ အီလက်ထရွန်နစ်အဘိဓာန်အသစ်သည် အသုံးပြုရအလွန်လွယ်ကူပြီး ကောင်းမွန်ပုံရသည်။",
            },
            {
                furi: "ええ。わたしは　もう　｜＾買（か）｜いました。",
                normal: "ええ。わたしは　もう　買いました。",
                en: "Yeah. I already bought it.",
                mm: "အင်း။ ဝယ်ပြီးသွားပြီ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson47/bunpou/4N47RB3.mp3")
    },
    {
        sentence: [
            {
                furi: "ワット｜＾先（せん）生（せい）｜は　｜＾寂（さび）｜しい　｜＾先（せん）生（せい）｜だそうですね。",
                normal: "ワット先生は　寂しい　先生だそうですね。",
                en: "Mr. Watt seems to be a lonely teacher.",
                mm: "မစ္စတာဝပ်သည် အထီးကျန်သော ဆရာတစ်ဦးဖြစ်ပုံရသည်။",
            },
            {
                furi: "ええ。でも、｜＾授（じゅ）業（ぎょう）｜は　とても　おもしろいですよ。",
                normal: "ええ。でも、授業は　とても　おもしろいですよ。",
                en: "Yeah. But in class he is very interesting.",
                mm: "အင်း။ ဒါပေမယ့် အတန်းက အရမ်းစိတ်ဝင်စားဖို့ကောင်းတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson47/bunpou/4N47RB4.mp3")
    },
    {
        sentence: [
            {
                furi: "にぎやかな　｜＾声（こえ）｜が　しますね。",
                normal: "にぎやかな　声が　しますね。",
                en: "It sounds lively.",
                mm: "အသံသွက်တယ်။",
            },
            {
                furi: "ええ。パーティーでも　して　いるようですね。",
                normal: "ええ。パーティーでも　して　いるようですね。",
                en: "Yeah. It looks like he's at the party.",
                mm: "အင်း။ ပါတီတွေလည်း လုပ်ကြပုံရတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson47/bunpou/4N47RB5.mp3")
    },
    {
        sentence: [
            {
                furi: "＾人（ひと）｜が　｜＾大（おお）勢（ぜい）｜　｜＾集（あつ）｜まって　いますね。",
                normal: "人が　大勢　集まって　いますね。",
                en: "A lot of people are gathering, aren't they?",
                mm: "လူတွေ အများကြီး စုနေကြတယ် မဟုတ်လား ။",
            },
            {
                furi: "＾事（じ）故（こ）｜のようですね。パトカーと　｜＾救（きゅう）急（きゅう）車（しゃ）｜が　｜＾来（き）｜て　いますよ。",
                normal: "事故のようですね。パトカーと　救急車が　来て　いますよ。",
                en: "Sounds like an accident. A police car and an ambulance are here.",
                mm: "မတော်တဆမှုတစ်ခုဖြစ်သလိုပဲ။ ရဲကားနဲ့ လူနာတင်ယာဉ်တစ်စင်း ဒီမှာရှိတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson47/bunpou/4N47RB6.mp3")
    },
]