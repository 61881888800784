import KanjiStrokeComponent from "@components/common/KanjiStrokeComponent";
import { useAppSelector as selector } from '@store/hooks';
import { MdClose } from 'react-icons/md';

import { Minna } from "@consts";
import {
    Popover,
    PopoverContent,
    PopoverHandler
} from "@material-tailwind/react";
import { toString } from "@screens/home/category/KanjiDetailScreen";
import { ReactNode, memo, useState } from "react";
interface KanjiPopOverPro{
    id: number;
    children: ReactNode;
    kanjiDetail?: (id:number)=>void ;
}
const KanjiPopOverComponent = ({ id, children, kanjiDetail }: KanjiPopOverPro) => {
    const { mean, url, kun, on, strokes, level,lesson } = Minna.kanji.information[id];
    const { lang } = selector(({ service }) => service);
    const [open, setOpen] = useState(false);
    const moreBtnAction = ()=>{
        if (kanjiDetail) {
            kanjiDetail(id);
        }
    }
    return (
        <div key={Math.random()}>
            <Popover handler={setOpen} open={open}>
                <PopoverHandler>
                    {children}
                </PopoverHandler>
                <PopoverContent className="relative flex flex-row overflow-hidden w-[255px] h-[150px] z-popover p-0 m-0 border-none card-light-shadow dark-kanji-card-shadow">
                    <div className="absolute text-white font-semibold -left-[5px] top-[0px] shadow-md flex flex-row justify-center items-center rounded-br-[10px] w-[50px] h-[30px] text-[18px] bg-[#FFC654]">
                        {id+1}
                    </div>
                    <div className="flex-1 flex flex-col justify-center items-center bg-[#0084E8] p-3">
                        <KanjiStrokeComponent src={url} style="w-[90px] h-[90px] mt-3" />
                        <div className="text-white">
                            {lang === 'en' ? mean.en : mean.mm}
                        </div>
                    </div>

                    <div className="flex-[3] flex flex-col justify-between text-black bg-white dark:bg-darkBackground dark:text-white p-3">
                        <div className=" h-[20px] overflow-hidden">Kun: {toString(kun)}</div>
                        <div className=" h-[20px] overflow-hidden">On: {toString(on)}</div>
                        <div>Stroke: {strokes}</div>
                        <div>Lesson: {lesson}</div>
                        <div className="flex flex-row items-center justify-end mt-3">
                            <div onClick={moreBtnAction} className="text-white bg-[#0084E8] px-[15px] py-[3px] rounded-[15px] bunkei-title-light btn">More</div></div>
                    </div>
                    {/* Close Btn */}
                    <div onClick={()=>setOpen(false)} className="absolute top-0 right-0 btn text-[#4F4F4F] dark:text-[#D4D4D4] p-[6px]">
                        <MdClose width={30} height={30}/>
                    </div>
                </PopoverContent>
            </Popover>
       </div>
    );
}
export default memo(KanjiPopOverComponent);