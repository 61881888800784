export const reibun= [
    {
        sentence: [
            {
                furi: 'だめだ。もう　｜＾走（はし）｜れない。',
                normal: 'だめだ。もう　走れない。',
                en: "No. I can't run anymore.",
                mm: 'မရတော့ဘူး။ မပြေးနိုင်တော့ဘူး။',
            },
            {
                furi: '＾頑（がん）張（ば）｜れ。あと　５００メートルだ。',
                normal: '頑張れ。あと　５００メートルだ。',
                en: 'keep it up. 500 meters to go.',
                mm: 'ကြိုးစားထား၊ မီတာ ၅၀၀ဘဲ ကျန်တော့တယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson33/bunpou/4N33RB1.mp3")
    },
    {
        sentence: [
            {
                furi: 'もう　｜＾時（じ）間（かん）｜が　ない。',
                normal: 'もう　時間が　ない。',
                en: "I don't have time anymore.",
                mm: 'ငါ့မှာ အချိန်မရှိတော့ဘူး။',
            },
            {
                furi: 'まだ　１｜＾分（ぷん）｜　ある。あきらめるな。',
                normal: 'まだ　１分　ある。あきらめるな。',
                en: 'You still have one minute. Do not give up.',
                mm: 'ခုမှ တစ်မိနစ်ပဲ ရှိသေးတယ်။ အရှုံးမပေးပါနှင့်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson33/bunpou/4N33RB2.mp3")
    },
    {
        sentence: [
            {
                furi: 'この　｜＾池（いけ）｜で　｜＾遊（あそ）｜んでは　いけません。あそこに「｜＾入（はい）｜るな」と　｜＾書（か）｜いて　ありますよ。',
                normal: 'この　池で　遊んでは　いけません。あそこに「入るな」と　書いて　ありますよ。',
                en: "Don't play in this pond. It says 'Don't enter' over there.",
                mm: 'ဒီရေကန်မှာ မကစားပါနဲ့။ အဲဒီမှာ "မဝင်ပါနဲ့" လို့ ‌ရေးထားတယ်။',
            },
            {
                furi: 'あ、ほんとうだ。',
                normal: 'あ、ほんとうだ。',
                en: 'Oh, really.',
                mm: 'အိုတကယ်ပဲလား။',
            },
        ],
    audioUrl: require("@assets/audio/lesson33/bunpou/4N33RB3.mp3")
    },
    {
        sentence: [
            {
                furi: 'あの　｜＾漢（かん）字（じ）｜は　｜＾何（なん）｜と　｜＾読（よ）｜むんですか。',
                normal: 'あの　漢字は　何と　読むんですか。',
                en: 'How do you read those kanji?',
                mm: 'အဲဒီ kanji ကို ဘယ်လိုဖတ်လဲ။',
            },
            {
                furi: '「きんえん」です。たばこを　｜＾吸（す）｜っては　いけないと　いう　｜＾意（い）味（み）｜です。',
                normal: '「きんえん」です。たばこを　吸っては　いけないと　いう　意味です。',
                en: 'It is 「きんえん」. It means that you must not smoke.',
                mm: 'အဲဒါ 「きんえん」 ပါ။ ဆိုလိုတာက ဆေးလိပ်မသောက်ရဘူး။',
            },
        ],
    audioUrl: require("@assets/audio/lesson33/bunpou/4N33RB4.mp3")
    },
    {
        sentence: [
            {
                furi: 'この　マークは　どういう　｜＾意（い）味（み）｜です。',
                normal: 'この　マークは　どういう　意味です。',
                en: 'What does this mark mean?',
                mm: 'အဲ့ဒီအမှတ်အသားက ဘာကို ဆိုလိုတာလဲ။',
            },
            {
                furi: '＾洗（せん）濯（たく）機（き）｜で　｜＾洗（あら）｜えると　いう　｜＾意（い）味（み）｜です。',
                normal: '洗濯機で　洗えると　いう　意味です。',
                en: 'It means that it can be washed in a washing machine.',
                mm: 'ဆိုလိုတာက အဝတ်လျှော်စက်နဲ့ လျှော်လို့ရတယ်။',
            },
        ],
    audioUrl: require("@assets/audio/lesson33/bunpou/4N33RB5.mp3")
    },
    {
        sentence: [
            {
                furi: 'グブタさんは　いますか。',
                normal: 'グブタさんは　いますか。',
                en: 'Gubuta-san, is he there?',
                mm: 'Gubuta-san ၊ သူအဲဒီမှာလား။',
            },
            {
                furi: '＾今（いま）出（で）｜かけて　います。３０｜＾分（ぷん）｜ぐらいで　｜＾戻（もど）｜ると　｜＾言（い）｜って　いました。',
                normal: '今出かけて　います。３０分ぐらいで　戻ると　言って　いました。',
                en: 'He is leaving now. He said he would be back in about 30 minutes.',
                mm: 'အခု ထွက်သွားပြီ။ မိနစ် ၃၀ လောက်ကြာမှ ပြန်လာမယ်လို့ ပြောတယ်။',
            },
        ],
    audioUrl: require("@assets/audio/lesson33/bunpou/4N33RB6.mp3")
    },
    {
        sentence: [
            {
                furi: 'すみませんが、｜＾渡（わた）辺（なべ）｜さんに　あしたの　パーティーは　６｜＾時（じ）｜からだと　｜＾伝（つた）｜えて　いただけませんか。',
                normal: 'すみませんが、渡辺さんに　あしたの　パーティーは　６時からだと　伝えて　いただけませんか。',
                en: "Excuse me, but could you tell Mr. Watanabe that tomorrow's party starts at 6:00?",
                mm: 'တဆိတ်လောက်၊ မနက်ဖြန် ပါတီပွဲကို ၆နာရီမှာစမယ်လို့ Mr. Watanabe ကိုပြောနိုင်မလား။',
            },
            {
                furi: 'わかりました。６｜＾時（じ）｜からですね。',
                normal: 'わかりました。６時からですね。',
                en: 'I got it. It will start at 6:00,right.',
                mm: 'နားလည်ပါပြီ။ ၆နာရီ‌စမှာ‌‌နော်',
            },
        ],
    audioUrl: require("@assets/audio/lesson33/bunpou/4N33RB7.mp3")
    },
]
/*
{
    furi: '',
    normal: '',
    en: '',
    mm: '',
},
*/