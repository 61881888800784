import Icon from "@consts/icon";
import {
    Card,
    SpeedDial,
    SpeedDialHandler,
    Typography,
} from "@material-tailwind/react";
import { memo, useEffect } from "react";

export enum SPEDIAL {
    FURI_ONLY = "FURI_ONLY",
    FURI_TRAN = "FURI_TRAN",
    SHOW_ALL = "SHOW_ALL",
}

interface SpeedDailType {
    type: SPEDIAL;
    isHovering: any;
    setIsHovering: any;
    showVocab?: any;
    setShowVocab?: any;
    showTranslate: any;
    setShowTranslate: any;
    furigana: any;
    toggleFuri: any;
    dark: any;
    onShuffle?: any;
    setOnShuffle?: any;
}

const SpeedDialComponent = ({type,isHovering,setIsHovering, showVocab, setShowVocab, showTranslate, setShowTranslate, furigana, toggleFuri, dark, onShuffle, setOnShuffle }: SpeedDailType) => {
    setShowVocab = setShowVocab? setShowVocab : ()=>{};
    setOnShuffle = setOnShuffle? setOnShuffle : ()=>{};
    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };
    const hoveringToggle = () => {
        setIsHovering(!isHovering);
    }
    const HoverableDiv = ({ handleMouseOver, handleMouseOut,handelOnclick }: any) => {
        return (
            <div  onClick={hoveringToggle} style={{ }}> 
                {
                    !isHovering ? <img src={Icon.Edit} className="transition-transform w-[40px] h-[40px] md:w-[50px] md:h-[50px]" />
                        : <>
                            <div className="">
                                {
                                    speedDials.map(({ label, icon, onclick }) => {
                                        return <div key={Math.random()} onClick={onclick} className="my-[6px] md:my-[8px] h-[30px] md:h-[40px] flex flex-row items-center">
                                            <div className=" bg-[#E3E3E3] dark:bg-[#575757] flex flex-row items-center rounded-[30px] h-full w-[120px] px-[10px] md:w-[143px] md:px-[20px] ">
                                                <Typography className="text-[#424242] dark:text-white font-[500px]">{label}</Typography>
                                            </div>
                                            <Card className={`-ml-[30px] md:-ml-[40px] ${label === 'Shuffle' && onShuffle ? 'bg-yellow ' : 'bg-white'} w-[30px] md:w-[40px] h-full rounded-full flex flex-row items-center justify-center`}>
                                                <img src={icon} className={`w-[14px] h-[21px] md:w-[21px] md:h-[21px] ${label === 'Shuffle' && onShuffle ? 'text-white ' : ''}`} />
                                            </Card>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="flex flex-row justify-end">
                                <img src={Icon.Close} className="transition-transform  w-[40px] h-[40px] md:w-[50px] md:h-[50px]" />
                            </div>
                        </>
                }
            </div>
        );
    };
    const vocabToggle = () => {
        if (showVocab) setShowTranslate(true);
        setShowVocab(!showVocab);
    }
    const translateToggle = () => {
        if (showTranslate) setShowVocab(true);
        setShowTranslate(!showTranslate)
    }
    const speedDials = type === SPEDIAL.SHOW_ALL?[
        {
            label: 'Shuffle',
            icon: Icon.Shuffel,
            onclick: () => setOnShuffle(!onShuffle)
        },
        {
            label: 'Translate',
            icon: showTranslate ? Icon.EyeOff : Icon.Eye,
            onclick: translateToggle
        },
        {
            label: 'Vocabulary',
            icon: showVocab ? Icon.EyeOff : Icon.Eye,
            onclick: vocabToggle
        },
        {
            label: 'Furigana',
            icon: furigana == 'true' ? Icon.EyeOff : Icon.Eye,
            onclick: toggleFuri
        },
    ]:
    type === SPEDIAL.FURI_ONLY?[
        {
            label: 'Furigana',
            icon: furigana == 'true' ? Icon.EyeOff : Icon.Eye,
            onclick: toggleFuri
        },
        ] :
            type === SPEDIAL.FURI_TRAN ?
                [
                   {
                        label: 'Translate',
                        icon: showTranslate ? Icon.EyeOff : Icon.Eye,
                        onclick: translateToggle
                    },
                    {
                        label: 'Furigana',
                        icon: furigana == 'true' ? Icon.EyeOff : Icon.Eye,
                        onclick: toggleFuri
                    },
                ] : []
            
    useEffect(()=>{
        // console.log(type);
    })
    return (
        <div className="z-menu fixed btn right-[25px] md:right-20 bottom-[80px] text-[16px]">
            <SpeedDial>
                <SpeedDialHandler>
                    <HoverableDiv
                        handleMouseOver={handleMouseOver}
                        handleMouseOut={handleMouseOut}
                        handelOnclick={()=>setIsHovering(false)}
                    />
                </SpeedDialHandler>
            </SpeedDial>
        </div>
        // </div>
    );
}
export default memo(SpeedDialComponent);