import CategoryViewBtn from "@components/common/CategoryViewBtn"
import { Card } from "@material-tailwind/react"
import { memo } from "react"

const Conversation = ({ cagetories, kaiwaPerson, furigana, furiTranslate, fontSize, lang }: any) => {
    //明るくて、　いい　部屋ですね。どこで　買ったんですか。
    const Spliter = (sentence: any) => {
        const json = eval(sentence);

        return 
    }
    return <Card onClick={cagetories[2].onclick}
        className="hover:scale-105 transition duration-500 flex-[3] md:h-[250px] rounded-[20px] text-[16px] md:text-[22px]  p-6 dark:bg-darkFooter btn dark-shadow ">
        <div className="flex flex-row ml-5 mb-8">
            <div className=""><img src={cagetories[2].Icon} /></div>
            <div className="text-black dark:text-white font-semibold ml-[10px] md:ml-[20px]">{cagetories[2].label}</div>
        </div>
        <div className="flex flex-col md:flex-row">
            <div className="md:flex-[7] h-[160px] md:h-[187px] overflow-hidden text-[11px] md:text-[15px]">
                {
                    [kaiwaPerson[0], kaiwaPerson[1]].map(({ person: { furi, normal, avatar }, sentence }: any, i: number) => {
                        return <div key={i} className="flex flex-col ">
                            <div className={` flex flex-row ${i % 2 == 0 ? '' : 'text-right flex-row-reverse'}`}>
                                <div className={` ${i % 2 == 0 ? 'mr-1 md:mr-2' : 'ml-1 md:ml-2'}`}>
                                    <img src={(avatar)} />
                                </div>
                                <div className="flex flex-col">
                                    <div className={` flex flex-row ${i % 2 == 0 ? '' : 'justify-end'}`}>
                                        {furigana === 'true' && furi.includes("＾") ? furiTranslate({ context: furi, fontSize, style: "flex-wrap items-center" }) : normal}
                                    </div>
                                    <div className={`p-1 flex flex-col ${i % 2 == 0 ?'':'items-end'}`}>
                                        {(sentence.map(({ furi, normal, en, mm }: any) => {
                                            return <div key={Math.random()} className="bg-[#C7E7FF] px-1 w-fit rounded-[15px] pl-2 py-1 mb-2 md:mb-3 text-black ">
                                                {furigana === 'true' && furi.includes("＾") ? furiTranslate({ context: furi, fontSize, style: "flex-wrap items-center" }) :normal}
                                            </div>
                                        }))[0]}
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
           <CategoryViewBtn cagetories={cagetories}/>
        </div>
    </Card> 
}
export default memo(Conversation);