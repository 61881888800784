export const bunkei= [
    {
        sentence: [
            {
                furi: 'わたしは　｜＾日（に）本（ほん）語（ご）｜が　｜＾少（すこ）｜し　｜！話（はな）せます｜。',
                normal: 'わたしは　日本語が　少し　話せます。',
                en: 'I can speak Japanese a little.',
                mm: 'ကျနော် ဂျပန်စကား နဲနဲပြောနိုင်ပါတယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson27/bunpou/4N27BK1.mp3")
    },
    {
        sentence: [
            {
                furi: '＾山（やま）｜が　はっきり　｜！見（み）え｜ます。',
                normal: '山が　はっきり　見えます。',
                en: 'I can see the mountains clearly.',
                mm: 'တောင်ကို ပြတ်ပြတ်သားသားမြင်နိုင်တယ်။ ',
            },
        ],
        audioUrl: require("@assets/audio/lesson27/bunpou/4N27BK2.mp3")
    },
    {
        sentence: [
            {
                furi: '＾駅（えき）｜の　｜＾前（まえ）｜に　｜＾大（おお）｜きい　スーパーが　｜！でき｜ました。',
                normal: '駅の　前に　大きい　スーパーが　できました。',
                en: 'A large supermarket has opened in front of the station.',
                mm: 'ဘူတာအရှေ့မှာ ကြီးမားတဲ့ စူပါမားကပ်ကြီး တည်ဆောက်ပြီးစီးခဲ့ပါသည်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson27/bunpou/4N27BK3.mp3")
    },
]