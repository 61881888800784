
export const renshuuA = [
    {
        isTable: true,
        tables: [
            {
                head: {
                    a: {
                        val:"",
                        span: 1,
                        style: ""
                    },
                    b: {
                        val:"ます｜＾形（けい）",
                        span: 3,
                        style: ""
                    },
                    c: {
                        val: "＾命（めい）令（れい）形（けい）",
                        span: 2,
                        style: ""
                    },
                    d: {
                        val: "＾禁（きん）止（し）形（けい）",
                        span: 3,
                        style: ""
                    }
                },
                body: [
                    {
                        no: "I",
                        l1: "い",
                        l2: "い",
                        l3: "ます",
                        c1: "い",
                        c2:"え",
                        r1: "い",
                        r2: "る",
                        r3:"な"
                    },
                    {
                        no: "",
                        l1: "か",
                        l2: "き",
                        l3: "ます",
                        c1: "か",
                        c2:"け",
                        r1: "か",
                        r2: "く",
                        r3:"な"
                    },
                    {
                        no: "",
                        l1: "およ",
                        l2: "ぎ",
                        l3: "ます",
                        c1: "およ",
                        c2:"げ",
                        r1: "およ",
                        r2: "ぐ",
                        r3:"な"
                    },
                    {
                        no: "",
                        l1: "だ",
                        l2: "し",
                        l3: "ます",
                        c1: "だ",
                        c2:"せ",
                        r1: "だ",
                        r2: "す",
                        r3:"な"
                    },
                    {
                        no: "",
                        l1: "た",
                        l2: "ち",
                        l3: "ます",
                        c1: "た",
                        c2:"て",
                        r1: "た",
                        r2: "つ",
                        r3:"な"
                    },
                    {
                        no: "",
                        l1: "あそ",
                        l2: "び",
                        l3: "ます",
                        c1: "あそ",
                        c2:"べ",
                        r1: "あそ",
                        r2: "ぶ",
                        r3:"な"
                    },
                    {
                        no: "",
                        l1: "の",
                        l2: "み",
                        l3: "ます",
                        c1: "の",
                        c2:"め",
                        r1: "の",
                        r2: "む",
                        r3:"な"
                    },
                    {
                        no: "",
                        l1: "すわ",
                        l2: "り",
                        l3: "ます",
                        c1: "すわ",
                        c2:"れ",
                        r1: "すわ",
                        r2: "る",
                        r3:"な"
                    },
                 
                ],
                style: {
                    no: "p-2 w-[50px] text-right bg-white dark:bg-darkFooter",
                    l1: "p-2 text-right bg-white dark:bg-darkFooter",
                    l2: "p-2 w-[30px] text-center bg-[#729eff] dark:bg-[#003257]",
                    l3: "p-2 text-left bg-white dark:bg-darkFooter",
                    c1: "p-2 text-right bg-white dark:bg-darkFooter",
                    c2: "p-2 w-[30px] text-center bg-[#729eff] dark:bg-[#003257]",
                    r1: "p-2 text-right bg-white dark:bg-darkFooter",
                    r2: "p-2 w-[30px] text-center bg-[#729eff] dark:bg-[#003257]",
                    r3: "p-2 text-left bg-white dark:bg-darkFooter"
                }
            },
            {
                head: {
                    a: {
                        val:"",
                        span: 1,
                        style: ""
                    },
                    b: {
                        val:"ます｜＾形（けい）",
                        span: 2,
                        style: ""
                    },
                    c: {
                        val: "＾命（めい）令（れい）形（けい）",
                        span: 2,
                        style: ""
                    },
                    d: {
                        val: "＾禁（きん）止（し）形（けい）",
                        span: 3,
                        style: ""
                    }
                },
                body: [
                    {
                        no: "II",
                        l1: "やめ",
                        l3: "ます",
                        c1: "やめ",
                        c2:"ろ",
                        r1: "やめ",
                        r2: "る",
                        r3:"な"
                    },
                    {
                        no: "",
                        l1: "で",
                        l3: "ます",
                        c1: "で",
                        c2:"ろ",
                        r1: "で",
                        r2: "る",
                        r3:"な"
                    },
                    {
                        no: "",
                        l1: "み",
                        l3: "ます",
                        c1: "み",
                        c2:"ろ",
                        r1: "み",
                        r2: "る",
                        r3:"な"
                    },
                    {
                        no: "",
                        l1: "おり",
                        l3: "ます",
                        c1: "おり",
                        c2:"ろ",
                        r1: "おり",
                        r2: "る",
                        r3:"な"
                    },                   
                ],
                style: {
                    no: "p-2 w-[50px] text-right bg-white dark:bg-darkFooter",
                    l1: "p-2 text-right bg-white dark:bg-darkFooter",
                    l3: "p-2 text-left bg-white dark:bg-darkFooter",
                    c1: "p-2 text-right bg-white dark:bg-darkFooter",
                    c2: "p-2 w-[30px] text-center bg-[#729eff] dark:bg-[#003257]",
                    r1: "p-2 text-right bg-white dark:bg-darkFooter",
                    r2: "p-2 w-[30px] text-center bg-[#729eff] dark:bg-[#003257]",
                    r3: "p-2 text-left bg-white dark:bg-darkFooter"
                }
            },
            {
                head: {
                    a: {
                        val:"",
                        span: 1,
                        style: ""
                    },
                    b: {
                        val:"ます｜＾形（けい）",
                        span: 2,
                        style: ""
                    },
                    c: {
                        val: "＾命（めい）令（れい）形（けい）",
                        span: 1,
                        style: ""
                    },
                    d: {
                        val: "＾禁（きん）止（し）形（けい）",
                        span: 2,
                        style: ""
                    }
                },
                body: [
                    {
                        no: "III",
                        l1: "き",
                        l3: "ます",
                        c1: "こい",
                        r2: "くる",
                        r3:"な"
                    },                  
                    {
                        no: "",
                        l1: "し",
                        l3: "ます",
                        c1: "しろ",
                        r2: "する",
                        r3:"な"
                    },                  
                ],
                style: {
                    no: "p-2 w-[50px] text-right bg-white dark:bg-darkFooter",
                    l1: "p-2 text-right bg-[#729eff] dark:bg-[#003257]",
                    l3: "p-2 text-left bg-white dark:bg-darkFooter",
                    c1: "p-2 text-center bg-[#729eff] dark:bg-[#003257]",
                    r2: "p-2 text-right bg-[#729eff] dark:bg-[#003257]",
                    r3: "p-2 text-left bg-white dark:bg-darkFooter"
                }
            },
            
        ],
        audioUrl: require("@assets/audio/lesson33/renshua/4N33RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "にげろ",
                normal: "にげろ",
                en: "run away",
                mm: "‌‌‌‌ပြေးစမ်း",
            },
            {
                furi: "＾交（こう）通（つう）規（き）則（そく）｜を　｜！まもれ｜。",
                normal: "交通規則を　まもれ。",
                en: "Obey traffic rules.",
                mm: "ယာဉ်စည်းကမ်းလိုက်နာပါ။",
            },
            {
                furi: "｜！うごくな｜。",
                normal: "うごくな。",
                en: "Don't move",
                mm: "မလှုပ်နဲ့‌‌",
            },
            {
                furi: "＾電（でん）車（しゃ）｜の　｜＾中（なか）｜で　｜！さわぐな｜。",
                normal: "電車の　中で　さわぐな。",
                en: "Don't be noisy on the train.",
                mm: "ရထားပေါ်မှာ ဆူညံမနေပါနဲ့။",
            },
        ],
        audioUrl: require("@assets/audio/lesson33/renshua/4N33RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "あそこに｜！「くるまを　とめるな」｜と　｜＾書（か）｜いて　あります。",
                normal: "あそこに　「くるまを　とめるな」と　書いて　あります。",
                en: "It says 'Don't stop the car' over there.",
                mm: 'ဟိုမှာ "ကားမရပ်နဲ့" လို့ပြောတယ်။',
            },
            {
                furi: "あの　｜＾漢（かん）字（じ）｜は　｜！「いりぐち」｜　と　｜＾読（よ）｜みます。",
                normal: "あの　漢字は　「いりぐち」　と　読みます。",
                en: 'That kanji is read as 「いりぐち」.',
                mm: 'ထို kanji ကို 「いりぐち」 ဟုဖတ်သည်။',
         
            },
        ],
        audioUrl: require("@assets/audio/lesson33/renshua/4N33RSA3.mp3")
    },
    {
        sentence: [
            {
                furi: "この　マークは　｜！とまれ｜　と　いう　｜＾意（い）味（み）｜です。",
                normal: "この　マークは　とまれ　と　いう　意味です。",
                en: "This mark means 'stop'.",
                mm: 'ဤအမှတ်အသားသည် "ရပ်တန့်ပါ"လို့ ဆိုလိုသည်။',
            },
            {
                furi: "この　マークは　｜＾水（みず）｜で　｜！あらっては　いけない｜　と　いう　｜＾意（い）味（み）｜です。",
                normal: "この　マークは　水で　あらっては　いけない　と　いう　意味です。",
                en: "This mark means that it should not be washed with water.",
                mm: "ဤအမှတ်အသားသည် ရေဖြင့်ဆေးကြောခြင်းမပြုရဟု ဆိုလိုသည်။",               
            },
            {
                furi: "この　マークは｜！リサイクルできる｜　と　いう　｜＾意（い）味（み）｜です。",
                normal: "この　マークは　リサイクルできる　と　いう　意味です。",
                en: "This mark means that it can be recycled.",
                mm: "ဤအမှတ်အသားသည် ပြန်လည်အသုံးပြုနိုင်သည်ဟု ဆိုလိုသည်။",
         
            },
        ],
        audioUrl: require("@assets/audio/lesson33/renshua/4N33RSA4.mp3")
    },
    {
        sentence: [
            {
                furi: "＾山（やま）｜＾田（だ）｜さんは　あした　５｜＾時（じ）｜に　｜！くる｜　と　｜＾言（い）｜って　いました。",
                normal: "山田さんは　あした　５時に　くる　と　言って　いました。",
                en: "Mr. Yamada said he will come tomorrow at 5:00.",
                mm: "မနက်ဖြန် ညနေ ၅ နာရီမှာ လာမယ်လို့ မစ္စတာ ယာမာဒါက ပြောပါတယ်။",
            },
            {
                furi: "＾山（やま）｜＾田（だ）｜さんは　｜＾締（し）｜め｜＾切（き）｜りに｜　｜！まに　あわない｜　と　｜＾言（い）｜って　いました。",
                normal: "山田さんは　締め切りに　まに　あわない　と　言って　いました。",
                en: "Mr. Yamada said he would not meet the deadline.",
                mm: "နောက်ဆုံး သတ်မှတ်ရက်ကို မီမှာ မဟုတ်ဘူးလို့ မစ္စတာ ယာမာဒါက ပြောပါတယ်။",
            },
            {
                furi: "＾山（やま）｜＾田（だ）｜さんは　きのう　荷物を｜！おくった｜と　｜＾言（い）｜って　いました。",
                normal: "山田さんは　きのう　荷物を　おくったと　言って　いました。",
                en: "Mr. Yamada said he sent the package yesterday.",
                mm: "မစ္စတာ ယာမာဒါက မနေ့က အထုပ်ကို ပို့လိုက်တယ်လို့ ပြောပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson33/renshua/4N33RSA5.mp3")
    },
    {
        sentence: [
            {
                furi: "＾田（た）中（なか）｜さんに　１０｜＾分（ぷん）｜ほど｜！おくれる｜と　｜＾伝（つた）｜えて　いただけませんか。",
                normal: "田中さんに　１０分ほど　おくれる　と　伝えて　いただけませんか。",
                en: "Could you tell Mr. Tanaka that he will be 10 minutes late?",
                mm: "10 မိနစ်နောက်ကျမယ်လို့ မစ္စတာ တာနာကာကိုပြောနိုင်မလား။",
            },
            {
                furi: "＾田（た）中（なか）｜さんに　｜＾土（ど）曜（よう）日（び）｜は　｜＾都（つ）合（ごう）｜が　｜！わるい｜と　｜＾伝（つた）｜えて　いただけませんか。",
                normal: "田中さんに　土曜日は　都合が　わるい　と　伝えて　いただけませんか。",
                en: "Could you tell Mr. Tanaka that Saturday is inconvenient?",
                mm: "စနေနေ့က အဆင်မပြေဘူးလို့ မစ္စတာ တာနာကာကို ပြောနိုင်မလား။",
            },
            {
                furi: "＾田（た）中（なか）｜さんに　｜＾締（し）｜め｜＾切（き）｜りは　｜！あさってだ｜と　｜＾伝（つた）｜えて　いただけませんか。",
                normal: "田中さんに　締め切りは　あさってだ　と　伝えて　いただけませんか。",
                en: "Could you tell Mr. Tanaka that the deadline is the day after tomorrow?",
                mm: "နောက်ဆုံးရက်က သဘက်ခါဖြစ်ကြောင်း မစ္စတာ တာနာကာကို ပြောနိုင်မလား။",
            },
        ],
        audioUrl: require("@assets/audio/lesson33/renshua/4N33RSA6.mp3")
    },
];

/*
    {
        sentence: [
            {
                furi: "",
                normal: "",
                en: "",
                mm: "",
            },
        ],
        audioUrl: require("@assets/audio/testing.mp3")
    },




*/