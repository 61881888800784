import { navItems } from "@components/header/NavbarComponent";
import { RootState } from "@store";
import { memo } from "react"; 
import { useSelector } from "react-redux";
import DesktopViewFooter from "./DesktopViewFooter";
import MobileTabComponent from "./MobileTabComponent";
const FooterComponent = ({ useNavigate, Logger, currentRoute }: any) => { 
    const { fontSize } = useSelector(({ service }: RootState) => service);
    const log = Logger(14);
    return (<>
        {/* Desktop View */}
        <DesktopViewFooter fontSize={fontSize} />
        {/* Mobile View */}
        <MobileTabComponent navItems={navItems} useNavigate={useNavigate} currentRoute={currentRoute} />

    </>)
}
export default memo(FooterComponent);