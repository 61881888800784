import Icon from "@consts/icon";

export const lesson42 = {
    title: {
        furi: 'ボーナスは　｜＾何（なん）｜に　｜＾使（つか）｜いますか',
        normal: 'ボーナスは　何に　使いますか',
        en: 'What will you use the bonus for?',
        mm: 'ဘောနပ်စ်ကို ဘာအတွက်သုံးမှာလည်း',
    },
    conversation: [
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '＾林（はやし）｜さん、ボーナスは　いつ　｜＾出（で）｜るんですか。',
                    normal: '林さん、ボーナスは　いつ　出るんですか。',
                    en: 'Mr. Hayashi, when will the bonus come out?',
                    mm: 'ဟရရှိစံ ဘောနပ်စ် ဘယ်တော့ရမှာလည်း',
                },
            ],
        },
        {
            person: {
                furi: '＾林（はやし）',
                normal: '林',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: '＾来（らい）週（しゅう）｜です。｜＾鈴（すず）木（き）｜さんの｜＾会（かい）社（しゃ）｜は？',
                    normal: '来週です。鈴木さんの会社は？',
                    en: "It's next week. What about Suzuki's company?",
                    mm: 'လာမယ့်အပတ်ရမှာပါ၊ စုစုခိစံရဲ့ ကုမ္ပဏီကကော?',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'あしたです。｜＾楽（たの）｜しみですね。',
                    normal: 'あしたです。楽しみですね。',
                    en: "It's tomorrow. I'm looking forward to it.",
                    mm: 'မနက်ဖြန်ပါ၊ အဲ့ဒါကိုစောင့်မျှော်နေတာပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾林（はやし）',
                normal: '林',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: 'ええ。｜＾鈴（すず）木（き）｜さんは　｜＾何（なん）｜に　｜＾使（つか）｜いますか。',
                    normal: 'ええ。鈴木さんは　何に　使いますか。',
                    en: 'Yeah. Suzuki-san, what do you use it for?',
                    mm: 'အင်း၊ စုစုခိစံက ဘာအတွက်သုံးမှာလည်း?',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'まず　｜＾新（あたら）｜しい　｜＾自（じ）転（てん）車（しゃ）｜を　｜＾買（か）｜って、それから　｜＾旅（りょ）行（こう）｜に　｜＾行（い）｜って。。。',
                    normal: 'まず　新しい　自転車を　買って、それから　旅行に　行って。。。',
                    en: 'At first I will buy a new bicycle, then go on a trip. . .',
                    mm: 'အရင်ဆုံး စက်ဘီးအသစ်တစ်စီးဝယ်မယ် ပြီးရင် ခရီးထွက်မယ် . . .',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）',
                normal: '小川',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: '＾貯（ちょ）金（きん）｜は　しないんですか。',
                    normal: '貯金は　しないんですか。',
                    en: "Don't you save money?",
                    mm: 'ငွေမစုတော့ဘူးလား?',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '＾僕（ぼく）｜は　あまり　｜＾考（かんが）｜えた　こと、ありませんね。',
                    normal: '僕は　あまり　考えた　こと、ありませんね。',
                    en: "I haven't given it much thought.",
                    mm: 'ကျွန်နော် သိပ်စဉ်းစားထားတာကမရှိဘူး',
                },
            ],
        },
        {
            person: {
                furi: '＾林（はやし）',
                normal: '林',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: 'わたしは　｜＾半（はん）分（ぶん）｜は　｜＾貯（ちょ）金（きん）｜する　つもりです。',
                    normal: 'わたしは　半分は　貯金する　つもりです。',
                    en: 'I plan to save half of my money.',
                    mm: 'ကျွန်မကတော့ တစ်ဝက်ကိုစုမယ်လို့ တွေးထားတယ်',
                },
            ],
        },
        {
            person: {
                furi: '＾鈴（すず）木（き）',
                normal: '鈴木',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'えっ、｜＾半（はん）分（ぶん）｜も　｜＾貯（ちょ）金（きん）｜するんですか。',
                    normal: 'えっ、半分も　貯金するんですか。',
                    en: 'Eh, are you going to save half of it?',
                    mm: 'ဟင် တစ်ဝက်တောင် စုမှာလား?',
                },
            ],
        },
        {
            person: {
                furi: '＾林（はやし）',
                normal: '林',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: 'ええ、いつか　イギリスへ　｜＾留（りゅう）学（がく）｜しようと　｜＾思（おも）｜って　いるです。',
                    normal: 'ええ、いつか　イギリスへ　留学しようと　思って　いるです。',
                    en: "Yes, I'm thinking of studying abroad in England someday.",
                    mm: 'အင်း တစ်နေ့နေ့မှာ အင်္ဂလန်မှာ ကျောင်းသွားတက်ဖို့ စဉ်းစားထားလို့ပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾小（お）川（がわ）',
                normal: '小川',
                avatar: Icon.Kaiwa.MEN2
            },
            sentence: [
                {
                    furi: 'へえ、｜＾独（どく）身（しん）｜の　｜＾人（ひと）｜は　いいですね。｜＾全（ぜん）部（ぶ）｜　｜＾自（じ）分（ぶん）｜の　ために、｜＾使（つか）｜えて。',
                    normal: 'へえ、独身の　人は　いいですね。全部　自分の　ために、使えて。',
                    en: 'Hmm, single people are nice. Use everything for your own girlfriend.',
                    mm: 'ဟမ်၊ လူပျိုလူလွတ်တွေက ကောင်းလိုက်တာ၊ အားလုံးကိုကိုယ့်အတွက် သုံးနိုင်တယ်',
                },
                {
                    furi: 'わたしは　｜＾家（いえ）｜の　ローンを　｜＾払（はら）｜って　｜＾子（こ）｜どもの　｜＾教（きょう）育（いく）｜の　ために、｜＾貯（ちょ）金（きん）｜したら、ほとんど　｜＾残（のこ）｜りませんよ。',
                    normal: 'わたしは　家の　ローンを　払って　子どもの　教育の　ために、貯金したら、ほとんど　残りませんよ。',
                    en: "If I pay my house loan and save money for my children's education, there will be almost nothing left.",
                    mm: 'ကျွန်တော်ကတော့ အိမ်ချေးငွေနဲ့ သားသမီးတွေရဲ့ ပညာရေးအတွက် ငွေစုလိုက်ရင် ဘာမှ မကျန်တော့ဘူး။',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson42/kaiwa/4N42KW.mp3")
}
/*
{
    title: {
        furi: '',
        normal: '',
        en: '',
        mm: '',
    },
    conversation: [
        {
            person: {
                furi: '',
                normal: '',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '',
                    normal: '',
                    en: '',
                    mm: '',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson36/kaiwa/4N36KW.mp3")
}
*/