import { memo } from "react";

const TitleComponent = ({furigana,furi,normal,furiTranslate,fontSize,lesson}: any)=>{
    return (
        <div key={Math.random()}>
            <div className={`absolute left-0 px-[10px] md:px-[18px] py-[5px] md:py-[8px] rounded-r-[100px] text-[${fontSize}px] bg-[#0084E8] bunkei-title-light text-white w-fit`}>
                {furigana === 'true' ? furiTranslate({ context: furi + "｜ "+lesson, fontSize, style: "flex-wrap",notUseLibrary: true }) : normal + " " + lesson}
            </div>
        </div>
    );
}
export default memo(TitleComponent);