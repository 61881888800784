import Icon from "@consts/icon";

export const lesson48 = {
    title: {
        furi: '＾休（やす）｜ませて　いただけませんか',
        normal: '休ませて　いただけませんか',
        en: '',
        mm: 'ခွင့်ယူခွင့်လေးပေးလို့ရမလား',
    },
    conversation: [
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '＾課（か）長（ちょう）｜、｜＾今（いま）｜お｜＾忙（いぞが）｜しいですか。',
                    normal: '課長、今お忙しいですか。',
                    en: '',
                    mm: 'မန်နေဂျာကြီး၊ အခု အလုပ်များနေလားမသိဘူးနော်',
                },
            ],
        },
        {
            person: {
                furi: '＾中（なか）村（むら）課（か）長（ちょう）',
                normal: '中村課長',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'いいえ、どうぞ。',
                    normal: 'いいえ、どうぞ。',
                    en: '',
                    mm: 'ဟင့်အင်း လာပါ',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'ちょっと　お｜＾願（ねが）｜いが　あるんですが。。。',
                    normal: 'ちょっと　お願いが　あるんですが。。。',
                    en: '',
                    mm: 'နဲနဲအကူအညီလေးတောင်းချင်လို့ပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾中（なか）村（むら）課（か）長（ちょう）',
                normal: '中村課長',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: '＾何（なん）｜ですか。',
                    normal: '何ですか。',
                    en: '',
                    mm: 'ဘာများလဲ?',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'あのう、｜＾来（らい）月（げつ）｜　｜＾７（なの）日（か）｜から　｜＾１０（とお）日（か）｜ほど　｜＾休（やす）｜みを　｜＾取（と）｜らせて　いただけませんか。',
                    normal: 'あのう、来月　７日から　１０日ほど　休みを　取らせて　いただけませんか。',
                    en: '',
                    mm: 'ဟိုလေ၊ နောက်လ ၇ ရက်နေ့ကနေစပြီး ၁၀ရက်လောက် ခွင့်ယူလို့ရမလား?',
                },
            ],
        },
        {
            person: {
                furi: '＾中（なか）村（むら）課（か）長（ちょう）',
                normal: '中村課長',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: '＾１０（とお）日（か）間（かん）｜ですか。',
                    normal: '１０日間ですか。',
                    en: '',
                    mm: '၁၀ရက် လား?',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: '＾実（じつ）｜は　アメリカの　｜＾友（とも）達（だち）｜が　｜＾結（けっ）婚（こん）｜するんです。',
                    normal: '実は　アメリカの　友達が　結婚するんです。',
                    en: '',
                    mm: 'တစ်ကယ်က အမေရိကက သူငယ်ချင်းက လက်ထပ်မှာမို့ပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾中（なか）村（むら）課（か）長（ちょう）',
                normal: '中村課長',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'そうですか',
                    normal: 'そうですか',
                    en: '',
                    mm: 'ဟုတ်လား',
                },
                {
                    furi: 'えーと、｜＾来（らい）月（げつ）｜は　｜＾20（はつ）日（か）｜に　｜＾営（えい）業（ぎょう）会（かい）議（ぎ）｜が　ありますが、それまでに　｜＾帰（かえ）｜れますね。',
                    normal: 'えーと、来月は　20日に　営業会議が　ありますが、それまでに　帰れますね。',
                    en: '',
                    mm: 'ကောင်းပြီ၊ နောက်လ ၂၀ ရက်နေ့မှာ အရောင်းအစည်းအဝေးရှိတယ် အဲ့ဒီရက်မတိုင်ခင်က ခွင့်ပြန်လို့ရတယ်',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'はい。',
                    normal: 'はい。',
                    en: '',
                    mm: 'ဟုတ်ကဲ့',
                },
            ],
        },
        {
            person: {
                furi: '＾中（なか）村（むら）課（か）長（ちょう）',
                normal: '中村課長',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'じゃ、かまいませんよ。｜＾楽（たの）｜しんで　｜＾来（き）｜て　ください。',
                    normal: 'じゃ、かまいませんよ。楽しんで　来て　ください。',
                    en: '',
                    mm: 'ကဲ ကိစ္စမရှိပါဘူး၊ ပျော်ပြီး ပြန်လာခဲ့ပါ',
                },
            ],
        },
        {
            person: {
                furi: 'ミラー',
                normal: 'ミラー',
                avatar: Icon.Kaiwa.MEN1
            },
            sentence: [
                {
                    furi: 'ありがとう　ございます。',
                    normal: 'ありがとう　ございます。',
                    en: '',
                    mm: 'ကျေးဇူးအများကြီးတင်ပါတယ်',
                },
            ],
        }
    ],
    audioUrl: require("@assets/audio/lesson48/kaiwa/4N48KW.mp3")
}
/*
{
    title: {
        furi: '',
        normal: '',
        en: '',
        mm: '',
    },
    conversation: [
        {
            person: {
                furi: '',
                normal: '',
                avatar: Icon.Kaiwa.OFFICER
            },
            sentence: [
                {
                    furi: '',
                    normal: '',
                    en: '',
                    mm: '',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson36/kaiwa/4N36KW.mp3")
}
*/
