export const bunkei= [
    {
        sentence: [
            {
                furi: '＾窓（まど）｜が　｜！閉（し）まっています｜。',
                normal: '窓が　閉まって　います。',
                en: 'The window is closed.',
                mm: 'ပြူတင်းပေါက်က ပိတ်နေပါသည်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson29/bunpou/4N29BK1.mp3")
    },
    {
        sentence: [
            {
                furi: '＾電（でん）車（しゃ）｜に　｜＾傘（かさ）｜を　｜！忘（わす）れて　しまいました｜。',
                normal: '電車に　傘を　忘れて　しまいました。',
                en: 'I forgot my umbrella on the train.',
                mm: 'ရထားပေါ်မှာ ထီးမေ့သွားတယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson29/bunpou/4N29BK2.mp3")
    },
];

/*

{
    furi: "",
    normal: "",
    en: "",
    mm: "",
},

*/