import Icon from "@consts/icon";

export const lesson43 = {
    title: {
        furi: '＾毎（まい）日（にち）｜　｜＾楽（たの）｜しそうです',
        normal: '毎日　楽しそうです',
        en: "It looks like it's going to be fun every day",
        mm: 'နေ့တိုင်း ပျော်နေပုံပဲ',
    },
    conversation: [
        {
            person: {
                furi: '＾林（はやし）',
                normal: '林',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: 'これ、どなたの｜＾写（しゃ）真（しん）｜ですか。',
                    normal: 'これ、どなたの写真ですか。',
                    en: 'Whose photo is this?',
                    mm: 'ဒီဟာက ဘယ်သူ့ ဓါတ်ပုံပါလဲ?',
                },
            ],
        },
        {
            person: {
                furi: 'シュミット',
                normal: 'シュミット',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '｜＾息（むす）子（こ）｜の　ハンスです。｜＾運（うん）動（どう）会（かい）｜の　とき、｜＾撮（と）｜った　｜＾写（しゃ）真（しん）｜です。',
                    normal: '息子の　ハンスです。運動会の　とき、　撮った　写真です。',
                    en: 'This is my son Hans. This is a picture I took of him at the sports day.',
                    mm: 'သားလေး ဟန်စုပါ၊ အားကစားပွဲမှာ ရိုက်ထားတဲ့ပုံပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾林（はやし）',
                normal: '林',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: '＾元（げん）気（き）｜そうですね',
                    normal: '元気そうですね',
                    en: "You're looking well",
                    mm: 'ကျန်းမာတဲ့ပုံပဲ',
                },
            ],
        },
        {
            person: {
                furi: 'シュミット',
                normal: 'シュミット',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'ええ。ハンスは　｜＾走（はし）｜るのが　｜＾速（はや）｜いんですよ。',
                    normal: 'ええ。ハンスは　走るのが　速いんですよ。',
                    en: 'Yeah. Hans runs fast.',
                    mm: 'အင်း၊ ဟန်စုက အပြေးမြန်တယ်လေ',
                },
                {
                    furi: '＾日（に）本（ほん）｜の　｜＾小（しょう）学（がっ）校（こう）｜にも　｜＾慣（な）｜れて、　｜＾友（とも）達（たち）｜も　できて、｜＾毎（まい）日（にち）　楽（たの）｜しそうです。',
                    normal: '日本の　小学校にも　慣れて、　友達も　できて、毎日　楽しそうです。',
                    en: "He is getting used to Japanese elementary school, I've made friends, and every day seems to be a lot of fun.",
                    mm: 'ဂျပန်ရဲ့ မူလတန်းကျောင်းမှာ အသားကျပြီး သူငယ်ချင်းတွေရပြီးတော့ နေ့တိုင်းပျော်နေပုံပဲ',
                },
            ],
        },
        {
            person: {
                furi: '＾林（はやし）',
                normal: '林',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: 'よかたですね。こちらの　｜＾方（かた）｜は　｜＾奥（おく）｜さんですか。きれいな　｜＾方（かた）｜ですね。',
                    normal: 'よかたですね。こちらの　方は　奥さんですか。きれいな　方ですね。',
                    en: "It's good. Is this the wife? She's pretty.",
                    mm: 'တော်သေးတာပေါ့၊ ဒီဘက်ကတစ်ယောက်က အမျိုးသမီးလား? ချောမောလှပတဲ့အမျိုးသမီးပဲနော်',
                },
            ],
        },
        {
            person: {
                furi: 'シュミット',
                normal: 'シュミット',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: 'ありがとう　ございます。',
                    normal: 'ありがとう　ございます。',
                    en: 'thank you.',
                    mm: 'ကျေးဇူးတင်ပါတယ်',
                },
                {
                    furi: '＾妻（つま）｜は　いろいろな　ことに　｜＾興（きょう）味（み）｜が　あって、　いっしょに　いると、おもしろいんですよ。',
                    normal: '妻は　いろいろな　ことに　興味が　あって、　いっしょに　いると、おもしろいんですよ。',
                    en: "My wife is interested in many things, and it's fun to be with her.",
                    mm: 'အမျိုးသမီးက အမျိုးမျိုးကိစ္စတွေစိတ်ဝင်စားပြီးတော့ သူ့နဲ့အတူရှိရတာ‌ပျော်စရာကောင်းပါတယ်',
                },
            ],
        },
        {
            person: {
                furi: '＾林（はやし）',
                normal: '林',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: 'そうですか。',
                    normal: 'そうですか。',
                    en: 'Is that so.',
                    mm: 'ဟုတ်လား',
                },
            ],
        },
        {
            person: {
                furi: 'シュミット',
                normal: 'シュミット',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '＾特（とく）｜に　｜＾歴（れき）史（し）｜が　｜＾好（す）｜きで、｜＾時（じ）間（かん）｜が　あれば、｜＾古（ふる）｜い　｜＾町（まち）｜を　｜＾歩（ある）｜いて　います。',
                    normal: '特に　歴史が　好きで、時間が　あれば、古い　町を　歩いて　います。',
                    en: 'I especially like history, and if I have time, I like to walk around old towns.',
                    mm: 'အထူးသဖြင့် သမိုင်းကိုစိတ်ဝင်စားပြီးတော့ အချိန်ရရင် မြို့ဟောင်းတွေကို လျှောက်လည်လေ့ရှိပါတယ်',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson43/kaiwa/4N43KW.mp3")
}
/*
{
    title: {
        furi: '',
        normal: '',
        en: '',
        mm: '',
    },
    conversation: [
        {
            person: {
                furi: '',
                normal: '',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '',
                    normal: '',
                    en: '',
                    mm: '',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson36/kaiwa/4N36KW.mp3")
}
*/