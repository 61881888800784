import { BunpouType } from "..";

export const bunpou: BunpouType = {
  mondai1:{
    title: 'もんだい１（　　）に　何を　入れますか。１・２・３・４から　いちばん　いい　ものを　ーつ　えらんで　ください。',
    questions: [
      {
        number: 1,
        question: [
          '(1)母が　育てた　トマト（　　）ジュースを　作りました。',
        ],
        answers: ['で','に','へ','や'],
        pick: '',
        correct: 'で'
      },
      {
        number: 2,
        question: ['(2)この　かばんは　軽いから、旅行（　　）　便利です。'],
        answers: ['と','を','に','の'],
        pick: '',
        correct: 'に'
      },
      {
        number: 3,
        question: [
          '(3)（教室で）',
          '先生「じゃあ、一人（　　）　じこしょうかいを　して　ください。」'
        ],
        answers: ['など','ずつ','しか','も'],
        pick: '',
        correct: 'ずつ'
      },
      {
        number: 4,
        question: [
          '(4)（料理教室で）',
          '先生「今日は　だれ（　　）　かんたんに　作れる　ケーキの　作り方を　教えます。」'
        ],
        answers: ['で','は','でも','なら'],
        pick: '',
        correct: 'でも'
      },
      {
        number: 5,
        question: ['(5)いっしょうけんめい　勉強して、（　　）　外国の　大学に　行きたいです。'],
        answers: ['すっかり','たぶん','やっと','いつか'],
        pick: '',
        correct: 'いつか'
      },
      {
        number: 6,
        question: ['(6)今朝、ねぼうして　しまったので、学校まで　走っていったが、（　　）　ちこくした。'],
        answers: ['かならず','ときどき','そろそろ','やはり'],
        pick: '',
        correct: 'やはり'
      },
      {
        number: 7,
        question: [
          '(7)（電話で）',
          'A「もしもし、これから（　　）に　行くよ。」',
          'B「じゃあ、駅に　着いたら、電話を　ください。私の　家は　駅から　違いので、車で　むかえに　いきます。」',
        ],
        answers: ['こっち','そっち','あっち','どっち'],
        pick: '',
        correct: 'そっち'
      },
      {
        number: 8,
        question: ['(8) あしたは　朝、会議が　あるから、（　　）家を　出ます。'],
        answers: ['早くて','早く','早い','早いと'],
        pick: '',
        correct: '早く'
      },
      {
        number: 9,
        question: ['(9) 田中さんは　体が　大きくて　こわそう（　　）、本当は　こわくありません。'],
        answers: ['し','だし','だげれど','けれど'],
        pick: '',
        correct: 'だげれど'
      },
      {
        number: 10,
        question: ['(10) インターネットラジオを　（　　）ために、パソコンを　買いました。'],
        answers: ['聞きます','聞こう','聞く','聞ける'],
        pick: '',
        correct: '聞く'
      },
      {
        number: 11,
        question: ['(11) もらった　りんごを　ジャムに（　　）　食べました。'],
        answers: ['して','されて','なって','なれて'],
        pick: '',
        correct: 'して'
      },
      {
        number: 12,
        question: ['(12) 最近、私は　仕事が　いそがしくて、なかなか　ともだちと（　　）。'],
        answers: ['会える','会えない','会う','会った'],
        pick: '',
        correct: '会えない'
      },
      {
        number: 13,
        question: [
          '(13) 山本「田中さん、元気が　ありませんね。どう（　　）か。」',
          '田中「ええ、かぜを　ひきました。」',
        ],
        answers: ['でしょう','なります','します','したんです'],
        pick: '',
        correct: 'したんです'
      },
      {
        number: 14,
        question: [
          '(14)こども「ご飯　まだ？」',
          '母「うん。今から（　　）だよ。」',
        ],
        answers: ['作るところ','作って　いるところ','作るとき','作って　いるとき'],
        pick: '',
        correct: '作るところ'
      },
      {
        number: 15,
        question: [
          '(15)田中「木村さん、荷物が　重そうだね。（　　）。」',
          '木村「ありがとうございます。」',
        ],
        answers: ['てつだいたいんですか','てつだって　くださいよ','てつだったよ','てつだおうか'],
        pick: '',
        correct: 'てつだおうか'
      },
    ]
  },
  mondai2:{
    title: 'もんだい（2）｜！ ★ ｜ に　何を　入れますか。１・２・３・４から　いちばん　いい　ものを　ーつ　えらんで　ください。',
    questions: [
      {
        number: 16,
        question: [
          '(16)れいぞうこに｜！＿＿★＿｜ので、食べました。',
        ],
        answers: ['ケーキが','父が','買ってきた','残っていた'],
        pick: '',
        correct: 'ケーキが'
      },
      {
        number: 17,
        question: [
          '(17)大事な｜！＿＿★＿｜が　かかって　きた。',
        ],
        answers: ['電話','とちゅう','話の','で'],
        pick: '',
        correct: 'で'
      },
      {
        number: 18,
        question: [
          '(18)（学校で）',
          '先生「ろうかが　います。歩く｜！＿＿★＿｜して　ください。」',
        ],
        answers: ['ように','注意','ときは','すべらない'],
        pick: '',
        correct: 'ように'
      },
      {
        number: 19,
        question: [
          '(19)来週、会社の｜！＿＿★＿｜しました。',
        ],
        answers: ['近くに','する','ことに','ひっこしを'],
        pick: '',
        correct: 'する'
      },
      {
        number: 20,
        question: [
          '(20)北町に｜！＿＿★＿｜そうです。',
        ],
        answers: ['和食の　お店は','「さくら」と　いう','有名だ','ある'],
        pick: '',
        correct: '和食の　お店は'
      },
    ]
  },
}