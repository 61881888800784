export const explain= [
    {
        pattern_furi: '可能動詞(かのうどうし)',
        pattern_normal: '可能動詞',
        en: [
            '　Changing a form of a verb into "Can Form".',
            '　可能動詞(かのうどうし) is often preceded by “が”.'
        ],
        mm: [ 
            '　ကြိယာပုံစံ တစ်ခုကို “နိုင်/တတ်/လို့ရတယ်” စသည့် ကြိယာပုံစံသို့ ပြောင်းလဲ၍ သုံးခြင်းကို ခေါ်သည်။',
            '　可能動詞(かのうどうし) ၏အရှေ့တွင် “が” အလိုက်များသည်။'
        ]
    },
    {
        pattern_furi: '～できました',
        pattern_normal: '～できました',
        en: [
            '　～できました is often preceded by “が”.',
            '　It is used when something is there that was not there before..',
        ],
        mm: [
            '　အရှေ့တွင် “が” အလိုက်များသည်။',
            '　အရင်တုန်းက မရှိခဲ့တဲ့ အရာတစ်ခုက အဲဒီနေရာမှာ ရှိလာတာမျိုးမှာ သုံးပါတယ်။',
        ]
    },
    {
        pattern_furi: '～しか',
        pattern_normal: '～しか',
        en: [
            '　It is often used when there are few choices or you want to express small number.',
            '　“しか” is usually followed by a negative verb.'
        ],
        mm: [
            '　(～က လွဲပြီး)ရွေးချယ်စရာနည်းသောအခါမျိုး၊ အရေအတွက်အားဖြင့် နည်းသည်ဟု ဖေါ်ပြရာတွင် သုံးသည်။',
            '　“しか” ၏နောက်တွင် အငြင်းပုံစံ ကြိယာသာလိုက်လေ့ရှိသည်။'
        ]
    }
]