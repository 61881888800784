import { memo } from "react";
import SettingScreenLayout from "@layouts/setting/SettingScreenLayout";
import SettingWebComponent from "@components/setting/SettingWebComponent";
import SettingMobileComponent from "@components/setting/SettingMobileComponent";
import SizeBox from "@components/SizeBox";

const SettingScreen = ({ Logger, showModel, setShowModel,setUnlockPremium, Routes, useNavigate, lang, fontSize, changeLanguage, furigana, toggleFuri, changeFontSize,dark,toggleTheme }: any) => {
    // const log = Logger(4);
    const navigate = useNavigate();
    return (
        <SettingScreenLayout
            body={
                <div className="container mx-auto">
                    <div className="text-right text-[18px] md:text-[20px] text-gray-600 dark:text-gray-400">Version : {require("../../../package.json").version}</div>
                    <SizeBox vertical="h-[16px] md:h-[20px]"/>
                    {/* Web View */}
                    <SettingWebComponent
                        dark={dark}
                        toggleTheme={toggleTheme}
                        fontSize={fontSize}
                        lang={lang}
                        changeLanguage={changeLanguage}
                        changeFontSize={changeFontSize}
                    />
                 
                    {/* Mobile View */}
                   <SettingMobileComponent navigate={navigate} Routes={Routes}/>
                </div>
            }
            Routes={Routes}
            Logger={Logger}
            useNavigate={useNavigate}
            showModel={showModel}
            setShowModel={setShowModel}
            setUnlockPremium={setUnlockPremium}
        />
    )
}
export default memo(SettingScreen);