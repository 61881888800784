export const explain= [
    {
        pattern_furi: '～ながら',
        pattern_normal: '～ながら',
        en: [
            '　doing one while doing another.',
            '　Remove the まろ from the ます form of the verb and combine it with ながら.'
        ],
        mm: [
            '　(～ရင်း) တစ်ခုကိုလုပ်ရင်းနဲ့ နောက်တစ်ခုကိုလည်းလုပ်နေ',
            '　ကြိယာ ますform မှ ます ကိုဖြုတ်ပြီး ながらနှင့်ပေါင်း'
        ]
    },
    {
        pattern_furi: '～し、～し',
        pattern_normal: '～し、～し',
        en: [
            '　なadj, noun must be followed by だし.'
        ],
        mm: [
            '　なadj, noun ၏နောက်တွင် だし ဖြင့် ဆက်ပေးရမည်။',
        ]
    },
    {
        pattern_furi: 'Vて　+　いただけませんか',
        pattern_normal: 'Vて　+　いただけませんか',
        en: [
            '　Can\'t you do it ? Used to ask for help.',
            '　can be used after Vて.'
        ],
        mm: [
            '　လုပ်ပေးလို့မရဘူးလား။ အကူအညီ တောင်းခံရာတွင် သုံးသည်။',
            '　Vて၏နောက်တွင် သုံးနိုင်သည်။'
        ]
    }
]