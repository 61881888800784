
export const renshuuA = [
    {
        sentence: [
            {
                furi: "＾病（びょう）院（いん）｜へ　｜！いった｜　ほうが　いいです。",
                normal: "病院へ　いった　ほうが　いいです。",
                en: "You had better go to the hospital.",
                mm: "ဆေးခန်းသွားပြတာ ပိုကောင်းပါတယ်။",
            },
            {
                furi: "＾薬（くすり）｜を　｜！のんだ｜　ほうが　いいです。",
                normal: "薬を　のんだ　ほうが　いいです。",
                en: "It's better to take medicine.",
                mm: "ဆေးသောက်တာ ပိုကောင်းပါတယ်။",
            },
            {
                furi: "たばこを　｜！すわない｜　ほうが　いいです。",
                normal: "たばこを　すわない　ほうが　いいです。",
                en: "It's better not to smoke.",
                mm: "ဆေးလိပ်မသောက်တာ ပိုကောင်းပါတယ်။",
            },
            {
                furi: "おふろに　｜！はいらない｜　ほうが　いいです。",
                normal: "おふろに　はいらない　ほうが　いいです。",
                en: "It's better not to take a bath.",
                mm: "ရေမချိုးတာ ပိုကောင်းပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson32/renshua/4N32RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾今（こん）夜（や）｜は　｜＾星（ほし）｜が　｜！みえる｜　でしょう",
                normal: "今夜は　星が　みえる　でしょう",
                en: "You might see the stars tonight",
                mm: "ဒီည ကြယ်တွေကို မြင်ရလိမ့်မယ်။",
            },
            {
                furi: "＾今（こん）夜（や）｜は　｜＾雪（ゆき）｜が　｜！ふらない｜　でしょう",
                normal: "今夜は　雪が　ふらない　でしょう",
                en: "It won't snow tonight",
                mm: "ဒီည နှင်းမကျဘူး။",
            },
            {
                furi: "＾今（こん）夜（や）｜は　｜！さむい｜　でしょう",
                normal: "今夜は　さむい　でしょう",
                en: "It will be cold tonight",
                mm: "ဒီည အေးနေလိမ့်မယ်။",
            },
            {
                furi: "＾今（こん）夜（や）｜は　｜＾月（つき）｜が　｜！きれい｜　でしょう",
                normal: "今夜は　月が　きれい　でしょう",
                en: "The moon will be beautiful tonight",
                mm: "ဒီည လဟာ လှပနေလိမ့်မယ်။",
            },
            {
                furi: "＾今（こん）夜（や）｜は　｜！あめ｜　でしょう",
                normal: "今夜は　あめ　でしょう",
                en: "It will rain tonight",
                mm: "ဒီည မိုးရွာလိမ့်မယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson32/renshua/4N32RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾彼（かれ）｜は　｜＾会（かい）社（しゃ）｜を　｜！やめる｜かも　しれません。",
                normal: "彼は　会社を　やめる　かも　しれません。",
                en: "He may quit the company.",
                mm: "သူ ကုမ္ပဏီကနေ နှုတ်ထွက်နိုင်တယ်။",
            },
            {
                furi: "＾彼（かれ）｜は　パーティーに｜！こない｜かも　しれません。",
                normal: "彼は　パーティーに　こない　かも　しれません。",
                en: "He may not come to the party.",
                mm: "သူ ပါတီကို မလာလောက်ဘူး။",
            },
            {
                furi: "＾彼（かれ）｜は　あした｜！いそがしい｜かも　しれません。",
                normal: "彼は　あした　いそがしい　かも　しれません。",
                en: "He may be busy tomorrow.",
                mm: "မနက်ဖြန် သူ အလုပ်ရှုပ်နေနိုင်တယ်။",
            },
            {
                furi: "＾彼（かれ）｜は　｜＾来（らい）週（しゅう）｜！ひま｜かも　しれません。",
                normal: "彼は　来週　ひま　かも　しれません。",
                en: "He may be free next week.",
                mm: "သူက နောက်တစ်ပတ် အားလောက်တယ်။",
            },
            {
                furi: "＾彼（かれ）｜は　｜！びょうき｜かも　しれません。",
                normal: "彼は　びょうき　かも　しれません。",
                en: "He may be sick",
                mm: "သူ နေမကောင်းဖြစ်နိုင်တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson32/renshua/4N32RSA3.mp3")
    },
];

/*
    {
        sentence: [
            {
                furi: "",
                normal: "",
                en: "",
                mm: "",
            },
        ],
        audioUrl: require("@assets/audio/testing.mp3")
    }
*/