export const reibun= [
    {
        sentence: [
            {
                furi: "＾日（にっ）記（き）｜を　｜＾続（つづ）｜けて　いますか。",
                normal: "日記を　続けて　いますか。",
                en: "Are you keeping a diary?",
                mm: "မင်း ဒိုင်ယာရီဆက်ရေးနေသေးလား။",
            },
            {
                furi: "いいえ、　｜＾3（みっ）日（か）｜で　やめて　しまいました。",
                normal: "いいえ、　3日で　やめて　しまいました。",
                en: "No, I quit after 3 days.",
                mm: "မဟုတ်ဘူး၊ ငါ ၃ ရက်ပြီးရင် ရပ်လိုက်တယ်။",
            },
            {
                furi: "＾始（はじ）｜めるのは　｜＾簡（かん）単（たん）｜ですが、　｜＾続（つづ）｜けるのは　｜＾難（むずか）｜しいですね。",
                normal: "始めるのは　簡単ですが、　続けるのは　難しいですね。",
                en: "It's easy to start, but hard to keep going.",
                mm: "စတင်ဖို့ လွယ်ကူပေမဲ့ ဆက်လုပ်ဖို့ ခက်ခဲတယ်နော်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson38/bunpou/4N38RB1.mp3")
    },
    {
        sentence: [
            {
                furi: "きれいな　｜＾庭（にわ）｜ですね。",
                normal: "きれいな　庭ですね。",
                en: "It's a beautiful garden.",
                mm: "လှပတဲ့ ဥယျာဉ်တစ်ခုပါ။",
            },
            {
                furi: "ありがとう　ございます。",
                normal: "ありがとう　ございます。",
                en: "thank you.",
                mm: "ကျေးဇူးတင်ပါတယ်။",
            },
            {
                furi: "＾夫（おっと）｜は　｜＾花（はな）｜を　｜＾育（そだ）｜てるのが　｜＾上（じょう）手（ず）｜なんです。",
                normal: "夫は　花を　育てるのが　上手なんです。",
                en: "My husband is good at growing flowers.",
                mm: "ကျွန်မခင်ပွန်းက ပန်းပင်တွေ စိုက်တာ တော်တယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson38/bunpou/4N38RB2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾東（とう）京（きょう）｜は　どうですか。",
                normal: "東京は　どうですか。",
                en: "How about Tokyo?",
                mm: "တိုကျိုကော ဘယ်လိုလဲ။",
            },
            {
                furi: "＾人（ひと）｜が　｜＾多（おお）｜いですね。それに　みんな　｜＾歩（ある）｜くのが　｜＾速（はや）｜いですね。",
                normal: "人が　多いですね。それに　みんな　歩くのが　速いですね。",
                en: "There are many people. Besides, everyone walks fast.",
                mm: "လူတွေအများကြီးဘဲနော်။ ဒါ့အပြင် လူတိုင်း လမ်းလျှောက်တာက မြန်ကြတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson38/bunpou/4N38RB3.mp3")
    },
    {
        sentence: [
            {
                furi: "あ、いけない。",
                normal: "あ、いけない。",
                en: "Oh no.",
                mm: "အာ မဖြစ်ဘူး။",
            },
            {
                furi: "どう　したんですか。",
                normal: "どう　したんですか。",
                en: "What happened.",
                mm: "ဘာဖြစ်တာလဲ။",
            },
            {
                furi: "＾車（くるま）｜の　｜＾窓（まど）｜を　｜＾閉（し）｜めるのを　｜＾忘（わす）｜れました。",
                normal: "車の　窓を　閉めるのを　忘れました。",
                en: "I forgot to close the car window.",
                mm: "ကားပြတင်းပေါက်ပိတ်ဖို့ မေ့သွားတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson38/bunpou/4N38RB4.mp3")
    },
    {
        sentence: [
            {
                furi: "＾宮（みや）崎（ざき）｜さんに　｜＾赤（あか）｜ちゃんが　｜＾生（う）｜まれたのを　｜＾知（し）｜って　いますか。",
                normal: "宮崎さんに　赤ちゃんが　生まれたのを　知って　いますか。",
                en: "Did you know that Miyazaki had a baby?",
                mm: "Miyazaki-san ကလေးမွေးတယ်ဆိုတာ မင်းသိလား။",
            },
            {
                furi: "いいえ、｜＾知（し）｜りませんでした。いつですか。",
                normal: "いいえ、知りませんでした。いつですか。",
                en: "No I didn't know. when?",
                mm: "မဟုတ်ဘူး ငါမသိခဲ့ဘူး။ ဘယ်တုန်းကလဲ။",
            },
            {
                furi: "１か｜＾月（げつ）｜ぐらいまえです。",
                normal: "１か月ぐらいまえです。",
                en: "About a month ago.",
                mm: "လွန်ခဲ့သော တစ်လခန့်က။",
            },
        ],
        audioUrl: require("@assets/audio/lesson38/bunpou/4N38RB5.mp3")
    },
    {
        sentence: [
            {
                furi: "＾初（はじ）｜めて　｜＾好（す）｜きに　なった　｜＾人（ひと）｜の　ことを　｜＾覚（おぼ）｜えて　いますか。",
                normal: "初めて　好きに　なった　人の　ことを　覚えて　いますか。",
                en: "Do you remember the first person you fell in love with?",
                mm: "ပထမဆုံး ချစ်မိသွားတဲ့လူကို မှတ်မိသေးလား။",
            },
            {
                furi: "ええ。｜＾彼（かの）女（じょ）｜に　｜＾初（はじ）｜めて　｜＾会（あ）｜ったのは　｜＾小（しょう）学（がっ）校（こう）｜の　｜＾教（きょう）室（しつ）｜です。",
                normal: "ええ。彼女に　初めて　会ったのは　小学校の　教室です。",
                en: "Yeah. The first time I met her was in my elementary school classroom.",
                mm: "အင်း။ မူလတန်းကျောင်း စာသင်ခန်းမှာ သူမကို ပထမဆုံးတွေ့ခဲ့တယ်။",
            },
            {
                furi: "＾彼（かの）女（じょ）｜は　｜＾音（おん）楽（がく）｜の　｜＾先（せん）生（せい）｜でした。",
                normal: "彼女は　音楽の　先生でした。",
                en: "She was a music teacher.",
                mm: "သူမသည် ဂီတဆရာတစ်ဦးဖြစ်သည်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson38/bunpou/4N38RB6.mp3")
    },
]