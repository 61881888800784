export const reibun= [
    {
        sentence: [
            {
                furi: '＾日（に）本（ほん）語（ご）｜の　｜＾新（しん）聞（ぶん）｜が　｜！読（よ）め｜ますか。',
                normal: '日本語の　新聞が　読めますか。',
                en: 'Can you read Japanese newspapers?',
                mm: 'ဂျပန်သတင်းစာကို ဖတ်နိုင်လား?',
            },
            {
                furi: '。。。いいえ、｜！読（よ）めません。',
                normal: '。。。いいえ、読めません。',
                en: '. . . No, I can\'t read it.',
                mm: 'ဟင့်အင်း မဖတ်နိုင်ပါဘူး။',
            },
        ],
        audioUrl: require("@assets/audio/lesson27/bunpou/4N27RB1.mp3")
    },
    {
        sentence: [
            {
                furi: '＾鳥（とり）｜の　｜＾声（こえ）｜が　｜！聞（き）こえ｜ますね。',
                normal: '鳥の　声が　聞こえますね。',
                en: 'You can hear the birds.',
                mm: 'ငှက်ရဲ့အသံက ကြားနေရတယ်နော်။',
            },
            {
                furi: '。。。ええ。もう　｜＾春（はる）｜ですね。',
                normal: '。。。ええ。もう　春ですね。',
                en: '. . . yes. It is already spring.',
                mm: 'ဟုတ်တယ် နွေဦးတောင်ရောက်ပြန်ပြီလေ။',
            },
        ],
        audioUrl: require("@assets/audio/lesson27/bunpou/4N27RB2.mp3")
    },
    {
        sentence: [
            {
                furi: '＾法（ほう）隆（りゅう）寺（じ）｜は　いつ　｜！でき｜ましたか。',
                normal: '法隆寺は　いつ　できましたか。',
                en: 'When was Horyuji Temple built?',
                mm: 'Horyuji ဘုရားကျောင်းကို ဘယ်တုန်းက ဆောက်ခဲ့တာလဲ?',
            },
            {
                furi: '。。。６０７｜＾年（ねん）｜に　｜！でき｜ました。',
                normal: '。。。６０７年に　できました。',
                en: '. . . In 607 he was made.',
                mm: 'သက္ကရစ် ၆၀၇ခုနှစ်မှာ တည်ဆောက်ပြီးစီးခဲ့ပါတယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson27/bunpou/4N27RB3.mp3")
    },
    {
        sentence: [
            {
                furi: 'パワー｜＾電（でん）気（き）｜は　｜＾何（なん）日（にち）｜ぐらい　｜＾夏（なつ）｜休みが　ありますか。',
                normal: 'パワー電気は　何日ぐらい　夏休みが　ありますか。',
                en: 'How many days does Power Denki have summer vacation?',
                mm: 'Power Denki မှာနွေရာသီအားလပ်ရက်ဘယ်နှစ်ရက်ရှိပါသလဲ?',
            },
            {
                furi: '。。。そうですね。３｜＾週（しゅう）間（かん）｜ぐらいです。',
                normal: '。。。そうですね。３週間ぐらいです。',
                en: '. . . I agree. About three weeks.',
                mm: '. . . အင်း၊ ၃ ပတ်လောက်ပါ။',
            },
            {
                furi: 'いいですね。　わたしの　｜＾会（かい）社（しゃ）｜は　１｜＾週（しゅう）間（かん）｜しか　｜！休（やす）め｜ません。',
                normal: 'いいですね。　わたしの　会社は　１週間しか　休めません。',
                en: 'Sounds good. My company allows him only one week off.',
                mm: 'ကောင်းသားပဲ။ ငါ့ကုမ္ပဏီက တစ်ပတ်လောက်ပဲ အနားယူလို့ရတယ်။',
            },
        ],
        audioUrl: require("@assets/audio/lesson27/bunpou/4N27RB4.mp3")
    },
    {
        sentence: [
            {
                furi: 'この　マンションで　ペットが　｜！飼（か）え｜ますか。',
                normal: 'この　マンションで　ペットが　飼えますか。',
                en: 'Can I keep pets in this apartment?',
                mm: 'အိမ်မွေးတိရစ္ဆာန်များကို ဒီတိုက်ခန်းတွင် မွေးမြူနိုင်ပါသလား။',
            },
            {
                furi: '。。。｜＾小（ちい）｜さい　｜＾鳥（とり）｜や　｜＾魚（さかな）｜は　｜！飼（か）え｜ますが、　｜＾犬（いぬ）｜や　｜＾猫（ねこ）｜は　｜！飼（か）え｜ません。',
                normal: '。。。小さい　鳥や　魚は　飼えますが、　犬や　猫は　飼えません。',
                en: '. . . You can keep small birds and fish, but not dogs or cats.　',
                mm: '. . . ငှက်ငယ်များနှင့် ငါးများကို မွေးမြူနိုင်ပေမယ့် ခွေးနဲ့ ကြောင်ကတော့ မွေးမြူလို့မရပါဘူး။',
            },
        ],
        audioUrl: require("@assets/audio/lesson27/bunpou/4N27RB5.mp3")
    },
]