import { lesson1 } from "./lesson1";
import { lesson10 } from "./lesson10";
import { lesson11 } from "./lesson11";
import { lesson12 } from "./lesson12";
import { lesson13 } from "./lesson13";
import { lesson14 } from "./lesson14";
import { lesson15 } from "./lesson15";
import { lesson16 } from "./lesson16";
import { lesson17 } from "./lesson17";
import { lesson18 } from "./lesson18";
import { lesson19 } from "./lesson19";
import { lesson2 } from "./lesson2";
import { lesson20 } from "./lesson20";
import { lesson3 } from "./lesson3";
import { lesson4 } from "./lesson4";
import { lesson5 } from "./lesson5";
import { lesson6 } from "./lesson6";
import { lesson7 } from "./lesson7";
import { lesson8 } from "./lesson8";
import { lesson9 } from "./lesson9";
export const search: any = {
    ...lesson1,...lesson2,...lesson3,...lesson4,...lesson5,
    ...lesson6,...lesson7,...lesson8,...lesson9,...lesson10,
    ...lesson11,...lesson12,...lesson13,...lesson14,...lesson15,
    ...lesson16,...lesson17,...lesson18,...lesson19,...lesson20
}