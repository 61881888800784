export const lesson31 = [
  {
    furi: '＾続（つづ）｜けます',
    normal: 'つづけます',
    mm: 'ဆက်လုပ်သည်',
    remark_mm: "",
    en: 'continue',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson31/vocab/4N31VO1.mp3")
  },
  {
    furi: '＾見（み）｜つけます',
    normal: '見つけます',
    mm: 'ရှာဖွေသည်၊ ရှာတွေ့သည်',
    remark_mm: "",
    en: 'find',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson31/vocab/4N31VO2.mp3")
  },
  {
      furi: "＾取（と）｜ります",
      normal: "取ります",
      en: "take",
      remark_en: "take [a holiday]",
      mm: "ယူသည်",
      remark_mm: "ခွင့်ရက်(ကို)",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO3.mp3")
  },
  {
      furi: "＾受（う）｜けま",
      normal: "受けます",
      en: "take",
      remark_en: "take an exam",
      mm: "ဖြေဆိုသည်",
      remark_mm: "စာမေးပွဲ(ကို)",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO4.mp3")
  },
  {
      furi: "＾申（もう）｜し｜＾込（こ）｜みます",
      normal: "申し込みます",
      en: "apply for",
      remark_en: "apply for job",
      mm: "လျှောက်ထားသည်",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO5.mp3")
  },
  {
      furi: "＾休（きゅう）憩（けい）｜します",
      normal: "休憩します",
      en: "take a break",
      remark_en: "take a rest",
      mm: "ခဏနားသည်",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO6.mp3")
  },
  {
      furi: "＾連（れん）休（きゅう）",
      normal: "連休",
      en: "consecutive holidays",
      remark_en: "",
      mm: "ပိတ်ရက်ရှည်",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO7.mp3")
  },
  {
      furi: "＾作（さく）文（ぶん）",
      normal: "作文",
      en: "eassay",
      remark_en: "composition",
      mm: "စာစီစာကုံး",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO8.mp3")
  },
  {
      furi: "＾発（はっ）表（ぴょう）",
      normal: "発表",
      en: "announcement",
      remark_en: "presentation",
      mm: "ထုတ်ပြန်ကြေငြာခြင်း",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO9.mp3")
  },
  {
      furi: "＾展（てん）覧（らん）会（かい）",
      normal: "展覧会",
      en: "exhibition",
      remark_en: "",
      mm: "ကုန်စည်ပြပွဲ",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO10.mp3")
  },
  {
      furi: "＾結（けっ）婚（こん）式（しき）",
      normal: "結婚式",
      en: "wedding ceremony",
      remark_en: "",
      mm: "မင်္ဂလာပွဲ",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO11.mp3")
  },
  {
      furi: "［お］｜＾葬（そう）式（しき）",
      normal: "［お］葬式",
      en: "funeral",
      remark_en: "",
      mm: "နာရေးအခမ်းအနား",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO12.mp3")
  },
  {
      furi: "＾式（しき）",
      normal: "式",
      en: "ceremony",
      remark_en: "",
      mm: "အခမ်းအနား၊ သဘင်",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO13.mp3")
  },
  {
      furi: "＾本（ほん）社（しゃ）",
      normal: "本社",
      en: "head office",
      remark_en: "",
      mm: "ရုံးချုပ်",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO14.mp3")
  },
  {
      furi: "＾支（し）店（てん）",
      normal: "支店",
      en: "branch office",
      remark_en: "",
      mm: "ဆိုင်ခွဲ၊ရုံးခွဲ",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO15.mp3")
  },
  {
      furi: "＾教（きょう）会（かい）",
      normal: "教会",
      en: "church",
      remark_en: "",
      mm: "ဘုရားကျောင်း",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO16.mp3")
  },
  {
      furi: "＾大（だい）学（がく）院（いん）",
      normal: "大学院",
      en: "graduate school",
      remark_en: "",
      mm: "ဘွဲ့လွန်",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO17.mp3")
  },
  {
      furi: "＾動（どう）物（ぶつ）園（えん）",
      normal: "動物園",
      en: "zoo",
      remark_en: "",
      mm: "တိရိစ္ဆာန်ရုံ",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO18.mp3")
  },
  {
      furi: "＾温（おん）泉（せん）",
      normal: "温泉",
      en: "hot spring,spa",
      remark_en: "",
      mm: "ရေပူစမ်း",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO19.mp3")
  },
  {
      furi: "＾帰（かえ）｜り",
      normal: "帰り",
      en: "return",
      remark_en: "",
      mm: "အပြန်",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO20.mp3")
  },
  {
      furi: "お｜＾子（こ）｜さん",
      normal: "お子さん",
      en: "child",
      remark_en: "someone's",
      mm: "ကလေး",
      remark_mm: "သူတစ်ပါး၏",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO21.mp3")
  },
  {
      furi: "－｜＾号（ごう）",
      normal: "－号",
      en: "number",
      remark_en: "train number etc",
      mm: "နံပါတ်-၊အမှတ်-",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO22.mp3")
  },
  {
      furi: "～の　ほう",
      normal: "～の　ほう",
      en: "place towards～,direction of ～",
      remark_en: "",
      mm: "～ရဲ့ဘက်",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO23.mp3")
  },
  {
      furi: "ずっと",
      normal: "ずっと",
      en: "the whole time",
      remark_en: "",
      mm: "တောက်လျှောက်",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO24.mp3")
  },
  {
      furi: "バリ",
      normal: "バリ",
      en: "Bali",
      remark_en: "Indonesion island",
      mm: "ဘာလီကျွန်း",
      remark_mm: "အင်ဒိုနီးရှားရှိကျွန်း",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO25.mp3")
  },
  {
      furi: "ピカソ",
      normal: "ピカソ",
      en: "Pablo Picasso",
      remark_en: "Spanish painter",
      mm: "ပီကာဆို",
      remark_mm: "စပိန်လူမျိုး ပန်းချီပညာရှင်",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO26.mp3")
  },
  {
      furi: "のぞみ",
      normal: "のぞみ",
    en: "Nozomi SuperExpress",
      remark_en: "name of shinkansen",
      mm: "နော်ဇိုးမိ",
      remark_mm: "ကျည်ဆန်ရထားအမည်",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO27.mp3")
  },
  {
      furi: "＾新（しん）神（こう）戸（べ）",
      normal: "新神戸",
      en: "shinkobe",
      remark_en: "name of a station",
      mm: "ရှင်းခိုးဘဲ",
      remark_mm: "ဘူတာအမည်",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO28.mp3")
  },
  {
      furi: "＾残（のこ）｜ります",
      normal: "残ります",
      en: "remain,be left",
      remark_en: "",
      mm: "ကျန်ရစ်သည်",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO29.mp3")
  },
  {
      furi: "＾入（にゅう）学（がく）試（し）験（けん）",
      normal: "入学試験",
      en: "Entrance Exam",
      remark_en: "",
      mm: "ကျောင်းဝင်ခွင့်စာမေးပွဲ",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO30.mp3")
  },
  {
      furi: "＾月（つき）｜に",
      normal: "月に",
      en: "per month",
      remark_en: "",
      mm: "တစ်လမှာ၊ တစ်လကို",
      remark_mm: "",
      group: 0,
      audioUrl: require("@assets/audio/lesson31/vocab/4N31VO31.mp3")
  },
  {
      furi: "＾村（むら）",
      normal: "村",
      en: "village",
      remark_en: "",
      mm: "ရွာ၊ ကျေးလက်",
      remark_mm: "",
      group: 0
  },
  {
      furi: "＾卒（そつ）業（ぎょう）｜します",
      normal: "卒業します",
      en: "graduate",
      remark_en: "",
      mm: "ကျောင်းပြီးသည်၊ ဘွဲ့ရသည်",
      remark_mm: "",
      group: 0
  },
  {
      furi: "＾映（えい）画（が）館（かん）",
      normal: "映画館",
      en: "cinema",
      remark_en: "",
      mm: "ရုပ်ရှင်ရုံ",
      remark_mm: "",
      group: 0
  },
  {
      furi: "＾嫌（いや）｜［な］",
      normal: "嫌［な］",
      en: "hateful,disagreeatable",
      remark_en: "",
      mm: "မနှစ်မြို့၊ မကြိုက်သော၊ မကြည်ဖြူသော",
      remark_mm: "",
      group: 0
  },
  {
      furi: "＾空（そら）",
      normal: "空",
      en: "sky",
      remark_en: "",
      mm: "မိုးကောင်းကင်",
      remark_mm: "",
      group: 0
  },
  {
      furi: "＾閉（と）｜じます",
      normal: "閉じます",
      en: "close",
      remark_en: "",
      mm: "ပိတ်သည်",
      remark_mm: "",
      group: 0
  },
  {
      furi: "＾都（と）会（かい）",
      normal: "都会",
      en: "city",
      remark_en: "",
      mm: "မြို့ပြ",
      remark_mm: "",
      group: 0
  },
  {
      furi: "＾子（こ）｜どもたち",
      normal: "子どもたち",
      en: "children",
      remark_en: "",
      mm: "ကလေးတွေ၊ ကလေးများ",
      remark_mm: "",
      group: 0
  },
  {
      furi: "＾自（じ）由（ゆう）｜に",
      normal: "自由に",
      en: "freely",
      remark_en: "",
      mm: "လွတ်လပ်စွာ၊ လွတ်လွတ်လပ်လပ်",
      remark_mm: "",
      group: 0
  }
]