import Back from "@assets/icon/game/back 1.svg";
import { memo } from "react";
import 'react-circular-progressbar/dist/styles.css';
const GameNavBarComponent = ({gameOver,back,id }:any) => {
    return <div className="container mx-auto">
        {/* Nav Bar web view */}
        <div className="hidden md:flex flex-row justify-between items-center pt-[40px]">
                {/* Left */}
                <div onClick={back} className="flex-1 `select-none cursor-pointer">
                    <div><img src={Back} className="w-[40px] h-[40px]"/></div>
                </div>
                {/* Center */}
                {gameOver && <div className="flex-1 text-center text-[#424242] dark:text-[#D4D4D4] text-[30px] font-semibold ">
                        Result
                    </div>
                }
                {!gameOver &&
                <div className="flex-1 text-center text-[#424242] dark:text-[#D4D4D4] text-[30px] font-semibold">
                        LESSON {id + 1}
                    </div>
                }
                {/* Right */}
                <div className="flex-1 text-right text-[#424242] dark:text-[#D4D4D4] text-[20px] font-semibold">
                    Minna Kanji Quizs
                </div>
            </div>
        {/* Nav Bar Mobile view */}
        <div className="md:hidden pt-[40px]">
            <div className="flex flex-row items-center">
                {/* Left */}
                <div onClick={back} className="select-none cursor-pointer">
                    <div><img src={Back} className="w-[30px] h-[30px]" /></div>
                </div>
                {/* Right */}
                <div className="flex-1 flex flex-row justify-center text-[#424242] dark:text-[#D4D4D4] text-[20px] font-semibold">
                    Minna Kanji Quizs
                </div>
              </div>
            {/* Center */}
            {gameOver && <div className="flex-1 pt-[40px] text-center text-[#424242] dark:text-[#D4D4D4] text-[20px] font-semibold ">
                Result
            </div>
            }
            {!gameOver &&
                <div className="flex-1 pt-[40px] text-center text-[#424242] dark:text-[#D4D4D4] text-[20px] font-semibold">
                    LESSON {id + 1}
                </div>
            }
            </div>
    </div>
}
export default memo(GameNavBarComponent);