import Heart from "@assets/icon/game/health 2.svg";
import Right from "@assets/icon/game/right.svg";
import Wrong from "@assets/icon/game/wrong.svg";
import { Card, timeline } from "@material-tailwind/react";
import { memo } from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import CountUp from 'react-countup';
import GameDashboardWebComponent from "./GameDashboardWebComponent";
import GameDashboardMobileComponent from "./GameDashboardMobileComponent";

const GameDashboardComponent = ({ game,count,score, oldScore, life, lifeState, correct, wrong, gameOver, percent, timeLeft }:any) => {
    return (<div className="container mx-auto flex flex-row justify-center">
        {/* Web */}
        <GameDashboardWebComponent
            life={life}
            lifeState={lifeState}
            oldScore={oldScore}
            score={score}
            percent={percent}
            timeLeft={timeLeft}
            game={game}
            count={count}
            correct={correct}
            wrong={wrong}
        />
        {/* Mobile */}
     
        <GameDashboardMobileComponent
            life={life}
            lifeState={lifeState}
            oldScore={oldScore}
            score={score}
            percent={percent}
            timeLeft={timeLeft}
            game={game}
            count={count}
            correct={correct}
            wrong={wrong}
        />
 
        
    </div>
    )
}
export default memo(GameDashboardComponent);