import SizeBox from "@components/SizeBox"
import TitleComponent from "@components/common/TitleComponent"
import { Card } from "@material-tailwind/react";
import { IoMdRadioButtonOn, IoMdRadioButtonOff } from "react-icons/io";
import { memo } from "react"
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";

const MondaiTwoComponent = ({ mondai2, pickAnswer2,underline,furigana,furiTranslate,fontSize,checkMondai2,setCheckMondai2}:any) => {
    return <div>{!checkMondai2 ? <div className=" mt-[30px]">
        <TitleComponent
            furi="＾問（もん）｜＾題（だい）"
            normal="問　題"
            furigana={furigana}
            furiTranslate={furiTranslate}
            fontSize={fontSize}
            lesson="２"
            />
        <SizeBox vertical="h-[70px] md:h-[80px]" />
        <div className="mb-[100px]">

            {
                mondai2.map(({ question, answers, picked }: any, index: number) => {
                    return <div key={Math.random()}>
                        <SizeBox vertical="h-5" />
                        <div className="flex flex-row">
                            <div className="mr-1">{index + 1})</div>
                            <div>{underline({ context: question, style: "flex-wrap" })}</div>
                        </div>
                        <SizeBox vertical="h-5" />
                        <div className="grid grid-cols-2 gap-5 mb-5">
                            {
                                answers.map((answer: string, i: number) => {
                                    return <div key={Math.random()}
                                        onClick={() => pickAnswer2({ questionId: index, answer })}
                                        className={``}>
                                        <div className="pr-2">
                                            {
                                                // bg-[#d1fae5] true
                                                answer === picked ?
                                                    <Card className="shadow-md dark:shadow-[#344a5a] z-0  flex flex-row items-center text-[#fff] bg-[#1e90ff]  font-semibold  p-2 rounded-[10px] btn">
                                                        <MdRadioButtonChecked className="mr-[5px] " />
                                                        {answer}
                                                    </Card>
                                                    : <Card className="shadow-md dark:shadow-[#344a5a] z-0  bg-white dark:bg-[#001E34] flex flex-row items-center p-2 rounded-[10px] btn">
                                                        <MdRadioButtonUnchecked className="mr-[5px]" />
                                                        {answer}
                                                    </Card>
                                            }
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                })
            }
        </div>
        <SizeBox vertical="h-[150px] md:h-0"/>
        <div className="absolute bottom-[130px] md:-bottom-[80px] right-0 w-full flex flex-row justify-center">
            <div onClick={() => {
                setCheckMondai2(true)
            }}
                className="btn bg-navbar p-3 text-white rounded-md">
                Check Answer
            </div>
        </div>
        {/* <SizeBox vertical="h-[30px]"/> */}
    </div>
        :
        <div className="mt-[40px] md:mt-[80px]">
            <TitleComponent
                furi="＾問（もん）｜＾題（だい）"
                normal="問　題"
                furigana={furigana}
                furiTranslate={furiTranslate}
                fontSize={fontSize}
                lesson="２"
            />
            <SizeBox vertical="h-[70px] md:h-[80px]" />
            <div className="mb-[100px]">

                {
                    mondai2.map(({ question, answers, picked,correct }: any, index: number) => {
                        return <div key={Math.random()}>
                            <SizeBox vertical="h-5" />
                            <div className="flex flex-row">
                                <div className="mr-1">{index + 1})</div>
                                <div>{underline({ context: question, style: "flex-wrap" })}</div>
                            </div>
                            <SizeBox vertical="h-5" />
                            <div className="grid grid-cols-2 gap-5 mb-5">
                                {
                                    answers.map((answer: string, i: number) => {
                                        return <div key={Math.random()}
                                            onClick={() => pickAnswer2({ questionId: index, answer })}
                                            className={``}>
                                            <div className="pr-2">
                                                {
                                                    // bg-[#d1fae5] true
                                                    answer === picked && correct == picked?
                                                        <Card className="shadow-md dark:shadow-[#344a5a] z-0  flex flex-row items-center text-green-500 bg-[#d1fae5]  font-semibold  p-2 rounded-[10px] btn">
                                                            <MdRadioButtonChecked className="mr-[5px] " />
                                                            {answer}
                                                        </Card>
                                                        :
                                                        (
                                                            answer == picked && correct !== picked ?
                                                                <Card className="shadow-md dark:shadow-[#344a5a] z-0  bg-red-200 text-red-500 font-semibold  flex flex-row items-center p-2 rounded-[10px] btn">
                                                                    <MdRadioButtonChecked className="mr-[5px]" />
                                                                    {answer}
                                                                </Card>
                                                                :
                                                                <Card className="shadow-md dark:shadow-[#344a5a] z-0  bg-gray-200 dark:bg-[#001E34] flex flex-row items-center p-2 rounded-[10px] btn">
                                                                    {
                                                                        answer === correct?
                                                                            <MdRadioButtonChecked className="mr-[5px] text-green-500 font-semibold" />
                                                                        :<MdRadioButtonUnchecked className="mr-[5px] " />

                                                                    }
                                                                    {answer === correct ? <div className="text-green-500 font-semibold">{answer}</div> :  answer }
                                                                    
                                                                </Card>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>

                    })
                }
            </div>
        </div>
    }
    </div>
}
export default memo(MondaiTwoComponent);