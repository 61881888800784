export const renshuuA = [
    {
        isTable: true,
        tables: [
            {
                head: {
                    a: {
                        val: "",
                        span: 1,
                        style: ""
                    },
                    b: {
                        val: "",
                        span: 3,
                        style: ""
                    },
                    c: {
                        val: "＾尊（そん）敬（けい）",
                        span: 3,
                        style: ""
                    }
                },
                body: [
                    {
                        no: "I",
                        l1: "あ",
                        l2: "い",
                        l3: "ます",
                        r1: "あ",
                        r2: "わ",
                        r3: "れます"
                    },
                    {
                        no: "",
                        l1: "き",
                        l2: "き",
                        l3: "ます",
                        r1: "き",
                        r2: "か",
                        r3: "れます"
                    },
                    {
                        no: "",
                        l1: "いそ",
                        l2: "ぎ",
                        l3: "ます",
                        r1: "いそ",
                        r2: "が",
                        r3: "れます"
                    },
                    {
                        no: "",
                        l1: "はな",
                        l2: "し",
                        l3: "ます",
                        r1: "はな",
                        r2: "さ",
                        r3: "れます"
                    },
                    {
                        no: "",
                        l1: "ま",
                        l2: "ち",
                        l3: "ます",
                        r1: "ま",
                        r2: "た",
                        r3: "れます"
                    },
                    {
                        no: "",
                        l1: "よ",
                        l2: "び",
                        l3: "ます",
                        r1: "よ",
                        r2: "ば",
                        r3: "れます"
                    },
                    {
                        no: "",
                        l1: "よ",
                        l2: "み",
                        l3: "ます",
                        r1: "よ",
                        r2: "ま",
                        r3: "れます"
                    },
                    {
                        no: "",
                        l1: "かえ",
                        l2: "り",
                        l3: "ます",
                        r1: "かえ",
                        r2: "ら",
                        r3: "れます"
                    },
                ],
                style: {
                    no: "p-2 w-[30px] text-right bg-white dark:bg-darkFooter",
                    l1: "p-2 text-right bg-white dark:bg-darkFooter",
                    l2: "p-2 w-[30px] text-right bg-[#729eff] dark:bg-[#003257]",
                    l3: "p-2 text-left bg-white dark:bg-darkFooter",
                    r1: "p-2 text-right bg-white dark:bg-darkFooter",
                    r2: "p-2 w-[30px] text-right bg-[#729eff] dark:bg-[#003257]",
                    r3: "p-2 text-left bg-white dark:bg-darkFooter"
                }
            },
            {
                head: {
                    a: {
                        val: "",
                        span: 1,
                        style: ""
                    },
                    b: {
                        val: "",
                        span: 2,
                        style: ""
                    },
                    c: {
                        val: "＾尊（そん）敬（けい）",
                        span: 2,
                        style: ""
                    }
                },
                body: [
                    {
                        no: "II",
                        l1: "かけ",
                        l3: "ます",
                        r1: "かけ",
                        r3: "られます"
                    },
                    {
                        no: "",
                        l1: "で",
                        l3: "ます",
                        r1: "で",
                        r3: "られます"
                    },
                    {
                        no: "",
                        l1: "おき",
                        l3: "ます",
                        r1: "おき",
                        r3: "られます"
                    },
                    {
                        no: "",
                        l1: "おり",
                        l3: "ます",
                        r1: "おり",
                        r3: "られます"
                    },
                    {
                        no: "",
                        l1: "",
                        l3: "",
                        r1: "",
                        r3: ""
                    },
                    {
                        no: "III",
                        l1: "き",
                        l3: "ます",
                        r1: "こ",
                        r3: "られます"
                    },
                    {
                        no: "",
                        l1: "し",
                        l3: "ます",
                        r1: "さ",
                        r3: "れます"
                    },
                ],
                style: {
                    no: "p-2 w-[50px] text-right bg-white dark:bg-darkFooter",
                    l1: "p-2 text-right bg-white dark:bg-darkFooter",
                    l3: "p-2 text-left bg-white dark:bg-darkFooter",
                    r1: "p-2 text-right bg-white dark:bg-darkFooter",
                    r3: "p-2 text-left bg-white dark:bg-darkFooter"
                }
            },
        ],
        audioUrl: require("@assets/audio/lesson49/renshua/4N49RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "＾伊（い）藤（とう）先（せん）生（せい）｜は　さっき　｜！こられました。",
                normal: "伊藤先生は　さっき　こられました。",
                en: "Professor Ito just came.",
                mm: "ပရော်ဖက်ဆာ Ito က အခုမှ ရောက်လာတာ။",
            },
            {
                furi: "＾伊（い）藤（とう）先（せん）生（せい）｜は　あしたは　｜！やすまれます。",
                normal: "伊藤先生は　あしたは　やすまれます。",
                en: "Ito-sensei will be absent tomorrow.",
                mm: "မနက်ဖြန်တွင် အိတိုဆန်းဆေးက ခွင့်ယူမည် ဖြစ်ပါသည်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson49/renshua/4N49RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾社（しゃ）長（ちょう）｜は　お｜！でかけ｜に　なりました。",
                normal: "社長は　お　でかけに　なりました。",
                en: "The president has gone out.",
                mm: "ဥက္ကဌကြီးက အပြင်ထွက်သွားပါတယ်။",
            },
            {
                furi: "＾社（しゃ）長（ちょう）｜は　お｜！かえり｜に　なりました。",
                normal: "社長は　おかえりに　なりました。",
                en: "The president has returned.",
                mm: "ဥက္ကဌကြီးက ပြန်သွားပါပြီ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson49/renshua/4N49RSA3.mp3")
    },
    {
        isTable: true,
        tables: [
            {
                head: {
                    a: {
                        val: "",
                        span: 1,
                        style: ""
                    },
                    b: {
                        val: "＾尊（そん）敬（けい）語（ご）",
                        span: 1,
                        style: "text-left"
                    },
                },
                body: [
                    {
                        l1: "いきます",
                        l2: "いらっしゃいます",
                    },
                    {
                        l1: "きます",
                        l2: "",
                    },
                    {
                        l1: "います",
                        l2: "",
                    },
                    {
                        l1: "たべます",
                        l2: "めしあがります",
                    },
                    {
                        l1: "のみます",
                        l2: "",
                    },
                ],
                style: {
                    l1: "p-2 text-left bg-white dark:bg-darkFooter",
                    l2: "p-2 text-left bg-white dark:bg-darkFooter",
                }
            },
            {
                head: {
                    a: {
                        val: "",
                        span: 1,
                        style: ""
                    },
                    b: {
                        val: "＾尊（そん）敬（けい）語（ご）",
                        span: 1,
                        style: "text-left"
                    },
                },
                body: [
                    {
                        l1: "いいます",
                        l2: "おっしゃいます",
                    },
                    {
                        l1: "知って　います",
                        l2: "ごぞんじです",
                    },
                    {
                        l1: "みます",
                        l2: "ごらんに　なります",
                    },
                    {
                        l1: "します",
                        l2: "なさいます",
                    },
                    {
                        l1: "くれます",
                        l2: "くださいます",
                    },
                ],
                style: {
                    l1: "p-2 text-left bg-white dark:bg-darkFooter",
                    l2: "p-2 text-left bg-white dark:bg-darkFooter",
                }
            },
         
        ],
        audioUrl: require("@assets/audio/lesson49/renshua/4N49RSA4.mp3")
    },
    {
        sentence: [
            {
                furi: "＾社（しゃ）長（ちょう）｜は　｜＾会（かい）議（ぎ）室（しつ）｜に　｜！いらっしゃいます。",
                normal: "社長は　会議室に　いらっしゃいます。",
                en: "The president is in the conference room.",
                mm: "ဥက္ကဌကြီးက အစည်းအဝေးခန်းထဲမှာပါ။",
            },
            {
                furi: "＾社（しゃ）長（ちょう）｜は　ゴルフを　｜！なさいます。",
                normal: "社長は　ゴルフを　なさいます。",
                en: "The president plays golf.",
                mm: "ဥက္ကဌကြီးက ဂေါက်ရိုက်နေပါတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson49/renshua/4N49RSA5.mp3")
    },
    {
        sentence: [
            {
                furi: "お｜！かけ｜　ください。",
                normal: "おかけ　ください。",
                en: "Please sit down.",
                mm: "ထိုင်ပါ။",
            },
            {
                furi: "お｜！はいり｜　ください。",
                normal: "おはいり　ください。",
                en: "Please come in.",
                mm: "ဝင်ပါ။",
            },
            {
                furi: "ご｜！ちゅうい｜　ください。",
                normal: "ごちゅうい　ください。",
                en: "Take care",
                mm: "ဂရုစိုက်ပါ။",
            },
            {
                furi: "ご｜！れんらく｜　ください。",
                normal: "ごれんらく　ください。",
                en: "Please contact me",
                mm: "ဆက်သွယ်ပေးပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson49/renshua/4N49RSA6.mp3")
    },
];