export const lesson46 = [
  {
      "furi": "＾渡（わた）｜します",
      "normal": "渡します",
      "en": "hand over",
      "remark_en": "",
      "mm": "လက်ဆင့်ကမ်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO1.mp3")
  },
  {
      "furi": "＾帰（かえ）｜って｜＾来（き）｜ます",
      "normal": "帰って来ます",
      "en": "come back",
      "remark_en": "",
      "mm": "ပြန်လာသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO2.mp3")
  },
  {
      "furi": "＾出（で）｜ます",
      "normal": "出ます",
      "en": "leave, depart",
      "remark_en": "",
      "mm": "ထွက်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO3.mp3")
  },
  {
      "furi": "＾届（とど）｜きます",
      "normal": "届きます",
      "en": "to reach",
      "remark_en": "",
      "mm": "ပို့လာသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO4.mp3")
  },
  {
      "furi": "＾入（にゅう）学（がく）｜します",
      "normal": "入学します",
      "en": "Enroll(university)",
      "remark_en": "",
      "mm": "ကျောင်းဝင်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO5.mp3")
  },
  {
      "furi": "＾卒（そつ）業（ぎょう）｜します",
      "normal": "卒業します",
      "en": "Graduation",
      "remark_en": "",
      "mm": "ကျောင်းပြီးသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO6.mp3")
  },
  {
      "furi": "＾焼（や）｜きます",
      "normal": "焼きます",
      "en": "bake, grill, toast",
      "remark_en": "",
      "mm": "ဖုတ်သည်၊ကင်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO7.mp3")
  },
  {
      "furi": "＾焼（や）｜けます",
      "normal": "焼けます",
      "en": "Burn",
      "remark_en": "",
      "mm": "ဖုတ်၍ကျက်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO8.mp3")
  },
  {
      "furi": "＾留（る）守（す）",
      "normal": "留守",
      "en": "absence",
      "remark_en": "",
      "mm": "လူမရှိခြင်း(အိမ်)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO9.mp3")
  },
  {
      "furi": "＾宅（たく）配（はい）便（びん）",
      "normal": "宅配便",
      "en": "delivery service",
      "remark_en": "",
      "mm": "အရောက်ပို့ပစ္စည်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO10.mp3")
  },
  {
      "furi": "＾原（げん）因（いん）",
      "normal": "原因",
      "en": "cause",
      "remark_en": "",
      "mm": "အကြောင်းအရင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO11.mp3")
  },
  {
      "furi": "こちら",
      "normal": "こちら",
      "en": "my place, my side",
      "remark_en": "",
      "mm": "ဒီဘက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO12.mp3")
  },
  {
      "furi": "～の　ところ",
      "normal": "～の　ところ",
      "en": "the place around ~",
      "remark_en": "",
      "mm": "～ရဲ့နေရာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO13.mp3")
  },
  {
      "furi": "＾半（はん）年（とし）",
      "normal": "半年",
      "en": "half a year",
      "remark_en": "",
      "mm": "နှစ်ဝက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO14.mp3")
  },
  {
      "furi": "ちょうど",
      "normal": "ちょうど",
      "en": "just, exactly",
      "remark_en": "",
      "mm": "အနေတော်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO15.mp3")
  },
  {
      "furi": "たった｜＾今（いま）",
      "normal": "たった今",
      "en": "just now",
      "remark_en": "",
      "mm": "အခုလေးတင်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO16.mp3")
  },
  {
      "furi": "＾今（いま）｜いいですか。",
      "normal": "今いいですか。",
      "en": "May I bother you now?",
      "remark_en": "",
      "mm": "အခု(အချိန်)ရသလား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO17.mp3")
  },
  {
      "furi": "ガスサービスセンター",
      "normal": "ガスサービスセンター",
      "en": "gas service center",
      "remark_en": "",
      "mm": "ဂက်စ်ဝန်ဆောင်မှုစင်တာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO18.mp3")
  },
  {
      "furi": "ガスレンジ",
      "normal": "ガスレンジ",
      "en": "gas range",
      "remark_en": "",
      "mm": "ဂက်စ်မီးဖို",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO19.mp3")
  },
  {
      "furi": "＾具（ぐ）合（あい）",
      "normal": "具合",
      "en": "condition",
      "remark_en": "",
      "mm": "အခြေအနေ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO20.mp3")
  },
  {
      "furi": "＾申（もう）｜し｜＾訳（わけ）｜ありません。",
      "normal": "申し訳ありません。",
      "en": "I'm sorry",
      "remark_en": "",
      "mm": "တောင်းပန်ပါတယ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO21.mp3")
  },
  {
      "furi": "どちら｜＾様（さま）｜でしょうか。",
      "normal": "どちら様でしょうか。",
      "en": "Who is this, please?",
      "remark_en": "",
      "mm": "ဘယ်သူများပါလဲ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO22.mp3")
  },
  {
      "furi": "お｜＾待（ま）｜たせしました。",
      "normal": "お待たせしました。",
      "en": "Sorry to have kept you waiting",
      "remark_en": "",
      "mm": "စောင့်နေရလို့တောင်းပန်ပါတယ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson46/vocab/4N46VO23.mp3")
  },
  {
      "furi": "ついています",
      "normal": "ついています",
      "en": "lucky",
      "remark_en": "",
      "mm": "ကံကောင်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾床（ゆか）",
      "normal": "床",
      "en": "floor",
      "remark_en": "",
      "mm": "ကြမ်းပြင်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾転（ころ）｜びます",
      "normal": "転びます",
      "en": "fall",
      "remark_en": "",
      "mm": "ပြုတ်ကျသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "ベル",
      "normal": "ベル",
      "en": "bell",
      "remark_en": "",
      "mm": "ဘဲလ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾鳴（な）｜ります",
      "normal": "鳴ります",
      "en": "ring",
      "remark_en": "",
      "mm": "မြည်သည်(အသံ)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾慌（あわ）｜てて",
      "normal": "慌てて",
      "en": "In a hurry",
      "remark_en": "",
      "mm": "ကပျာကယာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾順（じゅん）番（ばん）｜に",
      "normal": "順番に",
      "en": "In order",
      "remark_en": "",
      "mm": "အစီအစဉ်အတိုင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾出（で）来（き）事（こと）",
      "normal": "出来事",
      "en": "event",
      "remark_en": "",
      "mm": "အဖြစ်အပျက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  }
]

/*
    {
        furi: "",
        normal: "",
        en: "",
        remark_en: "",
        mm: "",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson29/vocab/4N29VO1.mp3")
    },


*/
const test = [
  {
    'goi': '渡（わた）」します',
    'unicode': 'လက်ဆင့်ကမ်းသည်',
    'zawgyi': 'လက္ဆင့္ကမ္းသည္',
    'english': 'hand over',
    'long': false
  },
  {
    'goi': '帰（かえ）」って」来（き）」ます',
    'unicode': 'ပြန်လာသည်',
    'zawgyi': 'ျပန္လာသည္',
    'english': 'come back',
    'long': false
  },
  {
    'goi': '出（で）」ます',
    'unicode': 'ထွက်သည်',
    'zawgyi': 'ထြက္သည္',
    'english': 'leave, depart',
    'long': false
  },
  {
    'goi': '届（とど）」きます',
    'unicode': 'ပို့လာသည်',
    'zawgyi': 'ပို႔လာသည္',
    'english': 'to reach',
    'long': false
  },
  {
    'goi': '入（にゅう）」学（がく）」します',
    'unicode': 'ကျောင်းဝင်သည်',
    'zawgyi': 'ေက်ာင္းဝင္သည္',
    'english': 'Enroll(university)',
    'long': false
  },
  {
    'goi': '卒（そつ）」業（ぎょう）」します',
    'unicode': 'ကျောင်းပြီးသည်',
    'zawgyi': 'ေက်ာင္းၿပီးသည္',
    'english': 'Graduation',
    'long': false
  },
  {
    'goi': '焼（や）」きます',
    'unicode': 'ဖုတ်သည်၊ကင်သည်',
    'zawgyi': 'ဖုတ္သည္၊ကင္သည္',
    'english': 'bake, grill, toast',
    'long': false
  },
  {
    'goi': '焼（や）」けます',
    'unicode': 'ဖုတ်၍ကျက်သည်',
    'zawgyi': 'ဖုတ္၍က်က္သည္',
    'english': 'Burn',
    'long': false
  },
  {
    'goi': '留（る）」守（す）',
    'unicode': 'လူမရှိခြင်း(အိမ်)',
    'zawgyi': 'လူမရွိျခင္း(အိမ္)',
    'english': 'absence',
    'long': false
  },
  {
    'goi': '宅（たく）」配（はい）」便（びん）',
    'unicode': 'အရောက်ပို့ပစ္စည်း',
    'zawgyi': 'အေရာက္ပို႔ပစၥည္း',
    'english': 'delivery service',
    'long': false
  },
  {
    'goi': '原（げん）」因（いん）',
    'unicode': 'အကြောင်းအရင်း',
    'zawgyi': 'အေၾကာင္းအရင္း',
    'english': 'cause',
    'long': false
  },
  {
    'goi': 'こちら',
    'unicode': 'ဒီဘက်',
    'zawgyi': 'ဒီဘက္',
    'english': 'my place, my side',
    'long': false
  },
  {
    'goi': '～の　ところ',
    'unicode': '～ရဲ့နေရာ',
    'zawgyi': '～ရဲ့ေနရာ',
    'english': 'the place around ~',
    'long': false
  },
  {
    'goi': '半（はん）」年（とし）',
    'unicode': 'နှစ်ဝက်',
    'zawgyi': 'ႏွစ္ဝက္',
    'english': 'half a year',
    'long': false
  },
  {
    'goi': 'ちょうど',
    'unicode': 'အနေတော်',
    'zawgyi': 'အေနေတာ္',
    'english': 'just, exactly',
    'long': false
  },
  {
    'goi': 'たった」今（いま）',
    'unicode': 'အခုလေးတင်',
    'zawgyi': 'အခုေလးတင္',
    'english': 'just now',
    'long': false
  },
  {
    'goi': '今（いま）」いいですか。',
    'unicode': 'အခု(အချိန်)ရသလား',
    'zawgyi': 'အခု(အခ်ိန္)ရသလား',
    'english': 'May I bother you now?',
    'long': false
  },
  {
    'goi': 'ガスサービスセンター',
    'unicode': 'ဂက်စ်ဝန်ဆောင်မှုစင်တာ',
    'zawgyi': 'ဂက္စ္ဝန္ေဆာင္မႈစင္တာ',
    'english': 'gas service center',
    'long': true
  },
  {
    'goi': 'ガスレンジ',
    'unicode': 'ဂက်စ်မီးဖို',
    'zawgyi': 'ဂက္စ္မီးဖို',
    'english': 'gas range',
    'long': false
  },
  {
    'goi': '具（ぐ）」合（あい）',
    'unicode': 'အခြေအနေ',
    'zawgyi': 'အေျခအေန',
    'english': 'condition',
    'long': false
  },
  {
    'goi': '申（もう）」し」訳（わけ）」ありません。',
    'unicode': 'တောင်းပန်ပါတယ်',
    'zawgyi': 'ေတာင္းပန္ပါတယ္',
    'english': 'I\'m sorry',
    'long': false
  },
  {
    'goi': 'どちら」様（さま）」でしょうか。',
    'unicode': 'ဘယ်သူများပါလဲ',
    'zawgyi': 'ဘယ္သူမ်ားပါလဲ',
    'english': 'Who is this, please?',
    'long': false
  },
  {
    'goi': 'お」待（ま）」たせしました。',
    'unicode': 'စောင့်နေရလို့တောင်းပန်ပါတယ်',
    'zawgyi': 'ေစာင့္ေနရလို႔ေတာင္းပန္ပါတယ္',
    'english': 'Sorry to have kept you waiting',
    'long': true
  },
  {
    'goi': 'ついています',
    'unicode': 'ကံကောင်းသည်',
    'zawgyi': 'ကံေကာင္းသည္',
    'english': 'lucky',
    'long': false
  },
  {
    'goi': '床（ゆか）',
    'unicode': 'ကြမ်းပြင်',
    'zawgyi': 'ၾကမ္းျပင္',
    'english': 'floor',
    'long': false
  },
  {
    'goi': '転（ころ）」びます',
    'unicode': 'ပြုတ်ကျသည်',
    'zawgyi': 'ျပဳတ္က်သည္',
    'english': 'fall',
    'long': false
  },
  {
    'goi': 'ベル',
    'unicode': 'ဘဲလ်',
    'zawgyi': 'ဘဲလ္',
    'english': 'bell',
    'long': false
  },
  {
    'goi': '鳴（な）」ります',
    'unicode': 'မြည်သည်(အသံ)',
    'zawgyi': 'ျမည္သည္(အသံ)',
    'english': 'ring',
    'long': false
  },
  {
    'goi': '慌（あわ）」てて',
    'unicode': 'ကပျာကယာ',
    'zawgyi': 'ကပ်ာကယာ',
    'english': 'In a hurry',
    'long': false
  },
  {
    'goi': '順（じゅん）」番（ばん）」に',
    'unicode': 'အစီအစဉ်အတိုင်း',
    'zawgyi': 'အစီအစဥ္အတိုင္း',
    'english': 'In order',
    'long': false
  },
  {
    'goi': '出（で）」来（き）」事（こと）',
    'unicode': 'အဖြစ်အပျက်',
    'zawgyi': 'အျဖစ္အပ်က္',
    'english': 'event',
    'long': false
  }
]