export const lesson40 = [
  {
      "furi": "＾数（かぞ）｜えます",
      "normal": "数えます",
      "en": "count",
      "remark_en": "",
      "mm": "ရေတွက်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO1.mp3")
  },
  {
      "furi": "＾量（はか）｜ります",
      "normal": "量ります",
      "en": "weigh",
      "remark_en": "",
      "mm": "ချိန်တွယ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO2.mp3")
  },
  {
      "furi": "＾確（たし）｜かめます",
      "normal": "確かめます",
      "en": "confirm, make sure",
      "remark_en": "",
      "mm": "စိစစ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO3.mp3")
  },
  {
      "furi": "＾合（あ）｜います",
      "normal": "合います",
      "en": "fit",
      "remark_en": "",
      "mm": "ကိုက်ညီသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO4.mp3")
  },
  {
      "furi": "＾出（しゅっ）発（ぱつ）｜します",
      "normal": "出発します",
      "en": "depart",
      "remark_en": "",
      "mm": "ထွက်ခွါသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO5.mp3")
  },
  {
      "furi": "＾到（とう）着（ちゃく）｜します",
      "normal": "到着します",
      "en": "arrive",
      "remark_en": "",
      "mm": "ဆိုက်ရောက်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO6.mp3")
  },
  {
      "furi": "＾酔（よ）｜います",
      "normal": "酔います",
      "en": "get drunk",
      "remark_en": "",
      "mm": "မူးဝေသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO7.mp3")
  },
  {
      "furi": "うまくいきます",
      "normal": "うまくいきます",
      "en": "It works well",
      "remark_en": "",
      "mm": "အဆင်ပြေသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO8.mp3")
  },
  {
      "furi": "＾出（で）｜ます",
      "normal": "出ます",
      "en": "Occurs(problem)",
      "remark_en": "",
      "mm": "ထွက်ပေါ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO9.mp3")
  },
  {
      "furi": "＾相（そう）談（だん）｜します",
      "normal": "相談します",
      "en": "To consult",
      "remark_en": "",
      "mm": "တိုင်ပင်ဆွေးနွေးသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO10.mp3")
  },
  {
      "furi": "＾必（ひつ）要（よう）｜[な]",
      "normal": "必要[な]",
      "en": "necessary",
      "remark_en": "",
      "mm": "လိုအပ်သော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO11.mp3")
  },
  {
      "furi": "＾天（てん）気（き）予（よ）報（ほう）",
      "normal": "天気予報",
      "en": "weather forecast",
      "remark_en": "",
      "mm": "မိုးလေဝသသတင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO12.mp3")
  },
  {
      "furi": "＾忘（ぼう）年（ねん）会（かい）",
      "normal": "忘年会",
      "en": "year-end party",
      "remark_en": "",
      "mm": "နှစ်ကုန်အထိမ်းအမှတ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO13.mp3")
  },
  {
      "furi": "＾新（しん）年（ねん）会（かい）",
      "normal": "新年会",
      "en": "New Year’s party",
      "remark_en": "",
      "mm": "နှစ်သစ်ကူးအထိမ်းအမှတ်ပွဲ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO14.mp3")
  },
  {
      "furi": "＾二（に）次（じ）会（かい）",
      "normal": "二次会",
      "en": "second party",
      "remark_en": "",
      "mm": "ဒုတိယပွဲဆက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO15.mp3")
  },
  {
      "furi": "＾発（はっ）表（ぴょう）会（かい）",
      "normal": "発表会",
      "en": "Presentation",
      "remark_en": "",
      "mm": "စာတမ်းဖတ်ပွဲ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO16.mp3")
  },
  {
      "furi": "＾大（たい）会（かい）",
      "normal": "大会",
      "en": "mass meeting, convention",
      "remark_en": "",
      "mm": "ပြိုင်ပွဲ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO17.mp3")
  },
  {
      "furi": "マラソン",
      "normal": "マラソン",
      "en": "marathon",
      "remark_en": "",
      "mm": "မာရသွန်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO18.mp3")
  },
  {
      "furi": "コンテスト",
      "normal": "コンテスト",
      "en": "contest",
      "remark_en": "",
      "mm": "ယှဉ်ပြိုင်ပွဲ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO19.mp3")
  },
  {
      "furi": "おもて",
      "normal": "おもて",
      "en": "face, front",
      "remark_en": "",
      "mm": "အပြင်ဘက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO20.mp3")
  },
  {
      "furi": "うら",
      "normal": "うら",
      "en": "back, inside",
      "remark_en": "",
      "mm": "အတွင်းဘက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO21.mp3")
  },
  {
      "furi": "まちがい",
      "normal": "まちがい",
      "en": "wrong, mistake",
      "remark_en": "",
      "mm": "အမှား၊မှားယွင်းမှု",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO22.mp3")
  },
  {
      "furi": "きず",
      "normal": "きず",
      "en": "Injuries",
      "remark_en": "",
      "mm": "ဒဏ်ရာ၊အနာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO23.mp3")
  },
  {
      "furi": "ズボン",
      "normal": "ズボン",
      "en": "Pants",
      "remark_en": "",
      "mm": "ဘောင်းဘီ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO24.mp3")
  },
  {
      "furi": "［お］としより",
      "normal": "［お］としより",
      "en": "Elderly",
      "remark_en": "",
      "mm": "သက်ကြီးရွယ်အို",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO25.mp3")
  },
  {
      "furi": "＾長（なが）｜さ",
      "normal": "長さ",
      "en": "length",
      "remark_en": "",
      "mm": "အရှည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO26.mp3")
  },
  {
      "furi": "＾重（おも）｜さ",
      "normal": "重さ",
      "en": "Weight",
      "remark_en": "",
      "mm": "အလေးချိန်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO27.mp3")
  },
  {
      "furi": "＾高（たか）｜さ",
      "normal": "高さ",
      "en": "height",
      "remark_en": "",
      "mm": "အမြင့်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO28.mp3")
  },
  {
      "furi": "＾大（おお）｜きさ",
      "normal": "大きさ",
      "en": "size, scale",
      "remark_en": "",
      "mm": "အရွယ်အစား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO29.mp3")
  },
  {
      "furi": "～｜＾便（びん）",
      "normal": "～便",
      "en": "flight number",
      "remark_en": "",
      "mm": "လေယာဉ်နံပါတ်-",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO30.mp3")
  },
  {
      "furi": "～｜＾個（こ）",
      "normal": "～個",
      "en": "counter for small objects",
      "remark_en": "",
      "mm": "-ခု",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO31.mp3")
  },
  {
      "furi": "～｜＾本（ほん）",
      "normal": "～本",
      "en": "counter for long objects",
      "remark_en": "",
      "mm": "-ချောင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO32.mp3")
  },
  {
      "furi": "～｜＾本（ぽん）",
      "normal": "～本",
      "en": "counter for long objects",
      "remark_en": "",
      "mm": "-ချောင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO33.mp3")
  },
  {
      "furi": "～｜＾本（ぼん）",
      "normal": "～本",
      "en": "counter for long objects",
      "remark_en": "",
      "mm": "-ချောင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO34.mp3")
  },
  {
      "furi": "～｜＾杯（はい）",
      "normal": "～杯",
      "en": "cup of",
      "remark_en": "",
      "mm": "-ခွက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO35.mp3")
  },
  {
      "furi": "～｜＾杯（ぱい）",
      "normal": "～杯",
      "en": "cup of",
      "remark_en": "",
      "mm": "-ခွက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO36.mp3")
  },
  {
      "furi": "～｜＾杯（ばい）",
      "normal": "～杯",
      "en": "cup of",
      "remark_en": "",
      "mm": "-ခွက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO37.mp3")
  },
  {
      "furi": "～センチ",
      "normal": "～センチ",
      "en": "-centimeters",
      "remark_en": "",
      "mm": "-စင်တီမီတာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO38.mp3")
  },
  {
      "furi": "～ミリ",
      "normal": "～ミリ",
      "en": "-millimeters",
      "remark_en": "",
      "mm": "-မီလီမီတာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO39.mp3")
  },
  {
      "furi": "～グラム",
      "normal": "～グラム",
      "en": "-grams",
      "remark_en": "",
      "mm": "-ဂရမ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO40.mp3")
  },
  {
      "furi": "～｜＾以（い）｜＾上（じょう）",
      "normal": "～以上",
      "en": "over ~",
      "remark_en": "",
      "mm": "～ထက်ပို(၍)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO41.mp3")
  },
  {
      "furi": "～｜＾以（い）｜＾下（か）",
      "normal": "～以下",
      "en": "under ~",
      "remark_en": "",
      "mm": "～ထက်လျော့(၍)",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO42.mp3")
  },
  {
      "furi": "＾長（なが）崎（さき）",
      "normal": "長崎",
      "en": "Nagasaki",
      "remark_en": "",
      "mm": "နာဂဆခိ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO43.mp3")
  },
  {
      "furi": "＾仙（せん）台（だい）",
      "normal": "仙台",
      "en": "Sendai",
      "remark_en": "",
      "mm": "ဆန်းဒိုင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO44.mp3")
  },
  {
      "furi": "JL",
      "normal": "JL",
      "en": "JL",
      "remark_en": "",
      "mm": "ဂျေအယ်လ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO45.mp3")
  },
  {
      "furi": "＾七（たな）夕（ばた）祭（まつ）｜り",
      "normal": "七夕祭り",
      "en": "Star Festival",
      "remark_en": "",
      "mm": "တနဘတပွဲတော်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO46.mp3")
  },
  {
      "furi": "＾東（とう）照（しょう）宮（ぐう）",
      "normal": "東照宮",
      "en": "Toshogu",
      "remark_en": "",
      "mm": "တိုးရှောဂူး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO47.mp3")
  },
  {
      "furi": "どうでしょうか。",
      "normal": "どうでしょうか。",
      "en": "How is ~ ?",
      "remark_en": "",
      "mm": "ဘယ်လိုလဲ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO48.mp3")
  },
  {
      "furi": "テスト",
      "normal": "テスト",
      "en": "text, examination",
      "remark_en": "",
      "mm": "စာမေးပွဲ၊ စမ်းသပ်မှု",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO49.mp3")
  },
  {
      "furi": "＾成（せい）績（せい）",
      "normal": "成績",
      "en": "score, result",
      "remark_en": "",
      "mm": "ရမှတ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO50.mp3")
  },
  {
      "furi": "ところで",
      "normal": "ところで",
      "en": "by the way",
      "remark_en": "",
      "mm": "စကားမစပ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO51.mp3")
  },
  {
      "furi": "いらっしゃいます",
      "normal": "いらっしゃいます",
      "en": "come",
      "remark_en": "",
      "mm": "ကြွသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO52.mp3")
  },
  {
      "furi": "＾様（よう）子（す）",
      "normal": "様子",
      "en": "situation, condition, appearance",
      "remark_en": "",
      "mm": "ပုံသဏ္ဌာန်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson40/vocab/4N40VO53.mp3")
  },
  {
      "furi": "＾事（じ）件（けん）",
      "normal": "事件",
      "en": "incident, case",
      "remark_en": "",
      "mm": "အမှု",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "オートバイ",
      "normal": "オートバイ",
      "en": "motorcycle",
      "remark_en": "",
      "mm": "မော်တော်ဆိုင်ကယ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾爆（ばく）弾（だん）",
      "normal": "爆弾",
      "en": "bomb",
      "remark_en": "",
      "mm": "ဗုံး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾積（つ）｜みます",
      "normal": "積みます",
      "en": "load, pile up",
      "remark_en": "",
      "mm": "စုပုံသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾運（うん）転（てん）手（しゅ）",
      "normal": "運転手",
      "en": "driver",
      "remark_en": "",
      "mm": "ကား/ရထားမောင်းသူ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾離（はな）｜れた",
      "normal": "離れた",
      "en": "remote",
      "remark_en": "",
      "mm": "အလှမ်းကွာသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾急（きゅう）｜に",
      "normal": "急に",
      "en": "suddenly",
      "remark_en": "",
      "mm": "ရုတ်တရက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾動（うご）｜かします",
      "normal": "動かします",
      "en": "operate, move",
      "remark_en": "",
      "mm": "လှုပ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾一（いっ）生（しょう）懸（けん）命（めい）",
      "normal": "一生懸命",
      "en": "will all one’s effort",
      "remark_en": "",
      "mm": "အားသွန်ခွန်စိုက်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾犯（はん）人（にん）",
      "normal": "犯人",
      "en": "suspect, criminal",
      "remark_en": "",
      "mm": "ရာဇဝတ်သား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾男（おとこ）",
      "normal": "男",
      "en": "men",
      "remark_en": "",
      "mm": "ယျောကျ်ား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾手（て）｜に｜＾入（い）｜れます",
      "normal": "手に入れます",
      "en": "obtain, get",
      "remark_en": "",
      "mm": "အရယူသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾今（いま）｜でも",
      "normal": "今でも",
      "en": "even now",
      "remark_en": "",
      "mm": "ယခုထက်တိုင်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  }
]

/*
    {
        furi: "",
        normal: "",
        en: "",
        remark_en: "",
        mm: "",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson29/vocab/4N29VO1.mp3")
    },


*/
const test = [
  {
    'goi': '数（かぞ）」えます',
    'unicode': 'ရေတွက်သည်',
    'zawgyi': 'ေရတြက္သည္',
    'english': 'count',
    'long': false
  },
  {
    'goi': '量（はか）」ります',
    'unicode': 'ချိန်တွယ်သည်',
    'zawgyi': 'ခ်ိန္တြယ္သည္',
    'english': 'weigh',
    'long': false
  },
  {
    'goi': '確（たし）」かめます',
    'unicode': 'စိစစ်သည်',
    'zawgyi': 'စိစစ္သည္',
    'english': 'confirm, make sure',
    'long': false
  },
  {
    'goi': '合（あ）」います',
    'unicode': 'ကိုက်ညီသည်',
    'zawgyi': 'ကိုက္ညီသည္',
    'english': 'fit',
    'long': false
  },
  {
    'goi': '出（しゅっ）」発（ぱつ）」します',
    'unicode': 'ထွက်ခွါသည်',
    'zawgyi': 'ထြက္ခြါသည္',
    'english': 'depart',
    'long': false
  },
  {
    'goi': '到（とう）」着（ちゃく）」します',
    'unicode': 'ဆိုက်ရောက်သည်',
    'zawgyi': 'ဆိုက္ေရာက္သည္',
    'english': 'arrive',
    'long': false
  },
  {
    'goi': '酔（よ）」います',
    'unicode': 'မူးဝေသည်',
    'zawgyi': 'မူးေဝသည္',
    'english': 'get drunk',
    'long': false
  },
  {
    'goi': 'うまくいきます',
    'unicode': 'အဆင်ပြေသည်',
    'zawgyi': 'အဆင္ေျပသည္',
    'english': 'It works well',
    'long': false
  },
  {
    'goi': '出（で）」ます',
    'unicode': 'ထွက်ပေါ်သည်',
    'zawgyi': 'ထြက္ေပၚသည္',
    'english': 'Occurs(problem)',
    'long': false
  },
  {
    'goi': '相（そう）」談（だん）」します',
    'unicode': 'တိုင်ပင်ဆွေးနွေးသည်',
    'zawgyi': 'တိုင္ပင္ေဆြးေႏြးသည္',
    'english': 'To consult',
    'long': false
  },
  {
    'goi': '必（ひつ）」要（よう）」[な]',
    'unicode': 'လိုအပ်သော',
    'zawgyi': 'လိုအပ္ေသာ',
    'english': 'necessary',
    'long': false
  },
  {
    'goi': '天（てん）」気（き）」予（よ）」報（ほう）',
    'unicode': 'မိုးလေဝသသတင်း',
    'zawgyi': 'မိုးေလဝသသတင္း',
    'english': 'weather forecast',
    'long': false
  },
  {
    'goi': '忘（ぼう）」年（ねん）」会（かい）',
    'unicode': 'နှစ်ကုန်အထိမ်းအမှတ်',
    'zawgyi': 'ႏွစ္ကုန္အထိမ္းအမွတ္',
    'english': 'year-end party',
    'long': false
  },
  {
    'goi': '新（しん）」年（ねん）」会（かい）',
    'unicode': 'နှစ်သစ်ကူးအထိမ်းအမှတ်ပွဲ',
    'zawgyi': 'ႏွစ္သစ္ကူးအထိမ္းအမွတ္ပြဲ',
    'english': 'New Year’s party',
    'long': false
  },
  {
    'goi': '二（に）」次（じ）」会（かい）',
    'unicode': 'ဒုတိယပွဲဆက်',
    'zawgyi': 'ဒုတိယပြဲဆက္',
    'english': 'second party',
    'long': false
  },
  {
    'goi': '発（はっ）」表（ぴょう）」会（かい）',
    'unicode': 'စာတမ်းဖတ်ပွဲ',
    'zawgyi': 'စာတမ္းဖတ္ပြဲ',
    'english': 'Presentation',
    'long': false
  },
  {
    'goi': '大（たい）」会（かい）',
    'unicode': 'ပြိုင်ပွဲ',
    'zawgyi': 'ၿပိဳင္ပြဲ',
    'english': 'mass meeting, convention',
    'long': false
  },
  {
    'goi': 'マラソン',
    'unicode': 'မာရသွန်',
    'zawgyi': 'မာရသြန္',
    'english': 'marathon',
    'long': false
  },
  {
    'goi': 'コンテスト',
    'unicode': 'ယှဉ်ပြိုင်ပွဲ',
    'zawgyi': 'ယွဥ္ၿပိဳင္ပြဲ',
    'english': 'contest',
    'long': false
  },
  {
    'goi': 'おもて',
    'unicode': 'အပြင်ဘက်',
    'zawgyi': 'အျပင္ဘက္',
    'english': 'face, front',
    'long': false
  },
  {
    'goi': 'うら',
    'unicode': 'အတွင်းဘက်',
    'zawgyi': 'အတြင္းဘက္',
    'english': 'back, inside',
    'long': false
  },
  {
    'goi': 'まちがい',
    'unicode': 'အမှား၊မှားယွင်းမှု',
    'zawgyi': 'အမွား၊မွားယြင္းမႈ',
    'english': 'wrong, mistake',
    'long': false
  },
  {
    'goi': 'きず',
    'unicode': 'ဒဏ်ရာ၊အနာ',
    'zawgyi': 'ဒဏ္ရာ၊အနာ',
    'english': 'Injuries',
    'long': false
  },
  {
    'goi': 'ズボン',
    'unicode': 'ဘောင်းဘီ',
    'zawgyi': 'ေဘာင္းဘီ',
    'english': 'Pants',
    'long': false
  },
  {
    'goi': '［お］としより',
    'unicode': 'သက်ကြီးရွယ်အို',
    'zawgyi': 'သက္ႀကီးရြယ္အို',
    'english': 'Elderly',
    'long': false
  },
  {
    'goi': '長（なが）」さ',
    'unicode': 'အရှည်',
    'zawgyi': '',
    'english': '',
    'long': false
  },
  {
    'goi': '重（おも）」さ',
    'unicode': 'အလေးချိန်',
    'zawgyi': 'အေလးခ်ိန္',
    'english': 'Weight',
    'long': false
  },
  {
    'goi': '高（たか）」さ',
    'unicode': 'အမြင့်',
    'zawgyi': 'အျမင့္',
    'english': 'height',
    'long': false
  },
  {
    'goi': '大（おお）」きさ',
    'unicode': 'အရွယ်အစား',
    'zawgyi': 'အရြယ္အစား',
    'english': 'size, scale',
    'long': false
  },
  {
    'goi': '～」便（びん）',
    'unicode': 'လေယာဉ်နံပါတ်-',
    'zawgyi': 'ေလယာဥ္နံပါတ္-',
    'english': 'flight number',
    'long': false
  },
  {
    'goi': '～」個（こ）',
    'unicode': '-ခု',
    'zawgyi': '-ခု',
    'english': 'counter for small objects',
    'long': false
  },
  {
    'goi': '～」本（ほん）',
    'unicode': '-ချောင်း',
    'zawgyi': '-ေခ်ာင္း',
    'english': 'counter for long objects',
    'long': false
  },
  {
    'goi': '～」本（ぽん）',
    'unicode': '-ချောင်း',
    'zawgyi': '-ေခ်ာင္း',
    'english': 'counter for long objects',
    'long': false
  },
  {
    'goi': '～」本（ぼん）',
    'unicode': '-ချောင်း',
    'zawgyi': '-ေခ်ာင္း',
    'english': 'counter for long objects',
    'long': false
  },
  {
    'goi': '～」杯（はい）',
    'unicode': '-ခွက်',
    'zawgyi': '-ခြက္',
    'english': 'cup of',
    'long': false
  },
  {
    'goi': '～」杯（ぱい）',
    'unicode': '-ခွက်',
    'zawgyi': '-ခြက္',
    'english': 'cup of',
    'long': false
  },
  {
    'goi': '～」杯（ばい）',
    'unicode': '-ခွက်',
    'zawgyi': '-ခြက္',
    'english': 'cup of',
    'long': false
  },
  {
    'goi': '～センチ',
    'unicode': '-စင်တီမီတာ',
    'zawgyi': '-စင္တီမီတာ',
    'english': '-centimeters',
    'long': false
  },
  {
    'goi': '～ミリ',
    'unicode': '-မီလီမီတာ',
    'zawgyi': '-မီလီမီတာ',
    'english': '-millimeters',
    'long': false
  },
  {
    'goi': '～グラム',
    'unicode': '-ဂရမ်',
    'zawgyi': '-ဂရမ္',
    'english': '-grams',
    'long': false
  },
  {
    'goi': '～」以（い）」上（じょう）',
    'unicode': '～ထက်ပို(၍)',
    'zawgyi': '～ထက္ပို(၍)',
    'english': 'over ~',
    'long': false
  },
  {
    'goi': '～」以（い）」下（か）',
    'unicode': '～ထက်လျော့(၍)',
    'zawgyi': '～ထက္ေလ်ာ့(၍)',
    'english': 'under ~',
    'long': false
  },
  {
    'goi': '長（なが）」崎（さき）',
    'unicode': 'နာဂဆခိ',
    'zawgyi': 'နာဂဆခိ',
    'english': 'Nagasaki',
    'long': false
  },
  {
    'goi': '仙（せん）」台（だい）',
    'unicode': 'ဆန်းဒိုင်း',
    'zawgyi': 'ဆန္းဒိုင္း',
    'english': 'Sendai',
    'long': false
  },
  {
    'goi': 'JL',
    'unicode': 'ဂျေအယ်လ်',
    'zawgyi': 'ေဂ်အယ္လ္',
    'english': 'JL',
    'long': false
  },
  {
    'goi': '七（たな）」夕（ばた）」祭（まつ）」り',
    'unicode': 'တနဘတပွဲတော်',
    'zawgyi': 'တနဘတပြဲေတာ္',
    'english': 'Star Festival',
    'long': false
  },
  {
    'goi': '東（とう）」照（しょう）」宮（ぐう）',
    'unicode': 'တိုးရှောဂူး',
    'zawgyi': 'တိုးေရွာဂူး',
    'english': 'Toshogu',
    'long': false
  },
  {
    'goi': 'どうでしょうか。',
    'unicode': 'ဘယ်လိုလဲ',
    'zawgyi': 'ဘယ္လိုလဲ',
    'english': 'How is ~ ?',
    'long': false
  },
  {
    'goi': 'テスト',
    'unicode': 'စာမေးပွဲ၊ စမ်းသပ်မှု',
    'zawgyi': 'စာေမးပြဲ၊ စမ္းသပ္မႈ',
    'english': 'text, examination',
    'long': false
  },
  {
    'goi': '成（せい）」績（せい）',
    'unicode': 'ရမှတ်',
    'zawgyi': 'ရမွတ္',
    'english': 'score, result',
    'long': false
  },
  {
    'goi': 'ところで',
    'unicode': 'စကားမစပ်',
    'zawgyi': 'စကားမစပ္',
    'english': 'by the way',
    'long': false
  },
  {
    'goi': 'いらっしゃいます',
    'unicode': 'ကြွသည်',
    'zawgyi': 'ႂကြသည္',
    'english': 'come',
    'long': false
  },
  {
    'goi': '様（よう）」子（す）',
    'unicode': 'ပုံသဏ္ဌာန်',
    'zawgyi': 'ပုံသဏၭာန္',
    'english': 'situation, condition, appearance',
    'long': false
  },
  {
    'goi': '事（じ）」件（けん）',
    'unicode': 'အမှု',
    'zawgyi': 'အမႈ',
    'english': 'incident, case',
    'long': false
  },
  {
    'goi': 'オートバイ',
    'unicode': 'မော်တော်ဆိုင်ကယ်',
    'zawgyi': 'ေမာ္ေတာ္ဆိုင္ကယ္',
    'english': 'motorcycle',
    'long': false
  },
  {
    'goi': '爆（ばく）」弾（だん）',
    'unicode': 'ဗုံး',
    'zawgyi': 'ဗုံး',
    'english': 'bomb',
    'long': false
  },
  {
    'goi': '積（つ）」みます',
    'unicode': 'စုပုံသည်',
    'zawgyi': 'စုပုံသည္',
    'english': 'load, pile up',
    'long': false
  },
  {
    'goi': '運（うん）」転（てん）」手（しゅ）',
    'unicode': 'ကား/ရထားမောင်းသူ',
    'zawgyi': 'ကား/ရထားေမာင္းသူ',
    'english': 'driver',
    'long': false
  },
  {
    'goi': '離（はな）」れた',
    'unicode': 'အလှမ်းကွာသည်',
    'zawgyi': 'အလွမ္းကြာသည္',
    'english': 'remote',
    'long': false
  },
  {
    'goi': '急（きゅう）」に',
    'unicode': 'ရုတ်တရက်',
    'zawgyi': '႐ုတ္တရက္',
    'english': 'suddenly',
    'long': false
  },
  {
    'goi': '動（うご）」かします',
    'unicode': 'လှုပ်သည်',
    'zawgyi': 'လႈပ္သည္',
    'english': 'operate, move',
    'long': false
  },
  {
    'goi': '一（いっ）」生（しょう）」懸（けん）」命（めい）',
    'unicode': 'အားသွန်ခွန်စိုက်',
    'zawgyi': 'အားသြန္ခြန္စိုက္',
    'english': 'will all one’s effort',
    'long': false
  },
  {
    'goi': '犯（はん）」人（にん）',
    'unicode': 'ရာဇဝတ်သား',
    'zawgyi': 'ရာဇဝတ္သား',
    'english': 'suspect, criminal',
    'long': false
  },
  {
    'goi': '男（おとこ）',
    'unicode': 'ယျောကျ်ား',
    'zawgyi': 'ေယ်ာက်္ား',
    'english': 'men',
    'long': false
  },
  {
    'goi': '手（て）」に」入（い）」れます',
    'unicode': 'အရယူသည်',
    'zawgyi': 'အရယူသည္',
    'english': 'obtain, get',
    'long': false
  },
  {
    'goi': '今（いま）」でも',
    'unicode': 'ယခုထက်တိုင်',
    'zawgyi': 'ယခုထက္တိုင္',
    'english': 'even now',
    'long': false
  }
]