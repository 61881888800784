export const lesson1 = {
    '住': 0,
    '所': 1,
    '京': 2,
    '都': 3,
    '府': 4,
    '県': 5,
    '市': 6,
    '区': 7,
    '町': 8,
    '村': 9
};