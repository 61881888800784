import Number from "@components/common/Number"
import { memo } from "react"

const KanjiExampleMobileComponent = ({fontSize,furigana,example,furiTranslate,kanjiDetail,lang}:any) => {
    const ExampleTile = ({ sentence, meaning, i }: any) => {
        return (
            <div className="flex flex-row items-start mt-4">
                <Number fontSize={fontSize} furigana={furigana}>
                    {i + 1}
                    <span className="ml-2">.</span>
                </Number>
                <div className="flex flex-col">
                    <div className="">{sentence}</div>
                    <div className="text-navbar">{meaning}</div>
                </div>
            </div>
        )
    }
    return <>
        <div className={` absolute left-0 px-[10px] py-[5px] rounded-r-[100px] text-[14px] bg-[#0084E8] bunkei-title-light text-white w-fit`}>
            Example
        </div>
        <div className="mt-10 flex flex-col p-4 pt-2">
                {
                    example.map(({ furi, normal, en, mm }: any, i: number) => {
                        return (<div key={Math.random()}>
                            {<ExampleTile i={i} sentence={furigana === 'true' ?
                                furiTranslate({ context: furi, fontSize, style: "flex-wrap items-center", kanjiDetail }) : normal}
                                meaning={lang == 'en' ? en : mm}
                            />}
                        </div>)
                    })
                }
        </div>
    </>
}
export default memo(KanjiExampleMobileComponent);