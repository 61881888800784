export const explain = [
    // ＾友（とも）達（だち）｜
    {
        pattern_furi: 'お+Vます+します',
        pattern_normal: 'お+Vます+します',
        en: [
            '　Used in self-deprecating speech.',
            '　Using 「お」 in front of words is a kind of self-deprecating speech, as well as a polite way of communicating with the other.',
            ],
        mm: [
            '　မိမိကိုယ်ကို နှိမ်ချပြောခြင်းတွင် သုံးသည်။',
            '　စကားလုံးများ၏ရှေ့တွင် 「お」ထည့်၍သုံးခြင်းသည် မိမိကိုယ်နှိမ်ချပြောခြင်းတမျိုးဖြစ်သလို တဖက်သူကို ယဉ်ကျေးစွာ ပြောဆိုဆက်ဆံသော အဓိပ္ပါယ်လည်း ရပါသည်။',
        ]
    },
    {
        pattern_furi: 'ご+｜＾漢（かん）語（ご）｜+します',
        pattern_normal: 'ご+漢語(かんご)+します',
        en: [
            '　Used in self-deprecating speech.',
            ],
        mm: [
            '　မိမိကိုယ်ကို နှိမ်ချပြောခြင်းတွင် သုံးသည်။',
        ]
    },
   
]