export const lesson30 = [
  {
    furi: '＾はります',
    normal: 'はります',
    mm: 'ကပ်သည်',
    remark_mm: "",
    en: 'put up, post, paste',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO1.mp3")
  },
  {
    furi: '＾掛（か）｜けます',
    normal: '掛けます',
    mm: 'ချိတ်ဆက်သည်',
    remark_mm: "",
    en: 'hang',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO2.mp3")
  },
  {
    furi: '＾飾（かざ）｜ります',
    normal: '飾ります',
    mm: 'အလှဆင်သည်',
    remark_mm: "",
    en: 'decorate',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO3.mp3")
  },
  {
    furi: '＾並（なら）｜べます',
    normal: '並べます',
    mm: 'တန်းစီသည်',
    remark_mm: "",
    en: 'arrange, line up',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO4.mp3")
  },
  {
    furi: '＾植（う）｜えます',
    normal: '植えます',
    mm: 'စိုက်ပျိုးသည်',
    remark_mm: "",
    en: 'plant',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO5.mp3")
  },
  {
    furi: '＾戻（もど）｜します',
    normal: '戻します',
    mm: 'ပြန်ထားသည်',
    remark_mm: "",
    en: 'return, put ~ back',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO6.mp3")
  },
  {
    furi: 'まとめます',
    normal: 'まとめます',
    mm: 'စုစည်းသည်၊နိဂုံးချုပ်သည်',
    remark_mm: "",
    en: 'put ~ together, sum up',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO7.mp3")
  },
  {
    furi: 'しまいます',
    normal: 'しまいます',
    mm: 'သိမ်းဆည်းသည်',
    remark_mm: "",
    en: 'put things in their place',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO8.mp3")
  },
  {
    furi: '＾決（き）｜めます',
    normal: '決めます',
    mm: 'ဆုံးဖြတ်သည်',
    remark_mm: "",
    en: 'decide',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO9.mp3")
  },
  {
    furi: '＾予（よ）習（しゅう）｜します',
    normal: '予習します',
    mm: 'ကြိုတင်လေ့လာသည်',
    remark_mm: "",
    en: 'prepare one\'s lesson',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO10.mp3")
  },
  {
    furi: '＾復（ふく）習（しゅう）｜します',
    normal: '復習します',
    mm: 'ပြန်လှန်လေ့ကျင့်သည်',
    remark_mm: "",
    en: 'review one\'s lesson',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO11.mp3")
  },
  {
    furi: 'そのままにします',
    normal: 'そのままにします',
    mm: 'အဲ့ဒီအတိုင်းထားသည်',
    remark_mm: "",
    en: 'leave thing as they are',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO12.mp3")
  },
  {
    furi: '＾授（じゅ）業（ぎょう）',
    normal: '授業',
    mm: 'စာသင်ကြားမှု',
    remark_mm: "",
    en: 'class',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO13.mp3")
  },
  {
    furi: '＾講（こう）義（ぎ）',
    normal: '講義',
    mm: 'ပို့ချမှု',
    remark_mm: "",
    en: 'lecture',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO14.mp3")
  },
  {
    furi: 'ミーテイング',
    normal: 'ミーテイング',
    mm: 'အစည်းအဝေး',
    remark_mm: "",
    en: 'meeting',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO15.mp3")
  },
  {
    furi: '＾予（よ）定（てい）',
    normal: '予定',
    mm: 'အစီအစဉ်',
    remark_mm: "",
    en: 'a plan, a scheduled',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO16.mp3")
  },
  {
    furi: 'お｜＾知（し）｜らせ',
    normal: 'お知らせ',
    mm: 'အကြောင်းကြားခြင်း',
    remark_mm: "",
    en: 'a notice',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO17.mp3")
  },
  {
    furi: 'ガイドブック',
    normal: 'ガイドブック',
    mm: 'လမ်းညွှန်',
    remark_mm: "",
    en: 'guidebook',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO18.mp3")
  },
  {
    furi: 'カレンダー',
    normal: 'カレンダー',
    mm: 'ပြက္ခဒိန်',
    remark_mm: "",
    en: 'calendar',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO19.mp3")
  },
  {
    furi: 'ポスター',
    normal: 'ポスター',
    mm: 'ပိုစတာ',
    remark_mm: "",
    en: 'poster',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO20.mp3")
  },
  {
    furi: '＾予（よ）定（てい）表（ひょう）',
    normal: '予定表',
    mm: 'အချိန်ဇယား',
    remark_mm: "",
    en: 'a scheduled',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO21.mp3")
  },
  {
    furi: 'ゴミ｜＾箱（ばこ）',
    normal: 'ゴミ箱',
    mm: 'အမှိုက်ပုံး',
    remark_mm: "",
    en: 'trash can, dustbin',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO22.mp3")
  },
  {
    furi: '＾人（にん）形（ぎょう）',
    normal: '人形',
    mm: 'လူရုပ်',
    remark_mm: "",
    en: 'doll',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO23.mp3")
  },
  {
    furi: '＾花（か）瓶（びん）',
    normal: '花瓶',
    mm: 'ပန်းအိုး',
    remark_mm: "",
    en: 'vase',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO24.mp3")
  },
  {
    furi: '＾鏡（かがみ）',
    normal: '鏡',
    mm: 'ကြည့်မှန်',
    remark_mm: "",
    en: 'mirror',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO25.mp3")
  },
  {
    furi: '＾引（ひ）｜き｜＾出（だ）｜し',
    normal: '引き出し',
    mm: 'အ့ဆွဲ',
    remark_mm: "",
    en: 'drawer',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO26.mp3")
  },
  {
    furi: '＾玄（げん）関（かん）',
    normal: '玄関',
    mm: 'ဖိနပ်ချွတ်နေရာ',
    remark_mm: "",
    en: 'front door, porch',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO27.mp3")
  },
  {
    furi: '＾廊（ろう）下（か）',
    normal: '廊下',
    mm: 'လျှောက်လမ်း',
    remark_mm: "",
    en: 'corridor, hallway',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO28.mp3")
  },
  {
    furi: '＾壁（かべ）',
    normal: '壁',
    mm: 'နံရံ',
    remark_mm: "",
    en: 'wall',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO29.mp3")
  },
  {
    furi: '＾池（いけ）',
    normal: '池',
    mm: 'ရေကန်',
    remark_mm: "",
    en: 'pond',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO30.mp3")
  },
  {
    furi: '＾元（もと）｜の｜＾所（ところ）',
    normal: '元の所',
    mm: 'မူလနေရာ',
    remark_mm: "",
    en: 'original place',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO31.mp3")
  },
  {
    furi: '＾周（まわ）｜り',
    normal: '周り',
    mm: 'ဘေးပတ်လည်',
    remark_mm: "",
    en: 'round, around',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO32.mp3")
  },
  {
    furi: '＾真（ま）｜ん｜＾中（なか）',
    normal: '真ん中',
    mm: 'အလယ်ခေါင်',
    remark_mm: "",
    en: 'center',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO33.mp3")
  },
  {
    furi: '＾隅（すみ）',
    normal: '隅',
    mm: 'ထောင့်စွန်း',
    remark_mm: "",
    en: 'corner',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO34.mp3")
  },
  {
    furi: 'まだ',
    normal: 'まだ',
    mm: 'သေးတုန်း',
    remark_mm: "",
    en: 'not yet',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO35.mp3")
  },
  {
    furi: 'リュック',
    normal: 'リュック',
    mm: 'ကျောပိုးအိတ်',
    remark_mm: "",
    en: 'ruck sack',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO36.mp3")
  },
  {
    furi: '＾非（ひ）常（じょう）袋（ぶくろ）',
    normal: '非常袋',
    mm: 'အရေးပေါ်အိတ်',
    remark_mm: "",
    en: 'Emergency bag',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO37.mp3")
  },
  {
    furi: '＾非（ひ）常（じょう）時（じ）',
    normal: '非常時',
    mm: 'အရေးပေါ်အချိန်',
    remark_mm: "",
    en: 'In an emergency',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO38.mp3")
  },
  {
    furi: '＾生（せい）活（かつ）｜します',
    normal: '生活します',
    mm: 'နေထိုင်အသက်မွေးသည်',
    remark_mm: "",
    en: 'Live',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO39.mp3")
  },
  {
    furi: '＾懐（かい）中（ちゅう）電（でん）灯（とう）',
    normal: '懐中電灯',
    mm: 'လက်နှိပ်ဓါတ်မီး',
    remark_mm: "",
    en: 'flashlight',
    remark_en: "",
    audioUrl: require("@assets/audio/lesson30/vocab/4N30VO40.mp3")
  },
  {
    furi: '～とか、～とか',
    normal: '～とか、～とか',
    mm: '～တို့၊～တို့',
    remark_mm: "",
    en: '~ Or ~',
    remark_en: ""
  },
  {
    furi: '＾丸（まる）｜い',
    normal: '丸い',
    mm: 'လုံးဝိုင်းသော၊',
    remark_mm: "",
    en: 'round',
    remark_en: ""
  },
  {
    furi: 'ある～',
    normal: 'ある～',
    mm: 'တစ်ခုသော～',
    remark_mm: "",
    en: 'some~',
    remark_en: ""
  },
  {
    furi: '＾夢（ゆめ）｜を｜＾見（み）｜ます',
    normal: '夢を見ます',
    mm: 'အိမ်မက် မက်သည်',
    remark_mm: "",
    en: 'Dream',
    remark_en: ""
  },
  {
    furi: 'うれしい',
    normal: 'うれしい',
    mm: 'ဝမ်းသာသော',
    remark_mm: "",
    en: 'happy, glad',
    remark_en: ""
  },
  {
    furi: '＾嫌（いや）｜[な]',
    normal: '嫌[な]',
    mm: 'မနှစ်မြို့သော',
    remark_mm: "",
    en: 'hateful, disagreeable',
    remark_en: ""
  },
  {
    furi: 'すると',
    normal: 'すると',
    mm: 'ဆိုရင်ပဲ',
    remark_mm: "",
    en: 'and then',
    remark_en: ""
  },
  {
    furi: '＾目（め）｜が｜＾覚（さ）｜めます',
    normal: '目が覚めます',
    mm: 'နိုးသည်',
    remark_mm: "",
    en: 'wake up',
    remark_en: ""
  }
];