import BOY from "@assets/icon/kaiwa/boy.svg";
import GIRL from "@assets/icon/kaiwa/girl.svg";
import MEN1 from "@assets/icon/kaiwa/men1.svg";
import MEN2 from "@assets/icon/kaiwa/men2.svg";
import WOMEN1 from "@assets/icon/kaiwa/women1.svg";
import WOMEN2 from "@assets/icon/kaiwa/women2.svg";
import MANAGER from "@assets/icon/kaiwa/manager.svg";
import STAFF from "@assets/icon/kaiwa/staff.svg";
import OFFICER from "@assets/icon/kaiwa/officer.svg";
import POLICE from "@assets/icon/kaiwa/police.svg";
import PEOPLE1 from "@assets/icon/kaiwa/people1.svg";
import PEOPLE2 from "@assets/icon/kaiwa/people2.svg";
import DOCTOR from "@assets/icon/kaiwa/doctor.svg";
import TRAFFIC_POLICE from "@assets/icon/kaiwa/traffic-police.svg";
import DELIVERY from "@assets/icon/kaiwa/delivery.svg";

export const Kaiwa = {
    BOY,GIRL,MEN1,MEN2,WOMEN1,WOMEN2,MANAGER,STAFF,
    OFFICER,POLICE,PEOPLE1,DOCTOR,TRAFFIC_POLICE,DELIVERY
}