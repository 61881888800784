export const lesson39 = [
  {
      "furi": "＾答（こた）｜えます",
      "normal": "答えます",
      "en": "answer",
      "remark_en": "",
      "mm": "ဖြေဆိုသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO1.mp3")
  },
  {
      "furi": "＾倒（たお）｜れます",
      "normal": "倒れます",
      "en": "fall down",
      "remark_en": "",
      "mm": "ပြိုလဲကျသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO2.mp3")
  },
  {
      "furi": "＾通（とお）｜ります",
      "normal": "通ります",
      "en": "pass",
      "remark_en": "",
      "mm": "ဖြတ်သန်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO3.mp3")
  },
  {
      "furi": "＾死（し）｜にます",
      "normal": "死にます",
      "en": "die",
      "remark_en": "",
      "mm": "သေသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO4.mp3")
  },
  {
      "furi": "びっくりします",
      "normal": "びっくりします",
      "en": "be surprised",
      "remark_en": "",
      "mm": "အထိတ်တလန့်ဖြစ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO5.mp3")
  },
  {
      "furi": "がっかりします",
      "normal": "がっかりします",
      "en": "be disappointed",
      "remark_en": "",
      "mm": "စိတ်ဓါတ်ကျသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO6.mp3")
  },
  {
      "furi": "＾安（あん）心（しん）｜します",
      "normal": "安心します",
      "en": "be relieved",
      "remark_en": "",
      "mm": "စိတ်ချ/အေးသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO7.mp3")
  },
  {
      "furi": "けんかします",
      "normal": "けんかします",
      "en": "quarrel, fight",
      "remark_en": "",
      "mm": "ရန်ဖြစ်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO8.mp3")
  },
  {
      "furi": "＾離（り）婚（こん）｜します",
      "normal": "離婚します",
      "en": "divorce",
      "remark_en": "",
      "mm": "ကွာရှင်းပြတ်ဆဲသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO9.mp3")
  },
  {
      "furi": "＾太（ふと）｜ります",
      "normal": "太ります",
      "en": "to get fat",
      "remark_en": "",
      "mm": "ဝသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO10.mp3")
  },
  {
      "furi": "やせます",
      "normal": "やせます",
      "en": "Lose weight",
      "remark_en": "",
      "mm": "ပိန်သည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO11.mp3")
  },
  {
      "furi": "＾複（ふく）雑（ざつ）｜[な]",
      "normal": "複雑[な]",
      "en": "complicated, complex",
      "remark_en": "",
      "mm": "ရှုတ်ထွေးသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO12.mp3")
  },
  {
      "furi": "＾邪（じゃ）魔（ま）｜[な]",
      "normal": "邪魔[な]",
      "en": "obstructive, in the way",
      "remark_en": "",
      "mm": "အနှောင့်အယှက်ဖြစ်သော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO13.mp3")
  },
  {
      "furi": "＾硬（かた）｜い",
      "normal": "硬い",
      "en": "Hard",
      "remark_en": "",
      "mm": "မာကြောသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO14.mp3")
  },
  {
      "furi": "＾軟（やわ）｜らかい",
      "normal": "軟らかい",
      "en": "soft",
      "remark_en": "",
      "mm": "ပျော့ပြောင်းသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO15.mp3")
  },
  {
      "furi": "＾汚（きたな）｜い",
      "normal": "汚い",
      "en": "dirty",
      "remark_en": "",
      "mm": "ညစ်ပတ်သော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO16.mp3")
  },
  {
      "furi": "うれしい",
      "normal": "うれしい",
      "en": "glad, happy",
      "remark_en": "",
      "mm": "ဝမ်းသာသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO17.mp3")
  },
  {
      "furi": "＾悲（かな）｜しい",
      "normal": "悲しい",
      "en": "sad",
      "remark_en": "",
      "mm": "ဝမ်းနည်းသော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO18.mp3")
  },
  {
      "furi": "＾恥（は）｜ずかしい",
      "normal": "恥ずかしい",
      "en": "embarrassed, a shamed",
      "remark_en": "",
      "mm": "ရှက်သော",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO19.mp3")
  },
  {
      "furi": "＾首（しゅ）相（しょう）",
      "normal": "首相",
      "en": "Prime Minister",
      "remark_en": "",
      "mm": "ဝန်ကြီးချုပ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO20.mp3")
  },
  {
      "furi": "＾地（じ）震（しん）",
      "normal": "地震",
      "en": "earthquake",
      "remark_en": "",
      "mm": "မြေငလျင်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO21.mp3")
  },
  {
      "furi": "＾津（つ）波（なみ）",
      "normal": "津波",
      "en": "tidal wave",
      "remark_en": "",
      "mm": "ဆူနာမီ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO22.mp3")
  },
  {
      "furi": "＾台（たい）風（ふう）",
      "normal": "台風",
      "en": "typhoon",
      "remark_en": "",
      "mm": "မုန်တိုင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO23.mp3")
  },
  {
      "furi": "＾雷（かみなり）",
      "normal": "雷",
      "en": "Thunder",
      "remark_en": "",
      "mm": "မိုးကြိုး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO24.mp3")
  },
  {
      "furi": "＾火（か）事（じ）",
      "normal": "火事",
      "en": "fire",
      "remark_en": "",
      "mm": "မီးလောင်မှု",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO25.mp3")
  },
  {
      "furi": "＾事（じ）故（こ）",
      "normal": "事故",
      "en": "accident",
      "remark_en": "",
      "mm": "မတော်တဆမှု့",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO26.mp3")
  },
  {
      "furi": "ハイキング",
      "normal": "ハイキング",
      "en": "hiking",
      "remark_en": "",
      "mm": "အပျော်ခရီးထွက်ခြင်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO27.mp3")
  },
  {
      "furi": "＾[お]見（み）合（あ）｜い",
      "normal": "[お]見合い",
      "en": "interview with a view to marriage",
      "remark_en": "",
      "mm": "အိမ်ထောင်ဖက်နှင့်တွေ့ဆုံ-",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO28.mp3")
  },
  {
      "furi": "＾操（そう）作（さ）",
      "normal": "操作",
      "en": "operation",
      "remark_en": "",
      "mm": "လုပ်ဆောင်နည်း",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO29.mp3")
  },
  {
      "furi": "＾会（かい）場（じょう）",
      "normal": "会場",
      "en": "meeting place",
      "remark_en": "",
      "mm": "ခန်းမ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO30.mp3")
  },
  {
      "furi": "～｜＾代（だい）",
      "normal": "～代",
      "en": "charge, fare, fee",
      "remark_en": "",
      "mm": "～ခ၊～ဖိုး",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO31.mp3")
  },
  {
      "furi": "～｜＾屋（や）",
      "normal": "～屋",
      "en": "~shop",
      "remark_en": "",
      "mm": "～ဆိုင်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO32.mp3")
  },
  {
      "furi": "フロント",
      "normal": "フロント",
      "en": "front desk, reception desk",
      "remark_en": "",
      "mm": "ဧည့်ကြိုကောင်တာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO33.mp3")
  },
  {
      "furi": "ー｜＾号（ごう）｜＾室（しつ）",
      "normal": "ー号室",
      "en": "room number ~",
      "remark_en": "",
      "mm": "အခန်းနံပါတ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO34.mp3")
  },
  {
      "furi": "タオル",
      "normal": "タオル",
      "en": "towel",
      "remark_en": "",
      "mm": "မျက်နှာသုတ်ပဝါ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO35.mp3")
  },
  {
      "furi": "せっけん",
      "normal": "せっけん",
      "en": "soap",
      "remark_en": "",
      "mm": "ဆပ်ပြာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO36.mp3")
  },
  {
      "furi": "＾大（おお）勢（ぜい）",
      "normal": "大勢",
      "en": "a great number of people",
      "remark_en": "",
      "mm": "လူအများ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO37.mp3")
  },
  {
      "furi": "お｜＾疲（つか）｜れさまでした。",
      "normal": "お疲れさまでした。",
      "en": "Thank you for your hard work",
      "remark_en": "",
      "mm": "ပင်ပန်းသွားပြီနော်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO38.mp3")
  },
  {
      "furi": "＾伺（うかが）｜います。",
      "normal": "伺います。",
      "en": "I’m coming",
      "remark_en": "",
      "mm": "လာပါမယ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO39.mp3")
  },
  {
      "furi": "＾途（と）中（ちゅう）｜で",
      "normal": "途中で",
      "en": "on the way",
      "remark_en": "",
      "mm": "လမ်းခုလတ်၌",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO40.mp3")
  },
  {
      "furi": "トラック",
      "normal": "トラック",
      "en": "truck, lorry",
      "remark_en": "",
      "mm": "ကုန်တင်ကား",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO41.mp3")
  },
  {
      "furi": "ぶつかります",
      "normal": "ぶつかります",
      "en": "bump, collide",
      "remark_en": "",
      "mm": "တိုက်မိသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": require("@assets/audio/lesson39/vocab/4N39VO42.mp3")
  },
  {
      "furi": "＾大（おと）人（な）",
      "normal": "大人",
      "en": "adult",
      "remark_en": "",
      "mm": "အရွယ်ရောက်သူ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "しかし",
      "normal": "しかし",
      "en": "but",
      "remark_en": "",
      "mm": "ဒါပေမယ့်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "また",
      "normal": "また",
      "en": "more",
      "remark_en": "",
      "mm": "နောက်ထပ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾洋（よう）服（ふく）",
      "normal": "洋服",
      "en": "Western clothes",
      "remark_en": "",
      "mm": "အနောက်တိုင်းပုံစံအဝတ်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾西（せい）洋（よう）化（か）｜します",
      "normal": "西洋化します",
      "en": "be Westernized",
      "remark_en": "",
      "mm": "အနောက်တိုင်းပုံစံပြောင်းသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾会（あ）｜います",
      "normal": "会います",
      "en": "fit, suit",
      "remark_en": "",
      "mm": "ကိုက်ညီသည်",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾今（いま）｜では",
      "normal": "今では",
      "en": "now",
      "remark_en": "",
      "mm": "ယခုအခါမှာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾成（せい）人（じん）式（しき）",
      "normal": "成人式",
      "en": "coming-of-age celebration",
      "remark_en": "",
      "mm": "အရွယ်ရောက်ဂုဏ်ပြုပွဲ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  },
  {
      "furi": "＾伝（でん）統（とう）的（てき）｜な",
      "normal": "伝統的な",
      "en": "traditional",
      "remark_en": "",
      "mm": "မိရိုးဖလာ",
      "remark_mm": "",
      "group": 0,
      "audioUrl": null
  }
]

/*
    {
        furi: "",
        normal: "",
        en: "",
        remark_en: "",
        mm: "",
        remark_mm: "",
        group: 0,
        audioUrl: require("@assets/audio/lesson29/vocab/4N29VO1.mp3")
    },


*/
const test = [
  {
    'goi': '答（こた）」えます',
    'unicode': 'ဖြေဆိုသည်',
    'zawgyi': 'ေျဖဆိုသည္',
    'english': 'answer',
    'long': false
  },
  {
    'goi': '倒（たお）」れます',
    'unicode': 'ပြိုလဲကျသည်',
    'zawgyi': 'ၿပိဳလဲက်သည္',
    'english': 'fall down',
    'long': false
  },
  {
    'goi': '通（とお）」ります',
    'unicode': 'ဖြတ်သန်းသည်',
    'zawgyi': 'ျဖတ္သန္းသည္',
    'english': 'pass',
    'long': false
  },
  {
    'goi': '死（し）」にます',
    'unicode': 'သေသည်',
    'zawgyi': 'ေသသည္',
    'english': 'die',
    'long': false
  },
  {
    'goi': 'びっくりします',
    'unicode': 'အထိတ်တလန့်ဖြစ်သည်',
    'zawgyi': 'အထိတ္တလန႔္ျဖစ္သည္',
    'english': 'be surprised',
    'long': true
  },
  {
    'goi': 'がっかりします',
    'unicode': 'စိတ်ဓါတ်ကျသည်',
    'zawgyi': 'စိတ္ဓါတ္က်သည္',
    'english': 'be disappointed',
    'long': false
  },
  {
    'goi': '安（あん）」心（しん）」します',
    'unicode': 'စိတ်ချ/အေးသည်',
    'zawgyi': 'စိတ္ခ်/ေအးသည္',
    'english': 'be relieved',
    'long': false
  },
  {
    'goi': 'けんかします',
    'unicode': 'ရန်ဖြစ်သည်',
    'zawgyi': 'ရန္ျဖစ္သည္',
    'english': 'quarrel, fight',
    'long': false
  },
  {
    'goi': '離（り）」婚（こん）」します',
    'unicode': 'ကွာရှင်းပြတ်ဆဲသည်',
    'zawgyi': 'ကြာရွင္းျပတ္ဆဲသည္',
    'english': 'divorce',
    'long': false
  },
  {
    'goi': '太（ふと）」ります',
    'unicode': 'ဝသည်',
    'zawgyi': 'ဝသည္',
    'english': 'to get fat',
    'long': false
  },
  {
    'goi': 'やせます',
    'unicode': 'ပိန်သည်',
    'zawgyi': 'ပိန္သည္',
    'english': 'Lose weight',
    'long': false
  },
  {
    'goi': '複（ふく）」雑（ざつ）」[な]',
    'unicode': 'ရှုတ်ထွေးသော',
    'zawgyi': 'ရႈတ္ေထြးေသာ',
    'english': 'complicated, complex',
    'long': false
  },
  {
    'goi': '邪（じゃ）」魔（ま）」[な]',
    'unicode': 'အနှောင့်အယှက်ဖြစ်သော',
    'zawgyi': 'အေႏွာင့္အယွက္ျဖစ္ေသာ',
    'english': 'obstructive, in the way',
    'long': false
  },
  {
    'goi': '硬（かた）」い',
    'unicode': 'မာကြောသော',
    'zawgyi': 'မာေၾကာေသာ',
    'english': 'Hard',
    'long': false
  },
  {
    'goi': '軟（やわ）」らかい',
    'unicode': 'ပျော့ပြောင်းသော',
    'zawgyi': 'ေပ်ာ့ေျပာင္းေသာ',
    'english': 'soft',
    'long': false
  },
  {
    'goi': '汚（きたな）」い',
    'unicode': 'ညစ်ပတ်သော',
    'zawgyi': 'ညစ္ပတ္ေသာ',
    'english': 'dirty',
    'long': false
  },
  {
    'goi': 'うれしい',
    'unicode': 'ဝမ်းသာသော',
    'zawgyi': 'ဝမ္းသာေသာ',
    'english': 'glad, happy',
    'long': false
  },
  {
    'goi': '悲（かな）」しい',
    'unicode': 'ဝမ်းနည်းသော',
    'zawgyi': 'ဝမ္းနည္းေသာ',
    'english': 'sad',
    'long': false
  },
  {
    'goi': '恥（は）」ずかしい',
    'unicode': 'ရှက်သော',
    'zawgyi': 'ရွက္ေသာ',
    'english': 'embarrassed, a shamed',
    'long': false
  },
  {
    'goi': '首（しゅ）」相（しょう）',
    'unicode': 'ဝန်ကြီးချုပ်',
    'zawgyi': 'ဝန္ႀကီးခ်ဳပ္',
    'english': 'Prime Minister',
    'long': false
  },
  {
    'goi': '地（じ）」震（しん）',
    'unicode': 'မြေငလျင်',
    'zawgyi': 'ေျမငလ်င္',
    'english': 'earthquake',
    'long': false
  },
  {
    'goi': '津（つ）」波（なみ）',
    'unicode': 'ဆူနာမီ',
    'zawgyi': 'ဆူနာမီ',
    'english': 'tidal wave',
    'long': false
  },
  {
    'goi': '台（たい）」風（ふう）',
    'unicode': 'မုန်တိုင်း',
    'zawgyi': 'မုန္တိုင္း',
    'english': 'typhoon',
    'long': false
  },
  {
    'goi': '雷（かみなり）',
    'unicode': 'မိုးကြိုး',
    'zawgyi': 'မိုးႀကိဳး',
    'english': 'Thunder',
    'long': false
  },
  {
    'goi': '火（か）」事（じ）',
    'unicode': 'မီးလောင်မှု',
    'zawgyi': 'မီးေလာင္မႈ',
    'english': 'fire',
    'long': false
  },
  {
    'goi': '事（じ）」故（こ）',
    'unicode': 'မတော်တဆမှု့',
    'zawgyi': 'မေတာ္တဆမႈ႔',
    'english': 'accident',
    'long': false
  },
  {
    'goi': 'ハイキング',
    'unicode': 'အပျော်ခရီးထွက်ခြင်း',
    'zawgyi': 'အေပ်ာက္ခရီးထြက္ျခင္း',
    'english': 'hiking',
    'long': false
  },
  {
    'goi': '[お]見（み）」合（あ）」い',
    'unicode': 'အိမ်ထောင်ဖက်နှင့်တွေ့ဆုံ-',
    'zawgyi': 'အိမ္ေထာင္ဖက္ႏွင့္ေတြ႕ဆုံ-',
    'english': 'interview with a view to marriage',
    'long': false
  },
  {
    'goi': '操（そう）」作（さ）',
    'unicode': 'လုပ်ဆောင်နည်း',
    'zawgyi': 'လုပ္ေဆာင္နည္း',
    'english': 'operation',
    'long': false
  },
  {
    'goi': '会（かい）」場（じょう）',
    'unicode': 'ခန်းမ',
    'zawgyi': 'ခန္းမ',
    'english': 'meeting place',
    'long': false
  },
  {
    'goi': '～」代（だい）',
    'unicode': '～ခ၊～ဖိုး',
    'zawgyi': '～ခ၊～ဖိုး',
    'english': 'charge, fare, fee',
    'long': false
  },
  {
    'goi': '～」屋（や）',
    'unicode': '～ဆိုင်',
    'zawgyi': '～ဆိုင္',
    'english': '~shop',
    'long': false
  },
  {
    'goi': 'フロント',
    'unicode': 'ဧည့်ကြိုကောင်တာ',
    'zawgyi': 'ဧည့္ႀကိဳေကာင္တာ',
    'english': 'front desk, reception desk',
    'long': false
  },
  {
    'goi': 'ー」号（ごう）」室（しつ）',
    'unicode': 'အခန်းနံပါတ်',
    'zawgyi': 'အခန္းနံပါတ္',
    'english': 'room number ~',
    'long': false
  },
  {
    'goi': 'タオル',
    'unicode': 'မျက်နှာသုတ်ပဝါ',
    'zawgyi': 'မ်က္ႏွာသုတ္ပဝါ',
    'english': 'towel',
    'long': false
  },
  {
    'goi': 'せっけん',
    'unicode': 'ဆပ်ပြာ',
    'zawgyi': 'ဆပ္ျပာ',
    'english': 'soap',
    'long': false
  },
  {
    'goi': '大（おお）」勢（ぜい）',
    'unicode': 'လူအများ',
    'zawgyi': 'လူအမ်ား',
    'english': 'a great number of people',
    'long': false
  },
  {
    'goi': 'お」疲（つか）」れさまでした。',
    'unicode': 'ပင်ပန်းသွားပြီနော်',
    'zawgyi': 'ပင္ပန္းသြားၿပီေနာ္',
    'english': 'Thank you for your hard work',
    'long': true
  },
  {
    'goi': '伺（うかが）」います。',
    'unicode': 'လာပါမယ်',
    'zawgyi': 'လာပါမယ္',
    'english': 'I’m coming',
    'long': false
  },
  {
    'goi': '途（と）」中（ちゅう）」で',
    'unicode': 'လမ်းခုလတ်၌',
    'zawgyi': 'လမ္းခုလတ္၌',
    'english': 'on the way',
    'long': false
  },
  {
    'goi': 'トラック',
    'unicode': 'ကုန်တင်ကား',
    'zawgyi': 'ကုန္တင္ကား',
    'english': 'truck, lorry',
    'long': false
  },
  {
    'goi': 'ぶつかります',
    'unicode': 'တိုက်မိသည်',
    'zawgyi': 'တိုက္မိသည္',
    'english': 'bump, collide',
    'long': false
  },
  {
    'goi': '大（おと）」人（な）',
    'unicode': 'အရွယ်ရောက်သူ',
    'zawgyi': 'အရြယ္ေရာက္သူ',
    'english': 'adult',
    'long': false
  },
  {
    'goi': 'しかし',
    'unicode': 'ဒါပေမယ့်',
    'zawgyi': 'ဒါေပမယ့္',
    'english': 'but',
    'long': false
  },
  {
    'goi': 'また',
    'unicode': 'နောက်ထပ်',
    'zawgyi': 'ေနာက္ထပ္',
    'english': 'more',
    'long': false
  },
  {
    'goi': '洋（よう）」服（ふく）',
    'unicode': 'အနောက်တိုင်းပုံစံအဝတ်',
    'zawgyi': 'အေနာက္တိုင္းပုံစံအဝတ္',
    'english': 'Western clothes',
    'long': false
  },
  {
    'goi': '西（せい）」洋（よう）」化（か）」します',
    'unicode': 'အနောက်တိုင်းပုံစံပြောင်းသည်',
    'zawgyi': 'အေနာက္တိုင္းပုံစံေျပာင္းသည္',
    'english': 'be Westernized',
    'long': true
  },
  {
    'goi': '会（あ）」います',
    'unicode': 'ကိုက်ညီသည်',
    'zawgyi': 'ကိုက္ညီသည္',
    'english': 'fit, suit',
    'long': false
  },
  {
    'goi': '今（いま）」では',
    'unicode': 'ယခုအခါမှာ',
    'zawgyi': 'ယခုအခါမွာ',
    'english': 'now',
    'long': false
  },
  {
    'goi': '成（せい）」人（じん）」式（しき）',
    'unicode': 'အရွယ်ရောက်ဂုဏ်ပြုပွဲ',
    'zawgyi': 'အရြယ္ေရာက္ဂုဏ္ျပဳပြဲ',
    'english': 'coming-of-age celebration',
    'long': false
  },
  {
    'goi': '伝（でん）」統（とう）」的（てき）」な',
    'unicode': 'မိရိုးဖလာ',
    'zawgyi': 'မိရိုးဖလာ',
    'english': 'traditional',
    'long': false
  }
]