import Icon from "@consts/icon";

export const lesson44 = {
    title: {
        furi: 'この　｜＾写（しゃ）真（しん）｜みたいに　して　ください',
        normal: 'この　写真みたいに　して　ください',
        en: 'Please make it like this photo',
        mm: 'ဒီဓါတ်ပုံအတိုင်း ဆောင်ရွက်ပေးပါ',
    },
    conversation: [
        {
            person: {
                furi: '＾美（び）容（よう）師（し）',
                normal: '美容師',
                avatar: Icon.Kaiwa.BOY
            },
            sentence: [
                {
                    furi: 'いらっしゃいませ。きょうは　どう　なさいますか。',
                    normal: 'いらっしゃいませ。きょうは　どう　なさいますか。',
                    en: 'welcome. How would you do today?',
                    mm: 'ကြွပါခင်ဗျ ဒီနေ့ ဘယ်လိုလုပ်ချင်ပါသလည်း',
                },
            ],
        },
        {
            person: {
                furi: 'イー',
                normal: 'イー',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: 'カット、お｜＾願（ねが）｜いします。',
                    normal: 'カット、お願いします。',
                    en: 'Cut, please.',
                    mm: 'ဆံပင်ညှပ်ပေးပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾美（び）容（よう）師（し）',
                normal: '美容師',
                avatar: Icon.Kaiwa.BOY
            },
            sentence: [
                {
                    furi: 'じゃ、シャンプーを　しますから、こちらへどうぞ。',
                    normal: 'じゃ、シャンプーを　しますから、こちらへどうぞ。',
                    en: "Well then, I'll shampoo you, so please come this way.",
                    mm: 'ဒါဆို ခေါင်းလျှော်မှာမို့ ကျေးဇူးပြုပြီး ဒီကိုလာခဲ့ပါ',
                },
                {
                    furi: '。。。。。。。。。。。。',
                    normal: '。。。。。。。。。。。。',
                    en: '',
                    mm: '',
                },
                {
                    furi: 'カットは　どういうふうに　なさいますか。',
                    normal: 'カットは　どういうふうに　なさいますか。',
                    en: 'How would you like the cut?',
                    mm: 'ဆံပင်ညှပ်တာက ဘယ်လိုပုံစံမျိုးညှပ်ချင်ပါသလဲ',
                },
            ],
        },
        {
            person: {
                furi: 'イー',
                normal: 'イー',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: 'ショートに　したいんですけど。。。',
                    normal: 'ショートに　したいんですけど。。。',
                    en: "I'd like to go short. . .",
                    mm: 'တိုတိုလေးညှပ်ချင်ပါတယ်',
                },
                {
                    furi: 'この　｜＾写（しゃ）真（しん）｜またいに　して　ください。',
                    normal: 'この　写真またいに　して　ください。',
                    en: 'Please make it like this picture.',
                    mm: 'ဒီဓါတ်ပုံထဲကအတိုင်း ဆောင်ရွက်ပေးပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾美（び）容（よう）師（し）',
                normal: '美容師',
                avatar: Icon.Kaiwa.BOY
            },
            sentence: [
                {
                    furi: 'あ、すてきですね。',
                    normal: 'あ、すてきですね。',
                    en: "Oh, that's nice.",
                    mm: 'အား ကောင်းလိုက်တာ',
                },
                {
                    furi: '。。。。。。。。。。。。',
                    normal: '。。。。。。。。。。。。',
                    en: '',
                    mm: '',
                },
                {
                    furi: '＾前（まえ）｜の｜＾長（なが）｜さは　これで　よろしいでしょうか。',
                    normal: '前の長さは　これで　よろしいでしょうか。',
                    en: 'Is this the length of the front?',
                    mm: 'အရှေ့ကဆံပင်က ဒီလောက်ဆိုအဆင်ပြေလား',
                },
            ],
        },
        {
            person: {
                furi: 'イー',
                normal: 'イー',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: 'そうですね。もう　｜＾少（すこ）｜し　｜＾短（みじか）｜くして　ください。',
                    normal: 'そうですね。もう　少し　短くして　ください。',
                    en: 'Yes. Please make it a little shorter.',
                    mm: 'အင်း...နဲနဲတိုပေးပါအုန်း',
                },
            ],
        },
        {
            person: {
                furi: '＾美（び）容（よう）師（し）',
                normal: '美容師',
                avatar: Icon.Kaiwa.BOY
            },
            sentence: [
                {
                    furi: 'どうも　お｜＾疲（つか）｜れさまでした。',
                    normal: 'どうも　お疲れさまでした。',
                    en: 'Thank you so much.',
                    mm: 'ကျေးဇူးအများကြီးတင်ပါတယ်',
                },
            ],
        },
        {
            person: {
                furi: 'イー',
                normal: 'イー',
                avatar: Icon.Kaiwa.GIRL
            },
            sentence: [
                {
                    furi: 'ありがとう。',
                    normal: 'ありがとう。',
                    en: 'Thank you',
                    mm: 'ကျေးဇူးပါ',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson44/kaiwa/4N44KW.mp3")
}
/*
{
    title: {
        furi: '',
        normal: '',
        en: '',
        mm: '',
    },
    conversation: [
        {
            person: {
                furi: '',
                normal: '',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '',
                    normal: '',
                    en: '',
                    mm: '',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson36/kaiwa/4N36KW.mp3")
}
*/