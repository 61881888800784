import Conversation from "@assets/icon/category/Group.svg";
import Bullet from "@assets/icon/category/bullet.svg";
import Exercise from "@assets/icon/category/exercise.svg";
import Female from "@assets/icon/category/female.svg";
import Grammar from "@assets/icon/category/grammer 1.svg";
import Kanji from "@assets/icon/category/kanji.svg";
import Male from "@assets/icon/category/male.svg";
import Male2 from "@assets/icon/category/male2.svg";
import Vocab from "@assets/icon/category/vocabulary.svg";
import Facebook from "@assets/icon/desktopFooter/facebook.svg";
import Messenger from "@assets/icon/desktopFooter/messenger.svg";
import Phone from "@assets/icon/desktopFooter/phone.svg";
import Telegram from "@assets/icon/desktopFooter/telegram.svg";
import Arrow from "@assets/icon/kanji/arrow.svg";
import Quizz1 from "@assets/icon/kanji/quiz-avator 1.svg";
import Quizz2 from "@assets/icon/kanji/quiz-avator 2.svg";
import QuizzM2 from "@assets/icon/kanji/quiz-avator-hiragana-to-kanji-mobile.svg";
import QuizzM1 from "@assets/icon/kanji/quiz-avator-kanji-to-hiragana-mobile.svg";
import FullStar from "@assets/icon/lesson_card/full-star.svg";
import HalfStar from "@assets/icon/lesson_card/half-star.svg";
import Lock from "@assets/icon/lesson_card/lock.svg";
import NoneStar from "@assets/icon/lesson_card/none-star.svg";
import Logo from "@assets/icon/logo.svg";
import Premium from "@assets/icon/premium.svg";
import SubHome from "@assets/icon/subNav/home-icon 1.svg";
import Close from "@assets/icon/vocab/close.svg";
import Edit from "@assets/icon/vocab/edit.svg";
import EyeOff from "@assets/icon/vocab/eye-off.svg";
import Eye from "@assets/icon/vocab/eye.svg";
import Pencil from "@assets/icon/vocab/pencil.svg";
import Show from "@assets/icon/vocab/show.svg";
import Shuffel from "@assets/icon/vocab/shuffle.svg";
import Shuffel1 from "@assets/icon/vocab/shuffle1.svg";
import Speaker from "@assets/icon/vocab/speaker.svg";
import Amiespeaker from "@components/common/icon/Speaker.svg";
import Next from "@components/common/icon/next.svg";
import Play from "@components/common/icon/play.svg";
import Prev from "@components/common/icon/prev.svg";
import { Grammar as Grammars } from "./grammar";
import { Kaiwa } from "./kaiwa";

const Icon = {
    //
    Logo,
    // desktop nav bar
    Premium,
      // desktop footer
    Facebook,Messenger,Telegram,Phone,
    // lesson card
    Lock, FullStar, HalfStar, NoneStar,
      // sub nav
    SubHome,
      // category
    Vocab, Grammar, Kanji, Conversation, Bullet, Male,Male2, Female, Exercise,
      // vocab
    Speaker, Edit, Show, Shuffel, Shuffel1, Pencil, Eye, Close, EyeOff,
      // kanji
  Quizz1, Quizz2, QuizzM1, QuizzM2, Arrow,
    // amidio audio 
  Amiespeaker,Prev,Play,Next,
  // KAIWA
  Kaiwa,
  // GRAMMAR
  Grammars
}

export default Icon;