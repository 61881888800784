import Number from "@components/common/Number";
import { BunopouMondaiType, BunopouQuestionType } from "@consts/test";
import { Card } from "@material-tailwind/react";
import { Dispatch, SetStateAction, memo } from "react";
import { GiCheckMark } from "react-icons/gi";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";

interface Mondai2Type{
    finish: Boolean;
    fontSize: number;
    mondai: BunopouMondaiType;
    setMondai: Dispatch<SetStateAction<BunopouMondaiType>>;
    furigana?: String | undefined;
}

const BunpouM1Component = ({  mondai: {title, questions},setMondai, fontSize, furigana,finish }: Mondai2Type)=>{
    
    const onSelect = (ans:String,index: number)=>{
        questions[index].pick = ans;
        setMondai({title,questions});
    }
    const StarRender = ({sentance}:{sentance: String})=>{
        if(!sentance.includes("！")){
            return <div className="">{sentance}</div>
        }else{
            let sprates = sentance.split("｜");
            return <div className="flex flex-row flex-wrap ">
                {
                    sprates.map((sprate)=>{
                        if(sprate.includes("！")){
                            let onlyStar = sprate.substring(1);
                            let onlyStarArray = onlyStar.split('');
                            // return onlyStarArray;
                            
                            return onlyStarArray.map((amie:String)=>{
                                // return amie;
                                if(amie==="＿"){
                                    return <div className="underline mx-[3px]">
                                        　
                                    </div>
                                }else{
                                    return <div className="font-bold mx-[3px] underline">{amie}</div>
                                }
                            })
                        }else{
                            let strSector = sprate.split("");
                            return strSector.map((sector)=>{
                                return <div className="">{sector}</div>;
                            });
                        }
                    })
                }
            </div>
        }
        
    }
    return <div>
        <div className="font-semibold">{StarRender({ sentance: title })}</div>
        {
            questions.map(({ number, question, answers, pick, correct }: BunopouQuestionType,i:number) => {
                return <div key={i} className="mt-[20px] mb-[50px]">
                    {/* question */}
                    <div className="flex flex-row mb-[15px]">
                        <Number furigana={furigana || "true"} fontSize={fontSize}>
                            {number}
                            <span>. </span>
                        </Number>
                        <div className="">
                            {
                                question.map((ques:String) => {
                                    return <div>{StarRender({sentance:ques})}</div>;
                                })
                            }
                        </div>
                    </div>
                    { finish?
                        // Show REsult
                        <div className="mt-[10px] grid grid-cols-2 gap-[18px] md:gap-[30px] md:grid-cols-2">
                            {answers.map((ans)=>{
                                return ans===pick && pick===correct?
                                // ans correct
                                    <Card key={Math.random()} className="shadow-md dark:shadow-[#344a5a]  relative flex flex-row items-center bg-[#00E392]  font-semibold  dark:text-green-900 p-2 rounded-[10px] btn">
                                    <MdRadioButtonChecked className="mr-[5px]"/>
                                    {ans}
                                    <GiCheckMark size={25} className="absolute text-green-700 dark:text-green-900 dark:right-[4px]  -right-[10px]" />
                                </Card>
                                // ans wrong
                                    : ans === pick && pick !== correct ? <Card key={Math.random()} className="shadow-md dark:shadow-[#344a5a] relative flex flex-row items-center bg-[#FF516E] p-2 rounded-[10px] btn">
                                    <MdRadioButtonChecked className="mr-[5px]"/>
                                        {ans}
                                </Card>
                                // user no select
                                        : <Card key={Math.random()} className={`shadow-md dark:shadow-[#344a5a] ${ans == correct ? ' bg-[#00E392]  font-semibold  dark:text-green-900 ' : 'bg-white dark:bg-[#001E34]'} flex flex-row items-center  p-2 rounded-[10px] btn`}>
                                <MdRadioButtonUnchecked className="mr-[5px]"/>
                                {ans}
                            </Card>
                            })}
                        </div>
                        // Multiple Choice
                        : <div className="mt-[10px] grid grid-cols-2 gap-[18px] md:gap-[24px]  md:grid-cols-2">
                            {answers.map((ans)=>{
                                return ans===pick?
                                    <Card key={Math.random()} className="shadow-md dark:shadow-[#344a5a] flex flex-row items-center bg-[#00E392]  font-semibold  dark:text-green-900 p-2 rounded-[10px] btn">
                                    <MdRadioButtonChecked className="mr-[5px]"/>
                                    {ans}
                                </Card>
                                    : <Card key={Math.random()} onClick={() => onSelect(ans, i)} className="shadow-md dark:shadow-[#344a5a] flex flex-row items-center bg-white dark:bg-[#001E34] p-2 rounded-[10px] btn">
                                    <MdRadioButtonUnchecked className="mr-[5px]"/>
                                    {ans}
                                </Card>
                            })}
                        </div>
                    }
                </div>
            })
        }
    </div>
}
export default memo(BunpouM1Component);