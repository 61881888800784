import { information } from "./information";
import { search } from "./search";
import { games } from "./game";
 
const getLesson = (lessonNum:string) => {
    return kanji.information.filter(({lesson})=>lesson == lessonNum);
}

export const kanji = {
    search,
    information,
    games,
    getLesson
};
