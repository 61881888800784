export const lesson8 = {
    "季": 70,
    "節": 71,
    "春": 72,
    "夏": 73,
    "秋": 74,
    "冬": 75,
    "暑": 76,
    "寒": 77,
    "暖": 78,
    "涼": 79
};