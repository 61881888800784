import Logger from '@helper/logger';
interface M2 {
    question: string;
    picked: string;
    answers: Array<string>;
    correct: string
}
interface M1 {
    question: string;
    picked: Array<string>;
    answers: Array<string>;
    correct: Array<string>;
}
const log = Logger(15);
export const testMondai2 = (tests: Array<M2>)=>{
    // console.log(tests);
    tests.map(({question,answers,correct},index: number)=>{
        if(!question.includes("！")){
            log.e("! missing on",index);
            log.e(question);
        }
        if(answers.length!=4){
            log.e("answers Length",index);
            log.e(question);
        }
        // check duplicate answers
        answers.map((ans)=>{
            if( answers.filter((a)=>a===ans).length!=1 ){
                log.e("Duplicate Answer",index);
                log.e(question);
            }
        })
        if(!answers.includes(correct)){
            log.e("missing correct on Answers Array",index);
            log.e(question);
        }
        log.sys(index," done");
        console.log(index," done");
    });
}
export const testMondai1 = (tests: Array<M1>)=>{
    tests.map(({question,picked,answers,correct},index: number)=>{
        let qArray = question.split("＄");
        // 1 no of $
        if( !(qArray.length-1 === picked.length && picked.length === correct.length) ){
            log.e("Inconsistance of $ and picked,correct etc",1);
            log.e(question);
        }
        // 2 properly $0,$1,$2 etc
        const [init,zero,one,two] = qArray;
        if(zero && zero[0]!=="０"){
            log.e("position error of $0",2);
            log.e(question);
        }
        if(one && one[0]!=="１"){
            log.e("position error of $1",2);
            log.e(question);
        }
        if(two && two[0]!=="２"){
            log.e("position error of $2",2);
            log.e(question);
        }
        // 3 Duplicate
        answers.map((ans)=>{
            if( answers.filter((a)=>a===ans).length!=1 ){
                log.e("Duplicate Answer",3);
                log.e(question);
            }
        })
        // 4 Correct Check
        correct.map((c)=>{
            if(!answers.includes(c)){
                log.e("missing correct on Answers Array",4);
                log.e(question);
            }
        })
        log.sys(index," done");
        console.log(index," done");
    })
}