import { useRive } from '@rive-app/react-canvas';
import { memo } from "react";

const KanjiStroke = ({ src, style }: any) => {
    const { RiveComponent} = useRive({
        src,
        autoplay: true
    })
    return <RiveComponent width="100%" className={`${style? style : "w-48 h-48"}`} />
}
export default memo(KanjiStroke);