import { Card } from "@material-tailwind/react";
import { memo } from "react"
import Heart from "@assets/icon/game/health 2.svg";
import Right from "@assets/icon/game/right.svg";
import Wrong from "@assets/icon/game/wrong.svg";
import CountUp from 'react-countup';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const GameDashboardWebComponent = ({life,lifeState,oldScore,score,percent,timeLeft,game,count,correct,wrong}:any) => {
    return <Card className="hidden md:flex flex-row p-5 justify-between items-start w-[60%] rounded-[20px] dark:bg-darkFooter card-light-shadow ">
        <div className="flex-[2]">
            {/* Life Display */}
            <div className="flex flex-row items-start h-full">
                <img src={Heart} width={40} height={40} className="-mr-[25px]  -mt-[10px] z-menu " />
                <div className="z-0 relative flex flex-row justify-center items-center pl-[20px] w-[63px] h-[28px] rounded-full bg-gradient-to-r from-[#FFDBDE] via-[#FF5565] via-45% to-[#FF5565] ">
                    <div className="ml-2 text-white">{life}</div>
                    {lifeState && <div className={`${lifeState === "-1" ? "text-red-500" : "text-green-500"} absolute -right-[15px] -top-[5px] font-bold text-sm`}>{lifeState}</div>}
                </div>
            </div>
            {/* Score */}
            <div className="mt-[25px] text-[18px] flex flex-row">
                {<CountUp
                    start={oldScore}
                    end={score}
                    duration={2}
                />}
                <div className="ml-2">Scores</div>
            </div>
        </div>
        <div className="flex-[4] flex flex-col items-center justify-center h-full">
            <div className="w-[80px] pb-[20px]">
                <CircularProgressbar
                    value={percent}
                    text={timeLeft}
                    strokeWidth={9}
                    styles={buildStyles({
                        // Rotation of path and trail, in number of turns (0-1)
                        rotation: 0,

                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',

                        // Text size
                        textSize: '25px',

                        // How long animation takes to go from one percentage to another, in seconds
                        pathTransitionDuration: 0.5,

                        // Can specify path transition in more detail, or remove it entirely
                        // pathTransition: 'none',

                        // Colors //Green: #00FA9A Yellow:	#FFFF00 Red: #DC143C
                        pathColor: percent < 40 ? "#00FA9A" : percent < 70 ? "#FFFF00" : "#DC143C",
                        textColor: percent < 40 ? "#00FA9A" : percent < 70 ? "#FFFF00" : "#DC143C",
                        trailColor: '#d6d6d6',
                        backgroundColor: '#3e98c7',
                    })}
                />
            </div>
            {game.length > 0 && <div className=" text-navbar text-[20px] font-semibold">
                {game[count].question}
            </div>}

        </div>
        <div className="flex-[2] flex flex-col items-end">
            <div className="flex flex-row items-center">
                <div className="text-green-500 text-[25px] font-semibold mr-2"> {correct}</div>
                <div><img src={Right} width={30} height={30} /></div>
            </div>
            <div className="flex flex-row mt-[10px] items-center">
                <div className="text-red-500 text-[25px] font-semibold mr-2"> {wrong}</div>
                <div><img src={Wrong} width={30} height={30} /></div>
            </div>
        </div>
    </Card >
}
export default memo(GameDashboardWebComponent);