export const lesson18 = {
    "漢": 170,
    "字": 171,
    "文": 172,
    "教": 173,
    "勉": 174,
    "習": 175,
    "英": 176,
    "考": 177,
    "研": 178,
    "究": 179
};