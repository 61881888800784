import Icon from "@consts/icon";
import { memo, useEffect, useRef, useState } from "react"; 
const DesktopViewFooter = ({ fontSize }: { fontSize: number }) => {
    const footer = useRef<HTMLDivElement>(null);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [footerY, setFooterY] = useState<number | undefined>(0);
    const contactItems = [
        {
            href: 'https://www.facebook.com/integratedSolution.mm',
            Icon: Icon.Facebook, 
            // Icon: Icon.facebook, 
            size: 28,
            style:'mr-4 btn '
        },
        {
            href: 'https://m.me/integratedSolution.mm',
            Icon: Icon.Messenger, 
            size: 28,
            style:'mr-4 btn'
        },
        {
            href: 'https://t.me/IntegratedSolution',
            Icon: Icon.Telegram, 
            size: 28,
            style:'mr-4 btn'
        },
        {
            href: 'tel:+959421208939',
            Icon: Icon.Phone, 
            size: 16,
            style:'btn'
            // style:'text-[#00C838] dark:text-gray-600 rounded-full bg-white dark:bg-gray-200 p-1.5 btn'
        },
    ]
    const isSatisfiedLayout = (): Boolean => {    
        if (footerY) {
            return footerY >screenHeight - 60;
        } else {
            return true;
        }
    }
    const resizeHandler = () => {
        setScreenHeight(window.innerHeight);
        setFooterY(footer.current?.offsetTop);
    }
    useEffect(() => {
        setFooterY(footer.current?.offsetTop);
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        }
    })
    return (
        <div ref={footer} className={`${!isSatisfiedLayout()? 'fixed bottom-0 ':''} hidden md:flex h-[60px] w-full bg-white dark:text-white dark:bg-darkFooter shadow-[inset_0_1px_1px_0_rgba(255,255,255,0.25)] dark:shadow-[inset_0_1px_1px_0_rgba(255,255,255,0.25)]`}>
            <div className="container mx-auto flex flex-row w-full justify-between items-center">
                <div className="flex flex-row items-center">
                    <div className="mr-2 text-[18px] ">Developed by</div>
                    <div className="text-[18px] font-bold">Integrated Solution</div>
                </div>
                <div className={`text-[18px]  text-center text-grayDark dark:text-[#BCBCBC]`}>
                    ©2023 • All Rights Reserved
                </div>
                <div className={` flex flex-row justify-end items-center `}>
                    {
                        contactItems.map(({ href, Icon, style, size }, i):any => {
                            return (
                                <a key={i} href={href} target="_blank" className={`${style} `}>
                                    <img src={Icon} className="w-[30px] h-[30px]"/>
                                </a>
                            )
                            
                        })
                    }
            </div>
           </div>
        </div>
    )
}
export default memo(DesktopViewFooter);