import { memo } from "react";
const MondaiScreen = ({ selector, name, Logger, dispatch, setName, Routes, useNavigate,useLocation}: any) => {
    const log = Logger(9);
    const location = useLocation();
    log.s(" Mondai Screen Console");
    return (<>
        <div className="flex flex-col items-center justify-center h-full ">
            Mondai Screen
            <div>Name: {name}</div>
            id:{location.state.id}
            Lesson: {location.state.lesson}
        </div>
    </>)
}
export default memo(MondaiScreen);