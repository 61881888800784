
export const lesson17 = [
    {"lesson":17,"level":4,"question":"きこくする","represent":["帰","国"],"answer":["帰国する","出発する","乗車する","発表する"],"true":"帰国する","times":10},
    {"lesson":17,"level":4,"question":"つうがくする","represent":["通","学"],"answer":["通学する","乗車する","発表する","出発する"],"true":"通学する","times":10},
    {"lesson":17,"level":4,"question":"とうちゃくする","represent":["着"],"answer":["到着する","乗車する","通学する","発表する"],"true":"到着する","times":10},
    {"lesson":17,"level":4,"question":"のる","represent":["乗"],"answer":["乗る","着る","帰る","降る"],"true":"乗る","times":10},
    {"lesson":17,"level":4,"question":"こうどうする","represent":["行","動"],"answer":["行動する","帰国する","到着する","乗車する"],"true":"行動する","times":10},
    {"lesson":17,"level":4,"question":"うんそう","represent":["運","送"],"answer":["運送","着物","回転","交通"],"true":"運送","times":10},
    {"lesson":17,"level":4,"question":"しゅっぱつする","represent":["出","発"],"answer":["出発する","発表する","到着する","行動する"],"true":"出発する","times":10},
    {"lesson":17,"level":4,"question":"じょうきゃく","represent":["乗"],"answer":["乗客","運転","動物","運送"],"true":"乗客","times":10},
    {"lesson":17,"level":4,"question":"きる","represent":["着"],"answer":["着る","乗る","降る","帰る"],"true":"着る","times":10},
    {"lesson":17,"level":4,"question":"こうつう","represent":["交","通"],"answer":["交通","運転","着物","外交"],"true":"交通","times":10},
    {"lesson":17,"level":4,"question":"うんてん","represent":["運","転"],"answer":["運転","着物","外交","回転"],"true":"運転","times":10},
    {"lesson":17,"level":4,"question":"とおる","represent":["通"],"answer":["通る","着る","帰る","降る"],"true":"通る","times":10},
    {"lesson":17,"level":4,"question":"どうぶつ","represent":["動","物"],"answer":["動物","乗客","運転","着物"],"true":"動物","times":10},
    {"lesson":17,"level":4,"question":"はつばい","represent":["発","売"],"answer":["発売","交通","運動","運転"],"true":"発売","times":10},
    {"lesson":17,"level":4,"question":"きもの","represent":["着","物"],"answer":["着物","回転","運転","外交"],"true":"着物","times":10},
    {"lesson":17,"level":4,"question":"じょうしゃする","represent":["乗","車"],"answer":["乗車する","到着する","発表する","通学する"],"true":"乗車する","times":10},
    {"lesson":17,"level":4,"question":"うんどう","represent":["運","動"],"answer":["運動","回転","運送","運転"],"true":"運動","times":10},
    {"lesson":17,"level":4,"question":"はっこう","represent":["発","行"],"answer":["発行","発売","回転","着物"],"true":"発行","times":10},
    {"lesson":17,"level":4,"question":"はっぴょうする","represent":["発"],"answer":["発表する","到着する","通学する","出発する"],"true":"発表する","times":10},
    {"lesson":17,"level":4,"question":"かいてん","represent":["回","転"],"answer":["回転","着物","発行","乗客"],"true":"回転","times":10},
    {"lesson":17,"level":4,"question":"がいこう","represent":["外","交"],"answer":["外交","発行","回転","運送"],"true":"外交","times":10},
    {"lesson":17,"level":4,"question":"かえる","represent":["帰"],"answer":["帰る","乗る","着る","通る"],"true":"帰る","times":10},
    {"lesson":17,"level":4,"question":"ふる","represent":["降"],"answer":["降る","着る","通る","帰る"],"true":"降る","times":10},
]