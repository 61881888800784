
export const lesson15 = [
    {"lesson":15,"level":4,"question":"ちゅういする","represent":["注","意"],"answer":["注意する","信用する","返済する","注目する"],"true":"注意する","times":10},
    {"lesson":15,"level":4,"question":"しよう","represent":["使","用"],"answer":["使用","化学","送料","作文"],"true":"使用","times":10},
    {"lesson":15,"level":4,"question":"きんだいか","represent":["近","代","化"],"answer":["近代化","洗面所","先進国","送別会"],"true":"近代化","times":10},
    {"lesson":15,"level":4,"question":"しんごう","represent":["信","号"],"answer":["信号","化学","作品","返事"],"true":"信号","times":10},
    {"lesson":15,"level":4,"question":"せんざい","represent":["洗"],"answer":["洗剤","作品","通信","文化"],"true":"洗剤","times":10},
    {"lesson":15,"level":4,"question":"さくひん","represent":["作","品"],"answer":["作品","使用","作文","返事"],"true":"作品","times":10},
    {"lesson":15,"level":4,"question":"つうしん","represent":["通","信"],"answer":["通信","信号","送料","作品"],"true":"通信","times":10},
    {"lesson":15,"level":4,"question":"ちゅうもくする","represent":["注","目"],"answer":["注目する","注文する","返信する","返済する"],"true":"注目する","times":10},
    {"lesson":15,"level":4,"question":"へんじ","represent":["返","事"],"answer":["返事","動作","送料","作品"],"true":"返事","times":10},
    {"lesson":15,"level":4,"question":"かがく","represent":["化","学"],"answer":["化学","送料","返事","作文"],"true":"化学","times":10},
    {"lesson":15,"level":4,"question":"そうべつかい","represent":["送","別","会"],"answer":["送別会","先進国","近代化","洗面所"],"true":"送別会","times":10},
    {"lesson":15,"level":4,"question":"へんしんする","represent":["返","信"],"answer":["返信する","注目する","注意する","返済する"],"true":"返信する","times":10},
    {"lesson":15,"level":4,"question":"へんさいする","represent":["返"],"answer":["返済する","返信する","注意する","信用する"],"true":"返済する","times":10},
    {"lesson":15,"level":4,"question":"しんぽする","represent":["進","歩"],"answer":["進歩する","注文する","返済する","信用する"],"true":"進歩する","times":10},
    {"lesson":15,"level":4,"question":"せんめんしょ","represent":["洗","所"],"answer":["洗面所","先進国","送別会","近代化"],"true":"洗面所","times":10},
    {"lesson":15,"level":4,"question":"さくぶん","represent":["作","文"],"answer":["作文","大使","動作","洗剤"],"true":"作文","times":10},
    {"lesson":15,"level":4,"question":"しんようする","represent":["信","用"],"answer":["信用する","注文する","返済する","注目する"],"true":"信用する","times":10},
    {"lesson":15,"level":4,"question":"ぶんか","represent":["文","化"],"answer":["文化","作品","通信","作文"],"true":"文化","times":10},
    {"lesson":15,"level":4,"question":"せんしんこく","represent":["先","進","国"],"answer":["先進国","送別会","洗面所","近代化"],"true":"先進国","times":10},
    {"lesson":15,"level":4,"question":"たいし","represent":["大","使"],"answer":["大使","動作","使用","作文"],"true":"大使","times":10},
    {"lesson":15,"level":4,"question":"どうさ","represent":["動","作"],"answer":["動作","通信","文化","化学"],"true":"動作","times":10},
    {"lesson":15,"level":4,"question":"ちゅうもんする","represent":["注","文"],"answer":["注文する","返信する","信用する","注目する"],"true":"注文する","times":10},
    {"lesson":15,"level":4,"question":"そうりょう","represent":["送","料"],"answer":["送料","通信","作品","洗剤"],"true":"送料","times":10},
]