import Icon from "@consts/icon";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
interface SubNavProps{
    nabs:any;
    lesson:number ;
    style?:string;
}
const SubNavComponent = ({ nabs, lesson,style }:SubNavProps) => {
    const navigate = useNavigate();

    const redirect = ({route,state}:any)=>{
        navigate(route, {
            state
        })
    }
    return (
        <div className={`flex flex-row justify-between items-center ${style}`}>
            <div className="flex flex-row items-center">
                {
                    nabs.map(({ title, route, state }: any, index: number) => {
                        return index == 0 ? (
                            <div key={index} onClick={() => redirect({ route })} className="hidden md:block btn mr-[10px] md:mr-[16px]">
                                <img src={Icon.SubHome} width={25} height={25}/>
                            </div>
                        ) :
                        (
                            <div key={index}
                                style={{ cursor: route ? "pointer " : "not-allowed", opacity: route ? "100%" : "60%" }}
                                onClick={route ? () => redirect({ route, state }) : () => { }}
                                className="select-none text-[12px] md:text-[14px] font-semibold mr-[10px] md:mr-[16px] text-navbar border-2 border-navbar rounded-full px-[13px] py-[1px]"
                            >
                            {title}
                            </div>
                        )
                    })
                }
            </div>
            <div className="text-[#424242] dark:text-[#D4D4D4] text-[16px] md:text-[18px] select-none font-semibold">LESSON<span className="ml-2">{lesson+26}</span></div>
        </div>
    );
}

export default memo(SubNavComponent);