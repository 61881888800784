import Icon from "@consts/icon";

export const lesson40 = {
    title: {
        furi: '＾友（とも）達（だち）｜が　できたが　どうか、　｜＾心（しん）配（ぱい）｜です',
        normal: '友達が　できたが　どうか、　心配です',
        en: "I'm worried about whether or not making friends",
        mm: 'သူငယ်ချင်း ရလား ဘယ်လိုလဲဆိုတာ စိတ်ပူတယ်',
    },
    conversation: [
        {
            person: {
                furi: 'クララ',
                normal: 'クララ',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: '＾先（せん）生（せい）｜、ハンスは　｜＾学（がっ）校（こう）｜で　どうでしょうか。',
                    normal: '先生、ハンスは　学校で　どうでしょうか。',
                    en: 'Teacher, how is Hans at school?',
                    mm: 'ဆရာမ၊ ဟန်စုက ကျောင်းမှာ ဘယ်လိုလည်း(အဆင်ပြေလား)?',
                },
                {
                    furi: '＾友（とも）達（だち）｜が　できたか　どうか、｜＾心（しん）配（ぱい）｜なんですか。。。',
                    normal: '友達が　できたか　どうか、　心配なんですか。。。',
                    en: "I'm worried about whether or not making friends",
                    mm: 'သူငယ်ချင်းတွေ ရှိရဲ့လား ဘယ်လိုလည်းဆိုတာ စိတ်ပူနေလို့ပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾伊（い）藤（とう）先（せん）生（せい）',
                normal: '伊藤先生',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: '＾大（だい）丈（じょう）夫（ぶ）｜ですよ。',
                    normal: '大丈夫ですよ。',
                    en: 'No problem.',
                    mm: 'အဆင်ပြေပါတယ်',
                },
                {
                    furi: 'ハンス｜＾君（くん）｜は　クラスで　とても　｜＾人（にん）気（き）｜が　あります。',
                    normal: 'ハンス君は　クラスで　とても　人気が　あります。',
                    en: 'Hans is very popular in this class.',
                    mm: 'ဟန်စုက အတန်းထဲမှာ နာမည်ကြီးပါ',
                },
            ],
        },
        {
            person: {
                furi: 'クララ',
                normal: 'クララ',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: 'そうですか。｜＾安（あん）心（しん）｜しました。',
                    normal: 'そうですか。安心しました。',
                    en: 'Is that so. relieved.',
                    mm: 'ဟုတ်လား စိတ်အေးသွားပါပြီ',
                },
                {
                    furi: '＾勉（べん）強（きょう）｜は　どうですか。｜＾漢（かん）字（じ）｜が　｜＾大（たい）変（へん）｜だと　｜＾言（い）｜って　いますが。。。',
                    normal: '勉強は　どうですか。漢字が　大変だと　言って　いますが。。。',
                    en: 'How is studying? Although she says that kanji is difficult. . .',
                    mm: 'စာကောအဆင်ပြေလား၊ ခန်းကြီးက ခက်တယ်လို့ ပြောနေလို့ပါ',
                },
            ],
        },
        {
            person: {
                furi: '＾伊（い）藤（とう）先（せん）生（せい）',
                normal: '伊藤先生',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: '＾毎（まい）日（にち）｜　｜＾漢（かん）字（じ）｜の　テストを　して　いますが、ハンス｜＾君（くん）｜は　いい　｜＾成（せい）績（せき）｜ですよ。',
                    normal: '毎日　漢字の　テストを　して　いますが、ハンス君は　いい　成績ですよ。',
                    en: 'There is a kanji test every day, and Hans got a good grade.',
                    mm: 'နေ့တိုင်းဖြေတဲ့ ခန်းကြီး စာမေးပွဲမှာတော့ ဟန်စုက အမှတ်ကောင်းပါတယ်',
                },
            ],
        },
        {
            person: {
                furi: 'クララ',
                normal: 'クララ',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: 'そうですか。ありがとう　ございます。',
                    normal: 'そうですか。ありがとう　ございます。',
                    en: 'Is that so. thank you.',
                    mm: 'ဟုတ်လား၊ ကျေးဇူးတင်ပါတယ်။',
                },
            ],
        },
        {
            person: {
                furi: '＾伊（い）藤（とう）先（せん）生（せい）',
                normal: '伊藤先生',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'ところで、もうすぐ　｜＾運（うん）動（どう）会（かい）｜ですが、お｜＾父（とう）｜さんも　いらっしゃいますか。',
                    normal: 'ところで、もうすぐ　運動会ですが、お父さんも　いらっしゃいますか。',
                    en: 'By the way, the sports festival is coming soon, will the father be there too?',
                    mm: 'ဒါနဲ့လေ၊ မကြာမီ ကျောင်းအားကစားပွဲတော်ရှိပါတယ်၊ ကလေးအဖေကော တက်ရောက်မလား?',
                },
            ],
        },
        {
            person: {
                furi: 'クララ',
                normal: 'クララ',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: 'ええ。',
                    normal: 'ええ。',
                    en: 'yes',
                    mm: 'အင်း',
                },
            ],
        },
        {
            person: {
                furi: '＾伊（い）藤（とう）先（せん）生（せい）',
                normal: '伊藤先生',
                avatar: Icon.Kaiwa.WOMEN2
            },
            sentence: [
                {
                    furi: 'ハンス｜＾君（くん）｜が　｜＾学（がっ）校（こう）｜で　どんな　｜＾様（よう）子（す）｜か、ぜひ　｜＾見（み）｜て　ください。',
                    normal: 'ハンス君が　学校で　どんな　様子か、ぜひ　見て　ください。',
                    en: "Please take a look at how Hans is doing at school.",
                    mm: 'ဟန်စုက ကျောင်းမှာ ဘယ်လိုအခြေအနေလည်းဆိုတာကို ဆက်ဆက် လာကြည့်ပါ',
                },
            ],
        },
        {
            person: {
                furi: 'クララ',
                normal: 'クララ',
                avatar: Icon.Kaiwa.WOMEN1
            },
            sentence: [
                {
                    furi: 'わかりました。これからも　よろしく　お｜＾願（ねが）｜いします。',
                    normal: 'わかりました。これからも　よろしく　お願いします。',
                    en: 'I got it. I look forward to working with you.',
                    mm: 'နားလည်ပါပြီ၊ ဆက်ပြီးကူညီစောင့်လျှောက်ပေးပါရှင့်',
                },
            ],
        }
    ],
    audioUrl: require("@assets/audio/lesson40/kaiwa/4N40KW.mp3")
}

/*
{
    title: {
        furi: '',
        normal: '',
        en: '',
        mm: '',
    },
    conversation: [
        {
            person: {
                furi: '',
                normal: '',
                avatar: Icon.Kaiwa.MANAGER
            },
            sentence: [
                {
                    furi: '',
                    normal: '',
                    en: '',
                    mm: '',
                },
            ],
        },
    ],
    audioUrl: require("@assets/audio/lesson36/kaiwa/4N36KW.mp3")
}
*/