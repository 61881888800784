export const bunkei= [
    {
        sentence: [
            {
                furi: "ゆうべ　お｜＾酒（さけ）｜を　｜＾飲（の）｜みすぎました。",
                normal: "ゆうべ　お酒を　飲みすぎました。",
                en: "I drank too much alcohol last night.",
                mm: "မနေ့ညက အရက်အရမ်းသောက်မိတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson44/bunpou/4N44BK1.mp3")
    },
    {
        sentence: [
            {
                furi: "この　パソコンは　｜＾使（つか）｜いやすいです。",
                normal: "この　パソコンは　使いやすいです。",
                en: "This computer is easy to use.",
                mm: "ဤကွန်ပြူတာသည် အသုံးပြုရလွယ်ကူသည်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson44/bunpou/4N44BK2.mp3")
    },
    {
        sentence: [
            {
                furi: "ズボンを　｜＾短（みじか）｜く　して　ください。",
                normal: "ズボンを　短く　して　ください。",
                en: "Please shorten pants.",
                mm: "ကျေးဇူးပြု၍ ဘောင်းဘီကို တို‌‌ပေးပါ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson44/bunpou/4N44BK3.mp3")
    },
]