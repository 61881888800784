import { MojiType } from "..";

export const moji: MojiType = {
  mondai1:{
    title: 'もんだい１＿＿＿＿の　ことばは　ひらがなで　どう　かきますか。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 1,
        question: 'この　ほんだなは　｜！自分｜で　つくりました。',
        answers: ['じぷん','じぶん','じいぷん','じいぶん'],
        pick: '',
        correct: 'じぶん'
      },
      {
        number: 2,
        question: 'この　｜！旅館｜は　とても　ゆうめいです。',
        answers: ['りょうかん','りょうがん','りょかん','りょがん'],
        pick: '',
        correct: 'りょかん'
      },
      {
        number: 3,
        question: '今は　｜！特に｜ほしい　ものは　ありません。',
        answers: ['さきに','べつに','すぐに','とくに'],
        pick: '',
        correct: 'とくに'
      },
      {
        number: 4,
        question: 'なつやすみの　よていが　｜！決まりました｜。',
        answers: ['あつまりました','きまりました','はじまりました','とまりました'],
        pick: '',
        correct: 'きまりました'
      },
      {
        number: 5,
        question: 'この　国は　ロボットの　ぎじゅつが　｜！進んで｜　いる。',
        answers: ['つづんで','すずんで','つつんで','すすんで'],
        pick: '',
        correct: 'すすんで'
      },
      {
        number: 6,
        question: 'スーツケースを　｜！運んで｜　います',
        answers: ['かんで','ふんで','はこんで','たのんで'],
        pick: '',
        correct: 'はこんで'
      },
      {
        number: 7,
        question: '｜！空港｜まで　バスで　行きます。',
        answers: ['こうく','こうくう','くうこ','くうこう'],
        pick: '',
        correct: 'くうこう'
      },
      {
        number: 8,
        question: 'あさより　かぜが　すこし｜！弱く｜　なりました。',
        answers: ['よわく','つよく','おそく','はやく'],
        pick: '',
        correct: 'よわく'
      },
      {
        number: 9,
        question: '新しい　｜！住所｜を　教えてください。',
        answers: ['じゅうしょ','じゅうしょう','じゅじょ','じゅじょう'],
        pick: '',
        correct: 'じゅうしょ'
      },
    ]
  },
  mondai2:{
    title: 'もんだい２＿＿＿＿の　ことばは　どう　かきますか。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 10,
        question: 'この　カメラの　つかいかたを　｜！せつめい｜して　ください。',
        answers: ['訳明','設明','説明','記明'],
        pick: '',
        correct: '説明'
      },
      {
        number: 11,
        question: 'わたしは　いつも　あさ　7時　｜！おきます｜。',
        answers: ['起きます','置きます','措きます','押きます'],
        pick: '',
        correct: '起きます'
      },
      {
        number: 12,
        question: 'ちかてつの　えきは　ここから　｜！とおい｜です。',
        answers: ['近く','遠い','違い','通い'],
        pick: '',
        correct: '遠い'
      },
      {
        number: 13,
        question: '毎日　｜！やさい｜を　食べます。',
        answers: ['理菜','也祭','家菜','野菜'],
        pick: '',
        correct: '野菜'
      },
      {
        number: 14,
        question: '目を　｜！とじて｜　ください。',
        answers: ['賭じる','開じて','閉じて','関じて'],
        pick: '',
        correct: '閉じて'
      },
      {
        number: 15,
        question: 'この　店は　9時から　6時まで　｜！えいぎょう｜して　います。',
        answers: ['宮業','営業','宮事','営事'],
        pick: '',
        correct: '営業'
      },
    ]
  },
  mondai3:{
    title: 'もんだい３（　　）に　なにを　いれますか。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 16,
        question: '大学の（　　）が　この　会社に　つとめて　います。',
        answers: ['せんぱい','しゃいん','てんいん','しゃちょう'],
        pick: '',
        correct: 'せんぱい'
      },
      {
        number: 17,
        question: 'いえを　出る　前に、　でんきを　けしたか（　　）しました。',
        answers: ['スタート','オープン','スイッチ','チェック'],
        pick: '',
        correct: 'チェック'
      },
      {
        number: 18,
        question: 'りょうしんが　来たので、とうきょうを（　　）しました。',
        answers: ['うけつけ','あんない','れんらく','しょうたい'],
        pick: '',
        correct: 'あんない'
      },
      {
        number: 19,
        question: 'ともだちは　やくそくの　時間に　おくれましたが、みんなに（　　）。',
        answers: ['いけませんでした','すみませんでした','あやまりませんでした','まにあいませんでした'],
        pick: '',
        correct: 'あやまりませんでした'
      },
      {
        number: 20,
        question: 'この　ねこは（　　）いるので　おもいです。',
        answers: ['たりて','おとって','ふえて','のこって'],
        pick: '',
        correct: 'おとって'
      },
      {
        number: 21,
        question: 'みんなで　かんがえれば、いい（　　）が　でるかもしれません。',
        answers: ['アイデイア','ニュース','クラブ','シーズン'],
        pick: '',
        correct: 'アイデイア'
      },
      {
        number: 22,
        question: '日本で　買った　本を　国へ（　　）。',
        answers: ['おとしました','かけました','おくりました','なげました'],
        pick: '',
        correct: 'おくりました'
      },
      {
        number: 23,
        question: 'この　コーヒーは　ちょっと　へんな　あじが（　　）。',
        answers: ['来ますね','見ますね','しますね','いますね'],
        pick: '',
        correct: 'しますね'
      },
      {
        number: 24,
        question: 'お母さんの　びょうきが（　　）、よかったですね。',
        answers: ['おちて','きれて','しまって','なおって'],
        pick: '',
        correct: 'なおって'
      },
      {
        number: 25,
        question: 'かさを（　　）あるいて　いる　人が　いるから、外は　雨でしょう。',
        answers: ['さして','おして','あけて','うけて'],
        pick: '',
        correct: 'さして'
      }
    ]
  },
  mondai4:{
    title: 'もんだい4＿＿＿＿の ぶんと　だいたい　おなじ　いみの　ぶんが　あります。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 26,
        question: 'わたしは　やまださんを　かいものに　さそいました。',
        answers: ['わたしは　やまださんに　「今日は　かいものに　いけません」と　言いました。','わたしは　やまださんに　「今日から　かいものに　行って　きます」と　言いました。','わたしは　やまださんに　「かいものに　行って　きて　ください」と　言いました。','わたしは　やまださんに　「いっしょに　かいものに　いきませんか」と　言いました。'],
        pick: '',
        correct: 'わたしは　やまださんに　「いっしょに　かいものに　いきませんか」と　言いました。'
      },
      {
        number: 27,
        question: 'デパートで　さいふを　ぬすまれました。',
        answers: ['デパートで　さいふを　とられました。','デパートで　さいふを　みせられました。','デパートで　さいふを　わたされました。','デパートで　さいふを　かえされました。'],
        pick: '',
        correct: 'デパートで　さいふを　とられました。'
      },
      {
        number: 28,
        question: 'この　車は　ヨーロッパです。',
        answers: ['この　車は　ヨーロッパで　つくられて　います。','この　車は　ヨーロッパで　のられて　います。','この　車は　ヨーロッパで　しられて　います。','この　車は　ヨーロッパで　かわれて　います。'],
        pick: '',
        correct: 'この　車は　ヨーロッパで　つくられて　います。'
      },
      {
        number: 29,
        question: 'やまもとさんの　じは　こまかいです。',
        answers: ['やまもとさんの　じは　きれいです。','やまもとさんの　じは　きたないです。','やまもとさんの　じは　ちいさいです。','やまもとさんの　じは　おおきいです。'],
        pick: '',
        correct: 'やまもとさんの　じは　ちいさいです。'
      },
      {
        number: 30,
        question: 'きのうの　かいぎには　たなかさんいがいは　きました。',
        answers: ['きのうの　かいぎには　たなかさんは　きましたが、ほかの　人は　来ませんでした。','きのうの　かいぎには　たなかさんが　来ませんでしたが、ほかの　人は　来ました。','きのうの　かいぎには　たなかさんは　来る　前に、ほかの　人が　来ました。','きのうの　かいぎには　たなかさんが　木た　後で、ほかの　人が　来ました。'],
        pick: '',
        correct: 'きのうの　かいぎには　たなかさんが　来ませんでしたが、ほかの　人は　来ました。'
      },
    ]
  },
  mondai5:{
    title: 'もんだい５　つぎの ことばの　つかいかたで　いちばん　いい　ものを　１・２・３・４から　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 31,
        question: '　とちゅう',
        answers: [
          'こんやの　｜！とちゅう｜、雨が　ふるでしょう。',
          'まるい　テーブルの　｜！とちゅう｜に　ケーキを　おきました。',
          'ビルと　ビルの　｜！とちゅう｜から　うみが　見えます。',
          'かいぎの　｜！とちゅう｜で　電話が　なりました。',
        ],
        pick: '',
        correct: 'かいぎの　｜！とちゅう｜で　電話が　なりました。'
      },
      {
        number: 32,
        question: '　にがい',
        answers: [
          '今週は　しごとの　よていが　｜！にがい｜です。',
          'きのうは　｜！にがい｜天気でした。',
          'たなかさんの　いぬは　｜！にがい｜ですね。',
          'ここは　とても　｜！にがい｜レストランですね。',
        ],
        pick: '',
        correct: 'たなかさんの　いぬは　｜！にがい｜ですね。'
      },
      {
        number: 33,
        question: '　つたえる',
        answers: [
          '外国語の　しょうせつを　日本語に　｜！つたえました｜。',
          '学生に　しけんの　時間を　｜！つたえました｜。',
          'ゆうびんきょくで　にもつを　｜！つたえました｜。',
          'ともだちの　たんじょうびに　花を　｜！つたえました｜。',
        ],
        pick: '',
        correct: '学生に　しけんの　時間を　｜！つたえました｜。'
      },
      {
        number: 34,
        question: '　かたづける',
        answers: [
          'へやを｜！かたづけて｜パーテイーの　じゅんびを　しました。',
          'せんせいは　学生を　三つの　グループに　｜！かたづけました｜。',
          'プレゼントを　きれいな　はこに　｜！かたづけて｜　ともだちに　あげます。',
          'いろいろな　いけんが　出たので、少し　｜！かたづけました｜。',
        ],
        pick: '',
        correct: 'へやを｜！かたづけて｜パーテイーの　じゅんびを　しました。'
      },
      {
        number: 35,
        question: '　にあう',
        answers: [
          'いみの　｜！にあって｜いる　ことばが　たくさん　あります。',
          'やまださんは　あかい　ふくが　｜！にあいます｜。',
          'あの　子は　目が　お母さんに　｜！にあって｜いますね。',
          'なんども　かぞえましたが、かずが　｜！にあいません｜。',
        ],
        pick: '',
        correct: 'やまださんは　あかい　ふくが　｜！にあいます｜。'
      },
    ]
  }
}