export const lesson19 = {
    "問": 180,
    "題": 181,
    "試": 182,
    "験": 183,
    "質": 184,
    "合": 185,
    "答": 186,
    "用": 187,
    "紙": 188,
    "意": 189
};