import { Minna } from "@consts";
import Icon from "@consts/icon";
import { BottomSpace, TopSpace } from "@consts/layout";
// import { TopSpace,WhiteSpace,BottomSpace } from "@consts/layout";
import CategoryScreenLayout from "@layouts/home/CategoryScreenLayout";
import { memo, useEffect } from "react";
import { ScreenPropType } from "../../App";
import CategoryMobile from "./category/CategoryMobile";
import CategoryWeb from "./category/CategoryWeb";
const CategoryScreen = ({ showModel,setShowModel,setUnlockPremium,fontSize, Logger,routeHistory,setRouteData, lang,furigana, useLocation,Routes, useNavigate}: ScreenPropType) => {
    const log = Logger(3);
    const navigate = useNavigate();
    const location = useLocation();
    const { lesson, id } = location.state;
    const vocab = Minna.vocab[id];
    const grammarExplain = Minna.grammar[id].explain;
    const kaiwaPerson = Minna.kaiwa[id].conversation;
    interface Category {
        Icon: string;
        label: string;
        onclick: ()=>{};
    }
    const cagetories:Array<Category> = [
        {
            Icon: Icon.Vocab,
            label: 'Vocabulary',
            onclick: () => navigate(Routes.VOCAB, {
                state: { lesson, id }

            })
        },

        {
            Icon: Icon.Grammar,
            label: 'Grammar',
            onclick: () => navigate(Routes.GRAMMAR, {
                state: { lesson, id }

            })

        },
        {
            Icon: Icon.Conversation,
            label: 'Conversation',
            onclick: () => navigate(Routes.KAIWA, {
                state: { lesson, id }

            })
        },
        {
            Icon: (id + 1) % 5 == 0 ?Icon.Exercise:Icon.Kanji,
            label: (id + 1) % 5 == 0 ?'Exercise': 'Kanji',
            onclick: () => navigate(
                (id + 1) % 5 == 0 ? Routes.FUKUSHUU : Routes.KANJI,
                {state: { lesson, id }
                // { state: (id + 1) % 5 == 0 ?
                //     { lesson, id: id == 24 ? 4 : id - (4 * Math.floor(id / 4)) }
                //     :{ lesson: id - Math.floor(id / 5), id: id - Math.floor(id / 5) }
                // }
            })
            // onclick: () => navigate((id + 1) % 5 == 0 ?Routes.FUKUSHUU:Routes.KANJI, {
            //     state: (id + 1) % 5 == 0 ? { lesson, id: id == 24 ? 4 : id - (4 * Math.floor(id / 4)) }
            //     :    { lesson: id - Math.floor(id / 5), id: id - Math.floor(id / 5) }

            // })
        },
    ];
    const nabs = [
        {
            title: "home",
            route: Routes.HOME,
        },
        {
            title: "Category",
            route: null,
        }
    ];
    useEffect(() => {
        if(routeHistory.home.route != Routes.CATEGORY){
            setRouteData({home:{
                state:{ lesson, id },
                route:Routes.CATEGORY
            }})
        }
    },[])
    // const Top = Layout.Category.Top.Web;
    return (
        <>
            {/* <SizeBox vertical="h-5" /> */}
            <TopSpace/>
            <CategoryScreenLayout
                body={<>
                    {/* <ResponsiveView /> */}
                    <CategoryWeb nabs={nabs}
                        lesson={lesson}
                        cagetories={cagetories}
                        vocab={vocab}
                        furigana={furigana}
                        fontSize={fontSize}
                        lang={lang}
                        grammarExplain={grammarExplain}
                        id={id}
                        kaiwaPerson={kaiwaPerson} />
                    <CategoryMobile nabs={nabs}
                        lesson={lesson}
                        cagetories={cagetories}
                        vocab={vocab}
                        furigana={furigana}
                        fontSize={fontSize}
                        lang={lang}
                        grammarExplain={grammarExplain}
                        id={id}
                        kaiwaPerson={kaiwaPerson} />
                    <BottomSpace />
                </>
                }
                Routes={Routes} Logger={Logger} useNavigate={useNavigate}
                setShowModel={setShowModel}
                showModel={showModel}
                setUnlockPremium={setUnlockPremium}
            />
        </>

    )
}
export default memo(CategoryScreen);