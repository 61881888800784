export const renshuuA = [
    {
        sentence: [
            {
                furi: "ちょうど　｜＾今（いま）｜から　｜＾試（し）合（あい）｜が　｜！はじまる｜　ところです。",
                normal: "ちょうど　今から　試合が　はじまる　ところです。",
                en: "The game is about to start now.",
                mm: "ကစားပွဲသည် ယခု စတင်ပါတော့မည်။",
            },
            {
                furi: "これから　みんなで　｜！しょくじする｜　ところです。",
                normal: "これから　みんなで　しょくじする　ところです。",
                en: "We are going to eat together from now on.",
                mm: "ငါတို့ အခုကစပြီး အတူတူစားကြမလို့။",
            },
            {
                furi: "＾今（いま）｜　｜＾部（へ）屋（や）｜を　｜！かたづけて　いる｜　ところです。",
                normal: "今　部屋を　かたづけて　いる　ところです。",
                en: "I am cleaning up my room now.",
                mm: "အခု ငါ့အခန်းကို သန့်ရှင်းရေးလုပ်နေတုန်း။",
            },
            {
                furi: "＾論（ろん）文（ぶん）｜を　｜！かいて　いる｜　ところです。",
                normal: "論文を　かいている　ところです。",
                en: "I am writing my thesis.",
                mm: "ငါ့စာတမ်းကို ရေးနေတုန်း။",
            },
            {
                furi: "たった｜＾今（いま）｜　バスが　｜！でた｜　ところです。",
                normal: "たった今　バスが　でた　ところです。",
                en: "The bus has just left.",
                mm: "ဘတ်စ်ကားက ခု‌လေးတင် ထွက်သွားပြီ။",
            },
            {
                furi: "うちへ　｜！かえって　きた｜　ところです。",
                normal: "うちへ　かえって　きた　ところです。",
                en: "I have just come home.",
                mm: "ငါ အခုမှ အိမ်ပြန်ရောက်တာ။",
            },
        ],
        audioUrl: require("@assets/audio/lesson46/renshua/4N46RSA1.mp3")
    },
    {
        sentence: [
            {
                furi: "わたしは　さっき　｜＾昼（ひる）｜ごはんを　｜！たべた｜　ばかりです。",
                normal: "わたしは　さっき　昼ごはんを　たべた　ばかりです。",
                en: "I just ate lunch a while ago.",
                mm: "ကျွန်တော် ခုနကမှ နေ့လည်စာ စားပြီးခါစဘဲ ရှိ‌သေးတယ်။",
            },
            {
                furi: "ワットさんは　｜＾先（せん）月（げつ）｜　｜！けっこんした｜　ばかりです。",
                normal: "ワットさんは　先月　けっこんした　ばかりです。",
                en: "Watt just got married last month.",
                mm: "Watt ဟာ ပြီးခဲ့တဲ့လက လက်ထပ်ခဲ့ပါတယ်။",
            },
            {
                furi: "この　コピー｜＾機（き）｜は　｜＾先（せん）週（しゅう）｜　｜！しゅうりした｜　ばかりです。",
                normal: "この　コピー機は　先週　しゅうりした　ばかりです。",
                en: "This copier was just repaired last week.",
                mm: "ဒီမိတ္တူကို ပြီးခဲ့တဲ့အပတ်ကပဲ ပြင်ပြီးခဲ့ပါတယ်။",
            }
        ],
        audioUrl: require("@assets/audio/lesson46/renshua/4N46RSA2.mp3")
    },
    {
        sentence: [
            {
                furi: "＾荷（に）物（もつ）｜は　あした　｜！とどく｜　はずです。",
                normal: "荷物は　あした　とどく　はずです。",
                en: "The package should arrive tomorrow.",
                mm: "ပစ္စည်းက မနက်ဖြန်ရောက်မယ် (သေချာတယ်)။",
            },
            {
                furi: "グプタさんは　お｜＾酒（さけ）｜を　｜！のまない｜　はずです。",
                normal: "グプタさんは　お酒を　のまない　はずです。",
                en: "Gupta does not drink alcohol.",
                mm: "Guptaက အရက်မသောက်ဘူး (သေချာတယ်)။",
            },
            {
                furi: "＾部（ぶ）長（ちょう）｜は　きょうは　｜！いそがしい｜　はずです。",
                normal: "部長は　きょうは　いそがしい　はずです。",
                en: "The manager must be busy today.",
                mm: "မန်နေဂျာက ဒီနေ့ အလုပ်ရှုပ်နေမှာ သေချာတယ်။",
            },
            {
                furi: "＾課（か）長（ちょう）｜は　ドイツ｜＾語（ご）｜が　｜！じょうずな｜　はずです。",
                normal: "課長は　ドイツ語が　じょうずな　はずです。",
                en: "The section chief must be fluent in German.",
                mm: "ဌာနမှူးက ဂျာမန်စကား ကျွမ်းကျင်မှာ သေချာတယ်။",
            },
            {
                furi: "＾小（お）川（がわ）｜さんは　｜！るすの｜　はずです。",
                normal: "小川さんは　るすの　はずです。",
                en: "Mr. Ogawa must be absence.",
                mm: "မစ္စတာ အိုဂါဝါ အိမ်မှာ မရှိတာ သေချာတယ်။",
            },
        ],
        audioUrl: require("@assets/audio/lesson46/renshua/4N46RSA3.mp3")
    },
];