const CryptoJS = require("crypto-js");
export const dependencyHelper ={
    /* crypto-js */
    /* npm i crypto-js */
    encrypt: (message:string,secret:string)=>{
        return CryptoJS.AES.encrypt(message, secret).toString();
    },
    decrypt:(message:string,secret:string)=>{
        return (CryptoJS.AES.decrypt(message, secret)).toString(CryptoJS.enc.Utf8);
    },
    checkAuth:(premium:string)=>{
        let uuid = localStorage.getItem("uuid");
        if(uuid){
            const [a,b,c,d,secret] = uuid.split("-");
            const result =(CryptoJS.AES.decrypt(premium, secret+"AKMAAMML.MINNAN4")).toString(CryptoJS.enc.Utf8);
            return  result===secret;
        }else{
            return false;
        }

    }
    /* crypto-js */
}
