
export const lesson9 = [
    {"lesson":9,"level":4,"question":"こえ","represent":["声"],"answer":["体","声","薬","心"],"true":"声","times":10},
    {"lesson":9,"level":4,"question":"かお","represent":["顔"],"answer":["顔","心","頭","薬"],"true":"顔","times":10},
    {"lesson":9,"level":4,"question":"かがく","represent":["科","学"],"answer":["薬局","外科","内側","科学"],"true":"科学","times":10},
    {"lesson":9,"level":4,"question":"おおごえ","represent":["大","声"],"answer":["首相","顔面","大声","外科"],"true":"大声","times":10},
    {"lesson":9,"level":4,"question":"ないか","represent":["内","科"],"answer":["外科","国内","目薬","内科"],"true":"内科","times":10},
    {"lesson":9,"level":4,"question":"ずつう","represent":["頭"],"answer":["顔面","顔金","外科","頭痛"],"true":"頭痛","times":10},
    {"lesson":9,"level":4,"question":"げか","represent":["外","科"],"answer":["関心","内側","病気","外科"],"true":"外科","times":10},
    {"lesson":9,"level":4,"question":"びょういん","represent":["病","院"],"answer":["病院","病人","首相","科目"],"true":"病院","times":10},
    {"lesson":9,"level":4,"question":"かんしん","represent":["心"],"answer":["体力","頭痛","外科","関心"],"true":"関心","times":10},
    {"lesson":9,"level":4,"question":"かない","represent":["家","内"],"answer":["科目","家内","大声","頭痛"],"true":"家内","times":10},
    {"lesson":9,"level":4,"question":"とうぶ","represent":["頭"],"answer":["病院","頭部","薬局","目薬"],"true":"頭部","times":10},
    {"lesson":9,"level":4,"question":"うちがわ","represent":["内"],"answer":["体育","科学","病気","内側"],"true":"内側","times":10},
    {"lesson":9,"level":4,"question":"かもく","represent":["科","目"],"answer":["体力","家内","顔面","科目"],"true":"科目","times":10},
    {"lesson":9,"level":4,"question":"あたま","represent":["頭"],"answer":["薬","体","声","頭"],"true":"頭","times":10},
    {"lesson":9,"level":4,"question":"びょうにん","represent":["病","人"],"answer":["関心","病気","頭金","病人"],"true":"病人","times":10},
    {"lesson":9,"level":4,"question":"こころ","represent":["心"],"answer":["薬","心","頭","顔"],"true":"心","times":10},
    {"lesson":9,"level":4,"question":"しゅしょう","represent":["首"],"answer":["内科","外科","首相","頭部"],"true":"首相","times":10},
    {"lesson":9,"level":4,"question":"がんめん","represent":["顔"],"answer":["顔面","家内","体育","病人"],"true":"顔面","times":10},
    {"lesson":9,"level":4,"question":"くすり","represent":["薬"],"answer":["声","頭","薬","顔"],"true":"薬","times":10},
    {"lesson":9,"level":4,"question":"びょうき","represent":["病","気"],"answer":["病人","内側","病気","科目"],"true":"病気","times":10},
    {"lesson":9,"level":4,"question":"たいいく","represent":["体"],"answer":["内科","体育","顔面","目薬"],"true":"体育","times":10},
    {"lesson":9,"level":4,"question":"こくない","represent":["国","内"],"answer":["国内","頭痛","家内","病人"],"true":"国内","times":10},
    {"lesson":9,"level":4,"question":"やっきょく","represent":["薬"],"answer":["科学","薬局","頭部","目薬"],"true":"薬局","times":10},
    {"lesson":9,"level":4,"question":"めぐすり","represent":["目","薬"],"answer":["内科","科学","目薬","国内"],"true":"目薬","times":10},
    {"lesson":9,"level":4,"question":"たいりょく","represent":["体","力"],"answer":["顔面","体力","科目","体育"],"true":"体力","times":10},
    {"lesson":9,"level":4,"question":"あたまきん","represent":["頭","金"],"answer":["家内","頭金","科目","顔色"],"true":"頭金","times":10},
    {"lesson":9,"level":4,"question":"かおいろ","represent":["顔","色"],"answer":["頭金","体力","顔色","病気"],"true":"顔色","times":10},
    {"lesson":9,"level":4,"question":"からだ","represent":["体"],"answer":["頭","体","顔","心"],"true":"体","times":10},
]