export const lesson2 = {
    '明': 10,
    '暗': 11,
    '遠': 12,
    '近': 13,
    '強': 14,
    '弱': 15,
    '重': 16,
    '軽': 17,
    '太': 18,
    '細': 19
};