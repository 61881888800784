import { MojiType } from "..";

export const moji: MojiType = {
  mondai1:{
    title: 'もんだい１＿＿＿＿の　ことばは　ひらがなで　どう　かきますか。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 1,
        question: 'くつに　｜！石｜が　入って　いました。',
        answers: ['いし','すな','くさ','えだ'],
        pick: '',
        correct: 'いし'
      },
      {
        number: 2,
        question: 'にほんで　いろいろな　｜！経験｜を　しました。',
        answers: ['けいけん','けいげん','けけん','けげん'],
        pick: '',
        correct: 'けいけん'
      },
      {
        number: 3,
        question: '｜！店員｜に　トイレが　どこに　あるか　聞きました。',
        answers: ['てにん','てんにん','ていん','てんいん'],
        pick: '',
        correct: 'てんいん'
      },
      {
        number: 4,
        question: 'きょうは　｜！食堂｜が　こんで　いました。',
        answers: ['しゅくど','しょくどう','しょくど','しゅくどう'],
        pick: '',
        correct: 'しょくどう'
      },
      {
        number: 5,
        question: 'この　まどから　｜！港｜が　見えます。',
        answers: ['うみ','みなと','みずうみ','いけ'],
        pick: '',
        correct: 'みなと'
      },
      {
        number: 6,
        question: 'この　｜！小説｜は　おもしろかったです。',
        answers: ['しょせつ','しょぜつ','しょうせつ','しょうぜつ'],
        pick: '',
        correct: 'しょうせつ'
      },
      {
        number: 7,
        question: 'まいばん　｜！日記｜を　書いて　います。',
        answers: ['にっき','にちき','にっきい','にちきい'],
        pick: '',
        correct: 'にっき'
      },
      {
        number: 8,
        question: '｜！夕方｜、雨が　ふりました。',
        answers: ['ゆうほう','ゆうがた','ゆほう','ゆがた'],
        pick: '',
        correct: 'ゆうがた'
      },
      {
        number: 9,
        question: 'もうすぐ　｜！秋｜ですね。',
        answers: ['ふゆ','なつ','はる','あき'],
        pick: '',
        correct: 'あき'
      },
    ]
  },
  mondai2:{
    title: 'もんだい２＿＿＿＿の　ことばは　どう　かきますか。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 10,
        question: 'すずきさんは　｜！あおい｜シャツを　きています。',
        answers: ['青い','黒い','赤い','白い'],
        pick: '',
        correct: '青い'
      },
      {
        number: 11,
        question: 'かいぎの　｜！ばしょ｜を　おしえて　ください。',
        answers: ['揚所','湯所','揚听','場所'],
        pick: '',
        correct: '場所'
      },
      {
        number: 12,
        question: 'わたしの　いえは　えきから｜！あるいて｜５分です。',
        answers: ['走て','走いて','歩て','歩いて'],
        pick: '',
        correct: '歩て'
      },
      {
        number: 13,
        question: 'ちかてつが　できて　｜！べんり｜に　なりました。',
        answers: ['便宜','便理','便立','便利'],
        pick: '',
        correct: '便利'
      },
      {
        number: 14,
        question: 'とても　｜！ねむかった｜ので、コーヒーを　飲みました。',
        answers: ['睡むかった','睡かった','眠むかった','眠かった'],
        pick: '',
        correct: '眠かった'
      },
      {
        number: 15,
        question: 'きょうは　｜！ゆき｜が　ふって　います。',
        answers: ['雪','電','雷','雲'],
        pick: '',
        correct: '雪'
      },
    ]
  },
  mondai3:{
    title: 'もんだい３（　　）に　なにを　いれますか。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 16,
        question: 'けさ　せんたくした　ふくが　まだ（　　）いません。',
        answers: ['ひえて','なおって','ぬれて','かわいて'],
        pick: '',
        correct: 'かわいて'
      },
      {
        number: 17,
        question: 'スミスさんは　いつも（　　）べんきょうして　います。',
        answers: ['だいじに','たいせつに','ねっしんに','まっすぐに'],
        pick: '',
        correct: 'ねっしんに'
      },
      {
        number: 18,
        question: 'わたしは　にほんの　まんがに（　　）が　あります。',
        answers: ['きぶん','きょうみ','こころ','しゅみ'],
        pick: '',
        correct: 'きょうみ'
      },
      {
        number: 19,
        question: 'わたしは　テニスの（　　）を　よく　しりません。',
        answers: ['アイデイア','ルール','あんない','せつめい'],
        pick: '',
        correct: 'ルール'
      },
      {
        number: 20,
        question: 'りょこうの　にもつは　もう（　　）できましたか。',
        answers: ['やくそく','りよう','せわ','ようい'],
        pick: '',
        correct: 'ようい'
      },
      {
        number: 21,
        question: 'おとうとと（　　）して　母に　あげる　プレゼントを　えらびました。',
        answers: ['さんせい','あいさつ','そうだん','へんじ'],
        pick: '',
        correct: 'そうだん'
      },
      {
        number: 22,
        question: 'この　にもつを　あそこに（　　）ください。',
        answers: ['はこんで','つたえて','ひろって','むかえて'],
        pick: '',
        correct: 'はこんで'
      },
      {
        number: 23,
        question: 'この　きかいは　つかいかたを　まちがえると　とても（　　）です',
        answers: ['けっこう','きけん','じゆう','あんぜん'],
        pick: '',
        correct: 'きけん'
      },
      {
        number: 24,
        question: '入口の　前には　車を（　　）ください。',
        answers: ['やめないで','しめないで','とめないで','きめないで'],
        pick: '',
        correct: 'とめないで'
      }
    ]
  },
  mondai4:{
    title: 'もんだい4＿＿＿＿の ぶんと　だいたい　おなじ　いみの　ぶんが　あります。１・２・３・４から　いちばん　いい　ものを　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 25,
        question: 'バスが　しゅっぱつしました。',
        answers: ['ばすが　とまりました。','バスが　つきました。','バスが　でました。','バスが　まがりました。'],
        pick: '',
        correct: 'バスが　でました。'
      },
      {
        number: 26,
        question: 'もっと　ていねいに　かいて　ください。',
        answers: ['もっと　おおきく　かいて　ください。','もっと　きれいに　かいて　ください。','もっと　ふとく　かいて　ください。','もっと　かんたんに　かいて　ください。'],
        pick: '',
        correct: 'もっと　きれいに　かいて　ください。'
      },
      {
        number: 27,
        question: 'あには　えが　うまいです。',
        answers: ['あには　えが　じょうずです。','あには　えが　きらいです。','あには　えが　へたです。','あには　えが　すきです。'],
        pick: '',
        correct: 'あには　えが　じょうずです。'
      },
      {
        number: 28,
        question: 'きのうは　ねぼうしました。',
        answers: ['きのうは　ねるのが　おそく　なって　しまいました。','きのうは　はやく　ねて　しまいました。','きのうは　おきるのが　おそく　なって　しまいました。','きのうは　はやく　おきて　しまいました。'],
        pick: '',
        correct: 'きのうは　おきるのが　おそく　なって　しまいました。'
      },
      {
        number: 29,
        question: 'たなかさんは　せんせいに　ほめられました。',
        answers: ['先生は　たなかさんに　「気を　つけてね」と　言いました。','先生は　たなかさんに　「とても　よかったですよ」と　言いました。','先生は　たなかさんに　「ちょっと　休みましょう」と　言いました。','先生は　たなかさんに　「たいへんですね」と　言いました。'],
        pick: '',
        correct: '先生は　たなかさんに　「とても　よかったですよ」と　言いました。'
      }
    ]
  },
  mondai5:{
    title: 'もんだい５　つぎの ことばの　つかいかたで　いちばん　いい　ものを　１・２・３・４から　ひとつ　えらんで　ください。',
    questions: [
      {
        number: 30,
        question: '　るす',
        answers: [
          'さいきん　いそがっしくて、しごとが　｜！るす｜に　なりません。',
          'あの　デパートは　きょうは　｜！るす｜です。',
          'この　ひこうきには　｜！るす｜の　せきが　ありません。',
          'ともだちの　いえに　行ったら　｜！るす｜でした。',
        ],
        pick: '',
        correct: 'ともだちの　いえに　行ったら　｜！るす｜でした。'
      },
      {
        number: 31,
        question: '　しんせつ',
        answers: [
          'この　りょうりは　からだに　とても｜！しんせつ｜ですよ。',
          'コンビニは　何でも　買えるので　とても　｜！しんせつ｜です。',
          'この　びょういんの　おいっしゃさんは　とても｜！しんせつ｜ですよ。',
          'わたっしの　母は　にわの　花に　とても｜！しんせつ｜です。',
        ],
        pick: '',
        correct: 'この　びょういんの　おいっしゃさんは　とても｜！しんせつ｜ですよ。'
      },
      {
        number: 32,
        question: '　にがい',
        answers: [
          'かぜを　ひいたので、　こえが｜！にがい｜です。',
          'くらい　みちを　一人で　あるくのは　｜！にがい｜です。',
          'にもつが　おもかったので、うでが　｜！にがい｜です。',
          'この　くすりは　とても　｜！にがい｜です。',
        ],
        pick: '',
        correct: 'この　くすりは　とても　｜！にがい｜です。'
      },
      {
        number: 33,
        question: '　わる',
        answers: [
          'おさらを　おとして｜！わって｜しまいました。',
          'しんぶんを　｜！わって｜、かばんに　入れました。',
          'ようふくを　｜！わって｜、母に　おこられました。',
          'この　ジャマを　三本　びんに｜！わって｜ください。',
        ],
        pick: '',
        correct: 'おさらを　おとして｜！わって｜しまいました。'
      },
      {
        number: 34,
        question: '　ちこく',
        answers: [
          'あの　とけいは　ちょっと　｜！ちこく｜して　います。',
          'じゅぎょうに　｜！ちこく｜して　すみません。',
          '10時の　でんしゃに　｜！ちこく｜して　しまいました。',
          'らんらくが｜！ちこく｜して　すみません。',
        ],
        pick: '',
        correct: 'じゅぎょうに　｜！ちこく｜して　すみません。'
      },
    ]
  }
}