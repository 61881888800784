
export const lesson4 = [
    {"lesson":4,"level":4,"question":"せいとう","represent":["正","答"],"answer":["悪口","短大","赤道","正答"],"true":"正答","times":10},
    {"lesson":4,"level":4,"question":"よい","represent":["良"],"answer":["良い","赤い","短い","悪い"],"true":"良い","times":10},
    {"lesson":4,"level":4,"question":"せいねん","represent":["青","年"],"answer":["青年","悪口","赤道","最良"],"true":"青年","times":10},
    {"lesson":4,"level":4,"question":"ひろしま","represent":["広"],"answer":["黒板","広島","正月","正答"],"true":"広島","times":10},
    {"lesson":4,"level":4,"question":"あおい","represent":["青"],"answer":["黒い","短い","青い","良い"],"true":"青い","times":10},
    {"lesson":4,"level":4,"question":"あかい","represent":["赤"],"answer":["赤い","短い","低い","広い"],"true":"赤い","times":10},
    {"lesson":4,"level":4,"question":"たんだい","represent":["短","大"],"answer":["最良","短大","正月","悪性"],"true":"短大","times":10},
    {"lesson":4,"level":4,"question":"こくばん","represent":["黒"],"answer":["赤字","黒板","短大","赤道"],"true":"黒板","times":10},
    {"lesson":4,"level":4,"question":"あかじ","represent":["赤","字"],"answer":["赤字","短大","低音","青年"],"true":"赤字","times":10},
    {"lesson":4,"level":4,"question":"くろじ","represent":["黒","字"],"answer":["黒字","短大","悪口","青年"],"true":"黒字","times":10},
    {"lesson":4,"level":4,"question":"ひくい","represent":["低"],"answer":["短い","黒い","良い","低い"],"true":"低い","times":10},
    {"lesson":4,"level":4,"question":"あくせい","represent":["悪"],"answer":["悪性","低音","青年","正月"],"true":"悪性","times":10},
    {"lesson":4,"level":4,"question":"くろい","represent":["黒"],"answer":["短い","赤い","黒い","青い"],"true":"黒い","times":10},
    {"lesson":4,"level":4,"question":"さいりょう","represent":["良"],"answer":["赤字","悪性","最良","正答"],"true":"最良","times":10},
    {"lesson":4,"level":4,"question":"こうこく","represent":["広"],"answer":["広告","正月","赤道","広島"],"true":"広告","times":10},
    {"lesson":4,"level":4,"question":"みじかい","represent":["短"],"answer":["低い","短い","悪い","広い"],"true":"短い","times":10},
    {"lesson":4,"level":4,"question":"ていおん","represent":["低","音"],"answer":["短大","低音","広告","正答"],"true":"低音","times":10},
    {"lesson":4,"level":4,"question":"ひろい","represent":["広"],"answer":["短い","広い","良い","青い"],"true":"広い","times":10},
    {"lesson":4,"level":4,"question":"わるぐち","represent":["悪","口"],"answer":["青年","悪口","赤字","正月"],"true":"悪口","times":10},
    {"lesson":4,"level":4,"question":"せきどう","represent":["赤","道"],"answer":["赤字","低音","赤道","黒字"],"true":"赤道","times":10},
    {"lesson":4,"level":4,"question":"しょうがつ","represent":["正","月"],"answer":["広島","広告","正月","最良"],"true":"正月","times":10},
    {"lesson":4,"level":4,"question":"わるい","represent":["悪"],"answer":["黒い","低い","青い","悪い"],"true":"悪い","times":10},
]